import { apiClient } from './index';
import { handleResponse, handleError } from './responseHandler';
import APIConstants from '../../constants/APIConstants';
import { getSelectedFeedlotId } from '../storageService/GlobalData';
import getLanguageKey from '../languageService/index';
import moment from 'moment';
import { DATE_FORMATS } from '../../constants/AppConstants';

class ActivityService {
  getActivities(lastSynced) {
    let url = APIConstants.GET_ACTIVITIES.replace(
      '{{id}}',
      getSelectedFeedlotId() || 1,
    ).replace('{{languageKey}}', getLanguageKey());
    url = url.concat('&include_fields=1');
    if (lastSynced) {
      url = url.concat(`&syncCall=${true}&time=${lastSynced}`);
    }
    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  getActivityById(activityId) {
    let url = APIConstants.GET_ACTIVITY_BY_ID.replace(
      '{{feedlot_id}}',
      getSelectedFeedlotId() || 1,
    )
      // .replace('{{id}}', activityId);
      // url = url.concat('?include_fields=1');
      .replace('{{id}}', activityId)
      .replace('{{languageKey}}', getLanguageKey());
    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  getSubmissionById(feedlot_activity_submission_id) {
    let url = APIConstants.GET_SUBMISSION_BY_ID.replace(
      '{{id}}',
      feedlot_activity_submission_id,
    );
    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  getDeprecatedActivities() {
    const url = APIConstants.GET_DEPRECATED_ACTIVITIES.replace(
      '{{id}}',
      getSelectedFeedlotId() || 1,
    ).replace('{{languageKey}}', getLanguageKey());
    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  getActivitiesTags() {
    const url = APIConstants.GET_ACTIVITY_TAGS;
    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  getBackdatedActivitySubmissions(
    activityNumber = 1,
    instanceName = null,
    startDate = null,
    endDate = null,
  ) {
    let url = APIConstants.GET_BACKDATED_ACTIVITY_SUBMISSIONS.replace(
      '{{feedlotId}}',
      getSelectedFeedlotId() || 1,
    )
      .replace('{{activityNumber}}', activityNumber)
      .replace('{{startDate}}', startDate)
      .replace('{{endDate}}', endDate);

    if (instanceName) {
      url = url.concat('&instance_value=' + instanceName);
    }

    return apiClient()
      .get(encodeURI(url))
      .then(handleResponse)
      .catch(handleError);
  }

  getActivitySubmissions(
    activityNumber = 1,
    instanceName = null,
    startIndex = 0,
  ) {
    let url = APIConstants.GET_ACTIVITY_SUBMISSIONS.replace(
      '{{feedlotId}}',
      getSelectedFeedlotId() || 1,
    )
      .replace('{{activityNumber}}', activityNumber)
      .replace('{{startIndex}}', startIndex);

    if (instanceName) {
      url = url.concat('&instance_value=' + instanceName);
    }
    return apiClient()
      .get(encodeURI(url))
      .then(handleResponse)
      .catch(handleError);
  }

  assignActivity(payload) {
    const url = APIConstants.POST_ASSIGN_ACTIVITY;
    return apiClient()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  }

  updateActivityReminder(payload) {
    const url = APIConstants.PUT_ACTIVITY_REMINDER;
    return apiClient()
      .put(url, payload)
      .then(handleResponse)
      .catch(handleError);
  }

  deleteActivityReminder(activityId) {
    const url = APIConstants.DELETE_ACTIVITY_REMINDER.replace(
      '{{id}}',
      activityId,
    );
    return apiClient().delete(url).then(handleResponse).catch(handleError);
  }

  postActivitySchedule(payload) {
    const url = APIConstants.POST_ACTIVITY_SCHEDULE;
    return apiClient()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  }

  postActivityPrint(payload) {
    const url = APIConstants.POST_ACTIVITY_PRINT;
    return apiClient()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  }

  postActivitySubmission(payload) {
    const url = APIConstants.POST_ACTIVITY_SUBMISSION;
    return apiClient()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  }

  postFormActivityPrint(feed_lot_activity_submission_id) {
    const url = APIConstants.POST_FORM_ACTIVITY_PRINT.replace(
      '{{id}}',
      feed_lot_activity_submission_id,
    ).replace('{{languageKey}}', getLanguageKey());
    return apiClient().post(url).then(handleResponse).catch(handleError);
  }

  postActivityAttachment(payload) {
    const url = APIConstants.POST_ACTIVITY_ATTACHMENT;
    return apiClient()
      .put(url, payload)
      .then(handleResponse)
      .catch(handleError);
  }

  removeActivitySubmission(payload) {
    const url = APIConstants.REMOVE_ACTIVITY_SUBMISSION;
    return apiClient()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  }

  syncActivitySubmissions(
    activityNumber = 1,
    instanceName = null,
    lastSynced = new Date(),
  ) {
    let url = APIConstants.SYNC_ACTIVITY_SUBMISSIONS.replace(
      '{{feedlotId}}',
      getSelectedFeedlotId() || 1,
    )
      .replace('{{activityNumber}}', activityNumber)
      .replace(
        '{{lastSyncedTime}}',
        moment(lastSynced || moment().subtract(1, 'minutes')).format(
          DATE_FORMATS.YYYY_MM_DD_HH_mm_ss,
        ),
      );

    if (instanceName) {
      url = url.concat('&instance_value=' + instanceName);
    }
    return apiClient()
      .get(encodeURI(url))
      .then(handleResponse)
      .catch(handleError);
  }
}

export default new ActivityService();
