import { getLocalstorageItem, setLocalstorageItem } from '.';
import LocaleHelper from '../../helpers/LocaleHelper';
import { LOG8, LOCAL_STORAGE_KEYS } from '../../constants/AppConstants';
import LanguageService from '../../services/languageService';

class ActivityManager {
  saveActivities(ActivitiesToSave = []) {
    try {
      let tagsArr =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.TAGS)) || [];
      let activitiesArr =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVITIES)) || [];
      ActivitiesToSave = ActivitiesToSave.filter(
        (q) =>
          // LOG 8 activities are hidden
          !q.activity_number.toLowerCase().includes(LOG8.Log8Constant) &&
          !q.activity_number.toLowerCase().includes(LOG8.Log8Point1Constant) &&
          !q.activity_number.toLowerCase().includes(LOG8.Log8aConstant),
      );
      ActivitiesToSave.map((item) => {
        tagsArr = item.activity_tags
          ? [...tagsArr, ...item.activity_tags]
          : tagsArr;
      });

      tagsArr = tagsArr.filter(
        (v, i, a) => a.findIndex((t) => t.key === v.key) === i,
      );

      for (let i in ActivitiesToSave) {
        let activity = ActivitiesToSave[i];
        activity.isRemoved = false;
        activity.waiting_for_submission =
          activity.waiting_for_submission || false;
        let activityIndex = activitiesArr.findIndex(
          (q) => q.activity_number == activity.activity_number,
        );

        if (activityIndex != -1) {
          activitiesArr[activityIndex] = activity;
        } else {
          activitiesArr.push(activity);
        }
      }

      setLocalstorageItem(LOCAL_STORAGE_KEYS.TAGS, JSON.stringify(tagsArr));
      setLocalstorageItem(
        LOCAL_STORAGE_KEYS.ACTIVITIES,
        JSON.stringify(activitiesArr),
      );
      return true;
    } catch (e) {
      console.log('saveActivitiesfailed', e);
      return false;
    }
  }

  saveDeprecatedActivities(ActivitiesToSave) {
    try {
      let tagsArr =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.TAGS)) || [];
      ActivitiesToSave.map((item) => {
        tagsArr = item.activity_tags
          ? [...tagsArr, ...item.activity_tags]
          : tagsArr;
      });

      tagsArr = tagsArr.filter(
        (v, i, a) => a.findIndex((t) => t.key === v.key) === i,
      );

      setLocalstorageItem(LOCAL_STORAGE_KEYS.TAGS, JSON.stringify(tagsArr));
      let activitiesArr =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVITIES)) || [];
      activitiesArr = activitiesArr.filter((q) => q.status != 5);
      setLocalstorageItem(
        LOCAL_STORAGE_KEYS.ACTIVITIES,
        JSON.stringify([...activitiesArr, ...ActivitiesToSave]),
      );
      return true;
    } catch (e) {
      console.log('saveActivitiesfailed', e);
      return false;
    }
  }

  saveBackDatedActivities(ActivitiesToSave) {
    try {
      let activitiesArr =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVITIES)) || [];

      for (let i in ActivitiesToSave) {
        let activity = ActivitiesToSave[i];
        let activityIndex = activitiesArr.findIndex(
          (q) => q.feedlot_activity_id == activity.feedlot_activity_id,
        );

        if (activityIndex != -1) {
          if (
            activitiesArr[activityIndex] &&
            activitiesArr[activityIndex].activityBackDatedSubmissions
          ) {
            activitiesArr[activityIndex].activityBackDatedSubmissions.custom = {
              backdatedSubmissionCount:
                activity.activityBackDatedSubmissions.custom
                  .backdatedSubmissionCount,
            };
          }
        }
      }

      this.saveActivities(activitiesArr);
      return true;
    } catch (e) {
      console.log('saveActivitiesfailed', e);
      return false;
    }
  }

  getActivities(query = '', filterw = []) {
    let activities =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVITIES)) || [];

    activities = activities
      .filter((q) => q.status == 2 && q.isRemoved === false)
      .filter(
        (q) =>
          q.activity_number.toLowerCase().includes(query.toLowerCase()) ||
          (q.locale[LanguageService()] &&
            q.locale[LanguageService()].title &&
            q.locale[LanguageService()].title
              .toLowerCase()
              .includes(query.toLowerCase())),
      );

    // filter = filter.map(function (obj) {
    //   return obj.value;
    // });

    if (filterw && filterw.length > 0) {
      filterw = filterw.map((q) => q.value);

      if (filterw.findIndex((q) => q === 'All') === -1) {
        for (let i = 0; i < filterw.length; i++) {
          if (filterw[i] === 'No Tag') {
            activities = activities.filter(
              (q) => q.activity_tags && q.activity_tags.length == 0,
            );
          } else {
            activities = activities.filter(
              (q) =>
                q.activity_tags &&
                q.activity_tags.some((r) => filterw.indexOf(r.value) >= 0),
            );
            i = filterw.length;
          }
        }
      }
    }

    activities = activities.sort((a, b) =>
      a.activity_number.localeCompare(b.activity_number),
    );

    return activities;
  }

  removeActivities(idsToRemove) {
    let didRemove = false;

    let activities =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVITIES)) || [];

    for (let i in activities) {
      let activity = activities[i];
      if (
        idsToRemove.findIndex((id) => id === activity.feedlot_activity_id) >
          -1 &&
        activity.isRemoved === false
      ) {
        activity.isRemoved = true;
        didRemove = true;
      }
    }

    setLocalstorageItem(
      LOCAL_STORAGE_KEYS.ACTIVITIES,
      JSON.stringify(activities),
    );
    return didRemove;
  }

  // getActivityByFeedLotActivityId(id) {
  //   let activity = ProgBeefDB.objects(ACTIVITY_SCHEMA_NAME).filtered(
  //     `feedlot_activity_id == '${id}'`,
  //   );
  //   if (activity[0]) {
  //     return activity[0];
  //   }
  //   return {};
  // }

  getDeprecatedActivities() {
    let activities =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVITIES)) || [];

    activities = activities.filter((q) => q.status == 5);

    activities = activities.sort((a, b) =>
      a.activity_number.localeCompare(b.activity_number),
    );

    return activities;
  }

  getTags() {
    let tags = localStorage.getItem(LOCAL_STORAGE_KEYS.TAGS);
    return JSON.parse(tags);
  }

  getBackdatedActivities(type, query = '', filter = []) {
    let activities =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVITIES)) || [];

    activities = activities.filter(
      (q) =>
        q.status == 2 &&
        (q.activity_number.toLowerCase().includes(query.toLowerCase()) ||
          (q.locale[LanguageService()] &&
            q.locale[LanguageService()].title &&
            q.locale[LanguageService()].title
              .toLowerCase()
              .includes(query.toLowerCase()))),
    );

    if (filter && filter.length > 0) {
      filter = filter.map((q) => q.value);

      if (filter.findIndex((q) => q === 'All') === -1) {
        let filteredArray = [];

        for (let i = 0; i < filter.length; i++) {
          let arr = activities;
          if (filter[i] == 'No Tag') {
            arr = arr.filter((q) => q.activity_tags.length == 0);
          } else {
            arr = arr.filter((q) =>
              q.activity_tags.some((r) => filter.indexOf(r.value) >= 0),
            );
            i = filter.length;
          }
          filteredArray = new Set([...filteredArray, ...arr]);
        }
        activities = Array.from(filteredArray);
      }
    }

    if (type) {
      activities = activities.filter(
        (q) =>
          q.activityBackDatedSubmissions[
            LocaleHelper.BACKDATED_PICKER_VALUES()[type].value
          ] &&
          q.activityBackDatedSubmissions[
            LocaleHelper.BACKDATED_PICKER_VALUES()[type].value
          ].backdatedSubmissionCount > 0,
      );
    }

    activities = activities.sort((a, b) =>
      a.activity_number.localeCompare(b.activity_number),
    );

    return activities;
  }

  // getCustomBackdatedActivities(backdatedAct, query = '', filter = []) {
  //   const idsQuery = backdatedAct
  //     .map((q) => q.activity_number)
  //     .map((id) => `activity_number = '${id}'`)
  //     .join(' OR ');

  //   let activities = ProgBeefDB.objects(ACTIVITY_SCHEMA_NAME)
  //     .filtered('status = 2')
  //     .filtered(idsQuery)
  //     .filtered(
  //       'activity_number CONTAINS[c] "' +
  //       query +
  //       '" || localeString CONTAINS[c] "' +
  //       query +
  //       '" ',
  //     );

  //   if (filter.findIndex((q) => q.value === 'All') === -1) {
  //     for (let i = 0; i < filter.length; i++) {
  //       if (filter[i].value === 'No Tag') {
  //         activities = activities.filtered('activity_tags.@size == 0');
  //       } else {
  //         activities = activities.filtered(
  //           filter
  //             .map((item) => 'activity_tags.value = "' + item.value + '"')
  //             .join(' OR '),
  //         );
  //         i = filter.length;
  //       }
  //     }
  //   }

  //   activities = activities.sorted('activity_number', false);
  //   return Array.from(activities);
  // }

  getActivityByActivityNumber(activity_number) {
    let activities =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVITIES)) || [];

    let activity =
      activities &&
      activities.filter(
        (q) => q.activity_number.toLowerCase() == activity_number.toLowerCase(),
      );

    return activity.length > 0 ? activity[0] : null;
  }
}

export default new ActivityManager();
