import { apiClient } from './index';
import { handleResponse, handleError } from './responseHandler';
import APIConstants from '../../constants/APIConstants';
import { getSelectedFeedlotId, getUserId } from '../storageService/GlobalData';

class DashboardService {
  getWorkers() {
    const url = APIConstants.GET_WORKERS.replace(
      '{{id}}',
      getSelectedFeedlotId || 1,
    );

    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  postFeedlotReport(payload) {
    const url = APIConstants.POST_FEEDLOT_REPORT;
    return apiClient()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  }

  getFeedlotStatistics() {
    const url = APIConstants.GET_FEEDLOT_STATS.replace(
      '{{id}}',
      getSelectedFeedlotId || 1,
    );
    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  getBackdatedSubmissions() {
    const url = APIConstants.GET_BACKDATED_SUBMISSION.replace(
      '{{feedlotId}}',
      getSelectedFeedlotId || 1,
    );
    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  getFeedlotCustomPerformance(startDate, endDate) {
    const url = APIConstants.GET_FEEDLOT_CUSTOM_PERFORMANCE.replace(
      '{{id}}',
      getSelectedFeedlotId || 1,
    )
      .replace('{{start_date}}', startDate)
      .replace('{{end_date}}', endDate);
    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  getFeedlotRank() {
    const url = APIConstants.FEEDLOT_RANK.replace(
      '{{id}}',
      getSelectedFeedlotId || 1,
    );

    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  setUserDevice(payload, userId, token) {
    const url = APIConstants.SET_USER_DEVICE.replace('{{id}}', userId);

    return apiClient(token)
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  }

  getBackdatedCustomSubmissions(startDate, endDate) {
    const url = APIConstants.GET_BACKDATED_CUSTOM_SUBMISSIONS.replace(
      '{{feedlotId}}',
      getSelectedFeedlotId || 1,
    )
      .replace('{{start_date}}', startDate)
      .replace('{{end_date}}', endDate);
    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  getBackdatedCustomSubmissionsActivities(startDate, endDate) {
    const url = APIConstants.GET_BACKDATED_CUSTOM_SUBMISSIONS_ACTIVITIES.replace(
      '{{feedlotId}}',
      getSelectedFeedlotId || 1,
    )
      .replace('{{start_date}}', startDate)
      .replace('{{end_date}}', endDate);
    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  getMissedActivities() {
    const url = APIConstants.GET_MISSED_ACTIVITIES.replace(
      '{{feedlotId}}',
      getSelectedFeedlotId || 1,
    ).replace('{{userId}}', getUserId() || 1);
    return apiClient().get(url).then(handleResponse).catch(handleError);
  }
}

export default new DashboardService();
