export default {
  GET_VIDEOS_REQUEST: 'GET_VIDEOS_REQUEST',
  GET_VIDEOS: 'GET_VIDEOS',
  GET_VIDEOS_SUCCESS: 'GET_VIDEOS_SUCCESS',
  GET_VIDEOS_FAILURE: 'GET_VIDEOS_FAILURE',

  SEARCH_VIDEOS_REQUEST: 'SEARCH_VIDEOS_REQUEST',
  SEARCH_VIDEOS: 'SEARCH_VIDEOS',
  SEARCH_VIDEOS_SUCCESS: 'SEARCH_VIDEOS_SUCCESS',

  RESET_VIDEOS_PAYLOAD: 'RESET_VIDEOS_PAYLOAD',
  RESET_VIDEOS_DOWNLOAD: 'RESET_VIDEOS_DOWNLOAD',
  DELETE_VIDEOS: 'DELETE_VIDEOS',

  REMOVE_VIDEO: 'REMOVE_VIDEO',

  DOWNLOAD_VIDEOS_REQUEST: 'DOWNLOAD_VIDEOS_REQUEST',
  DOWNLOAD_VIDEOS: 'DOWNLOAD_VIDEOS',
  DOWNLOAD_VIDEOS_SUCCESS: 'DOWNLOAD_VIDEOS_SUCCESS',
  DOWNLOAD_VIDEOS_FAILURE: 'DOWNLOAD_VIDEOS_FAILURE',
};
