import React, { Component } from 'react';
import { Spinner } from 'reactstrap';

import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import localization from '@progressivebeef/shared/src/localization/i18n';
import PerformanceActivityCard from '../../../../components/PerformanceActivityCard';
import { globalHelper } from '@progressivebeef/shared/src/helpers';

class ListItem extends Component {
  renderSectionHeader = (item) => <div>{item.title}</div>;
  renderItem = (item, section) => {
    let { percentageKey, history } = this.props;

    return (
      <PerformanceActivityCard
        percentageKey={percentageKey}
        history={history}
        item={item}
        section={{ key: section }}
        {...this.props}
      />
    );
  };
  keyExtractor = (item, index) => item.title + index;

  render() {
    let { data, isLoading } = this.props;

    return (
      <div id="#main">
        {data &&
        data.length > 0 &&
        data.map((o) => o.data).flat().length > 0 ? (
          data.map((section, index) => {
            return (
              <div key={index + 400}>
                {section.data.length > 0 && section.title
                  ? React.createElement(
                      'div',
                      {
                        className: 'section-header',
                        key: index + 100,
                      },
                      this.renderSectionHeader(section)
                    )
                  : null}
                {React.createElement(
                  'div',
                  {
                    className: 'section-item-container',
                    key: index + 200,
                  },
                  section.data.map((item, index) =>
                    React.createElement(
                      'div',
                      {
                        className: 'section-item',
                        key: index,
                      },
                      this.renderItem(item, section.key, index)
                    )
                  )
                )}
              </div>
            );
          })
        ) : isLoading ? (
          <div className="bottom-infinite-loader">
            {globalHelper.renderIf(isLoading)(<Spinner color={'red'} />)}
          </div>
        ) : (
          <div className="no-result-found-box">
            <p>{localization[getLocale()].NO_RECORD_FOUND}</p>
          </div>
        )}
      </div>
    );
  }
}

export default ListItem;
