import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Input, InputGroup } from 'reactstrap';
import './style.scss';

import Separator from '../../../../components/Separator';
import BasicDatePicker from '../../../../components/BasicDatePicker';
import RowItem from '../../../../components/RowItem';
import PickerComponent from '../../../../components/Picker';
import DocumentsAssignedList from './DocumentsAssignedList';

//language
import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

import LocaleHelper from '@progressivebeef/shared/src/helpers/LocaleHelper';

class AddEditUserForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showDocumentList: false,
    };

    this.inputs = {};
  }

  render() {
    let { handleChange, handleBlur, values, setFieldValue } = this.props;
    let { showDocumentList } = this.state;

    return (
      <InputGroup className="user-add-form-box">
        {showDocumentList && (
          <DocumentsAssignedList
            modalVisible={showDocumentList}
            dismissModal={() => this.setState({ showDocumentList: false })}
            user={this.props.user}
            {...this.props}
          />
        )}
        <Separator title={localization[getLocale()].PERSONAL_DETAILS} />
        <div className="flex-col">
          <div className="label">{localization[getLocale()].FIRST_NAME}</div>
          <div className="field-box">
            <Input
              onChange={handleChange('first_name')}
              onBlur={handleBlur('first_name')}
              value={values.first_name}
            />
          </div>
        </div>
        <div className="flex-col">
          <div className="label">{localization[getLocale()].LAST_NAME}</div>
          <div className="field-box">
            <Input
              onChange={handleChange('last_name')}
              onBlur={handleBlur('last_name')}
              value={values.last_name}
            />
          </div>
        </div>

        <Separator title={localization[getLocale()].ACCOUNT_DETAIL} />
        <div className="flex-col">
          <div className="label">{localization[getLocale()].USER_NAME}</div>
          <div className="field-box">
            <Input
              disabled={this.props.user.user_name ? true : false}
              onChange={handleChange('user_name')}
              onBlur={handleBlur('user_name')}
              value={values.user_name}
            />
          </div>
        </div>
        <div className="flex-col">
          <div className="label">
            {localization[getLocale()].PASSWORD_LABEL}
          </div>
          <div className="field-box">
            <Input
              onChange={handleChange('password')}
              onBlur={handleBlur('password')}
              value={values.password}
              type="password"
              autoComplete="new-password"
            />
          </div>
        </div>
        <div className="flex-col">
          <div className="label">
            {localization[getLocale()].CONFIRM_PASSWORD}
          </div>
          <div className="field-box">
            <Input
              onChange={handleChange('confirmPassword')}
              onBlur={handleBlur('confirmPassword')}
              value={values.confirmPassword}
              type="password"
            />
          </div>
        </div>
        <div className="flex-col">
          <div className="label">{localization[getLocale()].EMAIL}</div>
          <div className="field-box">
            <Input
              onChange={handleChange('email_address')}
              onBlur={handleBlur('email_address')}
              value={values.email_address}
              type="email"
            />
          </div>
        </div>
        <div className="flex-col">
          <div className="label">{localization[getLocale()].ROLE}</div>
          <div className="field-box">
            <PickerComponent
              selectedValue={values.user_role_key}
              onValueChange={(data) => setFieldValue('user_role_key', data)}
              data={LocaleHelper.USER_ROLES_PICKER_VALUES()}
            />
          </div>
        </div>
        <div className="flex-col">
          <div className="label">{localization[getLocale()].STATUS}</div>
          <div className="field-box">
            <PickerComponent
              selectedValue={values.is_active}
              data={LocaleHelper.USER_STATUS_PICKER_VALUES()}
              onValueChange={(itemValue) => {
                setFieldValue('is_active', itemValue);
              }}
            />
          </div>
        </div>
        <div className="flex-col">
          <div className="label">
            {localization[getLocale()].APP_JOINGING_DATE}
          </div>
          <div className="field-box">
            <BasicDatePicker
              disabled={
                this.props.user && this.props.user.user_id ? true : false
              }
              minimumDate={new Date()}
              value={new Date(values.joining_date)}
              onChange={(event, value) => {
                let date = value ? value : values.joining_date;
                setFieldValue('joining_date', date);
              }}
              onPress={(event, value) => {
                let date = value ? value : values.joining_date;
                setFieldValue('joining_date', date);
              }}
            />
          </div>
        </div>

        <Separator title={localization[getLocale()].DOCUMENTS_ASSIGNED} />
        <RowItem
          icon={true}
          heading={localization[getLocale()].SELECT_DOCUMENTS}
          value={
            values.document_numbers.length > 0
              ? values.document_numbers.length
              : null
          }
          onPress={() => {
            if (
              typeof this.props.user.status === undefined ||
              (this.props.user &&
                this.props.user.status != LocaleHelper.USER_STATUS_PICKER_VALUES()[1].value)
            ) {
              this.setState({
                showDocumentList: true,
              });
            }
          }}
        />
      </InputGroup>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditUserForm);
