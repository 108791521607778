import React, { PureComponent } from 'react';
import AssignLogsListItem from './ListItem';

class AssignLogList extends PureComponent {
  renderSectionHeader = item => <div>{item.title}</div>
  renderItem = (item) => {
    if (!item || !item.profile) {
      return null;
    }
    return (
      <AssignLogsListItem
        key={`${item.user_id}-${item.selected}`}
        item={item}
        updateSearchUsersListItem={this.props.updateSearchUsersListItem}
      />
    );
  }
  keyExtractor = (item, index) => item.title + index;

  render() {
    const { users } = this.props;
    const data = [{ data: users }];
    if (!users || !users.length) {
      return null;
    }

    return (
      data && data[0].data.map((item, index) => {
        if (!item || !item.profile) {
          return null;
        }
        return (
          <AssignLogsListItem
            key={`${item.user_id}-${item.selected}`}
            item={item}
            updateSearchUsersListItem={this.props.updateSearchUsersListItem}
          />
        );
      })
    );
  }
}

export default AssignLogList;
