import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import './style.scss';

import PBIcon from '../../../components/PBIcon';
import AppContainer from '../../../components/AppContainer';
import RankCard from './Cards/rank';
import BackDatedSubmissionsCard from './Cards/BackDatedSubmissionsCard';
import FeedyardPerformanceCard from './Cards/PerformanceCards';
import ExportComplianceCard from './Cards/exportCompliance';
import ActiveWorkerCard from './Cards/activeWorkersCard';
import PendingActionsCard from './Cards/pendingActions';
import ActivitiesDueTodayCard from './Cards/activitiesDueTodayCard';
import RecentlyMissedActivities from './Cards/RecentlyMissedActivities';

import './style.scss';

import { globalHelper } from '@progressivebeef/shared/src/helpers';

import localization from '@progressivebeef/shared/src/localization/i18n';
import {
  getLocale,
  getFirstLogin,
} from '@progressivebeef/shared/src/services/storageService/GlobalData';

import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';
import {
  syncDataBlockingRequest,
  syncDataManuallyRequest,
} from '@progressivebeef/shared/src/store/actions/dataSync';
import { logoutRequested } from '@progressivebeef/shared/src/store/actions/authentication';
import {
  userRoles,
  FEEDYARD_STATUS,
  DATE_FORMATS,
} from '@progressivebeef/shared/src/constants/AppConstants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { showErrorAlert } from '../../../components/Alerts';
import { Spinner } from 'reactstrap';

import { trackEvent } from '@progressivebeef/shared/src/services/reactMixpanelService';
import { Events } from '@progressivebeef/shared/src/constants/MixpanelConstants';
require('moment/locale/es');

class Dashboard extends Component {
  onLogoutTap = () => {
    alert(
      localization[getLocale()].CONFIRMATION.toUpperCase(),
      localization[getLocale()].LOGOUT_CONFIRMATION,
      [
        {
          text: 'No',
          onPress: () => {
            'cancel';
          },
        },
        {
          text: 'Yes',
          onPress: () => {
            this.props.logout();
          },
        },
      ],
    );
  };

  componentDidMount() {
    // this.props.syncDataBlocking();
    if (getFirstLogin()) this.props.history.push(RouteConstants.COACHMARK);
    else if (this.props.isLocked == true)
      this.props.history.push(RouteConstants.LOCK_SCREEN);
  }

  onRefresh = async () => {
    const { isSyncingManually, isAutoSyncing } = this.props;

    if (navigator.onLine) {
      if (!isSyncingManually && !isAutoSyncing) {
        trackEvent(Events.PERFORMED_MANUAL_SYNC);
        this.props.syncDataManually();
      }
    } else {
      showErrorAlert(localization[getLocale()].OFFLINE_MODE_REFRESH_ERROR);
    }
  };

  render() {
    const {
      user,
      lastSynced,
      isSyncing,
      isSyncingManually,
      isAutoSyncing,
      selectedFeedyard,
      history,
      failure,
    } = this.props;
    const feedyardName = selectedFeedyard ? selectedFeedyard.name : null;

    const roleId = Number(user.userRoleId);
    const isWorker = roleId !== userRoles.WORKER;
    const isSyncingInProgress = isSyncing || isSyncingManually || isAutoSyncing;
    const shouldShowComplianceCard =
      roleId === userRoles.FEEDLOT_MANAGER ||
      roleId === userRoles.TRAINER ||
      roleId === userRoles.PROGRAM_MANAGER ||
      roleId === userRoles.SUPERVISOR ||
      roleId === userRoles.INTERNAL_AUDITOR ||
      roleId === userRoles.EXTERNAL_AUDITOR;
    const shouldShowBackdatedCard =
      roleId === userRoles.FEEDLOT_MANAGER ||
      roleId === userRoles.TRAINER ||
      roleId === userRoles.PROGRAM_MANAGER ||
      roleId === userRoles.SUPERVISOR ||
      roleId === userRoles.INTERNAL_AUDITOR ||
      roleId === userRoles.EXTERNAL_AUDITOR;
    const shouldShowRankCard =
      (roleId === userRoles.SUPERVISOR ||
        roleId === userRoles.FEEDLOT_MANAGER ||
        roleId === userRoles.PROGRAM_MANAGER ||
        roleId === userRoles.INTERNAL_AUDITOR ||
        roleId === userRoles.EXTERNAL_AUDITOR) &&
      selectedFeedyard.status === FEEDYARD_STATUS.LIVE_FEEDYARD;

    return (
      <>
        <AppContainer
          title={feedyardName}
          iconHeader
          IconRightHeader={this.renderRightHeader}
          history={history}>
          <div>
            {globalHelper.renderIf(!!isSyncingManually || !!isAutoSyncing)(
              <div className="bottom-infinite-loader">
                <Spinner color={'red'} />
                <div className="bottom-txt">
                  {localization[getLocale()].SYNCING}
                </div>
              </div>,
            )}
            <InfiniteScroll
              dataLength={1}
              hasMore={false}
              refreshFunction={this.onRefresh}
              pullDownToRefresh={!isSyncingManually && !isAutoSyncing}
              pullDownToRefreshThreshold={50}
              pullDownToRefreshContent={
                !isSyncingManually &&
                !isAutoSyncing && (
                  <h3 style={{ textAlign: 'center' }}>
                    &#8595; Pull down to refresh
                  </h3>
                )
              }
              releaseToRefreshContent={
                !isSyncingManually &&
                !isAutoSyncing && (
                  <h3 style={{ textAlign: 'center' }}>
                    &#8593; Release to refresh
                  </h3>
                )
              }>
              <div className="dashboard-main-screen">
                {globalHelper.renderIf(!isSyncingInProgress)(
                  <div className="last-sync-box">
                    <p>
                      {failure
                        ? `${localization[getLocale()].LAST_SYNC_FAILED}:${' '}
                    ${
                      lastSynced &&
                      `${moment(lastSynced)
                        .locale(getLocale())
                        .format(DATE_FORMATS.MMM_DD_YYYY)} - ${moment(
                        lastSynced,
                      )
                        .locale(getLocale())
                        .fromNow()}`
                    }`
                        : `${localization[getLocale()].LAST_SYNCED}:${' '}${
                            lastSynced &&
                            `${moment(lastSynced)
                              .locale(getLocale())
                              .format(DATE_FORMATS.MMM_DD_YYYY)} - ${moment(
                              lastSynced,
                            )
                              .locale(getLocale())
                              .fromNow()}`
                          }`}
                    </p>
                  </div>,
                )}
                {globalHelper.renderIf(shouldShowRankCard)(<RankCard />)}
                <FeedyardPerformanceCard {...this.props} />
                {globalHelper.renderIf(shouldShowBackdatedCard)(
                  <BackDatedSubmissionsCard {...this.props} />,
                )}
                {globalHelper.renderIf(shouldShowComplianceCard)(
                  <ExportComplianceCard {...this.props} />,
                )}

                <div className="dasboard-blue-card-parent">
                  {globalHelper.renderIf(isWorker)(
                    <ActiveWorkerCard {...this.props} />,
                  )}
                  <PendingActionsCard {...this.props} />
                </div>

                <ActivitiesDueTodayCard {...this.props} />
                <RecentlyMissedActivities {...this.props} />
              </div>
            </InfiniteScroll>
          </div>
        </AppContainer>
      </>
    );
  }

  renderRightHeader = () => {
    const { mobileNotificationCount } = this.props;

    return (
      <div className="notigication-bar right-align">
        {mobileNotificationCount > 0 && (
          <div className="num">{mobileNotificationCount}</div>
        )}
        <div className="icon">
          <PBIcon
            onPress={() => this.props.history.push(RouteConstants.NOTIFICATION)}
            name={'bell'}
          />
        </div>
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logoutRequested()),
    syncDataBlocking: (payload) => dispatch(syncDataBlockingRequest(payload)),
    syncDataManually: (payload) => dispatch(syncDataManuallyRequest(payload)),
  };
};

const mapStateToProps = (state) => {
  const { user, selectedFeedyard, isLocked } = state.authentication;
  const { mobileNotificationCount } = state.dashboard;
  const { isSyncing, isSyncingManually, isAutoSyncing, lastSynced, failure } =
    state.dataSync;
  return {
    user,
    selectedFeedyard,
    mobileNotificationCount,
    isSyncing,
    isSyncingManually,
    isAutoSyncing,
    lastSynced,
    failure,
    isLocked,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
