import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from 'reactstrap';
import classnames from 'classnames';

import WorkerItem from './workerItem';
import PBIcon from '../../../../components/PBIcon';
import AppContainer from '../../../../components/AppContainer';

import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import localization from '@progressivebeef/shared/src/localization/i18n';

import { SORTED_ASC } from '@progressivebeef/shared/src/constants/AppConstants';
import {
  sortWorkersAsc,
  sortWorkersDesc,
} from '@progressivebeef/shared/src/store/actions/dashboard';
import { globalHelper } from '@progressivebeef/shared/src/helpers';

class ActiveWorkers extends Component {
  constructor(props) {
    super(props);
    const { workersSorted } = props;
    this.state = {
      activeTab: '1',
      workersSorted,
    };
  }

  componentDidUpdate() {
    const { workersSorted } = this.props;
    if (this.state.workersSorted !== workersSorted) {
      this.setState({ workersSorted });
    }
  }

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          type={'FontAwesome'}
          name={'chevron-left'}
          onPress={() => {
            this.props.history.goBack();
          }}
        />
      </div>
    );
  };

  renderRightHeader = () => {
    const { workersSorted } = this.state;
    const iconName =
      workersSorted === SORTED_ASC
        ? 'sort-numeric-down'
        : 'sort-numeric-down-alt';
    return (
      <div className="head-icon right-align">
        <PBIcon
          name={iconName}
          onPress={() => {
            if (workersSorted === SORTED_ASC) {
              this.props.sortDesc();
            } else {
              this.props.sortAsc();
            }
          }}
        />
      </div>
    );
  };

  toggle = (tab) => {
    this.setState({
      activeTab: tab,
    });
  };

  render() {
    const {
      activeWorkers,
      nonActiveWorkers,
      isWorkersLoading,
      history,
    } = this.props;
    let { activeTab } = this.state;

    return (
      <>
        <AppContainer
          iconHeader
          title={localization[getLocale()].ACTIVITY_WORKERS}
          IconLeftHeader={this.renderLeftHeader}
          IconRightHeader={this.renderRightHeader}
          history={history}
        >
          <div className="tab-screen-parent-container">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    this.toggle('1');
                  }}
                >
                  {localization[getLocale()].ACTIVE}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    this.toggle('2');
                  }}
                >
                  {localization[getLocale()].INACTIVE}
                </NavLink>
              </NavItem>
            </Nav>

            <div className="tab-screen-scrollable-container">
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <div id="#main">
                    {activeWorkers && activeWorkers.length > 0 ? (
                      activeWorkers.map((item) => {
                        return (
                          <WorkerItem
                            {...this.props}
                            key={item.worker_statistics_id.toString()}
                            item={item}
                          />
                        );
                      })
                    ) : isWorkersLoading ? (
                      <div className="bottom-infinite-loader">
                        {globalHelper.renderIf(isWorkersLoading)(
                          <Spinner color={'red'} />
                        )}
                      </div>
                    ) : (
                      <div className="no-result-found-box">
                        <p>{localization[getLocale()].NO_RECORD_FOUND}</p>
                      </div>
                    )}
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div id="#main">
                    {nonActiveWorkers && nonActiveWorkers.length > 0 ? (
                      nonActiveWorkers.map((item) => {
                        return (
                          <WorkerItem
                            {...this.props}
                            key={item.worker_statistics_id.toString()}
                            item={item}
                          />
                        );
                      })
                    ) : isWorkersLoading ? (
                      <div className="bottom-infinite-loader">
                        {globalHelper.renderIf(isWorkersLoading)(
                          <Spinner color={'red'} />
                        )}
                      </div>
                    ) : (
                      <div className="no-result-found-box">
                        <p>{localization[getLocale()].NO_RECORD_FOUND}</p>
                      </div>
                    )}
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </AppContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sortAsc: (payload) => dispatch(sortWorkersAsc(payload)),
    sortDesc: (payload) => dispatch(sortWorkersDesc(payload)),
  };
};

const mapStateToProps = (state) => {
  const {
    isWorkersLoading,
    workersSorted,
    activeWorkers,
    nonActiveWorkers,
  } = state.dashboard;
  return {
    isWorkersLoading,
    workersSorted,
    activeWorkers,
    nonActiveWorkers,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveWorkers);
