import moment from 'moment';
import { getLocalstorageItem, setLocalstorageItem } from '.';

import { auditStatus, LOCAL_STORAGE_KEYS } from '../../constants/AppConstants';
import auditAssignmentModel from '../../models/auditAssignmentModel';
import NotificationManager from './NotificationManager';

class AuditManager {
  /**
   * get audits and sections from api and save to database
   * returns true when successfully saved
   */
  saveAudits(AuditsToSave) {
    try {
      let savedAudits =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUDITS)) || [];

      for (let i = 0; i < AuditsToSave.length; i++) {
        let savedAuditIndex = savedAudits.findIndex(
          (q) => q.audit_assignment_id == AuditsToSave[i].audit_assignment_id,
        );
        if (savedAuditIndex != -1) {
          savedAudits[savedAuditIndex] = auditAssignmentModel(AuditsToSave[i]);
        } else {
          savedAudits.push(auditAssignmentModel(AuditsToSave[i]));
        }
      }

      let nonClosedAudits = savedAudits.filter(
        (a) => a.status != auditStatus.CLOSED,
      );

      let closedAudits = savedAudits.filter(
        (a) => a.status === auditStatus.CLOSED,
      );
      closedAudits = closedAudits
        .sort((a, b) => moment(b.update_date).diff(a.update_date))
        .slice(0, 2);

      //only keep non closed audits and only two closed audits at a time
      savedAudits = [...nonClosedAudits, ...closedAudits];

      setLocalstorageItem(
        LOCAL_STORAGE_KEYS.AUDITS,
        JSON.stringify(savedAudits),
      );

      return true;
    } catch (e) {
      console.log('saveAudits failed', e);
      return false;
    }
  }

  saveAudit(audit_assignment_id, status) {
    try {
      let audits =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUDITS)) || [];

      let audit = audits.find(
        (q) => q.audit_assignment_id == audit_assignment_id,
      );

      if (audit != -1) {
        audit.status = status;
        this.saveAudits([audit]);

        let notificationArr = NotificationManager.getMobileNotifications();
        notificationArr = notificationArr.map((q) => {
          if (q.audit_assignment && q.module_id == audit_assignment_id) {
            q.audit_assignment.status = status;
          }
          return q;
        });

        setLocalstorageItem(
          LOCAL_STORAGE_KEYS.NOTIFICATIONS,
          JSON.stringify(notificationArr),
        );
        return audit;
      }
      return null;
    } catch (e) {
      console.log('saveAudit failed', e);
      return null;
    }
  }

  searchAudits(filterValue, searchText = '') {
    try {
      let filteredAuditAssignments =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUDITS)) || [];

      if (filterValue != 0) {
        filteredAuditAssignments = filteredAuditAssignments.filter(
          (q) => q.status == filterValue,
        );
      }

      if (searchText && searchText != '') {
        filteredAuditAssignments = filteredAuditAssignments.filter((q) =>
          q.audit.audit_number.toLowerCase().includes(searchText.toLowerCase()),
        );
      }

      filteredAuditAssignments = filteredAuditAssignments.sort(
        (a, b) =>
          moment(a.update_date).format('l') < moment(b.update_date).format('l'),
      );
      return filteredAuditAssignments;
    } catch (err) {
      console.log('error returning searched audits', err);
      return [];
    }
  }

  searchAuditNCs(auditAssignmentId, searchText = '') {
    try {
      let audits =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUDITS)) || [];
      let audit = audits.filter(
        (q) => q.audit_assignment_id == auditAssignmentId,
      );

      if (audit.length > 0) {
        let auditNCs = audit[0].non_conformances || [];

        if (searchText && searchText != '') {
          auditNCs = auditNCs.filter(
            (nc) =>
              nc.sectionText.toLowerCase().includes(searchText.toLowerCase()) ||
              nc.questionText.toLowerCase().includes(searchText.toLowerCase()),
          );
        }

        auditNCs = auditNCs.sort((a, b) => a.nc_index > b.nc_index);

        return auditNCs;
      } else {
        return [];
      }
    } catch (err) {
      console.log('error returning searched audit NCs', err);
      return [];
    }
  }

  //#region Comment
  getQuestionComments(
    audit_assignment_id,
    audit_section_id,
    audit_section_question_id,
  ) {
    let questionComments = [];
    try {
      let audits =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUDITS)) || [];
      let auditAssignment = audits.filter(
        (q) => q.audit_assignment_id == audit_assignment_id,
      );

      if (auditAssignment.length > 0) {
        let audit_sections = auditAssignment[0].audit.audit_sections;
        let section = audit_sections.filter(
          (q) => q.audit_section_id == audit_section_id,
        )[0];

        if (section) {
          let section_questions = section.section_questions;
          let question = section_questions.filter(
            (q) => q.audit_section_question_id == audit_section_question_id,
          )[0];

          if (question) {
            questionComments = question.audit_question_comments.filter(
              // only keep feedYard comments (target_type = 3 or type = 3)
              (comment) => comment.target_type === 3 || comment.type === 3,
            );
          }
        }
      }
    } catch (e) {
      console.log('getQuestionComments fail', e);
    } finally {
      return questionComments;
    }
  }

  saveComment(comment, sectionId) {
    try {
      let audits =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUDITS)) || [];
      let auditAssignmentIndex = audits.findIndex(
        (q) => q.audit_assignment_id == comment.audit_assignment_id,
      );

      if (auditAssignmentIndex != -1) {
        let auditAssignment = audits[auditAssignmentIndex];
        let audit_sections = auditAssignment.audit.audit_sections;
        let sectionIndex = audit_sections.findIndex(
          (q) => q.audit_section_id == sectionId,
        );
        //find current question in current section to update
        let question = audit_sections[sectionIndex].section_questions.find(
          (obj) =>
            obj.audit_section_question_id === comment.audit_section_question_id,
        );
        if (question) {
          question.audit_question_comments.push(comment);
          this.saveAudits([auditAssignment]);
        } else {
          return null;
        }
        return comment;
      }
      return null;
    } catch (e) {
      console.log('Error on creation comment', e);
      return null;
    }
  }
  //#endregion

  getAuditById(id) {
    try {
      let audits =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUDITS)) || [];

      let audit = audits.filter((q) => q.audit_assignment_id == id);
      return audit[0];
    } catch (err) {
      console.log('error returning searched audits', err);
      return [];
    }
  }
}

export default new AuditManager();
