import React, { PureComponent } from 'react';
import moment from 'moment';
import { Collapse, CardBody, Card, CardHeader } from 'reactstrap';
import './style.scss'

import PBIcon from '../PBIcon';

import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

import { DATE_FORMATS } from '@progressivebeef/shared/src/constants/AppConstants';
import { globalHelper } from '@progressivebeef/shared/src/helpers';

class ProgramManualDetailAccordion extends PureComponent {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
  }

  // _renderHeader = (expanded) => {
  //   const { programManual } = this.props;
  //   return (
  //     <div>
  //       <p>{programManual.document_number}</p>
  //       {expanded ? (
  //         <PBIcon name="chevron-up" />
  //       ) : (
  //         <PBIcon name="chevron-down" />
  //       )}
  //     </div>
  //   );
  // };

  // _renderContent = () => {
  //   const { programManual } = this.props;
  //   return (
  //     <div>
  //       <p>
  //         {`${localization[getLocale()].REVISION_DATE}: ${moment(
  //           programManual.template_revision_date
  //         ).format(DATE_FORMATS.MM_DD_YY)}`}
  //       </p>
  //       <p>
  //         {`${localization[getLocale()].FEEDYARD_REVISION_DATE}: ${moment(
  //           programManual.revision_date
  //         ).format(DATE_FORMATS.MM_DD_YY)}`}
  //       </p>
  //     </div>
  //   );
  // };

  // render() {
  //   return (
  //     <Accordion
  //       dataArray={dataArray}
  //       animation={true}
  //       expanded={true}
  //       renderHeader={(item, expanded) => this._renderHeader(expanded)}
  //       renderContent={() => this._renderContent()}
  //     />
  //   );
  // }

  toggle(e) {
    this.setState({ collapse: !this.state.collapse });
  }

  render() {
    const { collapse } = this.state;
    const { programManual } = this.props;

    return (
      <div className="activity-schedule-box">
        <Card>
          <CardHeader onClick={this.toggle}>
            <div className="label">
              {programManual.document_number
                ? programManual.document_number
                : ''}
            </div>
            {collapse ? (
              <div className="arrow-icon">
                <PBIcon name="chevron-up" />
              </div>
            ) : (
              <div className="arrow-icon">
                <PBIcon name="chevron-down" />
              </div>
            )}
          </CardHeader>
          <Collapse isOpen={collapse}>
            <CardBody>
              <div className="detail-view-box">

              <div className="rev-date-info">
                  <div className="label">
                    <div>{`${localization[getLocale()].REVISION_DATE}:`}</div>
                    <div>{`${localization[getLocale()].FEEDYARD_REVISION_DATE}:`}</div>
                  </div>
                  <div className="date">
                    <div>{globalHelper.getRevisionDateFormat(programManual.template_revision_date)}</div>
                    <div>{globalHelper.getRevisionDateFormat(programManual.revision_date)}</div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Collapse>
        </Card>
      </div>
    );
  }
}

export default ProgramManualDetailAccordion;
