import React, { Component } from 'react';
import { globalHelper } from '@progressivebeef/shared/src/helpers';

import './style.scss';

class DescriptionView extends Component {
  render() {
    let { title, subTitle, description } = this.props;
    let formattedDesc = description.split('\n');

    return (
      <div className="desc-view-box">
        <div className="row-flex">
          {globalHelper.renderIf(title) && <div className="title">{title}</div>}
          {globalHelper.renderIf(subTitle) && (
            <div className="sub-title">{subTitle}</div>
          )}
        </div>
        {globalHelper.renderIf(formattedDesc) && (
          <div className="desc">
            {formattedDesc.map((desc) => (
              <p style={{marginBottom: 0}}>{desc}</p>
            ))}
          </div>
        )}
      </div>
    );
  }
}
export default DescriptionView;
