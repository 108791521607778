import ACTION_CONSTANTS from '../../constants/actionConstants/index';

export const initialState = {
  isLogin: false,
  isLoading: false,
  loadingText: '',
  user: {},
  selectedFeedyard: null,
  assignedFeedyards: [],
  isFingerPrintEnabled: false,
  isFaceUnlockEnabled: false,
  isFirstLogin: null,
  searchText: '',
  feedyardsLoading: false,
  latestAndroidVersion: null,
  latestiOSVersion: null,
  iOSForceUpdate: false,
  androidForceUpdate: false,
  appUpdated: false,
  locale: 'en',
  isLocked: false,
  emailAlerts: false,
};

export default (state = initialState, action) => {
  // console.log(action);

  switch (action.type) {
    case ACTION_CONSTANTS.RESET_LOCK_SCREEN_PROPS: {
      return {
        ...state,
        success: false,
        error: false,
      };
    }

    //#region GET_INITIALIZED
    case ACTION_CONSTANTS.GET_INITIALIZED:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
        loadingText: 'loading...',
      };

    case ACTION_CONSTANTS.GET_INITIALIZED_SUCCESS:
      return {
        ...state,
        selectedFeedyard: action.payload.selectedFeedyard,
        isLogin: !!(action.payload.user && action.payload.user.token),
        user: action.payload.user,
        emailAlerts: action.payload.emailAlerts == 'true' ? true : false,
        loadingText: null,
        isFaceUnlockEnabled:
          action.payload.isFaceUnlockEnabled == 'true' ? true : false,
        isFingerPrintEnabled:
          action.payload.isFingerPrintEnabled == 'true' ? true : false,
        isFirstLogin: action.payload.isFirstLogin,
        isLoading: false,
        isLocked: action.payload.isLocked,
      };

    case ACTION_CONSTANTS.GET_INITIALIZED_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    //#endregion

    //#region LOGIN
    case ACTION_CONSTANTS.LOGIN_REQUESTED:
      return {
        ...state,
        success: false,
        error: false,
        isLoading: true,
      };

    case ACTION_CONSTANTS.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload.user,
        emailAlerts: action.payload.user.userEmailAlerts,
        isFaceUnlockEnabled:
          action.payload.isFaceUnlockEnabled == 'true' ? true : false,
        isFingerPrintEnabled:
          action.payload.isFingerPrintEnabled == 'true' ? true : false,
        isLogin: true,
      };

    case ACTION_CONSTANTS.LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        loadingText: '',
        error: action.payload.error,
      };
    //#endregion

    //#region TOGGLE_BIOMETRIC_REQUESTED
    case ACTION_CONSTANTS.TOGGLE_BIOMETRIC_REQUESTED:
      return {
        ...state,
        success: false,
        error: false,
        isLoading: true,
      };

    case ACTION_CONSTANTS.TOGGLE_BIOMETRIC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFaceUnlockEnabled:
          action.payload.isFaceUnlockEnabled == 'true' ? true : false,
        isFingerPrintEnabled:
          action.payload.isFingerPrintEnabled == 'true' ? true : false,
      };

    case ACTION_CONSTANTS.TOGGLE_BIOMETRIC_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    //#endregion

    //#region LOGIN_VIA_BIOMETRIC_REQUESTED
    case ACTION_CONSTANTS.LOGIN_VIA_BIOMETRIC_REQUESTED:
      return {
        ...state,
        success: false,
        error: false,
        isLoading: true,
      };

    case ACTION_CONSTANTS.LOGIN_VIA_BIOMETRIC_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ACTION_CONSTANTS.LOGIN_VIA_BIOMETRIC_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    //#endregion

    //#region GET_ASSIGNED_FEEDLOTS
    case ACTION_CONSTANTS.GET_ASSIGNED_FEEDYARDS_REQUESTED:
      return {
        ...state,
        success: false,
        error: false,
        isLoading: true,
        searchText: action.payload.searchText,
      };

    case ACTION_CONSTANTS.GET_ASSIGNED_FEEDYARDS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        assignedFeedyards: action.payload.assignedFeedyards,
      };

    case ACTION_CONSTANTS.GET_ASSIGNED_FEEDYARDS_FAILURE:
      return {
        ...state,
        isLoading: false,
        loadingText: '',
      };
    //#endregion

    //#region GET_ASSIGNED_FEEDLOTS
    case ACTION_CONSTANTS.SELECT_FEEDYARD_REQUESTED:
      return {
        ...state,
        success: false,
        error: false,
        isLoading: true,
      };

    case ACTION_CONSTANTS.SELECT_FEEDYARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedFeedyard: action.payload.selectedFeedyard,
      };

    case ACTION_CONSTANTS.SELECT_FEEDYARD_FAILURE:
      return {
        ...state,
        isLoading: false,
        loadingText: '',
        error: action.payload.error,
      };
    //#endregion

    //#region LOGOUT
    case ACTION_CONSTANTS.LOGOUT_REQUESTED:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
        loadingText: 'Loging out',
        pendingActionsPresent: false,
        appUpdated:
          action.payload && action.payload.appUpdated
            ? action.payload.appUpdated
            : false,
      };

    case ACTION_CONSTANTS.LOGOUT_SUCCESS:
      return {
        ...state,
        user: {},
        isLoading: false,
        selectedFeedyard: null,
        isLogin: false,
        pendingActionsPresent: false,
        appUpdated: false,
      };

    case ACTION_CONSTANTS.LOGOUT_FAILURE:
      return {
        ...state,
        isLoading: false,
        loadingText: '',
        error: action.payload.error,
        pendingActionsPresent: action.payload.pendingActionsPresent || null,
        appUpdated: false,
      };
    //#endregion

    //#region FIRST_LOGIN
    case ACTION_CONSTANTS.SET_FIRST_LOGIN_REQUESTED:
      return Object.assign({}, state, {
        isLoading: true,
      });

    case ACTION_CONSTANTS.SET_FIRST_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isFirstLogin: action.payload.isFirstLogin,
      });

    case ACTION_CONSTANTS.SET_FIRST_LOGIN_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
      });
    //#endregion

    //#region LATEST_ANDROID_VERSION
    case ACTION_CONSTANTS.SET_LATEST_ANDROID_VERSION_REQUESTED:
      return state;

    case ACTION_CONSTANTS.SET_LATEST_ANDROID_VERSION_SUCCESS:
      return {
        ...state,
        latestAndroidVersion: action.payload.version,
      };

    case ACTION_CONSTANTS.SET_LATEST_ANDROID_VERSION_FAILURE:
      return state;
    //#endregion

    //#region LATEST_IOS_VERSION
    case ACTION_CONSTANTS.SET_LATEST_IOS_VERSION_REQUESTED:
      return state;

    case ACTION_CONSTANTS.SET_LATEST_IOS_VERSION_SUCCESS:
      return {
        ...state,
        latestiOSVersion: action.payload.version,
      };

    case ACTION_CONSTANTS.SET_LATEST_IOS_VERSION_FAILURE:
      return state;
    //#endregion

    //#region ANDROID_FORCE_UPDATE
    case ACTION_CONSTANTS.SET_ANDROID_FORCE_UPDATE_REQUESTED:
      return state;

    case ACTION_CONSTANTS.SET_ANDROID_FORCE_UPDATE_SUCCESS:
      return {
        ...state,
        androidForceUpdate: action.payload,
      };

    case ACTION_CONSTANTS.SET_ANDROID_FORCE_UPDATE_FAILURE:
      return state;
    //#endregion

    //#region IOS_FORCE_UPDATE
    case ACTION_CONSTANTS.SET_IOS_FORCE_UPDATE_REQUESTED:
      return state;

    case ACTION_CONSTANTS.SET_IOS_FORCE_UPDATE_SUCCESS:
      return {
        ...state,
        iOSForceUpdate: action.payload,
      };

    case ACTION_CONSTANTS.SET_IOS_FORCE_UPDATE_FAILURE:
      return state;
    //#endregion

    //#region FORGOT_PASSWORD_REQUESTED
    case ACTION_CONSTANTS.FORGOT_PASSWORD_REQUESTED:
      return { ...state, isLoading: true, success: false, error: false };

    case ACTION_CONSTANTS.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: action.payload,
      };

    case ACTION_CONSTANTS.FORGOT_PASSWORD_FAILURE:
      return { ...state, isLoading: false, error: action.payload.error };
    //#endregion

    //#region GENERATE_PIN_REQUESTED
    case ACTION_CONSTANTS.GENERATE_PIN_REQUESTED:
      return { ...state, isLoading: true, success: false, error: false };

    case ACTION_CONSTANTS.GENERATE_PIN_SUCCESS:
      let stateUserObj = state.user;
      stateUserObj['pinCode'] = action.payload.pinCode;
      return {
        ...state,
        isLogin: true,
        isLoading: false,
        user: stateUserObj,
      };

    case ACTION_CONSTANTS.GENERATE_PIN_FAILURE:
      return { ...state, isLoading: false, error: action.payload.error };
    //#endregion

    //#region VALIDATE_PIN_REQUESTED
    case ACTION_CONSTANTS.VALIDATE_PIN_REQUESTED:
      return { ...state, isLoading: true, success: false, error: false };

    case ACTION_CONSTANTS.VALIDATE_PIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        isLocked: false,
      };

    case ACTION_CONSTANTS.VALIDATE_PIN_FAILURE:
      return { ...state, isLoading: false, error: action.payload.error };
    //#endregion

    //#region UNLOCK_APP_VIA_BIOMETRIC_REQUESTED
    case ACTION_CONSTANTS.UNLOCK_APP_VIA_BIOMETRIC_REQUESTED:
      return { ...state, isLoading: true, success: false, error: false };

    case ACTION_CONSTANTS.UNLOCK_APP_VIA_BIOMETRIC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
      };

    case ACTION_CONSTANTS.UNLOCK_APP_VIA_BIOMETRIC_FAILURE:
      return { ...state, isLoading: false, error: action.payload.error };
    //#endregion

    //#region SET_LOCALIZATION
    case ACTION_CONSTANTS.SET_LOCALE_TO_STORAGE:
      return { ...state, isLoading: false, locale: action.payload };
    //#endregion

    //#region SHOW_LOCK_SCREEN
    case ACTION_CONSTANTS.SHOW_LOCK_SCREEN:
      return { ...state, isLocked: true };
    //#endregion

    //#region TOGGLE_EMAIL_ALERTS
    case ACTION_CONSTANTS.TOGGLE_EMAIL_ALERT_REQUESTED:
      return {
        ...state,
        success: false,
        error: false,
        isLoading: true,
      };

    case ACTION_CONSTANTS.TOGGLE_EMAIL_ALERT_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        emailAlerts: action.payload.enable_email_alerts,
      };

    case ACTION_CONSTANTS.TOGGLE_EMAIL_ALERT_FAILED:
      return {
        ...state,
        isLoading: false,
        loadingText: '',
        error: action.payload.error,
      };
    //#endregion

    default:
      return state;
  }
};
