import { LOCAL_STORAGE_KEYS } from '../../constants/AppConstants';
import { getLocalstorageItem, setLocalstorageItem } from '.';

class BackdatedSubmissionsManager {
  //saveActivities
  saveStats(stats) {
    try {
      let oldStats = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVITY_BACKDATED_SUBMISSIONS)
      );
      stats.custom = oldStats ? oldStats.custom : null;
      setLocalstorageItem(
        LOCAL_STORAGE_KEYS.ACTIVITY_BACKDATED_SUBMISSIONS,
        JSON.stringify(stats)
      );
      return true;
    } catch (e) {
      console.log('saveStatsFailed', e);
      return false;
    }
  }

  saveCustomStats(customStats) {
    try {
      let stats = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVITY_BACKDATED_SUBMISSIONS)
      );

      if (stats) {
        stats.custom = customStats.custom_date;
        setLocalstorageItem(
          LOCAL_STORAGE_KEYS.ACTIVITY_BACKDATED_SUBMISSIONS,
          JSON.stringify(stats)
        );
      }

      return true;
    } catch (e) {
      console.log('saveStatsFailed', e);
      return false;
    }
  }

  getStats() {
    let stats = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVITY_BACKDATED_SUBMISSIONS)
    );
    return stats;
  }
}

export default new BackdatedSubmissionsManager();
