import React from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';

//helpers
import { globalHelper } from '@progressivebeef/shared/src/helpers';

//constants
import AssetConstants from '@progressivebeef/shared/src/constants/AssetConstants';
import './style.scss';

const PbLoader = (props) => {
  const { isLoaderLoading, loadingText, isAuthenticating } = props;

  const renderLoader = () => {
    if (isAuthenticating) {
      return (
        <div className="authentication-screen" // src={AssetConstants.pbBg}
        >
          <div className="logo-box">
            <img alt="description" src={AssetConstants.pbLogo} />
          </div>
          <div className="loader-box">
            <div className="spinner-loader"></div>
            {globalHelper.renderIf(loadingText) && <div className="text">{loadingText}</div>}
          </div>
        </div>
      );
    } else {
      return (
        <div className="loading">
          {globalHelper.renderIf(loadingText)(<div>{loadingText}</div>)}
        </div>
      );
    }
  };

  return isLoaderLoading ? renderLoader() : null;
};

const mapStateToProps = (state) => {
  const { isLoaderLoading, loadingText, isAuthenticating } = state.view;

  return {
    isLoaderLoading,
    loadingText,
    isAuthenticating,
  };
};

export default connect(mapStateToProps)(PbLoader);
