import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Spinner, Modal } from 'reactstrap';

import AppContainer from '../../../../../components/AppContainer';
import PBIcon from '../../../../../components/PBIcon';

import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

import { globalHelper } from '@progressivebeef/shared/src/helpers';
import DocumentList from '../../../../../components/DocumentList';

class DocumentsAssignedList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { isLoading, modalVisible } = this.props;
    let { user, history } = this.props;

    return (
      <>
        <Modal className="visible-to-user-popup" isOpen={modalVisible}>
          <AppContainer
            hideBottomBar
            iconHeader
            history={history}
            IconLeftHeader={this.renderLeftHeader}
            noncollapsible
            title={
              user && user.status
                ? localization[getLocale()].ASSIGN_DOCUMENTS
                : localization[getLocale()].SELECT_DOCUMENTS
            }
          >
            {globalHelper.renderIf(isLoading)(
              <div className="bottom-infinite-loader">
                <Spinner color="primary" />
              </div>
            )}

            <div className="add-user-document-listing-parent">
              <DocumentList onSubmit={this.onSubmit} {...this.props} />
            </div>
          </AppContainer>
        </Modal>
      </>
    );
  }

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          name={'chevron-left'}
          onPress={() => this.props.dismissModal()}
        />
      </div>
    );
  };

  onSubmit = (selectedDocuments) => {
    let { setFieldValue } = this.props,
      selectedDocumentNumbers = [];

    selectedDocuments.map((item) => {
      selectedDocumentNumbers.push(item.document_number);
    });

    setFieldValue('document_numbers', selectedDocumentNumbers);
    this.props.dismissModal();
  };
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  const { isLoading, documents, tags } = state.programManuals;

  return {
    isLoading,
    documents,
    tags,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentsAssignedList);
