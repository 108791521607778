import { VIDEO_CATEGORIES } from '../constants/AppConstants';
import _ from 'lodash';

import { getLocale } from '../services/storageService/GlobalData';
import localization from '../localization/i18n';
import navigationVideosModel from '../models/navigationVideosModel';
var RNFS = null;
// import downloadFolder from 'downloads-folder';

if (process.env.REACT_APP_PLATFORM !== 'WEB') {
  import('react-native-fs').then((RNFS) => {
    RNFS = RNFS;
  });
}

class NavigationVideosHelper {
  categorySeparatedModel(data) {
    return [
      {
        title: localization[getLocale()].ACCOUNT,
        key: VIDEO_CATEGORIES.ACCOUNT,
        data: data.filter((q) => q.category_id === VIDEO_CATEGORIES.ACCOUNT),
      },
      {
        title: localization[getLocale()].ACTIVITIES,
        key: VIDEO_CATEGORIES.ACTIVITIES,
        data: data.filter((q) => q.category_id === VIDEO_CATEGORIES.ACTIVITIES),
      },
      {
        title: localization[getLocale()].DASHBOARD,
        key: VIDEO_CATEGORIES.DASHBOARD,
        data: data.filter((q) => q.category_id === VIDEO_CATEGORIES.DASHBOARD),
      },
      {
        title: localization[getLocale()].PROGRAM_MANUAL,
        key: VIDEO_CATEGORIES.PROGRAM_MANUAL,
        data: data.filter(
          (q) => q.category_id === VIDEO_CATEGORIES.PROGRAM_MANUAL,
        ),
      },
      {
        title: localization[getLocale()].AUDITS,
        key: VIDEO_CATEGORIES.AUDITS,
        data: data.filter((q) => q.category_id === VIDEO_CATEGORIES.AUDITS),
      },
      {
        title: localization[getLocale()].USERS,
        key: VIDEO_CATEGORIES.USERS,
        data: data.filter((q) => q.category_id === VIDEO_CATEGORIES.USERS),
      },
    ];
  }

  async isDownloadable(videos) {
    var nVideos = [];
    for (let i = 0; i < videos.length; i++) {
      let item = new navigationVideosModel(videos[i]);
      await RNFS.exists(RNFS.DocumentDirectoryPath + '/' + item.file).then(
        (exists) => {
          if (exists) {
            item.isDownloaded = true;
          } else {
            item.isDownloaded = false;
          }
        },
      );

      nVideos.push(item);
    }

    return nVideos;
  }

  fileExists(url) {
    var http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    return http.status != 404;
  }

  async isWebDownloadable(videos) {
    // var downloadsFolder = require('downloads-folder');
    // console.log('downloadsFolderdownloadsFolder', downloadFolder());
    var nVideos = [];
    for (let i = 0; i < videos.length; i++) {
      let item = navigationVideosModel(videos[i]);
      item.isDownloaded = false;

      nVideos.push(item);
    }

    return nVideos;
  }
}

export default new NavigationVideosHelper();
