import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import AppContainer from '../../../components/AppContainer';
import PBIcon from '../../../components/PBIcon';
import './style.scss';
import {
  filterActivities,
  getActivitiesRequest,
  filterPerformanceActivities,
  getPerformanceActivitiesRequest,
} from '@progressivebeef/shared/src/store/actions/activities';
import MultiSelectList from '../../../components/MultiSelectList';
import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

class Filter extends PureComponent {
  filterPress = (filter) => {
    const { fromPerformance } = this.props.location.state;
    if (fromPerformance) {
      this.props.filterPerformanceActivities(filter);
      this.props.getPerformanceActivities();
    } else {
      this.props.filterActivities(filter);
      this.props.getActivities({ update: false });
    }
    this.props.history.goBack();
  };

  getFilterOptions() {
    const { filter, performanceActivitiesFilter, tags } = this.props;
    let allTags = [
      ...[
        { key: 'All', value: 'All', isSelect: false },
        { key: 'No Tag', value: 'No Tag', isSelect: false },
      ],
      ...tags,
    ];

    let filterOptionKeys = this.props.location.state.filterOptions.map(
      (a) => a.key,
    );
    let tagList =
      allTags.filter((value) => {
        return filterOptionKeys.indexOf(value.key) === -1;
      }) || [];

    let filterOptions = [
      ...this.props.location.state.filterOptions,
      ...tagList,
    ];
    let { fromPerformance } = this.props.location.state;

    return filterOptions.map(function (item) {
      const index = fromPerformance
        ? performanceActivitiesFilter.findIndex((m) => m.key === item.key)
        : filter.findIndex((m) => m.key === item.key);

      if (index === -1) {
        return item;
      } else {
        return fromPerformance
          ? performanceActivitiesFilter[index]
          : filter[index];
      }
    });
  }

  render() {
    let { history } = this.props;
    let filterOptions = this.getFilterOptions();

    return (
      <AppContainer
        title={localization[getLocale()].SELECT_TAGS}
        iconHeader
        history={history}
        IconLeftHeader={this.renderLeftHeader}
        noncollapsible>
        <MultiSelectList
          buttonText={localization[getLocale()].APPLY_TAGS}
          data={filterOptions}
          onPress={this.filterPress}
        />
      </AppContainer>
    );
  }

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          name={'chevron-left'}
          onPress={() => this.props.history.goBack()}
        />
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    filterActivities: (payload) => dispatch(filterActivities(payload)),
    filterPerformanceActivities: (payload) =>
      dispatch(filterPerformanceActivities(payload)),
    getActivities: (payload) => dispatch(getActivitiesRequest(payload)),
    getPerformanceActivities: (payload) =>
      dispatch(getPerformanceActivitiesRequest(payload)),
  };
};

const mapStateToProps = (state) => {
  const { filter, performanceActivitiesFilter, tags } = state.activities;

  return {
    filter,
    tags,
    performanceActivitiesFilter,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
