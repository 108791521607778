import { setLocalstorageItem } from '.';
import { LOCAL_STORAGE_KEYS } from '../../constants/AppConstants';

class InstanceManager {
  saveInstances(InstancesToSave, activityId) {
    try {
      let instances = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.INSTANCES)) || [];

      if (instances) {
        let filtered = instances.filter(
          (q) => q.feedlot_activity_id != activityId
        );
        let instanceArr = [...InstancesToSave, ...filtered];

        setLocalstorageItem(LOCAL_STORAGE_KEYS.INSTANCES, JSON.stringify(instanceArr));
      } else {
        setLocalstorageItem(LOCAL_STORAGE_KEYS.INSTANCES, JSON.stringify(InstancesToSave));
      }

      return true;
    } catch (e) {
      console.log('InstancesToSave Failed', e);
      return false;
    }
  }

  getInstancesByActivityId(activityId) {
    let instances = localStorage.getItem(LOCAL_STORAGE_KEYS.INSTANCES);
    if (instances) {
      instances = JSON.parse(instances);
      let filtered = instances.filter(
        (q) => q.feedlot_activity_id == activityId
      );
      return filtered;
    }

    return [];
  }
}

export default new InstanceManager();
