import React, { Component } from 'react';

import './style.scss';
import AssetConstants from '@progressivebeef/shared/src/constants/AssetConstants';
import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

import AuditService from '@progressivebeef/shared/src/services/api/audits';

import { showAlertMsg } from '../Alerts';
import { Spinner } from 'reactstrap';
import PBIcon from '../PBIcon';

class AttachmentPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchedBase64: false,
      fetchedSource: '',
      isLoading: props.shouldFetch,
      failedToLoad: false,
    };
  }

  componentDidMount() {
    let { data, fileName, shouldFetch } = this.props;

    if (shouldFetch && !data) {
      if (navigator.onLine) {
        AuditService.getCommentAttachment(fileName).then((src) => {
          this.setState({
            isLoading: false,
            fetchedBase64: true,
            fetchedSource: src,
          });
        });
      } else {
        this.setState({ isLoading: false, failedToLoad: true });
        // showAlertMsg("Error", "Cannot load images due to inactive internet connection.")
      }
    }
  }

  render() {
    let { isLoading, fetchedBase64, fetchedSource, failedToLoad } = this.state;
    let { isComment, isPDF, hideClose, source, data } = this.props;

    if (!source && isLoading && !data) return <Spinner color="primary" />;

    let imageSource = !isComment
      ? source
      : failedToLoad || (isLoading && !data)
      ? isPDF
        ? AssetConstants.pdf
        : AssetConstants.png
      : fetchedBase64
      ? fetchedSource
      : `${isPDF ? 'data:application/pdf' : 'data:image/jpeg'};base64,${data}`;

    return (
      <div
        className={`attachment-preview-container ${
          isComment ? 'attachment-preview-container-alt' : ''
        }`}
        onClick={() => {
          if (failedToLoad) {
            showAlertMsg(
              'Error',
              localization[getLocale()].NO_INTERNET_CONNECTION,
            );
            return;
          }
          if (!isComment) this.props.onClick();
          else this.props.onClick(imageSource);
        }}>
        {!hideClose && (
          <div
            className="attachment-delete-button"
            style={{ cursor: 'pointer' }}>
            <PBIcon
              type={'FontAwesome'}
              name={'times-circle'}
              color="#f00000"
              onPress={this.props.onClosePress}
              size={18}
            />
          </div>
        )}
        {isPDF ? (
          <>
            <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
              <PBIcon
                type={'FontAwesome'}
                name={'file-pdf'}
                style={{
                  fontSize: 26,
                  color: failedToLoad ? '#CFD0D4' : '#283040',
                }}
              />
            </div>
            {!isComment && (
              <div className="pdf-filename">{this.props.fileName}</div>
            )}
          </>
        ) : (
          <img
            src={imageSource}
            style={{
              width: '100%',
              height: '100%',
              borderRadius: 10,
            }}
          />
        )}
      </div>
    );
  }
}

export default AttachmentPreview;
