import { getLocale } from '../services/storageService/GlobalData';
import localization from '../localization/i18n';
/**
 * This file contains all the constants used in the code
 */

let Platform = null;
if (process.env.REACT_APP_PLATFORM !== 'WEB') {
  import('react-native').then(({ Platform }) => {
    Platform = Platform;
  });
}

const CLIENT_TYPES = {
  ADMIN_APP: 1,
  NON_AUDIT_APP: 2,
  AUDIT_APP: 3,
};

export const CLIENT_TYPE = CLIENT_TYPES.NON_AUDIT_APP;

export const IDLE_TIME = 900000; //900000 ms = 15 minutes
export const PIN_CODE_LENGTH = 4;
export const IMAGE_FILE_UPLOAD_ALLOWED_TYPES = ['jpeg', 'jpg', 'png'];
export const FINGERPRINT_LABLE_FOR_OS =
  process.env.REACT_APP_PLATFORM !== 'WEB' && Platform.OS === 'ios'
    ? 'Touch ID'
    : 'Fingerprint';
export const FACE_UNLOCK_LABLE_FOR_OS =
  process.env.REACT_APP_PLATFORM !== 'WEB' && Platform.OS === 'ios'
    ? 'Face ID'
    : 'Face-unlock';
export const DECIMAL_PLACES = 2;
export const ATTACHMENT_SIZE_LIMIT = 5242880; //Max size allowed for the attachment that is 5MB
export const MAX_ATTACHMENTS_LENGTH = 3;

export const BOTTOM_BAR_TABS = {
  DASHBOARD: 'DASHBOARD',
  SOP: 'SOP',
  USERS: 'USERS',
  ACTIVITIES: 'ACTIVITIES',
  MORE: 'MORE',
};

export const FEEDYARD_STATUS = {
  TRAINING_FEEDYARD: 0,
  LIVE_FEEDYARD: 1,
};

export const SHOW_INSTRUCTION = {
  HIDE: 0,
  SHOW: 1,
};

export const LOG8 = {
  Log8Constant: 'log 8',
  Log8aConstant: 'log 8a',
  Log8Point1Constant: 'log 8.1',
};

export const AppPagination = {
  RECORD_SIZE: 20,
  START_INDEX: 0,
};

/**
 * User Roles
 */
export const userRoles = {
  PROGRAM_MANAGER: 1,
  TRAINER: 2,
  FEEDLOT_MANAGER: 3,
  SUPERVISOR: 4,
  WORKER: 5,
  INTERNAL_AUDITOR: 6,
  EXTERNAL_AUDITOR: 7,
  INTERNAL_REVIEWER: 8,
  EXTERNAL_REVIEWER: 9,
};

export const rankStatus = {
  NOT_LOADED: 1,
  LOADING: 2,
  LOADED: 3,
};

export const userRolesName = {
  1: 'Program Manager',
  2: 'Trainer',
  3: 'Feedlot Manager',
  4: 'Supervisor',
  5: 'Worker',
  6: 'Internal Auditor',
  7: 'External Auditor',
  8: 'Internal Reviewer',
  9: 'External Reviewer',
};

export const auditorRoles = [
  userRoles.INTERNAL_AUDITOR,
  userRoles.EXTERNAL_AUDITOR,
];

export const mobileAppRoles = [
  userRoles.PROGRAM_MANAGER,
  userRoles.TRAINER,
  userRoles.FEEDLOT_MANAGER,
  userRoles.SUPERVISOR,
];

export const LogoutMode = {
  MANUAL: 'Manual',
  AUTO: 'Auto',
};

export const BiometricLoginMode = {
  MANUAL: 'Manual',
  TOUCH_ID: 'TouchID',
  FACE_ID: 'FaceID',
};

export const BIOMETRIC_ACTION_TYPE = {
  LOGIN: 'Login',
  UNLOCK: 'Unlock',
};

export const AuditType = {
  6: 'INTERNAL',
  7: 'EXTERNAL',
};

export const weekDaysValues = {
  SUNDAY: 1,
  MONDAY: 2,
  TUESDAY: 3,
  WEDNESDAY: 4,
  THURSDAY: 5,
  FRIDAY: 6,
  SATURDAY: 7,
};

export const monthValues = {
  JAN: 1,
  FEB: 2,
  MAR: 3,
  APR: 4,
  MAY: 5,
  JUN: 6,
  JUL: 7,
  AUG: 8,
  SEP: 9,
  OCT: 10,
  NOV: 11,
  DEC: 12,
};

export const monthInitials = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'July',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
};

export const LoginType = {
  PASSWORD: 0,
  PIN: 1,
};

export const auditStatus = {
  DUE_TODAY: 'dueToday',
  DUE_THIS_WEEK: 'dueThisWeek',
  DUE_THIS_MONTH: 'dueThisMonth',
  OVERDUE: 'overdue',
  ASSIGNED: 1,
  PENDING: 2,
  SUBMITTED: 3,
  CLOSED: 4,
  REVIEW_IN_PROGRESS: 5,
  RESPONSE_REQUIRED: 6,
  REVIEWED: 7,
  SUBMITTED_TO_REVIEWER: 8,
  SUBMITTED_TO_AUDITOR: 9,
  WAITING_FOR_REVIEW: 'waitingForReview',
};

export const commentTypes = {
  REVIEWER: 1,
  AUDITOR: 2,
  FEEDYARD: 3,
};

export const commentTargetTypes = {
  REVIEWER: 1,
  AUDITOR: 2,
  FEEDYARD: 3,
};

export const NonConformanceStatus = {
  RESOLVED: 1,
  UNRESOLVED: 0,
};

export const ScrollPositions = {
  DOWN: 'down',
  UP: 'up',
};

export const ACTIVITY_FREQUENCY = {
  EVENT_BASED: 1,
  REQUIRED: 2,
  FEEDLOT_SCHEDULED: 3,
  SPECIAL_LOG: 4,
};

export const ACTIVITY_FREQUENCY_VALUES = {
  1: 'EVENT_BASED',
  2: 'REQUIRED',
  3: 'FEEDLOT_SCHEDULED',
  4: 'SPECIAL_LOG',
};

export const ACTIVITY_FREQUENCY_TYPES = {
  YEARLY: 1,
  QUARTERLY: 2,
  MONTHLY: 3,
  WEEKLY: 4,
  DAILY: 5,
  BIANNUALLY: 8,
  TWELVE_MONTH: 9,
};

export const SHOW_TWELVE_MONTH_ACTIVITY_TIME_DAYS = 60;

/**
 * Activity Permissions
 */
export const ACTIVITY_PERMISSIONS = {
  EVERYONE: 1,
  ASSIGNED_WORKER: 2,
  FEEDLOT_MANAGER: 4,
  SUPERVISOR: 3,
};

/**
 * Activity Type
 */
export const ACTIVITY_TYPE = {
  LOG: 1,
  FORM: 2,
  ATTACHMENT: 3,
};

export const ACTIVITY_TYPE_VALUES = {
  1: 'LOG',
  2: 'FORM',
  3: 'ATTACHMENT',
};

export const ACTIVITY_TYPES = {
  DAILY: 'day',
  WEEKLY: 'week',
  MONTHLY: 'month',
  QUATERLY: 'quater',
  UPCOMMING: 'upcoming',
  UNSCHEDULED: 'unscheduled',
  WAITING_FOR_SUBMISSION: 'waitingForSubmission',
};

/**
 * Activity Frequency Calendar
 */
export const Activity_Frequency_Calendar = {
  YEARLY: 1,
  QUARTERLY: 2,
  MONTHLY: 3,
  WEEKLY: 4,
  DAILY: 5,
};

export const Activity_Schedule_Repeat_Criteria = {
  DAY_OF_THE_MONTH: 0,
  ONE_WEEK: 1,
  TWO_WEEKS: 2,
  THREE_WEEKS: 3,
};

export const MonthOptions = {
  DAY_OF_MONTH: 1,
  EVERY_N_WEEK: 2,
};

export const APP_MODULES = {
  ACTIVITIES: 'ACTIVITIES',
};

export const ENVIRONMENT = {
  PRODUCTION: 'prod',
  STAGING: 'staging',
  QA: 'qa',
  DEV: 'dev',
};

export const DATE_FORMATS = {
  MM_DD_YYYY: 'MM/DD/YYYY',
  MM_DD_YY: 'MM/DD/YY',
  MM_DD: 'MM/DD',
  MMMM_YYYY: 'MMMM, YYYY',
  MMMM_DD_YYYY: 'MMMM DD, YYYY',
  MMM_DD_YYYY: 'MMM DD, YYYY',
  YYYY_MM_DD_HH_mm_ss: 'YYYY-MM-DD HH:mm:ss',
  YYYY_MM_DD: 'YYYY-MM-DD',
  MM_DD_YYYY_LT: 'MM/DD/YYYY LT',
  H_MM_A: 'h:mm A',
  MM_DD_YY_H_MM_A: 'MM/DD/YY h:mm A',
};

export const SUBMISSION_STATUS = {
  REMOVED: 0,
  SUBMITTED: 1,
  PENDING: 2,
  REJECTED: 3,
  MISSED: 4,
};

export const DAYS = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },
  { value: 13, label: '13' },
  { value: 14, label: '14' },
  { value: 15, label: '15' },
  { value: 16, label: '16' },
  { value: 17, label: '17' },
  { value: 18, label: '18' },
  { value: 19, label: '19' },
  { value: 20, label: '20' },
  { value: 21, label: '21' },
  { value: 22, label: '22' },
  { value: 23, label: '23' },
  { value: 24, label: '24' },
  { value: 25, label: '25' },
  { value: 26, label: '26' },
  { value: 27, label: '27' },
  { value: 28, label: '28' },
  { value: 29, label: '29' },
  { value: 30, label: '30' },
];

export const SORTED_ASC = 1;
export const SORTED_DESC = 0;

export const MODULE_NAMES = {
  NAVIGATION_VIDEOS_MODULE: 'NavigationVideoModule',
  ACTIVITY_MODULE: 'ActivityModule',
  AUDIT_MODULE: 'AuditModule',
  NOTIFICATION_MODULE: 'NotificationModule',
  UPDATES_MODULE: 'UpdatesModule',
  SUBMISSION_MODULE: 'SubmissionModule',
  DATA_SYNC_MODULE: 'DataSyncModule',
  PROGRAM_MANUAL_MODULE: 'ProgramManualModule',
  USERS_MODULE: 'UsersModule',
};

export const PUSH_NOTIFICATION_TYPES = {
  GROUP: 'GROUP',
  MISSED_REMINDER: 'MISSED_REMINDER',
  SUBMISSION_VIOLATION: 'SUBMISSION_VIOLATION',
};

export const USER_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
  LOCKED: 3,
};

export const BACKDATED_SUBMISSIONS_FILTER = {
  YESTERDAY: 'yesterday',
  THIS_WEEK: 'thisWeek',
  LAST_WEEK: 'lastWeek',
  THIS_MONTH: 'thisMonth',
  LAST_MONTH: 'lastMonth',
  THIS_YEAR: 'thisYear',
  CUSTOM: 'custom',
};

export const BACKDATED_SUBMISSIONS_FILTER_INDEX = {
  yesterday: '0',
  thisWeek: '1',
  lastWeek: '2',
  thisMonth: '3',
  lastMonth: '4',
  thisYear: '5',
  custom: '6',
};

export const BACKDATED_SUBMISSIONS_FILTER_KEYS = {
  0: 'yesterday',
  1: 'thisWeek',
  2: 'lastWeek',
  3: 'thisMonth',
  4: 'lastMonth',
  5: 'thisYear',
  6: 'custom',
};

/**
 * email validation Regex
 */
export const emailRegex =
  /^\w+([\.-]?\w+)+@\w+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/;

/**
 * special characters validation Regex
 */
// export const specialCharactersRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
export const specialCharactersRegex = /[\/\\:*?"<>|&]+/;
export const userNameRegex = /^[a-zA-Z0-9.\-_]{3,45}$/;
export const documentTitleRegex = /[\/\\:*?"<>|]+/;

/**
 * Performance Dial keys
 */
export const PERFORMANCE_DATA_KEYS = {
  daily: () => localization[getLocale()]._DAILY,
  thisWeek: () => localization[getLocale()].THIS_WEEK,
  weekly: () => localization[getLocale()]._WEEKLY,
  thisMonth: () => localization[getLocale()].THIS_MONTH,
  monthly: () => localization[getLocale()]._MONTHLY,
  cumulative: () => localization[getLocale()].CURRENT_YEAR,
};

export const PERFORMANCE_DATA_KEYS_SORTING_ENUM = {
  daily: 0,
  thisWeek: 1,
  weekly: 2,
  thisMonth: 3,
  monthly: 4,
  cumulative: 5,
};

export const PERFORMANCE_DATA_KEYS_MAP = {
  daily: 'daily',
  thisWeek: 'thisWeek',
  weekly: 'weekly',
  thisMonth: 'thisMonth',
  monthly: 'monthly',
  cumulative: 'cumulative',
};

export const PERFORMANCE_KEY_CUSTOM = 'custom';

/**
 * Activity Status
 */
export const ACTIVITY_STATUS = {
  DEPRECATED: 5,
};

export const DOCUMENT_TYPE_KEY = {
  TRAINING: 5,
};

export const DOCUMENT_STATUS = {
  VIEWED: 1,
  UNVIEWED: 0,
};

export const PLATFORM = {
  IOS: 'ios',
  ANDROID: 'android',
  WEB: 'web',
};

export const TAB_ALL = 0;

export const NOTIFICATION_EVENTS = {
  AUDIT_SUBMITTED: 'AUDIT_SUBMITTED',
  DOCUMENTS_READY: 'DOCUMENTS_READY',
  ACTIVITY_DUE_TODAY: 'ACTIVITY_DUE_TODAY',
  ACTIVITY_LOG8A_DUE_TODAY: 'ACTIVITY_LOG8A_DUE_TODAY',
  ACTIVITY_LOG8_POINT_ONE_DUE_TODAY: 'ACTIVITY_LOG8_POINT_ONE_DUE_TODAY',
  ACTIVITY_MISSED_RECENTLY: 'ACTIVITY_MISSED_RECENTLY',
  ACTIVITY_REMINDER: 'ACTIVITY_REMINDER',
  ACTIVITY_SUBMISSION_VIOLATION: 'ACTIVITY_SUBMISSION_VIOLATION',
  DOCUMENT_FEEDYARD_REVISION_UPDATE: 'DOCUMENT_FEEDYARD_REVISION_UPDATE',
  ACTIVITY_FEEDYARD_REVISION_UPDATE: 'ACTIVITY_FEEDYARD_REVISION_UPDATE',
  ACTIVITY_INSTANCE_ASSIGNED: 'ACTIVITY_INSTANCE_ASSIGNED',
  ACTIVITY_ASSIGNED: 'ACTIVITY_ASSIGNED',
  TRAINING_DUE_TODAY: 'TRAINING_DUE_TODAY',
  TRAINING_MISSED: 'TRAINING_MISSED',
  TRAINING_VIEWED: 'TRAINING_VIEWED',
  ACTIVITY_LOG8A_FILLED: 'ACTIVITY_LOG8A_FILLED',
  ACTIVITY_LOG8_MARKED_COMPLETED: 'ACTIVITY_LOG8_MARKED_COMPLETED',
  ACTIVITY_REMOVED: 'ACTIVITY_REMOVED',
  NEW_COMMENT_ADDED: 'NEW_COMMENT_ADDED',
  AUDIT_UNASSIGNED: 'AUDIT_UNASSIGNED',
};

export const NOTIFICATION_METADATA = {
  reminderIconName: 'stopwatch',
  reminderIconColor: '#fa6400',
  reminderTitleColor: '#fa6400',

  dueTodayIconName: 'clock',
  dueTodayIconColor: '#fa6400',
  dueTodayTitleColor: '#fa6400',

  submissionVoilationIconColor: '#ff0000',
  submissionVoilationTitleColor: '#ff0000',
  submissionVoilationIconName: 'exclamation-circle',

  documentsReadyIconColor: '#0079a4',
  documentsReadyTitleColor: '#0079a4',
  documentsReadyIconName: 'file-pdf',

  trainingDueTodayIconColor: '#0079a4',
  trainingDueTodayTitleColor: '#0079a4',
  trainingDueTodayIconName: 'file-pdf',

  trainingViewedIconColor: '#0079a4',
  trainingViewedTitleColor: '#0079a4',
  trainingViewedIconName: 'file-pdf',

  activityInstanceAssignedIconColor: '#0079a4',
  activityInstanceAssignedTitleColor: '#333333',
  activityInstanceAssignedIconName: 'file-check',

  activityLog8FilledIconName: 'info-circle',
  activityLog8FilledIconColor: '#0079a4',
  activityLog8FilledTitleColor: '#333333',

  activityLog8CompletedIconName: 'clipboard-list-check',
  activityLog8CompletedIconColor: '#cccccc',
  activityLog8CompletedTitleColor: '#333333',

  activityRevisionUpdatedIconColor: '#0079a4',
  activityRevisionUpdatedTitleColor: '#0079a4',
  activityRevisionUpdatedIconName: 'info-circle',

  auditSubmittedIconColor: '#0079a4',
  auditSubmittedTitleColor: '#0079a4',
  auditSubmittedIconName: 'file-pdf',

  commentAddedIconColor: '#0079a4',
  commentAddedTitleColor: '#0079a4',
  commentAddedIconName: 'comment',
};

export const NOTIFICATION_UPDATE_EVENTS = {
  ACTIVITY_SUBMISSION_ADDED: 'ACTIVITY_SUBMISSION_ADDED',
  ACTIVITY_ASSIGNED_UPDATE: 'ACTIVITY_ASSIGNED_UPDATE',
  ACTIVITY_SUBMISSION_REMOVED: 'ACTIVITY_SUBMISSION_REMOVED',
  ACTIVITY_INSTANCE_ASSIGNED_UPDATE: 'ACTIVITY_INSTANCE_ASSIGNED_UPDATE',
};

export const KEYBOARD_TYPES = {
  NUMBER: 'number-pad',
  DECIMAL: 'decimal-pad',
  DEFAULT: 'default',
};

export const VIDEO_CATEGORIES = {
  ACCOUNT: 1,
  DASHBOARD: 2,
  PROGRAM_MANUAL: 3,
  USERS: 4,
  ACTIVITIES: 5,
  AUDITS: 6,
};

export const FEEDLOT_RANK = {
  COUNT: 'rank_count',
  POSITION: 'rank_position',
  PERFORMANCE: 'rank_performance',
};

export const ACTIVITY_CUSTOM_IDS = 'activity_custom_ids';

export const CUSTOM_PERFORMANCE = {
  PERFORMANCE: 'custom_performance',
  START_DATE: 'custom_start_date',
  END_DATE: 'custom_end_date',
  ACTIVITIES: 'custom_activities',
};

export const CUSTOM_BACKDATED = {
  START_DATE: 'backdated_start_date',
  END_DATE: 'backdated_end_date',
};

export const AUDIT_REPORT_FILENAME = 'pdf';
/**
 * base64 validation Regex
 */
export const base64Regex =
  /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;
/*
 * ACTIVE WORKER Keys
 */
export const USER_PERFORMANCE = {
  ACTIVE_TODAY: 3,
  NOT_ACTIVE_TODAY: 4,
};

export const APPLICATION_TYPES = {
  SENCHA: 'Mobile Sencha App',
  REACT_NATIVE: 'Mobile React Native App',
  WEB_RN: 'Web React JS App',
  WEB_SENCHA: 'Web Sencha App',
};

export const LOCAL_STORAGE_KEYS = {
  USER: 'user',
  ACCESS_TOKEN: 'token',
  CONFIGURATION: 'configuration',
  LAST_SYNC: 'last_sync',
  LAST_SYNC_UI: 'last_sync_ui',
  LAST_SYNC_SCHEDULED_TASK: 'last_sync_scheduled_task',
  LAST_SYNC_ADHOC_TASK: 'last_sync_adhoc_task',
  LAST_SYNC_TASK_HISTORY: 'last_sync_task_history',
  LAST_SYNC_LOCATION: 'last_sync_location',
  LAST_SYNC_WORKORDER: 'last_sync_workorder',
  PIN_CODE: 'pinCode',
  SELECTED_FEEDYARD: 'selectedFeedyard',
  IS_FIRST_LOGIN: 'isFirstLogin',
  TAGS: 'tags',
  ACTIVITIES: 'activities',
  ACTIVITY_BACKDATED_SUBMISSIONS: 'ActivityBackDatedSubmissions',
  USERS: 'users',
  INSTANCES: 'instances',
  META_DATA: 'metadata',
  MISSED_ACTIVITIES_NOTIFICATION: 'MissedActivitiesNotification',
  PROGRAM_MANUALS: 'programManuals',
  PROGRAM_MANUAL_TAGS: 'programManualTags',
  SUBMISSIONS: 'submissions',
  WORKER: 'Worker',
  LAST_NOTIFCATION_SYNC_TIME: 'lastNotifcationSyncTime',
  NOTIFICATION_COUNT: 'notificationCount',
  AUDITS: 'audits',
  NOTIFICATIONS: 'notifications',
  NOTIFICATIONS_UPDATE: 'notificationUpdates',
  NAVIGATION_VIDEOS: 'navigationVideos',
  IS_LOCKED: 'isLocked',
  APP_TRAINING_VIDEO_HTML: 'appTrainingVideoHTML',
};

export const PROGRAM_MANUAL_STATUS = {
  ACTIVE: 2,
  BIN: 6,
};

export const DEVICE_TYPES = {
  TABLET: 'Tablet',
  HANDSET: 'Handet',
};

export const NON_CONFORMANCE_STATUS = {
  RESOLVED: 1,
  UNRESOLVED: 0,
};

export const IS_CORRECTIVE_ACTIONS_REQUIRED = {
  YES: '1',
  NO: '0',
  NO_SET_BY_USER: '2',
};
