import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from 'reactstrap';
import classnames from 'classnames';
import printJS from 'print-js';

import AppContainer from '../../../../components/AppContainer';
import PDFViewer from '../../../../components/PDFViewer';
import PBIcon from '../../../../components/PBIcon';
import ProgramManualPickerModal from '../../../../components/ProgramManualPickerModal';
import URLCard from '../../../../components/URLCard';
import {
  showSuccessAlert,
  showErrorAlert,
} from '../../../../components/Alerts';

import localization from '@progressivebeef/shared/src/localization/i18n';
import {
  getLocale,
  getToken,
  getSelectedFeedlotId,
} from '@progressivebeef/shared/src/services/storageService/GlobalData';

import { baseUrl } from '@progressivebeef/shared/src/services/api';
import DocumentHelper from '@progressivebeef/shared/src/helpers/DocumentHelper';
import { globalHelper } from '@progressivebeef/shared/src/helpers';

import APIConstants from '@progressivebeef/shared/src/constants/APIConstants';
import {
  getProgramManualByIdRequest,
  deleteProgramManualUrlRequest,
  updateProgramManualUrlRequest,
  markViewedRequest,
  resetErrorProps,
  resetProgramManualDetail,
} from '@progressivebeef/shared/src/store/actions/programMaunals';
import { userRoles } from '@progressivebeef/shared/src/constants/AppConstants';
import {
  showLoader,
  hideLoader,
} from '@progressivebeef/shared/src/store/actions/view';

import { trackEvent } from '@progressivebeef/shared/src/services/reactMixpanelService';
import {
  Events,
  Properties,
} from '@progressivebeef/shared/src/constants/MixpanelConstants';

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

class ViewDocument extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      urls: [],
      urlText: {
        text: '',
        previous: '',
      },
      markBtnVisible: true,
      activeTab: '1',
      englishFileData: null,
      spanishFileData: null,
      isPDFLoading: false,
      loadingError: false,
    };
  }

  componentWillUnmount() {
    this.props.resetProgramManualDetail();
  }

  componentDidMount() {
    const { currentProgramManual } = this.props.location.state;
    this.props.getProgramManualById({
      document_number: currentProgramManual.document_number,
      document_id: currentProgramManual.document_id,
    });

    let properties = {};
    properties[Properties.DOCUMENT_NUMBER] =
      currentProgramManual.document_number;
    trackEvent(Events.VIEWED_PROGRAM_MANUAL, properties);
  }

  componentDidUpdate(prevProps) {
    let { loading, showLoaderView, hideLoaderView, programManual, isViewed } =
      this.props;
    let { englishFileData, spanishFileData, isPDFLoading, loadingError } =
      this.state;

    if (loading) {
      showLoaderView();
    } else {
      hideLoaderView();
    }

    if (programManual !== prevProps.programManual) {
      this.setState({ urls: Array.from(programManual.document_urls || {}) });
    }

    if (isViewed) {
      this.setState({ markBtnVisible: false });
      showSuccessAlert(localization[getLocale()].TRAINING_VIEWED);
      this.props.getProgramManualById({
        document_number: programManual.document_number,
        document_id: programManual.document_id,
      });
      this.props.resetErrorProps();
    }

    if (programManual.view_preference === 2 && !loadingError) {
      if (!isPDFLoading && !englishFileData && !spanishFileData) {
        this.setState({
          isPDFLoading: true,
        });
      }
      if (isPDFLoading) {
        this.getProgramManualPdf();
      }
    }
  }

  getProgramManualPdf = async () => {
    try {
      const { currentProgramManual } = this.props.location.state;

      const token = getToken();

      const englishSource = {
        uri: encodeURI(
          `${baseUrl}${APIConstants.GET_DOCUMENT}`
            .replace('{{feedlot_id}}', getSelectedFeedlotId())
            .replace(
              '{{document_number}}',
              currentProgramManual.document_number,
            )
            .replace('{{language_key}}', 1),
        ),
        headers: { token },
      };

      const spanishSource = {
        uri: encodeURI(
          `${baseUrl}${APIConstants.GET_DOCUMENT}`
            .replace('{{feedlot_id}}', getSelectedFeedlotId())
            .replace(
              '{{document_number}}',
              currentProgramManual.document_number,
            )
            .replace('{{language_key}}', 2),
        ),
        headers: { token },
      };

      let englishFileData = await globalHelper.getFileFromServer(
        englishSource.uri,
        englishSource.headers,
      );
      let spanishFileData = await globalHelper.getFileFromServer(
        spanishSource.uri,
        spanishSource.headers,
      );

      this.setState({
        englishFileData,
        spanishFileData,
        isPDFLoading: false,
        loadingError: false,
      });
    } catch (e) {
      this.setState({
        isPDFLoading: false,
        loadingError: true,
      });
      showErrorAlert(e);
    }
  };

  toggle(tab) {
    this.setState({
      activeTab: tab,
    });
  }

  onPickerPress = () => {
    this.setState({ modalVisible: false });
  };

  onMorePress = () => {
    this.setState({ modalVisible: true });
  };

  onUrlCancelPress = (text) => {
    const { currentProgramManual } = this.props.location.state;
    let selected = this.state.urls.find((item) => item.url === text);

    let document = {
      feedlot_document: {
        feedlot_document_id: currentProgramManual.feedlot_document_id,
        document_number: currentProgramManual.document_number,
        document_urls: [
          {
            feedlot_document_url_id: selected.feedlot_document_url_id,
          },
        ],
      },
    };

    this.props.deleteProgramManualUrl(document);
    let filteredArray = this.state.urls.filter((item) => item.url !== text);
    this.setState({ urls: filteredArray });
  };

  onUrlEditPress = (text) => {
    let selected = this.state.urls.find((item) => item.url === text);

    this.setState({
      urlText: {
        text: selected.url,
        previous: selected.url,
      },
    });
  };

  addUrl = () => {
    const { urlText } = this.state;
    const { programManual } = this.props;
    let selected =
      urlText.previous !== ''
        ? this.state.urls.find((item) => item.url === urlText.previous)
        : null;
    let filteredArray =
      urlText.previous !== ''
        ? this.state.urls.filter((item) => item.url !== urlText.previous)
        : this.state.urls;

    if (urlText.text === '') {
      showErrorAlert(localization[getLocale()].NO_URL_FOUND);
    } else if (DocumentHelper.validURL(urlText.text)) {
      this.setState(
        {
          urls: [
            ...filteredArray,
            {
              url: urlText.text,
              feedlot_document_id: programManual.feedlot_document_id,
              feedlot_document_url_id: selected
                ? selected.feedlot_document_url_id
                : 0,
            },
          ],
          urlText: {
            text: '',
            previous: '',
          },
        },
        () => {
          let document = {
            feedlot_document: {
              feedlot_document_id: programManual.feedlot_document_id,
              document_urls: [
                ...filteredArray,
                {
                  url: urlText.text,
                  feedlot_document_id: programManual.feedlot_document_id,
                  feedlot_document_url_id: selected
                    ? selected.feedlot_document_url_id
                    : null,
                },
              ],
            },
          };

          this.props.updateProgramManualUrl(document);
        },
      );
    } else {
      showErrorAlert(localization[getLocale()].INVALID_URL);
    }
  };

  setURLText = (value) => {
    this.setState({
      urlText: {
        ...this.state.urlText,
        text: value,
      },
    });
  };

  markViewed = () => {
    const { programManual } = this.props;
    this.props.markViewed({
      documentNumber: programManual.document_number,
      feedlotDocumentId: programManual.feedlot_document_id,
    });
  };

  renderHTML = () => {
    const { programManual } = this.props;

    let programManualHTML =
      programManual.document_locale[getLocale() === 'en' ? 1 : 2].body;
    programManualHTML = htmlToReactParser.parse(programManualHTML);
    return programManualHTML;
  };

  render() {
    const {
      modalVisible,
      activeTab,
      englishFileData,
      spanishFileData,
      isPDFLoading,
    } = this.state;

    const { programManual, history } = this.props;
    const englishPdf = `data:application/pdf;base64,${englishFileData}`;
    const spanishPdf = `data:application/pdf;base64,${spanishFileData}`;

    return (
      <AppContainer
        title={programManual.document_number?.replace('m-', '')}
        iconHeader
        IconLeftHeader={this.renderLeftHeader}
        IconRightHeader={this.renderRightHeader}
        noncollapsible
        history={history}>
        <ProgramManualPickerModal
          item={programManual}
          view
          history={history}
          modalVisible={modalVisible}
          onPressEvent={this.onPickerPress}
        />

        <div className="tab-screen-parent-container">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => {
                  this.toggle('1');
                }}>
                {localization[getLocale()].ENGLISH}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  this.toggle('2');
                }}>
                {localization[getLocale()].SPANISH}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '3' })}
                onClick={() => {
                  this.toggle('3');
                }}>
                {localization[getLocale()].URL}
              </NavLink>
            </NavItem>
          </Nav>

          <div className="tab-screen-scrollable-container pos-relative">
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <div id="#main">
                  {programManual.view_preference === 1 ? (
                    <div id="programManualHTML-en">{this.renderHTML()}</div>
                  ) : programManual.view_preference === 2 ? (
                    isPDFLoading ? (
                      <div className="bottom-infinite-loader">
                        <Spinner color={'red'} />
                      </div>
                    ) : (
                      <>
                        <div style={{ width: '100%', height: '100vh' }}>
                          {englishFileData ? (
                            // <PDFViewer
                            //   iden="english"
                            //   source={{ base64: englishFileData }}
                            // />
                            <iframe
                              src={englishPdf}
                              width="100%"
                              height="100%"
                              title="PDF Viewer"
                            />
                          ) : (
                            <h1>NOMmi</h1>
                          )}
                        </div>
                      </>
                    )
                  ) : null}
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div id="#main">
                  {programManual.view_preference === 1 ? (
                    <div id="programManualHTML-es">{this.renderHTML()}</div>
                  ) : programManual.view_preference === 2 ? (
                    isPDFLoading ? (
                      <div className="bottom-infinite-loader">
                        <Spinner color={'red'} />
                      </div>
                    ) : (
                      <>
                        <div style={{ width: '100%', height: '100vh' }}>
                          {spanishFileData ? (
                            // <PDFViewer
                            //   iden="english"
                            //   source={{ base64: spanishFileData }}
                            // />
                            <iframe
                              src={spanishPdf}
                              width="100%"
                              height="100%"
                              title="PDF Viewer"
                            />
                          ) : (
                            <h1>NOMmi</h1>
                          )}
                        </div>
                      </>
                    )
                  ) : null}
                </div>
              </TabPane>
              <TabPane tabId="3">
                <div id="#main">
                  {this.state.urls.length > 0 ? (
                    this.state.urls.map((q) => {
                      return (
                        <URLCard
                          view
                          key={'url-' + q.feedlot_document_url_id}
                          onCancelPress={this.onUrlCancelPress}
                          onEditPress={this.onUrlEditPress}
                          text={q.url}
                          hideControls
                        />
                      );
                    })
                  ) : (
                    <div className="no-result-found-box">
                      <p>{localization[getLocale()].EMPTY_URL_LIST_MSG}</p>
                    </div>
                  )}
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </AppContainer>
    );
  }

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          name={'chevron-left'}
          onPress={() => this.props.history.goBack()}
        />
      </div>
    );
  };

  renderRightHeader = () => {
    const { user } = this.props;
    const { activeTab } = this.state;

    return Number(user.userRoleId) === userRoles.FEEDLOT_MANAGER ||
      Number(user.userRoleId) === userRoles.TRAINER ||
      Number(user.userRoleId) === userRoles.PROGRAM_MANAGER ? (
      <div className="head-icon right-align">
        {activeTab != 3 ? (
          <PBIcon onPress={() => this.onPrint()} name={'print'} />
        ) : (
          <div />
        )}
      </div>
    ) : null;
  };

  onPrint = () => {
    const { programManual } = this.props;
    const { activeTab, englishFileData, spanishFileData } = this.state;

    let properties = {};
    properties[Properties.DOCUMENT_NUMBER] = programManual.document_number;
    trackEvent(Events.PRINTED_PROGRAM_MANUAL, properties);

    if (programManual.view_preference === 1) {
      printJS({
        printable: 'programManualHTML' + getLocale(),
        type: 'html',
      });
    } else {
      printJS({
        printable: activeTab == 1 ? englishFileData : spanishFileData,
        type: 'pdf',
        base64: true,
      });
    }
  };

  onMorePress = () => {
    this.setState({ modalVisible: true });
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    markViewed: (payload) => dispatch(markViewedRequest(payload)),
    resetErrorProps: (payload) => dispatch(resetErrorProps(payload)),
    getProgramManualById: (payload) =>
      dispatch(getProgramManualByIdRequest(payload)),
    deleteProgramManualUrl: (payload) =>
      dispatch(deleteProgramManualUrlRequest(payload)),
    updateProgramManualUrl: (payload) =>
      dispatch(updateProgramManualUrlRequest(payload)),
    resetProgramManualDetail: (payload) =>
      dispatch(resetProgramManualDetail(payload)),
    showLoaderView: (payload) => dispatch(showLoader(payload)),
    hideLoaderView: (payload) => dispatch(hideLoader(payload)),
  };
};

const mapStateToProps = (state) => {
  const { programManual, isDetailLoading, isViewed } = state.programManuals;
  const { user } = state.authentication;

  return {
    programManual,
    loading: isDetailLoading,
    isViewed,
    user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewDocument);
