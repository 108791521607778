export default {
  GET_USERS: 'GET_USERS',
  GET_USERS_REQUEST: 'GET_USERS_REQUEST',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_FAILURE: 'GET_USERS_FAILURE',

  SEARCH_USERS_REQUEST: 'SEARCH_USERS_REQUEST',
  SEARCH_USERS_SUCCESS: 'SEARCH_USERS_SUCCESS',
  SEARCH_USERS_FAILURE: 'SEARCH_USERS_FAILURE',

  ADD_USER_REQUEST: 'ADD_USER_REQUEST',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  ADD_USER_FAILURE: 'ADD_USER_FAILURE',

  GET_ASSIGN_LOGS_USERS_LIST_REQUEST: 'GET_ASSIGN_LOGS_USERS_LIST_REQUEST',
  GET_ASSIGN_LOGS_USERS_LIST_SUCCESS: 'GET_ASSIGN_LOGS_USERS_LIST_SUCCESS',
  GET_ASSIGN_LOGS_USERS_LIST_FAILURE: 'GET_ASSIGN_LOGS_USERS_LIST_FAILURE',

  SEARCH_ASSIGN_LOGS_USERS_LIST_STORE: 'SEARCH_ASSIGN_LOGS_USERS_LIST_STORE',
  SEARCH_ASSIGN_LOGS_USERS_LIST_SUCCESS:
    'SEARCH_ASSIGN_LOGS_USERS_LIST_SUCCESS',

  RESET_ASSIGN_LOG_SEARCH: 'RESET_ASSIGN_LOG_SEARCH',

  UPDATE_SEARCH_ASSIGN_LOG_USERS: 'UPDATE_SEARCH_ASSIGN_LOG_USERS',

  CHANGE_PIN_REQUEST: 'CHANGE_PIN_REQUEST',
  CHANGE_PIN_SUCCESS: 'CHANGE_PIN_SUCCESS',
  CHANGE_PIN_FAILURE: 'CHANGE_PIN_FAILURE',

  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

  UNLOCK_USER_REQUEST: 'UNLOCK_USER_REQUEST',
  UNLOCK_USER_SUCCESS: 'UNLOCK_USER_SUCCESS',
  UNLOCK_USER_FAILURE: 'UNLOCK_USER_FAILURE',

  RESET_USER_PROPS: 'RESET_USER_PROPS',
};
