import React, { Component } from 'react';
import _ from 'lodash';

//service
// import SentryService from '../../../../../services/sentry';

import Label from './label';
import ImageIcon from './image';
import Preview from '../../../../../components/Preview';
import { showAlertMsg, showErrorAlert } from '../../../../../components/Alerts';

import { globalHelper } from '@progressivebeef/shared/src/helpers';
import { baseUrl } from '@progressivebeef/shared/src/services/api';
import getLanguageKey from '@progressivebeef/shared/src/services/languageService/index';
import localization from '@progressivebeef/shared/src/localization/i18n';
import {
  getLocale,
  getToken,
} from '@progressivebeef/shared/src/services/storageService/GlobalData';
import AssetConstants from '@progressivebeef/shared/src/constants/AssetConstants';
import {
  ACTIVITY_TYPE,
  ATTACHMENT_SIZE_LIMIT,
} from '@progressivebeef/shared/src/constants/AppConstants';
import { ATTACHMENT_TYPES } from '@progressivebeef/shared/src/constants/FormConstants';
import APIConstants from '@progressivebeef/shared/src/constants/APIConstants';
import { base64Regex } from '@progressivebeef/shared/src/constants/AppConstants';
import { Input } from 'reactstrap';
import Compressor from 'compressorjs';
import ActivitiesHelper from '@progressivebeef/shared/src/helpers/ActivitiesHelper';

export default class AttachmentField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      previewVisible: false,
    };
  }

  getCountCondition = () => {
    const { count } = this.state;
    return this.props.activityType === ACTIVITY_TYPE.ATTACHMENT
      ? count < 1
      : count < 3;
  };

  openPicker = async (event) => {
    const condition = this.getCountCondition();
    try {
      if (condition) {
        const file = event.target.files[0];

        await this.validateAttachment(file);
      }
    } catch (err) {}
  };

  setFilePath(filePath) {
    this.filePath = filePath;
  }

  compress(file) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        async success(result) {
          const data = await globalHelper.getBase64FileWeb(result);
          resolve(data);
        },
        error: reject,
      });
    });
  }

  validateAttachment = async (file) => {
    try {
      const { count } = this.state;
      if (file.size > ATTACHMENT_SIZE_LIMIT) {
        showErrorAlert(localization[getLocale()].ATTACHMENT_SIZE_ERROR);
      } else {
        this.setState({ count: count + 1 });
        let type = '';
        if (file.type.includes(ATTACHMENT_TYPES.PNG)) {
          type = ATTACHMENT_TYPES.PNG;
          this.compress(file).then((result) => {
            this.props.push({ uri: result, type: type });
          });
        } else if (file.type.includes(ATTACHMENT_TYPES.JPEG)) {
          type = ATTACHMENT_TYPES.JPEG;
          this.compress(file).then((result) => {
            this.props.push({ uri: result, type: type });
          });
        } else if (file.type.includes(ATTACHMENT_TYPES.PDF_WEB)) {
          type = ATTACHMENT_TYPES.PDF;
          const data = await globalHelper.getBase64FileWeb(file);
          this.props.push({ uri: data, type: type });
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  removeFile = (file) => {
    const { form, name, remove } = this.props;
    const index = _.findIndex(form.values[name], ['uri', file.uri]);
    remove(index);
    this.setState({ count: this.state.count - 1 });
  };

  preview = async (file) => {
    let image = {};
    if (file.name) {
      const token = getToken();
      image = {
        uri: `${baseUrl}${APIConstants.GET_ACTIVITY_ATTACHMENT}`.replace(
          '{{fileName}}',
          file.name,
        ),
        headers: { token },
      };
    } else {
      if (file.type === ATTACHMENT_TYPES.PDF) {
        image = file.uri;
      } else {
        image = file.uri;
      }
    }
    this.navigateToPreview(image, file.type);
  };

  navigateToPreview = (source, type) => {
    this.setState({ source, type, previewVisible: true });
  };

  getIcons = () => {
    const { form, name, disabled } = this.props;
    if (form.values[name]) {
      return form.values[name].map((file, index) => {
        let source = '';
        if (file.type === ATTACHMENT_TYPES.PNG) {
          source = AssetConstants.png;
        } else if (
          file.type === ATTACHMENT_TYPES.JPEG ||
          file.type === ATTACHMENT_TYPES.JPG
        ) {
          source = AssetConstants.jpg;
        } else if (
          file.type === ATTACHMENT_TYPES.PDF_WEB ||
          file.type === ATTACHMENT_TYPES.PDF
        ) {
          source = AssetConstants.pdf;
        }
        return (
          <ImageIcon
            key={'Attachment-' + name + '-' + index}
            source={source}
            disabled={disabled}
            removeFile={() => this.removeFile(file)}
            preview={() => this.preview(file)}
          />
        );
      });
    }
  };

  render() {
    const { data, disabled, form, name } = this.props;
    const { previewVisible, type, source } = this.state;
    const locale = data.label.locale[getLanguageKey()];
    return (
      <>
        {previewVisible && (
          <Preview
            modalVisible={previewVisible}
            rightHeader
            title={
              type === ATTACHMENT_TYPES.PDF
                ? localization[getLocale()].PDF_PREVIEW
                : localization[getLocale()].IMAGE_PREVIEW
            }
            pdfSource={type === ATTACHMENT_TYPES.PDF ? source : null}
            imageSource={type !== ATTACHMENT_TYPES.PDF ? source : null}
            onRightIconPress={() => this.setState({ previewVisible: false })}
            type={type}
          />
        )}
        <div className="add-submission-attachment-box">
          <div className="field-box">
            {this.props.activityType === ACTIVITY_TYPE.ATTACHMENT && (
              <div className="label">
                {`${localization[getLocale()].ATTACHMENT}`}
              </div>
            )}
            {this.props.activityType !== ACTIVITY_TYPE.ATTACHMENT && (
              <Label locale={locale} isFlex />
            )}
            <div className="field">
              {this.getCountCondition() && !disabled && (
                <Input
                  type="file"
                  id="myfile"
                  name="file"
                  onChange={this.openPicker}
                  accept="application/pdf, image/png, image/jpeg">
                  {localization[getLocale()].PLEASE_SELECT}
                </Input>
              )}
            </div>
          </div>
          {this.getCountCondition() && !disabled && (
            <div>
              <div className="file-instruction-info">
                {ActivitiesHelper.shouldShowInstruction(locale) && (
                  <div className="instruction-label">{locale.instruction}</div>
                )}
                {`${localization[getLocale()].ATTACHMENT_INFO_MESSAGE}`}
              </div>
            </div>
          )}
          <div className="attach-icons">
            {form.values[name] &&
              form.values[name].length > 0 &&
              this.getIcons()}
          </div>
        </div>
      </>
    );
  }
}
