import ACTION_CONSTANTS from '../../constants/actionConstants';

export const initialState = {
  isHTMLSyncing: false,
  isLoading: false,
  appTrainingVideoHTML: null,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_CONSTANTS.GET_APP_TRAINING_VIDEO_HTML: {
      const update = action.payload.update;
      const obj = {
        ...state,
        success: false,
        error: false,
        isHTMLSyncing: update,
      };
      // update loader only for api call
      if (update) {
        obj.isLoading = true;
      }
      return obj;
    }
    case ACTION_CONSTANTS.GET_APP_TRAINING_VIDEO_HTML_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isHTMLSyncing: false,
        appTrainingVideoHTML: action.payload.appTrainingVideoHtmlObj,
      };
    }

    case ACTION_CONSTANTS.GET_APP_TRAINING_VIDEO_HTML_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isHTMLSyncing: false,
      };
    }

    case ACTION_CONSTANTS.RESET_APP_TRAINING_VIDEO_STATE: {
      return {
        ...state,
        isLoading: false,
        error: false,
        isHTMLSyncing: false,
        appTrainingVideoHTML: null,
      };
    }
    default:
      return state;
  }
};
