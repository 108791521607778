import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import AlertModal from '../AlertModal';

export const showAlertMsg = (alertTitle, alertDesc, buttons) => {
  AlertModal.alert(alertTitle, alertDesc, buttons, { cancelable: false });
};

export const showSuccessAlert = (msg, buttons) => {
  showAlertMsg(localization[getLocale()].SUCCESS.toUpperCase(), msg, buttons);
};

export const showErrorAlert = (msg, buttons) => {
  showAlertMsg(localization[getLocale()].ERROR.toUpperCase(), msg, buttons);
};

export const showOfflineAlert = () => {
  showErrorAlert(localization[getLocale()].NO_INTERNET_CONNECTION, [
    {
      text: localization[getLocale()].OK,
      onPress: () => { },
      style: 'cancel',
    },
  ]);
};
