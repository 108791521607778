import React, { PureComponent } from 'react';
import PBIcon from '../PBIcon';
import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import ActivityTag from '../ActivityTag';
import { ACTIVITY_FREQUENCY } from '@progressivebeef/shared/src/constants/AppConstants';
import ActivitiesHelper from '@progressivebeef/shared/src/helpers/ActivitiesHelper';
import { Collapse, CardBody, Card, CardHeader } from 'reactstrap';

import './style.scss';
import { globalHelper } from '@progressivebeef/shared/src/helpers';

class ActivityDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
  }

  toggle(e) {
    this.setState({ collapse: !this.state.collapse });
  }

  render() {
    const { collapse } = this.state;
    const { activity } = this.props;
    const users = activity.users ? activity.users : [];

    return (
      <div className="activity-schedule-box">
        <Card>
          <CardHeader onClick={this.toggle}>
            <div className="label">
          {activity.locale
                  ? activity.locale[getLocale() === 'en' ? 1 : 2].title
                  : ''}
            </div>
            {collapse ? (
              <div className="arrow-icon">
                <PBIcon name="chevron-up" />
                </div>
            ) : (
               <div className="arrow-icon">
            <PBIcon name="chevron-down" />
                </div>
              )}</CardHeader>
          <Collapse isOpen={collapse}>
            <CardBody>
              <div className="activity-schedule-info-box">
                {activity.frequency_key === ACTIVITY_FREQUENCY.EVENT_BASED ? null : (
                  <div className="main-head">{`${
                    activity.schedule
                      ? activity.schedule.submission_number
                        ? activity.schedule.submission_number
                        : 1
                      : 1
                    } ${
                    localization[getLocale()].SUBMISSIONS_PER
                    } ${ActivitiesHelper.getFrequency(activity.schedule)}`}</div>
                )}
                <div className="rev-date-info">
                  <div className="label">
                    <div >{`${
                      localization[getLocale()].REVISION_DATE
                      }: `}</div>
                    <div  >{`${
                      localization[getLocale()].FEEDYARD_REVISION_DATE
                      }: `}</div>
                  </div>
                  <div className="date">
                    <div>
                      {globalHelper.getRevisionDateFormat(activity.template_revision_date)}
                    </div>
                    <div>
                      {globalHelper.getRevisionDateFormat(activity.revision_date)}
                    </div>
                  </div>
                </div>
                <div className="main-head">
                  {`${localization[getLocale()].REQUIREMENTS}:`}
                </div>
                <div className="detail-txt">
                  {activity.locale
                    ? activity.locale[getLocale() === 'en' ? 1 : 2].requirement
                    : ''}
                </div>
                <div className="main-head">{`${
                  localization[getLocale()].ASSIGNED_TO
                  }: `}</div>
                {users && users.length > 0 ? (
                  <div className="detail-txt">
                    {users.map(
                      (q, index) =>
                        (index ? ', ' : '') +
                        q.profile?.first_name +
                        ' ' +
                        q.profile?.last_name,
                    )}
                  </div>
                ) : (
                  <div className="main-head">{localization[getLocale()].NONE}</div>
                  )}
                {activity.activity_tags &&
                  Object.keys(activity.activity_tags).length !== 0 && (
                    <>
                      <div className="main-head">
                        {`${localization[getLocale()].ACTIVITY_TAGS}: `}
                      </div>
                      <div className="activity-tag-box">
                        <ActivityTag
                          full
                          tags={activity.activity_tags}
                        />
                      </div>
                    </>
                  )}
              </div>
            </CardBody>
          </Collapse>
        </Card>
      </div>
    );
  }
}

export default ActivityDetail;
