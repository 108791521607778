export default {
  //#region ===== WORKER ACTIONS =====
  GET_WORKERS_REQUEST: 'GET_WORKERS_REQUEST',
  GET_WORKERS: 'GET_WORKERS',
  GET_WORKERS_SUCCESS: 'GET_WORKERS_SUCCESS',
  GET_WORKERS_FAILURE: 'GET_WORKERS_FAILURE',

  SORT_WORKERS_ASC: 'SORT_WORKERS_ASC',
  SORT_WORKERS_DESC: 'SORT_WORKERS_DESC',
  //#endregion ===== WORKER ACTIONS =====

  //#region ===== FEEDLOT REPORT ACTIONS =====
  POST_FEEDLOT_REPORT_REQUEST: 'POST_FEEDLOT_REPORT_REQUEST',
  POST_FEEDLOT_REPORT_SUCCESS: 'POST_FEEDLOT_REPORT_SUCCESS',
  POST_FEEDLOT_REPORT_FAILURE: 'POST_FEEDLOT_REPORT_FAILURE',
  //#endregion ===== FEEDLOT REPORT ACTIONS =====

  //#region ===== FEEDLOT STATS ACTIONS =====
  GET_FEEDLOT_STATS_REQUEST: 'GET_FEEDLOT_STATS_REQUEST',
  GET_FEEDLOT_STATS: 'GET_FEEDLOT_STATS',
  GET_FEEDLOT_STATS_SUCCESS: 'GET_FEEDLOT_STATS_SUCCESS',
  GET_FEEDLOT_STATS_FAILURE: 'GET_FEEDLOT_STATS_FAILURE',
  //#endregion ===== FEEDLOT STATS ACTIONS =====

  //#region ===== BACKDATED SUBMISSIONS ACTIONS =====
  GET_BACKDATED_SUBMISSIONS_REQUEST: 'GET_BACKDATED_SUBMISSIONS_REQUEST',
  GET_BACKDATED_SUBMISSIONS: 'GET_BACKDATED_SUBMISSIONS',
  GET_BACKDATED_SUBMISSIONS_SUCCESS: 'GET_BACKDATED_SUBMISSIONS_SUCCESS',
  GET_BACKDATED_SUBMISSIONS_FAILURE: 'GET_BACKDATED_SUBMISSIONS_FAILURE',
  //#endregion ===== BACKDATED SUBMISSIONS ACTIONS =====

  //#region ===== FEEDLOT CUSTOM PERFORMANCE ACTIONS =====
  GET_FEEDLOT_CUSTOM_PERFORMANCE_REQUEST:
    'GET_FEEDLOT_CUSTOM_PERFORMANCE_REQUEST',
  GET_FEEDLOT_CUSTOM_PERFORMANCE_SUCCESS:
    'GET_FEEDLOT_CUSTOM_PERFORMANCE_SUCCESS',
  GET_FEEDLOT_CUSTOM_PERFORMANCE_FAILURE:
    'GET_FEEDLOT_CUSTOM_PERFORMANCE_FAILURE',
  //#endregion ===== FEEDLOT CUSTOM PERFORMANCE ACTIONS =====

  GET_CUSTOM_BACKDATED_DURATION_SUCCESS:
    'GET_CUSTOM_BACKDATED_DURATION_SUCCESS',

  //#region ===== FEEDLOT RANK ACTIONS =====
  GET_FEEDLOT_RANK_REQUEST: 'GET_FEEDLOT_RANK_REQUEST',
  GET_FEEDLOT_RANK_SUCCESS: 'GET_FEEDLOT_RANK_SUCCESS',
  GET_FEEDLOT_RANK_FAILURE: 'GET_FEEDLOT_RANK_FAILURE',
  //#endregion ===== FEEDLOT RANK ACTIONS =====

  HIDE_RANK_CARD: 'HIDE_RANK_CARD',
  RESET_RANK_CARD: 'RESET_RANK_CARD',

  //#region ===== BACKDATED CUSTOM SUBMISSIONS ACTIONS =====
  GET_BACKDATED_CUSTOM_SUBMISSIONS_REQUEST:
    'GET_BACKDATED_CUSTOM_SUBMISSIONS_REQUEST',
  GET_BACKDATED_CUSTOM_SUBMISSIONS: 'GET_BACKDATED_CUSTOM_SUBMISSIONS',
  GET_BACKDATED_CUSTOM_SUBMISSIONS_SUCCESS:
    'GET_BACKDATED_CUSTOM_SUBMISSIONS_SUCCESS',
  GET_BACKDATED_CUSTOM_SUBMISSIONS_FAILURE:
    'GET_BACKDATED_CUSTOM_SUBMISSIONS_FAILURE',
  GET_BACKDATED_CUSTOM_SUBMISSIONS_ACTIVITIES_SUCCESS:
    'GET_BACKDATED_CUSTOM_SUBMISSIONS_ACTIVITIES_SUCCESS',
  //#endregion ===== BACKDATED CUSTOM SUBMISSIONS ACTIONS =====

  //#region ===== MOBILE NOTIFICATION ACTIONS =====
  RESET_MOBILE_NOTIFICATION: 'RESET_MOBILE_NOTIFICATION',
  SET_MOBILE_NOTIFICATION_COUNT: 'SET_MOBILE_NOTIFICATION_COUNT',
  GET_MOBILE_NOTIFICATION_REQUEST: 'GET_MOBILE_NOTIFICATION_REQUEST',
  GET_MOBILE_NOTIFICATION: 'GET_MOBILE_NOTIFICATION',
  GET_MOBILE_NOTIFICATION_LOAD_MORE: 'GET_MOBILE_NOTIFICATION_LOAD_MORE',
  GET_MOBILE_NOTIFICATION_SUCCESS: 'GET_MOBILE_NOTIFICATION_SUCCESS',
  GET_MOBILE_NOTIFICATION_FAILURE: 'GET_MOBILE_NOTIFICATION_FAILURE',
  //#endregion ===== MOBILE NOTIFICATION ACTIONS =====

  //#region ===== MOBILE UPDATE ACTIONS =====
  RESET_MOBILE_UPDATE: 'RESET_MOBILE_UPDATE',
  GET_MOBILE_UPDATE_REQUEST: 'GET_MOBILE_UPDATE_REQUEST',
  GET_MOBILE_UPDATE: 'GET_MOBILE_UPDATE',
  GET_MOBILE_UPDATE_LOAD_MORE: 'GET_MOBILE_UPDATE_LOAD_MORE',
  GET_MOBILE_UPDATE_SUCCESS: 'GET_MOBILE_UPDATE_SUCCESS',
  GET_MOBILE_UPDATE_FAILURE: 'GET_MOBILE_UPDATE_FAILURE',
  //#endregion ===== MOBILE UPDATE ACTIONS =====

  GET_MISSED_ACTIVITIES: 'GET_MISSED_ACTIVITIES',
  GET_MISSED_ACTIVITIES_REQUEST: 'GET_MISSED_ACTIVITIES_REQUEST',
  GET_MISSED_ACTIVITIES_SUCCESS: 'GET_MISSED_ACTIVITIES_SUCCESS',
  GET_MISSED_ACTIVITIES_FAILURE: 'GET_MISSED_ACTIVITIES_FAILURE',

  GET_PENDING_ACTIONS_COUNT: 'GET_PENDING_ACTIONS_COUNT',
  GET_PENDING_ACTIONS_COUNT_REQUEST: 'GET_PENDING_ACTIONS_COUNT_REQUEST',
  GET_PENDING_ACTIONS_COUNT_SUCCESS: 'GET_PENDING_ACTIONS_COUNT_SUCCESS',
  GET_PENDING_ACTIONS_COUNT_FAILURE: 'GET_PENDING_ACTIONS_COUNT_FAILURE',

  GET_SEARCHED_PENDING_ACTIONS_REQUEST: 'GET_SEARCHED_PENDING_ACTIONS_REQUEST',
  GET_SEARCHED_PENDING_ACTIONS_SUCCESS: 'GET_SEARCHED_PENDING_ACTIONS_SUCCESS',
  GET_SEARCHED_PENDING_ACTIONS_FAILURE: 'GET_SEARCHED_PENDING_ACTIONS_FAILURE',

  RESET_BACKDATED_PROPS: 'RESET_BACKDATED_PROPS',
  SET_SERVER_ERROR: 'SET_SERVER_ERROR',
};
