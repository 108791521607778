import React from 'react';
import Label from './label';
import getLanguageKey from '@progressivebeef/shared/src/services/languageService';
import { Input } from 'reactstrap';
import { KEYBOARD_TYPES } from '@progressivebeef/shared/src/constants/AppConstants';
import { globalHelper } from '@progressivebeef/shared/src/helpers';
import ActivitiesHelper from '@progressivebeef/shared/src/helpers/ActivitiesHelper';

export default ({
  data,
  field,
  disabled,
  keyboardType,
  violated,
  placeholder,
  form: { handleChange, handleBlur, setFieldValue },
}) => {
  const locale = data.label.locale[getLanguageKey()];
  const isFlex =
    keyboardType === KEYBOARD_TYPES.NUMBER ||
    keyboardType === KEYBOARD_TYPES.DECIMAL;
  return (
    <>
      <div
        className={`add-submission-view-info-box${
          keyboardType === KEYBOARD_TYPES.NUMBER ||
          keyboardType === KEYBOARD_TYPES.DECIMAL
            ? ' col-flex'
            : ''
        }${violated ? ' violated' : ''}`}>
        <Label
          fullWidth={keyboardType ? false : true}
          locale={locale}
          violated={violated}
          isFlex={isFlex}
        />

        <div className="field-box">
          <Input
            icon
            disabled={disabled}
            pattern="^\d*(\.\d{0,2})?$"
            onChange={(e) => {
              if (keyboardType) {
                let value =
                  e.target.value === '' || e.target.value === null
                    ? 0
                    : e.target.value;
                const isValid =
                  keyboardType === KEYBOARD_TYPES.DECIMAL
                    ? globalHelper.handlePointValidation(value)
                    : globalHelper.handleIntValidation(value);

                if (isValid === true) {
                  setFieldValue &&
                    setFieldValue(data.group_id.toString(), e.target.value);
                }
              } else {
                setFieldValue(data.group_id.toString(), e.target.value);
              }
            }}
            value={field.value}
            textAlign={keyboardType ? 'right' : 'left'}
            type={
              keyboardType === KEYBOARD_TYPES.NUMBER ||
              keyboardType === KEYBOARD_TYPES.DECIMAL
                ? 'number'
                : 'textarea'
            }
            violated={violated}
            placeholder={placeholder}
            isMultiline={keyboardType ? false : true}
          />
        </div>
      </div>
      {isFlex && ActivitiesHelper.shouldShowInstruction(locale) && (
        <div className="instruction-label add-submission-view-info-box-instruction instruction-fix-margin">
          {locale.instruction}
        </div>
      )}
    </>
  );
};
