import React, { useRef } from 'react';

import AssetConstants from '@progressivebeef/shared/src/constants/AssetConstants';
import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import {
  ATTACHMENT_SIZE_LIMIT,
  MAX_ATTACHMENTS_LENGTH,
} from '@progressivebeef/shared/src/constants/AppConstants';
import { showAlertMsg, showErrorAlert } from '../Alerts';

const FilePicker = ({ addImage, addFile, attachmentsCount }) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (attachmentsCount >= MAX_ATTACHMENTS_LENGTH) {
      showAlertMsg(
        localization[getLocale()].INFO.toUpperCase(),
        localization[getLocale()].ATTACHMENT_LIMIT_REACHED,
      );
    } else {
      fileInputRef.current.value = null;
      fileInputRef.current.click();
    }
  };

  const handleFileSelected = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > ATTACHMENT_SIZE_LIMIT)
        showErrorAlert(localization[getLocale()].ATTACHMENT_SIZE_ERROR);
      else {
        const reader = new FileReader();
        const isPDF = file.type.split('/')[1].endsWith('pdf');

        reader.onloadend = () => {
          const data = reader.result.split(',')[1];
          if (isPDF) addFile({ name: file.name, base64: data });
          else addImage({ name: file.name, base64: data });
        };
        reader.readAsDataURL(file);
      }
    }
  };

  return (
    <div
      className="file-picker"
      style={{ cursor: 'pointer' }}
      onClick={handleButtonClick}>
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: 'none' }}
        accept="application/pdf, image/png, image/jpeg"
        onChange={handleFileSelected}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginLeft: 12,
        }}>
        <div className="icon-paperclip" style={{ marginTop: 3.5 }}>
          <img
            src={AssetConstants.attachment}
            style={{ width: '30px !important' }}
          />
        </div>
        <div
          className="txt"
          style={{
            fontSize: 10,
            color: '#32076e',
            marginTop: 2,
            textTransform: 'uppercase',
          }}>
          {localization[getLocale()].ATTACH}
        </div>
      </div>
    </div>
  );
};

export default FilePicker;
