import React, { PureComponent } from 'react';
import PBIcon from '../PBIcon';
import './style.scss';

class MoreOptionCard extends PureComponent {
  render() {
    let { name, iconName, onPress, selected } = this.props;

    return (
      <div className="box" onClick={() => onPress()}>
        <div className="icon">
          <PBIcon
            name={iconName}
            style={selected ? {} : {}}
          />
        </div>
        <div className="label">
          <div style={selected ? {} : {}}>
            {name}
          </div>
        </div>
      </div>
    );
  }
}

export default MoreOptionCard;
