import { apiClient, baseUrl } from './index';
import { handleResponse, handleError } from './responseHandler';
import { getSelectedFeedlotId, getToken } from '../storageService/GlobalData';

import APIConstants from '../../constants/APIConstants';
import axios from 'axios';

class AuditService {
  getAudits(lastSynced) {
    let url = APIConstants.GET_AUDITS.replace(
      '{{id}}',
      getSelectedFeedlotId() || 1,
    );
    if (lastSynced) {
      url = url.concat(`?syncCall=${true}&time=${lastSynced}`);
    }
    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  setAuditStatus(auditAssignmentId = 1, status) {
    const url = APIConstants.SET_AUDIT_STATUS.replace(
      '{{auditAssignmentId}}',
      auditAssignmentId,
    );

    return apiClient()
      .put(url + status)
      .then(handleResponse)
      .catch(handleError);
  }

  sendComment(payload) {
    return apiClient()
      .post(APIConstants.SEND_COMMENT, payload)
      .then(handleResponse)
      .catch(handleError);
  }

  getCommentAttachment = (fileName) => {
    return new Promise((resolve, reject) => {
      const token = getToken();
      axios
        .get(
          `${baseUrl}${APIConstants.GET_COMMENT_ATTACHMENTS}`.replace(
            '{{fileName}}',
            fileName,
          ),
          {
            headers: { token },
            responseType: 'arraybuffer',
          },
        )
        .then((response) => {
          const contentType =
            response.headers['content-type'] === 'application/octet-stream'
              ? 'image/jpeg'
              : response.headers['content-type'];
          let src = `data:${contentType};base64,${new Buffer(
            response.data,
            'binary',
          ).toString('base64')}`;
          resolve(src);
        });
    });
  };
}

export default new AuditService();
