import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from 'reactstrap';
import classnames from 'classnames';
import printJS from 'print-js';

import './style.scss';

import PdfViewer from '../../../../components/PDFViewer';
import ActivitySOPCard from '../../../../components/ActivitySOPCard';
import AppContainer from '../../../../components/AppContainer';
import ProgramManualPickerModal from '../../../../components/ProgramManualPickerModal';
import ProgramManualDetailAccordion from '../../../../components/ProgramManualDetailAccordion';
import PBIcon from '../../../../components/PBIcon';

import localization from '@progressivebeef/shared/src/localization/i18n';
import {
  getLocale,
  getToken,
  getSelectedFeedlotId,
} from '@progressivebeef/shared/src/services/storageService/GlobalData';

import { baseUrl } from '@progressivebeef/shared/src/services/api';
import APIConstants from '@progressivebeef/shared/src/constants/APIConstants';
import {
  resetCreateProps,
  getProgramManualByIdRequest,
  getProgramManualActivitiesByIdRequest,
} from '@progressivebeef/shared/src/store/actions/programMaunals';
import {
  showLoader,
  hideLoader,
} from '@progressivebeef/shared/src/store/actions/view';
import { globalHelper } from '@progressivebeef/shared/src/helpers';
import { showErrorAlert } from '../../../../components/Alerts';

import { trackEvent } from '@progressivebeef/shared/src/services/reactMixpanelService';
import {
  Events,
  Properties,
} from '@progressivebeef/shared/src/constants/MixpanelConstants';

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

class ProgramManualDetail extends PureComponent {
  fileType = '';
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      activeTab: '1',
      fileData: null,
      isPDFLoading: false,
      loadingError: false,
    };
  }

  componentDidMount() {
    const { currentProgramManual } = this.props.location.state;

    this.props.getProgramManualActivitiesById(
      currentProgramManual.document_number
    );
    this.props.getProgramManualById({
      document_number: currentProgramManual.document_number,
      document_id: currentProgramManual.document_id,
    });

    let properties = {};
    properties[Properties.DOCUMENT_NUMBER] =
      currentProgramManual.document_number;
    trackEvent(Events.VIEWED_PROGRAM_MANUAL, properties);
  }

  componentDidUpdate(prevProps) {
    let { loading, showLoaderView, hideLoaderView, programManual } = this.props;
    let { fileData, isPDFLoading, loadingError } = this.state;

    if (loading) {
      showLoaderView();
    } else {
      hideLoaderView();
    }

    if (programManual.view_preference == 2 && !loadingError) {
      if (!isPDFLoading && !fileData) {
        this.setState({
          isPDFLoading: true,
        });
      }
      this.getProgramManualPdf();
    }
  }

  onPickerPress = () => {
    this.setState({ modalVisible: false });
  };

  onCardPress = (item) => {
    this.setState({ modalVisible: true });
  };

  getProgramManualPdf = async () => {
    try {
      let { programManual } = this.props;

      const token = getToken();
      const englishSource = {
        uri: `${baseUrl}${APIConstants.GET_DOCUMENT}`
          .replace('{{feedlot_id}}', getSelectedFeedlotId())
          .replace('{{document_number}}', programManual.document_number)
          .replace('{{language_key}}', getLocale() === 'en' ? 1 : 2),
        headers: { token },
      };

      let data = await globalHelper.getFileFromServer(
        englishSource.uri,
        englishSource.headers
      );

      this.setState({
        fileData: data,
        isPDFLoading: false,
      });
    } catch (e) {
      console.log('getProgramManualPdf', e);
      this.setState({
        isPDFLoading: false,
        loadingError: true,
      });
      showErrorAlert(e);
    }
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      if (tab == 2) {
        trackEvent(Events.PM_ASSOC_ACTIVITIES_VIEWED);
      }
      this.setState({
        activeTab: tab,
      });
    }
  }

  onPrint = (item) => {
    const { programManual } = this.props;
    let { fileData } = this.state;
    let properties = {};
    properties[Properties.DOCUMENT_NUMBER] = programManual.document_number;
    trackEvent(Events.PRINTED_PROGRAM_MANUAL, properties);
    try {
      if (programManual.view_preference === 1) {
        printJS({
          printable: 'programManualHTML',
          type: 'html',
        });
      } else {
        printJS({
          printable: fileData,
          type: 'pdf',
          base64: true,
        });
      }
    } catch (e) {
      console.log('print error', e);
    }
  };

  renderHTML = () => {
    const { programManual } = this.props;

    let programManualHTML =
      programManual.document_locale[getLocale() === 'en' ? 1 : 2].body;
    programManualHTML = htmlToReactParser.parse(programManualHTML);
    return programManualHTML;
  };

  render() {
    const { programManual, activities, loading, history } = this.props;
    let { modalVisible, activeTab, fileData, isPDFLoading } = this.state;

    return (
      <AppContainer
        title={programManual.document_number}
        iconHeader
        IconLeftHeader={this.renderLeftHeader}
        IconRightHeader={this.renderRightHeader}
        noncollapsible
        history={history}
      >
        <ProgramManualPickerModal
          item={programManual}
          view
          history={history}
          modalVisible={modalVisible}
          onPressEvent={this.onPickerPress}
        />

        <div className="tab-screen-parent-container">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => {
                  this.toggle('1');
                }}
              >
                {localization[getLocale()].DETAILS}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  this.toggle('2');
                }}
              >
                {localization[getLocale()].ACTIVITIES}
              </NavLink>
            </NavItem>
          </Nav>

          <div className="tab-screen-scrollable-container pos-relative">
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <div id="#main">
                  {programManual.view_preference === 1 ? (
                    <>
                      <div>
                        <ProgramManualDetailAccordion
                          programManual={programManual}
                        />
                      </div>
                      <div
                        id="programManualHTML"
                        className="html-view-main-box"
                      >
                        {this.renderHTML()}
                      </div>
                    </>
                  ) : programManual.view_preference === 2 ? (
                    isPDFLoading ? (
                      <div className="bottom-infinite-loader">
                        <Spinner color={'red'} />
                      </div>
                    ) : (
                        <div className="prev-box">
                          {fileData && (
                            <PdfViewer iden="english" source={{ base64: fileData }} />
                          )}
                        </div>
                      )
                  ) : null}
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div id="#main">
                  {activities && activities.length > 0 ? (
                    activities.map((item) => {
                      return (
                        <ActivitySOPCard
                          history={history}
                          item={item}
                          {...this.props}
                        />
                      );
                    })
                  ) : loading ? (
                    <div className="bottom-infinite-loader">
                      {globalHelper.renderIf(loading)(
                        <Spinner color={'red'} />
                      )}
                    </div>
                  ) : (
                        <div className="no-result-found-box">
                          <p>
                            {
                              localization[getLocale()]
                                .NO_ASSOSIATED_ACTIVITIES_FOUND
                            }
                          </p>
                        </div>
                      )}
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </AppContainer>
    );
  }

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          name={'chevron-left'}
          onPress={() => {
            this.props.resetCreateProps();
            this.props.history.goBack();
          }}
        />
      </div>
    );
  };

  renderRightHeader = () => {
    return (
      <div className="head-icon right-align">
        <PBIcon onPress={() => this.onPrint()} name={'print'} />
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetCreateProps: (payload) => dispatch(resetCreateProps(payload)),
    getProgramManualById: (payload) =>
      dispatch(getProgramManualByIdRequest(payload)),
    getProgramManualActivitiesById: (payload) =>
      dispatch(getProgramManualActivitiesByIdRequest(payload)),
    showLoaderView: (payload) => dispatch(showLoader(payload)),
    hideLoaderView: (payload) => dispatch(hideLoader(payload)),
  };
};

const mapStateToProps = (state) => {
  const { programManual, activities, isDetailLoading } = state.programManuals;

  return {
    programManual,
    activities,
    loading: isDetailLoading,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgramManualDetail);
