import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import FormButton from '../FormButton';
import PickerModal from '../PickerModal';

import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

import { moveProgramManualToActiveRequest } from '@progressivebeef/shared/src/store/actions/programMaunals';
import { showAlertMsg, showErrorAlert } from '../Alerts';

class DeprecatedProgramManualPickerModal extends PureComponent {
  constructor(props) {
    super(props);
  }

  moveToActiveOnPress = async () => {
    const { onPressEvent } = this.props;

    if (window.navigator.onLine) {
      showAlertMsg(
        localization[getLocale()].CONFIRMATION.toUpperCase(),
        localization[getLocale()].MOVE_TO_ACTIVE_DOCUMENT,
        [
          {
            text: 'No',
            onPress: () => {
              onPressEvent();
            },
          },
          {
            text: 'Yes',
            onPress: () => {
              this.moveToActive();
              onPressEvent();
            },
          },
        ]
      );
    } else {
      showErrorAlert(localization[getLocale()].BINNED_OFFLINE, [
        {
          text: localization[getLocale()].OK,
          onPress: () => {
            onPressEvent();
          },
        },
      ]);
    }
  };

  moveToActive = () => {
    const { focusedProgramManual } = this.props;
    this.props.moveProgramManualToActive({
      documentNumber: focusedProgramManual.document_number,
      feedlotDocumentId: focusedProgramManual.feedlot_document_id,
    });
  };

  render() {
    const { modalVisible, onPressEvent } = this.props;
    return (
      <PickerModal
        show={modalVisible}
        onRequestClose={() => {
          onPressEvent();
        }}
      >
        <div>
          <FormButton
            success
            title={localization[getLocale()].MOVE_TO_ACTIVE}
            onPressEvent={this.moveToActiveOnPress}
          />
        </div>
        <div className="cancel-btn-box">
          <FormButton
            dark
            title={localization[getLocale()].CANCEL}
            onPressEvent={() => {
              onPressEvent();
            }}
          />
        </div>
      </PickerModal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    moveProgramManualToActive: (payload) =>
      dispatch(moveProgramManualToActiveRequest(payload)),
  };
};

const mapStateToProps = (state) => {
  const { focusedProgramManual } = state.programManuals;

  return {
    focusedProgramManual
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeprecatedProgramManualPickerModal);
