import React, { PureComponent } from 'react';
import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import { Col, Input, Row } from 'reactstrap';
import BottomTabBar from '../BottomTabBar';

//style
import './app-container.scss';
import Loading from '../Loading/Loading';

class AppContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchDisable: true,
    };
  }

  render() {
    const {
      loading,
      CustomHeader,
      title,
      onSearchPress,
      searchText,
      iconHeader,
      IconLeftHeader,
      IconRightHeader,
      hideBottomBar,
      noncollapsible,
      history,
    } = this.props;

    return (
      <div className="parent-scrollable-container">
        {CustomHeader ? (
          <CustomHeader />
        ) : (

            <div className="main-header-sec main-header-small">
              {noncollapsible
                ? (<>
                  <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <div className="col-2 col-md-2">
                      {iconHeader && IconLeftHeader && <IconLeftHeader />}
                    </div>
                    <div className="col-7 col-md-8">
                      <div className="main-header-title-center">
                        {title}
                      </div>
                    </div>
                    <div className="col-3 col-md-2">
                      {iconHeader && IconRightHeader && <IconRightHeader />}
                    </div>
                  </Row>
                </>) : (<>
                  <Row>
                    <div className="col-6 col-md-6">
                      {iconHeader && IconLeftHeader && <IconLeftHeader />}
                    </div>
                    <div className="col-6 col-md-6">
                      {iconHeader && IconRightHeader && <IconRightHeader />}
                    </div>
                  </Row>
                  <Row>
                    <Col md="12">
                      <div className="main-header-title">
                        {title}
                      </div>
                    </Col>
                  </Row></>)
              }

              {onSearchPress &&
                <Row>
                  <Col md="12">
                    <div className="header-search-bar">
                      <Input id="search" type="text" value={searchText} name="username" placeholder={localization[getLocale()].SEARCH} onChange={(e) => onSearchPress(e.target.value)} autoComplete="off" />
                      <i className="fal fa-search"></i>
                    </div>
                  </Col>
                </Row>
              }
            </div>
          )}
        {loading ? <Loading></Loading> : null}
        <div id="scrollableDiv" className={hideBottomBar ? "scrollable-content-box-without-bottom-bar" : "scrollable-content-box"}>{this.props.children}</div>
        {hideBottomBar ? null : <BottomTabBar history={history}></BottomTabBar>}
      </div>
    );
  }
}

export default AppContainer;
