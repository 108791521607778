import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import AppContainer from '../../../components/AppContainer';
import {
  getActivitiesRequest,
  searchActivities,
  sortActivities,
  resetActivitiesProps,
  setCurrentActivity,
  setCurrentInstance,
} from '@progressivebeef/shared/src/store/actions/activities';
import PBIcon from '../../../components/PBIcon';
import AssetConstants from '@progressivebeef/shared/src/constants/AssetConstants';
import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';

import { trackEvent } from '@progressivebeef/shared/src/services/reactMixpanelService';
import {
  Events,
  Properties,
} from '@progressivebeef/shared/src/constants/MixpanelConstants';

import './style.scss';

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from 'reactstrap';
import classnames from 'classnames';
import ActivityCard from '../../../components/ActivityCard';
import ActivityHelper from '@progressivebeef/shared/src/helpers/ActivitiesHelper';
import EmptyListMsg from '../../../components/EmptyListText';
import InfiniteScroll from 'react-infinite-scroll-component';
import { globalHelper } from '@progressivebeef/shared/src/helpers';
import {
  ACTIVITY_FREQUENCY,
  ACTIVITY_PERMISSIONS,
  BOTTOM_BAR_TABS,
  userRoles,
} from '@progressivebeef/shared/src/constants/AppConstants';
import ActivityPickerModal from '../../../components/ActivityPickerModal';
import { showErrorAlert } from '../../../components/Alerts';
import { setBottomBarTab } from '@progressivebeef/shared/src/store/actions/view';

class Activities extends PureComponent {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      sort: false,
      modalVisible: false,
    };
  }

  componentWillUnmount() {
    this.props.resetActivitiesProps();
  }

  renderSectionHeader = (item) => <div>{item.title}</div>;
  renderItem = (item) => {
    return (
      <ActivityCard
        onActivityCardPress={this.onActivityCardPress}
        {...this.props}
        item={item}></ActivityCard>
    );
  };
  keyExtractor = (item, index) => item.title + index;

  componentDidMount() {
    this.props.setBottomBarTab(BOTTOM_BAR_TABS.ACTIVITIES);
    this.props.getActivities({ update: false });
  }

  onRefresh = () => {
    if (navigator.onLine) {
      this.props.getActivities({ update: true, loading: true });
    } else {
      showErrorAlert(localization[getLocale()].OFFLINE_MODE_REFRESH_ERROR);
    }
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  onPickerPress = () => {
    this.setState({ modalVisible: false });
  };

  onActivityCardPress = (currentActivity) => {
    const { user, setCurrentActivityRequest } = this.props;

    setCurrentActivityRequest(currentActivity);

    if (Number(user.userRoleId) === userRoles.WORKER) {
      if (currentActivity.instance_based) {
        this.props.history.push(RouteConstants.ACTIVITY_INSTANCE, {
          currentActivity,
        });
      } else {
        this.props.history.push(RouteConstants.ACTIVITY_SUBMISSIONS, {
          currentActivity,
        });
      }
    } else {
      if (
        /**
         * if activity permission_key is assigned worker, and activity is instance based and can not be scheduled
         * then navigate directly instance screen
         */
        currentActivity.instance_based &&
        currentActivity.permission_key ===
          ACTIVITY_PERMISSIONS.ASSIGNED_WORKER &&
        currentActivity.frequency_key === ACTIVITY_FREQUENCY.REQUIRED
      ) {
        this.props.history.push(RouteConstants.ACTIVITY_INSTANCE, {
          currentActivity,
        });
      } else {
        this.setState({ modalVisible: true });
      }
    }
  };

  render() {
    const {
      activities,
      sortedActivities,
      loading,
      sort,
      user,
      query,
      history,
      setCurrentActivityRequest,
      setCurrentInstanceRequest,
    } = this.props;

    const { activeTab, modalVisible } = this.state;

    var allActivities = sort ? sortedActivities : activities;
    var assignedActivities = sort
      ? ActivityHelper.processAssignedActivities(sortedActivities, user)
      : ActivityHelper.processAssignedActivities(activities, user);

    var unassignedActivities = sort
      ? ActivityHelper.processUnassignedActivities(sortedActivities)
      : ActivityHelper.processUnassignedActivities(activities);

    return (
      <>
        <ActivityPickerModal
          user={user}
          history={history}
          modalVisible={modalVisible}
          onPressEvent={this.onPickerPress}
          setCurrentActivity={setCurrentActivityRequest}
          setCurrentInstance={setCurrentInstanceRequest}
        />
        <AppContainer
          title={localization[getLocale()].ACTIVITIES}
          onSearchPress={this.searchActivities}
          searchText={query}
          iconHeader
          history={history}
          IconLeftHeader={this.renderLeftHeader}
          IconRightHeader={this.renderRightHeader}>
          <div className="tab-screen-parent-container">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    this.toggle('1');
                  }}>
                  {localization[getLocale()].ALL}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    this.toggle('2');
                  }}>
                  {localization[getLocale()].ASSIGNED}
                </NavLink>
              </NavItem>
              {Number(user.userRoleId) !== userRoles.WORKER && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => {
                      this.toggle('3');
                    }}>
                    {localization[getLocale()].UNASSIGNED}
                  </NavLink>
                </NavItem>
              )}
            </Nav>

            <div id="main" className="tab-screen-scrollable-container">
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  {globalHelper.renderIf(loading)(
                    <div className="bottom-infinite-loader">
                      <Spinner color={'red'} />
                    </div>,
                  )}
                  <InfiniteScroll
                    dataLength={allActivities.length}
                    hasMore={false}
                    refreshFunction={this.onRefresh}
                    pullDownToRefresh
                    // height={350}
                    pullDownToRefreshThreshold={50}
                    pullDownToRefreshContent={
                      <div className="bottom-infinite-loader">
                        <Spinner color={'red'} />
                      </div>
                    }
                    releaseToRefreshContent={
                      <div className="bottom-infinite-loader">
                        <Spinner color={'red'} />
                      </div>
                    }>
                    {allActivities.map((section, index) => {
                      return (
                        <div key={index + 400}>
                          {section.data.length > 0 && section.title
                            ? React.createElement(
                                'div',
                                {
                                  className: 'section-header',
                                  key: index + 100,
                                },
                                this.renderSectionHeader(section),
                              )
                            : null}
                          {React.createElement(
                            'div',
                            {
                              className: 'section-item-container',
                              key: index + 200,
                            },
                            section.data.map((item, index) =>
                              React.createElement(
                                'div',
                                {
                                  className: 'section-item',
                                  key: index,
                                },
                                this.renderItem(item, index),
                              ),
                            ),
                          )}
                        </div>
                      );
                    })}
                    {activities.map((o) => o.data).flat() < 1 && !loading && (
                      <div className="no-submission-result-found">
                        <EmptyListMsg
                          emptyTxt={
                            localization[getLocale()].NO_ACTIVITIES_FOUND
                          }
                        />
                      </div>
                    )}
                  </InfiniteScroll>
                </TabPane>
                <TabPane tabId="2">
                  {globalHelper.renderIf(loading)(
                    <div className="bottom-infinite-loader">
                      <Spinner color={'red'} />
                    </div>,
                  )}
                  {assignedActivities.map((section, index) => {
                    return (
                      <div key={index + 400}>
                        {section.data.length > 0 && section.title
                          ? React.createElement(
                              'div',
                              {
                                className: 'section-header',
                                key: index + 100,
                              },
                              this.renderSectionHeader(section),
                            )
                          : null}
                        {React.createElement(
                          'div',
                          {
                            className: 'section-item-container',
                            key: index + 200,
                          },
                          section.data.map((item, index) =>
                            React.createElement(
                              'div',
                              {
                                className: 'section-item',
                                key: index,
                              },
                              this.renderItem(item, index),
                            ),
                          ),
                        )}
                      </div>
                    );
                  })}
                  {assignedActivities.map((o) => o.data).flat() < 1 &&
                    !loading && (
                      <div className="no-submission-result-found">
                        <EmptyListMsg
                          emptyTxt={
                            localization[getLocale()].NO_ACTIVITIES_FOUND
                          }
                        />
                      </div>
                    )}
                </TabPane>
                {Number(user.userRoleId) !== userRoles.WORKER && (
                  <TabPane tabId="3">
                    {globalHelper.renderIf(loading)(
                      <div className="bottom-infinite-loader">
                        <Spinner color={'red'} />
                      </div>,
                    )}
                    {unassignedActivities.map((section, index) => {
                      return (
                        <div key={index + 400}>
                          {section.data.length > 0 && section.title
                            ? React.createElement(
                                'div',
                                {
                                  className: 'section-header',
                                  key: index + 100,
                                },
                                this.renderSectionHeader(section),
                              )
                            : null}
                          {React.createElement(
                            'div',
                            {
                              className: 'section-item-container',
                              key: index + 200,
                            },
                            section.data.map((item, index) =>
                              React.createElement(
                                'div',
                                {
                                  className: 'section-item',
                                  key: index,
                                },
                                this.renderItem(item, index),
                              ),
                            ),
                          )}
                        </div>
                      );
                    })}
                    {unassignedActivities.map((o) => o.data).flat() < 1 &&
                      !loading && (
                        <div className="no-submission-result-found">
                          <EmptyListMsg
                            emptyTxt={
                              localization[getLocale()].NO_ACTIVITIES_FOUND
                            }
                          />
                        </div>
                      )}
                  </TabPane>
                )}
              </TabContent>
            </div>
          </div>
        </AppContainer>
      </>
    );
  }

  searchActivities = (queryString) => {
    let properties = {};
    properties[Properties.SEARCH_STRING] = queryString;
    trackEvent(Events.ACTIVITY_SEARCHED, properties);

    this.props.searchActivities(queryString);
    this.props.getActivities({ update: false });
  };

  sortPress = () => {
    const { sort } = this.props;
    let properties = {};
    properties[Properties.ACTIVITY_VIEW_TYPE] = sort
      ? 'Not_Grouped'
      : 'Grouped';
    trackEvent(Events.ACTIVITY_VIEW_TYPE_CHANGED, properties);

    this.props.sortActivities(sort ? false : true);
  };

  renderLeftHeader = () => {
    const { sort } = this.props;
    if (sort) {
      return (
        <div
          onClick={() => this.sortPress()}
          className="feedyard-head-icon left-align">
          <img alt="description" src={AssetConstants.timeSort} />
        </div>
      );
    } else {
      return (
        <div className="head-icon left-align">
          <PBIcon onPress={() => this.sortPress()} name={'sort-alpha-up'} />
        </div>
      );
    }
  };

  renderRightHeader = () => {
    const { filter } = this.props;
 
    return (
      <div>
        <div className="head-icon right-align">
          <div className="hald-icon-box">
            <div
              onClick={() =>
                this.props.history.push(RouteConstants.ACTIVITY_FILTER, {
                  filterOptions: filter,
                })
              }>
              <img
                alt="description"
                src={
                  filter && filter.length > 0
                    ? AssetConstants.filterFilled
                    : AssetConstants.filterUnfilled
                }
              />
            </div>
          </div>
          <div className="hald-icon-box">
            {' '}
            <PBIcon
              name={'trash'}
              onPress={() =>
                this.props.history.push(RouteConstants.ACTIVIY_BIN)
              }
            />
          </div>
        </div>
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getActivities: (payload) => dispatch(getActivitiesRequest(payload)),
    searchActivities: (payload) => dispatch(searchActivities(payload)),
    sortActivities: (payload) => dispatch(sortActivities(payload)),
    resetActivitiesProps: (payload) => dispatch(resetActivitiesProps(payload)),
    setCurrentActivityRequest: (payload) =>
      dispatch(setCurrentActivity(payload)),
    setCurrentInstanceRequest: (payload) =>
      dispatch(setCurrentInstance(payload)),
    setBottomBarTab: (payload) => dispatch(setBottomBarTab(payload)),
  };
};

const mapStateToProps = (state) => {
  const { sort, activities, sortedActivities, isLoading, query, filter } =
    state.activities;
  const { user } = state.authentication;

  return {
    sort,
    query,
    filter,
    user,
    activities,
    sortedActivities,
    loading: isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Activities);
