import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import '../../../../../node_modules/react-loading-skeleton/dist/skeleton.css';

import {
  getLocale,
  getSelectedFeedlotTrainingRulesEnabled,
} from '@progressivebeef/shared/src/services/storageService/GlobalData';
import localization from '@progressivebeef/shared/src/localization/i18n';

import DashboardHelper from '@progressivebeef/shared/src/helpers/DashboardHelper';
import { rankStatus } from '@progressivebeef/shared/src/constants/AppConstants';
import { getFeedlotRankRequest } from '@progressivebeef/shared/src/store/actions/dashboard';

class RankCard extends PureComponent {
  render() {
    const { rank, rankLoading, getRank } = this.props;

    const onCardTap = () => {
      if (rankLoading === rankStatus.NOT_LOADED) {
        getRank();
      }
    };

    return (
      <>
        {getSelectedFeedlotTrainingRulesEnabled() == 1 && (
          <div
            className={`rank-card ${
              rankLoading === rankStatus.NOT_LOADED && 'pointer'
            }`}
            onClick={onCardTap}>
            {rankLoading === rankStatus.LOADED ? (
              <div className="rank-star">
                <div className="no">
                  {rank.position || 0}
                  <sup>{DashboardHelper.getOrdinal(rank.position || 0)}</sup>
                </div>
              </div>
            ) : rankLoading === rankStatus.LOADING ? (
              <div className="mr-10">
                <Skeleton
                  width={60}
                  height={60}
                  baseColor="#C0E3D7"
                  highlightColor="#D8EFE7"
                  circle
                />
              </div>
            ) : (
              <div className="rank-star" />
            )}

            {rankLoading === rankStatus.NOT_LOADED ? (
              <div className="check-feedyard-button">
                <div className="label">
                  {String(localization[getLocale()].CHECK_FEEDYARD_RANK)}
                </div>
              </div>
            ) : (
              <div
                className={`desc ${
                  rankLoading === rankStatus.LOADING ? 'skeleton-width' : ''
                }`}>
                {rankLoading === rankStatus.LOADING ? (
                  <Skeleton containerClassName="flex-1" count={3} />
                ) : (
                  <div>
                    <div className="label">
                      {String(localization[getLocale()].RANK_LABEL)
                        .replace('{position}', rank.position || 0)
                        .replace(
                          '{position_ordinal}',
                          DashboardHelper.getOrdinal(rank.position || 0),
                        )
                        .replace('{total_feedyards}', rank.count || 0)}
                    </div>
                    <div className="txt">
                      {String(
                        localization[getLocale()].COMPLAINCE_LABEL,
                      ).replace(
                        '{performance}',
                        Number(rank.performance || 0).toFixed(2),
                      )}
                      <span>
                        {String(localization[getLocale()].KEEP_IT_UP)}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getRank: () => dispatch(getFeedlotRankRequest()),
  };
};

const mapStateToProps = (state) => {
  const { rank, rankLoading, showRankCard } = state.dashboard;

  return {
    rank,
    rankLoading,
    showRankCard,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RankCard);
