// modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, FormGroup, Input, Row } from 'reactstrap';
import { showErrorAlert } from '../../../components/Alerts';

// constants
import {
  logoutRequested,
  generatePinRequested,
} from '@progressivebeef/shared/src/store/actions/authentication';
import {
  showLoader,
  hideLoader,
} from '@progressivebeef/shared/src/store/actions/view';

//language
import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

//styles
import './Login.scss';
import logo from '../../../assets/img/brand/pb-logo.png';
import PBIcon from '../../../components/PBIcon';

class GeneratePin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPin: '',
      confirmPin: '',
    };
  }

  componentDidUpdate(prevProps) {
    let { user, isLogin, isLoading, error, showLoaderView, hideLoaderView } =
      this.props;

    if (isLoading) {
      showLoaderView();
    } else {
      hideLoaderView();
    }

    if (error !== prevProps.error) {
      if (typeof error === 'string') {
        showErrorAlert(error);
      }
    }

    if (!isLogin) {
      hideLoaderView();
      this.props.history.push('/Login');
    }
    //redirect to dashboard on generate pin success
    else if (user.pinCode) {
      hideLoaderView();
      this.props.history.push('/FeedyardList');
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ submitted: true });
    const { newPin, confirmPin } = this.state;

    if (newPin != '' && confirmPin != '') {
      if (newPin !== confirmPin) {
        showErrorAlert(localization[getLocale()].NEW_PIN_MATCH_ERROR);
      } else {
        const request = {
          newPin,
          confirmPin,
        };
        this.props.generateUserPin(request);
      }
    } else {
      showErrorAlert(localization[getLocale()].EMPTY_FIELD);
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { isLoading, logout } = this.props;
    const { newPin, confirmPin } = this.state;

    return (
      <div className="signInBody">
        <div>
          <Col className="loginSection">
            <Col md="12" className="loginInnerSection">
              <div className="logo">
                <img alt="Progresive Beef" src={logo} />
              </div>
              <form name="form" onSubmit={this.handleSubmit}>
                <div className="login-field-box generate-field-box">
                  <FormGroup>
                    <div className="field-group reset-pass-field">
                      <i className="fal fa-th" style={{ marginRight: 15 }}></i>
                      <Input
                        id="newPin"
                        value={newPin}
                        name="newPin"
                        type="password"
                        pattern="[0-9]+$"
                        maxLength={4}
                        placeholder={localization[getLocale()].ENTER_PIN}
                        onChange={this.handleChange}
                      />
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <div className="field-group reset-pass-field">
                      <i className="fal fa-th" style={{ marginRight: 15 }}></i>
                      <Input
                        id="confirmPin"
                        name="confirmPin"
                        type="password"
                        pattern="[0-9]+$"
                        maxLength={4}
                        value={confirmPin}
                        placeholder={localization[getLocale()].CONFIRM_PIN}
                        onChange={this.handleChange}
                      />
                    </div>
                  </FormGroup>
                </div>
                <Row>
                  <Col md="12" className="login-btn">
                    <Button
                      color="primary"
                      block
                      type="submit"
                      disabled={isLoading}>
                      {localization[getLocale()].GENERATE_PIN}
                    </Button>
                  </Col>
                </Row>

                <a href="/#" onClick={() => logout()} className="forgotPass">
                  {localization[getLocale()].LOGOUT}
                </a>
              </form>
            </Col>
          </Col>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    generateUserPin: (requestData) =>
      dispatch(generatePinRequested({ requestData })),
    logout: () => dispatch(logoutRequested()),
    showLoaderView: (payload) => dispatch(showLoader(payload)),
    hideLoaderView: (payload) => dispatch(hideLoader(payload)),
  };
};

const mapStateToProps = (state) => {
  let { isLogin, error, user, isLoading } = state.authentication;
  return {
    isLogin,
    error,
    user,
    isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneratePin);
