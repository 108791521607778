import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from 'reactstrap';
import classnames from 'classnames';

import AppContainer from '../../../../components/AppContainer';
import AuditCard from '../../../../components/AuditCard';

import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';
import {
  getAuditsRequested,
  searchAuditsRequested,
  setCurrentAuditRequested,
} from '@progressivebeef/shared/src/store/actions/audits';
import { TAB_ALL } from '@progressivebeef/shared/src/constants/AppConstants';
import { auditStatus } from '@progressivebeef/shared/src/constants/AppConstants';
import { globalHelper } from '@progressivebeef/shared/src/helpers';
import InfiniteScroll from 'react-infinite-scroll-component';
import { showErrorAlert } from '../../../../components/Alerts';

class Audits extends PureComponent {
  tabType = TAB_ALL;
  searchText = '';

  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
    };
  }

  componentDidMount() {
    this.searchAudits();
  }

  toggle(tab, tabType) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
      this.tabType = tabType;
      this.searchAudits(this.searchText);
    }
  }

  onRefresh = () => {
    if (navigator.onLine) {
      this.props.getAudits({ update: true, loading: true });
    } else {
      showErrorAlert(localization[getLocale()].OFFLINE_MODE_REFRESH_ERROR);
    }
  };

  renderSectionHeader = (item) => <div>{item.title}</div>;
  renderItem = (item) => {
    const { history } = this.props;

    return (
      <AuditCard
        key={'audit-' + item.audit_assignment_id}
        onCardPress={this.onCardPress}
        history={history}
        item={item}
        {...this.props}
      />
    );
  };
  keyExtractor = (item, index) => item.title + index;

  render() {
    const { activeTab } = this.state;
    const { history, searchText, audits, isLoading } = this.props;

    console.log('LOADING: ', isLoading);

    return (
      <>
        <AppContainer
          iconHeader
          IconRightHeader={this.renderRightHeader}
          title={localization[getLocale()].AUDITS}
          history={history}
          onSearchPress={this.searchAudits}
          searchText={searchText}>
          <div className="tab-screen-parent-container">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    this.toggle('1', TAB_ALL);
                  }}>
                  {localization[getLocale()].ALL}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    this.toggle('2', auditStatus.SUBMITTED);
                  }}>
                  {localization[getLocale()].RECEIVED}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => {
                    this.toggle('3', auditStatus.CLOSED);
                  }}>
                  {
                    localization[getLocale()][
                      'AUDIT_STATUS_' + auditStatus.CLOSED
                    ]
                  }
                </NavLink>
              </NavItem>
            </Nav>

            <div id="main" className="tab-screen-scrollable-container">
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  {globalHelper.renderIf(isLoading)(
                    <div className="bottom-infinite-loader">
                      <Spinner color={'red'} />
                    </div>,
                  )}
                  <InfiniteScroll
                    dataLength={audits.length}
                    hasMore={false}
                    refreshFunction={this.onRefresh}
                    pullDownToRefresh
                    // height={350}
                    pullDownToRefreshThreshold={50}
                    pullDownToRefreshContent={
                      <div className="bottom-infinite-loader">
                        <Spinner color={'red'} />
                      </div>
                    }
                    releaseToRefreshContent={
                      <div className="bottom-infinite-loader">
                        <Spinner color={'red'} />
                      </div>
                    }>
                    {audits && audits.map((o) => o.data).flat().length > 0
                      ? audits.map((section, index) => {
                          return (
                            <div key={index + 400}>
                              {section.data.length > 0 && section.title
                                ? React.createElement(
                                    'div',
                                    {
                                      className: 'section-header',
                                      key: index + 100,
                                    },
                                    this.renderSectionHeader(section),
                                  )
                                : null}
                              {React.createElement(
                                'div',
                                {
                                  className: 'section-item-container',
                                  key: index + 200,
                                },
                                section.data.map((item, index) =>
                                  React.createElement(
                                    'div',
                                    {
                                      className: 'section-item',
                                      key: index,
                                    },
                                    this.renderItem(item),
                                  ),
                                ),
                              )}
                            </div>
                          );
                        })
                      : !isLoading && (
                          <div className="no-result-found-box">
                            <p>{localization[getLocale()].NO_AUDITS_FOUND}</p>
                          </div>
                        )}
                  </InfiniteScroll>
                </TabPane>
                <TabPane tabId="2">
                  {audits[0] && audits[0].data.length > 0 ? (
                    audits[0].data.map((audit) => {
                      return (
                        <AuditCard
                          key={'audit-' + audit.audit_assignment_id}
                          onCardPress={this.onCardPress}
                          history={history}
                          item={audit}
                          {...this.props}
                        />
                      );
                    })
                  ) : isLoading ? (
                    <div className="bottom-infinite-loader">
                      {globalHelper.renderIf(isLoading)(
                        <Spinner color={'red'} />,
                      )}
                    </div>
                  ) : (
                    !isLoading && (
                      <div className="no-result-found-box">
                        <p>{localization[getLocale()].NO_AUDITS_FOUND}</p>
                      </div>
                    )
                  )}
                </TabPane>
                <TabPane tabId="3">
                  {audits[2] && audits[2].data.length > 0 ? (
                    audits[2].data.map((audit) => {
                      return (
                        <AuditCard
                          key={'audit-' + audit.audit_assignment_id}
                          onCardPress={this.onCardPress}
                          history={history}
                          item={audit}
                          {...this.props}
                        />
                      );
                    })
                  ) : isLoading ? (
                    <div className="bottom-infinite-loader">
                      {globalHelper.renderIf(isLoading)(
                        <Spinner color={'red'} />,
                      )}
                    </div>
                  ) : (
                    !isLoading && (
                      <div className="no-result-found-box">
                        <p>{localization[getLocale()].NO_AUDITS_FOUND}</p>
                      </div>
                    )
                  )}
                </TabPane>
              </TabContent>
            </div>
          </div>
        </AppContainer>
      </>
    );
  }

  renderRightHeader = () => {
    return <div />;
  };

  searchAudits = (searchText = '') => {
    this.searchText = searchText;
    this.props.searchAudits(this.tabType, searchText);
  };

  onCardPress = (item, history) => {
    this.props.setCurrentAudit(item);
    history.push(RouteConstants.AUDIT_NC_LIST, {
      audit: item,
    });
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    searchAudits: (tabType, searchText) =>
      dispatch(searchAuditsRequested({ tabType, searchText })),
    setCurrentAudit: (audit) => dispatch(setCurrentAuditRequested(audit)),
    getAudits: (payload) => dispatch(getAuditsRequested(payload)),
  };
};

const mapStateToProps = (state) => {
  let { searchText, audits, isLoading } = state.audits;
  return {
    searchText,
    audits,
    isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Audits);
