import moment from 'moment';
// import { showAlertMsg } from '../components/Alerts';
import { getLocale, getToken } from '../services/storageService/GlobalData';
import { baseUrl } from '../services/api';
import axios from 'axios';
import localization from '../localization/i18n';
import { DATE_FORMATS } from '../constants/AppConstants';

let RNFS = null,
  Platform = null,
  Linking = null;
let RNFetchBlob = null;
let ImagePicker = null;
if (process.env.REACT_APP_PLATFORM !== 'WEB') {
  import('react-native-fs').then((RNFS) => {
    RNFS = RNFS;
  });
  import('react-native-image-crop-picker').then((ImagePicker) => {
    ImagePicker = ImagePicker;
  });
  import('react-native').then(({ Linking, Platform }) => {
    Linking = Linking;
    Platform = Platform;
  });
  import('rn-fetch-blob').then((RNFetchBlob) => {
    RNFetchBlob = RNFetchBlob;
  });
} else {
  if (getLocale() == 'es') {
    require('moment/locale/es');
  }
}

const getDifferenceBetweenDates = (date1, date2) => {
  date1 = new Date(date1);
  date2 = new Date(date2);
  const diffTime = Math.abs(date2.getTime() - date1.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

const generateUuid = () => {
  return Math.floor(100000 + Math.random() * 900000);
};

const parseRealmList = (listObject, objectDeepParse) => {
  // if need to parse nested object pass objectDeepParse true
  //it will change nested arrays to object {0: {}, 1: {}}. work accordingly. that was only work around possible for now
  // if (objectDeepParse) {
  return JSON.parse(JSON.stringify(listObject));
  // }
  // return listObject.map(x => Object.assign({}, x));
};

const transposeArray = (array) => {
  return Object.keys(array[0]).map(function (c) {
    return array.map(function (r) {
      return r[c];
    });
  });
};

const formatDate = (date) => {
  let formattedDate =
    date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
  return formattedDate;
};

const renderIf = (condition) => {
  if (condition) {
    return (jsx) => jsx;
  }
  return () => null;
};

const getBase64FileWeb = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = (error) => reject(error);
  });

const getBase64File = async (fileName) => {
  const file = await RNFS.readFile(fileName, 'base64');
  return file;
};

const toCamelCase = (str) => {
  if (typeof str !== 'string') {
    return '';
  }
  if (!str) {
    return '';
  }
  return str
    .replace(/\s(.)/g, ($1) => $1.toUpperCase())
    .replace(/\s/g, '')
    .replace(/^(.)/, ($1) => $1.toLowerCase());
};

const capitalize = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
    match.toUpperCase(),
  );

const seperateDataByKey = (data, key, value) => {
  return data.filter((q) => q[key] === value);
};

const numberToWords = (num) => {
  let a = [
    '',
    'one ',
    'two ',
    'three ',
    'four ',
    'five ',
    'six ',
    'seven ',
    'eight ',
    'nine ',
    'ten ',
    'eleven ',
    'twelve ',
    'thirteen ',
    'fourteen ',
    'fifteen ',
    'sixteen ',
    'seventeen ',
    'eighteen ',
    'nineteen ',
  ];
  let b = [
    '',
    '',
    'twenty',
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eighty',
    'ninety',
  ];
  let c = ['', 'once', 'twice', 'thrice'];
  let str = '';
  if (num > 0 && num <= 3) {
    str = c[num];
  } else {
    if ((num = num.toString()).length > 9) {
      return 'overflow';
    }
    let n = ('000000000' + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) {
      return;
    }
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore '
        : '';
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh '
        : '';
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand '
        : '';
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred '
        : '';
    str +=
      n[5] != 0
        ? (str != '' ? 'and ' : '') +
          (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) +
          'times '
        : '';
  }
  return str;
};

const getPdfSource = (apiUrl, filename) => {
  const pdfSource = {
    uri: `${baseUrl}${apiUrl}/${filename}`,
    headers: { token: getToken() },
  };
  return pdfSource;
};

const getDateInFromNowFormat = (date) => {
  // date = date.slice(0, 19);
  if (date === null) {
    return moment.utc().local().fromNow().toUpperCase();
  } else {
    return moment.utc(date).local().fromNow().toUpperCase();
  }
};

////open camera on button click and return captured image
const openCamera = async () => {
  return new Promise((resolve, reject) => {
    try {
      ImagePicker.openCamera({
        includeBase64: true,
        // compressImageQuality: 0.4,
        // cropping: true,
      })
        .then((image) => {
          /**
           * creating object from returned object to use same obj model for validating attachment
           */
          let file = {
            size: image.size,
            type: image.mime,
            uri: image.path,
          };
          resolve(file);
        })
        .catch((e) => {
          console.log('openCamera failed', e);
          showPermissionAlertForIOSGallery();
          reject(e);
        });
    } catch (e) {
      console.log('try openCamera failed', e);
      showPermissionAlertForIOSGallery();
      reject(e);
    }
  });
};

const showPermissionAlertForIOSGallery = () => {
  if (Platform.OS === 'ios') {
    // showAlertMsg(
    //   localization[getLocale()].ALERT,
    //   localization[getLocale()].IOS_CAMERA_PERMISSION_ALERT,
    //   [
    //     {
    //       text: localization[getLocale()].CANCEL,
    //       onPress: () => {
    //         style: 'cancel';
    //       },
    //     },
    //     {
    //       text: localization[getLocale()].OK,
    //       onPress: () => {
    //         //redirect to settings
    //         Linking.openURL('app-settings:');
    //       },
    //     },
    //   ]
    // );
  }
};

const getBase64FromURL = async (url, filename, destinationType) => {
  try {
    let dirs = RNFetchBlob.fs.dirs;
    let response = await RNFetchBlob.config({
      // add this option that makes response data to be stored as a file,
      // this is much more performant.
      fileCache: true,
      //cache file at this path
      path: dirs.DocumentDir + '/' + filename,
    }).fetch('GET', baseUrl + url, {
      token: getToken(),
      'User-Device': 'mobile',
    });

    let status = response.info().status;
    let destination = null;

    if (status == 200) {
      if (!destinationType) {
        destination = response.path();
      }
    }
    return destination;
  } catch (errorMessage) {
    throw errorMessage;
  }
};

const createBlob = (fileContent, fileType) => {
  let blob = new Blob([fileContent], { type: fileType }),
    fileUrl = URL.createObjectURL(blob);

  return {
    blob: blob,
    fileUrl: fileUrl,
  };
};

const handlePointValidation = (value) => {
  //made "0." valid as it wasnot allowing . only
  const condition = new RegExp(/^\d+(\.\d*)?$/);
  return condition.test(value);
};

const handleIntValidation = (value) => {
  const condition = new RegExp(/^\d+$/);
  return condition.test(value);
};

const getFileFromServer = (url, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: headers,
        responseType: 'arraybuffer',
      })
      .then((response) => {
        let data = `${new Buffer(response.data, 'binary').toString('base64')}`;
        resolve(data);
      })
      .catch((e) => {
        console.log('getFileFromServer', e.message);
        if (e.message === 'Network Error')
          reject(localization[getLocale()].NO_INTERNET_CONNECTION);
      });
  });
};

const getRevisionDateFormat = (date) => {
  //as admin is having issues on sending dateTtime which is ISO format n iOS is not accepting any date except this format.we had to do this patch
  let d = date && date.includes(' ');
  if (d) {
    let dt = date.replace(' ', 'T');
    return moment(dt).locale(getLocale()).format(DATE_FORMATS.MM_DD_YY_H_MM_A);
  }
  return moment(date).locale(getLocale()).format(DATE_FORMATS.MM_DD_YY);
};

export const globalHelper = {
  generateUuid,
  handlePointValidation,
  getDifferenceBetweenDates,
  parseRealmList,
  transposeArray,
  formatDate,
  renderIf,
  getBase64FileWeb,
  getBase64File,
  toCamelCase,
  capitalize,
  seperateDataByKey,
  numberToWords,
  getPdfSource,
  getDateInFromNowFormat,
  openCamera,
  createBlob,
  getBase64FromURL,
  handlePointValidation,
  getFileFromServer,
  handleIntValidation,
  getRevisionDateFormat,
};
