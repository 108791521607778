import ACTION_CONSTANTS from '../../constants/actionConstants';
import { request, success, failure } from './index';

//#region Initialized
export function getInitialized(payload) {
  return request(ACTION_CONSTANTS.GET_INITIALIZED, payload);
}

export function getInitializedSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_INITIALIZED_SUCCESS, payload);
}

export function getInitializedFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_ACTIVITIES_FAILURE, payload);
}
//#endregion

//#region login
export function loginRequested(payload) {
  return request(ACTION_CONSTANTS.LOGIN_REQUESTED, payload);
}

export function loginSuccess(payload) {
  return success(ACTION_CONSTANTS.LOGIN_SUCCESS, payload);
}

export function loginFailure(payload) {
  return failure(ACTION_CONSTANTS.LOGIN_FAILURE, payload);
}
//#endregion

export function setUserDevice(payload) {
  return request(ACTION_CONSTANTS.SET_USER_DEVICE, payload);
}

//#region login using biometric
export function loginViaBiometricRequested(payload) {
  return request(ACTION_CONSTANTS.LOGIN_VIA_BIOMETRIC_REQUESTED, payload);
}

export function loginViaBiometricSuccess(payload) {
  return success(ACTION_CONSTANTS.LOGIN_VIA_BIOMETRIC_SUCCESS, payload);
}

export function loginViaBiometricFailure(payload) {
  return failure(ACTION_CONSTANTS.LOGIN_VIA_BIOMETRIC_FAILURE, payload);
}
//#endregion

//#region toggle Biometric from settings
export function toggleBiometricRequested(payload) {
  return request(ACTION_CONSTANTS.TOGGLE_BIOMETRIC_REQUESTED, payload);
}

export function toggleBiometricSuccess(payload) {
  return success(ACTION_CONSTANTS.TOGGLE_BIOMETRIC_SUCCESS, payload);
}

export function toggleBiometricFailure(payload) {
  return failure(ACTION_CONSTANTS.TOGGLE_BIOMETRIC_FAILURE, payload);
}
//#endregion

//#region Assigned feedyards
export function getAssignedFeedyards(payload) {
  return request(ACTION_CONSTANTS.GET_ASSIGNED_FEEDYARDS_REQUESTED, payload);
}

export function getAssignedFeedyardsSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_ASSIGNED_FEEDYARDS_SUCCESS, payload);
}

export function getAssignedFeedyardsFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_ASSIGNED_FEEDYARDS_FAILURE, payload);
}
//#endregion

//#region select feedyard
export function selectFeedyard(payload) {
  return request(ACTION_CONSTANTS.SELECT_FEEDYARD_REQUESTED, payload);
}

export function selectFeedyardSuccess(payload) {
  return success(ACTION_CONSTANTS.SELECT_FEEDYARD_SUCCESS, payload);
}

export function selectFeedyardFailure(payload) {
  return failure(ACTION_CONSTANTS.SELECT_FEEDYARD_FAILURE, payload);
}
//#endregion

//#region Logout
export function logoutRequested(payload) {
  return request(ACTION_CONSTANTS.LOGOUT_REQUESTED, payload);
}

export function logoutSuccess(payload) {
  return success(ACTION_CONSTANTS.LOGOUT_SUCCESS, payload);
}

export function logoutFailure(payload) {
  return failure(ACTION_CONSTANTS.LOGOUT_FAILURE, payload);
}
//#endregion

export function resetLockScreenProps(payload) {
  return request(ACTION_CONSTANTS.RESET_LOCK_SCREEN_PROPS, payload);
}

//#region First Login
export function setFirstLoginRequested(payload) {
  return request(ACTION_CONSTANTS.SET_FIRST_LOGIN_REQUESTED, payload);
}

export function setFirstLoginSuccess(payload) {
  return success(ACTION_CONSTANTS.SET_FIRST_LOGIN_SUCCESS, payload);
}

export function setFirstLoginFailure(payload) {
  return failure(ACTION_CONSTANTS.SET_FIRST_LOGIN_FAILURE, payload);
}
//#endregion

//#region Latest android version
export function setLatestAndroidVersionRequested(payload) {
  return request(
    ACTION_CONSTANTS.SET_LATEST_ANDROID_VERSION_REQUESTED,
    payload,
  );
}

export function setLatestAndroidVersionSuccess(payload) {
  return success(ACTION_CONSTANTS.SET_LATEST_ANDROID_VERSION_SUCCESS, payload);
}

export function setLatestAndroidVersionFailure(payload) {
  return failure(ACTION_CONSTANTS.SET_LATEST_ANDROID_VERSION_FAILURE, payload);
}
//#endregion

//#region Latest ios version
export function setLatestiOSVersionRequested(payload) {
  return request(ACTION_CONSTANTS.SET_LATEST_IOS_VERSION_REQUESTED, payload);
}

export function setLatestiOSVersionSuccess(payload) {
  return success(ACTION_CONSTANTS.SET_LATEST_IOS_VERSION_SUCCESS, payload);
}

export function setLatestiOSVersionFailure(payload) {
  return failure(ACTION_CONSTANTS.SET_LATEST_IOS_VERSION_FAILURE, payload);
}
//#endregion

//#region android force update
export function setAndroidForceUpdateRequested(payload) {
  return request(ACTION_CONSTANTS.SET_ANDROID_FORCE_UPDATE_REQUESTED, payload);
}

export function setAndroidForceUpdateSuccess(payload) {
  return success(ACTION_CONSTANTS.SET_ANDROID_FORCE_UPDATE_SUCCESS, payload);
}

export function setAndroidForceUpdateFailure(payload) {
  return failure(ACTION_CONSTANTS.SET_ANDROID_FORCE_UPDATE_FAILURE, payload);
}
//#endregion

//#region ios force update
export function setIOSForceUpdateRequested(payload) {
  return request(ACTION_CONSTANTS.SET_IOS_FORCE_UPDATE_REQUESTED, payload);
}

export function setIOSForceUpdateSuccess(payload) {
  return success(ACTION_CONSTANTS.SET_IOS_FORCE_UPDATE_SUCCESS, payload);
}

export function setIOSForceUpdateFailure(payload) {
  return failure(ACTION_CONSTANTS.SET_IOS_FORCE_UPDATE_FAILURE, payload);
}
//#endregion

//#region forgotPassword
export function forgotPasswordRequested(payload) {
  return request(ACTION_CONSTANTS.FORGOT_PASSWORD_REQUESTED, payload);
}

export function forgotPasswordSuccess(payload) {
  return success(ACTION_CONSTANTS.FORGOT_PASSWORD_SUCCESS, payload);
}

export function forgotPasswordFailure(payload) {
  return failure(ACTION_CONSTANTS.FORGOT_PASSWORD_FAILURE, payload);
}
//#endregion

//#region generatePin
export function generatePinRequested(payload) {
  return request(ACTION_CONSTANTS.GENERATE_PIN_REQUESTED, payload);
}

export function generatePinSuccess(payload) {
  return success(ACTION_CONSTANTS.GENERATE_PIN_SUCCESS, payload);
}

export function generatePinFailure(payload) {
  return failure(ACTION_CONSTANTS.GENERATE_PIN_FAILURE, payload);
}
//#endregion

//#region validate pin
export function validatePinRequested(payload) {
  return request(ACTION_CONSTANTS.VALIDATE_PIN_REQUESTED, payload);
}

export function validatePinSuccess(payload) {
  return success(ACTION_CONSTANTS.VALIDATE_PIN_SUCCESS, payload);
}

export function validatePinFailure(payload) {
  return failure(ACTION_CONSTANTS.VALIDATE_PIN_FAILURE, payload);
}
//#endregion

//#region validate pin using biometric
export function unlockAppViaBiometricRequested(payload) {
  return request(ACTION_CONSTANTS.UNLOCK_APP_VIA_BIOMETRIC_REQUESTED, payload);
}

export function unlockAppViaBiometricSuccess(payload) {
  return success(ACTION_CONSTANTS.UNLOCK_APP_VIA_BIOMETRIC_SUCCESS, payload);
}

export function unlockAppViaBiometricFailure(payload) {
  return failure(ACTION_CONSTANTS.UNLOCK_APP_VIA_BIOMETRIC_FAILURE, payload);
}
//#endregion

//#region getLocale to DB
export function setLocaleToStorage(payload) {
  return request(ACTION_CONSTANTS.SET_LOCALE_TO_STORAGE, payload);
}
//#endregion

//#region Email Alerts Toggle
export function emailAlertsToggleRequested(payload) {
  return request(ACTION_CONSTANTS.TOGGLE_EMAIL_ALERT_REQUESTED, payload);
}

export function emailAlertsToggleSuccess(payload) {
  return success(ACTION_CONSTANTS.TOGGLE_EMAIL_ALERT_SUCCEEDED, payload);
}

export function emailAlertsToggleFailure(payload) {
  return failure(ACTION_CONSTANTS.TOGGLE_EMAIL_ALERT_FAILED, payload);
}
//#endregion
