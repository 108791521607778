import moment from 'moment';

import {
  SORTED_ASC,
  DATE_FORMATS,
  USER_PERFORMANCE,
  BACKDATED_SUBMISSIONS_FILTER,
} from '../constants/AppConstants';
import { getLocale } from '../services/storageService/GlobalData';
import localization from '../localization/i18n';
require('moment/locale/es');

class DashboardHelper {
  static getActiveNonActiveWorkers(workers) {
    let activeWorkers = [];
    let nonActiveWorkers = [];
    let sortedArr = DashboardHelper.sortWorkersByDate(workers, SORTED_ASC);
    sortedArr.forEach((worker) => {
      const last_access_time = worker.last_access_time;
      // if (DashboardHelper.isTodaysDate(worker.last_access_time)) {
      if (worker.status === USER_PERFORMANCE.ACTIVE_TODAY) {
        worker.fromNow = moment(last_access_time).locale(getLocale()).fromNow();
        activeWorkers.push(worker);
      } else {
        worker.fromNow = last_access_time
          ? moment(last_access_time).locale(getLocale()).fromNow()
          : localization[getLocale()].NEVER_SIGNED_IN;
        nonActiveWorkers.push(worker);
      }
    });
    return { activeWorkers, nonActiveWorkers };
  }

  /**
   * compare and returns boolean if provided date is of today
   * @param {string} date - a valid date string
   */
  static isTodaysDate(date) {
    if (!date) {
      return false;
    }
    const currentDate = new Date();
    const dataDate = new Date(moment(date));
    if (
      currentDate.getDate() === dataDate.getDate() &&
      currentDate.getMonth() === dataDate.getMonth() &&
      currentDate.getFullYear() === dataDate.getFullYear()
    ) {
      return true;
    }
    return false;
  }

  static sortWorkersByDate(workers, asc) {
    const sortedArr = workers.sort((a, b) => {
      if (asc) {
        if (!a.last_access_time) {
          return 1;
        } else if (!b.last_access_time) {
          return -1;
        } else {
          return (
            new Date(moment(b.last_access_time)) -
            new Date(moment(a.last_access_time))
          );
        }
      } else {
        if (!a.last_access_time) {
          return -1;
        } else if (!b.last_access_time) {
          return 1;
        } else {
          return (
            new Date(moment(a.last_access_time)) -
            new Date(moment(b.last_access_time))
          );
        }
      }
    });
    return sortedArr;
  }

  static PERFORMANCE_DATE_STRING = {
    daily: '',
    thisWeek: (() => {
      let weekText = '';
      const today = new Date();
      const currentDate = new Date();

      const first = today.getDate() - today.getDay(); // First day is the day of the month - the day of the week
      const firstday = new Date(today.setDate(first));

      //set time to 00:00:00 for comparison
      currentDate.setHours(0, 0, 0, 0);
      firstday.setHours(0, 0, 0, 0);

      //hide date range for first day only
      if (currentDate < firstday) {
        // currentDay = firstday
        weekText = '';
      } else {
        weekText = `(${moment(firstday)
          .locale(getLocale())
          .format(DATE_FORMATS.MM_DD_YY)} - ${moment(currentDate)
          .locale(getLocale())
          .format(DATE_FORMATS.MM_DD_YY)})`;
      }
      return weekText;
    })(),
    weekly: (() => {
      // const previousweek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000); // get previous week date

      // const first = previousweek.getDate() - previousweek.getDay(); // First day is the day of the month - the day of the week
      // const last = first + 6; // last day is the first day + 6
      // const firstday = new Date(previousweek.setDate(first));
      // const lastday = new Date(previousweek.setDate(last));

      const today = new Date();
      var firstDateOfLastWeek = new Date(),
        lastDayOfLastWeek = new Date();
      //set to sunday's date of last week
      firstDateOfLastWeek = new Date(
        firstDateOfLastWeek.setDate(
          firstDateOfLastWeek.getDate() - (7 + today.getDay()),
        ),
      );
      //set to sat's date of last week
      lastDayOfLastWeek = new Date(
        lastDayOfLastWeek.setDate(firstDateOfLastWeek.getDate() + 6) %
          moment(firstDateOfLastWeek).daysInMonth(),
      );
      var start_date = moment(firstDateOfLastWeek)
        .locale(getLocale())
        .format(DATE_FORMATS.MM_DD_YY);
      var end_date = moment(firstDateOfLastWeek)
        .locale(getLocale())
        .add(6, 'days')
        .format(DATE_FORMATS.MM_DD_YY);

      const weekText = `(${start_date} - ${end_date})`;
      return weekText;
    })(),
    thisMonth: (() => {
      const currentDate = new Date();
      let currentMonthDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
      );

      return moment(currentMonthDate)
        .locale(getLocale())
        .format(DATE_FORMATS.MMMM_YYYY);
    })(),
    monthly: (() => {
      const currentDate = new Date();
      let previousMonthDate,
        year = currentDate.getFullYear(),
        month = currentDate.getMonth() - 1; //previous month

      //If current month is January then
      // set dec of previous year
      if (currentDate.getMonth() === 0) {
        month = 11;
        year = currentDate.getFullYear() - 1;
      }

      previousMonthDate = new Date(year, month);

      return moment(previousMonthDate)
        .locale(getLocale())
        .format(DATE_FORMATS.MMMM_YYYY);
    })(),
    cumulative: new Date().getFullYear(),
  };

  static getNotificationTimeText(date) {
    let notificationTimeText = '',
      currentDate = moment(),
      agoLabel = localization[getLocale()].AGO,
      durationText,
      interval;

    if (date) {
      const formattedDate = moment(date);
      interval = currentDate.diff(formattedDate, 'days');

      if (interval == 0) {
        notificationTimeText = localization[getLocale()].TODAY;
      } else {
        if (interval == 1) {
          durationText = localization[getLocale()].ONE_DAY;
        } else {
          durationText = localization[getLocale()].DAYS;
        }

        if (getLocale() === 'en') {
          notificationTimeText = interval + ' ' + durationText + ' ' + agoLabel;
        } else {
          notificationTimeText = agoLabel + ' ' + interval + ' ' + durationText;
        }
      }
    }
    return notificationTimeText;
  }

  static getTimesAgo(date) {
    if (date === null) {
      return moment.utc().local().fromNow();
    } else {
      return moment.utc(date).local().fromNow();
    }
  }

  static sortNotificationsByDate(notifications) {
    const sortedArr = notifications.sort((a, b) => {
      return (
        new Date(moment(b.notification_create_datetime)) -
        new Date(moment(a.notification_create_datetime))
      );
    });
    return sortedArr;
  }

  static getOrdinal(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return 'st';
    }
    if (j == 2 && k != 12) {
      return 'nd';
    }
    if (j == 3 && k != 13) {
      return 'rd';
    }
    return 'th';
  }

  static getBackdatedSubmissionsInDuration(filter, startDate, endDate) {
    var start_date = '',
      end_date = '';
    var date = new Date(),
      currentDate = new Date();
    switch (filter) {
      case BACKDATED_SUBMISSIONS_FILTER.YESTERDAY:
        start_date = moment(date.setDate(currentDate.getDate() - 1))
          .locale(getLocale())
          .format(DATE_FORMATS.MM_DD_YY);
        end_date = moment(date.setDate(currentDate.getDate() - 1))
          .locale(getLocale())
          .format(DATE_FORMATS.MM_DD_YY);
        break;
      case BACKDATED_SUBMISSIONS_FILTER.LAST_WEEK:
        var firstDateOfLastWeek = new Date(),
          lastDayOfLastWeek = new Date();
        //set to sunday's date of last week
        firstDateOfLastWeek = new Date(
          firstDateOfLastWeek.setDate(
            firstDateOfLastWeek.getDate() - (7 + currentDate.getDay()),
          ),
        );
        //set to sat's date of last week
        lastDayOfLastWeek = new Date(
          lastDayOfLastWeek.setDate(firstDateOfLastWeek.getDate() + 6) %
            moment(firstDateOfLastWeek).daysInMonth(),
        );
        start_date = moment(firstDateOfLastWeek)
          .locale(getLocale())
          .format(DATE_FORMATS.MM_DD_YY);
        end_date = moment(firstDateOfLastWeek)
          .locale(getLocale())
          .add(6, 'days')
          .format(DATE_FORMATS.MM_DD_YY);
        break;
      case BACKDATED_SUBMISSIONS_FILTER.THIS_WEEK:
        var firstDateOfThisWeek = new Date(),
          lastDayOfThisWeek = new Date();
        //set to sunday's date of this week
        firstDateOfThisWeek = new Date(
          firstDateOfThisWeek.setDate(
            firstDateOfThisWeek.getDate() - currentDate.getDay(),
          ),
        );
        start_date = moment(firstDateOfThisWeek)
          .locale(getLocale())
          .format(DATE_FORMATS.MM_DD_YY);
        end_date = moment(lastDayOfThisWeek)
          .locale(getLocale())
          .format(DATE_FORMATS.MM_DD_YY);
        break;
      case BACKDATED_SUBMISSIONS_FILTER.LAST_MONTH:
        start_date = moment()
          .locale(getLocale())
          .subtract(1, 'month')
          .startOf('month')
          .format(DATE_FORMATS.MM_DD_YY);
        end_date = moment()
          .locale(getLocale())
          .subtract(1, 'month')
          .endOf('month')
          .format(DATE_FORMATS.MM_DD_YY);
        break;
      case BACKDATED_SUBMISSIONS_FILTER.THIS_MONTH:
        start_date = moment(date)
          .locale(getLocale())
          .startOf('month')
          .format(DATE_FORMATS.MM_DD_YY);
        end_date = moment(date)
          .locale(getLocale())
          .format(DATE_FORMATS.MM_DD_YY);
        break;
      case BACKDATED_SUBMISSIONS_FILTER.THIS_YEAR:
        start_date = moment()
          .locale(getLocale())
          .startOf('year')
          .format(DATE_FORMATS.MM_DD_YY);
        end_date = moment(date)
          .locale(getLocale())
          .format(DATE_FORMATS.MM_DD_YY);
        break;
      case BACKDATED_SUBMISSIONS_FILTER.CUSTOM:
        start_date = moment(startDate)
          .locale(getLocale())
          .format(DATE_FORMATS.MM_DD_YY);
        end_date = moment(endDate)
          .locale(getLocale())
          .format(DATE_FORMATS.MM_DD_YY);
        break;
    }

    return {
      start_date,
      end_date,
    };
  }
}

export default DashboardHelper;
