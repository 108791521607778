// modules
import React, { Component } from 'react';
import { connect } from 'react-redux';

// constants
import ROUTE_CONSTANTS from '@progressivebeef/shared/src/constants/RouteConstants';
import { logoutRequested } from '@progressivebeef/shared/src/store/actions/authentication';

class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let { isLogin } = this.props;

    if (isLogin) {
      this.props.Logout();
    }
  }

  componentDidUpdate() {
    let { isLogin } = this.props;

    if (!isLogin) {
      this.props.history.push('/Login');
    }
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    Logout: () => dispatch(logoutRequested()),
  };
};

const mapStateToProps = (state) => {
  const { isLogin } = state.authentication;
  return {
    isLogin,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
