import React, { Component } from 'react';
import AppContainer from '../AppContainer';
import PBIcon from '../PBIcon';
import './style.scss';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import localization from '@progressivebeef/shared/src/localization/i18n';
import { Spinner, Modal } from 'reactstrap';
import PDFViewer from '../PDFViewer';
import ActivityHelper from '@progressivebeef/shared/src/helpers/ActivitiesHelper';

class Preview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      data: null,
    };
  }

  componentDidMount() {
    const { imageSource } = this.props;

    imageSource && imageSource.headers && this.renderImage();
  }

  startLoading = () => {
    this.setState({ showLoader: true });
  };

  stopLoading = () => {
    this.setState({ showLoader: false });
  };

  renderImage = async () => {
    const { imageSource, isBase64, isComment } = this.props;

    if (!isBase64) {
      var data = await ActivityHelper.getImageFromServer(
        imageSource.uri,
        imageSource.headers,
      );
      this.setState({ data });
    } else
      this.setState({
        data: isComment ? imageSource : `data:image/jpeg;base64,${imageSource}`,
      });
  };

  render() {
    const {
      pdfSource,
      imageSource,
      rightHeader,
      leftHeader,
      title,
      modalVisible,
      type,
      history,
      isComment,
    } = this.props;

    const pdfSourceURL = pdfSource
      ? pdfSource.headers
        ? {
            url: encodeURI(pdfSource.uri),
            httpHeaders: { token: pdfSource.headers.token },
          }
        : isComment
        ? pdfSource
        : `data:application/pdf;base64,${pdfSource}`
      : null;

    // console.log('this.state.data', this.state.data, pdfSource);
    // console.log('imageSourceimageSource', imageSource);
    return (
      <>
        <Modal className="attachement-preview-popup" isOpen={modalVisible}>
          <AppContainer
            title={title || localization[getLocale()].REPORT}
            iconHeader
            hideBottomBar
            IconLeftHeader={leftHeader && this.renderLeftHeader}
            IconRightHeader={rightHeader && this.renderRightHeader}
            history={history}
            noncollapsible>
            <>
              {this.state.showLoader && (
                <div style={{ flex: 1 }}>
                  <Spinner color={'rgb(80,45,127)'} animating={true} />
                </div>
              )}
              {pdfSourceURL ? (
                <div className="tab-screen-scrollable-container pos-relative">
                  <div className="prev-box">
                    <PDFViewer
                      iden="english"
                      source={{ connection: pdfSourceURL }}
                      setFilePath={(filePath) => {
                        this.filePath = filePath;
                      }}
                    />
                  </div>
                </div>
              ) : null}
              {imageSource ? (
                imageSource.headers ? (
                  <div className="prev-box">
                    <img
                      alt="description"
                      src={this.state.data}
                      onLoadStart={this.startLoading}
                    />
                  </div>
                ) : (
                  <div className="prev-box">
                    <img
                      alt="description"
                      src={
                        isComment
                          ? imageSource
                          : `data:${type};base64,${imageSource}`
                      }
                      onLoadStart={this.startLoading}
                    />
                  </div>
                )
              ) : null}
            </>
          </AppContainer>
        </Modal>
      </>
    );
  }

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          name={'chevron-left'}
          onPress={() => {
            this.props.history.goBack();
          }}
        />
      </div>
    );
  };

  renderRightHeader = () => {
    return (
      <div className="head-icon right-align">
        <PBIcon name={'times'} onPress={this.props.onRightIconPress} />
      </div>
    );
  };
}

export default Preview;
