import { put, takeEvery, call, select, takeLatest } from 'redux-saga/effects';
import ACTION_CONSTANTS from '../constants/actionConstants/index';
import {
  getProgramManual,
  getProgramManualSuccess,
  getProgramManualFailure,
  getProgramManualById,
  getProgramManualByIdSuccess,
  getProgramManualByIdFailure,
  getProgramManualActivitiesById,
  getProgramManualActivitiesByIdSuccess,
  getProgramManualActivitiesByIdFailure,
  getProgramManualTags,
  createTraining,
  createTrainingSuccess,
  createTrainingFailure,
  updateTraining,
  updateTrainingSuccess,
  updateTrainingFailure,
  getDeprecatedProgramManuals,
  getDeprecatedProgramManualsSuccess,
  getDeprecatedProgramManualsFailure,
  getMissedProgramManuals,
  getMissedProgramManualsSuccess,
  getMissedProgramManualsFailure,
  moveProgramManualToActive,
  moveProgramManualToActiveSuccess,
  moveProgramManualToActiveFailure,
  moveProgramManualToBin,
  moveProgramManualToBinSuccess,
  moveProgramManualToBinFailure,
  getDocumentListFailure,
  getDocumentListSuccess,
  emailReport,
  emailReportSuccess,
  emailReportFailure,
  deleteProgramManualUrl,
  deleteProgramManualUrlSuccess,
  deleteProgramManualUrlFailure,
  updateProgramManualUrl,
  updateProgramManualUrlSuccess,
  updateProgramManualUrlFailure,
  setNumberOfMissedTraining,
  markViewed,
  markViewedSuccess,
  markViewedFailure,
  getProgramManualByIdRequest,
  getProgramManualRequest,
} from '../store/actions/programMaunals';
import ProgramManualService from '../services/api/programManuals';
import ProgramManualManager from '../database/reactDataManager/ProgramManualManager';
import {
  DOCUMENT_TYPE_KEY,
  DOCUMENT_STATUS,
  PROGRAM_MANUAL_STATUS,
  MODULE_NAMES,
} from '../constants/AppConstants';
import ProgramManualHelper from '../helpers/ProgramManualHelper';
import MetaDataManager from '../database/reactDataManager/MetaDataManager';
import metaDataDto from '../models/metaDataModel';

import { getLocale } from '../services/storageService/GlobalData';
import localization from '../localization/i18n';

export const storeRequest = (state) => state.programManuals;

export function* getProgramManualsAsync({ payload }) {
  try {
    let store = yield select(storeRequest);
    const update = payload.update;
    let lastSynced = null; // lastsync time for syncing
    let metaData = MetaDataManager.getMetaDataByModule(
      MODULE_NAMES.PROGRAM_MANUAL_MODULE
    );
    if (metaData) {
      lastSynced = metaData.lastSynced;
    }

    yield put(getProgramManual({ update }));
    const query = store.query;

    if (navigator.onLine && update) {
      let response = yield call(
        ProgramManualService.getProgramManuals,
        lastSynced
      );

      let metaDataModel = metaDataDto({
        moduleName: MODULE_NAMES.PROGRAM_MANUAL_MODULE,
        lastSynced: response.time,
      });
      MetaDataManager.saveMetaData(metaDataModel); // upsert activity metadata with new lastSync time
      lastSynced = response.time; // setting lastsync time in store

      response = response.data.feedlot_documents;

      if (response.length > 0) {
        let manualAdded = ProgramManualManager.saveProgramManual(response);
      }

      let tagsResponse = yield call(ProgramManualService.getProgramManualTags);
      tagsResponse = tagsResponse.data.menu_items;

      if (tagsResponse.length > 0) {
        let tagsAdded = ProgramManualManager.saveProgramManualTags(
          tagsResponse
        );
      }
    }

    let tags = yield call(ProgramManualManager.getProgramManualTags);
    yield put(getProgramManualTags(tags));

    let programManuals = yield call(
      ProgramManualManager.getProgramManual,
      query
    );
    const data = programManuals.length > 0 ? programManuals : [];

    yield put(getProgramManualSuccess({ data, update }));
    yield call(getDocuments);
  } catch (e) {
    yield put(getProgramManualFailure(e));
  }
}

function* getProgramManualByIdAsync({ payload }) {
  try {
    yield put(getProgramManualById());
    const documentNumber = payload.document_number;
    // const document_id = payload.document_id;

    if (navigator.onLine) {
      let response = yield call(
        ProgramManualService.getProgramManualById,
        documentNumber
      );
      response = response.data.feedlot_documents;
      if (response.length > 0) {
        // console.log('responseresponseresponse', response[0]);
        ProgramManualManager.saveProgramManual(response);
      }
    }

    let programManual = yield call(
      ProgramManualManager.getProgramManualById,
      documentNumber
    );
    programManual['users'] = programManual.users ? programManual.users : [];
    programManual['document_tags'] = programManual.document_tags
      ? programManual.document_tags
      : '';

    yield put(getProgramManualByIdSuccess(programManual));
  } catch (e) {
    yield put(getProgramManualByIdFailure(e));
  }
}

function* getProgramManualActivitiesByIdAsync({ payload }) {
  try {
    yield put(getProgramManualActivitiesById());
    const documentNumber = payload;

    let activities = yield call(
      ProgramManualManager.getProgramManualActivitiesById,
      documentNumber
    );

    yield put(getProgramManualActivitiesByIdSuccess(activities));
  } catch (e) {
    yield put(getProgramManualActivitiesByIdFailure(e));
  }
}

function* createTrainingAsync({ payload }) {
  try {
    if (navigator.onLine) {
      yield put(createTraining());

      let response = yield call(ProgramManualService.createTraining, payload);
      let feedlot_document_id =
        response.data.feedlot_document.feedlot_document_id;

      if (feedlot_document_id > 0) {
        let urls =
          payload.feedlot_document.urls &&
          payload.feedlot_document.urls.map((q) => {
            return {
              feedlot_document_id: feedlot_document_id,
              url: q,
            };
          });
        try {
          let urlResponse = yield call(ProgramManualService.createTrainingURL, {
            feedlot_document: {
              feedlot_document_id: feedlot_document_id,
              document_urls: urls,
            },
          });
        } catch (e) { }
      }

      yield put(createTrainingSuccess());
    } else {
      yield put(
        createTrainingFailure({
          error: localization[getLocale()].NO_INTERNET_CONNECTION,
        })
      );
    }
  } catch (e) {
    yield put(createTrainingFailure({
      error: e.message
    }));
  }
}

function* updateTrainingAsync({ payload }) {
  try {
    if (navigator.onLine) {
      yield put(updateTraining());

      let response = yield call(ProgramManualService.updateTraining, payload);
      let feedlot_document_id =
        response.data.feedlot_document.feedlot_document_id;

      if (feedlot_document_id > 0) {
        let urls =
          payload.feedlot_document.urls &&
          payload.feedlot_document.urls.map((q) => {
            return {
              feedlot_document_id: feedlot_document_id,
              url: q,
            };
          });

        try {
          let oldUrls =
            payload.feedlot_document.urlIds &&
            payload.feedlot_document.urlIds.map((q) => {
              return {
                feedlot_document_url_id: q,
              };
            });

          yield call(ProgramManualService.deleteProgramManualUrl, {
            feedlot_document: {
              feedlot_document_id: feedlot_document_id,
              document_urls: oldUrls,
            },
          });
        } catch { }

        try {
          yield call(ProgramManualService.updateProgramManualUrl, {
            feedlot_document: {
              feedlot_document_id: feedlot_document_id,
              document_urls: urls,
            },
          });
        } catch { }
      }

      yield put(updateTrainingSuccess());
    } else {
      yield put(
        updateTrainingFailure({
          error: localization[getLocale()].NO_INTERNET_CONNECTION,
        })
      );
    }
  } catch (e) {
    yield put(updateTrainingFailure(e));
  }
}

function* getDeprecatedProgramManualsAsync({ payload }) {
  try {
    if (navigator.onLine) {
      yield put(getDeprecatedProgramManuals());
      const update = payload.update;

      if (update) {
        let programManualResp = yield call(
          ProgramManualService.getDeprecatedProgramManuals
        );
        programManualResp = programManualResp.data.feedlot_documents;

        if (programManualResp.length > 0) {
          let manualsAdded = ProgramManualManager.saveDeprecatedProgramManual(
            programManualResp
          );
          console.log(
            'save deprecated program manuals to DB successfull',
            manualsAdded
          );
        }
      }

      let manuals = yield call(
        ProgramManualManager.getDeprecatedProgramManuals
      );
      let tags = yield call(ProgramManualManager.getProgramManualTags);
      yield put(getProgramManualTags(tags));

      yield put(getDeprecatedProgramManualsSuccess(manuals));
    } else {
      yield put(
        getDeprecatedProgramManualsFailure({
          error: localization[getLocale()].NO_INTERNET_CONNECTION,
        })
      );
    }
  } catch (e) {
    yield put(getDeprecatedProgramManualsFailure(e));
  }
}

// function* getMissedProgramManualsAsync({ payload }) {
//   try {
//     yield put(getMissedProgramManuals());
//     let programManual = yield call(ProgramManualManager.getProgramManual, "");
//     var trainingsArray = [];

//     programManual.map(function (record) {
//       if (
//         record.document_type_key == DOCUMENT_TYPE_KEY.TRAINING &&
//         record.users.length > 0
//       ) {
//         var assignedUsers = record.users;
//         var numberOfUsersMissedTrainings = 0;

//         for (var index in assignedUsers) {
//           if (
//             assignedUsers[index].document_viewed == DOCUMENT_STATUS.UNVIEWED
//           ) {
//             var viewed_by = assignedUsers[index].viewed_by;
//             if (
//               ProgramManualHelper.checkMissedDateForUserTrainings(viewed_by)
//             ) {
//               numberOfUsersMissedTrainings++;
//             }
//           }
//         }
//         console.log("record", record);
//         record.assigned_users = assignedUsers;
//         record.users_missed_document_count = numberOfUsersMissedTrainings;
//         trainingsArray.push(record);
//       }
//     });
//     console.log("trainingsArray", trainingsArray);
//     yield put(
//       getMissedProgramManualsSuccess([
//         {
//           key: "missed",
//           data: trainingsArray,
//         },
//       ])
//     );
//   } catch (e) {
//     yield put(getMissedProgramManualsFailure(e));
//   }
// }

function* moveProgramManualToActiveAsync({ payload }) {
  try {
    yield put(moveProgramManualToActive());
    const documentNumber = payload.documentNumber;

    let response = yield call(
      ProgramManualService.moveProgramManualToActive,
      documentNumber
    );

    let manualAdded = ProgramManualManager.updateProgramManualStatus(
      documentNumber,
      PROGRAM_MANUAL_STATUS.ACTIVE
    );

    yield put(moveProgramManualToActiveSuccess());
  } catch (e) {
    yield put(moveProgramManualToActiveFailure(e));
  }
}

// function* markViewedAsync({ payload }) {
//   try {
//     yield put(markViewed());
//     const documentNumber = payload.documentNumber;
//     const feedlotDocumentId = payload.feedlotDocumentId;

//     let response = yield call(ProgramManualService.markViewed, documentNumber);

//     let manualAdded = ProgramManualManager.markViewed(feedlotDocumentId);

//     yield put(markViewedSuccess());
//   } catch (e) {
//     yield put(markViewedFailure(e));
//   }
// }

// export function* getProgramManualTagsAsync() {
//   try {
//     let tags = yield call(ProgramManualManager.getProgramManualTags);
//     yield put(getProgramManualTags(tags));
//   } catch (e) {}
// }

function* moveProgramManualToBinAsync({ payload }) {
  try {
    yield put(moveProgramManualToBin());
    const documentNumber = payload.documentNumber;
    // const feedlotDocumentId = payload.feedlotDocumentId;

    let response = yield call(
      ProgramManualService.moveProgramManualToBin,
      documentNumber
    );

    let manualAdded = ProgramManualManager.updateProgramManualStatus(
      documentNumber,
      PROGRAM_MANUAL_STATUS.BIN
    );

    yield put(moveProgramManualToBinSuccess());
  } catch (e) {
    yield put(moveProgramManualToBinFailure(e));
  }
}

function* getDocuments(action) {
  try {
    let trainings = ProgramManualManager.getTrainings();
    let tags = ProgramManualManager.getProgramManualTags();
    yield put(getProgramManualTags(tags));

    yield put(getDocumentListSuccess(trainings));
  } catch (e) {
    yield put(getDocumentListFailure({ error: e }));
  }
}

// function* emailReportAsync({ payload }) {
//   try {
//     yield put(emailReport());
//     let response = yield call(
//       payload.type === "binned"
//         ? ProgramManualService.emailBinnedReport
//         : ProgramManualService.emailReport,
//       payload.data
//     );
//     yield put(emailReportSuccess());
//   } catch (e) {
//     yield put(emailReportFailure(e));
//   }
// }

function* deleteProgramManualUrlAsync({ payload }) {
  try {
    yield put(deleteProgramManualUrl());
    let response = yield call(
      ProgramManualService.deleteProgramManualUrl,
      payload
    );

    // if (payload.feedlot_document.document_urls.length > 0) {
    //   let manualAdded = ProgramManualManager.deleteURL(
    //     payload.feedlot_document.document_urls[0],
    //   );
    // }

    // yield put(getProgramManualRequest());

    yield put(
      getProgramManualByIdRequest({
        feedlot_document_id: payload.feedlot_document.feedlot_document_id,
        document_number: payload.feedlot_document.document_number,
      })
    );
    yield put(deleteProgramManualUrlSuccess());
  } catch (e) {
    yield put(deleteProgramManualUrlFailure(e));
  }
}

function* updateProgramManualUrlAsync({ payload }) {
  try {
    yield put(updateProgramManualUrl());
    let response = yield call(
      ProgramManualService.updateProgramManualUrl,
      payload
    );
    yield put(updateProgramManualUrlSuccess());
  } catch (e) {
    yield put(updateProgramManualUrlFailure(e));
  }
}

function* programManualsSaga() {
  yield takeEvery(
    ACTION_CONSTANTS.GET_PROGRAM_MANUAL_REQUEST,
    getProgramManualsAsync
  );
  yield takeEvery(
    ACTION_CONSTANTS.GET_PROGRAM_MANUAL_BY_ID_REQUEST,
    getProgramManualByIdAsync
  );
  yield takeEvery(
    ACTION_CONSTANTS.GET_PROGRAM_MANUAL_ACTIVITIES_BY_ID_REQUEST,
    getProgramManualActivitiesByIdAsync
  );
  yield takeEvery(
    ACTION_CONSTANTS.CREATE_TRAINING_REQUEST,
    createTrainingAsync
  );
  yield takeEvery(
    ACTION_CONSTANTS.UPDATE_TRAINING_REQUEST,
    updateTrainingAsync
  );
  yield takeEvery(
    ACTION_CONSTANTS.GET_DEPRECATED_PROGRAM_MANUAL_REQUEST,
    getDeprecatedProgramManualsAsync
  );
  // yield takeEvery(
  //   ACTION_CONSTANTS.GET_MISSED_PROGRAM_MANUAL_REQUEST,
  //   getMissedProgramManualsAsync
  // );
  yield takeEvery(
    ACTION_CONSTANTS.MOVE_PROGRAM_MANUALS_TO_ACTIVE_REQUEST,
    moveProgramManualToActiveAsync
  );
  // yield takeEvery(ACTION_CONSTANTS.MARK_VIEWED_REQUEST, markViewedAsync);
  yield takeEvery(
    ACTION_CONSTANTS.MOVE_PROGRAM_MANUALS_TO_BIN_REQUEST,
    moveProgramManualToBinAsync
  );
  yield takeLatest(ACTION_CONSTANTS.GET_DOCUMENTS_REQUEST, getDocuments);
  // yield takeEvery(ACTION_CONSTANTS.EMAIL_REPORT_REQUEST, emailReportAsync);
  yield takeEvery(
    ACTION_CONSTANTS.DELETE_PROGRAM_MANUAL_URL_REQUEST,
    deleteProgramManualUrlAsync
  );
  yield takeEvery(
    ACTION_CONSTANTS.UPDATE_PROGRAM_MANUAL_URL_REQUEST,
    updateProgramManualUrlAsync
  );
  // yield takeEvery(
  //   ACTION_CONSTANTS.GET_PROGRAM_MANUAL_TAG_REQUEST,
  //   getProgramManualTagsAsync
  // );
}

export default programManualsSaga;
