import moment from 'moment';
import LocaleHelper from '../helpers/LocaleHelper'
import {
  userRoles,
  ACTIVITY_PERMISSIONS,
  DATE_FORMATS,
} from '../constants/AppConstants';
import { AddUserFormInitialValues } from '../constants/FormInitialValues';

class UserHelper {
  static filterUsersWithPermissionKey(dbUsers, permission_key) {
    let assignedUsers = [];
    dbUsers.forEach((user) => {
      let role = user.user_role_key;

      //If activity permission is Supervisor, then include only supervisors and feedlot manager
      if (permission_key === ACTIVITY_PERMISSIONS.SUPERVISOR) {
        if (
          role === userRoles.SUPERVISOR ||
          role === userRoles.FEEDLOT_MANAGER
        ) {
          assignedUsers.push(user);
        }
      } else if (permission_key === ACTIVITY_PERMISSIONS.FEEDLOT_MANAGER) {
        //If activity permission is Feedlotmanager, then include only feedlot managers
        if (role === userRoles.FEEDLOT_MANAGER) {
          assignedUsers.push(user);
        }
      } else {
        assignedUsers.push(user);
      }
    });
    return assignedUsers;
  }

  static getUserFormInitialValues(user, documents) {
    if (user) {
      return {
        user_name: user.user_name || '',
        password: user.password || '',
        confirmPassword: user.password || '',
        user_role_key: user.user_role_key || LocaleHelper.USER_ROLES_PICKER_VALUES()[0].value,
        user_id: user.user_id.toString(),
        is_active: user.status.toString(),
        joining_date: new Date(
          Date.parse(
            moment(user.joining_date, DATE_FORMATS.YYYY_MM_DD).format(),
          ),
        ),
        first_name: user.profile.first_name || '',
        last_name: user.profile.last_name || '',
        email_address: user.profile.email_address || '',
        document_numbers: this.getAssignedDocumentsByUser(
          user.user_id,
          documents,
        ),
      };
    } else {
      return AddUserFormInitialValues;
    }
  }

  static getAssignedDocumentsByUser(user_id, documents) {
    let assignedDocuments = [];
    documents.map((document) => {
      document.users.map((assignedUsers) => {
        if (assignedUsers.user_id === user_id) {
          assignedDocuments.push(document.document_number);
        }
      });
    });
    return assignedDocuments;
  }

  static getUserRequestData(payload) {
    let request = {
      user: {
        user_name: payload.user_name,
        password: payload.password,
        user_role_key: payload.user_role_key.toString(),
        user_id: payload.user_id ? payload.user_id.toString() : null,
        status: parseInt(payload.is_active, 10),
        joining_date: moment(payload.joining_date).format(
          DATE_FORMATS.YYYY_MM_DD,
        ),
        profile: {
          first_name: payload.first_name,
          last_name: payload.last_name,
          email_address: payload.email_address || '',
        },
        document_numbers: payload.document_numbers || [],
      },
    };

    return request;
  }

  static setAssignedDocumentSelected(documents, assignedDocumentNumbers) {
    documents.map((doc) => {
      if (assignedDocumentNumbers.indexOf(doc.document_number) != -1) {
        doc.isSelect = true;
      }
    });
    return documents;
  }
}

export default UserHelper;
