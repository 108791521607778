import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Spinner, Modal } from 'reactstrap';

import AppContainer from '../../../../components/AppContainer';
import PBIcon from '../../../../components/PBIcon';
import MultiSelectList from '../../../../components/MultiSelectList';

import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

import { setVisibleToUsers } from '@progressivebeef/shared/src/store/actions/programMaunals';
import { globalHelper } from '@progressivebeef/shared/src/helpers';

class VisibleTo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      usersListing: [],
    };
  }

  savePress = (filter) => {
    this.props.setVisibleToUsers(filter);
    this.props.dismissModal();
  };

  componentDidMount() {
    this.getListOfUsers();
  }

  componentDidUpdate(prevProps) {
    const { users } = this.props;
    if (users !== prevProps.users && users) {
      this.getListOfUsers();
    }
  }

  getListOfUsers() {
    const { users, visibleToUsers } = this.props;
    var usersListing = users.map(function (item) {
      const index = visibleToUsers.findIndex((m) => m.key === item.user_id);
      if (index === -1) {
        return {
          key: item.user_id,
          value: item.profile?.first_name + ' ' + item.profile?.last_name,
          isSelect:
            visibleToUsers && visibleToUsers.length === 0 ? true : false,
        };
      } else {
        return visibleToUsers[index];
      }
    });

    const allIndex = visibleToUsers.findIndex((m) => m.key === 'All');

    usersListing = [
      allIndex === -1
        ? {
            key: 'All',
            value: 'All',
            isSelect:
              visibleToUsers && visibleToUsers.length === 0 ? true : false,
          }
        : visibleToUsers[allIndex],
      ...usersListing,
    ];
    this.setState({
      usersListing: usersListing,
    });
  }

  render() {
    const { loading, history, modalVisible } = this.props;
    const { usersListing } = this.state;

    return (
      <>
        <Modal className="visible-to-user-popup" isOpen={modalVisible}>
          <AppContainer
            title={localization[getLocale()].ASSIGN_DOCUMENT}
            hideBottomBar
            iconHeader
            history={history}
            IconLeftHeader={this.renderLeftHeader}
            noncollapsible
          >
            {globalHelper.renderIf(loading)(
              <div className="bottom-infinite-loader">
                <Spinner color="primary" />
              </div>
            )}
            <MultiSelectList
              buttonText={localization[getLocale()].SAVE}
              data={usersListing}
              onPress={this.savePress}
            />
          </AppContainer>
        </Modal>
      </>
    );
  }

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          name={'chevron-left'}
          onPress={() => this.props.dismissModal()}
        />
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setVisibleToUsers: (payload) => dispatch(setVisibleToUsers(payload)),
  };
};

const mapStateToProps = (state) => {
  const { users, userLoading } = state.user;
  const { visibleToUsers } = state.programManuals;

  return {
    loading: userLoading,
    users: users,
    visibleToUsers,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VisibleTo);
