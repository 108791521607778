import {
  put,
  takeEvery,
  call,
  select,
  take,
  takeLatest,
  delay,
} from 'redux-saga/effects';
import _ from 'lodash';
import ACTION_CONSTANTS from '../constants/actionConstants/index';
import {
  getActivities,
  getActivitiesSuccess,
  getActivitiesFailure,
  sortActivitiesSuccess,
  getActivitiesTags,
  getActivityInstancesSuccess,
  getDeprecatedActivities,
  getDeprecatedActivitiesSuccess,
  getDeprecatedActivitiesFailure,
  getActivitySubmissionsSuccess,
  getActivitySubmissionsFailure,
  deleteActivityInstancesSuccess,
  deleteActivityInstancesFailure,
  addActivityInstancesSuccess,
  addActivityInstancesFailure,
  assignActivityFailure,
  assignActivitySuccess,
  setActivityReminderSuccess,
  setActivityReminderFailure,
  deleteActivityReminderSuccess,
  deleteActivityReminderFailure,
  setActivityScheduleSuccess,
  setActivityScheduleFailure,
  postActivityPrintSuccess,
  postActivityPrintFailure,
  postActivitySubmissionSuccess,
  postActivitySubmissionFailure,
  postFormActivityPrintSuccess,
  postFormActivityPrintFailure,
  getActivityByIdSuccess,
  getActivityByIdFailure,
  postActivityAttachmentSuccess,
  postActivityAttachmentFailure,
  getSubmissionByIdSuccess,
  getSubmissionByIdFailure,
  removeActivitySubmissionSuccess,
  removeActivitySubmissionFailure,
  getPerformanceActivitiesSuccess,
  getPerformanceActivitiesFailure,
  postActivityAttachmentRequest,
  syncActivitySubmissionsSuccess,
  syncActivitySubmissionsFailure,
  postPendingActivitySubmissionsFailure,
  postActivitySubmissionsOnServerRequest,
  postActivitySubmissionsOnServerSuccess,
  postActivitySubmissionsOnServerFailure,
  postPendingActivitySubmissionsSuccess,
  setCurrentActivity,
  getActivityRevisionSuccess,
  getActivityRevisionFailure,
  postRemoveActivitySubmissionsOnServerRequest,
  postRemoveActivitySubmissionsOnServerSuccess,
  postRemoveActivitySubmissionsOnServerFailure,
  resetSubmissionsSuccess,
  resetSubmissionsFailure,
  postActivitySubmissionsOnServerRemoved,
} from '../store/actions/activities';
import ActivityService from '../services/api/activities';
import InstanceService from '../services/api/instance';
import {
  ACTIVITY_TYPES,
  MODULE_NAMES,
  SUBMISSION_STATUS,
  DATE_FORMATS,
  ACTIVITY_TYPE,
  IMAGE_FILE_UPLOAD_ALLOWED_TYPES,
} from '../constants/AppConstants';
import ActivitiesHelper from '../helpers/ActivitiesHelper';
import ActivityManager from '../database/reactDataManager/ActivityManager';
import InstanceManager from '../database/reactDataManager/InstanceManager';
import SubmissionsManager from '../database/reactDataManager/SubmissionsManager';
import { getLocale } from '../services/storageService/GlobalData';
import localization from '../localization/i18n';
import { globalHelper } from '../helpers';
import { syncActivityDataRequest } from '../store/actions/dataSync';
import MetaDataManager from '../database/reactDataManager/MetaDataManager';
import metaDataDto from '../models/metaDataModel';
import moment from 'moment';
import { SUBMISSION_FIELD_TYPES } from '../constants/FormConstants';
import { SERVER_STATUS_CODES } from '../constants/ServerStatusCodes';
import { getPendingActionsCountRequest } from '../store/actions/dashboard';
import submissionModel from '../models/submissionModel';
import { Events, Properties } from '../constants/MixpanelConstants';
import { trackEvent } from '../services/reactMixpanelService';
import { showErrorAlert } from '../../../web/src/components/Alerts';
import { history } from '../../../web/src/bootSetup';
import RouteConstants from '../constants/RouteConstants';
// import { getBackdatedActivitiesAsync } from '../reactsagas/dashboard';

export const activityStoreRequest = (state) => state.activities;
export const dashboardStoreRequest = (state) => state.dashboard;
export const userStoreRequest = (state) => state.user;

export function* getActivitiesAsync({ payload }) {
  try {
    let activityStore = yield select(activityStoreRequest);
    let lastSynced = null; // lastsync time for syncing
    let activityMetaData = MetaDataManager.getMetaDataByModule(
      MODULE_NAMES.ACTIVITY_MODULE, // get activity metadata for last sync time
    );
    if (activityMetaData) {
      lastSynced = activityMetaData.lastSynced;
    }

    const update = payload.update;

    yield put(getActivities({ update }));
    const query = activityStore.query;
    const filter = activityStore.filter;

    let updateFocusedActivity = false;
    if (navigator.onLine && update) {
      try {
        let activitiesResp = yield call(
          ActivityService.getActivities,
          lastSynced,
        );

        let metaDataModel = metaDataDto({
          moduleName: MODULE_NAMES.ACTIVITY_MODULE,
          lastSynced: activitiesResp.time,
        });
        MetaDataManager.saveMetaData(metaDataModel); // upsert activity metadata with new lastSync time
        lastSynced = activitiesResp.time; // setting lastsync time in store

        activitiesResp = activitiesResp.data.feedlot_activites;

        if (activitiesResp.length > 0) {
          let activitiesAdded = ActivityManager.saveActivities(activitiesResp);
          // console.log('save activities to DB successfull', activitiesAdded);

          updateFocusedActivity = true;
        }
      } catch (e) {
        console.log('error in api call, returning data from db', e);
      }
    }

    let tags = yield call(ActivityManager.getTags);
    yield put(getActivitiesTags(tags));

    let activities = yield call(ActivityManager.getActivities, query, filter);
    yield put(
      sortActivitiesSuccess(ActivitiesHelper.sortActivities(activities)),
    );

    activities = ActivitiesHelper.separateActivities(activities);
    let separatedActivities = ActivitiesHelper.activitiesSeparatedModel(
      ActivitiesHelper.groupActivities(activities, ACTIVITY_TYPES.DAILY),
      ActivitiesHelper.groupActivities(activities, ACTIVITY_TYPES.WEEKLY),
      ActivitiesHelper.groupActivities(activities, ACTIVITY_TYPES.MONTHLY),
      ActivitiesHelper.groupActivities(activities, ACTIVITY_TYPES.QUATERLY),
      ActivitiesHelper.groupActivities(activities, ACTIVITY_TYPES.UPCOMMING),
      ActivitiesHelper.groupActivities(activities, ACTIVITY_TYPES.UNSCHEDULED),
      ActivitiesHelper.groupActivities(
        activities,
        ACTIVITY_TYPES.WAITING_FOR_SUBMISSION,
      ),
    );

    const data = {
      separatedActivities,
      update,
    };

    if (update && !query && !filter.length) {
      data.dueToday =
        ActivitiesHelper.calculateActivitiesDueTodayData(separatedActivities);
    }

    if (
      activityStore.focusedActivity &&
      activityStore.focusedActivity.feedlot_activity_id &&
      updateFocusedActivity
    ) {
      const selectedActivityDuration = _.find(
        data.separatedActivities,
        _.flow(
          _.property('data'),
          _.partialRight(_.some, {
            feedlot_activity_id:
              activityStore.focusedActivity.feedlot_activity_id,
          }),
        ),
      );

      if (selectedActivityDuration)
        yield put(
          setCurrentActivity(
            _.find(selectedActivityDuration.data, [
              'feedlot_activity_id',
              activityStore.focusedActivity.feedlot_activity_id,
            ]),
          ),
        );
    }

    yield put(getActivitiesSuccess(data));
  } catch (e) {
    yield put(getActivitiesFailure(e));
  }
}

function* deleteActivityInstancesAsync({ payload }) {
  try {
    if (navigator.onLine) {
      const res = yield call(InstanceService.deleteActivityInstance, payload);
      if (res && res.data) {
        const instances = res.data.feedlot_activity_instances;
        InstanceManager.saveInstances(instances);
        yield call(getActivitiesAsync, {
          payload: { update: true },
        });
        yield put(deleteActivityInstancesSuccess(instances));
      } else {
        yield put(
          deleteActivityInstancesFailure({
            errorMessage: null, // localization[getLocale()].SOMETHING_WENT_WRONG,
          }),
        );
      }
    } else {
      yield put(
        deleteActivityInstancesFailure({
          errorMessage: localization[getLocale()].NO_INTERNET_CONNECTION,
        }),
      );
    }
  } catch (e) {
    yield put(
      deleteActivityInstancesFailure({
        errorMessage: e.message,
      }),
    );
  }
}

function* getDeprecatedActivitiesAsync({ payload }) {
  try {
    yield put(getDeprecatedActivities());
    const update = payload.update;

    if (navigator.onLine && update) {
      let activitiesResp = yield call(ActivityService.getDeprecatedActivities);
      activitiesResp = activitiesResp.data.feedlot_activites;

      if (activitiesResp.length > 0) {
        let activitiesAdded =
          ActivityManager.saveDeprecatedActivities(activitiesResp);
        // console.log('save activities to DB successfull', activitiesAdded);
      }
    }

    let activities = yield call(ActivityManager.getDeprecatedActivities);

    yield put(
      getDeprecatedActivitiesSuccess([
        {
          key: 'deprecated',
          data: activities,
        },
      ]),
    );
  } catch (e) {
    yield put(getDeprecatedActivitiesFailure(e));
  }
}

function* getBackdatedActivitySubmissionsAsync({ payload }) {
  const { activityNumber, instanceName, startDate, endDate } = payload;

  if (navigator.onLine) {
    try {
      const response = yield call(
        ActivityService.getBackdatedActivitySubmissions,
        activityNumber,
        instanceName,
        startDate,
        endDate,
      );

      let data = response ? response.data.back_dated_submissions : [];

      if (instanceName) {
        SubmissionsManager.clearInstanceSubmissions(instanceName);
      } else {
        SubmissionsManager.clearActivitySubmissions(activityNumber);
      }

      SubmissionsManager.saveActivitySubmissions(
        data,
        activityNumber,
        instanceName,
        true,
      );
    } catch (e) {
      console.log('getActivitySubmissionsFailure', e);
      yield put(
        getActivitySubmissionsFailure({
          submissionListError: null, // localization[getLocale()].SOMETHING_WENT_WRONG,
        }),
      );
    }
  }
  yield call(
    getSubmissionsFromDB,
    activityNumber,
    instanceName,
    null,
    null,
    true,
  );
}

function* getActivitySubmissionsAsync(action) {
  try {
    const { activityNumber, instanceName, instanceId } = action.payload;
    let { startIndex } = action.payload;

    const activityStore = yield select(activityStoreRequest);
    let submissions = _.cloneDeep(activityStore.submissions);
    if (instanceId) {
      submissions = submissions.filter((q) => q.instance_value == instanceName);
    } else {
      submissions = submissions.filter(
        (q) => q.activity_number == activityNumber,
      );
    }
    let submissionsArr = [];

    let recordsAvailable = null;
    if (navigator.onLine) {
      const response = yield call(
        ActivityService.getActivitySubmissions,
        activityNumber,
        instanceName,
        startIndex,
      );

      if (
        response &&
        response.data &&
        response.data.submissions &&
        response.data.submissions.length > 0
      ) {
        recordsAvailable = true;
        if (startIndex === 0) {
          if (instanceId) {
            SubmissionsManager.clearInstanceSubmissions(instanceName);
          } else {
            SubmissionsManager.clearActivitySubmissions(activityNumber);
          }
        }
        var status = SubmissionsManager.saveActivitySubmissions(
          response.data.submissions,
          activityNumber,
          instanceName,
        );

        if (!status) {
          let arr = [];

          for (let i in response.data.submissions) {
            arr.push(
              submissionModel(
                response.data.submissions[i],
                null,
                activityNumber,
                instanceName,
                false,
              ),
            );
          }
          submissionsArr = [...arr];
        }
      } else {
        recordsAvailable = false;
      }
    }

    if (
      startIndex === 0 &&
      (recordsAvailable !== null ? !recordsAvailable : false)
    ) {
      startIndex = 0;
    } else if (
      startIndex > 0 &&
      (recordsAvailable !== null ? !recordsAvailable : false)
    ) {
      startIndex = startIndex - 10;
    }

    let dbSubmissions = [];

    if (instanceId) {
      dbSubmissions = SubmissionsManager.getInstanceSubmissions(
        activityNumber,
        instanceName,
        startIndex,
      );
    } else {
      dbSubmissions = SubmissionsManager.getActivitySubmissions(
        activityNumber,
        startIndex,
      );
    }

    submissionsArr = [...dbSubmissions, ...submissionsArr];

    if (submissionsArr.length == 0 && !navigator.onLine) {
      yield put(
        getActivitySubmissionsFailure({
          submissionListError: localization[getLocale()].NO_INTERNET_CONNECTION,
        }),
      );
    } else {
      // const submissionsList = Object.keys(
      //   globalHelper.parseRealmList(submissions)
      // ).map((key) => submissions[key]);
      yield put(
        getActivitySubmissionsSuccess({
          submissions: submissionsArr,
          updateSubmissionsList:
            recordsAvailable !== null ? !recordsAvailable : false,
        }),
      );
    }
  } catch (e) {
    console.log(e);
    yield put(
      getActivitySubmissionsFailure({
        submissionListerror: null, // localization[getLocale()].SOMETHING_WENT_WRONG,
      }),
    );
  }
}

function* getSubmissionsFromDB(
  activityNumber,
  instanceName,
  startIndex,
  recordsAvailable,
  isBackdated,
) {
  try {
    let submissions = [];
    if (instanceName) {
      submissions = SubmissionsManager.getInstanceSubmissions(
        activityNumber,
        instanceName,
        startIndex,
        isBackdated,
      );
    } else {
      submissions = SubmissionsManager.getActivitySubmissions(
        activityNumber,
        startIndex,
        isBackdated,
      );
    }

    if (submissions.length == 0 && !navigator.onLine) {
      yield put(
        getActivitySubmissionsFailure({
          submissionListError: localization[getLocale()].NO_INTERNET_CONNECTION,
        }),
      );
    }
    const submissionsList = Object.keys(
      globalHelper.parseRealmList(submissions),
    ).map((key) => submissions[key]);
    yield put(
      getActivitySubmissionsSuccess({
        submissions: submissionsList,
        updateSubmissionsList:
          recordsAvailable !== null ? !recordsAvailable : false,
      }),
    );
  } catch (e) {
    console.log('getSubmissionsFromDB fail', e);
    yield put(
      getActivitySubmissionsFailure({
        submissionListerror: null,
      }),
    );
  }
}

function* setActivityReminderAsync({ payload }) {
  try {
    if (navigator.onLine) {
      yield call(ActivityService.updateActivityReminder, payload);
      yield call(getActivitiesAsync, {
        payload: { update: true },
      });
      yield put(setActivityReminderSuccess());
    } else {
      yield put(
        setActivityReminderFailure({
          error: localization[getLocale()].NO_INTERNET_CONNECTION,
        }),
      );
    }
  } catch (e) {
    yield put(setActivityReminderFailure({ error: e.message }));
  }
}

function* setActivityScheduleAsync({ payload }) {
  try {
    if (navigator.onLine) {
      yield call(ActivityService.postActivitySchedule, payload);
      yield call(getActivitiesAsync, {
        payload: { update: true },
      });
      // yield call(getBackdatedActivitiesAsync, {
      //   payload: { update: true },
      // });
      yield put(setActivityScheduleSuccess());
    } else {
      yield put(
        setActivityScheduleFailure({
          error: localization[getLocale()].NO_INTERNET_CONNECTION,
        }),
      );
    }
  } catch (e) {
    yield put(setActivityScheduleFailure({ error: e.message }));
  }
}

function* removeActivitySubmission({ payload }) {
  try {
    if (navigator.onLine) {
      yield put(postRemoveActivitySubmissionsOnServerRequest(payload));
      yield take(
        ACTION_CONSTANTS.POST_REMOVE_ACTIVITY_SUBMISSION_ON_SERVER_SUCCESS,
      );
      yield put(removeActivitySubmissionSuccess());
    } else {
      yield delay(100);
      const { requestBody } = payload;
      let { feedlot_activity_submission_cancellation } = requestBody;

      let submissionToRemove =
        SubmissionsManager.getSubmissionByFeedlotActivitySubmissionId(
          feedlot_activity_submission_cancellation.feedlot_activity_submission_id,
        );
      submissionToRemove.feedlot_activity_submission_cancellation =
        feedlot_activity_submission_cancellation;

      // save submission offline
      const id = yield call(
        SubmissionsManager.saveActivitySubmissionByStatus,
        submissionToRemove,
        submissionToRemove.activity_number,
        submissionToRemove.instance_value,
        SUBMISSION_STATUS.PENDING,
      );

      const submission = SubmissionsManager.getSubmissionById(id);
      const activityStore = yield select(activityStoreRequest);
      let submissions = _.cloneDeep(activityStore.submissions);
      let index = submissions.findIndex((q) => q.id === submission.id);
      submissions.splice(index, 1, submission);

      yield put(
        removeActivitySubmissionSuccess({ removePending: true, submissions }),
      );
      yield put(getPendingActionsCountRequest()); //to update count on dashboard
    }
  } catch (e) {
    yield put(removeActivitySubmissionFailure({ error: e.message }));
  }
}

function* postRemoveActivitySubmissionsOnServer({ payload }) {
  try {
    if (navigator.onLine) {
      const res = yield call(
        ActivityService.removeActivitySubmission,
        payload.requestBody,
      );
      if (res && res.data && res.data.submission) {
        let { backdated, startDate, endDate } = payload;
        yield put(
          syncActivityDataRequest({
            submission: res.data.submission,
            backdated,
            startDate,
            endDate,
          }),
        );
        yield take(ACTION_CONSTANTS.SYNC_ACTIVITY_SUBMISSIONS_SUCCESS);
      }
      yield put(postRemoveActivitySubmissionsOnServerSuccess());
    } else {
      yield put(
        postRemoveActivitySubmissionsOnServerFailure({
          error: localization[getLocale()].NO_INTERNET_CONNECTION,
        }),
      );
    }
  } catch (e) {
    console.log('postRemoveActivitySubmissionsOnServerFailure', e);
    yield put(
      postRemoveActivitySubmissionsOnServerFailure({ error: e.message }),
    );
  }
}

function* syncActivitySubmissions({ payload }) {
  try {
    const activityStore = yield select(activityStoreRequest);
    let { submissions } = activityStore;
    let submissionsList = _.cloneDeep(submissions);
    const { activity_number } = activityStore.focusedActivity;
    let instanceName = null;

    if (activityStore.focusedInstance) {
      instanceName = activityStore.focusedInstance.instance_name;
    }

    let lastSynced = null;
    let submissionMetaData = MetaDataManager.getMetaDataByModule(
      MODULE_NAMES.SUBMISSION_MODULE, // get activity metadata for last sync time
    );
    if (submissionMetaData && submissionMetaData[0]) {
      lastSynced = submissionMetaData[0].lastSynced;
    }
    let res = yield call(
      ActivityService.syncActivitySubmissions,
      activity_number,
      instanceName,
      lastSynced,
    );

    let metaDataModel = metaDataDto({
      moduleName: MODULE_NAMES.SUBMISSION_MODULE,
      lastSynced: res.time,
    });
    MetaDataManager.saveMetaData(metaDataModel); // upsert activity metadata with new lastSync time

    let { submission, backdated, startDate, endDate } = payload;

    if (res) {
      const response =
        res.data && res.data.submissions ? res.data.submissions : [submission];
      for (let i = 0; i < response.length; i++) {
        const id = SubmissionsManager.saveActivitySubmissionByStatus(
          response[i],
          activity_number,
          instanceName,
        );
        const submission = SubmissionsManager.getSubmissionById(id);
        let index = _.findIndex(submissionsList, ['id', id]);
        if (index < 0) {
          index = ActivitiesHelper.getIndexToInsert(
            submissionsList,
            submission,
          );
          submissionsList.splice(index, 0, submission);
        } else {
          submissionsList[index] = submission;
        }
      }
    }
    yield put(syncActivitySubmissionsSuccess(submissionsList));
  } catch (e) {
    yield put(syncActivitySubmissionsFailure({ error: e.message }));
  }
}

function* postActivitySubmission({ payload }) {
  try {
    if (navigator.onLine) {
      yield put(postActivitySubmissionsOnServerRequest(payload));
      yield take(ACTION_CONSTANTS.POST_ACTIVITY_SUBMISSION_ON_SERVER_SUCCESS);
      yield put(postActivitySubmissionSuccess());
    } else {
      yield delay(100);
      const { formValues, submissionDate, currentActivity, currentInstance } =
        payload;

      // format attachment data
      const attachments = _.filter(currentActivity.fields, [
        'field_type_key',
        SUBMISSION_FIELD_TYPES.ATTACHMENT,
      ]);
      if (attachments.length > 0) {
        for (let i = 0; i < attachments.length; i++) {
          //attachments are not supported in offline reactjs due to localstorage
          if (formValues[attachments[i].group_id].length > 0) {
            throw new Error(localization[getLocale()].ATTACHMENT_OFFLINE_ERROR);
          }
          // let value = [];
          // for (let j = 0; j < formValues[attachments[i].group_id].length; j++) {
          //   let obj = {};
          //   obj.uri = yield call(
          //     globalHelper.getBase64File,
          //     formValues[attachments[i].group_id][j].uri
          //   );
          //   obj.type = formValues[attachments[i].group_id][j].type;
          //   value.push(obj);
          // }
          // formValues[attachments[i].group_id] =
          //   currentActivity.activity_type_key === ACTIVITY_TYPE.ATTACHMENT
          //     ? value[0]
          //     : JSON.stringify({
          //         attachments: value,
          //       });
        }
      }
      const userStore = yield select(userStoreRequest);
      const payloadObj = ActivitiesHelper.getSubmissionObject(
        formValues,
        submissionDate,
        currentActivity,
        currentInstance,
        userStore.assignLogUsers,
      );

      // save submission offline
      const id = SubmissionsManager.saveActivitySubmissionByStatus(
        payloadObj.feedlot_activity_submission,
        currentActivity.activity_number,
        currentInstance ? currentInstance.instance_value : null,
        SUBMISSION_STATUS.PENDING,
      );

      const submission = SubmissionsManager.getSubmissionById(id);
      const activityStore = yield select(activityStoreRequest);
      let submissions = _.cloneDeep(activityStore.submissions);
      submissions.splice(0, 0, submission);
      yield put(postActivitySubmissionSuccess(submissions));
      yield put(getPendingActionsCountRequest()); //to update count on dashboard
    }
  } catch (e) {
    console.log('postActivitySubmissionFailure', e);
    yield put(postActivitySubmissionFailure({ error: e.message }));
  }
}

export function* postPendingActivitySubmissions() {
  try {
    if (navigator.onLine) {
      const pendingSubmissions =
        SubmissionsManager.getPendingActivitySubmissions();
      if (pendingSubmissions.length) {
        for (let i = 0; i < pendingSubmissions.length; i++) {
          const submission = pendingSubmissions[i];

          let storeActivities = yield call(ActivityManager.getActivities);
          storeActivities =
            ActivitiesHelper.separateActivities(storeActivities);
          let separatedActivities = ActivitiesHelper.activitiesSeparatedModel(
            ActivitiesHelper.groupActivities(
              storeActivities,
              ACTIVITY_TYPES.DAILY,
            ),
            ActivitiesHelper.groupActivities(
              storeActivities,
              ACTIVITY_TYPES.WEEKLY,
            ),
            ActivitiesHelper.groupActivities(
              storeActivities,
              ACTIVITY_TYPES.MONTHLY,
            ),
            ActivitiesHelper.groupActivities(
              storeActivities,
              ACTIVITY_TYPES.QUATERLY,
            ),
            ActivitiesHelper.groupActivities(
              storeActivities,
              ACTIVITY_TYPES.UPCOMMING,
            ),
            ActivitiesHelper.groupActivities(
              storeActivities,
              ACTIVITY_TYPES.UNSCHEDULED,
            ),
            ActivitiesHelper.groupActivities(
              storeActivities,
              ACTIVITY_TYPES.WAITING_FOR_SUBMISSION,
            ),
          );

          const activities = _.find(
            separatedActivities,
            _.flow(
              _.property('data'),
              _.partialRight(_.some, {
                feedlot_activity_id: submission.feedlot_activity_id,
              }),
            ),
          );
          // console.log('submission', submission);
          if (submission.removeCallPending) {
            const requestBody = {
              feedlot_activity_submission_cancellation: {
                reason: submission.feedlotActivitySubmissionCancellation.reason,
                feedlot_activity_submission_id:
                  submission.feedlot_activity_submission_id,
              },
            };
            yield put(
              postRemoveActivitySubmissionsOnServerRequest({ requestBody }),
            );
            yield take(
              ACTION_CONSTANTS.POST_REMOVE_ACTIVITY_SUBMISSION_ON_SERVER_SUCCESS,
            );
          } else {
            const submissionObj = {
              currentActivity: _.find(activities.data, [
                'feedlot_activity_id',
                submission.feedlot_activity_id,
              ]),
              currentInstance: {
                feedlot_activity_instance_id:
                  submission.feedlot_activity_instance_id,
              },
              submissionDate: submission.submitted_date,
              formValues: ActivitiesHelper.getFormValuesFormat(
                submission.values,
              ),
            };

            yield put(postActivitySubmissionsOnServerRequest(submissionObj));
            // yield take(
            //   ACTION_CONSTANTS.POST_ACTIVITY_SUBMISSION_ON_SERVER_SUCCESS,
            // );
          }

          let properties = {};

          // properties[Properties.ACTIVITY_NAME] =
          //   currentActivity.locale[languageService()].title;
          properties[Properties.ACTIVITY_NUMBER] = submission.activity_number;
          trackEvent(Events.PENDING_SUBMISSION, properties);
        }
        SubmissionsManager.removePendingSubmissions();
        yield put(getPendingActionsCountRequest()); //to update count on dashboard
        yield put(postPendingActivitySubmissionsSuccess());
      }
    } else {
      yield put(
        postPendingActivitySubmissionsFailure({
          error: localization[getLocale()].NO_INTERNET_CONNECTION,
        }),
      );
    }
  } catch (e) {
    console.log('postPendingActivitySubmissionsFailure', e);
    yield put(postPendingActivitySubmissionsFailure({ error: e.message }));
  }
}

function* postActivitySubmissionsOnServer({ payload }) {
  let payloadObj = {};
  try {
    let { formValues } = payload;
    const {
      submissionDate,
      currentActivity,
      currentInstance,
      backdated,
      startDate,
      endDate,
    } = payload;

    // post attachments
    const attachments = _.filter(currentActivity.fields, [
      'field_type_key',
      SUBMISSION_FIELD_TYPES.ATTACHMENT,
    ]);
    if (attachments && attachments.length > 0) {
      for (let i = 0; i < attachments.length; i++) {
        let value = [];
        const attachment =
          typeof formValues[attachments[i].group_id] === 'string'
            ? JSON.parse(formValues[attachments[i].group_id]).attachments
              ? JSON.parse(formValues[attachments[i].group_id]).attachments
              : [JSON.parse(formValues[attachments[i].group_id])]
            : formValues[attachments[i].group_id];
        for (let j = 0; j < attachment.length; j++) {
          const attachmentPayload = yield call(
            ActivitiesHelper.getAttachmentObject,
            {
              value: attachment[j],
              submissionDate,
              activity: currentActivity,
              instance: currentInstance,
            },
          );
          yield put(postActivityAttachmentRequest(attachmentPayload));
          yield take(ACTION_CONSTANTS.POST_ACTIVITY_ATTACHMENT_SUCCESS);

          const activityStore = yield select(activityStoreRequest);
          value.push(activityStore.attachment);
        }
        formValues[attachments[i].group_id] =
          currentActivity.activity_type_key === ACTIVITY_TYPE.ATTACHMENT
            ? value[0]
            : JSON.stringify({
                attachments: value,
              });
      }
    }

    const userStore = yield select(userStoreRequest);
    payloadObj = ActivitiesHelper.getSubmissionObject(
      formValues,
      submissionDate,
      currentActivity,
      currentInstance,
      userStore.assignLogUsers,
    );

    if (payload.submissionId) {
      SubmissionsManager.removeRejectedSubmission(payload.submissionId);
    }

    const res = yield call(ActivityService.postActivitySubmission, payloadObj);
    if (res && res.data && res.data.submission) {
      yield put(
        syncActivityDataRequest({
          submission: res.data.submission,
          backdated,
          startDate,
          endDate,
        }),
      );
      yield take(ACTION_CONSTANTS.SYNC_ACTIVITY_SUBMISSIONS_SUCCESS);
    }
    yield put(postActivitySubmissionsOnServerSuccess());
  } catch (e) {
    const { currentActivity, currentInstance } = payload;

    if (e.code === SERVER_STATUS_CODES._00667) {
      yield call(getActivitiesAsync, {
        payload: { update: true },
      });
      showErrorAlert(`${currentActivity.activity_number} is moved to Bin.`, [
        {
          text: 'Ok',
          onPress: () => {
            history.push(RouteConstants.ACTIVITIES);
          },
        },
      ]);

      yield put(postActivitySubmissionsOnServerRemoved());
    } else if (e.code === SERVER_STATUS_CODES._00668) {
      yield call(
        SubmissionsManager.clearActivitySubmissions,
        currentActivity.activity_number,
      );
      const didRemove = yield call(ActivityManager.removeActivities, [
        currentActivity.feedlot_activity_id,
      ]);
      if (didRemove)
        yield call(getActivitiesAsync, {
          payload: { update: true },
        });

      showErrorAlert(
        `${currentActivity.activity_number} is removed from feedyard.`,
        [
          {
            text: 'Ok',
            onPress: () => {
              history.push(RouteConstants.ACTIVITIES);
            },
          },
        ],
      );

      yield put(postActivitySubmissionsOnServerRemoved());
    } else if (e.code === SERVER_STATUS_CODES._00669) {
      yield put(
        postActivitySubmissionsOnServerFailure({
          error: e.message,
        }),
      );
      showErrorAlert(e.message, [
        {
          text: 'Ok',
          onPress: () => {},
        },
      ]);
    } else if (e.code === SERVER_STATUS_CODES._00670) {
      yield put(
        postActivitySubmissionsOnServerFailure({
          error: e.message,
        }),
      );
      showErrorAlert(e.message, [
        {
          text: 'Ok',
          onPress: () => {},
        },
      ]);
    } else if (e.code === SERVER_STATUS_CODES._00075) {
      const id = SubmissionsManager.saveActivitySubmissionByStatus(
        payloadObj.feedlot_activity_submission,
        currentActivity.activity_number,
        currentInstance ? currentInstance.instance_value : null,
        SUBMISSION_STATUS.REJECTED,
        payload.submissionId,
      );
      // SubmissionsManager.sortActivities();
      const submission = SubmissionsManager.getSubmissionById(id);
      const activityStore = yield select(activityStoreRequest);
      let submissions = _.cloneDeep(activityStore.submissions);
      const index = _.findIndex(submissions, ['id', id]);
      if (index < 0) {
        submissions.splice(0, 0, submission);
      } else {
        submissions[index] = submission;
      }
      yield put(
        postActivitySubmissionsOnServerFailure({
          submissions,
          error: e.message,
        }),
      );
    } else {
      yield put(
        postActivitySubmissionsOnServerFailure({
          error: e.message,
        }),
      );
    }
  }
}

function* postActivityAttachment({ payload }) {
  try {
    const res = yield call(ActivityService.postActivityAttachment, payload);
    if (res && res.data) {
      yield put(
        postActivityAttachmentSuccess({
          attachment: res.data.feedlot_activity_attachment.file,
        }),
      );
    } else {
      yield put(
        postActivityAttachmentFailure({
          error: null, // localization[getLocale()].SOMETHING_WENT_WRONG,
        }),
      );
    }
  } catch (e) {
    console.log('postActivitySubmissionFailure', e);
    yield put(postActivitySubmissionFailure({ error: e.message }));
  }
}

// function* getAttachmentSubmissionFilePath({ payload }) {
//   try {
//     let filePath = null;
//     if (payload && payload.fileName) {
//       let { fileName } = payload;
//       let fileExtention = fileName.split('.')[1];
//       let url = APIConstants.GET_ACTIVITY_ATTACHMENT.replace(
//         '{{fileName}}',
//         fileName,
//       );
//       filePath = yield call(globalHelper.getBase64FromURL, url, fileName);
//       yield put(getAttachmentSubmissionFilePathSuccess({ filePath }));

//       if (filePath) {
//         if (IMAGE_FILE_UPLOAD_ALLOWED_TYPES.includes(fileExtention)) {
//           var data = yield call(globalHelper.getBase64File, filePath);
//           // yield call(RNPrint.print, {
//           //   html:
//           //     '<h1>Custom converted PDF Document</h1>' +
//           //     '<img src="' +
//           //     `data:image/${fileExtention};base64,${data}` +
//           //     '" width="500" height="600" />',
//           //   fileName: 'test',
//           //   base64: true,
//           // });
//         } else {
//           // yield call(RNPrint.print, {
//           //   filePath,
//           // });
//         }
//       }
//     }
//   } catch (e) {
//     console.log('getAttachmentSubmissionFilePath failed', e);
//     yield put(getAttachmentSubmissionFilePathFailure({ error: e }));
//   }
// }

function* addActivityInstancesAsync({ payload }) {
  try {
    if (navigator.onLine) {
      const res = yield call(InstanceService.addActivityInstance, payload);
      // console.log(
      //   'InstancesToSave suceessres.data.feedlot_activity_instances',
      //   res
      // );
      if (res && res.data) {
        const instances = res.data.feedlot_activity_instances;
        InstanceManager.saveInstances(instances);
        yield call(getActivitiesAsync, {
          payload: { update: true },
        });

        yield put(addActivityInstancesSuccess(instances));
      } else {
        yield put(
          addActivityInstancesFailure({
            error: null, // localization[getLocale()].SOMETHING_WENT_WRONG,
          }),
        );
      }
    } else {
      yield put(
        addActivityInstancesFailure({
          error: localization[getLocale()].NO_INTERNET_CONNECTION,
        }),
      );
    }
  } catch (e) {
    yield put(addActivityInstancesFailure({ error: e.message }));
  }
}

function* assignActivityAsync({ payload }) {
  try {
    if (navigator.onLine) {
      yield call(ActivityService.assignActivity, payload);
      yield call(getActivitiesAsync, {
        payload: { update: true },
      });
      yield put(assignActivitySuccess());
    } else {
      yield put(
        assignActivityFailure({
          error: localization[getLocale()].NO_INTERNET_CONNECTION,
        }),
      );
    }
  } catch (e) {
    console.log('assignActivityFailure', e);
    yield put(assignActivityFailure({ error: e.message }));
  }
}

function* getPerformanceActivities() {
  try {
    let activityStore = yield select(activityStoreRequest);
    let dashboardStore = yield select(dashboardStoreRequest);
    const query = activityStore.performanceQuery;
    const filter = activityStore.performanceActivitiesFilter;
    let activities = ActivityManager.getActivities(query, filter);
    let groupedPerformanceActivities =
      ActivitiesHelper.groupPerformanceActivities(activities);
    let separatedPerformanceActivities =
      ActivitiesHelper.separatePerformanceActivities(
        groupedPerformanceActivities,
      );
    let separatedCustomActivities = ActivitiesHelper.separateCustomActivities(
      dashboardStore.feedlot_activites,
      activities,
    );
    yield put(
      getPerformanceActivitiesSuccess({
        noActivitiesPresent: activities.length > 0 ? false : true,
        separatedPerformanceActivities,
        separatedCustomActivities,
      }),
    );
  } catch (e) {
    yield put(getPerformanceActivitiesFailure({ error: e.message }));
  }
}

function* postActivityPrint({ payload }) {
  try {
    const res = yield call(ActivityService.postActivityPrint, payload);
    if (res.code === SERVER_STATUS_CODES._00001) {
      yield put(
        postActivityPrintFailure({
          error: localization[getLocale()].NO_SUBMISSION_HISTORY,
        }),
      );
    } else {
      yield put(postActivityPrintSuccess());
    }
  } catch (e) {
    yield put(postActivityPrintFailure({ error: e.message }));
  }
}

function* postFormActivityPrint({ payload }) {
  try {
    yield call(ActivityService.postFormActivityPrint, payload);
    yield put(postFormActivityPrintSuccess());
  } catch (e) {
    yield put(postFormActivityPrintFailure({ error: e.message }));
  }
}

function* deleteActivityReminderAsync({ payload }) {
  try {
    yield call(ActivityService.deleteActivityReminder, payload);
    yield call(getActivitiesAsync, {
      payload: { update: true },
    });
    yield put(deleteActivityReminderSuccess());
  } catch (e) {
    yield put(deleteActivityReminderFailure({ error: e.message }));
  }
}

function* getActivityRevisionAsync({ payload }) {
  const activityId = payload;

  if (navigator.onLine) {
    let activity = yield call(ActivityService.getActivityById, activityId);
    activity = activity.data.feedlot_activity || {};
    yield put(getActivityRevisionSuccess(activity));
  } else {
    yield delay(100);
    yield put(
      getActivityRevisionFailure({
        error: localization[getLocale()].NO_INTERNET_CONNECTION,
      }),
    );
  }
}

function* resetSubmissions({ payload }) {
  try {
    if (payload) {
      let submissions = SubmissionsManager.resetSubmissions(payload);
      yield put(resetSubmissionsSuccess({ submissions }));
    }
  } catch (e) {
    console.log('resetSubmissions fail', e);
    yield put(resetSubmissionsFailure());
  }
}

function* activitiesSaga() {
  yield takeEvery(ACTION_CONSTANTS.GET_ACTIVITIES_REQUEST, getActivitiesAsync);
  // yield takeEvery(
  //   ACTION_CONSTANTS.GET_ACTIVITY_INSTANCES_REQUEST,
  //   getActivityInstancesAsync,
  // );
  yield takeEvery(
    ACTION_CONSTANTS.GET_DEPRECATED_ACTIVITIES_REQUEST,
    getDeprecatedActivitiesAsync,
  );
  yield takeLatest(
    ACTION_CONSTANTS.GET_ACTIVITY_SUBMISSIONS_REQUEST,
    getActivitySubmissionsAsync,
  );
  yield takeLatest(
    ACTION_CONSTANTS.GET_BACKDATED_ACTIVITY_SUBMISSIONS_REQUEST,
    getBackdatedActivitySubmissionsAsync,
  );
  yield takeEvery(
    ACTION_CONSTANTS.DELETE_ACTIVITY_INSTANCES_REQUEST,
    deleteActivityInstancesAsync,
  );
  yield takeEvery(
    ACTION_CONSTANTS.ADD_ACTIVITY_INSTANCES_REQUEST,
    addActivityInstancesAsync,
  );
  yield takeEvery(
    ACTION_CONSTANTS.ASSIGN_ACTIVITY_REQUEST,
    assignActivityAsync,
  );
  yield takeEvery(
    ACTION_CONSTANTS.SET_ACTIVITY_REMINDER_REQUEST,
    setActivityReminderAsync,
  );
  yield takeEvery(
    ACTION_CONSTANTS.DELETE_ACTIVITY_REMINDER_REQUEST,
    deleteActivityReminderAsync,
  );
  yield takeEvery(
    ACTION_CONSTANTS.SET_ACTIVITY_SCHEDULE_REQUEST,
    setActivityScheduleAsync,
  );
  yield takeEvery(
    ACTION_CONSTANTS.POST_ACTIVITY_PRINT_REQUEST,
    postActivityPrint,
  );
  yield takeEvery(
    ACTION_CONSTANTS.POST_FORM_ACTIVITY_PRINT_REQUEST,
    postFormActivityPrint,
  );
  yield takeEvery(
    ACTION_CONSTANTS.POST_ACTIVITY_SUBMISSION_REQUEST,
    postActivitySubmission,
  );
  // yield takeEvery(
  //   ACTION_CONSTANTS.GET_ACTIVITY_BY_ID_REQUEST,
  //   getAcitityByIdAsync,
  // );
  // yield takeEvery(
  //   ACTION_CONSTANTS.GET_SUBMISSION_BY_ID_REQUEST,
  //   getSubmissionByIdAsync,
  // );
  yield takeEvery(
    ACTION_CONSTANTS.POST_ACTIVITY_ATTACHMENT_REQUEST,
    postActivityAttachment,
  );
  yield takeEvery(
    ACTION_CONSTANTS.REMOVE_ACTIVITY_SUBMISSION_REQUEST,
    removeActivitySubmission,
  );
  yield takeEvery(
    ACTION_CONSTANTS.GET_PERFORMANCE_ACTIVITIES_REQUEST,
    getPerformanceActivities,
  );
  yield takeEvery(
    ACTION_CONSTANTS.SYNC_ACTIVITY_SUBMISSIONS_REQUEST,
    syncActivitySubmissions,
  );
  yield takeEvery(
    ACTION_CONSTANTS.POST_PENDING_ACTIVITY_SUBMISSIONS_REQUEST,
    postPendingActivitySubmissions,
  );
  yield takeEvery(
    ACTION_CONSTANTS.POST_ACTIVITY_SUBMISSION_ON_SERVER_REQUEST,
    postActivitySubmissionsOnServer,
  );
  yield takeEvery(
    ACTION_CONSTANTS.POST_REMOVE_ACTIVITY_SUBMISSION_ON_SERVER_REQUEST,
    postRemoveActivitySubmissionsOnServer,
  );
  yield takeLatest(
    ACTION_CONSTANTS.GET_ACTIVITY_REVISION_REQUEST,
    getActivityRevisionAsync,
  );
  // yield takeLatest(
  //   ACTION_CONSTANTS.GET_ATTACHMENT_SUBMISSION_FILE_PATH_REQUEST,
  //   getAttachmentSubmissionFilePath,
  // );
  // yield takeLatest(ACTION_CONSTANTS.RESET_SUBMISSIONS_REQUEST, resetSubmissions);
}

export default activitiesSaga;
