import React, { Component } from 'react';
import './style.scss';

class Loading extends Component {
  render() {
    return (<div className="loading">Loading&#8230;</div>)
  }
}

export default Loading;
