import React, { PureComponent } from 'react';
import moment from 'moment';
import PBIcon from '../PBIcon';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';
import { setCurrentActivity } from '@progressivebeef/shared/src/store/actions/activities';
import { connect } from 'react-redux';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import ActivityTag from '../ActivityTag';
import { DATE_FORMATS } from '@progressivebeef/shared/src/constants/AppConstants';
import ActivityPickerModal from '../ActivityPickerModal';
import ActivityHelper from '@progressivebeef/shared/src/helpers/ActivitiesHelper';
import { globalHelper } from '@progressivebeef/shared/src/helpers';

import './style.scss';

class ActivityBinCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
    };
  }

  onActivityCardPress = (currentActivity) => {
    const { setCurrentActivityRequest } = this.props;

    setCurrentActivityRequest(currentActivity);

    if (currentActivity.instance_based) {
      this.props.history.push(RouteConstants.ACTIVITY_INSTANCE, {
        currentActivity,
        bin: true,
      }); // if instance based then navigate to instances
    } else {
      this.props.history.push(RouteConstants.ACTIVITY_SUBMISSIONS, {
        currentActivity,
        bin: true,
      }); // if instance based then navigate to submissions
    }
  };

  onPickerPress = () => {
    this.setState({ modalVisible: false });
  };

  render() {
    var { modalVisible } = this.state;
    var { item, history, setCurrentActivityRequest } = this.props;
    return (
      <>
        <ActivityPickerModal
          item={item}
          history={history}
          modalVisible={modalVisible}
          onPressEvent={this.onPickerPress}
          setCurrentActivity={setCurrentActivityRequest}
        />
        <div
          onClick={() => this.onActivityCardPress(item)}
          className="activity-main-box bin-activity-main-box">

          <div className="activity-tile-box">
            {item.locale
              ? item.locale[getLocale() === 'en' ? 1 : 2].title
              : ''}
          </div>
          <div className="activity-list-of-detail">
            <div className="activity-log-box">
              <img
                alt="description"
                src={ActivityHelper.getTypeIcon(item)}
              />
              <div className="title">
                {item.activity_number}
              </div>
            </div>
            {globalHelper.renderIf(item.last_submitted_date)(
              <div className="activity-date-box">
                <div className="icon">
                  <PBIcon
                    name={'calendar-check'}
                  />
                </div>
                <div className="date">
                  {moment(item.last_submitted_date).format(
                    DATE_FORMATS.MM_DD_YY,
                  )}
                </div>
              </div>,
            )}
          </div>

          {item.activity_tags && (
            <div className="activity-tag-box">
              <ActivityTag
                history={history}
                tags={item.activity_tags}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state.authentication;

  return {
    user: user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentActivityRequest: (payload) =>
      dispatch(setCurrentActivity(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityBinCard);
