import localization from '../localization/i18n';

import {
  getLocale,
  getUserId,
  getUserRole,
  getSelectedFeedlotId,
} from '../services/storageService/GlobalData';

import {
  auditStatus,
  commentTypes,
  commentTargetTypes,
} from '../constants/AppConstants';

class AuditHelper {
  auditsSeparatedModel = (
    recivedFromAuditorData,
    submittedToAuditorData,
    closedData,
  ) => {
    return [
      {
        title:
          localization[getLocale()]['AUDIT_STATUS_' + auditStatus.SUBMITTED],
        key: auditStatus.SUBMITTED,
        data: recivedFromAuditorData,
      },
      {
        title:
          localization[getLocale()][
            'AUDIT_STATUS_' + auditStatus.SUBMITTED_TO_AUDITOR
          ],
        key: auditStatus.SUBMITTED_TO_AUDITOR,
        data: submittedToAuditorData,
      },
      {
        title: localization[getLocale()]['AUDIT_STATUS_' + auditStatus.CLOSED],
        key: auditStatus.CLOSED,
        data: closedData,
      },
    ];
  };

  getauditNCs = (auditAssignment) => {
    let auditNCs = [],
      nc_index = 1,
      audit_sections = auditAssignment.audit.audit_sections,
      ncArray = auditAssignment.audit_summary.non_conformances;
    if (audit_sections && audit_sections.length > 0) {
      audit_sections.map((section) => {
        let section_questions = section.section_questions;
        if (section_questions.length > 0) {
          section_questions.map((question) => {
            //if this question has NC
            if (
              question.audit_question_answer &&
              question.audit_question_answer.non_conformance &&
              question.audit_question_answer.non_conformance != ''
            ) {
              let thisQuesNC = ncArray.find(function (item) {
                return (
                  item.audit_question_answer_id ===
                  question.audit_question_answer.audit_question_answer_id
                );
              });
              if (thisQuesNC) {
                let nc = {
                  nc_index: nc_index++,
                  audit_question_answer_id: thisQuesNC.audit_question_answer_id,
                  non_conformance: thisQuesNC.non_conformance,
                  corrective_action: thisQuesNC.corrective_action
                    ? thisQuesNC.corrective_action
                    : '',
                  non_conformance_status: thisQuesNC.non_conformance_status
                    ? thisQuesNC.non_conformance_status
                    : 0,
                  sectionText: section.locale[0].title,
                  questionText: question.locale[0].body,
                  audit_section_id: section.audit_section_id,
                  audit_section_question_id: question.audit_section_question_id,
                  updated_at: thisQuesNC.updated_at,
                };
                auditNCs.push(nc);
              }
            }
          });
        }
      });
    }

    return auditNCs;
  };

  getCommentRequestObj = (
    auditSectionQuestionId,
    comment,
    auditAssignmentId,
    images,
    files,
  ) => {
    try {
      return {
        audit_question_comment: {
          feedlot_id: getSelectedFeedlotId(),
          audit_section_question_id: auditSectionQuestionId,
          comment,
          type: commentTypes.FEEDYARD,
          target_type: commentTargetTypes.AUDITOR,
          audit_assignment_id: auditAssignmentId,
          audit_question_comment_attachments: [
            ...images.map((image) => ({
              data: image.base64,
              extension: image.name.substr(image.name.lastIndexOf('.') + 1),
            })),
            ...files.map((file) => ({ data: file.base64, extension: 'pdf' })),
          ],
        },
      };
    } catch (e) {
      console.log('getCommentRequestObj error', e);
    }
  };
}

export default new AuditHelper();
