import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import AppContainer from '../../../../components/AppContainer';
import {
  getNavigationVideosRequest,
  searchNavigationVideos,
  resetNavigationVideosProps,
  downloadVideoRequest,
  deleteVideoRequest,
  removeVideoRequest,
} from '@progressivebeef/shared/src/store/actions/navigationVideos';
import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import { globalHelper } from '@progressivebeef/shared/src/helpers';
import { resetDownloadProps } from '@progressivebeef/shared/src/store/actions/navigationVideos';
import EmptyListMsg from '../../../../components/EmptyListText';
import InfiniteScroll from 'react-infinite-scroll-component';
import NavigationVideoCard from '../../../../components/NavigationVideoCard';
import {
  SwipeableList,
  SwipeableListItem,
} from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import AssetConstants from '@progressivebeef/shared/src/constants/AssetConstants';
import { showErrorAlert } from '../../../../components/Alerts';
import Loading from '../../../../components/Loading/Loading';
import { Spinner } from 'reactstrap';

class NavigationVideos extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sort: false,
      dataSource: null,
    };
  }

  componentDidMount() {
    this.props.getNavigationVideos({ update: false });
  }

  componentDidUpdate(prevProps) {
    const { downloaded, deleted } = this.props;
    if (downloaded !== prevProps.downloaded && downloaded) {
      this.setState({
        dataSource: Date.now(),
      });
      this.props.getNavigationVideos({ update: false, loading: true });
      this.props.resetDownloadProps();
    }

    if (deleted !== prevProps.deleted && deleted) {
      this.setState({
        dataSource: Date.now(),
      });
      this.props.getNavigationVideos({ update: false, loading: true });
      this.props.resetDownloadProps();
    }
  }

  onRefresh = () => {
    if (navigator.onLine) {
      this.props.getNavigationVideos({ update: true, loading: true });
    } else {
      showErrorAlert(localization[getLocale()].OFFLINE_MODE_REFRESH_ERROR);
    }
  };

  renderSectionHeader = (item) => <div>{item.title}</div>;
  renderItem = (item, isDownloaded, urls) => {
    return (
      <NavigationVideoCard
        history={this.props.history}
        dataSource={this.state.dataSource}
        item={item}
        urls={urls}
        isDownloaded={isDownloaded}
        {...this.props}
      />
    );
  };
  keyExtractor = (item, index) => item.title + index;

  deleteVideo = (item) => {
    showErrorAlert(localization[getLocale()].DELETE_VIDEO, [
      {
        text: localization[getLocale()].YES,
        onPress: () => {
          this.props.removeVideoRequest(item.training_video_id);
          this.props.deleteVideoRequest();
        },
      },
      {
        text: localization[getLocale()].NO,
        onPress: () => console.log(''),
      },
    ]);
  };

  render() {
    const {
      programManuals,
      history,
      loading,
      query,
      downloadLoading,
      downloadedVideo,
    } = this.props;

    return (
      <>
        <AppContainer
          title={localization[getLocale()].NAVIGATION_VIDEOS}
          history={history}
          onSearchPress={this.searchNavigationVideos}
          searchText={query}
          iconHeader
          IconRightHeader={this.renderRightHeader}
        >
          {globalHelper.renderIf(downloadLoading)(<Loading></Loading>)}
          <InfiniteScroll
            dataLength={programManuals.map((o) => o.data).flat().length}
            hasMore={false}
            refreshFunction={this.onRefresh}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
            pullDownToRefreshContent={
              <div className="bottom-infinite-loader">
                <Spinner color={'red'} />
              </div>
            }
            releaseToRefreshContent={
              <div className="bottom-infinite-loader">
                <Spinner color={'red'} />
              </div>
            }
          >
            <SwipeableList threshold={0.1}>
              {programManuals.map((section, index) => {
                return (
                  <div className="training-video-listing" key={index + 400}>
                    {section.data.length > 0 && section.title
                      ? React.createElement(
                        'div',
                        {
                          className: 'section-header',
                          key: index + 100,
                        },
                        this.renderSectionHeader(section)
                      )
                      : null}

                    {React.createElement(
                      'div',
                      {
                        className: 'section-item-container',
                        key: index + 200,
                      },
                      section.data.map((item, index) => (
                        <SwipeableListItem
                          threshold={0.1}
                          key={index}
                          blockSwipe={
                            downloadedVideo.findIndex(
                              (q) =>
                                q.training_video_id === item.training_video_id
                            ) === -1
                          }
                          swipeRight={{
                            content: (
                              <div
                                className="delete-box"
                                onClick={() => this.onDeletePress(item)}
                              >
                                <div>
                                  <img
                                    alt="description"
                                    src={AssetConstants.bin}
                                  />
                                  <div className="label-name">{'Delete'}</div>
                                </div>
                              </div>
                            ),
                            action: () => this.deleteVideo(item),
                          }}
                        >
                          {React.createElement(
                            'div',
                            {
                              className: 'section-item',
                              key: index,
                            },
                            this.renderItem(
                              item,
                              downloadedVideo.findIndex(
                                (q) =>
                                  q.training_video_id === item.training_video_id
                              ) !== -1,
                              downloadedVideo.find(
                                (q) =>
                                  q.training_video_id === item.training_video_id
                              ),
                              index
                            )
                          )}
                        </SwipeableListItem>
                      ))
                    )}
                  </div>
                );
              })}
            </SwipeableList>
            {programManuals.map((o) => o.data).flat() < 1 && !loading && (
              <div className="no-submission-result-found">
                <EmptyListMsg
                  emptyTxt={localization[getLocale()].NO_VIDEO_AVAILABLE}
                />
              </div>
            )}
          </InfiniteScroll>
        </AppContainer>
      </>
    );
  }

  renderRightHeader = () => {
    return <div />;
  };

  searchNavigationVideos = (queryString) => {
    this.props.searchNavigationVideos(queryString);
    this.props.getNavigationVideos({ update: false });
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    searchNavigationVideos: (payload) =>
      dispatch(searchNavigationVideos(payload)),
    getNavigationVideos: (payload) =>
      dispatch(getNavigationVideosRequest(payload)),
    resetNavigationVideosProps: (payload) =>
      dispatch(resetNavigationVideosProps(payload)),
    resetDownloadProps: (payload) => dispatch(resetDownloadProps(payload)),
    downloadVideoRequest: (payload) => dispatch(downloadVideoRequest(payload)),
    deleteVideoRequest: (payload) => dispatch(deleteVideoRequest(payload)),
    removeVideoRequest: (payload) => dispatch(removeVideoRequest(payload)),
  };
};

const mapStateToProps = (state) => {
  const {
    query,
    isLoading,
    downloadLoading,
    programManuals,
    downloaded,
    downloadedVideo,
    deleted,
  } = state.navigationVideos;
  const { user } = state.authentication;

  return {
    query,
    isLoading,
    downloadLoading,
    user,
    programManuals,
    loading: isLoading,
    downloaded,
    deleted,
    downloadedVideo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationVideos);
