import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Input, InputGroup } from 'reactstrap';
import './style.scss';

import AppContainer from '../../../../components/AppContainer';
import FormButton from '../../../../components/FormButton';
import {
  showAlertMsg,
  showErrorAlert,
  showSuccessAlert,
} from '../../../../components/Alerts';
import PBIcon from '../../../../components/PBIcon';

import { ChangePinValidationSchema } from '@progressivebeef/shared/src/constants/FormValidations';
import { ChangePinFormInitialValues } from '@progressivebeef/shared/src/constants/FormInitialValues';

import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

import { changePinRequest } from '@progressivebeef/shared/src/store/actions/user';
import {
  showLoader,
  hideLoader,
} from '@progressivebeef/shared/src/store/actions/view';
import { logoutRequested } from '@progressivebeef/shared/src/store/actions/authentication';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';

import { trackEvent } from '@progressivebeef/shared/src/services/reactMixpanelService';
import {
  Events,
  Properties,
} from '@progressivebeef/shared/src/constants/MixpanelConstants';

class ChangePin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCurrent: false,
      showNew: false,
      showConfirm: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      changePinSuccess,
      changePinError,
      isChangePinLoading,
      showLoaderView,
      hideLoaderView,
      logout,
    } = this.props;
    if (isChangePinLoading) {
      showLoaderView();
    } else {
      hideLoaderView();
    }

    if (changePinSuccess && prevProps.changePinSuccess !== changePinSuccess) {
      trackEvent(Events.PIN_CHANGED);
      showSuccessAlert(localization[getLocale()].PIN_CHANGED_SUCCESS, [
        {
          text: localization[getLocale()].OK,
          onPress: () => {
            logout();
            this.props.history.replace(RouteConstants.LOGIN);
          },
        },
      ]);
    }
    if (prevProps.changePinError !== changePinError) {
      if (typeof changePinError === 'string') {
        showErrorAlert(changePinError);
      }
    }
  }

  validate(values) {
    const lengths = Object.values(values).map((value) => value.length);
    const min = Math.min(...lengths);
    if (min === 0) {
      showAlertMsg(
        localization[getLocale()].ERROR,
        localization[getLocale()].PLEASE_FILL_ALL_FIELDS,
      );
    }
    if (min < 4 && min > 0) {
      showAlertMsg(
        localization[getLocale()].ERROR,
        localization[getLocale()].PIN_LENGTH_ERROR,
      );
    }
  }

  validateAndSubmit = (isValid, values, handleSubmit) => {
    if (!isValid) {
      this.validate(values);
    } else {
      const { newPin, confirmPin, currentPin } = values;
      if (currentPin === newPin) {
        showAlertMsg(
          localization[getLocale()].ERROR,
          localization[getLocale()].NEW_PIN_SAME_ERROR,
        );
        return;
      }
      if (newPin !== confirmPin) {
        showAlertMsg(
          localization[getLocale()].ERROR,
          localization[getLocale()].NEW_PIN_MATCH_ERROR,
        );
        return;
      }
      handleSubmit();
    }
  };

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          name={'chevron-left'}
          onPress={() => {
            this.props.history.goBack();
          }}
        />
      </div>
    );
  };

  render() {
    const { history } = this.props;

    return (
      <Formik
        initialValues={ChangePinFormInitialValues}
        validationSchema={ChangePinValidationSchema}
        onSubmit={(values) => {
          this.props.changePin({
            oldPin: values.currentPin,
            newPin: values.newPin,
          });
        }}>
        {(props) => {
          const { handleSubmit, handleChange, handleBlur, values, isValid } =
            props;
          return (
            <AppContainer
              title={localization[getLocale()].CHANGE_PIN}
              iconHeader
              history={history}
              IconLeftHeader={this.renderLeftHeader}>
              <InputGroup className="setting-change-pin-screen">
                <div className="flex-col">
                  <div className="label">
                    {localization[getLocale()].PLACEHOLDER_CURRENT_PIN}
                  </div>
                  <div className="field-box">
                    <Input
                      onChange={handleChange('currentPin')}
                      onBlur={handleBlur('currentPin')}
                      value={values.currentPin}
                      title={localization[getLocale()].PLACEHOLDER_CURRENT_PIN}
                      placeholder={'****'}
                      type={this.state.showCurrent ? 'text' : 'password'}
                      pattern="[0-9]+$"
                      maxLength={4}
                      name={'currentPin'}
                      id={'currentPin'}
                    />
                    <i
                      className={`fal trailing-icon ${
                        this.state.showCurrent ? 'fa-eye' : 'fa-eye-slash'
                      }`}
                      onClick={() => {
                        this.setState({
                          showCurrent: !this.state.showCurrent,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="flex-col">
                  <div className="label">
                    {localization[getLocale()].PLACEHOLDER_NEW_PIN}
                  </div>
                  <div className="field-box">
                    <Input
                      onChange={handleChange('newPin')}
                      onBlur={handleBlur('newPin')}
                      value={values.newPin}
                      title={localization[getLocale()].PLACEHOLDER_NEW_PIN}
                      placeholder={'****'}
                      type={this.state.showNew ? 'text' : 'password'}
                      pattern="[0-9]+$"
                      maxLength={4}
                      name={'newPin'}
                      id={'newPin'}
                    />
                    <i
                      className={`fal trailing-icon ${
                        this.state.showNew ? 'fa-eye' : 'fa-eye-slash'
                      }`}
                      onClick={() => {
                        this.setState({
                          showNew: !this.state.showNew,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="flex-col">
                  <div className="label">
                    {localization[getLocale()].PLACEHOLDER_CONFIRM_PIN}
                  </div>
                  <div className="field-box">
                    <Input
                      onChange={handleChange('confirmPin')}
                      onBlur={handleBlur('confirmPin')}
                      value={values.confirmPin}
                      title={localization[getLocale()].PLACEHOLDER_CONFIRM_PIN}
                      placeholder={'****'}
                      type={this.state.showConfirm ? 'text' : 'password'}
                      pattern="[0-9]+$"
                      maxLength={4}
                      name={'confirmPin'}
                      id={'confirmPin'}
                    />
                    <i
                      className={`fal trailing-icon ${
                        this.state.showConfirm ? 'fa-eye' : 'fa-eye-slash'
                      }`}
                      onClick={() => {
                        this.setState({
                          showConfirm: !this.state.showConfirm,
                        });
                      }}
                    />
                  </div>
                </div>
                <FormButton
                  onPressEvent={() =>
                    this.validateAndSubmit(isValid, values, handleSubmit)
                  }
                  title={localization[getLocale()].SAVE}
                  success
                />
              </InputGroup>
            </AppContainer>
          );
        }}
      </Formik>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changePin: (payload) => dispatch(changePinRequest(payload)),
    showLoaderView: (payload) => dispatch(showLoader(payload)),
    hideLoaderView: (payload) => dispatch(hideLoader(payload)),
    logout: () => dispatch(logoutRequested()),
  };
};

const mapStateToProps = (state) => {
  let { isChangePinLoading, changePinSuccess, changePinError } = state.user;

  return {
    isChangePinLoading,
    changePinSuccess,
    changePinError,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePin);
