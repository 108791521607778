import React from 'react';
import moment from 'moment';
import { globalHelper } from '@progressivebeef/shared/src/helpers';
import PBIcon from '../PBIcon';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';
import {
  PERFORMANCE_KEY_CUSTOM,
  DATE_FORMATS,
  BACKDATED_SUBMISSIONS_FILTER,
} from '@progressivebeef/shared/src/constants/AppConstants';
import ActivitiesHelper from '@progressivebeef/shared/src/helpers/ActivitiesHelper';
import AssetConstants from '@progressivebeef/shared/src/constants/AssetConstants';
import { Progress } from 'reactstrap';
import './style.scss';

const InstanceCard = (props) => {
  const {
    item,
    onPress,
    currentActivity,
    instanceName,
    percentageKey,
    custom_activities_instances,
    backdated,
    section,
  } = props;
  let value = 0;
  let color = '';
  let backdatedCount = null;
  if (percentageKey) {
    value =
      percentageKey !== PERFORMANCE_KEY_CUSTOM
        ? ActivitiesHelper.getInstancePerformance(item, percentageKey, section) //item.feedlotPerformance[percentageKey].performance
        : ActivitiesHelper.getCustomInstancePerformance(
            custom_activities_instances,
            currentActivity.activity_id,
            item.feedlot_activity_instance_id
          );
    color = 'missed';
    if (value && Number(value) === 0) {
      color = 'missed';
    } else if (value && Number(value) < 100) {
      color = 'partially';
    } else if (value && Number(value) === 100) {
      color = 'timely';
    }
  }

  if (backdated) {
    backdatedCount =
      backdated != BACKDATED_SUBMISSIONS_FILTER.CUSTOM
        ? item.activityBackDatedSubmissions[backdated]
          ? item.activityBackDatedSubmissions[backdated]
              .backdatedSubmissionCount
          : 0
        : ActivitiesHelper.getCustomInstanceBackdateCount(
            custom_activities_instances,
            currentActivity.activity_id,
            item.feedlot_activity_instance_id
          );
  }
  return (
    <div
      className={`instance-card-box ${
        backdated
          ? 'backdated-instance-card'
          : percentageKey
          ? 'instance-card-performance-box'
          : ''
      }`}
      onClick={onPress}
    >
      {!percentageKey || value === null ? (
        <div className="left-col">
          <div className={`${backdated ? 'backdated-label-box' : ''}`}>
            <div className="label">{item.instance_value}</div>
            {backdated ? (
              <div className="backdated-clock-box">
                <div className="icon">
                  <img alt="description" src={AssetConstants.circleClock} />
                </div>
                <div className="title">{backdatedCount && backdatedCount}</div>
              </div>
            ) : null}
            <div className="instance-info-icon-box">
              {globalHelper.renderIf(!item.status)(
                <div
                  onClick={() => {
                    props.history.push(
                      RouteConstants.ACTIVITY_REMOVED_INSTANCE,
                      {
                        currentActivity,
                        instanceName,
                        instance: item,
                      }
                    );
                  }}
                >
                  <PBIcon name="info-circle" />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="left-col">
          <div>
            <div className="label">{item.instance_value}</div>
          </div>

          <div className={'progress-bar-box ' + color}>
            <div className="progress-bar-parent">
              <Progress
                bar
                color="barColor"
                value={Number(value ? value : 0)}
              />
            </div>
            <div className="value-box">
              {value
                ? value > 0 && value < 100
                  ? Number(value).toFixed(2)
                  : Number(value)
                : Number(0)}
              %
            </div>
          </div>

          <div className="instance-info-icon-box">
            {globalHelper.renderIf(!item.status)(
              <div
                onClick={() => {
                  props.history.push(RouteConstants.ACTIVITY_REMOVED_INSTANCE, {
                    currentActivity,
                    instanceName,
                    instance: item,
                  });
                }}
              >
                <PBIcon name="info-circle" />
              </div>
            )}
          </div>
        </div>
      )}
      {globalHelper.renderIf(item.status)(
        <div className="right-col">
          <div className="instance-date-box">
            {globalHelper.renderIf(item.last_submitted_date)(
              <>
                <div className="icon">
                  <PBIcon name={'calendar-check'} />
                </div>
                <div className="date">
                  {moment(item.last_submitted_date).format(
                    DATE_FORMATS.MM_DD_YY
                  )}
                </div>
              </>
            )}
          </div>
          <div className="instance-date-box edit-date">
            {globalHelper.renderIf(item.due_date)(
              <>
                <div className="icon">
                  <PBIcon name={'calendar-edit'} />
                </div>
                <div className="date">
                  {moment(item.due_date).format(DATE_FORMATS.MM_DD_YY)}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default InstanceCard;
