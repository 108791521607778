import { apiClient } from './index';
import { handleResponse, handleError } from './responseHandler';
import APIConstants from '../../constants/APIConstants';

class NavigationVideosService {
  getNavigationVideos(lastSynced) {
    let url = APIConstants.GET_NAVIGATION_VIDEOS;
    // if (lastSynced) {
    //   url = url.concat(`&syncCall=${true}&time=${lastSynced}`);
    // }
    return apiClient().get(url).then(handleResponse).catch(handleError);
  }
}

export default new NavigationVideosService();
