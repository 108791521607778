import { put, takeEvery, call, select } from 'redux-saga/effects';
import _ from 'lodash';
import ACTION_CONSTANTS from '../constants/actionConstants/index';
import {
  getNavigationVideos,
  getNavigationVideosSuccess,
  getNavigationVideosFailure,
  downloadVideo,
  downloadVideoSuccess,
  downloadVideoFailure,
} from '../store/actions/navigationVideos';
import NavigationVideosService from '../services/api/navigationVideos';
import { MODULE_NAMES } from '../constants/AppConstants';
import NavigationVideosManager from '../database/reactDataManager/NavigationVideosManager';
import MetaDataManager from '../database/reactDataManager/MetaDataManager';
import NavigationVideosHelper from '../helpers/NavigationVideosHelper';
import metaDataDto from '../models/metaDataModel';
import APIConstants from '../constants/APIConstants';
import { getToken } from '../services/storageService/GlobalData';
import { apiClient } from '../services/api';
import fileDownload from 'js-file-download';
import Axios from 'axios';

export const navigationVideosStoreRequest = (state) => state.navigationVideos;
export const dashboardStoreRequest = (state) => state.dashboard;

export function* getNavigationVideosAsync({ payload }) {
  try {
    let navigationVideosStore = yield select(navigationVideosStoreRequest);
    let lastSynced = null; // lastsync time for syncing
    let activityMetaData = MetaDataManager.getMetaDataByModule(
      MODULE_NAMES.NAVIGATION_VIDEOS_MODULE // get activity metadata for last sync time
    );
    if (activityMetaData) {
      lastSynced = activityMetaData.lastSynced;
    }

    const update = payload.update;
    const loading = payload.loading;

    if (loading) {
      // if loading true then start activity loader
      yield put(getNavigationVideos());
    }

    const query = navigationVideosStore.query;

    if (navigator.onLine && update) {
      try {
        let videosResp = yield call(
          NavigationVideosService.getNavigationVideos,
          lastSynced
        );

        let metaDataModel = metaDataDto({
          moduleName: MODULE_NAMES.NAVIGATION_VIDEOS_MODULE,
          lastSynced: videosResp.time,
        });
        MetaDataManager.saveMetaData(metaDataModel); // upsert activity metadata with new lastSync time
        lastSynced = videosResp.time; // setting lastsync time in store

        videosResp = videosResp.data.training_videos;

        if (videosResp && videosResp.length > 0) {
          let videosAdded = NavigationVideosManager.saveNavigationVideos(
            videosResp
          );
          // console.log('save videos to DB successfull', videosAdded);
        }
      } catch (e) {
        console.log('error in api call, returning data from db', e);
      }
    }

    let videos = yield call(NavigationVideosManager.getNavigationVideos, query);
    videos = yield NavigationVideosHelper.isWebDownloadable(videos);
    videos = NavigationVideosHelper.categorySeparatedModel(videos);
    yield put(getNavigationVideosSuccess(videos));
  } catch (e) {
    console.log('NAVIGATION VIDEOS FAILURE: ', e);
    yield put(getNavigationVideosFailure(e));
  }
}

function downloadFile(fileName) {
  return Axios.get(
    apiClient().getBaseUrl() +
      APIConstants.GET_VIDEO.replace('{{file}}', fileName),
    {
      timeout: 90000,
      responseType: 'blob',
      headers: {
        token: getToken(),
        'Content-Type': 'application/json',
        'User-Device': 'mobile',
      },
    }
  );
}

function* downloadVideosAsync({ payload }) {
  try {
    yield put(downloadVideo());
    const fileName = payload.fileName;
    const english = payload.english;
    const spanish = payload.spanish;
    const id = payload.id;

    var result = {};
    result.training_video_id = id;

    var file = yield call(downloadFile, fileName);

    if (file) {
      var fileUrl = URL.createObjectURL(file.data);
      result.file = fileUrl;
      if (english) {
        var engFile = yield call(downloadFile, english);
        var englishUrl = URL.createObjectURL(engFile.data);
        result.english = englishUrl;
        if (spanish) {
          var spanFile = yield call(downloadFile, spanish);
          var spanishUrl = URL.createObjectURL(spanFile.data);
          result.spanish = spanishUrl;
          yield put(downloadVideoSuccess(result));
        } else {
          yield put(downloadVideoSuccess(result));
        }
      } else {
        yield put(downloadVideoSuccess(result));
      }
    }
  } catch (e) {
    yield put(downloadVideoFailure(e));
  }
}

function* navigationNavigationVideosSaga() {
  yield takeEvery(
    ACTION_CONSTANTS.GET_VIDEOS_REQUEST,
    getNavigationVideosAsync
  );
  yield takeEvery(
    ACTION_CONSTANTS.DOWNLOAD_VIDEOS_REQUEST,
    downloadVideosAsync
  );
}

export default navigationNavigationVideosSaga;
