import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import AppContainer from '../../../../components/AppContainer';
import PBIcon from '../../../../components/PBIcon';
import ProgramManualCard from '../../../../components/ProgramManualCard';

import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

import {
  showErrorAlert,
  showSuccessAlert,
} from '../../../../components/Alerts';
import {
  getDeprecatedProgramManualsRequest,
  getProgramManualRequest,
  resetCreateProps,
  setCurrentProgramManual,
} from '@progressivebeef/shared/src/store/actions/programMaunals';
import { globalHelper } from '@progressivebeef/shared/src/helpers';
import { Spinner } from 'reactstrap';
import DeprecatedProgramManualPickerModal from '../../../../components/DeprecatedProgramManualPickerModal';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';
import { userRoles } from '@progressivebeef/shared/src/constants/AppConstants';

class ProgramManualBin extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
    };
  }

  componentDidMount() {
    if (navigator.onLine) {
      this.props.getDeprecatedProgramManuals({ update: true });
    } else {
      showErrorAlert(localization[getLocale()].NO_INTERNET_CONNECTION);
    }
  }

  componentDidUpdate(prevProps) {
    const { isRestored } = this.props;
    if (isRestored) {
      showSuccessAlert(localization[getLocale()].RESTORED_DOCUMENT, [
        {
          text: localization[getLocale()].OK,
          onPress: () => {
            this.props.resetCreateProps();
            this.props.getProgramManuals({ update: false, loading: true });
            this.props.history.goBack();
          },
        },
      ]);
    }
  }

  onRefresh = () => {
    if (navigator.onLine) {
      this.props.getDeprecatedProgramManuals({ update: true });
    } else {
      showErrorAlert(localization[getLocale()].OFFLINE_MODE_REFRESH_ERROR);
    }
  };

  onPickerPress = () => {
    this.setState({ modalVisible: false });
  };

  onCardPress = (item) => {
    const { user, history, setCurrentProgramManual } = this.props;

    setCurrentProgramManual(item);

    if (item.document_type_key !== 5) {
      history.push(RouteConstants.PROGRAM_MANUAL_DETAIL, {
        currentProgramManual: item,
      });
    } else {
      if (
        Number(user.userRoleId) === userRoles.FEEDLOT_MANAGER ||
        Number(user.userRoleId) === userRoles.TRAINER ||
        Number(user.userRoleId) === userRoles.PROGRAM_MANAGER
      ) {
        this.setState({ modalVisible: true });
      } else {
        history.push(RouteConstants.VIEW_DOCUMENT, {
          currentProgramManual: item,
        });
      }
    }
  };

  render() {
    const { isLoading, deprecatedProgramManuals, history, tags } = this.props;

    const { modalVisible } = this.state;

    return (
      <>
        <DeprecatedProgramManualPickerModal
          modalVisible={modalVisible}
          onPressEvent={this.onPickerPress}
          history={history}
        />
        <AppContainer
          title={localization[getLocale()].DOCUMENTS_BIN}
          iconHeader
          IconLeftHeader={this.renderLeftHeader}
          noncollapsible
          history={history}
        >
          {deprecatedProgramManuals && deprecatedProgramManuals.length > 0 ? (
            deprecatedProgramManuals.map((item) => {
              return (
                <ProgramManualCard
                  key={'doc-' + item.feedlot_document_id}
                  deprecated
                  item={item}
                  tags={tags}
                  history={history}
                  onCardPress={this.onCardPress}
                />
              );
            })
          ) : isLoading ? (
            <div className="bottom-infinite-loader">
              {globalHelper.renderIf(isLoading)(<Spinner color={'red'} />)}
            </div>
          ) : (
            <div className="no-result-found-box">
              <p>{localization[getLocale()].NO_DOCUMENTS_FOUND}</p>
            </div>
          )}
        </AppContainer>
      </>
    );
  }

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          name={'chevron-left'}
          onPress={() => this.props.history.goBack()}
        />
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProgramManuals: (payload) => dispatch(getProgramManualRequest(payload)),
    resetCreateProps: (payload) => dispatch(resetCreateProps(payload)),
    getDeprecatedProgramManuals: (payload) =>
      dispatch(getDeprecatedProgramManualsRequest(payload)),
    setCurrentProgramManual: (payload) =>
      dispatch(setCurrentProgramManual(payload)),
  };
};

const mapStateToProps = (state) => {
  const {
    isLoading,
    isRestored,
    deprecatedProgramManuals,
    tags,
  } = state.programManuals;
  const { user } = state.authentication;

  return {
    deprecatedProgramManuals,
    isLoading,
    tags,
    isRestored,
    user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramManualBin);
