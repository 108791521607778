// modules
import React, { Component } from 'react';
import { connect } from 'react-redux';

//custom components
import AppContainer from '../../../components/AppContainer';
// import PBIcon from '../../components/PBIcon';

//constants
import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import {
  getAssignedFeedyards,
  selectFeedyard,
  logoutRequested,
} from '@progressivebeef/shared/src/store/actions/authentication';
import ROUTE_CONSTANTS from '@progressivebeef/shared/src/constants/RouteConstants';

//style
import {
  showLoader,
  hideLoader,
} from '@progressivebeef/shared/src/store/actions/view';
import FeedyardCard from '../../../components/FeedyardCard';
import PBIcon from '../../../components/PBIcon';
import {
  showSuccessAlert,
  showErrorAlert,
  showAlertMsg,
} from '../../../components/Alerts';
import './feedyardlist.scss';

class FeedyardList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFeedyard: this.props.selectedFeedyard,
    };
  }

  componentDidMount() {
    this.props.getAssignedFeedyards('');
  }

  componentDidUpdate(prevProps) {
    let {
      isLogin,
      isLoading,
      success,
      error,
      showLoaderView,
      hideLoaderView,
    } = this.props;
    const { pathname } = this.props.location;

    if (isLoading) {
      showLoaderView();
    } else {
      hideLoaderView();
    }

    if (success && success !== prevProps.success) {
      showSuccessAlert(success);
    }

    if (error !== prevProps.error) {
      if (typeof error === 'string') {
        showErrorAlert(error);
      }
    }

    if (!isLogin) {
      hideLoaderView();
      //redirect to login on logout
      this.props.history.push('/Login');
    }
    //redirect to dashboard on feedyard selection
    else if (
      (prevProps.selectedFeedyard || this.props.selectedFeedyard) &&
      (pathname.replace('/', '') === ROUTE_CONSTANTS.FEEDYARD_LIST ||
        prevProps.selectedFeedyard != this.props.selectedFeedyard)
    ) {
      hideLoaderView();
      this.props.history.push('/Dashboard');
    }
  }

  onLogoutTap = () => {
    showAlertMsg(
      localization[getLocale()].CONFIRMATION.toUpperCase(),
      localization[getLocale()].LOGOUT_CONFIRMATION,
      [
        {
          text: 'No',
          onPress: () => {
            'cancel';
          },
        },
        {
          text: 'Yes',
          onPress: () => {
            this.props.logout();
          },
        },
      ]
    );
  };

  searchFeedyards = (searchText = '') => {
    this.props.getAssignedFeedyards(searchText);
  };

  render() {
    const {
      searchText,
      assignedFeedyards,
      history,
      selectedFeedyard,
      isSyncing,
      isSyncingManually,
      isAutoSyncing,
      loading,
    } = this.props;
    const isSyncingInProgress =
      isSyncing || isSyncingManually || isAutoSyncing || loading;

    return (
      <AppContainer
        title={localization[getLocale()].FEEDYARD_LIST}
        onSearchPress={this.searchFeedyards}
        searchText={searchText}
        iconHeader
        hideBottomBar={
          selectedFeedyard && selectedFeedyard.feedlot_id ? false : true
        }
        IconRightHeader={this.renderRightHeader}
        history={history}
      >
        <div className="feedyard-top-status-bar">
          {searchText && searchText !== '' ? localization[getLocale()].SEARCHED_RESULTS : localization[getLocale()].ALL_FEEDYARDS}
        </div>
        <div className="feedyars-list-box">
          {assignedFeedyards && assignedFeedyards.length > 0 ?
            assignedFeedyards.map((item) => {
              return (
                <FeedyardCard
                  key={'feedlot=' + item.feedlot_id}
                  item={item}
                  onPress={(feedyard) => {
                    // if (isSyncingInProgress) {
                    //   showErrorAlert(
                    //     localization[getLocale()]
                    //       .CANNOT_SWITCH_FEEDLOT_WHILE_SYNCING,
                    //   );
                    // } else {
                      this.props.selectFeedyard(feedyard)
                    // }
                  }}
                />
              );
            })
            : searchText && searchText !== '' ?
              (
                <div className="no-result-found-box">
                  <p>{localization[getLocale()].NO_RECORD_FOUND}</p>
                </div>
              ) : (
                <div className="no-result-found-box">
                  <p>{localization[getLocale()].NO_FEEDYARDS_ASSIGNED}</p>
                </div>
              )}
        </div>
      </AppContainer>
    );
  }

  renderRightHeader = () => {
    let { selectedFeedyard } = this.props;

    if (!selectedFeedyard) {
      return (
        <div className="head-icon right-align">
          <PBIcon onPress={() => this.onLogoutTap()} name={'sign-out'} />
        </div>
      );
    } else {
      return <div />;
    }
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAssignedFeedyards: (searchText) =>
      dispatch(getAssignedFeedyards({ searchText })),
    selectFeedyard: (selectedFeedyard) =>
      dispatch(selectFeedyard({ selectedFeedyard })),
    logout: () => dispatch(logoutRequested()),
    showLoaderView: (payload) => dispatch(showLoader(payload)),
    hideLoaderView: (payload) => dispatch(hideLoader(payload)),
  };
};

const mapStateToProps = (state) => {
  let {
    selectedFeedyard,
    assignedFeedyards,
    isLogin,
    success,
    error,
    isLoading,
    searchText,
  } = state.authentication;
  const {
    isSyncing,
    isSyncingManually,
    isAutoSyncing,
    isLoading: loading,
  } = state.dataSync;

  return {
    selectedFeedyard,
    assignedFeedyards,
    isLogin,
    success,
    error,
    isLoading,
    searchText,
    isSyncing,
    isSyncingManually,
    isAutoSyncing,
    loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedyardList);
