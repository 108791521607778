import { apiClient } from './index';
import { handleResponse, handleError } from './responseHandler';
import APIConstants from '../../constants/APIConstants';

class Authentication {
  login(payload) {
    const url = APIConstants.LOGIN;
    return apiClient()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  }

  logout() {
    const url = APIConstants.LOGOUT;
    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  resetPassword = (user) => {
    const url = APIConstants.FORGOT_PASSWORD;
    const payload = { user };
    return apiClient()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  };

  generatePin = (params) => {
    const url = APIConstants.GENERATE_PIN;
    const urlParams = '?pin=' + params.pin;
    return apiClient()
      .post(url + params.userId + urlParams)
      .then(handleResponse)
      .catch(handleError);
  };

  toggleAlerts = (toggleValue) => {
    const url = APIConstants.TOGGLE_EMAIL_ALERTS;
    return apiClient()
      .post(url, toggleValue)
      .then(handleResponse)
      .catch(handleError);
  };
}

export default new Authentication();
