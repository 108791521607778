import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from 'reactstrap';
import classnames from 'classnames';
import AppContainer from '../../../../components/AppContainer';
import PBIcon from '../../../../components/PBIcon';
import NotificationItem from './NotificationItem';
import NotificationUpdateItem from './NotificationUpdateItem';
import {
  getMobileNotificationRequest,
  getMobileUpdateRequest,
  setMobileNotificationCount,
} from '@progressivebeef/shared/src/store/actions/dashboard';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';
import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import { globalHelper } from '@progressivebeef/shared/src/helpers';
import InfiniteScroll from 'react-infinite-scroll-component';
import { trackEvent } from '@progressivebeef/shared/src/services/reactMixpanelService';
import {
  Events,
  Properties,
} from '@progressivebeef/shared/src/constants/MixpanelConstants';
import { showErrorAlert } from '../../../../components/Alerts';
import {
  getAuditByIdRequested,
  setCurrentAuditRequested,
} from '@progressivebeef/shared/src/store/actions/audits';

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
    };
  }

  componentDidMount() {
    trackEvent(Events.VIEWED_NOTIFICATIONS);
    this.props.setMobileNotificationCount({
      mobileNotificationCount: 0,
    });
    if (navigator.onLine) {
      this.onRefreshNotifications();
      this.onRefreshUpdates();
    } else {
      showErrorAlert(localization[getLocale()].OFFLINE_MODE_LOAD_MORE_ERROR);
    }
  }

  componentDidUpdate(prevProps) {
    let { oldAuditNotificationTapError, auditExist, setCurrentAudit, history } =
      this.props;
    if (!prevProps.auditExist && auditExist && auditExist.audit_assignment_id) {
      setCurrentAudit(auditExist);
      history.push(RouteConstants.AUDIT_NC_LIST, {
        audit: auditExist,
      });
    }

    if (
      oldAuditNotificationTapError !== prevProps.oldAuditNotificationTapError
    ) {
      if (typeof oldAuditNotificationTapError === 'string') {
        showErrorAlert(oldAuditNotificationTapError, [
          {
            text: localization[getLocale()].OK,
            onPress: () => {},
            style: 'cancel',
          },
        ]);
      }
    }
  }

  onRefreshNotifications = () => {
    const { isMobileNotificationLoading, isMobileNotificationLoadingMore } =
      this.props;
    if (navigator.onLine) {
      if (!isMobileNotificationLoading && !isMobileNotificationLoadingMore) {
        this.props.getMobileNotification({ shouldSync: true });
      }
    } else {
      showErrorAlert(localization[getLocale()].OFFLINE_MODE_REFRESH_ERROR);
    }
  };

  onRefreshUpdates = () => {
    if (navigator.onLine) {
      const { isMobileUpdatesLoading, isMobileUpdatesLoadingMore } = this.props;
      if (!isMobileUpdatesLoading && !isMobileUpdatesLoadingMore) {
        this.props.getMobileUpdates({ shouldSync: true });
      }
    } else {
      showErrorAlert(localization[getLocale()].OFFLINE_MODE_REFRESH_ERROR);
    }
  };

  loadMoreNotifications = () => {
    if (navigator.onLine) {
      const {
        mobile_notifications,
        isMobileNotificationLoading,
        isMobileNotificationLoadingMore,
      } = this.props;
      const length = mobile_notifications.length;

      let properties = {};
      properties[Properties.LIST_NAME] = 'Notification';
      properties[Properties.NUMBER_OF_RECORDS] = length;
      trackEvent(Events.LOAD_MORE_LISTING, properties);

      if (
        length &&
        !isMobileNotificationLoadingMore &&
        !isMobileNotificationLoading
      ) {
        this.props.getMobileNotification({
          max_id: mobile_notifications[length - 1].mobile_notification_id,
        });
      }
    } else {
      showErrorAlert(localization[getLocale()].OFFLINE_MODE_LOAD_MORE_ERROR);
    }
  };

  loadMoreUpdates = () => {
    if (navigator.onLine) {
      const {
        mobile_updates,
        isMobileUpdatesLoading,
        isMobileUpdatesLoadingMore,
      } = this.props;
      const length = mobile_updates.length;

      let properties = {};
      properties[Properties.LIST_NAME] = 'Updates';
      properties[Properties.NUMBER_OF_RECORDS] = length;
      trackEvent(Events.LOAD_MORE_LISTING, properties);

      if (length && !isMobileUpdatesLoading && !isMobileUpdatesLoadingMore) {
        this.props.getMobileUpdates({
          max_id: mobile_updates[length - 1].mobile_notification_id,
        });
      }
    } else {
      showErrorAlert(localization[getLocale()].OFFLINE_MODE_LOAD_MORE_ERROR);
    }
  };

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          type={'FontAwesome'}
          name={'chevron-left'}
          onPress={() => {
            this.props.setMobileNotificationCount({
              mobileNotificationCount: 0,
            });
            this.props.history.goBack();
          }}
        />
      </div>
    );
  };

  renderNotificationsFooter = () => {
    const { isMobileNotificationLoadingMore } = this.props;
    if (!isMobileNotificationLoadingMore) {
      return null;
    }
    return (
      <Spinner
        color={'rgb(80,45,127)'}
        size="large"
        animating={isMobileNotificationLoadingMore}
      />
    );
  };

  renderUpdatesFooter = () => {
    const { isMobileUpdatesLoadingMore } = this.props;
    if (!isMobileUpdatesLoadingMore) {
      return null;
    }
    return (
      <Spinner
        color={'rgb(80,45,127)'}
        size="large"
        animating={isMobileUpdatesLoadingMore}
      />
    );
  };

  toggle = (tab) => {
    //scroll to top on tab change did this due to issue
    //When i load notification (activities tab), The update tab automatically also scroll down and load more data.
    let scrollableDiv = document.getElementById('scrollableDiv');
    if (scrollableDiv) scrollableDiv.scrollTop = 0;

    if (tab === 0) {
      trackEvent(Events.VIEWED_NOTIFICATIONS);
    } else {
      trackEvent(Events.VIEWED_UPDATES);
    }
    this.setState({
      activeTab: tab,
    });
  };

  render() {
    const {
      isMobileNotificationLoading,
      mobile_notifications,
      mobile_updates,
      isMobileUpdatesLoading,
      history,
      noMoreRecords,
      noMoreUpdateRecords,
      getAuditById,
    } = this.props;
    let { activeTab } = this.state;

    return (
      <>
        <AppContainer
          title={localization[getLocale()].NOTIFICATIONS}
          iconHeader
          noncollapsible
          IconLeftHeader={this.renderLeftHeader}
          history={history}>
          <Nav tabs className="notification-nav">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => {
                  this.toggle('1');
                }}>
                {localization[getLocale()].ACTIVITY}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  this.toggle('2');
                }}>
                {localization[getLocale()].UPDATE}
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent
            activeTab={this.state.activeTab}
            className="notification-tab">
            <TabPane tabId="1">
              {globalHelper.renderIf(
                isMobileNotificationLoading &&
                  mobile_notifications.length !== 0,
              )(
                <div className="bottom-infinite-loader">
                  <Spinner color={'red'} />
                </div>,
              )}
              {this.state.activeTab === '1' ? (
                <>
                  <InfiniteScroll
                    key={'1b9eld0'}
                    dataLength={mobile_notifications.length}
                    next={this.loadMoreNotifications}
                    hasMore={
                      mobile_notifications.length < 10 ? false : !noMoreRecords
                    }
                    endMessage={
                      <p style={{ textAlign: 'center' }}>
                        {mobile_notifications.length > 0 && (
                          <b>{localization[getLocale()].NO_MORE_RECORDS}</b>
                        )}
                      </p>
                    }
                    scrollThreshold={1}
                    scrollableTarget="scrollableDiv"
                    refreshFunction={this.onRefreshNotifications}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={50}
                    pullDownToRefreshContent={
                      <div className="bottom-infinite-loader">
                        <Spinner color={'red'} />
                      </div>
                    }
                    releaseToRefreshContent={
                      <div className="bottom-infinite-loader">
                        <Spinner color={'red'} />
                      </div>
                    }>
                    <div id="scrollableDiv">
                      {mobile_notifications &&
                      mobile_notifications.length > 0 ? (
                        mobile_notifications.map((item, index) => {
                          return (
                            <NotificationItem
                              {...this.props}
                              key={item.mobile_notification_id.toString()}
                              item={item}
                              setCurrentAudit={this.props.setCurrentAudit}
                              getAuditById={getAuditById}
                            />
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </InfiniteScroll>
                  {isMobileNotificationLoading ? (
                    <div className="bottom-infinite-loader">
                      {globalHelper.renderIf(isMobileNotificationLoading)(
                        <Spinner color={'red'} />,
                      )}
                    </div>
                  ) : mobile_notifications &&
                    mobile_notifications.length < 1 ? (
                    <div className="no-result-found-box">
                      <p>
                        {localization[getLocale()].NO_NOTIFICATION_AVAILABLE}
                      </p>
                    </div>
                  ) : null}
                </>
              ) : null}
            </TabPane>
            <TabPane tabId="2">
              {globalHelper.renderIf(
                isMobileUpdatesLoading && mobile_updates.length !== 0,
              )(
                <div className="bottom-infinite-loader">
                  <Spinner color={'red'} />
                </div>,
              )}
              {this.state.activeTab === '2' ? (
                <>
                  <InfiniteScroll
                    key={'asdbashbd'}
                    dataLength={mobile_updates.length}
                    next={this.loadMoreUpdates}
                    hasMore={
                      mobile_updates.length < 10 ? false : !noMoreUpdateRecords
                    }
                    endMessage={
                      <p style={{ textAlign: 'center' }}>
                        {mobile_updates.length > 0 && (
                          <b>{localization[getLocale()].NO_MORE_RECORDS}</b>
                        )}
                      </p>
                    }
                    scrollThreshold={1}
                    scrollableTarget="scrollableDiv"
                    refreshFunction={this.onRefreshUpdates}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={50}
                    pullDownToRefreshContent={
                      <div className="bottom-infinite-loader">
                        <Spinner color={'red'} />
                      </div>
                    }
                    releaseToRefreshContent={
                      <div className="bottom-infinite-loader">
                        <Spinner color={'red'} />
                      </div>
                    }>
                    <div id="scrollableDiv">
                      {mobile_updates && mobile_updates.length > 0 ? (
                        mobile_updates.map((item, index) => {
                          return (
                            <NotificationUpdateItem
                              {...this.props}
                              key={item.mobile_notification_id.toString()}
                              item={item}
                              setCurrentAudit={this.props.setCurrentAudit}
                            />
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </InfiniteScroll>
                  {isMobileUpdatesLoading ? (
                    <div className="bottom-infinite-loader">
                      {globalHelper.renderIf(isMobileUpdatesLoading)(
                        <Spinner color={'red'} />,
                      )}
                    </div>
                  ) : mobile_updates && mobile_updates.length < 1 ? (
                    <div className="no-result-found-box">
                      <p>{localization[getLocale()].NO_UPDATE_AVAILABLE}</p>
                    </div>
                  ) : null}
                </>
              ) : null}
            </TabPane>
          </TabContent>
        </AppContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMobileNotification: (payload) =>
      dispatch(getMobileNotificationRequest(payload)),
    getMobileUpdates: (payload) => dispatch(getMobileUpdateRequest(payload)),
    setMobileNotificationCount: (payload) =>
      dispatch(setMobileNotificationCount(payload)),
    setCurrentAudit: (audit) => dispatch(setCurrentAuditRequested(audit)),
    getAuditById: (id) => dispatch(getAuditByIdRequested(id)),
  };
};

const mapStateToProps = (state) => {
  const {
    mobile_notifications,
    isMobileNotificationLoading,
    isMobileNotificationLoadingMore,

    mobile_updates,
    isMobileUpdatesLoading,
    isMobileUpdatesLoadingMore,
    noMoreRecords,
    noMoreUpdateRecords,
  } = state.dashboard;
  const { auditExist, oldAuditNotificationTapError } = state.audits;

  return {
    mobile_notifications,
    isMobileNotificationLoading,
    isMobileNotificationLoadingMore,

    mobile_updates,
    isMobileUpdatesLoading,
    isMobileUpdatesLoadingMore,
    noMoreRecords,
    noMoreUpdateRecords,
    auditExist,
    oldAuditNotificationTapError,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
