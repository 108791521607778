import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Input } from 'reactstrap';

import './style.scss';

import AppContainer from '../../../../components/AppContainer';
import PBIcon from '../../../../components/PBIcon';
import Separator from '../../../../components/Separator';
import URLCard from '../../../../components/URLCard';
import FormButton from '../../../../components/FormButton';
import {
  showErrorAlert,
  showSuccessAlert,
} from '../../../../components/Alerts';

import localization from '@progressivebeef/shared/src/localization/i18n';
import {
  getLocale,
  getSelectedFeedlotId,
} from '@progressivebeef/shared/src/services/storageService/GlobalData';

import DocumentHelper from '@progressivebeef/shared/src/helpers/DocumentHelper';
import ProgramManualHelper from '@progressivebeef/shared/src/helpers/ProgramManualHelper';

import { AddDocumentValidationSchema } from '@progressivebeef/shared/src/constants/FormValidations';
import {
  createTrainingRequest,
  updateTrainingRequest,
  resetCreateProps,
  getProgramManualRequest,
  getProgramManualByIdRequest,
} from '@progressivebeef/shared/src/store/actions/programMaunals';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';
import {
  showLoader,
  hideLoader,
} from '@progressivebeef/shared/src/store/actions/view';
import { getUsersRequested } from '@progressivebeef/shared/src/store/actions/user';
import VisibleTo from '../VisibleTo';
import {
  documentTitleRegex,
  specialCharactersRegex,
} from '@progressivebeef/shared/src/constants/AppConstants';

class AddDocument extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      englishFile: null,
      spanishFile: null,
      urls: [],
      urlText: '',
      showVisibleTo: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const currentProgramManual = location.state
      ? location.state.currentProgramManual
      : null;
    if (currentProgramManual) {
      this.props.getProgramManualById({
        document_number: currentProgramManual.document_number,
        document_id: currentProgramManual.document_id,
      });
    }
    this.props.getUsers({ update: false });
  }

  componentDidUpdate(prevProps) {
    let { isDetailLoading, showLoaderView, hideLoaderView } = this.props;
    if (isDetailLoading) {
      showLoaderView();
    } else {
      hideLoaderView();
    }

    const { isCreated, updateTrainingError, isUpdated } = this.props;
    if (isCreated) {
      showSuccessAlert(localization[getLocale()].DOCUMENT_ADDED, [
        {
          text: localization[getLocale()].OK,
          onPress: () => {
            this.props.resetCreateProps();
            this.props.history.goBack();
            this.props.getProgramManuals({ update: true, loading: true });
          },
        },
      ]);
    }

    if (isUpdated) {
      showSuccessAlert(localization[getLocale()].DOCUMENT_UPDATED, [
        {
          text: localization[getLocale()].OK,
          onPress: () => {
            this.props.resetCreateProps();
            this.props.history.goBack();
            this.props.getProgramManuals({ update: true, loading: true });
          },
        },
      ]);
    }

    if (
      updateTrainingError &&
      updateTrainingError !== prevProps.updateTrainingError
    ) {
      showErrorAlert(updateTrainingError);
    }

    const { programManual } = this.props;
    if (programManual !== prevProps.programManual && programManual) {
      this.setState({
        urls: Array.from(programManual.document_urls || {}).map((q) => q.url),
        spanishFile: {
          name:
            programManual.document_locale && programManual.document_locale[2]
              ? programManual.document_locale[2]?.file_name
              : '',
        },
        englishFile: {
          name: programManual.document_locale
            ? programManual.document_locale[1]?.file_name
            : '',
        },
      });
    }
  }

  visibleToPress = () => {
    this.setState({ showVisibleTo: true });
  };

  selectFile = async (type, e, setFieldValue) => {
    try {
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = (readerEvt) => {
        let binaryString = readerEvt.target.result;
        let fileData = btoa(binaryString);
        let fileToSave = {
          data: fileData,
          name: file.name,
          type: file.type,
        };

        if (type == 'spanishFile') {
          this.setState({
            spanishFile: fileToSave,
          });
        } else {
          this.setState({
            englishFile: file,
          });
        }

        setFieldValue(type, fileToSave);
      };
    } catch (err) {
      throw err;
    }
  };

  cancelEnglishFiles = (setFieldValue) => {
    let englishFileInput = document.getElementById('englishFile');
    englishFileInput.value = null;

    this.setState({
      englishFile: null,
    });
    setFieldValue('englishFile', null);
  };

  cancelSpanishFiles = (setFieldValue) => {
    let spanishFileInput = document.getElementById('spanishFile');
    spanishFileInput.value = null;

    this.setState({
      spanishFile: null,
    });

    setFieldValue('spanishFile', null);
  };

  addUrl = () => {
    const { urls, urlText } = this.state;

    if (urlText === '') {
      showErrorAlert(localization[getLocale()].NO_URL_FOUND);
    } else if (DocumentHelper.validURL(urlText)) {
      this.setState({
        urls: [...urls, urlText],
        urlText: '',
      });
    } else {
      showErrorAlert(localization[getLocale()].INVALID_URL);
    }
  };

  setURLText = () => {
    //target element was showing null in params thats why i had to do this
    let urlField = document.getElementById('urls');
    let urlText = urlField.value;
    this.setState({
      urlText,
    });
  };

  onUrlCancelPress = (text) => {
    let filteredArray = this.state.urls.filter((item) => item !== text);
    this.setState({ urls: filteredArray });
  };

  onUrlEditPress = (text) => {
    let filteredArray = this.state.urls.filter((item) => item !== text);
    this.setState({ urls: filteredArray, urlText: text });
  };

  getUsersArray() {
    const { visibleToUsers, users } = this.props;
    let usersList = [];

    if (visibleToUsers && visibleToUsers.length === 0) {
      usersList = users.map((q) => q.user_id);
    } else {
      if (visibleToUsers.findIndex((q) => q.key === 'All') === -1) {
        usersList = visibleToUsers.map((q) => q.key);
      } else {
        usersList = users.map((q) => q.user_id);
      }
    }

    return usersList;
  }

  getUsersValue() {
    const { visibleToUsers } = this.props;
    let usersList = '';

    if (visibleToUsers && visibleToUsers.length === 0) {
      usersList = localization[getLocale()].ALL;
    } else {
      if (visibleToUsers.findIndex((q) => q.key === 'All') === -1) {
        usersList = visibleToUsers.map((q, index) =>
          index < 2 ? q.value : null,
        );

        if (usersList.length > 1) {
          usersList = usersList.slice(0, 1).join(', ');
          usersList = usersList + ' ...';
        } else {
          usersList = usersList.join(', ');
        }
      } else {
        usersList = localization[getLocale()].ALL;
      }
    }

    return usersList;
  }

  addDocument = async (values) => {
    try {
      let document = {
        feedlot_document: {
          document_number: 'm-' + values.documentNumber,
          document_number_without_prefix: values.documentNumber,
          document_tags: '',
          document_type_key: 5,
          english_file_name: values.englishFile.name,
          english_title: values.englishTitle,
          spanish_file_name: values.spanishFile.name
            ? values.spanishFile.name
            : '',
          spanish_title: values.spanishTitle ? values.spanishTitle : '',
          feedlot_id: getSelectedFeedlotId(),
          tag_key: 3,
          user_ids: this.getUsersArray(),
          view_preference: 2,
          document_locale: {},
          urls: this.state.urls,
        },
      };

      if (values.englishFile) {
        document.feedlot_document.document_locale = {
          ...document.feedlot_document.document_locale,
          1: {
            file_name: values.englishFile.name,
            language_key: 1,
            title: values.englishTitle,
            data: values.englishFile.data,
          },
        };

        if (values.spanishFile) {
          document.feedlot_document.document_locale = {
            ...document.feedlot_document.document_locale,
            2: {
              file_name: values.spanishFile.name,
              language_key: 2,
              title: values.spanishTitle,
              data: values.spanishFile.data,
            },
          };
          this.props.createTraining(document);
          return;
        } else {
          this.props.createTraining(document);
          return;
        }
      }
    } catch (e) {
      console.log('add doc fail', e);
    }
  };

  updateDocument = async (values) => {
    const { location, programManual } = this.props;
    const currentProgramManual = location.state
      ? location.state.currentProgramManual
      : null;

    let document = {
      feedlot_document: {
        ...programManual.feedlot_document,
        document_number: 'm-' + values.documentNumber,
        document_number_without_prefix: values.documentNumber,
        document_type_key: 5,
        document_tags: programManual.document_tags,
        english_file_name: values.englishFile.name,
        english_title: values.englishTitle,
        spanish_file_name: values.spanishFile.name
          ? values.spanishFile.name
          : '',
        spanish_title: values.spanishTitle ? values.spanishTitle : '',
        feedlot_id: getSelectedFeedlotId(),
        tag_key: 3,
        user_ids: this.getUsersArray(),
        view_preference: 2,
        urls: this.state.urls,
        urlIds: Array.from(programManual.document_urls || {}).map(
          (q) => q.feedlot_document_url_id,
        ),
        feedlot_document_id: currentProgramManual.feedlot_document_id,
      },
    };

    if (programManual.document_locale && programManual.document_locale[1]) {
      document.feedlot_document.document_locale = {
        ...document.feedlot_document.document_locale,
        1: {
          language_key: 1,
          title: values.englishTitle,
          feedlot_document_locale_id:
            programManual.document_locale[1].feedlot_document_locale_id,
        },
      };
    }

    if (programManual.document_locale && programManual.document_locale[2]) {
      document.feedlot_document.document_locale = {
        ...document.feedlot_document.document_locale,
        2: {
          language_key: 2,
          title: values.spanishTitle,
          feedlot_document_locale_id:
            programManual.document_locale[2].feedlot_document_locale_id,
        },
      };
    }

    if (
      (values.englishFile && programManual.document_locale
        ? programManual.document_locale[1].file_name
        : '') !== values.englishFile.name &&
      values.englishFile.data
    ) {
      document.feedlot_document.document_locale = {
        ...document.feedlot_document.document_locale,
        1: {
          file_name: values.englishFile.name,
          language_key: 1,
          title: values.englishTitle,
          data: values.englishFile.data,
        },
      };
      if (
        (values.spanishFile &&
        programManual.document_locale &&
        programManual.document_locale[2]
          ? programManual.document_locale[2].file_name
          : '') !== values.spanishFile.name &&
        values.spanishFile.data
      ) {
        document.feedlot_document.document_locale = {
          ...document.feedlot_document.document_locale,
          2: {
            file_name: values.spanishFile.name,
            language_key: 2,
            title: values.spanishTitle,
            data: values.spanishFile.data,
          },
        };
        this.props.updateTraining(document);
        return;
      } else {
        this.props.updateTraining(document);
        return;
      }
    } else if (
      (values.spanishFile &&
      programManual.document_locale &&
      programManual.document_locale[2]
        ? programManual.document_locale[2].file_name
        : '') !== values.spanishFile.name &&
      values.spanishFile.data
    ) {
      document.feedlot_document.document_locale = {
        ...document.feedlot_document.document_locale,
        2: {
          file_name: values.spanishFile.name,
          language_key: 2,
          title: values.spanishTitle,
          data: values.spanishFile.data,
        },
      };
      this.props.updateTraining(document);
      return;
    } else {
      this.props.updateTraining(document);
      return;
    }
  };

  render() {
    const { programManual, location, history } = this.props;
    const currentProgramManual = location.state
      ? location.state.currentProgramManual
      : null;
    const { englishFile, spanishFile, urls, urlText, showVisibleTo } =
      this.state;

    const AddDocumentFormInitialValues =
      ProgramManualHelper.getDocumentInitialFormValues(
        programManual,
        currentProgramManual,
      );

    return (
      <Formik
        initialValues={AddDocumentFormInitialValues}
        enableReinitialize={true}
        onSubmit={(values) =>
          currentProgramManual
            ? this.updateDocument(values)
            : this.addDocument(values)
        }
        validateOnMount={true}
        validationSchema={AddDocumentValidationSchema}>
        {(props) => {
          const {
            handleSubmit,
            handleChange,
            values,
            errors,
            setFieldValue,
            handleBlur,
            isValid,
            submitCount,
          } = props;
          let isSubmitted = submitCount > 0;
          return (
            <AppContainer
              title={
                currentProgramManual
                  ? localization[getLocale()].EDIT_DOCUMENT
                  : localization[getLocale()].ADD_DOCUMENT
              }
              iconHeader
              IconLeftHeader={this.renderLeftHeader}
              noncollapsible
              history={history}>
              {showVisibleTo && (
                <VisibleTo
                  modalVisible={showVisibleTo}
                  dismissModal={() => this.setState({ showVisibleTo: false })}
                  history={history}
                />
              )}
              <div className="add-document-main-sec">
                <div className="row-box">
                  <div className="label">
                    {localization[getLocale()].DOCUMENT_NO}*
                  </div>
                  <div className="field-box">
                    <Input
                      type="text"
                      id="documentNumber"
                      value={values.documentNumber}
                      disabled={currentProgramManual ? true : false}
                      onChange={handleChange('documentNumber')}
                      onBlur={handleBlur('documentNumber')}
                      error={isSubmitted && errors.documentNumber}
                      placeholder={localization[getLocale()].DOCUMENT_NO}
                    />
                  </div>
                </div>
                <div className="row-box">
                  <div className="label">
                    {localization[getLocale()].VISIBLE_TO}*
                  </div>
                  <div className="field-box">
                    <button
                      id="users"
                      value={this.getUsersValue()}
                      onSubmit={handleChange('users')}
                      onClick={this.visibleToPress}
                      error={isSubmitted && errors.users}>
                      {this.getUsersValue()}
                    </button>
                  </div>
                </div>
                <Separator title={localization[getLocale()].ENGLISH} />
                <div className="row-box">
                  <div className="label">
                    {localization[getLocale()].TITLE}*
                  </div>
                  <div className="field-box">
                    <Input
                      type="text"
                      id="englishTitle"
                      value={values.englishTitle}
                      onChange={handleChange('englishTitle')}
                      onBlur={handleBlur('englishTitle')}
                      error={isSubmitted && errors.englishTitle}
                      placeholder={localization[getLocale()].ENTER_TITLE}
                    />
                  </div>
                </div>
                <div className="row-box">
                  <div className="label">
                    {localization[getLocale()].ATTACH}*
                  </div>
                  <div className="field-box">
                    <Input
                      type="file"
                      id="englishFile"
                      accept={'.pdf'}
                      onChange={async (e) =>
                        await this.selectFile('englishFile', e, setFieldValue)
                      }
                      error={isSubmitted && errors.englishFil}
                      placeholder={localization[getLocale()].SELECT_FILES}
                      className={
                        englishFile && englishFile.name != ''
                          ? ' selected-file'
                          : ''
                      }
                    />
                    <div className="file-name">
                      {englishFile && englishFile.name != '' && (
                        <div className="inner-col">
                          {englishFile.name}
                          <PBIcon
                            onPress={() =>
                              this.cancelEnglishFiles(setFieldValue)
                            }
                            name={'times-circle'}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <Separator title={localization[getLocale()].SPANISH} />
                <div className="row-box">
                  <div className="label">{localization[getLocale()].TITLE}</div>
                  <div className="field-box">
                    <Input
                      type="text"
                      id="spanishTitle"
                      value={values.spanishTitle}
                      onChange={handleChange('spanishTitle')}
                      onBlur={handleBlur('spanishTitle')}
                      error={isSubmitted && errors.spanishTitle}
                      placeholder={localization[getLocale()].ENTER_TITLE}
                    />
                  </div>
                </div>
                <div className="row-box">
                  <div className="label">
                    {localization[getLocale()].ATTACH}
                  </div>
                  <div className="field-box">
                    <Input
                      type="file"
                      id="spanishFile"
                      accept={'.pdf'}
                      onChange={async (e) =>
                        await this.selectFile('spanishFile', e, setFieldValue)
                      }
                      error={isSubmitted && errors.spanishFile}
                      placeholder={localization[getLocale()].SELECT_FILES}
                      className={
                        spanishFile && spanishFile.name != ''
                          ? 'selected-file'
                          : ''
                      }
                    />
                    <div className="file-name">
                      {spanishFile && spanishFile.name != '' && (
                        <div className="inner-col">
                          {spanishFile.name}
                          <PBIcon
                            onPress={() =>
                              this.cancelSpanishFiles(setFieldValue)
                            }
                            name={'times-circle'}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <Separator title={localization[getLocale()].URL} />
                <div className="row-box">
                  <div className="label">{localization[getLocale()].URL}</div>
                  <div className="field-box">
                    <Input
                      type="text"
                      id="urls"
                      value={urlText}
                      onChange={this.setURLText}
                      placeholder={'https://www.example.com'}
                    />
                    <button
                      className="add-url-icon"
                      icon={'plus'}
                      onClick={this.addUrl}>
                      +
                    </button>
                  </div>
                </div>
                <div className="edit-document-url-card">
                  {urls.map((q, index) => {
                    return (
                      <URLCard
                        key={'url-' + index}
                        onCancelPress={this.onUrlCancelPress}
                        onEditPress={this.onUrlEditPress}
                        text={q}
                      />
                    );
                  })}
                </div>
              </div>
              <FormButton
                title={localization[getLocale()].SAVE}
                success
                onPressEvent={() =>
                  this.onSavePress(handleSubmit, isValid, values)
                }
              />
            </AppContainer>
          );
        }}
      </Formik>
    );
  }

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          name={'chevron-left'}
          onPress={() => {
            this.props.history.goBack();
            this.props.resetCreateProps();
          }}
        />
      </div>
    );
  };

  onSavePress = (handleSubmit, isValid, values) => {
    if (!isValid) {
      showErrorAlert(localization[getLocale()].PLEASE_FILL_ALL_FIELDS);
    } else if (this.validateAddDocumentRequest(values)) {
      handleSubmit();
    }
  };

  validateAddDocumentRequest = (data) => {
    let isValid = true;
    if (specialCharactersRegex.test(data.documentNumber)) {
      isValid = false;
      showErrorAlert(localization[getLocale()].INVALID_DOCUMENT_NO);
    } else if (documentTitleRegex.test(data.englishTitle)) {
      isValid = false;
      showErrorAlert(localization[getLocale()].INVALID_DOCUMENT_TITLE);
    } else if (documentTitleRegex.test(data.spanishTitle)) {
      isValid = false;
      showErrorAlert(localization[getLocale()].INVALID_DOCUMENT_TITLE);
    }
    return isValid;
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (payload) => dispatch(getUsersRequested(payload)),
    createTraining: (payload) => dispatch(createTrainingRequest(payload)),
    updateTraining: (payload) => dispatch(updateTrainingRequest(payload)),
    resetCreateProps: (payload) => dispatch(resetCreateProps(payload)),
    getProgramManuals: (payload) => dispatch(getProgramManualRequest(payload)),
    getProgramManualById: (payload) =>
      dispatch(getProgramManualByIdRequest(payload)),
    showLoaderView: (payload) => dispatch(showLoader(payload)),
    hideLoaderView: (payload) => dispatch(hideLoader(payload)),
  };
};

const mapStateToProps = (state) => {
  const { users } = state.user;
  const {
    visibleToUsers,
    isCreated,
    programManual,
    updateTrainingError,
    isUpdated,
    isDetailLoading,
  } = state.programManuals;

  return {
    programManual,
    isDetailLoading,
    visibleToUsers,
    isCreated,
    users,
    updateTrainingError,
    isUpdated,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDocument);
