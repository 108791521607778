// modules
import {
  call,
  put,
  takeLatest,
  select,
  takeEvery,
  delay,
} from 'redux-saga/effects';

// constants
import ACTION_CONSTANTS from '../constants/actionConstants';
import AuthService from '../services/api/authentication';
import DashboardService from '../services/api/dashboard';

import {
  logoutRequested,
  logoutFailure,
  logoutSuccess,
  getInitializedSuccess,
  getInitializedFailure,
  loginSuccess,
  loginFailure,
  setFirstLoginSuccess,
  setFirstLoginFailure,
  setLatestAndroidVersionRequested,
  setLatestAndroidVersionSuccess,
  setLatestAndroidVersionFailure,
  setLatestiOSVersionSuccess,
  setLatestiOSVersionRequested,
  setLatestiOSVersionFailure,
  toggleBiometricSuccess,
  toggleBiometricFailure,
  loginRequested,
  loginViaBiometricFailure,
  setAndroidForceUpdateSuccess,
  setAndroidForceUpdateFailure,
  setIOSForceUpdateSuccess,
  setIOSForceUpdateFailure,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  generatePinSuccess,
  generatePinFailure,
  validatePinSuccess,
  validatePinFailure,
  unlockAppViaBiometricFailure,
  unlockAppViaBiometricSuccess,
  emailAlertsToggleFailure,
  emailAlertsToggleSuccess,
} from '../store/actions/authentication';
import localization from '../localization/i18n';
import {
  setLocale,
  setToken,
  setUserRole,
  setUserId,
  setUsername,
  getLocale,
  setFirstLogin,
  setSelectedFeedlotId,
  setSelectedFeedlotName,
  setSelectedFeedlotTrainingRulesEnabled,
  setSelectedFeedlotRankEnabled,
  setUserFirstName,
  setUserLastName,
  getSelectedFeedlotId,
} from '../services/storageService/GlobalData';
import authenticationHelper from '../helpers/authenticationHelper';
import OptionsManager from '../database/reactDataManager/OptionsManager';
import AuthManager from '../database/reactDataManager/AuthManager';
import UserManager from '../database/reactDataManager/UserManager';
import { validateNewUserPin } from '../validators/form-validators';
import FeedlotManager from '../database/reactDataManager/FeedlotManager';
import { syncDataBlockingRequest } from '../store/actions/dataSync';

import { setMixpanelUser, trackEvent } from '../services/reactMixpanelService';
import { Events, Properties } from '../constants/MixpanelConstants';
import mixpanel from 'mixpanel-browser';
import { LogoutMode } from '../constants/AppConstants';
import moment from 'moment';

function* getInitialized(action) {
  try {
    // initialize sentry
    // Sentry.init();

    //initialize mixplanel
    mixpanel.init('004a38807d9fbbb6ea11b52b64637ea9');

    let user = {},
      selectedFeedyard = null,
      isLocked = false,
      emailAlerts = OptionsManager.getLoginUserEmailAlerts();

    if (OptionsManager.isLogin()) {
      setToken(OptionsManager.getToken());
      setUserRole(parseInt(OptionsManager.getLoggedinUserRoleId(), 10));
      setUserId(parseInt(OptionsManager.getLoggedinUserId(), 10));
      setUsername(OptionsManager.getLoggedinUserName());
      const userFullName = OptionsManager.getLoggedinUserFullName().split(' ');
      setUserFirstName(userFullName[0]);
      setUserLastName(userFullName[1]);

      user = OptionsManager.getLoggedinUser();
      isLocked = OptionsManager.isLocked();

      let selectedFeedyardId = OptionsManager.getSelectedFeedyard();
      if (selectedFeedyardId) {
        selectedFeedyard = FeedlotManager.getFeedyardById(selectedFeedyardId);
        setSelectedFeedlotRankEnabled(selectedFeedyard.is_ranked);
        setSelectedFeedlotId(selectedFeedyardId);
        setSelectedFeedlotName(selectedFeedyard.name);
        setSelectedFeedlotTrainingRulesEnabled(
          selectedFeedyard.training_rules_enabled,
        );
      }

      // set user for sentry
      // Sentry.setUserContext({
      //   username: user.userName,
      //   id: user.userId,
      // });
    }

    if (!OptionsManager.getLanguage()) {
      OptionsManager.saveLanguage();
    }
    setLocale(OptionsManager.getLanguage());
    moment.locale(OptionsManager.getLanguage());

    setFirstLogin(false);
    yield put(
      getInitializedSuccess({
        user,
        isFingerPrintEnabled: false,
        isFaceUnlockEnabled: false,
        isFirstLogin: false,
        selectedFeedyard,
        isLocked,
        emailAlerts,
      }),
    );

    if (user.userId != null && getSelectedFeedlotId() != null) {
      yield put(syncDataBlockingRequest());
    }
  } catch (e) {
    console.log('getInitializedFailure', e);
    yield put(getInitializedFailure(e));
  }
}

function* login(action) {
  try {
    let requestData = { user: action.payload.requestData };
    if (navigator.onLine) {
      let response = yield call(AuthService.login, requestData);
      if (response && response.data) {
        const loggedinUser = response.data.user;
        if (authenticationHelper.checkUserAuthorization(loggedinUser)) {
          //set language that was selected while logging in
          OptionsManager.saveLanguage();

          //create user obj for reducer also add relevent data in options table DB at the same time
          const user = {
            isLogin: OptionsManager.saveIsLogin('true'),
            userId: OptionsManager.saveLoggedinUserId(
              loggedinUser.user_id.toString(),
            ),
            pinCode: OptionsManager.savePinCode(loggedinUser.pin),
            token: OptionsManager.saveToken(loggedinUser.token),
            userName: OptionsManager.saveLoggedinUserName(
              loggedinUser.user_name,
            ),
            userEmail: OptionsManager.saveLoggedinUserEmail(
              loggedinUser.email_address,
            ),
            userFullName: OptionsManager.saveLoggedinUserFullName(
              loggedinUser.profile.first_name +
                ' ' +
                loggedinUser.profile.last_name,
            ),
            userRoleId: OptionsManager.saveLoggedinUserRoleId(
              loggedinUser.user_role_key.toString(),
            ),
            userRoleName: OptionsManager.saveLoggedinUserRoleName(
              loggedinUser.user_role_name,
            ),
            userEmailAlerts: OptionsManager.saveLoggedinUserEmailAlerts(
              loggedinUser.enable_email_alerts,
            ),
          };

          setToken(OptionsManager.getToken());
          setUserRole(parseInt(OptionsManager.getLoggedinUserRoleId(), 10));
          setUserId(parseInt(OptionsManager.getLoggedinUserId(), 10));
          setUsername(OptionsManager.getLoggedinUserName());
          const userFullName =
            OptionsManager.getLoggedinUserFullName().split(' ');
          setUserFirstName(userFullName[0]);
          setUserLastName(userFullName[1]);

          let userdataSaved = UserManager.saveUserInfo(loggedinUser);

          if (userdataSaved) {
            setFirstLogin(true);

            try {
              const device = (state) => state.view.device;
              const deviceId = yield select(device);
              const userDevice = yield call(
                DashboardService.setUserDevice,
                {
                  device: {
                    user_id: OptionsManager.getLoggedinUserId(),
                    device_token: deviceId.userId,
                    device_os: 'Web',
                    app_type: 2,
                  },
                },
                OptionsManager.getLoggedinUserId(),
                OptionsManager.getToken(),
              );
            } catch (e) {
              console.log(e);
            }

            //Mixpanel
            let properties = {};
            properties[Properties.LANGUAGE] = getLocale();
            properties[Properties.LOGIN_MODE] = action.payload.requestData.pin
              ? 'PIN'
              : 'PWD';

            setMixpanelUser(loggedinUser);
            trackEvent(Events.LOGIN, properties);

            // login succeeded
            yield put(
              loginSuccess({
                user,
                isFirstLogin: true,
                isFingerPrintEnabled: false,
                isFaceUnlockEnabled: false,
              }),
            );

            // // set user for sentry
            // Sentry.setUserContext({
            //   username: loggedinUser.user_name,
            //   id: loggedinUser.user_id,
            // });
          } else {
            yield put(
              loginFailure({
                error: null, // localization[getLocale()].SOMETHING_WENT_WRONG,
              }),
            );
            yield put(logoutRequested());
          }
        } else {
          console.log('HERE');
          //show unauthorized msg
          yield put(
            loginFailure({
              error: localization[getLocale()].ERROR_NOT_AUTHORIZED,
            }),
          );
        }
      } else {
        yield put(
          loginFailure({
            error: null, // localization[getLocale()].SOMETHING_WENT_WRONG,
          }),
        );
      }
    } else {
      yield put(
        loginFailure({
          error: localization[getLocale()].NO_INTERNET_CONNECTION,
        }),
      );
    }
  } catch (e) {
    console.log('login error', e);
    let error =
      e.message || (typeof e.message != 'undefined' && e.message == null)
        ? e.message
        : localization[getLocale()].ERROR_LOGIN_FAILED;
    yield put(loginFailure({ error }));
  }
}

function* forgotPassword(action) {
  try {
    const request = {
      user_name: action.payload.username,
    };
    if (navigator.onLine) {
      const response = yield call(AuthService.resetPassword, request);
      if (response) {
        yield put(
          forgotPasswordSuccess(
            localization[getLocale()].FORGOT_PASSWORD_REQUEST_SUCCESS_MSG,
          ),
        );
      }
    } else {
      yield put(
        forgotPasswordFailure({
          error: localization[getLocale()].NO_INTERNET_CONNECTION,
        }),
      );
    }
  } catch (e) {
    let error =
      e.message || e.message == null
        ? e.message
        : localization[getLocale()].ERROR_NO_EMAIL_TO_RESET_PASSWORD_PIN;
    yield put(
      forgotPasswordFailure({
        error,
      }),
    );
  }
}

function* logout(action) {
  try {
    let properties = {};

    if (navigator.onLine) {
      try {
        if (OptionsManager.isLogin() && OptionsManager.isLogin().value) {
          yield call(AuthService.logout);
          properties[Properties.LOGOUT_MODE] = LogoutMode.MANUAL;
        }
      } catch (e) {
        console.log('logoutFailure', e);
      }

      yield put(logoutSuccess({ isLogin: false }));

      properties[Properties.LOGOUT_MODE] = properties[Properties.LOGOUT_MODE]
        ? properties[Properties.LOGOUT_MODE]
        : LogoutMode.AUTO;
      trackEvent(Events.LOGOUT, properties);

      AuthManager.clearDBOnLogout();

      setLocale('en');
      setToken(null);
      setUserRole(null);
      setUserId(null);
      setUsername('');
      setSelectedFeedlotName('');
      setSelectedFeedlotId(null);
      moment.locale('en');

      window.location.reload(false);
    } else {
      yield put(
        logoutFailure({
          error: localization[getLocale()].NO_INTERNET_CONNECTION,
        }),
      );
    }
  } catch (e) {
    yield put(
      logoutFailure({
        error: null, // localization[getLocale()].SOMETHING_WENT_WRONG,
      }),
    );
  }
}

function* generatePin(action) {
  try {
    const { newPin, confirmPin } = action.payload.requestData;
    if (navigator.onLine) {
      let error = yield call(validateNewUserPin, newPin, confirmPin);

      if (error !== '') {
        yield put(generatePinFailure({ error }));
      } else {
        const userId = parseInt(OptionsManager.getLoggedinUserId(), 10);
        let response = yield call(AuthService.generatePin, {
          pin: newPin,
          userId,
        });

        if (response && response.data && response.data.user) {
          let responseUser = response.data.user;
          OptionsManager.savePinCode(responseUser.pin);
          yield put(generatePinSuccess({ pinCode: responseUser.pin }));
        } else {
          yield put(
            generatePinFailure({
              error: null, // localization[getLocale()].SOMETHING_WENT_WRONG,
            }),
          );
        }
      }
    } else {
      yield put(
        generatePinFailure({
          error: localization[getLocale()].NO_INTERNET_CONNECTION,
        }),
      );
    }
  } catch (e) {
    console.log('GENERATE_PIN_FAILED', e);
    yield put(generatePinFailure({ error: e }));
  }
}

function* validatePin(action) {
  try {
    let pinMatched = yield call(
      authenticationHelper.comparePin,
      OptionsManager.getPinCode(),
      action.payload.pin,
    );

    if (pinMatched) {
      OptionsManager.saveIsLocked(false);
      trackEvent(Events.PIN_USED_TO_UNLOCK);
      yield put(validatePinSuccess(true));
    } else {
      yield put(
        validatePinFailure({
          error: localization[getLocale()].INVALID_PIN_UNLOCK,
        }),
      );
    }
  } catch (e) {
    console.log('validatePinFailure', e);
    yield put(
      validatePinFailure({
        error: localization[getLocale()].INVALID_PIN_UNLOCK,
      }),
    );
  }
}

function* setLocaleToStorage() {
  try {
    OptionsManager.saveLanguage();
  } catch (e) {
    console.log('set locale to storage fail', e);
  }
}

function* setUserDeviceAsync(action) {
  try {
    // console.log('setUserDeviceAsync');
    const userDevice = yield call(
      DashboardService.setUserDevice,
      {
        device: {
          user_id: OptionsManager.getLoggedinUserId(),
          device_token: action.payload,
          device_os: 'Web',
          app_type: 2,
        },
      },
      OptionsManager.getLoggedinUserId(),
      OptionsManager.getToken(),
    );
  } catch (e) {
    console.log(e);
  }
}

function* showLockScreen() {
  try {
    yield delay(100);
    OptionsManager.saveIsLocked(true);
  } catch (e) {
    console.log('showLockScreen failed', e);
  }
}

function* toggleEmailAlertCheck(action) {
  try {
    if (navigator.onLine) {
      const { payload } = action;
      yield call(AuthService.toggleAlerts, {
        user: {
          enable_email_alerts: payload,
          user_id: parseInt(OptionsManager.getLoggedinUserId()),
        },
      });
      OptionsManager.saveLoggedinUserEmailAlerts(payload),
        yield put(
          emailAlertsToggleSuccess({
            enable_email_alerts: payload,
          }),
        );
    } else {
      yield put(
        emailAlertsToggleFailure({
          error: localization[getLocale()].NO_INTERNET_CONNECTION,
        }),
      );
    }
  } catch (e) {
    let error =
      e.message || (typeof e.message != 'undefined' && e.message == null)
        ? e.message
        : localization[getLocale()].ERROR_LOGIN_FAILED;
    yield put(emailAlertsToggleFailure({ error }));
  }
}

function* authSaga() {
  yield takeEvery(ACTION_CONSTANTS.GET_INITIALIZED, getInitialized);
  yield takeLatest(ACTION_CONSTANTS.LOGIN_REQUESTED, login);
  yield takeLatest(ACTION_CONSTANTS.SET_USER_DEVICE, setUserDeviceAsync);
  yield takeLatest(ACTION_CONSTANTS.FORGOT_PASSWORD_REQUESTED, forgotPassword);
  yield takeLatest(ACTION_CONSTANTS.LOGOUT_REQUESTED, logout);
  yield takeLatest(ACTION_CONSTANTS.GENERATE_PIN_REQUESTED, generatePin);
  yield takeEvery(ACTION_CONSTANTS.VALIDATE_PIN_REQUESTED, validatePin);
  yield takeLatest(ACTION_CONSTANTS.SET_LOCALE_TO_STORAGE, setLocaleToStorage);
  yield takeLatest(ACTION_CONSTANTS.SHOW_LOCK_SCREEN, showLockScreen);
  yield takeLatest(
    ACTION_CONSTANTS.TOGGLE_EMAIL_ALERT_REQUESTED,
    toggleEmailAlertCheck,
  );
}

export default authSaga;
