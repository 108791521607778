import React from 'react';
import PBIcon from '../PBIcon';
import FormButton from '../FormButton';

import './style.scss';

export default class MultiSelectList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataSource: props.data,
    };
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (data !== prevProps.data && data) {
      this.setState({
        dataSource: data,
      });
    }
  }

  selectItem = (data) => {
    if (data.key === 'All') {
      data.isSelect = !data.isSelect;
      this.setState({
        dataSource: this.props.data.map((q) => {
          return { key: q.key, value: q.value, isSelect: data.isSelect };
        }),
      });
    } else {
      data.isSelect = !data.isSelect;
      const index = this.state.dataSource.findIndex((key) => data.key === key);
      this.state.dataSource[index] = data;

      if (
        this.state.dataSource.filter((q) => q && q.isSelect).length ===
        this.props.data.length - 1
      ) {
        this.state.dataSource[0] = {
          ...this.state.dataSource[0],
          isSelect: data.isSelect,
        };
      }

      this.setState({
        dataSource: this.state.dataSource,
      });
    }
  };

  renderItem = (item) => {
    const { disabled } = this.props;

    return (
      <div
        key={item.key}
        className="activity-filter-tag-list"
        disabled={disabled ? disabled : false}
        onClick={() => (disabled ? null : this.selectItem(item))}>
        {item.value}
        <div className="icon-check">
          <PBIcon
            name={item.isSelect ? 'check' : ''}
            onPress={this.sortPress}
          />
        </div>
      </div>
    );
  };

  render() {
    const selected = this.state.dataSource.filter((q) => q && q.isSelect);
    const { onPress, buttonText, loading } = this.props;

    return (
      <>
        {this.state.dataSource &&
          this.state.dataSource.map((item) => {
            return this.renderItem(item);
          })}
        {/* <FlatList
          style={{ flexGrow: 0 }}
          refreshing={loading}
          data={this.state.dataSource}
          renderItem={({ item }) => (item ? this.renderItem(item) : null)}
          keyExtractor={(item, index) => index.toString()}
          extraData={this.state}
        /> */}
        {onPress && (
          <FormButton
            title={buttonText}
            success
            onPressEvent={() => onPress(selected)}
          />
        )}
      </>
    );
  }
}
