import {
  put,
  takeLatest,
  call,
  all,
  select,
  race,
  take,
} from 'redux-saga/effects';
import { getActivitiesAsync } from './activities';
import { getNavigationVideosAsync } from './navigationVideos';
import {
  getProgramManualsAsync,
  // getProgramManualTagsAsync,
} from './programManuals';
import { getAssignLogsUsersAsync } from './user';
import { getAuditsAsync } from './audits';
import {
  getWorkersAsync,
  getFeedlotStatsAsync,
  getMobileNotificationAsync,
  getMobileUpdateAsync,
  getMissedActivitiesAsync,
  getBackdatedSubmissionsAsync,
  getFeedlotRankAsync,
  //   getPendingActionsCountAsync,
} from './dashboard';
import { getAppTrainingVideoHtml } from './appTrainingVideoHTML';
import {
  syncDataBlockingSuccess,
  syncDataBlockingFailure,
  syncDataNonBlockingSuccess,
  syncDataNonBlockingFailure,
  syncDataManuallySuccess,
  syncDataManuallyFailure,
  postPendingDataRequest,
  cancelsync,
} from '../store/actions/dataSync';
import { syncActivitySubmissionsRequest } from '../store/actions/activities';
import ACTION_CONSTANTS from '../constants/actionConstants/index';

import localization from '../localization/i18n';
import {
  getLocale,
  getSelectedFeedlotRankEnabled,
  getUserRole,
} from '../services/storageService/GlobalData';
import {
  userRoles,
  MODULE_NAMES,
  DATE_FORMATS,
} from '../constants/AppConstants';

import MetaDataManager from '../database/reactDataManager/MetaDataManager';
import metaDataDto from '../models/metaDataModel';
import moment from 'moment';
import {
  getFeedlotCustomPerformanceRequest,
  getPendingActionsCountRequest,
} from '../store/actions/dashboard';
import OptionsManager from '../database/reactDataManager/OptionsManager';

export const dataSyncStoreRequest = (state) => state.dataSync;

/**
 * forks several calls with loading = true
 */
function* syncDataBlocking() {
  let lastSynced = new Date(); // lastsync time for syncing
  const userRole = getUserRole();

  let dataSyncMetaData = MetaDataManager.getMetaDataByModule(
    MODULE_NAMES.DATA_SYNC_MODULE, // get activity metadata for last sync time
  );
  if (dataSyncMetaData && dataSyncMetaData[0]) {
    lastSynced = dataSyncMetaData[0].lastSynced;
  }

  try {
    let dataSyncStore = yield select(dataSyncStoreRequest),
      { isSyncing, isSyncingManually, isAutoSyncing, isLoading } =
        dataSyncStore;

    const isSyncingInProgress =
      isSyncing || isSyncingManually || isAutoSyncing || isLoading;

    if (isSyncingInProgress) {
      yield put(cancelsync());
    }

    let dataSyncCalls = [
      call(getAppTrainingVideoHtml, {
        payload: { update: true },
      }),
      call(getNavigationVideosAsync, {
        payload: { update: true, loading: true },
      }),
      call(getBackdatedSubmissionsAsync, {
        payload: { loading: true },
      }),
      call(getActivitiesAsync, {
        payload: { update: true, loading: true },
      }),
      // call(getProgramManualTagsAsync),
      call(getProgramManualsAsync, {
        payload: { update: true, loading: true },
      }),
      call(getMobileNotificationAsync, {
        payload: { loading: true, firstCall: true },
      }),
      call(getFeedlotStatsAsync, { payload: { loading: true } }),
      // call(getPendingActionsCountAsync, {
      //   payload: { loading: true },
      // }),
    ];

    // only enable updates & missed activities for non-auditor users
    if (
      userRole !== userRoles.INTERNAL_AUDITOR &&
      userRole !== userRoles.EXTERNAL_AUDITOR
    ) {
      dataSyncCalls.push(
        call(getMobileUpdateAsync, { payload: { loading: true } }),
        call(getMissedActivitiesAsync, { payload: { loading: true } }),
      );
    }

    if (userRole != userRoles.WORKER) {
      // if (
      //   // userRole != userRoles.SUPERVISOR &&
      //   userRole != userRoles.TRAINER &&
      //   getSelectedFeedlotRankEnabled()
      // ) {
      //   dataSyncCalls.push(
      //     call(getFeedlotRankAsync, {
      //       payload: {},
      //     }),
      //   );
      // }
      dataSyncCalls.push(
        call(getAssignLogsUsersAsync, { payload: { update: true } }),
      );
      // dataSyncCalls.push(call(getUsersAsync, { payload: { update: true } }));
      dataSyncCalls.push(
        call(getWorkersAsync, { payload: { update: true, loading: true } }),
      );
      dataSyncCalls.push(
        call(getAuditsAsync, { payload: { update: true, loading: true } }),
      );
    }

    yield race({
      parallel: all(dataSyncCalls),
      cancelled: take(ACTION_CONSTANTS.CANCEL_SYNC),
    });
    // yield all(dataSyncCalls);
    yield put(getPendingActionsCountRequest());

    let performance = OptionsManager.getCustomPerformanceFilter();
    if (performance.startDate) {
      yield put(
        getFeedlotCustomPerformanceRequest({
          startDate: performance.startDate,
          endDate: performance.endDate,
        }),
      );
    }

    let metaDataModel = metaDataDto({
      moduleName: MODULE_NAMES.DATA_SYNC_MODULE,
      lastSynced: moment(lastSynced).format(DATE_FORMATS.YYYY_MM_DD_HH_mm_ss),
    });
    MetaDataManager.saveMetaData(metaDataModel); // upsert datasync metadata with new lastSync time
    yield put(syncDataBlockingSuccess({ lastSynced: new Date() }));
  } catch (e) {
    console.log(e);
    yield put(syncDataBlockingFailure({ error: e.message }));
  }
}

/**
 * forks several calls with loading false
 */
function* syncDataNonBlocking() {
  let lastSynced = new Date(); // lastsync time for syncing
  const userRole = getUserRole();

  let dataSyncMetaData = MetaDataManager.getMetaDataByModule(
    MODULE_NAMES.DATA_SYNC_MODULE, // get activity metadata for last sync time
  );
  if (dataSyncMetaData && dataSyncMetaData[0]) {
    lastSynced = dataSyncMetaData[0].lastSynced;
  }
  try {
    if (navigator.onLine) {
      yield all([
        put(
          postPendingDataRequest({
            payload: {},
          }),
        ),
      ]);
      let dataSyncCalls = [
        call(getAppTrainingVideoHtml, {
          payload: { update: true },
        }),
        call(getNavigationVideosAsync, {
          payload: { update: true },
        }),
        call(getBackdatedSubmissionsAsync, {
          payload: {},
        }),
        call(getActivitiesAsync, {
          payload: { update: true },
        }),
        // call(getProgramManualTagsAsync),
        // call(getProgramManualsAsync, {
        //   payload: { update: true, loading: true },
        // }),
        call(getMobileNotificationAsync, {
          payload: { shouldSync: true },
        }),
        call(getFeedlotStatsAsync, { payload: {} }),
      ];

      // only enable updates & missed activities for non-auditor users
      if (
        userRole !== userRoles.INTERNAL_AUDITOR &&
        userRole !== userRoles.EXTERNAL_AUDITOR
      ) {
        dataSyncCalls.push(
          call(getMobileUpdateAsync, { payload: { shouldSync: true } }),
          call(getMissedActivitiesAsync, { payload: {} }),
        );
      }

      if (userRole != userRoles.WORKER) {
        // if (
        //   // userRole != userRoles.SUPERVISOR &&
        //   userRole != userRoles.TRAINER &&
        //   getSelectedFeedlotRankEnabled()
        // ) {
        //   dataSyncCalls.push(
        //     call(getFeedlotRankAsync, {
        //       payload: {},
        //     }),
        //   );
        // }
        dataSyncCalls.push(
          call(getAssignLogsUsersAsync, { payload: { update: true } }),
          // call(getUsersAsync, { payload: { update: true } }),
          call(getWorkersAsync, { payload: { update: true } }),
          call(getAuditsAsync, { payload: { update: true } }),
        );
      }
      yield race({
        parallel: all(dataSyncCalls),
        cancelled: take(ACTION_CONSTANTS.CANCEL_SYNC),
      });

      let performance = OptionsManager.getCustomPerformanceFilter();
      if (performance.startDate) {
        yield put(
          getFeedlotCustomPerformanceRequest({
            startDate: performance.startDate,
            endDate: performance.endDate,
          }),
        );
      }
      // yield all(dataSyncCalls);
      let metaDataModel = metaDataDto({
        moduleName: MODULE_NAMES.DATA_SYNC_MODULE,
        lastSynced: moment(lastSynced).format(DATE_FORMATS.YYYY_MM_DD_HH_mm_ss),
      });
      MetaDataManager.saveMetaData(metaDataModel); // upsert datasync metadata with new lastSync time
      yield put(syncDataNonBlockingSuccess({ lastSynced: new Date() }));
    } else {
      yield put(
        syncDataNonBlockingFailure({
          error: localization[getLocale()].NO_INTERNET_CONNECTION,
          syncPending: true,
        }),
      );
    }
  } catch (e) {
    console.log(e);
    yield put(syncDataNonBlockingFailure());
  }
}

/**
 * executes calls like promise.all so in order to control
 *  refresh control loader on pull to refresh
 */
function* syncDataManuallyAsync() {
  let dataSyncMetaData = MetaDataManager.getMetaDataByModule(
    MODULE_NAMES.DATA_SYNC_MODULE, // get datasync metadata for last sync time
  );
  const userRole = getUserRole();

  if (dataSyncMetaData && dataSyncMetaData[0]) {
  }

  try {
    if (navigator.onLine) {
      let dataSyncCalls = [
        call(getAppTrainingVideoHtml, {
          payload: { update: true },
        }),
        call(getNavigationVideosAsync, {
          payload: { update: true },
        }),
        call(getBackdatedSubmissionsAsync, {
          payload: {},
        }),
        call(getActivitiesAsync, {
          payload: { update: true /*, loading: true*/ },
        }),
        // call(getProgramManualTagsAsync),
        call(getProgramManualsAsync, {
          payload: { update: true /*, loading: true*/ },
        }),
        call(getMobileNotificationAsync, {
          payload: { shouldSync: true },
        }),
        call(getFeedlotStatsAsync, { payload: {} }),
      ];

      // only enable updates & missed activities for non-auditor users
      if (
        userRole !== userRoles.INTERNAL_AUDITOR &&
        userRole !== userRoles.EXTERNAL_AUDITOR
      ) {
        dataSyncCalls.push(
          call(getMobileUpdateAsync, {
            payload: { shouldSync: true },
          }),
          call(getMissedActivitiesAsync, { payload: {} }),
        );
      }

      if (userRole != userRoles.WORKER) {
        // if (
        //   // userRole != userRoles.SUPERVISOR &&
        //   userRole != userRoles.TRAINER &&
        //   getSelectedFeedlotRankEnabled()
        // ) {
        //   dataSyncCalls.push(
        //     call(getFeedlotRankAsync, {
        //       payload: {},
        //     }),
        //   );
        // }
        dataSyncCalls.push(
          call(getAssignLogsUsersAsync, { payload: { update: true } }),
        );
        // dataSyncCalls.push(call(getUsersAsync, { payload: { update: true } }));
        dataSyncCalls.push(
          call(getWorkersAsync, {
            payload: { update: true /*, loading: true*/ },
          }),
        );
        dataSyncCalls.push(call(getAuditsAsync, { payload: { update: true } }));
      }

      yield race({
        parallel: all(dataSyncCalls),
        cancelled: take(ACTION_CONSTANTS.CANCEL_SYNC),
      });
      // yield all(dataSyncCalls);
      let performance = OptionsManager.getCustomPerformanceFilter();
      if (performance.startDate) {
        yield put(
          getFeedlotCustomPerformanceRequest({
            startDate: performance.startDate,
            endDate: performance.endDate,
          }),
        );
      }

      let metaDataModel = metaDataDto({
        moduleName: MODULE_NAMES.DATA_SYNC_MODULE,
        lastSynced: moment(new Date()).format(DATE_FORMATS.YYYY_MM_DD_HH_mm_ss),
      });
      MetaDataManager.saveMetaData(metaDataModel); // upsert datasync metadata with new lastSync time
      yield put(syncDataManuallySuccess({ lastSynced: new Date() }));
    } else {
      yield put(
        syncDataManuallyFailure({
          error: localization[getLocale()].NO_INTERNET_CONNECTION,
        }),
      );
    }
  } catch (e) {
    console.log(e);
    yield put(syncDataManuallyFailure({ error: e.message }));
  }
}

function* nonBlockingSyncMain() {
  yield call(syncDataNonBlocking);
}

function* syncActivitiesData({ payload }) {
  yield put(syncActivitySubmissionsRequest(payload.submission));

  let activitySyncCalls = [
    yield call(getActivitiesAsync, {
      payload: { update: true },
    }),
    yield call(getBackdatedSubmissionsAsync, {}),
  ];

  // let { backdated, submission, startDate, endDate } = payload;
  // if (
  //   backdated &&
  //   backdated === BACKDATED_SUBMISSIONS_FILTER.CUSTOM &&
  //   startDate &&
  //   endDate
  // ) {
  //   activitySyncCalls.push(
  //     yield call(getBackdatedCustomSubmissionsAsync, {
  //       payload: { startDate, endDate },
  //     }),
  //   );
  // }

  yield race({
    parallel: all(activitySyncCalls),
    cancelled: take(ACTION_CONSTANTS.CANCEL_SYNC),
  });
}

function* dataSycnSaga() {
  yield takeLatest(
    ACTION_CONSTANTS.SYNC_DATA_BLOCKING_REQUEST,
    syncDataBlocking,
  );
  yield takeLatest(
    ACTION_CONSTANTS.SYNC_DATA_NON_BLOCKING_REQUEST,
    nonBlockingSyncMain,
  );
  yield takeLatest(
    ACTION_CONSTANTS.SYNC_DATA_MANUALLY_REQUEST,
    syncDataManuallyAsync,
  );
  yield takeLatest(
    ACTION_CONSTANTS.SYNC_ACTIVITY_DATA_REQUEST,
    syncActivitiesData,
  );
}

export default dataSycnSaga;
