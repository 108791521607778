import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import './style.scss';

import ActivityTag from '../ActivityTag';
import PBIcon from '../PBIcon';
import ActivityPickerModal from '../ActivityPickerModal';

import { globalHelper } from '@progressivebeef/shared/src/helpers';
import ActivitiesHelper from '@progressivebeef/shared/src/helpers/ActivitiesHelper';

import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import localization from '@progressivebeef/shared/src/localization/i18n';

import { setCurrentActivity } from '@progressivebeef/shared/src/store/actions/activities';
import {
  userRoles,
  DATE_FORMATS,
} from '@progressivebeef/shared/src/constants/AppConstants';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';

class ActivitySOPCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
    };
  }

  onActivityCardPress = (currentActivity) => {
    const { user, setCurrentActivityRequest } = this.props;

    setCurrentActivityRequest(currentActivity);

    if (Number(user.userRoleId) === userRoles.WORKER) {
      if (currentActivity.instance_based) {
        this.props.history.push(RouteConstants.ACTIVITY_INSTANCE, {
          currentActivity,
        });
      } else {
        this.props.history.push(RouteConstants.ACTIVITY_SUBMISSIONS, {
          currentActivity,
        });
      }
    } else {
      this.setState({ modalVisible: true });
    }
  };

  onPickerPress = () => {
    this.setState({ modalVisible: false });
  };

  render() {
    var { item, history, setCurrentActivityRequest } = this.props;
    var { modalVisible } = this.state;
    const title = item.locale
      ? item.locale[getLocale() === 'en' ? 1 : 2].title
      : localization[getLocale()].TITLE;

    return (
      <>
        <ActivityPickerModal
          item={item}
          history={history}
          modalVisible={modalVisible}
          onPressEvent={this.onPickerPress}
          setCurrentActivity={setCurrentActivityRequest}
        />
        <div
          className="activity-submission-main-box"
          onClick={() => this.onActivityCardPress(item)}
        >
          <div>
            <div>
              <div className="activity-submission-log-box">
                <img alt="description" src={ActivitiesHelper.getTypeIcon(item)} />
                <div className="title">{item.activity_number}</div>
              </div>

              <div className="activity-submission-tile-box">{title}</div>
              <div className="activity-submission-list-of-detail">
                <div className="activity-submission-user-box">
                  <PBIcon name={'user'} />
                  <div className="no">{item.users ? item.users.length : 0}</div>
                </div>

                {globalHelper.renderIf(item.last_submitted_date)(
                  <div className="activity-submission-date-box">
                    <div className="icon">
                      <PBIcon name={'calendar-check'} />
                    </div>
                    <div className="date">
                      {moment(item.last_submitted_date).format(
                        DATE_FORMATS.MM_DD_YY
                      )}
                    </div>
                  </div>
                )}
                {item.reminder_date ? (
                  <div className="activity-submission-date-box stopwatch-edit-date">
                    <div className="icon">
                      <PBIcon name={'stopwatch'} />
                    </div>
                    <div className="date">
                      {moment(item.reminder_date).format(DATE_FORMATS.MM_DD_YY)}
                    </div>
                  </div>
                ) : (
                  globalHelper.renderIf(item.due_date)(
                    <div className="activity-submission-date-box stopwatch-edit-date">
                      <div className="icon">
                        <PBIcon name={'calendar-edit'} />
                      </div>
                      <div className="date">
                        {moment(item.due_date).format(DATE_FORMATS.MM_DD_YY)}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>

          {item.activity_tags && (
            <div>
              <ActivityTag history={history} tags={item.activity_tags} />
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state.authentication;

  return {
    user: user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentActivityRequest: (payload) =>
      dispatch(setCurrentActivity(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivitySOPCard);
