import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';
import AppContainer from '../../../components/AppContainer';
import PBIcon from '../../../components/PBIcon';
import ProgramManualCard from '../../../components/ProgramManualCard';

import { globalHelper } from '@progressivebeef/shared/src/helpers';

import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

import {
  getProgramManualRequest,
  searchProgramManuals,
  resetErrorProps,
  setCurrentProgramManual,
  resetSOPProps,
} from '@progressivebeef/shared/src/store/actions/programMaunals';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';
import {
  BOTTOM_BAR_TABS,
  userRoles,
} from '@progressivebeef/shared/src/constants/AppConstants';
import {
  showSuccessAlert,
  showErrorAlert,
  showOfflineAlert,
} from '../../../components/Alerts';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProgramManualPickerModal from '../../../components/ProgramManualPickerModal';
import { setBottomBarTab } from '@progressivebeef/shared/src/store/actions/view';

class ProgramManuals extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sort: false,
      query: '',
      modalVisible: false,
    };
  }

  componentDidMount() {
    this.props.setBottomBarTab(BOTTOM_BAR_TABS.SOP);
    this.props.getProgramManuals({ update: false });
  }

  componentWillUnmount() {
    this.props.resetSOPProps();
  }

  componentDidUpdate(prevProps) {
    const { isBinned, error } = this.props;
    if (isBinned) {
      showSuccessAlert(localization[getLocale()].BINNED_DOCUMENT, [
        {
          text: localization[getLocale()].OK,
          onPress: () => {
            this.props.getProgramManuals({ update: false, loading: true });
            this.props.resetErrorProps();
          },
        },
      ]);
    }

    if (error && error !== prevProps.error && Object.keys(error).length !== 0) {
      showErrorAlert(error && error.message, [
        {
          text: localization[getLocale()].OK,
          onPress: () => {
            this.props.resetErrorProps();
          },
        },
      ]);
    }
  }

  onRefresh = () => {
    if (navigator.onLine) {
      this.props.getProgramManuals({ update: true, loading: true });
    } else {
      showErrorAlert(localization[getLocale()].OFFLINE_MODE_REFRESH_ERROR);
    }
  };

  onPickerPress = () => {
    this.setState({ modalVisible: false });
  };

  onCardPress = (item) => {
    const { user, history, setCurrentProgramManual } = this.props;

    setCurrentProgramManual(item);

    if (item.document_type_key !== 5) {
      if (navigator.onLine) {
        history.push(RouteConstants.PROGRAM_MANUAL_DETAIL, {
          currentProgramManual: item,
        });
      } else {
        showOfflineAlert();
      }
    } else {
      if (
        Number(user.userRoleId) === userRoles.FEEDLOT_MANAGER ||
        Number(user.userRoleId) === userRoles.TRAINER ||
        Number(user.userRoleId) === userRoles.PROGRAM_MANAGER
      ) {
        this.setState({ modalVisible: true });
      } else {
        if (navigator.onLine) {
          history.push(RouteConstants.VIEW_DOCUMENT, {
            currentProgramManual: item,
          });
        } else {
          showOfflineAlert();
        }
      }
    }
  };

  render() {
    const { query, isLoading, programManuals, tags, history } = this.props;

    const { modalVisible } = this.state;

    return (
      <>
        <ProgramManualPickerModal
          modalVisible={modalVisible}
          onPressEvent={this.onPickerPress}
          history={history}
        />
        <AppContainer
          iconHeader
          history={history}
          searchText={query}
          title={localization[getLocale()].SOP}
          onSearchPress={this.searchProgramManuals}
          IconRightHeader={this.renderRightHeader}>
          {globalHelper.renderIf(isLoading)(
            <div className="bottom-infinite-loader">
              <Spinner color={'red'} />
            </div>,
          )}
          {programManuals && programManuals.length > 0 ? (
            <InfiniteScroll
              dataLength={programManuals.length}
              hasMore={false}
              refreshFunction={this.onRefresh}
              pullDownToRefresh
              pullDownToRefreshThreshold={50}
              pullDownToRefreshContent={
                <h3 style={{ textAlign: 'center' }}>
                  &#8595; Pull down to refresh
                </h3>
              }
              releaseToRefreshContent={
                <h3 style={{ textAlign: 'center' }}>
                  &#8593; Release to refresh
                </h3>
              }>
              {programManuals.map((item, index) => {
                return (
                  <ProgramManualCard
                    key={index}
                    tags={tags}
                    item={item}
                    history={history}
                    onCardPress={this.onCardPress}
                  />
                );
              })}
            </InfiniteScroll>
          ) : isLoading ? (
            <div className="bottom-infinite-loader">
              {globalHelper.renderIf(isLoading)(<Spinner color={'red'} />)}
            </div>
          ) : (
            !isLoading && (
              <div className="no-result-found-box">
                <p>{localization[getLocale()].NO_DOCUMENTS_FOUND}</p>
              </div>
            )
          )}
        </AppContainer>
      </>
    );
  }

  searchProgramManuals = (queryString) => {
    this.props.searchProgramManuals(queryString);
    this.props.getProgramManuals({ update: false });
  };

  renderRightHeader = () => {
    const { user } = this.props;

    return Number(user.userRoleId) === userRoles.FEEDLOT_MANAGER ||
      Number(user.userRoleId) === userRoles.TRAINER ||
      Number(user.userRoleId) === userRoles.PROGRAM_MANAGER ? (
      <div>
        <div className="head-icon right-align">
          <div className="hald-icon-box">
            <PBIcon
              onPress={() =>
                this.props.history.push(RouteConstants.ADD_DOCUMENT)
              }
              name={'plus'}
            />
          </div>

          <div className="hald-icon-box">
            <PBIcon
              name={'trash'}
              onPress={() =>
                this.props.history.push(RouteConstants.PROGRAM_MANUAL_BIN)
              }
            />
          </div>
        </div>
      </div>
    ) : (
      <div />
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetErrorProps: (payload) => dispatch(resetErrorProps(payload)),
    getProgramManuals: (payload) => dispatch(getProgramManualRequest(payload)),
    searchProgramManuals: (payload) => dispatch(searchProgramManuals(payload)),
    setCurrentProgramManual: (payload) =>
      dispatch(setCurrentProgramManual(payload)),
    resetSOPProps: (payload) => dispatch(resetSOPProps(payload)),
    setBottomBarTab: (payload) => dispatch(setBottomBarTab(payload)),
  };
};

const mapStateToProps = (state) => {
  const { query, isLoading, programManuals, tags, isBinned, error } =
    state.programManuals;
  const { user } = state.authentication;

  return {
    query,
    isBinned,
    error,
    user,
    isLoading,
    programManuals,
    tags,
    isBinned,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramManuals);
