import { request, success, failure } from './index';
import ACTION_CONSTANTS from '../../constants/actionConstants';

//#region getAudits
export function getAudits(payload) {
  return request(ACTION_CONSTANTS.GET_AUDITS, payload);
}

export function getAuditsRequested(payload) {
  return request(ACTION_CONSTANTS.GET_AUDITS_REQUEST, payload);
}

export function getAuditsSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_AUDITS_SUCCESS, payload);
}

export function getAuditsFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_AUDITS_FAILURE, payload);
}
//#endregion

//#region search Audits
export function searchAuditsRequested(payload) {
  return request(ACTION_CONSTANTS.SEARCH_AUDITS_REQUEST, payload);
}

export function searchAuditsSuccess(payload) {
  return success(ACTION_CONSTANTS.SEARCH_AUDITS_SUCCESS, payload);
}

export function searchAuditsFailure(payload) {
  return failure(ACTION_CONSTANTS.SEARCH_AUDITS_FAILURE, payload);
}
//#endregion

//#region set Audit
export function setCurrentAuditRequested(payload) {
  return request(ACTION_CONSTANTS.SET_CURRENT_AUDIT_REQUEST, payload);
}
//#endregion

//#region search Audit NCs
export function searchAuditNcsRequested(payload) {
  return request(ACTION_CONSTANTS.SEARCH_AUDIT_NCS_REQUEST, payload);
}

export function searchAuditNcsSuccess(payload) {
  return success(ACTION_CONSTANTS.SEARCH_AUDIT_NCS_SUCCESS, payload);
}

export function searchAuditNcsFailure(payload) {
  return failure(ACTION_CONSTANTS.SEARCH_AUDIT_NCS_FAILURE, payload);
}
//#endregion

//#region set audit status
export function setAuditStatusRequested(payload) {
  return request(ACTION_CONSTANTS.SET_AUDIT_STATUS_REQUEST, payload);
}

export function setAuditStatusSuccess(payload) {
  return success(ACTION_CONSTANTS.SET_AUDIT_STATUS_SUCCESS, payload);
}

export function setAuditStatusFailure(payload) {
  return failure(ACTION_CONSTANTS.SET_AUDIT_STATUS_FAILURE, payload);
}
//#endregion

//#region get audit summary pdf
export function getAuditSummaryPDFRequested(payload) {
  return request(ACTION_CONSTANTS.GET_AUDIT_REPORT_REQUEST, payload);
}

export function getAuditSummaryPDFSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_AUDIT_REPORT_SUCCESS, payload);
}

export function getAuditSummaryPDFFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_AUDIT_REPORT_FAILURE, payload);
}
//#endregion

//#region getQuestionComments
export function getQuestionComments(payload) {
  return request(ACTION_CONSTANTS.GET_QUESTION_COMMENTS, payload);
}

export function getQuestionCommentsRequested(payload) {
  return request(ACTION_CONSTANTS.GET_QUESTION_COMMENTS_REQUEST, payload);
}

export function getQuestionCommentsSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_QUESTION_COMMENTS_SUCCESS, payload);
}

export function getQuestionCommentsFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_QUESTION_COMMENTS_FAILURE, payload);
}
//#endregion

//#region sendComment
export function sendCommentRequested(payload) {
  return request(ACTION_CONSTANTS.SEND_COMMENT_REQUEST, payload);
}

export function sendCommentSuccess(payload) {
  return success(ACTION_CONSTANTS.SEND_COMMENT_SUCCESS, payload);
}

export function sendCommentFailure(payload) {
  return failure(ACTION_CONSTANTS.SEND_COMMENT_FAILURE, payload);
}
//#endregion

//#region getAuditById
export function getAuditByIdRequested(payload) {
  return request(ACTION_CONSTANTS.GET_AUDIT_BY_ID_REQUEST, payload);
}

export function getAuditByIdSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_AUDIT_BY_ID_SUCCESS, payload);
}

export function getAuditByIdFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_AUDIT_BY_ID_FAILURE, payload);
}
//#endregion
