import React, { Component } from 'react';

import ActivityTagList from '../ActivityTagList';
import './style.scss';

class ActivityTag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTagList: false,
    };
  }

  dismissModal = () => {
    this.setState({
      showTagList: false,
    });
  };

  render() {
    let { showTagList } = this.state;
    let { tags, full } = this.props;

    return (
      <>
        <ActivityTagList
          tags={tags}
          modalVisible={showTagList}
          dismissModal={this.dismissModal}
        />
        <div className="activity-tag-box">
          {tags.map((item, index) =>
            full ? (
              <div className="tag" key={index}>
                <div>{`${item.value ? item.value : ''}`}</div>
              </div>
            ) : index < 2 ? (
              item.value ? (
                <div className="tag" key={index}>
                  {`${item.value ? item.value : ''}`}
                </div>
              ) : null
            ) : index === 2 ? (
              <a>
                <div
                  className="more-tag"
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                    // history.push(RouteConstants.ACTIVITY_TAG_LIST, {
                    //   tags: tags,
                    // });
                    this.setState({
                      showTagList: true,
                    });
                  }}
                >
                  {'More tags…'}
                </div>
              </a>
            ) : null
          )}
        </div>
      </>
    );
  }
}

export default ActivityTag;
