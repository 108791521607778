export default {
  //#region ===== DIAGNOSTIC FILE ACTIONS =====
  SEND_DIAGNOSTIC_EMAIL_REQUEST: 'SEND_DIAGNOSTIC_EMAIL_REQUEST',
  SEND_DIAGNOSTIC_EMAIL_SUCCESS: 'SEND_DIAGNOSTIC_EMAIL_SUCCESS',
  SEND_DIAGNOSTIC_EMAIL_FAILURE: 'SEND_DIAGNOSTIC_EMAIL_FAILURE',
  //#endregion ===== DIAGNOSTIC FILE ACTIONS =====

  //#region ===== clear local storage ACTIONS =====
  CLEAR_LOCAL_STORAGE_REQUEST: 'CLEAR_LOCAL_STORAGE_REQUEST',
  CLEAR_LOCAL_STORAGE_SUCCESS: 'CLEAR_LOCAL_STORAGE_SUCCESS',
  CLEAR_LOCAL_STORAGE_FAILURE: 'CLEAR_LOCAL_STORAGE_FAILURE',
  //#endregion ===== DIAGNOSTIC FILE ACTIONS =====
  
};
