import { apiClient } from './index';
import { handleResponse, handleError } from './responseHandler';
import APIConstants from '../../constants/APIConstants';
import { getLocale, getSelectedFeedlotId } from '../storageService/GlobalData';

class ProgramManualService {
  getProgramManuals(lastSynced) {
    let url = APIConstants.GET_PROGRAM_MANUALS.replace(
      '{{id}}',
      getSelectedFeedlotId() || 1
    ).replace('{{languageKey}}', getLocale() === 'en' ? 1 : 2);
    if (lastSynced) {
      url = url.concat(`&syncCall=${true}&time=${lastSynced}`);
    }
    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  getProgramManualTags() {
    const url = APIConstants.GET_PROGRAM_MANUALS_TAGS;
    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  getProgramManualById(documentNumber) {
    const url = APIConstants.GET_PROGRAM_MANUAL_BY_ID.replace(
      '{{id}}',
      getSelectedFeedlotId() || 1,
    ).replace('{{document_number}}', documentNumber);
    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  createTraining(payload) {
    const url = APIConstants.CREATE_TRAINING;
    return apiClient()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  }

  updateTraining(payload) {
    const url = APIConstants.UPDATE_TRAINING;
    return apiClient()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  }

  createTrainingURL(payload) {
    const url = APIConstants.CREATE_TRAINING_URLS;
    return apiClient()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  }

  getDeprecatedProgramManuals() {
    const url = APIConstants.GET_DEPRECATED_PROGRAM_MANUALS.replace(
      '{{feedlotId}}',
      getSelectedFeedlotId() || 1
    );

    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  moveProgramManualToActive(documentNumber) {
    const url = APIConstants.MOVE_PROGRAM_MANUALS_TO_ACTIVE.replace(
      '{{feedlotId}}',
      getSelectedFeedlotId() || 1
    ).replace('{{documentNumber}}', documentNumber);

    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  moveProgramManualToBin(documentNumber) {
    const url = APIConstants.MOVE_PROGRAM_MANUALS_TO_BIN.replace(
      '{{feedlotId}}',
      getSelectedFeedlotId() || 1
    ).replace('{{documentNumber}}', documentNumber);

    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  emailBinnedReport(payload) {
    const url = APIConstants.EMAIL_BINNED_REPORT;

    return apiClient()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  }

  emailReport(payload) {
    const url = APIConstants.EMAIL_REPORT;

    return apiClient()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  }

  deleteProgramManualUrl(payload) {
    const url = APIConstants.DELETE_PROGRAM_MANUAL_URL;

    return apiClient()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  }

  updateProgramManualUrl(payload) {
    console.log(JSON.stringify(payload.feedlot_document.document_urls));
    const url = APIConstants.UPDATE_PROGRAM_MANUAL_URL;

    return apiClient()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  }

  markViewed(documentNumber) {
    const url = APIConstants.MARK_AS_VIEWED.replace(
      '{{feedlotId}}',
      getSelectedFeedlotId() || 1
    ).replace('{{documentNumber}}', documentNumber);

    return apiClient().get(url).then(handleResponse).catch(handleError);
  }
}

export default new ProgramManualService();
