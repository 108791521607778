export default {
  GET_ACTIVITIES_REQUEST: 'GET_ACTIVITIES_REQUEST',
  GET_ACTIVITIES: 'GET_ACTIVITIES',
  GET_ACTIVITIES_SUCCESS: 'GET_ACTIVITIES_SUCCESS',
  GET_ACTIVITIES_FAILURE: 'GET_ACTIVITIES_FAILURE',

  GET_BACKDATED_ACTIVITIES_REQUEST: 'GET_BACKDATED_ACTIVITIES_REQUEST',
  GET_BACKDATED_ACTIVITIES: 'GET_BACKDATED_ACTIVITIES',
  GET_BACKDATED_ACTIVITIES_SUCCESS: 'GET_BACKDATED_ACTIVITIES_SUCCESS',
  GET_BACKDATED_ACTIVITIES_FAILURE: 'GET_BACKDATED_ACTIVITIES_FAILURE',

  GET_ACTIVITY_BY_ID_REQUEST: 'GET_ACTIVITY_BY_ID_REQUEST',
  GET_ACTIVITY_BY_ID_SUCCESS: 'GET_ACTIVITY_BY_ID_SUCCESS',
  GET_ACTIVITY_BY_ID_FAILURE: 'GET_ACTIVITY_BY_ID_FAILURE',

  GET_SUBMISSION_BY_ID_REQUEST: 'GET_SUBMISSION_BY_ID_REQUEST',
  GET_SUBMISSION_BY_ID_SUCCESS: 'GET_SUBMISSION_BY_ID_SUCCESS',
  GET_SUBMISSION_BY_ID_FAILURE: 'GET_SUBMISSION_BY_ID_FAILURE',

  GET_ASSIGNED_ACTIVITIES_REQUEST: 'GET_ASSIGNED_ACTIVITIES_REQUEST',
  GET_ASSIGNED_ACTIVITIES_SUCCESS: 'GET_ASSIGNED_ACTIVITIES_SUCCESS',

  GET_UNASSIGNED_ACTIVITIES_REQUEST: 'GET_UNASSIGNED_ACTIVITIES_REQUEST',
  GET_UNASSIGNED_ACTIVITIES_SUCCESS: 'GET_UNASSIGNED_ACTIVITIES_SUCCESS',

  GET_STATIC_SORT_ACTIVITIES_SUCCESS: 'GET_STATIC_SORT_ACTIVITIES_SUCCESS',

  GET_SORTED_ACTIVITIES_REQUEST: 'GET_SORTED_ACTIVITIES_REQUEST',
  GET_SORTED_ACTIVITIES_SUCCESS: 'GET_SORTED_ACTIVITIES_SUCCESS',

  GET_SORTED_ASSIGNED_ACTIVITIES_SUCCESS:
    'GET_SORTED_ASSIGNED_ACTIVITIES_SUCCESS',
  GET_SORTED_UNASSIGNED_ACTIVITIES_SUCCESS:
    'GET_SORTED_UNASSIGNED_ACTIVITIES_SUCCESS',

  SET_CURRENT_ACTIVITY: 'SET_CURRENT_ACTIVITY',

  SEARCH_ACTIVITIES_REQUEST: 'SEARCH_ACTIVITIES_REQUEST',
  SEARCH_ACTIVITIES_SUCCESS: 'SEARCH_ACTIVITIES_SUCCESS',

  FILTER_ACTIVITIES: 'FILTER_ACTIVITIES',
  SEARCH_ACTIVITIES: 'SEARCH_ACTIVITIES',
  SORT_ACTIVITIES: 'SORT_ACTIVITIES',

  BACKDATED_FILTER_ACTIVITIES: 'BACKDATED_FILTER_ACTIVITIES',
  BACKDATED_SEARCH_ACTIVITIES: 'BACKDATED_SEARCH_ACTIVITIES',
  RESET_BACKDATED_PAYLOAD: 'RESET_BACKDATED_PAYLOAD',
  GET_SELECTED_BACKDATED_TAB: 'GET_SELECTED_BACKDATED_TAB',

  GET_ACTIVITIES_TAG_REQUEST: 'GET_ACTIVITIES_TAG_REQUEST',
  GET_ACTIVITIES_TAG: 'GET_ACTIVITIES_TAG',
  GET_ACTIVITIES_TAG_SUCCESS: 'GET_ACTIVITIES_TAG_SUCCESS',
  GET_ACTIVITIES_TAG_FAILURE: 'GET_ACTIVITIES_TAG_FAILURE',

  GET_ACTIVITY_INSTANCES_REQUEST: 'GET_ACTIVITY_INSTANCES_REQUEST',
  GET_ACTIVITY_INSTANCES_SUCCESS: 'GET_ACTIVITY_INSTANCES_SUCCESS',
  GET_ACTIVITY_INSTANCES_FAILURE: 'GET_ACTIVITY_INSTANCES_FAILURE',

  DELETE_ACTIVITY_INSTANCES_REQUEST: 'DELETE_ACTIVITY_INSTANCES_REQUEST',
  DELETE_ACTIVITY_INSTANCES_SUCCESS: 'DELETE_ACTIVITY_INSTANCES_SUCCESS',
  DELETE_ACTIVITY_INSTANCES_FAILURE: 'DELETE_ACTIVITY_INSTANCES_FAILURE',

  ADD_ACTIVITY_INSTANCES_REQUEST: 'ADD_ACTIVITY_INSTANCES_REQUEST',
  ADD_ACTIVITY_INSTANCES_SUCCESS: 'ADD_ACTIVITY_INSTANCES_SUCCESS',
  ADD_ACTIVITY_INSTANCES_FAILURE: 'ADD_ACTIVITY_INSTANCES_FAILURE',

  GET_DEPRECATED_ACTIVITIES_REQUEST: 'GET_DEPRECATED_ACTIVITIES_REQUEST',
  GET_DEPRECATED_ACTIVITIES: 'GET_DEPRECATED_ACTIVITIES',
  GET_DEPRECATED_ACTIVITIES_SUCCESS: 'GET_DEPRECATED_ACTIVITIES_SUCCESS',
  GET_DEPRECATED_ACTIVITIES_FAILURE: 'GET_DEPRECATED_ACTIVITIES_FAILURE',

  GET_ACTIVITY_SUBMISSIONS_REQUEST: 'GET_ACTIVITY_SUBMISSIONS_REQUEST',
  GET_ACTIVITY_SUBMISSIONS_SUCCESS: 'GET_ACTIVITY_SUBMISSIONS_SUCCESS',
  GET_ACTIVITY_SUBMISSIONS_FAILURE: 'GET_ACTIVITY_SUBMISSIONS_FAILURE',

  GET_BACKDATED_ACTIVITY_SUBMISSIONS_REQUEST:
    'GET_BACKDATED_ACTIVITY_SUBMISSIONS_REQUEST',
  GET_BACKDATED_ACTIVITY_SUBMISSIONS_SUCCESS:
    'GET_BACKDATED_ACTIVITY_SUBMISSIONS_SUCCESS',
  GET_BACKDATED_ACTIVITY_SUBMISSIONS_FAILURE:
    'GET_BACKDATED_ACTIVITY_SUBMISSIONS_FAILURE',

  ASSIGN_ACTIVITY_REQUEST: 'ASSIGN_ACTIVITY_REQUEST',
  ASSIGN_ACTIVITY_SUCCESS: 'ASSIGN_ACTIVITY_SUCCESS',
  ASSIGN_ACTIVITY_FAILURE: 'ASSIGN_ACTIVITY_FAILURE',

  SET_ACTIVITY_REMINDER_REQUEST: 'SET_ACTIVITY_REMINDER_REQUEST',
  SET_ACTIVITY_REMINDER_SUCCESS: 'SET_ACTIVITY_REMINDER_SUCCESS',
  SET_ACTIVITY_REMINDER_FAILURE: 'SET_ACTIVITY_REMINDER_FAILURE',

  DELETE_ACTIVITY_REMINDER_REQUEST: 'DELETE_ACTIVITY_REMINDER_REQUEST',
  DELETE_ACTIVITY_REMINDER_SUCCESS: 'DELETE_ACTIVITY_REMINDER_SUCCESS',
  DELETE_ACTIVITY_REMINDER_FAILURE: 'DELETE_ACTIVITY_REMINDER_FAILURE',

  SET_ACTIVITY_SCHEDULE_REQUEST: 'SET_ACTIVITY_SCHEDULE_REQUEST',
  SET_ACTIVITY_SCHEDULE_SUCCESS: 'SET_ACTIVITY_SCHEDULE_SUCCESS',
  SET_ACTIVITY_SCHEDULE_FAILURE: 'SET_ACTIVITY_SCHEDULE_FAILURE',

  POST_ACTIVITY_PRINT_REQUEST: 'POST_ACTIVITY_PRINT_REQUEST',
  POST_ACTIVITY_PRINT_SUCCESS: 'POST_ACTIVITY_PRINT_SUCCESS',
  POST_ACTIVITY_PRINT_FAILURE: 'POST_ACTIVITY_PRINT_FAILURE',

  POST_FORM_ACTIVITY_PRINT_REQUEST: 'POST_FORM_ACTIVITY_PRINT_REQUEST',
  POST_FORM_ACTIVITY_PRINT_SUCCESS: 'POST_FORM_ACTIVITY_PRINT_SUCCESS',
  POST_FORM_ACTIVITY_PRINT_FAILURE: 'POST_FORM_ACTIVITY_PRINT_FAILURE',

  POST_ACTIVITY_SUBMISSION_REQUEST: 'POST_ACTIVITY_SUBMISSION_REQUEST',
  POST_ACTIVITY_SUBMISSION_SUCCESS: 'POST_ACTIVITY_SUBMISSION_SUCCESS',
  POST_ACTIVITY_SUBMISSION_FAILURE: 'POST_ACTIVITY_SUBMISSION_FAILURE',

  POST_ACTIVITY_ATTACHMENT_REQUEST: 'POST_ACTIVITY_ATTACHMENT_REQUEST',
  POST_ACTIVITY_ATTACHMENT_SUCCESS: 'POST_ACTIVITY_ATTACHMENT_SUCCESS',
  POST_ACTIVITY_ATTACHMENT_FAILURE: 'POST_ACTIVITY_ATTACHMENT_FAILURE',

  REMOVE_ACTIVITY_SUBMISSION_REQUEST: 'REMOVE_ACTIVITY_SUBMISSION_REQUEST',
  REMOVE_ACTIVITY_SUBMISSION_SUCCESS: 'REMOVE_ACTIVITY_SUBMISSION_SUCCESS',
  REMOVE_ACTIVITY_SUBMISSION_FAILURE: 'REMOVE_ACTIVITY_SUBMISSION_FAILURE',

  RESET_ACTIVITIES_PAYLOAD: 'RESET_ACTIVITIES_PAYLOAD',
  RESET_PERFORMANCE_PAYLOAD: 'RESET_PERFORMANCE_PAYLOAD',
  GET_SELECTED_PERFORMANCE_TAB: 'GET_SELECTED_PERFORMANCE_TAB',

  GET_PERFORMANCE_ACTIVITIES_REQUEST: 'GET_PERFORMANCE_ACTIVITIES_REQUEST',
  GET_PERFORMANCE_ACTIVITIES_SUCCESS: 'GET_PERFORMANCE_ACTIVITIES_SUCCESS',
  GET_PERFORMANCE_ACTIVITIES_FAILURE: 'GET_PERFORMANCE_ACTIVITIES_FAILURE',

  SEARCH_PERFORMANCE_ACTIVITIES: 'SEARCH_PERFORMANCE_ACTIVITIES',
  FILTER_PERFORMANCE_ACTIVITIES: 'FILTER_PERFORMANCE_ACTIVITIES',

  SYNC_ACTIVITY_SUBMISSIONS_REQUEST: 'SYNC_ACTIVITY_SUBMISSIONS_REQUEST',
  SYNC_ACTIVITY_SUBMISSIONS_SUCCESS: 'SYNC_ACTIVITY_SUBMISSIONS_SUCCESS',
  SYNC_ACTIVITY_SUBMISSIONS_FAILURE: 'SYNC_ACTIVITY_SUBMISSIONS_FAILURE',

  POST_PENDING_ACTIVITY_SUBMISSIONS_REQUEST:
    'POST_PENDING_ACTIVITY_SUBMISSIONS_REQUEST',
  POST_PENDING_ACTIVITY_SUBMISSIONS_SUCCESS:
    'POST_PENDING_ACTIVITY_SUBMISSIONS_SUCCESS',
  POST_PENDING_ACTIVITY_SUBMISSIONS_FAILURE:
    'POST_PENDING_ACTIVITY_SUBMISSIONS_FAILURE',

  POST_ACTIVITY_SUBMISSION_ON_SERVER_REQUEST:
    'POST_ACTIVITY_SUBMISSION_ON_SERVER_REQUEST',
  POST_ACTIVITY_SUBMISSION_ON_SERVER_SUCCESS:
    'POST_ACTIVITY_SUBMISSION_ON_SERVER_SUCCESS',
  POST_ACTIVITY_SUBMISSION_ON_SERVER_REMOVED:
    'POST_ACTIVITY_SUBMISSION_ON_SERVER_REMOVED',
  POST_ACTIVITY_SUBMISSION_ON_SERVER_FAILURE:
    'POST_ACTIVITY_SUBMISSION_ON_SERVER_FAILURE',

  POST_REMOVE_ACTIVITY_SUBMISSION_ON_SERVER_REQUEST:
    'POST_REMOVE_ACTIVITY_SUBMISSION_ON_SERVER_REQUEST',
  POST_REMOVE_ACTIVITY_SUBMISSION_ON_SERVER_SUCCESS:
    'POST_REMOVE_ACTIVITY_SUBMISSION_ON_SERVER_SUCCESS',
  POST_REMOVE_ACTIVITY_SUBMISSION_ON_SERVER_FAILURE:
    'POST_REMOVE_ACTIVITY_SUBMISSION_ON_SERVER_FAILURE',

  GET_ACTIVITY_REVISION_REQUEST: 'GET_ACTIVITY_REVISION_REQUEST',
  GET_ACTIVITY_REVISION_SUCCESS: 'GET_ACTIVITY_REVISION_SUCCESS',
  GET_ACTIVITY_REVISION_FAILURE: 'GET_ACTIVITY_REVISION_FAILURE',

  RESET_SUBMISSION_PROPS: 'RESET_SUBMISSION_PROPS',

  GET_ATTACHMENT_SUBMISSION_FILE_PATH_REQUEST:
    'GET_ATTACHMENT_SUBMISSION_FILE_PATH_REQUEST',
  GET_ATTACHMENT_SUBMISSION_FILE_PATH_SUCCESS:
    'GET_ATTACHMENT_SUBMISSION_FILE_PATH_SUCCESS',
  GET_ATTACHMENT_SUBMISSION_FILE_PATH_FAILURE:
    'GET_ATTACHMENT_SUBMISSION_FILE_PATH_FAILURE',

  RESET_SUBMISSIONS_REQUEST: 'RESET_SUBMISSIONS_REQUEST',
  RESET_SUBMISSIONS_SUCCESS: 'RESET_SUBMISSIONS_SUCCESS',
  RESET_SUBMISSIONS_FAILURE: 'RESET_SUBMISSIONS_FAILURE',
};
