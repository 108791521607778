// modules
import { put, takeLatest, call } from 'redux-saga/effects';

import ACTION_CONSTANTS from '../constants/actionConstants';
import {
  clearLocalStorageSuccess,
  clearLocalStorageFailure,
} from '../store/actions/settings';
import SettingsManager from '../database/reactDataManager/SettingsManager';

export const authenticationStoreRequest = (state) => state.authentication;

function* clearLocalStorage({ payload }) {
  try {
    yield call(SettingsManager.clearLocalStorage, payload);
    yield put(clearLocalStorageSuccess());
  } catch (e) {
    console.log('clearLocalStorageFailure', e);
    yield put(clearLocalStorageFailure({ error: e.message }));
  }
}

function* settingsSaga() {
  yield takeLatest(
    ACTION_CONSTANTS.CLEAR_LOCAL_STORAGE_REQUEST,
    clearLocalStorage
  );
}

export default settingsSaga;
