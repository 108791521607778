// modules
import React, { useState } from 'react';
import { Formik } from 'formik';
import { Button, Input } from 'reactstrap';
import './style.scss';

//localization
import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

//constants
import AssetConstants from '@progressivebeef/shared/src/constants/AssetConstants';
import { CommentInitialValues } from '@progressivebeef/shared/src/constants/FormInitialValues';
import FilePicker from '../FilePicker';
import { ATTACHMENT_TYPES } from '@progressivebeef/shared/src/constants/FormConstants';
import Preview from '../Preview';
import AttachmentPreview from '../AttachmentPreview';
import { showAlertMsg, showOfflineAlert } from '../Alerts';
import { useEffect } from 'react';

const CommentBox = (props) => {
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);

  // preview state
  const [showPreview, setShowPreview] = useState(false);
  const [type, setType] = useState(ATTACHMENT_TYPES.PDF);
  const [source, setSource] = useState({});

  // TODO: reverse prop setting, this isnt good practice
  // should refactor files and images state to parent component (NCDetail)
  useEffect(
    (_) => {
      props.setIsAttachmentAdded(images.length !== 0 || files.length !== 0);
    },
    [images, files],
  );

  const openPreview = (source, type) => {
    setSource(source);
    setType(type);
    setShowPreview(true);
  };

  const addImage = (image) => setImages((oldImages) => [...oldImages, image]);
  const addFile = (file) => setFiles((oldFiles) => [...oldFiles, file]);

  const removeImage = (index) =>
    setImages((oldImages) => oldImages.filter((_, i) => i !== index));
  const removeFile = (index) =>
    setFiles((oldFiles) => oldFiles.filter((_, i) => i !== index));

  return (
    <>
      {showPreview && (
        <Preview
          modalVisible={showPreview}
          rightHeader
          title={
            type === ATTACHMENT_TYPES.PDF
              ? localization[getLocale()].PDF_PREVIEW
              : localization[getLocale()].IMAGE_PREVIEW
          }
          pdfSource={type === ATTACHMENT_TYPES.PDF ? source : null}
          imageSource={type !== ATTACHMENT_TYPES.PDF ? source : null}
          onRightIconPress={() => setShowPreview(false)}
          type={type}
          isBase64
        />
      )}
      <div className="main-comment-box">
        <Formik
          onSubmit={(values, formikActions) => {
            if (navigator.onLine) {
              props.onSubmit(values, formikActions, images, files);
              setImages([]);
              setFiles([]);
            } else showOfflineAlert();
          }}
          initialValues={CommentInitialValues}>
          {(props) => {
            const {
              values,
              errors,
              isSubmitted,
              handleSubmit,
              handleChange,
              handleBlur,
            } = props;
            return (
              <>
                <div className="field-box">
                  <textarea
                    value={values.comment}
                    placeholder={localization[getLocale()].COMMENT_PLACEHOLDER}
                    onChange={handleChange('comment')}
                    onBlur={handleBlur('comment')}
                    style={{
                      border: 0,
                      borderRadius: 0,
                      padding: 0,
                      fontSize: 14,
                      height: 'auto',
                      outline: 'none',
                      boxShadow: 'none',
                    }}
                  />
                  <div className="attachments-wrapper">
                    {images.map((image, i) => (
                      <AttachmentPreview
                        key={i}
                        source={`data:image/jpeg;base64,${image.base64}`}
                        onClick={() => {
                          openPreview(image.base64, ATTACHMENT_TYPES.PNG);
                        }}
                        onClosePress={(e) => {
                          e.stopPropagation();
                          showAlertMsg(
                            localization[getLocale()].ALERT,
                            localization[getLocale()]
                              .WANT_TO_DELETE_THIS_ATTACHMENT,
                            [
                              {
                                text: localization[getLocale()].NO,
                                onPress: () => {},
                              },
                              {
                                text: localization[getLocale()].YES,
                                onPress: () => {
                                  removeImage(i);
                                },
                              },
                            ],
                          );
                        }}
                      />
                    ))}
                    {files.map((file, i) => (
                      <AttachmentPreview
                        isPDF
                        key={i + images.length}
                        fileName={file.name}
                        onClick={() => {
                          openPreview(file.base64, ATTACHMENT_TYPES.PDF);
                        }}
                        onClosePress={(e) => {
                          e.stopPropagation();
                          showAlertMsg(
                            localization[getLocale()].ALERT,
                            localization[getLocale()]
                              .WANT_TO_DELETE_THIS_ATTACHMENT,
                            [
                              {
                                text: localization[getLocale()].NO,
                                onPress: () => {},
                              },
                              {
                                text: localization[getLocale()].YES,
                                onPress: () => {
                                  removeFile(i);
                                },
                              },
                            ],
                          );
                        }}
                      />
                    ))}
                  </div>
                </div>
                <div className="button-box">
                  <Button
                    disabled={
                      values.comment == '' &&
                      images.length == 0 &&
                      files.length == 0
                    }
                    transparent
                    onClick={handleSubmit}>
                    <div className="icon">
                      <img src={AssetConstants.sendComment} />
                    </div>
                    <div className="txt">
                      {localization[getLocale()].SUBMIT}
                    </div>
                  </Button>
                  <FilePicker
                    addImage={addImage}
                    addFile={addFile}
                    attachmentsCount={images.length + files.length}
                  />
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default CommentBox;
