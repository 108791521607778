export const SERVER_STATUS_CODES = {
  _00001: '_00001',
  _00002: '_00002',
  _00003: '_00003',
  _00004: '_00004',
  _00005: '_00005',
  _00006: '_00006',
  _00007: '_00007',
  _00008: '_00008',
  _00009: '_00009',
  _00010: '_00010',
  _00011: '_00011',
  _00012: '_00012',
  _00013: '_00013',
  _00014: '_00014',
  _00015: '_00015',
  _00016: '_00016',
  _00017: '_00017',
  _00018: '_00018',
  _00019: '_00019',
  _00020: '_00020',
  _00021: '_00021',
  _00022: '_00022',
  _00023: '_00023',
  _00024: '_00024',
  _00025: '_00025',
  _00026: '_00026',
  _00027: '_00027',
  _00028: '_00028',
  _00030: '_00030',
  _00031: '_00031',
  _00032: '_00032',
  _00033: '_00033',
  _00034: '_00034',
  _00035: '_00035',
  _00036: '_00036',
  _00037: '_00037',
  _00038: '_00038',
  _00039: '_00039',
  _00040: '_00040',
  _00041: '_00041',
  _00042: '_00042',
  _00043: '_00043',
  _00044: '_00044',
  _00045: '_00045',
  _00046: '_00046',
  _00047: '_00047',
  _00048: '_00048',
  _00049: '_00049',
  _00050: '_00050',
  _00051: '_00051',
  _00052: '_00052',
  _00053: '_00053',
  _00054: '_00054',
  _00055: '_00055',
  _00056: '_00056',
  _00057: '_00057',
  _00058: '_00058',
  _00059: '_00059',
  _00060: '_00060',
  _00061: '_00061',
  _00063: '_00063',
  _00071: '_00071',
  _00072: '_00072',
  _00073: '_00073',
  _00074: '_00074',
  _00075: '_00075',
  _00076: '_00076',
  _00077: '_00077',
  _00078: '_00078',
  _00079: '_00079',
  _00080: '_00080',
  _00081: '_00081',
  _00082: '_00082',
  _00083: '_00083',
  _00088: '_00088',
  _00089: '_00089',
  _00090: '_00090',
  _00091: '_00091',
  _00092: '_00092',
  _00093: '_00093',
  _00094: '_00094',
  _00095: '_00095',
  _00096: '_00096',
  _00097: '_00097',
  _00098: '_00098',
  _00100: '_00100',
  _00101: '_00101',
  _00102: '_00102',
  _00110: '_00110',
  _00111: '_00111',
  _00112: '_00112',
  _00113: '_00113',
  _00120: '_00120',
  _00121: '_00121',
  _00122: '_00122',
  _00123: '_00123',
  _00124: '_00124',
  _00125: '_00125',
  _00150: '_00150',
  _00151: '_00151',
  _00152: '_00152',
  _00153: '_00153',
  _00154: '_00154',
  _00155: '_00155',
  _00156: '_00156',
  _00157: '_00157',
  _00158: '_00158',
  _00159: '_00159',
  _00160: '_00160',
  _00161: '_00161',
  _00162: '_00162',
  _00163: '_00163',
  _00164: '_00164',
  _00165: '_00165',
  _00200: '_00200',
  _00065: '_00065',
  _00066: '_00066',
  _00222: '_00222',
  _00062: '_00062',
  _00069: '_00069',
  _00667: '_00667',
  _00668: '_00668',
  _00669: '_00669',
  _00670: '_00670',
};
