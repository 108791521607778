import React, { Component } from 'react';
import Label from './label';
import localization from '@progressivebeef/shared/src/localization/i18n';
import getLanguageKey from '@progressivebeef/shared/src/services/languageService/index';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import { Input } from 'reactstrap';
import { KEYBOARD_TYPES } from '@progressivebeef/shared/src/constants/AppConstants';

export default class TextAreaField extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {
      data,
      field,
      disabled,
      violated,
      form: { setFieldValue },
    } = this.props;
    const locale = data.label.locale[getLanguageKey()];
    return (
      <div className={`add-submission-view-info-box${violated ? ' violated' : ''}`}>
        {
          // style={[
          //   keyboardType ? styles.cardView : styles.cardViewInput,
          //   violated && styles.violatedCard,
          // ]}>
        }
        <Label fullWidth={true} locale={locale} violated={violated} />
        <Input
          icon
          disabled={disabled}
          onChange={(e) => {
            setFieldValue(data.group_id.toString(), e.target.value);
          }}
          type="textarea"
          value={field.value}
          violated={violated}
          placeholder={localization[getLocale()].ENTER_TEXT}
        />
      </div>
    );
  }
}
