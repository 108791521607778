import axios from 'axios';
import { getToken } from '../storageService/GlobalData';

let Config = null;

if (process.env.REACT_APP_PLATFORM !== 'WEB') {
  import('react-native-config').then((conf) => {
    Config = conf;
  });
}

export const getConnection = async () => {
  if (process.env.REACT_APP_PLATFORM !== 'WEB') {
    // waiting for the dynamic import of Config to resolve
    while (Config === null) {
      await new Promise((resolve) => {
        setTimeout(resolve, 10);
      });
    }
  }
};

export const baseUrl =
  process.env.REACT_APP_PLATFORM !== 'WEB'
    ? Config.API_URL
    : process.env.REACT_APP_API_URL;

export const apiClient = (token) => {
  const apiUrl =
    process.env.REACT_APP_PLATFORM !== 'WEB'
      ? Config.API_URL
      : process.env.REACT_APP_API_URL;
  const defaultOptions = {
    timeout: 180000,
    headers: {
      token: token ? token : getToken(),
      'Content-Type': 'application/json',
      'User-Device': 'mobile',
    },
  };

  return {
    getBaseUrl: () => {
      return `${apiUrl}`;
    },
    get: (url, options = {}) => {
      if (!isCompleteUrl(url)) {
        url = `${apiUrl}${url}`;
      }
      // console.log(
      //   'REQUESTING GET API CALL : \nURL : ',
      //   `${url}`,
      //   'OPTIONS : ',
      //   options,
      // );
      return axios.get(`${url}`, { ...defaultOptions, ...options });
    },
    post: (url, data, options = {}) => {
      if (!isCompleteUrl(url)) {
        url = `${apiUrl}${url}`;
      }
      // console.log(
      //   'REQUESTING POST API CALL : \nURL : ',
      //   `${url}`,
      //   'DATA : ',
      //   data,
      //   'OPTIONS : ',
      //   options,
      // );
      return axios.post(`${url}`, data, { ...defaultOptions, ...options });
    },
    put: (url, data, options = {}) => {
      if (!isCompleteUrl(url)) {
        url = `${apiUrl}${url}`;
      }
      // console.log(
      //   'REQUESTING PUT API CALL : \nURL : ',
      //   `${url}`,
      //   'DATA : ',
      //   data,
      //   'OPTIONS : ',
      //   options,
      // );
      return axios.put(`${url}`, data, { ...defaultOptions, ...options });
    },
    delete: (url, options = {}) => {
      if (!isCompleteUrl(url)) {
        url = `${apiUrl}${url}`;
      }
      // console.log(
      //   'REQUESTING DELETE API CALL : \nURL : ',
      //   `${url}`,
      //   'OPTIONS : ',
      //   options,
      // );
      return axios.delete(`${url}`, { ...defaultOptions, ...options });
    },
  };
};

function isCompleteUrl(url = '') {
  let val = url.indexOf('https://') > -1 || url.indexOf('http://') > -1;
  return val;
}
