import ACTION_CONSTANTS from '../../constants/actionConstants';

export const initialState = {
  auditSyncing: false,
  isLoading: false,
  error: false,
  success: false,
  searchText: '',
  tabType: 0,
  audits: [],
  audit: null,
  auditAssignmentId: null,
  statusToUpdate: null,
  ncSearchText: '',
  auditNCs: [],
  non_conformance: null,
  questionComments: [],
  questionCommentsLoading: false,
  sendCommentError: false,
  sectionId: null,
  auditSectionQuestionId: null,
  auditExist: null,
  oldAuditNotificationTapError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    //#region GET_AUDITS_REQUEST
    case ACTION_CONSTANTS.GET_AUDITS: {
      const update = action.payload.update;
      const obj = {
        ...state,
        success: false,
        error: false,
        auditSyncing: update,
      };
      // update loader only for api call
      if (update) {
        obj.isLoading = true;
      }
      return obj;
    }

    case ACTION_CONSTANTS.GET_AUDITS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        auditSyncing: false,
      };
    }
    case ACTION_CONSTANTS.GET_AUDITS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        auditSyncing: false,
      };
    }
    //#endregion

    //#region SEARCH_AUDITS_REQUEST
    case ACTION_CONSTANTS.SEARCH_AUDITS_REQUEST:
      return {
        ...state,
        success: false,
        error: false,
        searchText: action.payload.searchText,
        tabType: action.payload.tabType,
      };

    case ACTION_CONSTANTS.SEARCH_AUDITS_SUCCESS:
      return {
        ...state,
        isLoading: state.auditSyncing,
        audits: action.payload.audits,
      };

    case ACTION_CONSTANTS.SEARCH_AUDITS_FAILURE:
      return { ...state, isLoading: false, error: action.payload.error };
    //#endregion

    //#region SET_CURRENT_AUDIT_REQUEST
    case ACTION_CONSTANTS.SET_CURRENT_AUDIT_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
        audit: action.payload,
        auditExist: null,
        oldAuditNotificationTapError: null,
      };
    //#endregion

    //#region SEARCH_AUDIT_NCS_REQUEST
    case ACTION_CONSTANTS.SEARCH_AUDIT_NCS_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
        ncSearchText: action.payload.ncSearchText,
        audit: action.payload.audit,
      };

    case ACTION_CONSTANTS.SEARCH_AUDIT_NCS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        auditNCs: action.payload.auditNCs,
      };

    case ACTION_CONSTANTS.SEARCH_AUDIT_NCS_FAILURE:
      return { ...state, isLoading: false, error: action.payload.error };
    //#endregion

    //#region SET_AUDIT_STATUS_REQUEST
    case ACTION_CONSTANTS.SET_AUDIT_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
        statusToUpdate: action.payload.statusToUpdate,
        auditAssignmentId: action.payload.auditAssignmentId,
      };

    case ACTION_CONSTANTS.SET_AUDIT_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        audit: action.payload.audit,
        success: action.payload.success,
      };

    case ACTION_CONSTANTS.SET_AUDIT_STATUS_FAILURE:
      return { ...state, isLoading: false, error: action.payload.error };
    //#endregion

    //#region GET_AUDIT_REPORT_REQUEST
    case ACTION_CONSTANTS.GET_AUDIT_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case ACTION_CONSTANTS.GET_AUDIT_REPORT_SUCCESS:
      return {
        ...state,
        auditSummaryPDFPath: action.auditSummaryPDFPath,
        isLoading: false,
      };

    case ACTION_CONSTANTS.GET_AUDIT_REPORT_FAILURE:
      return { ...state, isLoading: false, error: action.payload.error };
    //#endregion

    //#region GET_QUESTION_COMMENTS_REQUEST
    case ACTION_CONSTANTS.GET_QUESTION_COMMENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
        questionCommentsLoading: true,
        auditAssignmentId: action.payload.audit_assignment_id,
        non_conformance: action.payload.non_conformance,
        sectionId: action.payload.non_conformance.audit_section_id,
        auditSectionQuestionId:
          action.payload.non_conformance.audit_section_question_id,
      };

    case ACTION_CONSTANTS.GET_QUESTION_COMMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        questionCommentsLoading: false,
        questionComments: action.payload.questionComments,
      };

    case ACTION_CONSTANTS.GET_QUESTION_COMMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        questionCommentsLoading: false,
        error: action.payload.error,
      };
    //#endregion

    //#region SEND_COMMENT_REQUEST
    case ACTION_CONSTANTS.SEND_COMMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
        sendCommentError: false,
      };

    case ACTION_CONSTANTS.SEND_COMMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        questionComments: action.payload,
      };

    case ACTION_CONSTANTS.SEND_COMMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        sendCommentError: action.payload.error,
      };
    //#endregion

    //#region GET_AUDIT_BY_ID_REQUEST
    case ACTION_CONSTANTS.GET_AUDIT_BY_ID_REQUEST: {
      return {
        ...state,
        auditExist: null,
        oldAuditNotificationTapError: null,
      };
    }

    case ACTION_CONSTANTS.GET_AUDIT_BY_ID_SUCCESS: {
      return {
        ...state,
        auditExist: action.payload,
      };
    }
    case ACTION_CONSTANTS.GET_AUDIT_BY_ID_FAILURE: {
      return {
        ...state,
        auditExist: null,
        oldAuditNotificationTapError: action.payload.error,
      };
    }
    //#endregion

    default:
      return state;
  }
};
