import { combineReducers } from 'redux';

import authentication from './authentication';
import activities, { initialState as activityInitialState } from './activities';
import audits, { initialState as auditInitialState } from './audits';
import programManuals, {
  initialState as programManualsInitialState,
} from './programManuals';
import navigationVideos, {
  initialState as navigationVideosInitialState,
} from './navigationVideos';
import user, { initialState as userInitialState } from './user';
import view from './view';
import dashboard, { initialState as dasboardInitialState } from './dasboard';
import dataSync, { initialState as dataSyncInitialState } from './dataSync';
import settings, { initialState as settingsInitialState } from './settings';
import cards, { initialState as cardsInitialState } from './cards';
import appTrainingVideoHTML, {
  initialState as appTrainingVideoHTMLInitialState,
} from './appTrainingVideoHTML';
import ACTION_CONSTANTS from '../../constants/actionConstants';

const appReducer = combineReducers({
  activities,
  audits,
  authentication,
  user,
  view,
  dashboard,
  programManuals,
  navigationVideos,
  dataSync,
  settings,
  cards,
  appTrainingVideoHTML,
});

const rootReducer = (state, action) => {
  if (action.type === ACTION_CONSTANTS.LOGOUT_SUCCESS) {
    state = undefined;
  } else if (action.type === ACTION_CONSTANTS.SELECT_FEEDYARD_REQUESTED) {
    //clear states of all reducers from single function
    state.activities = activityInitialState;
    state.audits = auditInitialState;
    state.user = userInitialState;
    state.dashboard = dasboardInitialState;
    state.programManuals = programManualsInitialState;
    state.navigationVideos = navigationVideosInitialState;
    state.dataSync = dataSyncInitialState;
    state.settings = settingsInitialState;
    cards.settings = cardsInitialState;
    state.appTrainingVideoHTML = appTrainingVideoHTMLInitialState;
  }
  return appReducer(state, action);
};

export default rootReducer;
