import {
  ACTIVITY_FREQUENCY,
  ACTIVITY_TYPES,
  SUBMISSION_STATUS,
  DATE_FORMATS,
  ACTIVITY_TYPE,
  userRoles,
  base64Regex,
  PERFORMANCE_DATA_KEYS_MAP,
  ACTIVITY_PERMISSIONS,
  SHOW_INSTRUCTION,
  ACTIVITY_FREQUENCY_TYPES,
  SHOW_TWELVE_MONTH_ACTIVITY_TIME_DAYS,
} from '../constants/AppConstants';
import _ from 'lodash';
import moment from 'moment';

import { getLocale } from '../services/storageService/GlobalData';
import getLanguageKey from '../services/languageService/index';
import { SUBMISSION_FIELD_TYPES } from '../constants/FormConstants';
import localization from '../localization/i18n';
import { globalHelper } from './index';
import activityModel from '../models/activityModel';
import feedlotPerformance from '../models/feedlotPerformanceModel';
import AssetConstants from '../constants/AssetConstants';
import axios from 'axios';

let Platform = null;
let PLATFORM = null;
if (process.env.REACT_APP_PLATFORM !== 'WEB') {
  import('react-native').then(({ Platform }) => {
    Platform = Platform;
  });
  import('../constants/theme/variables/commonFont').then(({ PLATFORM }) => {
    PLATFORM = PLATFORM;
  });
}

const PERFORMANCE_SECTION_KEYS = {
  MISSED: 'missed',
  COMPLETED: 'completed',
  PARTIALLY: 'partially',
  PREDICTED: 'predicted',
};

class ActivitiesHelper {
  processGroupDate(date) {
    var groupingDateTimeArray = date.split(' ');
    var groupingDateArray = groupingDateTimeArray[0].split('-');
    return new Date(
      groupingDateArray[0],
      groupingDateArray[1] - 1,
      groupingDateArray[2],
    );
  }

  separateActivities(activities) {
    activities.map((item, index) => {
      var group = '';

      // twelve month activity: if waiting_for_submission = true,
      // then show in waiting for submission, otherwise unscheduled

      if (
        item.schedule.frequency_calendar_key !==
        ACTIVITY_FREQUENCY_TYPES.TWELVE_MONTH
      )
        item.groupDate =
          item.frequency_key === ACTIVITY_FREQUENCY.FEEDLOT_SCHEDULED &&
          item.reminder_date
            ? this.processGroupDate(item.reminder_date)
            : item.due_date
            ? this.processGroupDate(item.due_date)
            : null;

      if (item.groupDate) {
        var currentDate = new Date(),
          currentDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate(),
          );

        var firstDateOfCurrentMonth = moment(currentDate).startOf('month'),
          lastDateOfCurrentMonth = moment(currentDate).endOf('month'),
          currentMonth = currentDate.getMonth(),
          dueDateMonth = item.groupDate.getMonth(),
          currentYear = currentDate.getFullYear(),
          dueDateYear = item.groupDate.getFullYear(),
          currentQuarter = Math.floor(currentMonth / 3 + 1),
          dueDateQuarter = Math.floor(dueDateMonth / 3 + 1);

        var numberOfDaysLeft = moment(item.groupDate).diff(currentDate, 'days');

        if (numberOfDaysLeft === 0) {
          group = ACTIVITY_TYPES.DAILY;
        } else if (numberOfDaysLeft < 0) {
          group = ACTIVITY_TYPES.UNSCHEDULED;
        } else if (
          numberOfDaysLeft <= 7 &&
          currentDate.getDay() < item.groupDate.getDay()
        ) {
          group = ACTIVITY_TYPES.WEEKLY;
        } else if (
          moment(item.groupDate).isBetween(
            firstDateOfCurrentMonth,
            lastDateOfCurrentMonth,
          )
        ) {
          group = ACTIVITY_TYPES.MONTHLY;
        } else if (
          currentYear === dueDateYear &&
          currentQuarter === dueDateQuarter
        ) {
          group = ACTIVITY_TYPES.QUATERLY;
        } else if (item.groupDate.getFullYear() >= currentDate.getFullYear()) {
          group = ACTIVITY_TYPES.UPCOMMING;
        }
      } else if (
        item.waiting_for_submission ||
        (item.schedule.frequency_calendar_key ===
          ACTIVITY_FREQUENCY_TYPES.TWELVE_MONTH &&
          moment(item.due_date).diff(moment(), 'days') + 2 <=
            SHOW_TWELVE_MONTH_ACTIVITY_TIME_DAYS)
      ) {
        group = ACTIVITY_TYPES.WAITING_FOR_SUBMISSION;
      } else group = ACTIVITY_TYPES.UNSCHEDULED;

      item.group = group;
    });
    return activities;
  }

  groupActivities(data, type) {
    var list = data.filter((q) => q.group === type);

    list = list.sort((a, b) =>
      a.activity_number.localeCompare(b.activity_number),
    );

    return list;
  }

  compare(a, b) {
    if (a.activity_number < b.activity_number) {
      return -1;
    }
    if (a.activity_number > b.activity_number) {
      return 1;
    }
    return 0;
  }

  sortActivities(activities) {
    return [
      {
        key: 'sort',
        data: activities,
      },
    ];
  }

  activitiesSeparatedModel(
    dailyData,
    weeklyData,
    monthlyData,
    quaterlyData,
    upcomingData,
    unscheduleData,
    waitingForSubmissionData,
  ) {
    return [
      // Remove twelve month activities
      // {
      //   title: localization[getLocale()].WAITING_FOR_SUBMISSION,
      //   key: ACTIVITY_TYPES.WAITING_FOR_SUBMISSION,
      //   data: waitingForSubmissionData,
      // },
      {
        title: localization[getLocale()].DUE_TODAY,
        key: ACTIVITY_TYPES.DAILY,
        data: dailyData,
      },
      {
        title: localization[getLocale()].DUE_WEEK,
        key: ACTIVITY_TYPES.WEEKLY,
        data: weeklyData,
      },
      {
        title: localization[getLocale()].DUE_MONTH,
        key: ACTIVITY_TYPES.MONTHLY,
        data: monthlyData,
      },
      {
        title: localization[getLocale()].DUE_QUARTER,
        key: ACTIVITY_TYPES.QUATERLY,
        data: quaterlyData,
      },
      {
        title: localization[getLocale()].UPCOMING,
        key: ACTIVITY_TYPES.UPCOMING,
        data: upcomingData,
      },
      {
        title: localization[getLocale()].UNSCHEDULED,
        key: ACTIVITY_TYPES.UNSCHEDULED,
        data: unscheduleData,
      },
    ];
  }

  parseInstancesSectionData(instances) {
    let activeInstances = [];
    let inActiveInstances = [];
    let res = [];
    Object.keys(instances).map((key) => {
      let instance = instances[key];
      if (instance.status) {
        activeInstances.push(instance);
      } else {
        inActiveInstances.push(instance);
      }
    });
    if (activeInstances.length) {
      res.push({
        title: localization[getLocale()].ACTIVE,
        data: activeInstances,
      });
    }
    if (inActiveInstances.length) {
      res.push({
        title: localization[getLocale()].INACTIVE,
        data: inActiveInstances,
      });
    }
    return res;
  }

  parseSubmissionsSectionData(submissions, backdated, startDate, endDate) {
    let res = [];
    let title = [];

    submissions = submissions
      .filter((value) => Object.keys(value).length !== 0)
      .sort((a, b) => {
        return b.submitted_date
          ? b.submitted_date.localeCompare(a.submitted_date)
          : -1;
      });

    for (let i = 0; i < submissions.length; i++) {
      const date = moment(
        submissions[i].submitted_date || submissions[i].actual_submission_date,
      ).format(DATE_FORMATS.MMMM_YYYY);
      if (!_.includes(title, date)) {
        title.push(date);
        res.push({ title: date, data: [] });
      }
    }

    // data
    for (let i = 0; i < submissions.length; i++) {
      const date = moment(
        submissions[i].submitted_date || submissions[i].actual_submission_date,
      ).format(DATE_FORMATS.MMMM_YYYY);
      let index = _.findIndex(res, function (x) {
        return x.title == date;
      });
      if (index >= 0) {
        Object.keys(submissions[i]).length !== 0
          ? res[index].data.push(submissions[i])
          : null;
      }
    }

    return res;
  }

  getStatus = (status) => {
    switch (status) {
      case SUBMISSION_STATUS.REMOVED: {
        return localization[getLocale()].REMOVED;
      }
      case SUBMISSION_STATUS.SUBMITTED: {
        return localization[getLocale()].SUBMITTED;
      }
      case SUBMISSION_STATUS.PENDING: {
        return localization[getLocale()].PENDING;
      }
      case SUBMISSION_STATUS.REJECTED: {
        return localization[getLocale()].REJECTED;
      }
      case SUBMISSION_STATUS.MISSED: {
        return localization[getLocale()].MISSED;
      }
    }
  };

  getIcon = (status) => {
    switch (status) {
      case SUBMISSION_STATUS.REMOVED: {
        return 'removed';
      }
      case SUBMISSION_STATUS.SUBMITTED: {
        return 'submitted';
      }
      case SUBMISSION_STATUS.PENDING: {
        return 'pending';
      }
      case SUBMISSION_STATUS.REJECTED: {
        return 'rejected';
      }
      case SUBMISSION_STATUS.MISSED: {
        return 'missed';
      }
    }
  };

  getSubmissionObject(values, submissionDate, activity, instance, users) {
    // format multiSelect values
    const multiSelect = _.filter(activity.fields, [
      'field_type_key',
      SUBMISSION_FIELD_TYPES.MULTI_SELECT,
    ]);
    if (multiSelect && multiSelect.length > 0) {
      for (let a = 0; a < multiSelect.length; a++) {
        let options = [];
        if (typeof values[multiSelect[a].group_id] === 'string') {
          options.push(values[multiSelect[a].group_id]);
        } else {
          for (let i = 0; i < values[multiSelect[a].group_id].length; i++) {
            //if submitting currently or processing already saved data
            if (values[multiSelect[a].group_id][i].value) {
              options.push(values[multiSelect[a].group_id][i].id);
            }
          }
        }
        values[multiSelect[a].group_id] = options.join(',');
      }
    }

    // format single select values
    const singleSelect = _.filter(activity.fields, [
      'field_type_key',
      SUBMISSION_FIELD_TYPES.SINGLE_SELECT,
    ]);
    if (singleSelect && singleSelect.length > 0) {
      for (let i = 0; i < singleSelect.length; i++) {
        if (
          typeof values[singleSelect[i].group_id] !== 'string' &&
          values[singleSelect[i].group_id] != ''
        ) {
          let selectedOption = _.find(values[singleSelect[i].group_id], [
            'value',
            true,
          ]);

          if (process.env.REACT_APP_PLATFORM !== 'WEB') {
            if (Platform.OS === PLATFORM.ANDROID) {
              if (!selectedOption && singleSelect[i].is_required === 1) {
                selectedOption = values[singleSelect[i].group_id][0];
              }
            }
          }

          values[singleSelect[i].group_id] = selectedOption
            ? selectedOption.id
            : '';
        }
      }
    }

    // get formula field value
    const formulaFields = _.filter(activity.fields, [
      'field_type_key',
      SUBMISSION_FIELD_TYPES.FORMULA,
    ]);
    if (formulaFields && formulaFields.length > 0) {
      for (let i = 0; i < formulaFields.length; i++) {
        const value = this.getFormulaFieldValue(
          values,
          values[formulaFields[i].group_id],
          activity.fields,
          formulaFields[i].is_absolute,
        );
        values[formulaFields[i].group_id] =
          value === '...'
            ? ''
            : typeof value == 'number'
            ? parseFloat(value.toFixed(2)).toString() //as browser was changing infinite and NaN to null in post call. had to intentionally stringify it
            : value;
      }
    }

    // get date field value
    const dateFields = _.filter(activity.fields, [
      'field_type_key',
      SUBMISSION_FIELD_TYPES.DATE_FIELD,
    ]);
    if (dateFields && dateFields.length > 0) {
      for (let i = 0; i < dateFields.length; i++) {
        values[dateFields[i].group_id] = moment(
          values[dateFields[i].group_id],
        ).format(DATE_FORMATS.YYYY_MM_DD_HH_mm_ss);
      }
    }

    if (process.env.REACT_APP_PLATFORM !== 'WEB') {
      if (Platform.OS === PLATFORM.ANDROID) {
        // get employee field value
        const employeeFields = _.filter(activity.fields, [
          'field_type_key',
          SUBMISSION_FIELD_TYPES.EMPLOYEES,
        ]);
        if (employeeFields && employeeFields.length > 0) {
          for (let i = 0; i < employeeFields.length; i++) {
            if (
              !values[employeeFields[i].group_id] &&
              employeeFields[i].is_required === 1
            ) {
              values[employeeFields[i].group_id] = users[0].user_id;
            }
          }
        }
      }
    }

    let obj = {
      feedlot_activity_submission: {
        feedlot_activity_id: activity.feedlot_activity_id,
        submitted_date: moment(submissionDate).format(
          DATE_FORMATS.YYYY_MM_DD_HH_mm_ss,
        ),
        values,
      },
    };

    if (instance) {
      obj.feedlot_activity_submission.feedlot_activity_instance_id =
        instance.feedlot_activity_instance_id;
    }
    return obj;
  }

  getAddSubmissionInitialValues(activity, submission) {
    let initialValues = {};
    const fields =
      activity.fields &&
      Object.keys(activity.fields).map((key) => activity.fields[key]);

    fields.map((field) => {
      let values = [];
      const obj = _.find(submission.values, ['key', field.group_id.toString()]);
      const fieldValue = obj ? obj.value : '';

      switch (field.field_type_key) {
        case SUBMISSION_FIELD_TYPES.SINGLE_SELECT:
          for (let i = 0; i < field.values.length; i++) {
            let obj = {};
            obj.id = field.values[i].value_id;
            obj.label = field.values[i].locale[getLanguageKey()].value;
            obj.value = fieldValue
              ? fieldValue.includes(obj.id.toString())
                ? true
                : false
              : false;
            values.push(obj);
          }
          initialValues[field.group_id] = values;
          break;

        case SUBMISSION_FIELD_TYPES.MULTI_SELECT:
          values = [];
          for (let i = 0; i < field.values.length; i++) {
            let obj = {};
            obj.id = field.values[i].value_id;
            obj.label = field.values[i].locale[getLanguageKey()].value;
            obj.value = fieldValue
              ? fieldValue.includes(obj.id.toString())
                ? true
                : false
              : false;
            if (field.special_property === 'pens' && activity.schedule) {
              obj.submissionCount =
                field.values[i].locale[getLanguageKey()].submission;
            }
            values.push(obj);
          }
          initialValues[field.group_id] = values;
          break;

        case SUBMISSION_FIELD_TYPES.PARAGRAPH:
          initialValues[field.group_id] =
            fieldValue || field.values[0].locale[getLanguageKey()].value;
          break;

        case SUBMISSION_FIELD_TYPES.FORMULA:
          // get tags from formula
          const tags = field.formula.replace(/[^a-z]/gi, '');
          _.split(tags, ',');
          let value = field.formula;
          for (let i = 0; i < tags.length; i++) {
            const formField = _.find(fields, [
              'field_tag',
              tags[i].toLowerCase(),
            ]);
            if (
              (formField &&
                formField.field_type_key === SUBMISSION_FIELD_TYPES.INTEGER) ||
              formField.field_type_key === SUBMISSION_FIELD_TYPES.DECIMAL ||
              formField.field_type_key === SUBMISSION_FIELD_TYPES.FORMULA
            ) {
              const id = formField.group_id;
              value = value.replace(tags[i], id);
            } else {
              value = '...';
            }
          }
          initialValues[field.group_id] = value;
          break;

        case SUBMISSION_FIELD_TYPES.ATTACHMENT:
          let parsedValue = [];
          if (fieldValue) {
            let parsedData =
              activity.activity_type_key === ACTIVITY_TYPE.ATTACHMENT
                ? { attachments: [fieldValue] }
                : JSON.parse(fieldValue);
            for (let i = 0; i < parsedData.attachments.length; i++) {
              let obj = {};
              if (
                submission.status === SUBMISSION_STATUS.PENDING &&
                !submission.removeCallPending
              ) {
                var attach =
                  typeof parsedData.attachments[i] === 'object'
                    ? parsedData.attachments[i]
                    : JSON.parse(parsedData.attachments[i]);
                obj.uri = attach.uri;
                obj.type = attach.type;
              } else {
                obj.name = parsedData.attachments[i];
                obj.type = parsedData.attachments[i].split('.')[1];
              }
              parsedValue.push(obj);
            }
          }
          initialValues[field.group_id] = parsedValue;
          break;

        default:
          initialValues[field.group_id] = fieldValue;
          break;
      }
    });

    return initialValues;
  }

  isValidFormulaFieldAnswer(value) {
    if (value == 0 || value == 'NaN' || value == 'Infinite' || Number(value)) {
      return true;
    }
    return false;
  }

  getFormulaFieldValue(fields, formula, allFields, isAbsolute) {
    //allFields is undefined when generating request obj as we just need values as it is
    try {
      const fieldIds = Object.keys(fields);
      let isEdited = false;
      for (let i = 0; i < fieldIds.length; i++) {
        const reg = new RegExp(fieldIds[i], 'g');
        const count = (formula.match(reg) || []).length;
        if (
          count == 0 &&
          formula != '' &&
          this.isValidFormulaFieldAnswer(formula)
        ) {
          //if there is already some value in formula field (offline case of pending action)
          isEdited = true;
        } else if (count > 0) {
          for (let j = 0; j < count; j++) {
            let value = 0;
            let currentField = allFields
              ? allFields.find((f) => f.group_id == fieldIds[i])
              : null;
            //if there is already some value/formula in initial value array for that field else 0 will be there;
            if (
              fields[fieldIds[i]] &&
              fields[fieldIds[i]].toString().match('[0-9]+')
            ) {
              if (
                currentField &&
                currentField.field_type_key == SUBMISSION_FIELD_TYPES.FORMULA
              ) {
                //get actual value after solving formula for nested formula field (only while filling submission form)
                value = this.getFormulaFieldValue(
                  fields,
                  fields[fieldIds[i]].toString(),
                  allFields,
                  currentField.is_absolute,
                );
                if (value === '...') {
                  value = 0;
                } else {
                  isEdited = true;
                }
              } else {
                //add value of the field if its other than formula field
                value = fields[fieldIds[i]];
                isEdited = true;
              }
            }

            formula = formula.replace(fieldIds[i], value);
          }
        }
      }

      //if used has not edited fields then show ...
      if (!isEdited) {
        formula = '...';
      }

      let result = formula === '...' ? formula : eval(formula);
      if (isEdited && isAbsolute) {
        result = Math.abs(result);
      }

      return result;
    } catch (e) {
      console.log('getFormulaFieldValue fail', e);
    }
  }

  getAttachmentObject = async ({
    value,
    submissionDate,
    activity,
    instance,
  }) => {
    let obj = {
      feedlot_activity_submission_attachment: {
        data: base64Regex.test(value.uri)
          ? value.uri
          : await globalHelper.getBase64File(value.uri),
        extension: value.type,
        feedlot_activity_id: activity.feedlot_activity_id,
        submitted_date: moment(submissionDate).format(
          DATE_FORMATS.YYYY_MM_DD_HH_mm_ss,
        ),
      },
    };

    if (instance) {
      obj.feedlot_activity_submission_attachment.feedlot_activity_instance_id =
        instance.feedlot_activity_instance_id;
    }

    return obj;
  };

  getFrequency(schedule) {
    var frequencyAsString = '';

    switch (schedule.frequency_calendar_key) {
      case 1:
        frequencyAsString = localization[getLocale()].YEARLY;
        break;
      case 2:
        frequencyAsString = localization[getLocale()].QUATERLY;
        break;
      case 3:
        frequencyAsString = localization[getLocale()].MONTHLY;
        break;
      case 4:
        frequencyAsString = localization[getLocale()].WEEKLY;
        break;
      case 5:
        frequencyAsString = localization[getLocale()].DAILY;
        break;
      case 8:
        frequencyAsString = localization[getLocale()].BIANNUALLY;
        break;
      case 9:
        frequencyAsString = localization[getLocale()].TWELVE_MONTHS;
        break;
    }
    return frequencyAsString;
  }

  getRemoveSubmissionObject(submissionId, reason) {
    const obj = {
      feedlot_activity_submission_cancellation: {
        feedlot_activity_submission_id: submissionId,
        reason: reason,
      },
    };
    return obj;
  }

  calculateActivitiesDueTodayData(activities) {
    let total = 0;
    let dueToday = 0;
    let data = { total, dueToday };
    if (!activities) {
      return data;
    }
    activities.forEach((activityType) => {
      if (activityType.key === ACTIVITY_TYPES.DAILY) {
        data.dueToday = activityType.data.length;
      }
      data.total += activityType.data.length;
    });
    return data;
  }

  parseDataForPicker(data, type) {
    let parsedData = [];
    if (data.length > 0) {
      if (type === 'EmployeeList') {
        data.map((option, index) => {
          let obj = {};
          obj.label = `${option.profile.first_name} ${option.profile.last_name}`;
          obj.value = option.user_id;
          parsedData[index] = obj;
        });
      } else {
        data.map((option, index) => {
          let obj = {};
          obj.label = option.label;
          obj.value = option.id;
          parsedData[index] = obj;
        });
      }

      let obj = {};
      obj.label = 'Please Select';
      obj.value = null;
      parsedData.unshift(obj);
    }
    return parsedData;
  }

  /**
   * pushing every activity in different groups according to activity's performance
   * @param {object} activities - returns groupPerformanceActivities object
   */
  groupPerformanceActivities(activities) {
    let performanceActivities = {
      cumulative: [],
      daily: [],
      monthly: [],
      thisMonth: [],
      thisWeek: [],
      weekly: [],
    };
    activities.forEach((activity) => {
      Object.keys(globalHelper.parseRealmList(activity.feedlotPerformance))
        .filter((key) => key !== 'performance')
        .map((key) => {
          if (
            (activity.feedlotPerformance[key] &&
              activity.feedlotPerformance[key].performance) ||
            (activity.activityPredictedPerformance &&
              activity.activityPredictedPerformance[key] &&
              activity.activityPredictedPerformance[key].performance)
          ) {
            performanceActivities[key].push(activity);
          }
        });
    });
    return performanceActivities;
  }

  /**
   * Separate grouped Performance Activities in to different levels of missed, partially submitted and completed
   * @param {*} groupedPerformanceActivities - grouped performance activities as returned by ActivitiesHelper.groupPerformanceActivities(activities)
   */
  separatePerformanceActivities(groupedPerformanceActivities) {
    let separatedPerformanceActivities = {};
    Object.keys(groupedPerformanceActivities).map((key) => {
      const missedActitivities = [];
      const partiallyMissedActitivities = [];
      const completedActitivities = [];
      const predictedPerformanceActivities = [];
      groupedPerformanceActivities[key].forEach((activity) => {
        if (activity.feedlotPerformance[key].performance) {
          if (Number(activity.feedlotPerformance[key].performance) === 100) {
            completedActitivities.push(activity);
          } else if (
            Number(activity.feedlotPerformance[key].performance) === 0
          ) {
            missedActitivities.push(activity);
          } else {
            partiallyMissedActitivities.push(activity);
          }
        }
        if (
          activity.activityPredictedPerformance &&
          activity.activityPredictedPerformance[key] &&
          activity.activityPredictedPerformance[key].performance
        ) {
          predictedPerformanceActivities.push(activity);
        }
      });
      separatedPerformanceActivities[key] = [
        {
          title: localization[getLocale()].MISSED,
          key: `${PERFORMANCE_SECTION_KEYS.MISSED} - ` + key,
          data: missedActitivities,
        },
        {
          title: localization[getLocale()].PARTIALLY_SUBMITTED,
          key: `${PERFORMANCE_SECTION_KEYS.PARTIALLY} - ` + key,
          data: partiallyMissedActitivities,
        },
        {
          title: localization[getLocale()].TIMELY_SUBMITTED,
          key: `${PERFORMANCE_SECTION_KEYS.COMPLETED} - ` + key,
          data: completedActitivities,
        },
        {
          title: this.getProgressString(key), //localization[getLocale()].PARTIALLY_SUBMITTED,
          key: `${PERFORMANCE_SECTION_KEYS.PREDICTED} - ` + key,
          data: predictedPerformanceActivities,
        },
      ];
    });
    return separatedPerformanceActivities;
  }

  separateCustomActivities(feedlot_activites, activities) {
    const missedActitivities = [];
    const partiallyMissedActitivities = [];
    const completedActitivities = [];
    const activityIds = activities.map((activity) => activity.activity_id);
    feedlot_activites
      .filter((fdAct) => activityIds.includes(fdAct.activity_id))
      .forEach((activity) => {
        let pushActivity = activities.find(
          (act) => act.activity_id === activity.activity_id,
        );
        let newActivity = activityModel(pushActivity);
        newActivity.feedlotPerformance = feedlotPerformance(
          newActivity.feedlotPerformance,
        );
        newActivity.feedlotPerformance.performance =
          activity.performance.toString();
        if (Number(activity.performance) != -1) {
          if (Number(activity.performance) === 100) {
            completedActitivities.push(newActivity);
          } else if (Number(activity.performance) === 0) {
            missedActitivities.push(newActivity);
          } else {
            partiallyMissedActitivities.push(newActivity);
          }
        }
      });
    return [
      {
        title: localization[getLocale()].MISSED,
        key: `${PERFORMANCE_SECTION_KEYS.MISSED}-`,
        data: missedActitivities,
      },
      {
        title: localization[getLocale()].PARTIALLY_SUBMITTED,
        key: `${PERFORMANCE_SECTION_KEYS.PARTIALLY}-`,
        data: partiallyMissedActitivities,
      },
      {
        title: localization[getLocale()].TIMELY_SUBMITTED,
        key: `${PERFORMANCE_SECTION_KEYS.COMPLETED}-`,
        data: completedActitivities,
      },
    ];
  }

  getInstancePerformance(instance, performanceKey, section) {
    section = section ? section.key.split(' ')[0] : null;

    if (
      section &&
      section === PERFORMANCE_SECTION_KEYS.PREDICTED &&
      instance.activityPredictedPerformance &&
      typeof instance.activityPredictedPerformance === 'object' &&
      instance.activityPredictedPerformance[performanceKey]
    ) {
      return instance.activityPredictedPerformance[performanceKey].performance;
    } else {
      return instance.feedlotPerformance[performanceKey].performance;
    }
  }

  getCustomInstancePerformance(
    custom_activities_instances,
    activityId,
    feedlot_activity_instance_id,
  ) {
    const instance = custom_activities_instances[activityId].filter(
      (ins) =>
        ins.feedlot_activity_instance_id === feedlot_activity_instance_id,
    )[0];
    if (instance && instance.performance && instance.performance > -1) {
      return instance.performance;
    }
    return null;
  }

  getCustomInstanceBackdateCount(
    custom_activities_instances,
    activityId,
    feedlot_activity_instance_id,
  ) {
    const instance = custom_activities_instances[activityId].filter(
      (ins) =>
        ins.feedlot_activity_instance_id === feedlot_activity_instance_id,
    )[0];
    if (
      instance &&
      instance.activityBackDatedSubmissions &&
      instance.activityBackDatedSubmissions.custom
    ) {
      return instance.activityBackDatedSubmissions.custom
        .backdatedSubmissionCount;
    }
    return null;
  }

  getProgressString(key) {
    if (!key) {
      return localization[getLocale()].PROGRESS;
    } else if (key === PERFORMANCE_DATA_KEYS_MAP.thisMonth) {
      return localization[getLocale()].MONTHS_PROGRESS;
    } else if (key === PERFORMANCE_DATA_KEYS_MAP.thisWeek) {
      return localization[getLocale()].WEEKS_PROGRESS;
    } else {
      return localization[getLocale()].PROGRESS;
    }
  }

  getActivityTypeIcon = (activityType) => {
    switch (activityType) {
      case ACTIVITY_TYPE.LOG: {
        return 'logType';
      }
      case ACTIVITY_TYPE.FORM: {
        return 'cow';
      }
      case ACTIVITY_TYPE.ATTACHMENT: {
        return 'attachment';
      }
      default:
        return 'barn';
    }
  };

  getActivityPerformanceValue(activity, performanceKey, section) {
    if (
      performanceKey &&
      activity.activityPredictedPerformance &&
      activity.activityPredictedPerformance[performanceKey] &&
      section.key.includes(PERFORMANCE_SECTION_KEYS.PREDICTED)
    ) {
      return activity.activityPredictedPerformance[performanceKey].performance;
    }
    return performanceKey
      ? activity.feedlotPerformance[performanceKey].performance
      : activity.feedlotPerformance.performance;
  }

  processAssignedActivities(activities, user) {
    var assigned = activities.map((item) => {
      return {
        ...item,
        data: item.data.filter((q) => {
          var isFound = false;

          if (q.users && q.users.length > 0) {
            if (Number(user.userRoleId) === userRoles.WORKER) {
              isFound = false;

              const users = Array.from(q.users);
              if (
                users.findIndex((w) => w.user_id === Number(user.userId)) !== -1
              ) {
                isFound = true;
              }
            } else {
              isFound = true;
            }
          } else {
            isFound = false;
          }
          return isFound;
        }),
      };
    });

    return assigned;
  }

  processUnassignedActivities(activities) {
    let unassigned = activities.map((item) => {
      return {
        ...item,
        data: item.data.filter(
          (q) => q.users === undefined || q.users?.length < 1,
        ),
      };
    });

    return unassigned;
  }

  getFormValuesFormat(values) {
    let formattedValues = {};
    Object.keys(values).map((value) => {
      const obj = values[value];
      formattedValues[obj.key] = obj.value;
    });
    return formattedValues;
  }

  getTypeIcon(item) {
    return item.activity_type_key === ACTIVITY_TYPE.LOG
      ? AssetConstants.logType
      : item.activity_type_key === ACTIVITY_TYPE.FORM
      ? AssetConstants.cow
      : item.activity_type_key === ACTIVITY_TYPE.ATTACHMENT
      ? AssetConstants.attachment
      : AssetConstants.barn;
  }

  getActivityFromStore(activitiesList, feedlot_activity_id) {
    const activities = _.find(
      activitiesList,
      _.flow(
        _.property('data'),
        _.partialRight(_.some, {
          feedlot_activity_id: feedlot_activity_id,
        }),
      ),
    );

    const activity = _.find(activities.data, [
      'feedlot_activity_id',
      feedlot_activity_id,
    ]);
    return activity;
  }

  getEmployeeName(users, id) {
    const user = _.find(users, ['user_id', id]);
    if (user) {
      return `${user.profile.first_name} ${user.profile.last_name}`;
    } else {
      return '';
    }
  }

  getOptionLabel(list, id) {
    const obj = _.find(list, ['id', id]);
    if (obj) {
      return obj.label;
    } else {
      return '';
    }
  }

  getIndexToInsert(list, obj) {
    let index = 0;

    for (let i in list) {
      if (list[i].submitted_date < obj.submitted_date) {
        index = i;
        break;
      }
    }
    return (index <= 0 ? 1 : index) - 1;
  }

  getPickerHeight = (item) => {
    const isPermissionWorkerAndInstancedBased =
      item.permission_key === ACTIVITY_PERMISSIONS.ASSIGNED_WORKER &&
      item.instance_based;
    var height = 150;

    if (!item.instance_based) {
      height = height + 60;
    }

    if (!isPermissionWorkerAndInstancedBased) {
      height = height + 60;
    }

    if (item.frequency_key !== ACTIVITY_FREQUENCY.REQUIRED) {
      height = height + 60;
    }

    if (item.instance_based) {
      height = height + 60;
    }

    return height;
  };

  getVoilatedRuleFieldLabels = (fieldsViolated, activityFields) => {
    let violatedFieldsLabels = [];
    for (let i in fieldsViolated) {
      for (let j in activityFields) {
        if (activityFields[j].group_id == fieldsViolated[i]) {
          violatedFieldsLabels.push(activityFields[j].label.locale[1].value);
        }
      }
    }
    return violatedFieldsLabels;
  };

  getImageFromServer(url, headers) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          headers: headers,
          responseType: 'arraybuffer',
        })
        .then((response) => {
          let data = `data:${
            response.headers['content-type']
          };base64,${new Buffer(response.data, 'binary').toString('base64')}`;
          resolve(data);
        });
    });
  }

  shouldShowInstruction = (locale) =>
    !!locale.instruction &&
    locale.show_instruction === SHOW_INSTRUCTION.SHOW &&
    locale.instruction.length > 0;
}

export default new ActivitiesHelper();
