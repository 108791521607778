import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { PrivateRoute } from './routes/PrivateRoute/PrivateRoute';
import './App.scss';
import store from '@progressivebeef/shared/src/store/react';

import ACTION_CONSTANTS from '@progressivebeef/shared/src/constants/actionConstants';

import Login from './screens/app/Login';
import ForgotPassword from './screens/app/Login/ForgotPassword';
import Logout from './screens/app/Login/Logout';
import GeneratePin from './screens/app/Login/GeneratePin';
import LockScreen from './screens/app/Login/LockScreen';
import Activities from './screens/app/Activities';
import FeedyardList from './screens/app/FeedyardList';
import SwitchFeedyard from './screens/app/FeedyardList';
import Dashboard from './screens/app/Dashboard';
import Coachmark from './screens/app/Coachmark';
import Notification from './screens/app/Dashboard/Notification';
import ActivityPerformance from './screens/app/Dashboard/ActivityPerformance';
import BackDatedSubmissions from './screens/app/Dashboard/BackDatedSubmissions';
import ExportCompliance from './screens/app/Dashboard/ExportCompliance';
import ActiveWorkers from './screens/app/Dashboard/ActiveWorkers';
import PendingActions from './screens/app/Dashboard/PendingActions';
import Filter from './screens/app/Activities/filter';
import ActivityBin from './screens/app/Activities/activityBin';
import SubmissionList from './screens/app/Activities/SubmissionList';
import ActivityTagList from './components/ActivityTagList';
import Print from './screens/app/Activities/Print';
import AssignLog from './screens/app/Activities/AssignLog';
import Schedule from './screens/app/Activities/Schedule';
import ProgramManuals from './screens/app/ProgramManuals';
import ProgramManualBin from './screens/app/ProgramManuals/ProgramManualBin';
import ProgramManualDetail from './screens/app/ProgramManuals/ProgramManualDetail';
import ViewDocument from './screens/app/ProgramManuals/ViewDocument';
import AddDocument from './screens/app/ProgramManuals/AddDocument';
import Instance from './screens/app/Activities/Instance';
import RemovedInstance from './screens/app/Activities/Instance/RemovedInstance';
import Settings from './screens/app/Settings';
import AppTrainingVideoHtml from './screens/app/AppTrainingVideoHTML';
import Users from './screens/app/Users';
import AddSubmission from './screens/app/Activities/AddSubmission';
import AddEditUser from './screens/app/Users/AddEditUser';
import NavigationVideo from './screens/app/NavigationVideo/NavigationVideos';
import ChangePassword from './screens/app/Settings/ChangePassword';
import ChangePin from './screens/app/Settings/ChangePin';
import VideoPlayer from './components/VideoPlayer';
import Audits from './screens/app/Audit/Audits';
import AuditNCList from './screens/app/Audit/AuditNCList';
import NCDetail from './screens/app/Audit/NCDetail';
import AuditReport from './screens/app/Audit/AuditReport';
import IdleTimer, { EVENTS } from 'react-idle-timer';
import ROUTE_CONSTANTS from '@progressivebeef/shared/src/constants/RouteConstants';
import { createBrowserHistory } from 'history';
import { IDLE_TIME } from '@progressivebeef/shared/src/constants/AppConstants';
import ServerError from './screens/app/ServerError';
import ServerNotResponding from './screens/app/ServerNotResponding';
import ViewDocumentReport from './components/ViewDocument';
import AlertService from '@progressivebeef/shared/src/services/AlertService';
import AlertModal from './components/AlertModal';

const afterEveryMins = 10 * 1000 * 60;

export const customHistory = createBrowserHistory();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isConnected: navigator.onLine,
    };
    store.dispatch({
      type: ACTION_CONSTANTS.GET_INITIALIZED,
    });
  }

  componentDidMount() {
    AlertService.alertModal = AlertModal;

    //only for app launch
    let { isLogin, user, selectedFeedyard } = store.getState().authentication;
    this.setState({
      isLogin,
      user,
      selectedFeedyard,
    });
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);

    setInterval(() => {
      //code that will be called every 3 seconds
      const isLoggedIn = store.getState().authentication.isLogin;
      const feedyardSelected = store.getState().authentication.selectedFeedyard;
      if (isLoggedIn && feedyardSelected) {
        store.dispatch({
          type: ACTION_CONSTANTS.SYNC_DATA_NON_BLOCKING_REQUEST,
          payload: { isAutoSync: true },
        });
      }
    }, afterEveryMins);

    setTimeout(() => {
      store.dispatch({
        type: ACTION_CONSTANTS.HIDE_RANK_CARD,
      });
    }, 70000);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    // console.log('condition', condition);
    if (condition === 'online') {
      this.setState({
        isConnected: true,
      });
      const isLoggedIn = store.getState().authentication.isLogin;
      const feedyardSelected = store.getState().authentication.selectedFeedyard;
      const syncPending = store.getState().dataSync.syncPending;
      // console.log('syncPending', syncPending);
      if (isLoggedIn && feedyardSelected) {
        store.dispatch({
          type: ACTION_CONSTANTS.POST_PENDING_ACTIVITY_SUBMISSIONS_REQUEST,
        });
        if (syncPending) {
          store.dispatch({
            type: ACTION_CONSTANTS.SYNC_DATA_NON_BLOCKING_REQUEST,
            payload: { isAutoSync: true },
          });
        }
      }

      return;
    }

    return this.setState({ isConnected: false });
  };

  render() {
    const { history } = this.props;

    return (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          timeout={IDLE_TIME}
          onIdle={() => {
            if (
              history?.location?.pathname != '/' + ROUTE_CONSTANTS.LOGIN &&
              history?.location?.pathname !=
                '/' + ROUTE_CONSTANTS.FORGOT_PASSWORD &&
              history?.location?.pathname !=
                '/' + ROUTE_CONSTANTS.GENERATE_PIN &&
              history?.location?.pathname != '/' + ROUTE_CONSTANTS.LOGOUT &&
              history?.location?.pathname != '/' + ROUTE_CONSTANTS.FEEDYARD_LIST
            ) {
              history.push(ROUTE_CONSTANTS.LOCK_SCREEN);
              store.dispatch({
                type: ACTION_CONSTANTS.SHOW_LOCK_SCREEN,
              });
            }
          }}
          debounce={250}
          events={[
            'keydown',
            'wheel',
            'DOMMouseScroll',
            'mousewheel',
            'mousedown',
            'touchstart',
            'touchmove',
            'MSPointerDown',
            'visibilitychanges',
          ]}
        />
        <Switch>
          <Route
            path="/ServerError"
            name="ServerError"
            component={ServerError}
          />
          <Route
            path="/ServerNotResponding"
            name="ServerNotResponding"
            component={ServerNotResponding}
          />
          <Route exact path="/Login" name="Login Page" component={Login} />
          <Route
            path="/ForgotPassword"
            name="Forgot Password"
            component={ForgotPassword}
          />

          <PrivateRoute path="/Logout" name="Logout Page" component={Logout} />

          <PrivateRoute
            path="/GeneratePin"
            name="GeneratePin"
            component={GeneratePin}
          />

          <Route path="/LockScreen" name="LockScreen" component={LockScreen} />

          <PrivateRoute
            path="/Dashboard"
            name="Dashboard"
            component={Dashboard}
          />

          <PrivateRoute
            path="/Coachmark"
            name="Coachmark"
            component={Coachmark}
          />

          <PrivateRoute
            path="/Notification"
            name="Notification"
            component={Notification}
          />

          <PrivateRoute
            path="/BackDatedSubmissions"
            name="BackDatedSubmissions"
            component={BackDatedSubmissions}
          />

          <PrivateRoute
            path="/ActivityPerformance"
            name="ActivityPerformance"
            component={ActivityPerformance}
          />

          <PrivateRoute
            path="/ExportCompliance"
            name="ExportCompliance"
            component={ExportCompliance}
          />

          <PrivateRoute
            path="/ActiveWorkers"
            name="ActiveWorkers"
            component={ActiveWorkers}
          />
          <PrivateRoute
            path="/PendingActions"
            name="PendingActions"
            component={PendingActions}
          />
          <PrivateRoute
            path="/Activities"
            name="Activities"
            component={Activities}
          />

          <PrivateRoute path="/Filter" name="Filter" component={Filter} />

          <PrivateRoute
            path="/AddSubmission"
            name="AddSubmission"
            component={AddSubmission}
          />

          <PrivateRoute path="/Print" name="Print" component={Print} />

          <PrivateRoute
            path="/ActivityBin"
            name="ActivityBin"
            component={ActivityBin}
          />

          <PrivateRoute
            path="/ActivityTagList"
            name="ActivityTagList"
            component={ActivityTagList}
          />

          <PrivateRoute
            path="/AssignLog"
            name="AssignLog"
            component={AssignLog}
          />

          <PrivateRoute path="/Schedule" name="Schedule" component={Schedule} />

          <PrivateRoute path="/Instance" name="Instance" component={Instance} />

          <PrivateRoute
            path="/RemovedInstance"
            name="RemovedInstance"
            component={RemovedInstance}
          />

          <PrivateRoute
            path="/ViewDocumentReport"
            name="ViewDocumentReport"
            component={ViewDocumentReport}
          />

          <PrivateRoute
            path="/SubmissionList"
            name="SubmissionList"
            component={SubmissionList}
          />

          <PrivateRoute
            path="/ProgramManual"
            name="ProgramManual"
            component={ProgramManuals}
          />

          <PrivateRoute
            path="/ProgramManualBin"
            name="ProgramManualBin"
            component={ProgramManualBin}
          />

          <PrivateRoute
            path="/ProgramManualDetail"
            name="ProgramManualDetail"
            component={ProgramManualDetail}
          />

          <PrivateRoute
            path="/ViewDocument"
            name="ViewDocument"
            component={ViewDocument}
          />

          <PrivateRoute
            path="/AddDocument"
            name="AddDocument"
            component={AddDocument}
          />

          <PrivateRoute
            path="/SwitchFeedyard"
            name="Switch Feedyard"
            component={SwitchFeedyard}
          />

          <PrivateRoute
            path="/ChangePassword"
            name="Change Password"
            component={ChangePassword}
          />
          <PrivateRoute
            path="/ChangePin"
            name="Change Pin"
            component={ChangePin}
          />
          <PrivateRoute
            path="/FeedyardList"
            name="Feedyard List"
            component={FeedyardList}
          />
          <PrivateRoute path="/Settings" name="Settings" component={Settings} />
          <PrivateRoute path="/Users" name="Users" component={Users} />
          <PrivateRoute
            path="/AddUser"
            name="AddUser"
            component={AddEditUser}
          />

          <PrivateRoute
            path="/EditUser"
            name="EditUser"
            component={AddEditUser}
          />
          <PrivateRoute
            path="/NavigationVideos"
            name="NavigationVideos"
            component={NavigationVideo}
          />
          <PrivateRoute
            path="/VideoPlayer"
            name="VideoPlayer"
            component={VideoPlayer}
          />
          <PrivateRoute path="/Audits" name="Audits" component={Audits} />
          <PrivateRoute
            path="/AuditNCList"
            name="AuditNCList"
            component={AuditNCList}
          />
          <PrivateRoute path="/NCDetail" name="NCDetail" component={NCDetail} />
          <PrivateRoute
            path="/AuditReport"
            name="AuditReport"
            component={AuditReport}
          />
          <PrivateRoute
            path="/AppTrainingVideoHtml"
            name="AppTrainingVideoHtml"
            component={AppTrainingVideoHtml}
          />

          <PrivateRoute path="/" name="Home" component={Dashboard} />
        </Switch>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
