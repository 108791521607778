import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import './style.scss';

import { getFeedlotCustomPerformanceRequest } from '@progressivebeef/shared/src/store/actions/dashboard';
import {
  setPerformanceEndDate,
  setPerformanceStartDate,
} from '@progressivebeef/shared/src/store/actions/cards';
import DashboardHelper from '@progressivebeef/shared/src/helpers/DashboardHelper';

import {
  PERFORMANCE_DATA_KEYS,
  DATE_FORMATS,
  PERFORMANCE_DATA_KEYS_SORTING_ENUM,
  PERFORMANCE_DATA_KEYS_MAP,
} from '@progressivebeef/shared/src/constants/AppConstants';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import localization from '@progressivebeef/shared/src/localization/i18n';
import SpeedometerCard from './speedometerCard';
import DatesModal from './dateModal';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';
import { showAlertMsg, showErrorAlert } from '../../../../../components/Alerts';
import ActivitiesHelper from '@progressivebeef/shared/src/helpers/ActivitiesHelper';

import { trackEvent } from '@progressivebeef/shared/src/services/reactMixpanelService';
import { Events } from '@progressivebeef/shared/src/constants/MixpanelConstants';
import { setSelectedPerformanceTab } from '@progressivebeef/shared/src/store/actions/activities';

const customPeformanceListIndex = 6;
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

class FeedyardPerformanceCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date(moment().subtract(1, 'd')),
      showStartDate: false,
      showEndDate: false,
      modalVisible: false,
    };
  }

  componentDidMount() {
    trackEvent(Events.VIEWED_DETAILED_PERFORMANCE);
  }

  componentDidUpdate(prevProps) {
    const { error } = this.props;

    if (error && error !== prevProps.error) {
      if (typeof error === 'string') {
        this.setState({ modalVisible: false });
        setTimeout(() => {
          showErrorAlert(error, [
            {
              text: localization[getLocale()].OK,
              onPress: () => {
                // this.props.resetBackdatedProps();
              },
            },
          ]);
        }, 500);
      } else {
        showErrorAlert(localization[getLocale()].SOMETHING_WENT_WRONG);
      }
    }
  }

  onSubmitCustomPerformance = (startDate, endDate) => {
    let currentDate = new Date(moment().endOf('day').subtract(1, 'd'));

    if (startDate > currentDate || endDate > currentDate) {
      showAlertMsg(
        localization[getLocale()].INVALID_DATES,
        localization[getLocale()].END_DATE_EARLIER_THAN_TODAY_ERROR
      );
      return;
    } else if (startDate > endDate) {
      showAlertMsg(
        localization[getLocale()].INVALID_DATES,
        localization[getLocale()].START_LESS_THAN_END_DATE
      );
      return;
    }

    startDate = moment(startDate).startOf('day');
    endDate = moment(endDate).endOf('day');

    startDate = moment(startDate).format(DATE_FORMATS.YYYY_MM_DD_HH_mm_ss);
    endDate = moment(endDate).format(DATE_FORMATS.YYYY_MM_DD_HH_mm_ss);

    this.props.getFeedlotCustomPerformance({
      startDate,
      endDate,
    });

    this.props.setPerformanceStartDate(startDate || currentDate);
    this.props.setPerformanceEndDate(endDate || currentDate);

    this.setState({ modalVisible: false });
  };

  renderCards = () => {
    const {
      performanceData,
      performance,
      isFeedlotCustomPerformanceLoading,
      user,
      isFeedlotStatsLoading,
      startDate,
      endDate,
      activities,
    } = this.props;

    const noActivities = ActivitiesHelper.processAssignedActivities(
      activities,
      user
    )
      .map((o) => o.data)
      .flat().length;

    const validPerformanceKeys = Object.keys(PERFORMANCE_DATA_KEYS);
    let elements = Object.keys(performanceData)
      .filter((key) => {
        if (validPerformanceKeys.includes(key)) {
          return key;
        }
      })
      .sort((a, b) => {
        return (
          PERFORMANCE_DATA_KEYS_SORTING_ENUM[a] -
          PERFORMANCE_DATA_KEYS_SORTING_ENUM[b]
        );
      })
      .map((key, index) => {
        const value =
          key === PERFORMANCE_DATA_KEYS_MAP.thisWeek
            ? null
            : Number(performanceData[key].performance);
        return (
          <SwiperSlide key={`speedo-meter-${index}`}>
            <SpeedometerCard
              isFeedlotStatsLoading={isFeedlotStatsLoading}
              user={user}
              onPress={() => {
                this.props.history.push(RouteConstants.ACTIVITY_PERFORMANCE, {
                  index,
                });
              }}
              noActivities={noActivities}
              value={value}
              heading={PERFORMANCE_DATA_KEYS[key]()}
              date={DashboardHelper.PERFORMANCE_DATE_STRING[key]}
            />
          </SwiperSlide>
        );
      });
    elements.push(
      <SwiperSlide key={'speedo-meter-6'}>
        <SpeedometerCard
          user={user}
          loading={isFeedlotCustomPerformanceLoading}
          onPress={() => {
            if (startDate && endDate) {
              this.props.history.push(RouteConstants.ACTIVITY_PERFORMANCE, {
                index: customPeformanceListIndex,
              });
            } else {
              showErrorAlert(localization[getLocale()].PERFORMANCE_CUSTOM_DATE_ERROR)
            }
          }}
          onDatePress={() => {
            this.setState({ modalVisible: true });
          }}
          isCustomCard={true}
          value={performance && Number(performance)}
          heading={
            startDate && endDate
              ? localization[getLocale()].CHANGE_DATE
              : localization[getLocale()].SELECT_DATE_RANGE_LABEL
          }
          date={
            startDate && endDate
              ? `(${moment(
                startDate ? startDate : this.props.performanceStartDate
              ).format(DATE_FORMATS.MM_DD_YY)} - ${moment(
                endDate ? endDate : this.props.performanceEndDate
              ).format(DATE_FORMATS.MM_DD_YY)})`
              : null
          }
        />
      </SwiperSlide>
    );
    return elements;
  };

  render() {
    const {
      isFeedlotStatsLoading,
      performanceStartDate,
      performanceEndDate,
      selectedTab,
      startDate,
      endDate,
    } = this.props;
    const {
      modalVisible,
      showStartDate,
      showEndDate,
      currentDate,
    } = this.state;

    return (
      <div>
        <DatesModal
          modalVisible={modalVisible}
          showStartDate={showStartDate}
          showEndDate={showEndDate}
          start_date={startDate || performanceStartDate}
          end_date={endDate || performanceEndDate}
          onSubmitStartDate={(date) => {
            this.props.setPerformanceStartDate(date || currentDate);
            this.setState({ showStartDate: false });
          }}
          onSubmitEndDate={(date) => {
            this.props.setPerformanceEndDate(date || currentDate);
            this.setState({ showEndDate: false });
          }}
          dismissModal={() => {
            this.setState({ modalVisible: false });
          }}
          onSubmit={this.onSubmitCustomPerformance}
        />
        <div
          className={`performace-swiper${isFeedlotStatsLoading ? ' dasboard-card-opt-half' : ''
            }`}
        >
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            height={200}
            initialSlide={Number(selectedTab)}
            pagination={{ clickable: true }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={(e) => {
              this.props.setSelectedPerformanceTab('' + e.activeIndex + '');
            }}
          >
            {this.renderCards()}
          </Swiper>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFeedlotCustomPerformance: (payload) =>
      dispatch(getFeedlotCustomPerformanceRequest(payload)),
    setPerformanceStartDate: (payload) =>
      dispatch(setPerformanceStartDate(payload)),
    setPerformanceEndDate: (payload) =>
      dispatch(setPerformanceEndDate(payload)),
    setSelectedPerformanceTab: (payload) =>
      dispatch(setSelectedPerformanceTab(payload)),
  };
};

const mapStateToProps = (state) => {
  const {
    performanceData,
    performance,
    feedlot_activites,
    isFeedlotStatsLoading,
    isFeedlotCustomPerformanceLoading,
    startDate,
    endDate,
    getCustomPerformanceError,
  } = state.dashboard;
  const { user } = state.authentication;
  const { performanceStartDate, performanceEndDate } = state.cards;
  const { activities, selectedTab } = state.activities;

  return {
    performanceData,
    performance,
    feedlot_activites,
    isFeedlotStatsLoading,
    isFeedlotCustomPerformanceLoading,
    user,
    startDate,
    endDate,
    performanceStartDate,
    performanceEndDate,
    activities,
    selectedTab,
    error: getCustomPerformanceError,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedyardPerformanceCard);
