import ACTION_CONSTANTS from '../../constants/actionConstants/index';

export const initialState = {
  isLoading: false,
  loadingText: '',
  programManuals: [],
  query: '',
  tags: [],
  filterUsers: [],
  focusedProgramManual: {},
  programManual: [],
  activities: [],
  visibleToUsers: [],
  selectedTags: [],
  deprecatedProgramManuals: [],
  missedProgramManuals: [],
  isCreated: false,
  isUpdated: false,
  isViewed: false,
  isRestored: false,
  isBinned: false,
  documents: [],
  assignedDocuments: [],
  isEmailed: false,
  error: {},
  numberOfMissedTraining: 0,
  isDetailLoading: false,
  updateTrainingError: false,
};

const programManuals = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_CONSTANTS.RESET_PROGRAM_MANUAL_DETAIL: {
      return {
        ...state,
        programManual: [],
      };
    }

    case ACTION_CONSTANTS.GET_PROGRAM_MANUAL: {
      // console.log('PROGRAM_MANUAL');
      const update = action.payload.update;
      const obj = {
        ...state,
      };
      if (update) {
        obj.isLoading = true;
      }
      return obj;
    }

    case ACTION_CONSTANTS.GET_PROGRAM_MANUAL_SUCCESS: {
      // console.log('PROGRAM_MANUAL_SUCCESS');
      const update = action.payload.update;
      const obj = {
        ...state,
        programManuals: action.payload.data,
      };
      if (update) {
        obj.isLoading = false;
      }
      return obj;
    }

    case ACTION_CONSTANTS.GET_PROGRAM_MANUAL_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ACTION_CONSTANTS.SET_MISSED_TRAINING: {
      return {
        ...state,
        numberOfMissedTraining: action.payload,
      };
    }

    case ACTION_CONSTANTS.SEARCH_PROGRAM_MANUAL: {
      // console.log('SEARCH_PROGRAM_MANUAL');
      return {
        ...state,
        query: action.payload,
      };
    }

    case ACTION_CONSTANTS.GET_PROGRAM_MANUAL_TAG: {
      // console.log('GET_PROGRAM_MANUAL_TAG');
      return {
        ...state,
        tags: action.payload || [],
      };
    }

    case ACTION_CONSTANTS.SET_CURRENT_PROGRAM_MANUAL: {
      return {
        ...state,
        focusedProgramManual: action.payload,
      };
    }

    case ACTION_CONSTANTS.GET_PROGRAM_MANUAL_BY_ID: {
      // console.log('GET_PROGRAM_MANUAL_BY_ID');
      return {
        ...state,
        isDetailLoading: true,
      };
    }

    case ACTION_CONSTANTS.GET_PROGRAM_MANUAL_BY_ID_SUCCESS: {
      // console.log('GET_PROGRAM_MANUAL_BY_ID_SUCCESS');
      return {
        ...state,
        isDetailLoading: false,
        programManual: action.payload,
        visibleToUsers: action.payload.users.map(function (item) {
          return {
            key: item.user_id,
            value: item.profile?.first_name + ' ' + item.profile?.last_name,
            isSelect: true,
          };
        }),
        selectedTags: action.payload.document_tags
          ? action.payload.document_tags.split(',').map(function (item) {
            return {
              key: item,
              value: state.tags.find((m) => m.key === Number(item))?.value,
              isSelect: true,
            };
          })
          : [],
      };
    }

    case ACTION_CONSTANTS.GET_PROGRAM_MANUAL_BY_ID_FAILURE: {
      return {
        ...state,
        isDetailLoading: false,
      };
    }

    case ACTION_CONSTANTS.GET_PROGRAM_MANUAL_ACTIVITIES_BY_ID: {
      // console.log('GET_PROGRAM_MANUAL_ACTIVITIES_BY_ID');
      return {
        ...state,
        isLoading: true,
      };
    }

    case ACTION_CONSTANTS.GET_PROGRAM_MANUAL_ACTIVITIES_BY_ID_SUCCESS: {
      // console.log('GET_PROGRAM_MANUAL_ACTIVITIES_BY_ID_SUCCESS');
      return {
        ...state,
        activities: action.payload,
        isLoading: false,
      };
    }

    case ACTION_CONSTANTS.GET_PROGRAM_MANUAL_ACTIVITIES_BY_ID_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ACTION_CONSTANTS.SET_VISIBLE_TO_USERS: {
      // console.log('SET_VISIBLE_TO_USERS');
      return {
        ...state,
        visibleToUsers: action.payload,
      };
    }

    case ACTION_CONSTANTS.SET_SELECTED_TAGS: {
      // console.log('SET_SELECTED_TAGS');
      return {
        ...state,
        selectedTags: action.payload,
      };
    }

    case ACTION_CONSTANTS.CREATE_TRAINING_REQUEST: {
      // console.log('CREATE_TRAINING_REQUEST');
      return {
        ...state,
        isCreated: false,
        updateTrainingError: false,
      };
    }

    case ACTION_CONSTANTS.CREATE_TRAINING: {
      // console.log('CREATE_TRAINING');
      return {
        ...state,
        isDetailLoading: true,
        isCreated: false,
        updateTrainingError: false,
      };
    }

    case ACTION_CONSTANTS.CREATE_TRAINING_SUCCESS: {
      // console.log('CREATE_TRAINING_SUCCESS');
      return {
        ...state,
        isDetailLoading: false,
        isCreated: true,
        updateTrainingError: false,
      };
    }

    case ACTION_CONSTANTS.CREATE_TRAINING_FAILURE: {
      // console.log('CREATE_TRAINING_FAILURE');
      return {
        ...state,
        error: action.payload,
        isDetailLoading: false,
        isCreated: false,
        updateTrainingError: action.payload.error,
      };
    }

    case ACTION_CONSTANTS.UPDATE_TRAINING_REQUEST: {
      // console.log('UPDATE_TRAINING_REQUEST');
      return {
        ...state,
        isUpdated: false,
        updateTrainingError: false,
      };
    }

    case ACTION_CONSTANTS.UPDATE_TRAINING: {
      // console.log('UPDATE_TRAINING');
      return {
        ...state,
        isDetailLoading: true,
        isUpdated: false,
        updateTrainingError: false,
      };
    }

    case ACTION_CONSTANTS.UPDATE_TRAINING_SUCCESS: {
      // console.log('UPDATE_TRAINING_SUCCESS');
      return {
        ...state,
        isDetailLoading: false,
        isUpdated: true,
        updateTrainingError: false,
      };
    }

    case ACTION_CONSTANTS.UPDATE_TRAINING_FAILURE: {
      // console.log('UPDATE_TRAINING_FAILURE');
      return {
        ...state,
        isDetailLoading: false,
        isUpdated: false,
        updateTrainingError: action.payload.error,
      };
    }

    case ACTION_CONSTANTS.RESET_CREATE_TRAINING: {
      return {
        ...state,
        isCreated: false,
        isUpdated: false,
        isRestored: false,
        isBinned: false,
        isEmailed: false,
        error: {},
        query: '',
        focusedProgramManual: {},
        programManual: [],
        activities: [],
        visibleToUsers: [],
        selectedTags: [],
        deprecatedProgramManuals: [],
        missedProgramManuals: [],
        filterUsers: [],
        isLoading: false,
        isDetailLoading: false,
        updateTrainingError: false,
      };
    }

    case ACTION_CONSTANTS.RESET_ERROR_TRAINING: {
      return {
        ...state,
        isCreated: false,
        isUpdated: false,
        isRestored: false,
        isBinned: false,
        isViewed: false,
        isEmailed: false,
        error: {},
        isLoading: false,
        isDetailLoading: false,
        updateTrainingError: false,
      };
    }

    case ACTION_CONSTANTS.RESET_FILTER_TRAINING: {
      return {
        ...state,
        filterUsers: [],
      };
    }

    case ACTION_CONSTANTS.RESET_SOP_PROPS: {
      return {
        ...state,
        filter: [],
        query: '',
      };
    }

    case ACTION_CONSTANTS.GET_MISSED_PROGRAM_MANUAL: {
      // console.log('GET_MISSED_PROGRAM_MANUAL');
      return {
        ...state,
        isLoading: true,
      };
    }

    case ACTION_CONSTANTS.GET_MISSED_PROGRAM_MANUAL_SUCCESS: {
      // console.log('GET_MISSED_PROGRAM_MANUAL_SUCCESS', action.payload);
      return {
        ...state,
        missedProgramManuals: action.payload,
        isLoading: false,
      };
    }

    case ACTION_CONSTANTS.GET_MISSED_PROGRAM_MANUAL_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ACTION_CONSTANTS.GET_DEPRECATED_PROGRAM_MANUAL: {
      // console.log('GET_DEPRECATED_PROGRAM_MANUAL');
      return {
        ...state,
        isLoading: true,
        error: false,
        deprecatedProgramManuals: []
      };
    }

    case ACTION_CONSTANTS.GET_DEPRECATED_PROGRAM_MANUAL_SUCCESS: {
      // console.log('GET_DEPRECATED_PROGRAM_MANUAL_SUCCESS', action.payload);
      return {
        ...state,
        deprecatedProgramManuals: action.payload,
        isLoading: false,
        error: false,
      };
    }

    case ACTION_CONSTANTS.GET_DEPRECATED_PROGRAM_MANUAL_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ACTION_CONSTANTS.MOVE_PROGRAM_MANUALS_TO_ACTIVE: {
      // console.log('MOVE_PROGRAM_MANUALS_TO_ACTIVE');
      return {
        ...state,
        isLoading: true,
        isRestored: false,
      };
    }

    case ACTION_CONSTANTS.MOVE_PROGRAM_MANUALS_TO_ACTIVE_SUCCESS: {
      // console.log('MOVE_PROGRAM_MANUALS_TO_ACTIVE_SUCCESS', action.payload);
      return {
        ...state,
        isRestored: true,
        isLoading: false,
      };
    }

    case ACTION_CONSTANTS.MOVE_PROGRAM_MANUALS_TO_ACTIVE_FAILURE: {
      return {
        ...state,
        isRestored: false,
        isLoading: false,
      };
    }

    case ACTION_CONSTANTS.MOVE_PROGRAM_MANUALS_TO_BIN: {
      // console.log('MOVE_PROGRAM_MANUALS_TO_BIN');
      return {
        ...state,
        isLoading: true,
        isBinned: false,
      };
    }

    case ACTION_CONSTANTS.MOVE_PROGRAM_MANUALS_TO_BIN_SUCCESS: {
      // console.log('MOVE_PROGRAM_MANUALS_TO_BIN_SUCCESS', action.payload);
      return {
        ...state,
        isBinned: true,
        isLoading: false,
      };
    }

    case ACTION_CONSTANTS.MOVE_PROGRAM_MANUALS_TO_BIN_FAILURE: {
      return {
        ...state,
        isBinned: false,
        isLoading: false,
        error: action.payload,
      };
    }

    case ACTION_CONSTANTS.MARK_VIEWED: {
      // console.log('MARK_VIEWED');
      return {
        ...state,
        isLoading: true,
        isViewed: false,
      };
    }

    case ACTION_CONSTANTS.MARK_VIEWED_SUCCESS: {
      // console.log('MARK_VIEWED_SUCCESS', action.payload);
      return {
        ...state,
        isLoading: false,
        isViewed: true,
      };
    }

    case ACTION_CONSTANTS.MARK_VIEWED_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isViewed: false,
      };
    }

    //#region GET_DOCUMENTS_REQUEST
    case ACTION_CONSTANTS.GET_DOCUMENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case ACTION_CONSTANTS.GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        documents: action.payload,
      };

    case ACTION_CONSTANTS.GET_DOCUMENTS_FAILURE:
      return { ...state, isLoading: false, error: action.payload.error };
    //#endregion

    case ACTION_CONSTANTS.EMAIL_REPORT: {
      // console.log('EMAIL_REPORT');
      return {
        ...state,
        isLoading: true,
        isEmailed: false,
      };
    }

    case ACTION_CONSTANTS.EMAIL_REPORT_SUCCESS: {
      // console.log('EMAIL_REPORT_SUCCESS', action.payload);
      return {
        ...state,
        isLoading: false,
        isEmailed: true,
      };
    }

    case ACTION_CONSTANTS.EMAIL_REPORT_FAILURE: {
      // console.log('EMAIL_REPORT_FAILURE');
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        isEmailed: false,
      };
    }

    case ACTION_CONSTANTS.DELETE_PROGRAM_MANUAL_URL: {
      // console.log('DELETE_PROGRAM_MANUAL_URL');
      return {
        ...state,
      };
    }

    case ACTION_CONSTANTS.DELETE_PROGRAM_MANUAL_URL_SUCCESS: {
      // console.log('DELETE_PROGRAM_MANUAL_URL_SUCCESS', action.payload);
      return {
        ...state,
      };
    }

    case ACTION_CONSTANTS.DELETE_PROGRAM_MANUAL_URL_FAILURE: {
      // console.log('DELETE_PROGRAM_MANUAL_URL_FAILURE', action.payload);
      return {
        ...state,
      };
    }

    case ACTION_CONSTANTS.UPDATE_PROGRAM_MANUAL_URL: {
      // console.log('UPDATE_PROGRAM_MANUAL_URL');
      return {
        ...state,
      };
    }

    case ACTION_CONSTANTS.UPDATE_PROGRAM_MANUAL_URL_SUCCESS: {
      // console.log('UPDATE_PROGRAM_MANUAL_URL_SUCCESS', action.payload);
      return {
        ...state,
      };
    }

    case ACTION_CONSTANTS.UPDATE_PROGRAM_MANUAL_URL_FAILURE: {
      // console.log('UPDATE_PROGRAM_MANUAL_URL_FAILURE', action.payload);
      return {
        ...state,
      };
    }

    case ACTION_CONSTANTS.FILTER_DOCUMENT_USERS: {
      // console.log('FILTER_DOCUMENT_USERS');
      return {
        ...state,
        filterUsers: action.payload,
      };
    }

    default:
      return state;
  }
};

export default programManuals;
