import React from 'react';
import getLanguageKey from '@progressivebeef/shared/src/services/languageService';
import ActivitiesHelper from '@progressivebeef/shared/src/helpers/ActivitiesHelper';
import { SUBMISSION_FIELD_TYPES } from '@progressivebeef/shared/src/constants/FormConstants';
import Label from './label';

export default ({
  data,
  field,
  fieldType,
  violated,
  fields,
  form: { values },
}) => {
  const locale = data.label.locale[getLanguageKey()];
  let value = field.value;

  if (fieldType === SUBMISSION_FIELD_TYPES.FORMULA) {
    if (field.value !== '...') {
      value = ActivitiesHelper.getFormulaFieldValue(
        values,
        field.value,
        fields,
        data.is_absolute,
      );
    }
  }

  // if (value === 0) {
  //   value = '...';
  // } else
  if (typeof value == 'number') {
    if (data.is_absolute) {
      value = Math.abs(value);
    }

    value = Number(value.toFixed(2));
  }

  return (
    <div
      className={`add-submission-view-info-box${
        data.violated ? ' violated' : ''
      }`}>
      <Label
        locale={locale}
        violated={violated}
        fullWidth={
          fieldType === SUBMISSION_FIELD_TYPES.FORMULA
            ? false
            : SUBMISSION_FIELD_TYPES.LABEL || SUBMISSION_FIELD_TYPES.PARAGRAPH
        }
        data={data}
      />
      <div className={'singleSelect'}>{value}</div>
    </div>
  );
};
