import React, { Component } from 'react';
import AppContainer from './../AppContainer';
import PBIcon from './../PBIcon';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import localization from '@progressivebeef/shared/src/localization/i18n';
import printJS from 'print-js';
import { globalHelper } from '@progressivebeef/shared/src/helpers';
import PdfViewer from '../PDFViewer';

class ViewDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
    };
  }

  async componentDidMount() {
    const { pdfSource } = this.props.location.state;

    let data = await globalHelper.getFileFromServer(
      pdfSource.uri,
      pdfSource.headers
    );

    this.setState({
      data: data
    });
  }

  render() {
    const { data } = this.state;
    return (
      <AppContainer
        title={localization[getLocale()].REPORT}
        iconHeader
        IconLeftHeader={this.renderLeftHeader}
        IconRightHeader={this.renderRightHeader}
        noncollapsible>
        <div className="prev-box">
          {data && (
            <PdfViewer iden="english" source={{ base64: data }} />
          )}
        </div>
      </AppContainer>
    );
  }

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          name={'chevron-left'}
          onPress={() => this.props.history.goBack()}
        />
      </div>
    );
  };


  onPrint = () => {
    const { data } = this.state;
    try {
      printJS({
        printable: data,
        type: 'pdf',
        base64: true,
      });
    } catch (e) {
      console.log('print error', e);
    }
  };

  renderRightHeader = () => {
    return (
      <div className="head-icon right-align">
        <PBIcon onPress={() => this.onPrint()} name={'print'} />
      </div>
    );
  };
}

export default ViewDocument;
