import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import FormButton from '../FormButton';
import PickerModal from '../PickerModal';

import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';
import {
  moveProgramManualToBinRequest,
  getProgramManualRequest,
} from '@progressivebeef/shared/src/store/actions/programMaunals';

import { showErrorAlert, showOfflineAlert } from '../Alerts';

class ProgramManualPickerModal extends PureComponent {
  navigateTo = (route) => {
    var { focusedProgramManual, history } = this.props;
    history.push(route, {
      currentProgramManual: focusedProgramManual,
    });
  };

  moveToBin = async () => {
    const { focusedProgramManual, onPressEvent } = this.props;
    if (window.navigator.onLine) {
      this.props.moveProgramManualToBin({
        documentNumber: focusedProgramManual.document_number,
        feedlotDocumentId: focusedProgramManual.feedlot_document_id,
      });
      onPressEvent();
    } else {
      showErrorAlert(localization[getLocale()].RESTORED_OFFLINE, [
        {
          text: localization[getLocale()].OK,
          onPress: () => {
            onPressEvent();
          },
        },
      ]);
    }
  };

  render() {
    const { modalVisible, onPressEvent, view } = this.props;
    return (
      <PickerModal
        show={modalVisible}
        onRequestClose={() => {
          onPressEvent();
        }}
      >
        <div>
          <FormButton
            title={
              localization[getLocale()].EDIT_DOCUMENT
              // getSelectedFeedlotTrainingRulesEnabled() === 0
              //   ? localization[getLocale()].EDIT_DOCUMENT
              //   : localization[getLocale()].EDIT_TRAINING
            }
            success
            onPressEvent={() => {
              onPressEvent();
              this.navigateTo(RouteConstants.ADD_DOCUMENT);
              // getSelectedFeedlotTrainingRulesEnabled() === 0
              //   ? this.navigateTo(RouteConstants.ADD_DOCUMENT)
              //   : this.navigateTo(RouteConstants.ADD_TRAINING);
            }}
          />
          <FormButton
            title={localization[getLocale()].MOVE_TO_BIN}
            success
            onPressEvent={this.moveToBin}
          />
          {!view && (
            <FormButton
              title={
                localization[getLocale()].VIEW_DOCUMENT
                // getSelectedFeedlotTrainingRulesEnabled() === 0
                //   ? localization[getLocale()].VIEW_DOCUMENT
                //   : localization[getLocale()].VIEW_TRAINING
              }
              success
              onPressEvent={() => {
                onPressEvent();
                if (navigator.onLine) {
                  this.navigateTo(RouteConstants.VIEW_DOCUMENT);
                } else {
                  showOfflineAlert();
                }
              }}
            />
          )}
        </div>
        <div className="cancel-btn-box">
          <FormButton
            title={localization[getLocale()].CANCEL}
            dark
            onPressEvent={() => {
              onPressEvent();
            }}
          />
        </div>
      </PickerModal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    moveProgramManualToBin: (payload) =>
      dispatch(moveProgramManualToBinRequest(payload)),
    getProgramManuals: (payload) => dispatch(getProgramManualRequest(payload)),
  };
};

const mapStateToProps = (state) => {
  const { focusedProgramManual } = state.programManuals;

  return {
    focusedProgramManual
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgramManualPickerModal);
