import ACTION_CONSTANTS from '../../constants/actionConstants';

export const initialState = {
  searchedUsers: [],
  users: [],
  assignLogUsers: [],
  searchableAssignLogUsers: [],
  isLoading: false,
  userLoading: false,
  query: '',
  searchText: '',
  userSaved: false,
  user: {},
  error: false,
  addEditUserError: false,
  success: false,
  isChangePinLoading: false,
  changePinSuccess: false,
  changePinError: false,
  isChangePasswordLoading: false,
  changePasswordSuccess: false,
  changePasswordError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_CONSTANTS.RESET_USER_PROPS: {
      return {
        ...state,
        searchText: '',
        query: '',
        userSaved: false,
        user: {},
        error: false,
        addEditUserError: false,
        success: false,
      };
    }

    case ACTION_CONSTANTS.GET_ASSIGN_LOGS_USERS_LIST_REQUEST: {
      return {
        ...state,
        isLoading: true,
        searchableAssignLogUsers: [],
      };
    }
    case ACTION_CONSTANTS.GET_ASSIGN_LOGS_USERS_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        assignLogUsers: action.payload,
        searchableAssignLogUsers: action.payload,
      };
    }
    case ACTION_CONSTANTS.GET_ASSIGN_LOGS_USERS_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ACTION_CONSTANTS.SEARCH_ASSIGN_LOGS_USERS_LIST_STORE: {
      return {
        ...state,
        query: action.payload,
      };
    }

    case ACTION_CONSTANTS.RESET_ASSIGN_LOG_SEARCH: {
      return {
        ...state,
        query: '',
      };
    }

    case ACTION_CONSTANTS.SEARCH_ASSIGN_LOGS_USERS_LIST_SUCCESS: {
      return {
        ...state,
        searchableAssignLogUsers: action.payload,
      };
    }

    case ACTION_CONSTANTS.UPDATE_SEARCH_ASSIGN_LOG_USERS: {
      return {
        ...state,
        searchableAssignLogUsers: action.payload.list.map((obj) => {
          if (obj.user_id === action.payload.user.user_id) {
            obj = action.payload.user;
          }
          return obj;
        }),
      };
    }

    //#region GET_USERS_REQUEST
    case ACTION_CONSTANTS.GET_USERS:
      return { ...state, userLoading: true, success: false, error: false };

    case ACTION_CONSTANTS.GET_USERS_SUCCESS:
      return {
        ...state,
        userLoading: false,
        users: action.payload,
      };

    case ACTION_CONSTANTS.GET_USERS_FAILURE:
      return { ...state, userLoading: false, error: action.payload.error };
    //#endregion

    //#region SEARCH_USERS_REQUEST
    case ACTION_CONSTANTS.SEARCH_USERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
        searchText: action.payload.searchText,
      };

    case ACTION_CONSTANTS.SEARCH_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        searchedUsers: action.payload.searchedUsers,
      };

    case ACTION_CONSTANTS.SEARCH_USERS_FAILURE:
      return { ...state, isLoading: false, error: action.payload.error };
    //#endregion

    //#region ADD_USER_REQUEST
    case ACTION_CONSTANTS.ADD_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
        userSaved: false,
        addEditUserError: false,
      };

    case ACTION_CONSTANTS.ADD_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload.user,
        userSaved: true,
      };

    case ACTION_CONSTANTS.ADD_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        addEditUserError: action.payload.error,
        userSaved: false,
      };
    //#endregion

    //#region ==== Change Pin ====
    case ACTION_CONSTANTS.CHANGE_PIN_REQUEST:
      return {
        ...state,
        isChangePinLoading: true,
        changePinSuccess: false,
        changePinError: false,
      };
    case ACTION_CONSTANTS.CHANGE_PIN_SUCCESS:
      return {
        ...state,
        isChangePinLoading: false,
        changePinSuccess: true,
      };
    case ACTION_CONSTANTS.CHANGE_PIN_FAILURE:
      return {
        ...state,
        isChangePinLoading: false,
        changePinError: action.payload.error,
      };
    //#endregion ==== Change Pin ====

    //#region ==== Change Password ====
    case ACTION_CONSTANTS.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isChangePasswordLoading: true,
        changePasswordSuccess: false,
        changePasswordError: false,
      };
    case ACTION_CONSTANTS.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isChangePasswordLoading: false,
        changePasswordSuccess: true,
      };
    case ACTION_CONSTANTS.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isChangePasswordLoading: false,
        changePasswordError: action.payload.error,
      };
    //#endregion ==== Change Password ====

    //#region ==== unlockuser ====
    case ACTION_CONSTANTS.UNLOCK_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case ACTION_CONSTANTS.UNLOCK_USER_SUCCESS:
      return {
        ...state,
        success: true,
        isLoading: false,
      };

    case ACTION_CONSTANTS.UNLOCK_USER_FAILURE:
      return { ...state, isLoading: false, error: action.payload.error };
    //#endregion ==== unlockuser ====

    default:
      return state;
  }
};
