import ActivitiesHelper from '@progressivebeef/shared/src/helpers/ActivitiesHelper';
import React, { PureComponent } from 'react';
// import PBIcon from '../../../../../components/PBIcon';
// import { Tooltip } from 'reactstrap';

export default class Label extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      tooltipOpen: false,
      id: null,
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    let ID = 'asda_' + Math.random().toString(36).substr(2, 9);
    this.setState({
      id: ID,
    });
  }

  toggle = () => {
    const { tooltipOpen } = this.state;

    this.setState({
      tooltipOpen: !tooltipOpen,
    });
  };

  setReady() {
    if (this.myRef.current) {
      this.setState({
        ready: true,
      });
    }
  }

  render() {
    const { id, tooltipOpen } = this.state;
    const { locale, isFlex } = this.props;

    return (
      id && (
        <>
          <div className="label-box">
            <div className="main-label">{locale.value}</div>

            {/* {locale.show_instruction === 1 && (
              <div className="icon">
                <div id={id}>
                  <PBIcon ref={this.myRef} name={'info-circle'} />
                </div>

                <Tooltip
                  isOpen={tooltipOpen}
                  placement="top"
                  target={id}
                  toggle={this.toggle}>
                  {locale.instruction}
                </Tooltip>
              </div>
            )} */}
          </div>
          {!isFlex && ActivitiesHelper.shouldShowInstruction(locale) && (
            <div className="instruction-label">{locale.instruction}</div>
          )}
        </>
      )
    );
  }
}
