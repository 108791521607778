// modules
import { put, takeLatest, call, select, delay } from 'redux-saga/effects';

import { getLocale } from '../services/storageService/GlobalData';
import localization from '../localization/i18n';

// DB managers
import AuditManager from '../database/reactDataManager/AuditManager';
import MetaDataManager from '../database/reactDataManager/MetaDataManager';

//services
import AuditService from '../services/api/audits';

//helpers
import AuditHelper from '../helpers/AuditHelper';
import { globalHelper } from '../helpers';

//Constants
import {
  getAudits,
  getAuditsSuccess,
  getAuditsFailure,
  searchAuditsFailure,
  searchAuditsSuccess,
  searchAuditNcsSuccess,
  searchAuditNcsFailure,
  setAuditStatusFailure,
  setAuditStatusSuccess,
  getQuestionCommentsFailure,
  sendCommentSuccess,
  sendCommentFailure,
  getQuestionCommentsSuccess,
  setCurrentAuditRequested,
  getAuditByIdFailure,
  getAuditByIdSuccess,
} from '../store/actions/audits';
import { auditStatus, MODULE_NAMES } from '../constants/AppConstants';
import ACTION_CONSTANTS from '../constants/actionConstants';
import metaDataDto from '../models/metaDataModel';

export const auditStoreRequest = (state) => state.audits;

export function* getAuditsAsync({ payload }) {
  try {
    console.log('GETTING AUDITS SYNC');
    const update = payload.update;
    const loading = payload.loading;
    if (loading) {
      yield put(getAudits({ update })); // if loading true then start audit loader
    }

    if (navigator.onLine && update) {
      try {
        let lastSynced = null; // lastsync time for syncing
        let auditMetaData = MetaDataManager.getMetaDataByModule(
          MODULE_NAMES.AUDIT_MODULE, // get audit last sync time
        );

        if (auditMetaData) {
          lastSynced = auditMetaData.lastSynced;
        }
        let auditsResp = yield call(AuditService.getAudits, lastSynced);

        let metaDataModel = metaDataDto({
          moduleName: MODULE_NAMES.AUDIT_MODULE,
          lastSynced: auditsResp.time,
        });
        MetaDataManager.saveMetaData(metaDataModel); // upsert audit metadata with new lastSync time

        let auditAssignments = auditsResp.data.audit_assignments;

        // if (auditAssignments.length > 0) {
        AuditManager.saveAudits(auditAssignments);
        // }
      } catch (e) {
        console.log('error in api call, returning data from db', e);
      }
    }

    yield call(searchAudits);
    console.log('DONE AUDITS SYNC');
    yield put(getAuditsSuccess());
  } catch (e) {
    yield put(getAuditsFailure({ error: e }));
  }
}

function* searchAudits() {
  try {
    console.log('SEARCHING AUDITS..');

    let auditStore = yield select(auditStoreRequest),
      { searchText, tabType } = auditStore;

    let audits = AuditManager.searchAudits(tabType, searchText);

    // if (tabType === 0) {
    let seperatedAudits = AuditHelper.auditsSeparatedModel(
      globalHelper.seperateDataByKey(audits, 'status', auditStatus.SUBMITTED),
      globalHelper.seperateDataByKey(
        audits,
        'status',
        auditStatus.SUBMITTED_TO_AUDITOR,
      ),
      globalHelper.seperateDataByKey(audits, 'status', auditStatus.CLOSED),
    );
    audits = seperatedAudits;
    // } else {
    //   audits = [
    //     {
    //       title: localization[getLocale()]['DUE_TODAY' + tabType],
    //       key: tabType,
    //       data: audits,
    //     },
    //   ];
    // }
    const data = {
      audits,
    };

    if (auditStore.audit && auditStore.audit.audit_assignment_id) {
      const selectedAuditStatus = _.find(
        audits,
        _.flow(
          _.property('data'),
          _.partialRight(_.some, {
            audit_assignment_id: auditStore.audit.audit_assignment_id,
          }),
        ),
      );
      yield put(
        setCurrentAuditRequested(
          _.find(selectedAuditStatus.data, [
            'audit_assignment_id',
            auditStore.audit.audit_assignment_id,
          ]),
        ),
      );
    }
    console.log('SEARCH AUDIT SUCCESS');

    yield put(searchAuditsSuccess(data));
  } catch (e) {
    console.log('search audits error', e);
    yield put(searchAuditsFailure({ error: e }));
  }
}

function* searchAuditNcs() {
  try {
    let auditStore = yield select(auditStoreRequest),
      { ncSearchText, audit } = auditStore;

    let auditNCs = AuditManager.searchAuditNCs(
      audit.audit_assignment_id,
      ncSearchText,
    );

    yield put(searchAuditNcsSuccess({ auditNCs }));
  } catch (e) {
    console.log('search audits error', e);
    yield put(searchAuditNcsFailure({ error: e }));
  }
}

function* setAuditStatus(action) {
  try {
    console.log('SETTING AUDIT STATUS');
    const { promise } = action.payload;
    let auditStore = yield select(auditStoreRequest),
      { auditAssignmentId, statusToUpdate } = auditStore;

    if (navigator.onLine) {
      //post status
      yield call(
        AuditService.setAuditStatus,
        auditAssignmentId,
        statusToUpdate,
      );
      // save in db
      let savedAudit = AuditManager.saveAudit(
        auditAssignmentId,
        statusToUpdate,
      );

      //so that main list is updated adter status change
      yield call(getAuditsAsync, {
        payload: { update: true },
      });

      console.log('SET AUDIT STATUS');
      yield put(
        setAuditStatusSuccess({
          audit: savedAudit,
          success: localization[getLocale()].AUDIT_SAVED_SUCCESSFULLY,
        }),
      );
      promise.resolve();
    } else {
      yield put(
        setAuditStatusFailure({
          error: localization[getLocale()].NO_INTERNET_CONNECTION,
        }),
      );
      promise.reject();
    }
  } catch (e) {
    if (!e.code) {
      e.message = localization[getLocale()].UNABLE_TO_SUBMIT_AUDIT;
    }
    yield put(
      setAuditStatusFailure({
        error: e,
      }),
    );
    promise.reject();
  }
}

function* getQuestionComments() {
  try {
    let auditStore = yield select(auditStoreRequest),
      { auditAssignmentId, sectionId, auditSectionQuestionId } = auditStore;

    let questionComments = AuditManager.getQuestionComments(
      auditAssignmentId,
      sectionId,
      auditSectionQuestionId,
    );

    yield put(getQuestionCommentsSuccess({ questionComments }));
  } catch (e) {
    yield put(getQuestionCommentsFailure({ error: e }));
  }
}

function* sendComment(action) {
  try {
    let auditStore = yield select(auditStoreRequest),
      { auditAssignmentId, auditSectionQuestionId, sectionId } = auditStore;
    let { comment } = action.payload.values;
    let { images, files } = action.payload;
    if (navigator.onLine) {
      let requestObj = AuditHelper.getCommentRequestObj(
        auditSectionQuestionId,
        comment,
        auditAssignmentId,
        images,
        files,
      );

      let response = yield call(AuditService.sendComment, requestObj);
      if (response && response.data) {
        let savedComment = response.data.audit_question_comment;
        AuditManager.saveComment(savedComment, sectionId);
        let auditStore = yield select(auditStoreRequest),
          { questionComments } = auditStore;
        questionComments.push(savedComment);
        yield put(sendCommentSuccess(questionComments));
      }
    } else {
      yield put(
        sendCommentFailure({
          error: localization[getLocale()].NO_INTERNET_CONNECTION,
        }),
      );
    }
  } catch (e) {
    console.log('send comment failed', e);
    if (!e.code) {
      e.message = localization[getLocale()].AN_ERROR_OCCURED;
    }
    yield put(sendCommentFailure({ error: e.message }));
  }
}

function* getAuditById(action) {
  try {
    let id = action.payload;
    let audit = AuditManager.getAuditById(id);
    yield delay(100);

    if (audit) {
      yield put(getAuditByIdSuccess(audit));
    } else {
      yield put(
        getAuditByIdFailure({
          error: localization[getLocale()].OLD_AUDIT_NOTIFICATION_TAP_ERROR,
        }),
      );
    }
  } catch (e) {
    console.log('getAuditById failed', e);
    if (!e.code) {
      e.message = localization[getLocale()].AN_ERROR_OCCURED;
    }
    yield put(getAuditByIdFailure({ error: e.message }));
  }
}

function* auditSaga() {
  yield takeLatest(ACTION_CONSTANTS.GET_AUDITS_REQUEST, getAuditsAsync);
  yield takeLatest(ACTION_CONSTANTS.SEARCH_AUDITS_REQUEST, searchAudits);
  yield takeLatest(ACTION_CONSTANTS.SEARCH_AUDIT_NCS_REQUEST, searchAuditNcs);
  yield takeLatest(ACTION_CONSTANTS.SET_AUDIT_STATUS_REQUEST, setAuditStatus);
  yield takeLatest(
    ACTION_CONSTANTS.GET_QUESTION_COMMENTS_REQUEST,
    getQuestionComments,
  );
  yield takeLatest(ACTION_CONSTANTS.SEND_COMMENT_REQUEST, sendComment);
  yield takeLatest(ACTION_CONSTANTS.GET_AUDIT_BY_ID_REQUEST, getAuditById);
}

export default auditSaga;
