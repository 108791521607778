import ACTION_CONSTANTS from '../../constants/actionConstants';

export const initialState = {
  loading: false,
  success: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_CONSTANTS.SEND_DIAGNOSTIC_EMAIL_REQUEST: {
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
      };
    }
    case ACTION_CONSTANTS.SEND_DIAGNOSTIC_EMAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
      };
    }
    case ACTION_CONSTANTS.SEND_DIAGNOSTIC_EMAIL_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    }

    //#region ==== clear local storage
    case ACTION_CONSTANTS.CLEAR_LOCAL_STORAGE_REQUEST: {
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
      };
    }
    case ACTION_CONSTANTS.CLEAR_LOCAL_STORAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
      };
    }
    case ACTION_CONSTANTS.CLEAR_LOCAL_STORAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    }
    //#endregion

    default:
      return state;
  }
};
