import React, { PureComponent } from 'react';
import FormButton from '../FormButton';
import PickerModal from '../PickerModal';
import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import {
  ACTIVITY_FREQUENCY,
  ACTIVITY_PERMISSIONS,
  userRoles,
} from '@progressivebeef/shared/src/constants/AppConstants';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';
import { globalHelper } from '@progressivebeef/shared/src/helpers';
import { connect } from 'react-redux';

class ActivityPickerModal extends PureComponent {
  navigateTo = (route) => {
    var { focusedActivity, history, percentageKey, section, backdated, date } =
      this.props;
    history.push(route, {
      currentActivity: focusedActivity,
      percentageKey,
      section,
      backdated,
      date,
    });
  };

  render() {
    const {
      user,
      focusedActivity,
      modalVisible,
      onPressEvent,
      setCurrentInstance,
    } = this.props;
    const isPermissionWorkerAndInstancedBased =
      focusedActivity.permission_key === ACTIVITY_PERMISSIONS.ASSIGNED_WORKER &&
      focusedActivity.instance_based;

    const roleId = Number(user.userRoleId);
    const shouldShowSchedule =
      roleId !== userRoles.INTERNAL_AUDITOR &&
      roleId !== userRoles.EXTERNAL_AUDITOR;

    const shouldShowAssign = shouldShowSchedule;

    return (
      <PickerModal
        show={modalVisible}
        onRequestClose={() => {
          onPressEvent();
          // this.props.setCurrentActivity({});
        }}>
        <div>
          {globalHelper.renderIf(!focusedActivity.instance_based)(
            <FormButton
              title={localization[getLocale()].VIEW_SUBMISSIONS}
              success
              onPressEvent={() => {
                onPressEvent();
                this.navigateTo(RouteConstants.ACTIVITY_SUBMISSIONS);
              }}
            />,
          )}
          {globalHelper.renderIf(
            !isPermissionWorkerAndInstancedBased && shouldShowAssign,
          )(
            <FormButton
              title={localization[getLocale()].ASSIGN}
              success
              onPressEvent={() => {
                onPressEvent();
                setCurrentInstance && setCurrentInstance(null);
                this.navigateTo(RouteConstants.ACTIVITY_ASSIGN_LOG);
              }}
            />,
          )}
          {globalHelper.renderIf(
            focusedActivity.frequency_key !== ACTIVITY_FREQUENCY.REQUIRED &&
              shouldShowSchedule,
          )(
            <FormButton
              title={localization[getLocale()].SCHEDULE}
              success
              onPressEvent={() => {
                onPressEvent();
                this.navigateTo(RouteConstants.ACTIVITY_SCHEDULE);
              }}
            />,
          )}

          {globalHelper.renderIf(focusedActivity.instance_based)(
            <FormButton
              title={localization[getLocale()].VIEW_INSTANCES}
              success
              onPressEvent={() => {
                onPressEvent();
                this.navigateTo(RouteConstants.ACTIVITY_INSTANCE);
              }}
            />,
          )}
        </div>
        <div className="cancel-btn-box">
          <FormButton
            title={localization[getLocale()].CANCEL}
            dark
            onPressEvent={() => {
              onPressEvent();
            }}
          />
        </div>
      </PickerModal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  const { user } = state.authentication;
  const { focusedActivity } = state.activities;

  return {
    user,
    focusedActivity,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActivityPickerModal);
