import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import getLanguageKey from '@progressivebeef/shared/src/services/languageService/index';
import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import { getUsersListRequest } from '@progressivebeef/shared/src/store/actions/user';
import Label from './label';
import PickerComponent from '../../../../../components/Picker';
import ActivitiesHelper from '@progressivebeef/shared/src/helpers/ActivitiesHelper';

class EmployeeField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: props.form.values[props.field.name],
    };
  }

  componentDidMount() {
    this.props.getUsers();
  }

  setFieldValue = (id) => {
    const { form, field } = this.props;
    form.setFieldValue(field.name, id);
    this.setState({ selectedOption: id });
  };

  render() {
    const { data, assignLogUsers, disabled } = this.props;
    const locale = data.label.locale[getLanguageKey()];

    return (
      <>
        <div
          className={`add-submission-view-info-box col-flex${
            data.violated ? ' violated' : ''
          }`}>
          <Label
            locale={locale}
            customLabelStyle={{ paddingVertical: 15 }}
            violated={data.violated}
            isFlex
          />
          {disabled && (
            <div className={'singleSelect'}>
              {ActivitiesHelper.getEmployeeName(
                assignLogUsers,
                Number(this.state.selectedOption),
              )}
            </div>
          )}
          {!disabled && (
            <PickerComponent
              selectedValue={Number(this.state.selectedOption)}
              onValueChange={this.setFieldValue}
              data={ActivitiesHelper.parseDataForPicker(
                assignLogUsers,
                'EmployeeList',
              )}
              placeholder={localization[getLocale()].PLEASE_SELECT}
            />
          )}
        </div>
        {ActivitiesHelper.shouldShowInstruction(locale) && (
          <div className="instruction-label add-submission-view-info-box-instruction instruction-fix-margin">
            {locale.instruction}
          </div>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (payload) => dispatch(getUsersListRequest(payload)),
  };
};

const mapStateToProps = (state) => {
  const { assignLogUsers } = state.user;
  return {
    assignLogUsers,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeField);
