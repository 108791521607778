import React, { Component } from 'react';
import { connect } from 'react-redux';
import './style.scss';
import PBIcon from '../PBIcon';

import { userRoles } from '@progressivebeef/shared/src/constants/AppConstants';

class URLCard extends Component {
  onCancelPress = () => {
    const { text, onCancelPress } = this.props;

    onCancelPress && onCancelPress(text);
  };

  onEditPress = () => {
    const { text, onEditPress } = this.props;

    onEditPress && onEditPress(text);
  };

  render() {
    let { text, user, view } = this.props;

    return (
      <div className="url-card-box">
        <div
          onClick={() => {
            if (!text.includes('http://') && !text.includes('https://')) {
              text = 'http://' + text;
            }
            // console.log(text);
            const win = window.open(text, '_blank');
            if (win != null) {
              win.focus();
            }
          }}
        >
          <p>{`${text}`}</p>
        </div>
        {view ? null : Number(user.userRoleId) === userRoles.FEEDLOT_MANAGER ||
          Number(user.userRoleId) === userRoles.TRAINER ||
          Number(user.userRoleId) === userRoles.PROGRAM_MANAGER ? (
            <div className="icon-box">
              <div className="edit-icon icon">
                <PBIcon onPress={this.onEditPress} active name={'pencil'} />
              </div>
              <div className="cancel-icon icon">
                <PBIcon
                  onPress={this.onCancelPress}
                  active
                  name={'times-circle'}
                />
              </div>
            </div>
          ) : null}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  const { user } = state.authentication;

  return {
    user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(URLCard);
