import { LOCAL_STORAGE_KEYS } from '../../constants/AppConstants';
import auditAssignmentModel from '../../models/auditAssignmentModel';
import submissionModel from '../../models/submissionModel';
import AuditManager from '../reactDataManager/AuditManager';
import { getLocalstorageItem, setLocalstorageItem } from '.';

class NotificationManager {
  //save recently missed activities notifications
  saveMissedActivitiesNotifications(notifications) {
    try {
      notifications = notifications || [];
      setLocalstorageItem(
        LOCAL_STORAGE_KEYS.MISSED_ACTIVITIES_NOTIFICATION,
        JSON.stringify(notifications),
      );
      return true;
    } catch (e) {
      console.log('saveMissedActivitiesNotificationsFailed', e);
      return false;
    }
  }

  //get recently missed activities notifications
  getMissedActivitiesNotifications() {
    let notifications =
      JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEYS.MISSED_ACTIVITIES_NOTIFICATION),
      ) || [];

    return notifications;
  }

  //clear recently missed activities notifications
  clearMissedActivitiesNotifications() {
    setLocalstorageItem(
      LOCAL_STORAGE_KEYS.MISSED_ACTIVITIES_NOTIFICATION,
      JSON.stringify([]),
    );
  }

  saveMobileNotifications(notifications) {
    try {
      let notificationArr =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.NOTIFICATIONS)) ||
        [];
      let notficiationToSave = [];
      let audits = [];

      for (let i in notifications) {
        if (notifications[i].submission) {
          let submission = submissionModel(notifications[i].submission);
          submission.submission_violation_history =
            notifications[i].submission.submission_violation_history;
          notifications[i].submission = submission;
        } else if (notifications[i].audit_assignment) {
          audits.push(auditAssignmentModel(notifications[i].audit_assignment));
        }
        notficiationToSave.push(notifications[i]);
      }

      AuditManager.saveAudits(audits);

      for (let i in notficiationToSave) {
        let noti = notficiationToSave[i];
        let notiIndex = notificationArr.findIndex(
          (q) => q.mobile_notification_id == noti.mobile_notification_id,
        );

        if (notiIndex != -1) {
          notificationArr[notiIndex] = noti;
        } else {
          notificationArr.push(noti);
        }
      }

      setLocalstorageItem(
        LOCAL_STORAGE_KEYS.NOTIFICATIONS,
        JSON.stringify(notificationArr),
      );
      return true;
    } catch (e) {
      console.log('saveMobileNotificationsFailed', e);
      return false;
    }
  }

  saveMobileNotificationsUpdates(notficiationToSave) {
    try {
      let notificationArr =
        JSON.parse(
          localStorage.getItem(LOCAL_STORAGE_KEYS.NOTIFICATIONS_UPDATE),
        ) || [];

      for (let i in notficiationToSave) {
        let noti = notficiationToSave[i];
        let notiIndex = notificationArr.findIndex(
          (q) => q.mobile_notification_id == noti.mobile_notification_id,
        );

        if (notiIndex != -1) {
          notificationArr[notiIndex] = noti;
        } else {
          notificationArr.push(noti);
        }
      }

      setLocalstorageItem(
        LOCAL_STORAGE_KEYS.NOTIFICATIONS_UPDATE,
        JSON.stringify(notificationArr),
      );
      return true;
    } catch (e) {
      console.log('saveMobileNotificationsUpdatesFailed', e);
      return false;
    }
  }

  getNotificationsUpdates() {
    let notificationArr =
      JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEYS.NOTIFICATIONS_UPDATE),
      ) || [];
    return notificationArr;
  }

  getMobileNotifications() {
    let notificationArr =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.NOTIFICATIONS)) || [];

    for (let i in notificationArr) {
      if (notificationArr[i].audit_assignment) {
        let audit = AuditManager.getAuditById(notificationArr[i].module_id);
        if (audit) notificationArr[i].audit_assignment.status = audit.status;
      }
    }
    return notificationArr;
  }
}

export default new NotificationManager();
