// modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './style.scss'

import { showOfflineAlert } from '../../../components/Alerts';

// methods
import { setFirstLoginRequested } from '@progressivebeef/shared/src/store/actions/authentication';

// constants
import localization from '@progressivebeef/shared/src/localization/i18n';
import {
  getLocale,
  getFirstLogin,
  getUserRole,
  setFirstLogin,
} from '@progressivebeef/shared/src/services/storageService/GlobalData';

import AssetConstants from '@progressivebeef/shared/src/constants/AssetConstants';
import { userRoles } from '@progressivebeef/shared/src/constants/AppConstants';

class Coachmark extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      coachmark: '',
      userRole: getUserRole() === userRoles.WORKER ? 'Worker' : 'Other',
    };
  }

  componentDidMount() {
    if (getFirstLogin()) {
      setFirstLogin(false);
    }
  }

  close = () => {
    this.props.history.goBack();
  };

  render() {
    const source =
      AssetConstants.coachmark[getLocale()][this.state.userRole].dashboard[
        this.state.currentIndex + 1
      ];
    return (
      <>
        <div className="coach-mark-box">
          <img alt="description" src={source} />
          <a className="control-btn close-btn" onClick={this.close}>
            {localization[getLocale()].CLOSE}
          </a>
          <div>
            {this.state.currentIndex > 0 && (
              <a className="control-btn prev-btn"
                onClick={() => {
                  if (navigator.onLine) {
                    this.setState({
                      currentIndex: this.state.currentIndex - 1,
                    });
                  } else {
                    showOfflineAlert();
                  }
                }}
              >
                {localization[getLocale()].PREVIOUS}
              </a>
            )}
            {this.state.currentIndex + 1 <
              Object.keys(
                AssetConstants.coachmark[getLocale()][this.state.userRole]
                  .dashboard
              ).length && (
              <a className="control-btn next-btn"
                onClick={() => {
                  if (navigator.onLine) {
                    this.setState({
                      currentIndex: this.state.currentIndex + 1,
                    });
                  } else {
                    showOfflineAlert();
                  }
                }}
              >
                {localization[getLocale()].NEXT}
              </a>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFirstLogin: (payload) => dispatch(setFirstLoginRequested(payload)),
  };
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Coachmark);
