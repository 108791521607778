export const FIELD_TYPES = {
  TEXT: 'text',
  CUSTOM_TEXT: 'custom_text',
  TEXT_BOX: 'text_box',
  TOUCHABLE_TEXT: 'touchable_text',
  DATE: 'date',
  MAP: 'map',
  PICKER: 'picker',
  CHECKBOX: 'checkbox',
  MONTH_YEAR: 'month_year',
  YEAR: 'year',
  SEARCH: 'search',
};

export const SUBMISSION_FIELD_TYPES = {
  TEXT_FIELD: 1,
  INTEGER: 2,
  SINGLE_SELECT: 3,
  MULTI_SELECT: 4,
  DECIMAL: 5,
  DATE_FIELD: 6,
  TEXT_AREA: 7,
  PARAGRAPH: 8,
  FORMULA: 9,
  EMPLOYEES: 10,
  ATTACHMENT: 11,
  LABEL: 12,
};

export const ATTACHMENT_TYPES = {
  PNG: 'png',
  JPEG: 'jpeg',
  JPG: 'jpg',
  PDF: 'pdf',
  PDF_WEB: 'application/pdf',
};
