import React, { Component } from 'react';
import App from './App';
import { Provider } from 'react-redux';
import { MixpanelProvider } from 'react-mixpanel';
import mixpanel from 'mixpanel-browser';

import createBrowserHistory from 'history/createBrowserHistory';
import store from '@progressivebeef/shared/src/store/react';
import PlatformService from '@progressivebeef/shared/src/services/plaform';
import AlertModal from './components/AlertModal';
import { Router } from 'react-router'

import { ToastContainer } from 'react-toastify';
import PbLoader from './components/PbLoader';

// export const history = createHistory();
export const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASE_NAME
})

export default class BootSetup extends Component {
  render() {
    PlatformService.isWeb = true;

    return (
      <>
        <Provider store={store} history={history}>
          <AlertModal></AlertModal>
          <MixpanelProvider mixpanel={mixpanel}>
            <ToastContainer className="customToast" hideProgressBar={true} />
            <PbLoader></PbLoader>
            {/* https://stackoverflow.com/questions/56707885/browserrouter-vs-router-with-history-push */}
            <Router history={history}>
              <App history={history} />
            </Router>
          </MixpanelProvider>
        </Provider>
      </>
    );
  }
}
