import React, { Component } from 'react';
import { connect } from 'react-redux';
import printJS from 'print-js';
import { Spinner } from 'reactstrap';
import './style.scss';

//custom comp
import AppContainer from '../../../../components/AppContainer';
import PBIcon from '../../../../components/PBIcon';
import PdfViewer from '../../../../components/PDFViewer';

import { globalHelper } from '@progressivebeef/shared/src/helpers';
import { showErrorAlert } from '../../../../components/Alerts';

class AuditReport extends Component {
  constructor(props) {
    super(props);
    const { currentAudit } = this.props.location.state;

    this.state = {
      isLoading: true,
      fileData: null,
      currentAudit
    };
  }

  componentDidMount() {
    let { pdfSource } = this.props.location.state;

    pdfSource && pdfSource.headers && this.getAuditReport();
  }

  getAuditReport = async () => {
    try {
      let { pdfSource } = this.props.location.state;

      let data = await globalHelper.getFileFromServer(
        pdfSource.uri,
        pdfSource.headers
      );

      this.setState({
        fileData: data,
        isLoading: false,
      });
    } catch (e) {
      console.log('renderAuditReport', e);
      showErrorAlert(e);
      this.setState({
        isLoading: false,
      });
    }
  };

  printAuditReport = async () => {
    let { fileData } = this.state;

    printJS({
      printable: fileData,
      type: 'pdf',
      base64: true,
    });
  };

  render() {
    let { currentAudit } = this.state;
    let { fileData, isLoading } = this.state;
    const { history } = this.props;

    return (
      <AppContainer
        title={currentAudit.audit.audit_number}
        iconHeader
        IconLeftHeader={this.renderLeftHeader}
        history={history}
        IconRightHeader={this.renderRightHeader}
        noncollapsible
        hideBottomBar
      >
        {isLoading ? (
          <div className="bottom-infinite-loader">
            <Spinner color={'red'} />
          </div>
        ) : (
            <div className="prev-box audit-report-prev">
              {fileData && <PdfViewer iden="english" source={{ base64: fileData }} />}
            </div>
          )}
      </AppContainer>
    );
  }

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          name={'chevron-left'}
          onPress={() => this.props.history.goBack()}
        />
      </div>
    );
  };

  renderRightHeader = () => {
    return (
      <div className="head-icon right-align">
        <PBIcon name={'print'} onPress={() => this.printAuditReport()} />
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditReport);
