//DB
import { LOCAL_STORAGE_KEYS, USER_STATUS } from '../../constants/AppConstants';
import { getLocalstorageItem, setLocalstorageItem } from '.';

class FeedLotUsersManager {
  //save FeedLotUsers
  saveUsers(UsersToSave) {
    try {
      let savedUsers =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USERS)) || [];
      for (let i = 0; i < UsersToSave.length; i++) {
        let savedUserIndex = savedUsers.findIndex(
          (q) => q.user_id == UsersToSave[i].user_id
        );
        if (savedUserIndex != -1) {
          savedUsers[savedUserIndex] = UsersToSave[i];
        } else {
          savedUsers.push(UsersToSave[i]);
        }
      }
      setLocalstorageItem(LOCAL_STORAGE_KEYS.USERS, JSON.stringify(savedUsers));
      return true;
    } catch (e) {
      console.log('saveUsersfailed', e);
      return false;
    }
  }

  saveUserInfo(user) {
    try {
      setLocalstorageItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(user));
      return true;
    } catch (err) {
      console.log(err);
    }
  }

  saveUser(user) {
    try {
      let users =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USERS)) || [];
      let userIndex = users.findIndex((q) => q.user_id == user.id);
      if (userIndex != -1) {
        users[userIndex] = user;
      } else {
        users.push(user);
      }
      this.saveUsers(users);
      return true;
    } catch (err) {
      console.log(err);
    }
  }

  getUserById(id) {
    let users =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USERS)) || [];

    users = users.find((q) => q.user_id == id);
    return users;
  }

  getActiveUsers() {
    let users =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USERS)) || [];

    users = users.filter((q) => q.status == 1).filter((q) => q.profile != null);

    users = users.sort((a, b) =>
      a.profile.first_name.localeCompare(b.profile.first_name)
    );

    return users || [];
  }

  searchUsers(status, searchValue) {
    try {
      let users =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USERS)) || [];

      if (status != USER_STATUS.LOCKED) {
        users = users.filter((q) => q.status == status);
      } else {
        users = users.filter((q) => q.is_locked == 1);
      }

      if (users.length > 0) {
        if (searchValue && searchValue != '') {
          users = users.filter((item) => {
            return (
              item.user_name
                .toLowerCase()
                .includes(searchValue.toLowerCase()) ||
              (item.profile.first_name + ' ' + item.profile.last_name)
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            );
          });
        }

        return users || [];
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  }
}

export default new FeedLotUsersManager();
