import React from 'react';
import { connect } from 'react-redux';
import './style.scss';

import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import localization from '@progressivebeef/shared/src/localization/i18n';

import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';
import {
  resetActivitiesProps,
  getActivitiesRequest,
} from '@progressivebeef/shared/src/store/actions/activities';

import { trackEvent } from '@progressivebeef/shared/src/services/reactMixpanelService';
import { Events } from '@progressivebeef/shared/src/constants/MixpanelConstants';

const ActivitiesDueTodayCard = (props) => {
  const {
    isLoading,
    activitiesDueToday,
    resetActivities,
    getActivities,
  } = props;
  // const containerStyle = {
  //   justifyContent: 'space-between',
  // };
  const color = isLoading ? 'rgba(210, 0, 0, 0.2)' : 'rgba(210, 0, 0, 1)';
  const dataColor = isLoading ? 'rgba(210, 0, 0, 0)' : 'rgba(210, 0, 0, 1)';
  const iconColor = isLoading
    ? { color: 'rgba(80, 45, 127, 0.2)' }
    : { color: 'rgba(80, 45, 127, 1)' };
  // const containerStyles = isLoading
  //   ? [styles.containerStyles, { height: normalize(60) }]
  //   : styles.containerStyles;
  const { total, dueToday } = activitiesDueToday;

  return (
    <div
      onClick={() => {
        trackEvent(Events.VIEWED_DUE_ACTIVITIES_TODAY);
        resetActivities();
        getActivities({ update: false }); // fetching activities after resetting
        props.history.push(RouteConstants.ACTIVITIES, {
          screen: RouteConstants.ACTIVITIES,
          initial: false,
        });
      }}
    >
      <div
        className={`activity-due-today-card${
          isLoading ? ' dasboard-card-opt-half' : ''
        }`}
      >
        {/* style={[containerStyles, containerStyle]} */}
        <>
          <div className="col-flex">
            {/* <p style={[styles.headingStyles, { color }]}> */}
            <div className="label">
              {localization[getLocale()].ACTIVITIES_DUE_TODAY}
            </div>
            {/* <p style={[styles.infoStyles, { color: dataColor }]}> */}
            <div className="num">{`(${dueToday}/${total})`}</div>
          </div>
        </>
      </div>
    </div>
  );
};

// const styles = {
//   cardStyles: {
//     marginTop: normalize(10),
//   },
//   containerStyles: {
//     flexDirection: 'row',
//     paddingTop: normalize(10),
//     paddingBottom: normalize(10),
//     borderColor: 'rgba(123,123,123,0.5)',
//     borderRadius: 4,
//     borderWidth: 0.3,
//   },
//   textContainerStyles: {
//     flexDirection: 'row',
//     flexWrap: 'wrap',
//     height: '100%',
//     alignItems: 'center',
//   },
//   headingStyles: {
//     fontSize: normalize(16),
//     fontFamily: commonFont.sfProSemiBold,
//     marginRight: normalize(10),
//   },
//   infoStyles: {
//     fontFamily: commonFont.sfProLight,
//     fontSize: normalize(25),
//     color: '#d20000',
//   },
//   iconStyles: {
//     fontSize: normalize(16),
//   },
// };

const mapDispatchToProps = (dispatch) => {
  return {
    resetActivities: (payload) => dispatch(resetActivitiesProps(payload)),
    getActivities: (payload) => dispatch(getActivitiesRequest(payload)),
  };
};

const mapStateToProps = (state) => {
  const { isLoading, dueToday } = state.activities;

  return {
    isLoading,
    activitiesDueToday: dueToday,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivitiesDueTodayCard);
