import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/src/stylesheets/datepicker.scss";

class BasicDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: new Date(this.props.value),
    };
  }

  onChange = (selectedDate) => {
    const { onChange } = this.props;
    this.setState({
      value: new Date(selectedDate),
    });
    onChange(selectedDate);
  };

  render() {
    const { minimumDate, disabled } = this.props;
    const { value } = this.state;

    return (
      <DatePicker
        disabled={disabled}
        selected={value}
        onChange={this.onChange}
        minDate={minimumDate}
        customInput={<DatePickerInput value={value} onClick={this.onChange} />}
        shouldCloseOnSelect={true}
      />
    );
  }

}

const DatePickerInput = ({ value, onClick }) => (
  <div onClick={onClick}>
    {value}
  </div>
);

export default BasicDatePicker;
