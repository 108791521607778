import React from 'react';
import PropTypes from 'prop-types';

const SettingOption = (props) => {
  const { onPress, title, rightComponent } = props;
  return (
    <div onClick={onPress}>
      <div className="change-pass-box">
        <div className="label">{title}</div>
        {rightComponent}
      </div>
    </div>
  );
};

SettingOption.propTypes = {
  onPress: PropTypes.func,
  title: PropTypes.string,
  rightComponent: PropTypes.any,
};

export default SettingOption;
