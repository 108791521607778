import React, { Component } from 'react';
import moment from 'moment';
import './style.scss';

import BubbleLabel from '../BubbleLabel';
import PBIcon from '../PBIcon';
import FormButton from '../FormButton';

import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

import {
  userRolesName,
  USER_STATUS,
  DATE_FORMATS,
} from '@progressivebeef/shared/src/constants/AppConstants';

class UserCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let {
      item,
      onCardPress,
      showDates,
      isLockedTab,
      onUnlockUserButtonPress,
      isAuditor,
    } = this.props;

    return (
      <div onClick={() => onCardPress && onCardPress(item)}>
        <div className="user-listing-box">
          <div className="user-info">
            <div className="name-col">
              <div className="name">
                {`${item.profile.first_name} ${item.profile.last_name}`}
              </div>
              <div className="lock-icon">
                {item.is_locked == 1 && !isLockedTab && (
                  <PBIcon name={'lock'} />
                )}
              </div>
            </div>
            <div className="designation">
              {localization[getLocale()]['USER_ROLE_' + item.user_role_key]}
            </div>
            {showDates && (
              <div>
                <div>
                  <PBIcon name={'clock'} />
                  <p>{moment(item.due_date).format(DATE_FORMATS.MM_DD_YY)}</p>
                </div>
                <div>
                  <PBIcon name={'calendar-check'} />
                  <p>{moment(item.viewed_by).format(DATE_FORMATS.MM_DD_YY)}</p>
                </div>
              </div>
            )}
          </div>
          {!isLockedTab ? (
            <div className="user-action">
              <BubbleLabel
                text={
                  item.status === USER_STATUS.ACTIVE
                    ? localization[getLocale()].ACTIVE
                    : localization[getLocale()].INACTIVE
                }
              />
            </div>
          ) : (
            !isAuditor && (
              <div className="unlock-btn-box">
                <FormButton
                  title={localization[getLocale()].UNLOCK}
                  success
                  onPressEvent={(e) => {
                    e.stopPropagation();
                    onUnlockUserButtonPress(item);
                  }}
                />
              </div>
            )
          )}
          {/* ----- 2nd row end ----- */}
        </div>
      </div>
    );
  }
}

export default UserCard;
