import { request, success, failure } from './index';
import ACTION_CONSTANTS from '../../constants/actionConstants';

export function getAppTrainingVideoHtml(payload) {
  return request(ACTION_CONSTANTS.GET_APP_TRAINING_VIDEO_HTML, payload);
}

export function getAppTrainingVideoHtmlRequest(payload) {
  return request(ACTION_CONSTANTS.GET_APP_TRAINING_VIDEO_HTML_REQUEST, payload);
}

export function getAppTrainingVideoHtmlSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_APP_TRAINING_VIDEO_HTML_SUCCESS, payload);
}

export function getAppTrainingVideoHtmlFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_APP_TRAINING_VIDEO_HTML_FAILURE, payload);
}

export function resetAppTrainingVideoState(payload) {
  return request(ACTION_CONSTANTS.RESET_APP_TRAINING_VIDEO_STATE, payload);
}
