import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import './style.scss';

import AppContainer from '../../../../components/AppContainer';
import PBIcon from '../../../../components/PBIcon';
import ListItem from './ListItem';
import Filter from './filter';

import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

import {
  getPerformanceActivitiesRequest,
  searchPerformanceActivities,
  clearPerformanceProps,
  setSelectedPerformanceTab,
} from '@progressivebeef/shared/src/store/actions/activities';
import AssetConstants from '@progressivebeef/shared/src/constants/AssetConstants';
import { PERFORMANCE_DATA_KEYS_MAP } from '@progressivebeef/shared/src/constants/AppConstants';

class ActivityPerformance extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.selectedTab 
        ? props.selectedTab 
        : props.location.state
        ? '' + props.location.state.index
        : '' + 0,
      showFilter: false,
    };
  }

  toggle = (tab) => {
    this.setState({
      activeTab: '' + tab,
    });
  };

  componentDidUpdate(prevProps) {
    //update when activities are synced
    if (prevProps.isLoading && !this.props.isLoading) {
      this.props.getPerformanceActivities();
    }
  }

  componentDidMount() {
    this.props.getPerformanceActivities();
  }

  render() {
    const {
      performanceActivities,
      feedlot_activites,
      customPerformanceActivities,
      isPerformanceActivitiesLoading,
      history,
      performanceQuery,
    } = this.props;
    const { activeTab, showFilter } = this.state;

    return (
      <>
        <AppContainer
          iconHeader
          title={localization[getLocale()].PERFORMANCE}
          onSearchPress={this.searchActivities}
          searchText={performanceQuery}
          IconRightHeader={this.renderRightHeader}
          IconLeftHeader={this.renderLeftHeader}
          history={history}
        >
          {showFilter && (
            <Filter
              modalVisible={showFilter}
              dismissModal={() => this.setState({ showFilter: false })}
              history={history}
              filterOptions={[
                { key: 'All', value: 'All', isSelect: false },
                { key: 'No Tag', value: 'No Tag', isSelect: false },
              ]}
              fromPerformance={true}
            />
          )}
          <div className="tab-screen-parent-container">
            <div id="scrollabl" className="scrollmenu nav-tabs">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '0' })}
                  onClick={() => {
                    this.toggle('0');
                    this.props.setSelectedPerformanceTab('0');
                  }}
                >
                  {localization[getLocale()]._DAILY}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    this.toggle('1');
                    this.props.setSelectedPerformanceTab('1');
                  }}
                >
                  {localization[getLocale()].THIS_WEEK}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    this.toggle('2');
                    this.props.setSelectedPerformanceTab('2');
                  }}
                >
                  {localization[getLocale()]._WEEKLY}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => {
                    this.toggle('3');
                    this.props.setSelectedPerformanceTab('3');
                  }}
                >
                  {localization[getLocale()].THIS_MONTH}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '4' })}
                  onClick={() => {
                    this.toggle('4');
                    this.props.setSelectedPerformanceTab('4');
                  }}
                >
                  {localization[getLocale()]._MONTHLY}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '5' })}
                  onClick={() => {
                    this.toggle('5');
                    this.props.setSelectedPerformanceTab('5');
                  }}
                >
                  {localization[getLocale()].CURRENT_YEAR}
                </NavLink>
              </NavItem>
              {customPerformanceActivities && customPerformanceActivities.map((o) => o.data).flat().length > 0 &&
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '6' })}
                    onClick={() => {
                      this.toggle('6');
                      this.props.setSelectedPerformanceTab('6');
                    }}
                  >
                    {localization[getLocale()].CUSTOM}
                  </NavLink>
                </NavItem>
              }
            </div>

            <div className="tab-screen-scrollable-container">
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="0">
                  <ListItem
                    tabType={this.props.location.state.filter}
                    {...this.props}
                    history={history}
                    isLoading={isPerformanceActivitiesLoading}
                    percentageKey={PERFORMANCE_DATA_KEYS_MAP.daily}
                    data={
                      performanceActivities[PERFORMANCE_DATA_KEYS_MAP.daily]
                    }
                  />
                </TabPane>
                <TabPane tabId="1">
                  <ListItem
                    tabType={this.props.location.state.filter}
                    {...this.props}
                    history={history}
                    isLoading={isPerformanceActivitiesLoading}
                    percentageKey={PERFORMANCE_DATA_KEYS_MAP.thisWeek}
                    data={
                      performanceActivities[PERFORMANCE_DATA_KEYS_MAP.thisWeek]
                    }
                  />
                </TabPane>
                <TabPane tabId="2">
                  <ListItem
                    tabType={this.props.location.state.filter}
                    {...this.props}
                    history={history}
                    isLoading={isPerformanceActivitiesLoading}
                    percentageKey={PERFORMANCE_DATA_KEYS_MAP.weekly}
                    data={
                      performanceActivities[PERFORMANCE_DATA_KEYS_MAP.weekly]
                    }
                  />
                </TabPane>
                <TabPane tabId="3">
                  <ListItem
                    tabType={this.props.location.state.filter}
                    {...this.props}
                    history={history}
                    isLoading={isPerformanceActivitiesLoading}
                    percentageKey={PERFORMANCE_DATA_KEYS_MAP.thisMonth}
                    data={
                      performanceActivities[PERFORMANCE_DATA_KEYS_MAP.thisMonth]
                    }
                  />
                </TabPane>
                <TabPane tabId="4">
                  <ListItem
                    tabType={this.props.location.state.filter}
                    {...this.props}
                    history={history}
                    isLoading={isPerformanceActivitiesLoading}
                    percentageKey={PERFORMANCE_DATA_KEYS_MAP.monthly}
                    data={
                      performanceActivities[PERFORMANCE_DATA_KEYS_MAP.monthly]
                    }
                  />
                </TabPane>
                <TabPane tabId="5">
                  <ListItem
                    tabType={this.props.location.state.filter}
                    {...this.props}
                    history={history}
                    isLoading={isPerformanceActivitiesLoading}
                    percentageKey={PERFORMANCE_DATA_KEYS_MAP.cumulative}
                    data={
                      performanceActivities[
                      PERFORMANCE_DATA_KEYS_MAP.cumulative
                      ]
                    }
                  />
                </TabPane>
                {customPerformanceActivities && customPerformanceActivities.map((o) => o.data).flat().length > 0 &&
                  <TabPane tabId="6">
                    <ListItem
                      tabType={this.props.location.state.filter}
                      {...this.props}
                      history={history}
                      isLoading={isPerformanceActivitiesLoading}
                      data={
                        feedlot_activites.length
                          ? customPerformanceActivities
                          : []
                      }
                    />
                  </TabPane>}
              </TabContent>
            </div>
          </div>
        </AppContainer>
      </>
    );
  }

  searchActivities = (queryString) => {
    this.props.searchActivities(queryString);
    this.props.getPerformanceActivities();
  };

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          name={'chevron-left'}
          onPress={() => {
            this.props.history.goBack();
            this.props.clearPerformanceProps();
          }}
        />
      </div>
    );
  };

  renderRightHeader = () => {
    const { performanceActivitiesFilter } = this.props;

    return (
      <div className="head-icon right-align">
        <div
          onClick={() =>
            this.setState({
              showFilter: true,
            })
          }
        >
          <img
            alt="desc"
            src={
              performanceActivitiesFilter &&
                performanceActivitiesFilter.length > 0
                ? AssetConstants.filterFilled
                : AssetConstants.filterUnfilled
            }
          />
        </div>
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPerformanceActivities: (payload) =>
      dispatch(getPerformanceActivitiesRequest(payload)),
    searchActivities: (payload) =>
      dispatch(searchPerformanceActivities(payload)),
    clearPerformanceProps: (payload) =>
      dispatch(clearPerformanceProps(payload)),
    setSelectedPerformanceTab: (payload) =>
      dispatch(setSelectedPerformanceTab(payload)),
  };
};

const mapStateToProps = (state) => {
  const {
    isLoading,
    performanceQuery,
    performanceActivitiesFilter,
    performanceActivities,
    isPerformanceActivitiesLoading,
    customPerformanceActivities,
    selectedTab,
  } = state.activities;
  const { user } = state.authentication;
  const { feedlot_activites } = state.dashboard;
  return {
    isLoading,
    performanceQuery,
    performanceActivitiesFilter,
    performanceActivities,
    user,
    isPerformanceActivitiesLoading: isPerformanceActivitiesLoading,
    feedlot_activites,
    customPerformanceActivities,
    selectedTab,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityPerformance);
