import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import './style.scss';

import {
  setCurrentActivity,
  getActivityInstancesRequest,
  deleteActivityInstancesRequest,
  addActivityInstancesRequest,
  setCurrentInstance,
  getActivitiesRequest,
} from '@progressivebeef/shared/src/store/actions/activities';
import {
  showLoader,
  hideLoader,
  setBottomBarTab,
} from '@progressivebeef/shared/src/store/actions/view';

import Separator from '../../../../components/Separator';
import AppContainer from '../../../../components/AppContainer';
import InstanceCard from '../../../../components/InstanceCard';
import FormButton from '../../../../components/FormButton';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';
import PickerModal from '../../../../components/PickerModal';
import PBIcon from '../../../../components/PBIcon';
import ActivityDetail from '../../../../components/ActivityDetail';
import {
  showSuccessAlert,
  showErrorAlert,
} from '../../../../components/Alerts';

import ActivitiesHelper from '@progressivebeef/shared/src/helpers/ActivitiesHelper';
import { globalHelper } from '@progressivebeef/shared/src/helpers';

import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

import {
  userRoles,
  ACTIVITY_PERMISSIONS,
  BOTTOM_BAR_TABS,
} from '@progressivebeef/shared/src/constants/AppConstants';

import InputDialog from './inputDialogue';
import ReactSectionList from 'react-sectionlist';

import { trackEvent } from '@progressivebeef/shared/src/services/reactMixpanelService';
import {
  Events,
  Properties,
} from '@progressivebeef/shared/src/constants/MixpanelConstants';
import languageService from '@progressivebeef/shared/src/services/languageService';

class InstanceList extends Component {
  constructor(props) {
    super(props);
    const { currentActivity } = this.props.location.state;
    this.state = {
      currentActivity,
      currentInstance: {},
      modalVisible: false,
      dialogValue: '',
      removeDialogVisible: false,
      addDialogVisible: false,
    };
  }

  componentDidMount() {
    if (
      this.state.currentActivity.instance_based &&
      this.props.instances.length == 0
    ) {
      this.props.setBottomBarTab(BOTTOM_BAR_TABS.ACTIVITIES);
      this.props.getActivities({ update: false });
    }
  }

  componentDidUpdate(prevProps) {
    let {
      error,
      isNonActivityLoading,
      showLoaderView,
      hideLoaderView,
      delInstanceSuccess,
      addInstanceSuccess,
      activities,
    } = this.props;

    let { currentActivity } = this.state;

    if (isNonActivityLoading) {
      showLoaderView();
    } else {
      hideLoaderView();
    }
    if (
      delInstanceSuccess &&
      delInstanceSuccess !== prevProps.delInstanceSuccess
    ) {
      showSuccessAlert(localization[getLocale()].REMOVE_INSTANCE_SUCCESS);
    }
    if (
      addInstanceSuccess &&
      addInstanceSuccess !== prevProps.addInstanceSuccess
    ) {
      let properties = {};

      properties[Properties.ACTIVITY_NAME] =
        currentActivity.locale[languageService()].title;
      properties[Properties.ACTIVITY_NUMBER] = currentActivity.activity_number;

      trackEvent(Events.INSTANCE_ADDED, properties);

      showSuccessAlert(localization[getLocale()].ADD_INSTANCE_SUCCESS);
    }
    if (error && error !== prevProps.error) {
      if (typeof error === 'string') {
        showErrorAlert(error);
      } else {
        showErrorAlert(localization[getLocale()].SOMETHING_WENT_WRONG);
      }
    }

    if (prevProps.activities.length == 0 && activities.length > 0) {
      const selectedActivityDuration = _.find(
        activities,
        _.flow(
          _.property('data'),
          _.partialRight(_.some, {
            feedlot_activity_id: this.state.currentActivity.feedlot_activity_id,
          }),
        ),
      );

      this.props.setCurrentActivity(
        _.find(selectedActivityDuration.data, [
          'feedlot_activity_id',
          this.state.currentActivity.feedlot_activity_id,
        ]),
      );
    }
  }

  getAlert(message = '', onPress = () => {}) {
    showSuccessAlert(message, [
      {
        text: localization[getLocale()].OK,
        onPress,
      },
    ]);
  }

  navigateTo = (route) => {
    const { currentInstance, currentActivity } = this.state;
    this.props.history.push(route, {
      currentInstance: currentInstance,
      currentActivity,
      bin: this.props.location.state.bin,
      backdated: this.props.location.state.backdated,
      date: this.props.location.state.date,
    });
  };

  onDelete = () => {
    const { currentInstance, dialogValue } = this.state;
    const { feedlot_activity_id, feedlot_activity_instance_id } =
      currentInstance;
    const payload = {
      feedlot_activity_instance: {
        feedlot_activity_id,
        feedlot_activity_instance_id,
        reason: dialogValue,
      },
    };
    this.props.deleteActivityInstace(payload);
  };

  onAdd = () => {
    const { currentActivity, dialogValue } = this.state;
    const { feedlot_activity_id } = currentActivity;
    const payload = {
      feedlot_activity_instance: {
        feedlot_activity_id,
        instance_value: dialogValue,
      },
    };
    this.props.addActivityInstace(payload);
  };

  onPressCard = () => {
    const { user } = this.props;
    const { bin } = this.props.location.state;
    if (Number(user.userRoleId) === userRoles.WORKER || bin) {
      this.navigateTo(RouteConstants.ACTIVITY_SUBMISSIONS);
    } else {
      this.setState({ modalVisible: true });
    }
  };

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          name={'chevron-left'}
          onPress={() => {
            this.props.history.goBack();
          }}
        />
      </div>
    );
  };

  renderRightHeader = () => {
    const { bin } = this.props.location.state;
    const { user } = this.props;
    const role = Number(user.userRoleId);
    if (
      role === userRoles.INTERNAL_AUDITOR ||
      role === userRoles.EXTERNAL_AUDITOR ||
      role === userRoles.WORKER ||
      bin
    ) {
      return null;
    }
    return (
      <div className="head-icon right-align">
        <PBIcon
          name={'plus'}
          onPress={() => this.setState({ addDialogVisible: true })}
        />
      </div>
    );
  };

  renderSectionHeader = (item) => {
    return <Separator title={item.title} />;
  };

  renderItem = (item) => {
    const { currentActivity } = this.state;
    const { custom_activities_instances } = this.props;
    const { percentageKey, backdated, date, section } =
      this.props.location.state;
    const instanceName =
      getLocale() === 'en'
        ? currentActivity.locale[1].instance_name
        : currentActivity.locale[2].instance_name;

    return (
      <InstanceCard
        {...this.props}
        key={item.feedlot_activity_instance_id}
        item={item}
        backdated={backdated}
        date={date}
        custom_activities_instances={custom_activities_instances}
        section={section}
        percentageKey={percentageKey}
        currentActivity={currentActivity}
        instanceName={instanceName}
        onPress={() => {
          this.setState({ currentInstance: item }, () => {
            if (item.status) {
              this.props.setCurrentInstance(item);
            }
            this.onPressCard();
          });
        }}
      />
    );
  };
  keyExtractor = (item, index) => item.title + index;

  render() {
    const {
      addDialogVisible,
      removeDialogVisible,
      modalVisible,
      currentInstance,
      currentActivity,
    } = this.state;
    const { user, instances, history } = this.props;
    const { backdated, date } = this.props.location.state;
    const isWorkerPermission =
      currentActivity.permission_key === ACTIVITY_PERMISSIONS.ASSIGNED_WORKER;
    const instanceName =
      getLocale() === 'en'
        ? currentActivity.locale[1].instance_name
        : currentActivity.locale[2].instance_name;
    const submissionExists = currentInstance.submission_exists;

    const roleId = Number(user.userRoleId);
    const isAuditor =
      roleId === userRoles.INTERNAL_AUDITOR ||
      roleId === userRoles.EXTERNAL_AUDITOR;

    return (
      <>
        {globalHelper.renderIf(!isAuditor)(
          <InputDialog
            dialogVisible={removeDialogVisible}
            title={localization[getLocale()].REMOVE_INSTANCE}
            description={
              submissionExists
                ? localization[getLocale()].REMOVE_INSTANCE_DIALOGUE
                : localization[getLocale()].REMOVE_INSTANCE_WITHOUT_REASON_LABEL
            }
            submissionExists={submissionExists}
            successBtnText={
              submissionExists == false
                ? localization[getLocale()].YES
                : localization[getLocale()].REMOVE
            }
            onInputChange={(dialogValue) => this.setState({ dialogValue })}
            onCancelPress={() =>
              this.setState({ removeDialogVisible: false, dialogValue: '' })
            }
            onSuccessPress={() => {
              if (!this.state.dialogValue && submissionExists) {
                showErrorAlert(localization[getLocale()].ERROR_REMOVE_INSTANCE);
              } else {
                this.setState({ removeDialogVisible: false }, () => {
                  this.onDelete();
                });
              }
            }}
          />,
        )}
        {globalHelper.renderIf(!isAuditor)(
          <InputDialog
            dialogVisible={addDialogVisible}
            title={localization[getLocale()].ADD_INSTANCE}
            description={localization[getLocale()].ADD_INSTANCE_DIALOGUE}
            successBtnText={localization[getLocale()].ADD_INSTANCE}
            onInputChange={(dialogValue) => {
              this.setState({ dialogValue });
            }}
            onCancelPress={() =>
              this.setState({ addDialogVisible: false, dialogValue: '' })
            }
            onSuccessPress={() => {
              if (!this.state.dialogValue) {
                showErrorAlert(localization[getLocale()].ERROR_ADD_INSTANCE);
              } else {
                this.setState({ addDialogVisible: false }, () => {
                  this.onAdd();
                });
              }
            }}
          />,
        )}
        <PickerModal
          show={modalVisible}
          onRequestClose={() => {
            this.setState({ modalVisible: false });
          }}>
          <FormButton
            title={localization[getLocale()].VIEW_SUBMISSIONS}
            success
            onPressEvent={() => {
              this.setState({ modalVisible: false });
              this.navigateTo(RouteConstants.ACTIVITY_SUBMISSIONS, {
                currentInstance,
                backdated: backdated,
                date: date,
              });
            }}
          />
          {globalHelper.renderIf(currentInstance.status)(
            <>
              {globalHelper.renderIf(isWorkerPermission && !isAuditor)(
                <FormButton
                  title={localization[getLocale()].ASSIGN}
                  success
                  onPressEvent={() => {
                    this.setState({ modalVisible: false });
                    this.navigateTo(RouteConstants.ACTIVITY_ASSIGN_LOG, {
                      currentInstance,
                    });
                  }}
                />,
              )}
              {globalHelper.renderIf(!isAuditor)(
                <FormButton
                  title={localization[getLocale()].REMOVE}
                  success
                  onPressEvent={() => {
                    this.setState({
                      removeDialogVisible: true,
                      modalVisible: false,
                    });
                  }}
                />,
              )}
            </>,
          )}
          <div className="cancel-btn-box">
            <FormButton
              title={localization[getLocale()].CANCEL}
              dark
              onPressEvent={() => {
                this.setState({ modalVisible: false });
              }}
            />
          </div>
        </PickerModal>
        <AppContainer
          noncollapsible
          title={instanceName}
          iconHeader
          history={history}
          IconLeftHeader={this.renderLeftHeader}
          IconRightHeader={this.renderRightHeader}>
          <div>
            <ActivityDetail activity={currentActivity} />
          </div>
          <ReactSectionList
            keyExtractor={this.keyExtractor}
            renderSectionHeader={this.renderSectionHeader}
            renderItem={this.renderItem}
            sections={ActivitiesHelper.parseInstancesSectionData(instances)}
          />
        </AppContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentActivity: (payload) => dispatch(setCurrentActivity(payload)),
    getActivityInstances: (payload) =>
      dispatch(getActivityInstancesRequest(payload)),
    deleteActivityInstace: (payload) =>
      dispatch(deleteActivityInstancesRequest(payload)),
    addActivityInstace: (payload) =>
      dispatch(addActivityInstancesRequest(payload)),
    setCurrentInstance: (payload) => dispatch(setCurrentInstance(payload)),
    showLoaderView: (payload) => dispatch(showLoader(payload)),
    hideLoaderView: (payload) => dispatch(hideLoader(payload)),
    setBottomBarTab: (payload) => dispatch(setBottomBarTab(payload)),
    getActivities: (payload) => dispatch(getActivitiesRequest(payload)),
  };
};

const mapStateToProps = (state) => {
  const {
    instances,
    isNonActivityLoading,
    error,
    delInstanceSuccess,
    addInstanceSuccess,
    activities,
  } = state.activities;
  const { user } = state.authentication;
  const { custom_activities_instances } = state.dashboard;

  return {
    instances,
    isNonActivityLoading,
    delInstanceSuccess,
    addInstanceSuccess,
    error,
    user,
    custom_activities_instances,
    activities,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InstanceList);
