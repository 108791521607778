import React, { PureComponent } from 'react';
import _ from 'lodash';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

class PickerComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: null,
      dropdownOpen: false,
    };
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  dropDownSelect = (option) => {
    const { onValueChange } = this.props;

    onValueChange(option);
    this.toggle();
  };

  render() {
    const { data, selectedValue, placeholder, disabled } = this.props;
    const { dropdownOpen } = this.state;
    return (
      <>
        <Dropdown isOpen={dropdownOpen} toggle={this.toggle}>
          <DropdownToggle caret disabled={disabled}>
            {selectedValue && data.length > 0
              ? _.find(data, ['value', selectedValue]) && _.find(data, ['value', selectedValue]).label
              : placeholder}
          </DropdownToggle>
          <DropdownMenu>
            {data.map((option) => {
              return (
                <DropdownItem header>
                  <div onClick={() => this.dropDownSelect(option.value)}>
                    {option.label}
                  </div>
                </DropdownItem>
                // <Picker.Item
                //   key={index}
                //   label={option.label}
                //   value={option.value}
                // />
              );
            })}
          </DropdownMenu>
        </Dropdown>

        {/* {Platform.OS === PLATFORM.IOS && (
          <View style={customStyles}>
            <TouchableOpacity
              onPress={() => {
                if (!disabled) {
                  this.setState({ showPicker: true });
                }
              }}
              onLongPress={() => {
                if (!disabled) {
                  this.setState({ showPicker: true });
                }
              }}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Text
                  style={[
                    placeholderStyle,
                    selectedValue && textStyle,
                    customStyles,
                  ]}>
                  {selectedValue && data.length > 0
                    ? _.find(data, ['value', selectedValue]).label
                    : placeholder}
                </Text>
                {icon && (
                  <PBIcon style={[textStyle]} active name={'chevron-down'} />
                )}
              </View>
            </TouchableOpacity>
            <Modal
              transparent={'true'}
              animationType={'none'}
              visible={this.state.showPicker}
              onRequestClose={() => {
                // console.log("close modal");
              }}>
              <View style={styles.containerStyles}>
                <View styles={styles.subContainer}>
                  <View style={styles.btnView}>
                    <TouchableOpacity
                      style={styles.btnCancelStyles}
                      onPress={() => this.setState({ showPicker: false })}
                      onLongPress={() => this.setState({ showPicker: false })}>
                      <Text style={styles.txtStyles}>
                        {localization[getLocale()].CANCEL}
                      </Text>
                    </TouchableOpacity>
                    <Text style={styles.textStyles}>
                      {localization[getLocale()].PLEASE_SELECT}
                    </Text>
                    <TouchableOpacity
                      style={styles.btnStyles}
                      onPress={() => {
                        this.setState({ showPicker: false });
                        onValueChange(selectedValue || data[0].value);
                      }}
                      onLongPress={() => {
                        this.setState({ showPicker: false });
                        onValueChange(selectedValue || data[0].value);
                      }}>
                      <Text style={styles.txtStyles}>
                        {localization[getLocale()].DONE}
                      </Text>
                    </TouchableOpacity>
                  </View>

                  <View style={styles.datePicker}>
                    <Picker
                      selectedValue={selectedValue}
                      onValueChange={(itemValue) =>
                        onValueChange(itemValue)
                      }>
                      {data.map((option, index) => {
                        return (
                          <Picker.Item
                            key={index}
                            label={option.label}
                            value={option.value}
                          />
                        );
                      })}
                    </Picker>
                  </View>
                </View>
              </View>
            </Modal>
          </View>
        )}

        {Platform.OS === PLATFORM.ANDROID && showAndroidPicker && (
          <View style={styles.pickerMainView}>
            <View style={styles.pickerMainSubView}>
              <View style={styles.pickerView}>
                <Picker
                  selectedValue={selectedValue}
                  enabled={!disabled}
                  style={[
                    styles.androidPicker,
                    {
                      marginLeft: this.state.marginLeft,
                      marginRight: -(30 + (marginRight || 0)),
                    },
                  ]}
                  onValueChange={(itemValue, itemIndex) => {
                    this.setState({
                      selectedOption: itemValue,
                      marginLeft: this.getPixel(data[itemIndex].label),
                    });
                    onValueChange(itemValue);
                  }}>
                  {data.map((option, index) => {
                    return (
                      <Picker.Item
                        key={index}
                        label={option.label}
                        value={option.value}
                      />
                    );
                  })}
                </Picker>
              </View>
              <PBIcon style={{ paddingTop: 5 }} active name={'caret-down'} />
            </View>
          </View>
        )}

        {Platform.OS === PLATFORM.ANDROID && !showAndroidPicker && (
          <Picker
            selectedValue={selectedValue}
            enabled={!disabled}
            style={[
              styles.androidPickerOld,
              disabled && { backgroundColor: 'white' },
            ]}
            onValueChange={(itemValue) => onValueChange(itemValue)}>
            {data.map((option, index) => {
              return (
                <Picker.Item
                  key={index}
                  label={option.label}
                  value={option.value}
                />
              );
            })}
          </Picker>
        )} */}
      </>
    );
  }
}

export default PickerComponent;
