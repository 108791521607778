import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import PBIcon from '../../../../components/PBIcon';
import NotificationHelper from '@progressivebeef/shared/src/helpers/NotificationsHelper';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';
import { DATE_FORMATS } from '@progressivebeef/shared/src/constants/AppConstants';

var click = true;

const NotificationUpdateItem = (props) => {
  const { item, history } = props;
  const date = moment(item.notification_create_datetime).format(
    DATE_FORMATS.MM_DD_YY,
  );
  const time = moment(item.notification_create_datetime).format(
    DATE_FORMATS.H_MM_A,
  );
  const { heading, subHeading, isRemovedUpdate } =
    NotificationHelper.getNotificationUpdateData(item);
  return (
    <div
      className="updated-notification-listing-box"
      onMouseDown={(e) => (click = true)}
      onMouseMove={(e) => (click = false)}
      onClick={() => {
        if (click) {
          history.replace(RouteConstants.ACTIVITIES);
        }
      }}>
      <div className="left-col">
        <div className="icon">
          <PBIcon name="file-alt" />
        </div>
        <div className="desc">
          <div>
            <div className={`label${isRemovedUpdate ? ' removed-item' : ''}`}>
              {heading}
            </div>
            <div className="submitted-by">{subHeading}</div>
          </div>
        </div>
      </div>
      <div className="right-col">
        <div>
          <div className="date-time-box">
            <div className="date-icon">
              <PBIcon name="calendar" />
            </div>
            <div className="date">{date}</div>
          </div>
          <div className="date-time-box">
            <div className="time-icon">
              <PBIcon name="clock" />
            </div>
            <div className="time">{time}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

NotificationUpdateItem.propTypes = {
  item: PropTypes.any,
  onPress: PropTypes.func,
};

export default NotificationUpdateItem;
