import { apiClient } from './index';
import { handleResponse, handleError } from './responseHandler';
import APIConstants from '../../constants/APIConstants';
import { getSelectedFeedlotId } from '../storageService/GlobalData';

class NotificationService {
  getMobileNotifications(userId, lastSynced, max_id = '', firstCall) {
    let params = {
      startIndex: 0,
      records: firstCall ? 30 : 10,
      sendCount: true,
    };
    let url = APIConstants.GET_MOBILE_NOTIFICATIONS.replace(
      '{{feedlot_id}}',
      getSelectedFeedlotId() || 1,
    ).replace('{{user_id}}', userId);

    if (lastSynced) {
      params.syncCall = true;
      params.time = lastSynced;
      params.records = 30;
    }

    if (max_id) {
      params.max_id = max_id;
    }

    return apiClient()
      .get(url, { params })
      .then(handleResponse)
      .catch(handleError);
  }

  getMobileUpdates(userId, lastSynced, max_id = '') {
    let params = {
      startIndex: 0,
      records: 10,
    };
    let url = APIConstants.GET_MOBILE_UPDATES.replace(
      '{{feedlot_id}}',
      getSelectedFeedlotId() || 1,
    ).replace('{{user_id}}', userId);

    if (lastSynced) {
      params.syncCall = true;
      params.time = lastSynced;
      params.records = 30;
    }

    if (max_id) {
      params.max_id = max_id;
    }

    return apiClient()
      .get(url, { params })
      .then(handleResponse)
      .catch(handleError);
  }
}

export default new NotificationService();
