import React from 'react';
import _ from 'lodash';

import getLanguageKey from '@progressivebeef/shared/src/services/languageService';
import Label from './label';
import { globalHelper } from '@progressivebeef/shared/src/helpers';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import localization from '@progressivebeef/shared/src/localization/i18n';
import { Input } from 'reactstrap';

export default ({
  data,
  name,
  disabled,
  replace,
  submissionNumber,
  specialProperty,
  form: { setFieldTouched, values },
}) => {
  const locale = data.label.locale[getLanguageKey()];

  const setFieldValue = (option) => {
    const index = _.findIndex(values[name], ['id', option.id]);
    option.value = !option.value;
    replace(index, option);
    setFieldTouched(data.group_id.toString(), true, true);
  };

  const renderView = () => {
    if (submissionNumber && specialProperty === 'pens' && !disabled) {
      const pensCleaned = [];
      const pensNotCleaned = [];

      values[name].map((option) => {
        if (option.submissionCount < submissionNumber) {
          pensNotCleaned.push(option);
        } else if (option.submissionCount >= submissionNumber) {
          pensCleaned.push(option);
        }
      });

      let view = [];
      if (pensNotCleaned.length > 0) {
        const cleanedPensText = (
          <div>
            {`${localization[getLocale()].PENS_NOT_CLEANED
              } ${globalHelper.numberToWords(submissionNumber)}`}
          </div>
        );
        view.push(cleanedPensText);

        const cleanedPens = pensNotCleaned.map((option) => {
          return (
            <div
              className="form-check-label"
              disabled={disabled}
              onClick={() => (disabled ? null : setFieldValue(option))}
            >
              <Input
                type="checkbox"
                checked={option.value}
                disabled={disabled}
              />
              <span className="checkmark"></span>
              <div className="label">{option.label}</div>
            </div>
          );
        });
        view.push(cleanedPens);
      }

      if (pensCleaned.length > 0 && pensNotCleaned.length > 0) {
        const separator = <div />;
        view.push(separator);
      }

      if (pensCleaned.length > 0) {
        const uncleanedPensText = (
          <div>
            {`${localization[getLocale()].PENS_ALREADY_CLEANED
              } ${globalHelper.numberToWords(submissionNumber)}`}
          </div>
        );
        view.push(uncleanedPensText);

        const uncleanedPens = pensCleaned.map((option) => {
          return (
            <div
              className="form-check-label"
              disabled={disabled}
              onClick={() => (disabled ? null : setFieldValue(option))}
            >
              <Input
                type="checkbox"
                checked={option.value}
                disabled={disabled}
              />
              <span className="checkmark"></span>
              <div className="label">{option.label}</div>
            </div>
          );
        });
        view.push(uncleanedPens);
      }

      return view;
    } else {
      return values[name] && values[name] != '' && values[name].map((option) => {
        return (
          <div
            className="form-check-label"
            disabled={disabled}
            onClick={() => (disabled ? null : setFieldValue(option))}
          >
            <Input type="checkbox" checked={option.value} disabled={disabled} />
            <span className="checkmark"></span>
            <div className="label">{option.label}</div>
          </div>
        );
      });
    }
  };

  return (
    <div className={`add-submission-view-info-box${data.violated ? ' violated' : ''}`}>
      <Label locale={locale} fullWidth={true} />
      <div>{renderView()}</div>
    </div>
  );
};
