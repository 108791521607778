export default {
  //#region general
  ERROR: 'Error',
  SUCCESS: 'Éxito',
  CONFIRMATION: 'Confirmación',
  YES: 'Sí',
  NO: 'No',
  OK: 'Ok',
  CANCEL: 'Cancelar',
  SUBMIT_FORM: 'Enviar',
  ENGLISH: 'English',
  SPANISH: 'Español',
  UPDATE: 'Actualizar',
  NEXT: 'Próximo',
  PREVIOUS: 'Anterior',
  CLOSE: 'Cerrar',
  SAVE: 'Guardar',
  PROCEED: 'Continuar',
  ALERT: 'Alerta',
  SUBMIT: 'Entregar',
  DATE: 'Fecha:',
  CONTINUE: 'Continuar',
  CREATE: 'Crear',
  PROGRESSIVE_BEEF: 'Progressive Beef',
  UNLOCK: 'Abrir',
  USE: 'Usar',
  TO: 'para',
  PLACEHOLDER_ENTER_HERE: 'Entrar aquí',
  SYNCING: 'Sincronización',
  //#endregion

  TOUCHID_SUPERSEDE:
    'Touch ID may supersede Face ID if both are enabled from device settings',
  DISABLE_TOUCHID:
    'To enable Face ID, please disable Touch ID from phone settings.',
  DELETE_VIDEO: 'Do you want to delete video?',

  //#region empty texts
  NO_PENDING_ACTIONS_AVAILABLE: 'No hay acciones pendientes',
  NO_PAGE_DATA_AVAILABLE: 'No Page Data Available!',
  NO_AUDITS_FOUND: 'No se encontró auditoria(s)',
  NO_AUDIT_NCS_FOUND: 'No se encontraros disconformidades de auditoria',
  //#endregion

  //#region routes header title
  LOGIN: 'INICIAR SESIÓN',
  FORGOT_PASSWORD: 'Olvidó Contraseña',
  FEEDYARD_LIST: 'Seleccione Empresas de engorda',
  DASHBOARD: 'Tablero',
  SOP: 'SOP',
  USERS: 'Usuarios',
  ACTIVITIES: 'Actividades',
  MORE: 'Más',
  AUDITS: 'Auditorías',
  NAVIGATION_VIDEOS: 'Videos de Entrenamiento',
  SETTINGS: 'Ajustes',
  SWITCH_FEEDYARD: 'Empresas de engorda',
  LOGOUT: 'Cierre de Sesión',
  NOTIFICATION: 'Notificación',
  NOTIFICATIONS: 'Notificacións',
  PENDING_ACTION: 'Informes Pendientes',
  GENERATE_PIN: 'Generar PIN',
  COACHMARK: 'Coachmark',
  ASSIGN_LOG: 'Asignar Bitácora',
  //#endregion

  //#region DASHBOARD
  FEEDLOT_PERFORMANCE: 'Desempeño de la Empresa',
  WORKER_PERFORMANCE: 'Desempeño del trabajador',
  PERFORMANCE: 'Actuación',
  CUSTOM: 'Personalizado',
  CHANGE_DATE: 'Cambiar fecha',
  VERSION: 'Versión',
  APP_UPGRADE_TEXT: 'Nueva versión disponible',
  NEW_VERSION_AVAILABLE: 'Nueva versión disponible',
  ACTIVITIES_DUE_TODAY: 'Actividades Para Hoy',
  WORKERS_ACTIVE_TODAY: 'Trabajadores activos hoy',
  EXPORT_COMPLIANCE: 'Cumplimiento de Exportación',
  REPORT: 'Informe',
  REPORT_EMAIL: 'Se ha procesado su solicitud correctamente.',
  YESTERDAY: 'Ayer',
  LAST_WEEK: 'La semana pasada',
  LAST_MONTH: 'El mes pasado',
  CURRENT_YEAR: 'Año vigente',
  THIS_WEEK: 'Esta Semana',
  THIS_MONTH: 'Este Mes',
  LOADING: 'Cargando contenido',
  NEVER_SIGNED_IN: 'Nunca inicio sesión',
  NO_ACTIVITIES_IN_DATE_RANGE:
    'No hay actividades entregados para los días seleccionados.',
  SELECT_DATE_RANGE_LABEL: 'Seleccione intervalo de fechas',
  CLEAR_STORAGE_ALL: 'Borra todo',
  CLEAR_STORAGE_2_WEEKS: '2 semanas',
  //#endregion

  //#region ==== NOTIFICATION
  NO_NOTIFICATION_AVAILABLE: 'No hay notificación disponible',
  NO_UPDATE_AVAILABLE: 'No update available',
  NOTIFICATION_REMINDER: 'Recordatorio de Actividad',
  NOTIFICATION_ACTIVITY_DUE_TODAY: 'Con fecha límite de hoy',
  NOTIFICATION_ACTIVITY_MISSED: 'Actividad faltante',
  NOTIFICATION_SUBMISSION_VOILATION: 'Existen violaciones',
  NOTIFICATION_DOCUMENT_READY: 'Documento listo para imprimir o descargar',
  NOTIFICATION_TRAINING_DUE_TODAY: 'Se puede consultar la práctica.',
  NOTIFICATION_TRAINING_VIEWED_BY: 'La práctica ha sido vista por',
  NOTIFICATION_TRAINING_MISSED: 'La práctica no ha sido vista por',
  NOTIFICATION_ACTIVITY_INSTANCE_ASSIGNED: 'Actividad asignada para instancia',
  NOTIFICATION_ACTIVITY_LOG8_FILLED: 'Actividad llena',
  NOTIFICATION_ACTIVITY_LOG8_COMPLETED: 'Actividad completada',
  NOTIFICATION_REVISION_AVAILABLE: 'Tiene una revisión actualizada',
  NOTIFICATION_ACTIVITY_ASSIGNED: 'Actividad asignada',
  NOTIFICATION_AUDIT_SUBMITTED: 'Una nueva auditoría ha sido presentada por',
  NEW_COMMENT_ADDED: 'A new comment has been added by',
  UPDATE_SUBMITTED_BY: 'Entregado por',
  UPDATE_ACTIVITY_ASSIGNED: 'Asignar a',
  UPDATE_SUBMISSION_REMOVED: 'Eliminado por',
  AUDIT_ASSIGNED: 'Auditoria asignado a Usted',
  AUDIT_REVIEWED: 'Auditoria revisada',
  REVIEW_REQUIRED: 'Revisión requerida',
  //#endregion ==== NOTIFICATION

  //#region login
  USERNAME: 'Ingrese su nombre de usuario',
  PIN: 'Ingrese su PIN',
  PASSWORD: 'Ingrese su contraseña',
  AUTHENTICATING: 'Autenticando...',
  ENTER_PASSWORD: 'Ingrese su contraseña',
  LOGIN_VIA_BIOMETRIC: 'iniciar sesión de Prog. Beef usando',
  UNLOCK_VIA_BIOMETRIC: 'Abrir usando ',
  //logout
  LOGOUT_CONFIRMATION: '¿Desea cerrar la sesión?',
  //#endregion

  //#region feedyards
  NO_FEEDYARDS_ASSIGNED: 'No está asignado a ninguna Empresa',
  SEARCHED_RESULTS: 'Resultados buscados',
  ALL_FEEDYARDS: 'Todas las engordas',
  FEEDLOT_SINGLE_ASSIGNMENT: 'Sólo se le ha asignado un corral de engorda.',
  //#endregion

  //#region forget PIN/password
  LOST_PIN_PASSWORD: '¿Olvidó su contraseña?',
  BACK_TO_LOGIN: 'Regresar a login',
  FORGET_PASSWORD_TEXT:
    'Ingrese su nombre de usuario para crear una nueva contraseña',
  //#endregion

  //#region GENERATE PIN
  GENERATE: 'Establezca un PIN',
  ENTER_PIN: 'Ingrese su PIN',
  CONFIRM_PIN: 'Ingrese nuevamente el Nuevo PIN',
  //#endregion

  //#region User
  USER_NAME: 'Nombre del Usuario',
  NAME: 'Nombre',
  EMAIL: 'Correo Electrónico',
  NO_USERS_FOUND: 'No se encontraron usuarios en este estado',
  FIRST_NAME: 'Nombre',
  LAST_NAME: 'Apellido',
  PASSWORD_LABEL: 'Contraseña',
  CONFIRM_PASSWORD: 'Confirmar Contraseña',
  ROLE: 'Labor que desempeña',
  STATUS: 'Estatus',
  APP_JOINGING_DATE: 'Fecha de ingreso a la aplicación',
  DOCUMENTS: 'Documentos',
  DOCUMENTS_ASSIGNED: 'Documentos Asignados',
  ASSIGN_DOCUMENTS: 'Asignados Documentos',
  SELECT_DOCUMENTS: 'Seleccione Documentos',
  ADD_USER: 'Agregar Usuario',
  EDIT_USER: 'Editar Usuario',
  ACCOUNT_DETAIL: 'Detalles de cuenta',
  PERSONAL_DETAILS: 'Detalles personales',
  ACCOUNT: 'Cuenta',
  //#endregion

  //#region audit
  NON_CONFORMANCE: 'Disconformidade',
  NON_CONFORMANCES: 'Disconformidades',
  CORRECTIVE_ACTIONS: 'Acciones correctivas',
  NC_DUE_DATE: 'Fecha de vencimiento',
  RECEIVED: 'Aceptado',
  CORRECTIVE_ACTIONS_DUE_DATE: 'Fecha de vencimiento de acción correctiva',
  CA_DUE_DATE: 'Fecha de vencimiento',
  CONFIRM_SUBMIT_TO_AUDITOR: 'Enviar al Auditor. ¿desea continuar?',
  RESOLVED: 'Resuelto',
  UNRESOLVED: 'Unresolved',
  DESCRIPTION: 'Descripción',
  NO_CA_PROVIDED: 'Ninguna acción correctiva proporcionada por el Auditor',
  COMMENT_PLACEHOLDER: 'Escriba su comentario aquí',
  CORRECTIVE_ACTION: 'Acciones correctiva',
  AUDIT_RESPONSE_REQUIRED: 'Respuesta de auditoria requerida',
  //audit status
  AUDIT_STATUS_dueToday: 'Se Vence Hoy',
  AUDIT_STATUS_dueThisWeek: 'Se Vence esta semana',
  AUDIT_STATUS_dueThisMonth: 'Se vence este mes',
  AUDIT_STATUS_overdue: 'Vencido',
  AUDIT_STATUS_1: 'Auditorias debidas',
  AUDIT_STATUS_2: 'En proceso',
  AUDIT_STATUS_3: 'Recibido del auditor',
  AUDIT_STATUS_4: 'Cerrado',
  AUDIT_STATUS_5: 'Revisión en proceso',
  AUDIT_STATUS_6: 'Respuesta requerida',
  AUDIT_STATUS_7: 'Revisado',
  AUDIT_STATUS_8: 'Enviado al revisor',
  AUDIT_STATUS_9: 'Enviado al auditor',
  AUDIT_STATUS_waitingForReview: 'Esperando revisión',
  AUDIT_CARD_STATUS_3: 'Aceptado',
  AUDIT_CARD_STATUS_4: 'Cerrado',
  AUDIT_CARD_STATUS_9: 'Enviado',
  //#endregion

  //#region settings
  PROFILE_SEND_DIAGNOSTICS_REPORT: 'Enviar reporte diagnostico',
  PLACEHOLDER_CURRENT_PASSWORD: 'Contraseña actual *',
  PLACEHOLDER_NEW_PASSWORD: 'Contraseña Nueva *',
  PLACEHOLDER_CONFIRM_PASSWORD: 'Confirmar Contraseña Nueva *',
  PLACEHOLDER_NEW_PIN: 'PIN nuevo *',
  PLACEHOLDER_CONFIRM_PIN: ' Confirme su PIN *',
  VIEW_COACHMARKS: 'ver marcas del entrenador',
  EMAIL_ALERTS: 'Email Alerts',
  LOGIN_OPTIONS: 'Opciones de inicio de sesión',
  TOUCH_ID_FACE_ID_DISABLE_CONFIRMATION:
    'Desactivará {{1}} y activará {{2}}. ¿desea continuar?',
  CHANGE_PIN: 'Cambiar el PIN',
  CHANGE_PASSWORD: 'Cambiar Contraseña',
  NEW_PIN: 'PIN nuevo',
  RE_ENTER_PIN: 'Ingrese nuevamente el Nuevo PIN',
  NEW_PASSWORD: 'Contraseña Nueva',
  RE_ENTER_PASSWORD: 'Contraseña Nueva',
  PASSWORD_LENGTH_ERROR: 'La contraseña debe tener 10 caracteres',
  SEND_DIAGNOSTIC_INFO: 'Envíe la información de diagnóstico',
  CLEAR_STORAGE_OPTION: 'Borra espacio de almacenamiento',
  CURRENT_PASSWORD: 'Contraseña actual',
  LOGOUT_CONFIRMATION_MSG: (numberOfPendingActions) =>
    `Tiene ${numberOfPendingActions} acciones pendientes. Si cierra la sesión se perderán. ¿Desea salir y perderlas?`,
  //#endregion

  //#region ACTIVITY
  ACTIVITY: 'Actividad',
  SCHEDULE: 'Calendario de actividades',
  DUE_DATE: 'Fecha De Vencimiento',
  REPEAT_EVERY: 'Repite Cada',
  REPEAT: 'Repetir',
  REMOVE_INSTANCE: 'Eliminar instancia',
  INSTANCE_DELETED_BY: 'Borrado Por:',
  REMOVE_INSTANCE_DIALOGUE:
    '¿Está seguro que desea eliminar esta instancia? Si es así por favor de la razón.',
  ADD_INSTANCE: 'Agregar Instancia',
  ADD_INSTANCE_DIALOGUE: 'Por favor agregue valor de instancia',
  ADD_INSTANCE_SUCCESS: 'Instancia añadida.',
  REMOVE_INSTANCE_SUCCESS: 'Instancia eliminada.',
  ERROR_REMOVE_INSTANCE:
    'Deberá ingresar una razón para eliminar esta instancia.',
  ERROR_ADD_INSTANCE: 'Deberá proveer el nombre de la instancia.',
  TIMELY_SUBMITTED: 'Entregado a tiempo',
  PARTIALLY_SUBMITTED: 'Parcialmente entregado',
  MONTHS_PROGRESS: 'El progreso de este mes',
  WEEKS_PROGRESS: 'El progreso de esta semana',
  DAY: 'Día',
  DONE: 'Hecho',
  PRINT: 'Impresión',
  START_DATE: 'Fecha de inicio',
  END_DATE: 'Fecha final',
  NO_SUBMISSION_FOUND: 'No hay informes entregados.',
  ACTIVITY_WORKERS: 'Actividad Laboral',
  ACTIVE: 'Activo',
  INACTIVE: 'Inactivo',
  SCHEDULE_SUBMISSION_COUNT_LABEL:
    'Deberá entregar un informe para cada día que esta actividad haya sido programada',
  NO_SUBMISSION_HISTORY:
    'No hay informes entregados para los días seleccionados.',
  REMOVED: 'Eliminado',
  SUBMITTED: 'Entregado',
  PENDING: 'Pendiente',
  REJECTED: 'Rehusado',
  MISSED: 'Faltante',
  REMOVE: 'Eliminado',
  SUBMITTED_DATE: 'Fecha de envió',
  //#endregion

  //#region ACTIVITIES
  ACTIVITY_BIN: 'Actividades eliminadas',
  SEARCH: 'Búsqueda',
  SELECT_TAGS: 'Seleccionar etiquetas',
  ASSIGNED_TAGS: 'Etiquetas asignadas',
  ALL: 'Todo',
  ASSIGNED: 'Asignado',
  UNASSIGNED: 'No Asignado',
  VIEW_SUBMISSIONS: 'Ver Informes Entregados',
  VIEW_INSTANCES: 'Ver instancias',
  ASSIGN: 'Asignar',
  HISTORY: 'Historia',
  SUBMISSIONS_PER: 'entrega(s) por ',
  REVISION_DATE: 'Fecha de Revisión',
  FEEDYARD_REVISION_DATE: 'Fecha de Revisión del Corral de Engorda',
  REQUIREMENTS: 'Requisitos',
  ASSIGNED_TO: 'Asignado a',
  ACTIVITY_TAGS: 'Etiquetas de Actividad',
  DUE_TODAY: 'Con fecha límite de hoy',
  DUE_WEEK: 'Con fecha límite de esta semana',
  DUE_MONTH: 'Con fecha límite de este mes',
  DUE_QUARTER: 'Con fecha límite de este trimestre',
  UPCOMING: 'Futuras',
  UNSCHEDULED: 'No programadas',
  MOVE_TO_BIN: 'Mover a la papelera',
  URL: 'URL',
  DETAILS: 'Detalles',
  NO_RECORD_FOUND: 'No hay información',
  NO_ACTIVITIES_FOUND: 'No se encontraron actividades',
  NO_ASSOSIATED_ACTIVITIES_FOUND: 'No existen actividades asociadas',
  NO_DOCUMENTS_FOUND:
    'No hay documento. Comuníquese con Progressive Beef o inténtelo más tarde.',
  NO_URL_FOUND: 'No hay información de URL que guardar o actualizar',
  INVALID_URL: 'URL no válida. Por favor, escriba la URL en un formato válido.',
  PLEASE_FILL_ALL_FIELDS: 'Favor de llenar todos los campos obligatorios.',
  EMPTY_URL_LIST_MSG: 'No existen URLs para esta documento',

  TITLE: 'Título',
  ENTER_URL: 'Ingresar URL',
  YEARLY: 'Anual',
  QUATERLY: 'Trimestral',
  MONTHLY: 'Mensual',
  WEEKLY: 'Semanal',
  DAILY: 'Diaria',

  SUNDAY: 'domingo',
  MONDAY: 'lunes',
  TUESDAY: 'mártes',
  WEDNESDAY: 'miércoles',
  THURSDAY: 'jueves',
  FRIDAY: 'viernes',
  SATURDAY: 'sábado',
  ONE_WEEK: '1 Semana',
  TWO_WEEKS: '2 Semanas',
  THREE_WEEKS: '3 Semanas',
  DAY_OF_THE_MONTH: 'Día de la semana',

  ATTACHMENT_MAX_SIZE_EXCEED:
    'No puede cargar el archivo. Excede el máximo de 5MB permisible.',
  ENTER_TEXT: 'Ingresar texto',
  MOVE_TO_ACTIVE: "Mover a 'Activo'",
  NO_MORE_RECORDS: 'No hay más archivos',
  EARLIER_END_DATE_ERROR:
    'La fecha de finalizado debe ser más tarde que la fecha de inicio',
  END_DATE_EARLIER_THAN_TODAY_ERROR: 'La fecha debe ser antes de hoy',
  EMAIL_REPORT: 'Enviar informe por correo electrónico',
  ENTER_NUMBER: 'Ingresar número',
  ENTER_DECIMAL: 'Ingresar decimal',
  _MONTHLY: 'Mensual',
  _DAILY: 'Ayer',
  _WEEKLY: 'Semanal',
  COMULATIVE: 'Acumulativo',
  TRAINING: 'Prácticas',
  EMPLOYEE: 'Empleado',
  SORT_BY: 'Ordenar por:',
  REPORT_EMAIL_SENT: 'Se ha procesado su solicitud correctamente.',
  ADD_TRAINING: 'Agregar práctica',
  EDIT_TRAINING: 'Editar práctica',
  VIEW_TRAINING: 'Ver capacitación',
  TRAINING_NUMBER: 'No. de práctica',
  TAGS_APPLICABLE_TRAINING: 'Etiquetas que se pueden aplicar a la práctica',
  TRAINING_FREQUENCY: 'Frecuencia de práctica',
  TRAINING_VISIBLE_AFTER: 'Práctica visible después de',
  NOTICATION_LEVEL: 'Nivel de notificación',
  TRAINING_VISIBLE_TO: 'La práctica es visible para',

  ONCE: 'Una vez',
  SIX_MONTHS: 'Seis meses',
  ONE_YEAR: 'Un año',
  NUMBER_OF_DAYS: 'No. de días',

  FEEDLOT_MANAGER: 'Gerente del Corral de Engorda',
  WORKER: 'Trabajador ',
  AUDITOR: 'Auditor',
  BOTH: 'Ambos/ambas',

  MISSED_TRAINING: 'Empleado(s) que perdió/eron el/los entrenamiento(s)',
  TRAININGS: 'Prácticas',
  REVISION: 'Revisión',
  FEEDYARD_REVISION: 'Revisión del Corral de Engorda',

  REQUEST_SENT_TO_ADMIN: 'Su solicitud ha sido enviada a admin',
  INVALID_DATES: 'Fechas Inválidas',
  INVALID_EMAIL: 'Dirección de correo electrónico no válida',
  START_LESS_THAN_END_DATE:
    'Fecha final debe ser igual o después de fecha de inicio.',
  MOVE_TO_ACTIVE_CONFIRMATION:
    '¿Está seguro de que desea restaurar esta práctica de la papelera?',
  RESTORED_SUCCESSFULLY: 'La práctica se ha restablecido exitosamente.',
  BINNED_SUCCESSFULLY:
    'Esta práctica ha sido exitosamente trasladada a la papelera.',
  BINNED_DOCUMENT:
    'Esta práctica ha sido exitosamente trasladada a la papelera.',
  BINNED_OFFLINE:
    'No es posible mover esta práctica a la papelera mientras esté desconectado.',
  RESTORED_OFFLINE:
    'No es posible restaurar esta práctica mientras esté desconectado.',
  MOVE_TO_ACTIVE_DOCUMENT:
    '¿Está seguro de que desea restaurar esta práctica de la papelera?',
  RESTORED_DOCUMENT: 'La práctica se ha restablecido exitosamente.',

  ASSIGNED_USERS: 'Usuarios asignados',
  VIEWED: 'Visto',
  UNVIEWED: 'No visto',
  OVERDUE: 'Vencido',
  FILTER: 'Filtro',
  NO_USER_FOUND: 'No se encontraron usuarios en este estado',

  NO_CATEGORY: 'No hay categoría disponible',
  NO_VIDEO: 'No hay video disponible',
  VIDEOS: 'Video(s)',
  UPLOADED_ON: 'Subido en',

  PROGRAM_MANUAL: 'Manual del Programa',

  REASON_FOR_REMOVAL: 'Razón por eliminar',
  REASON_REQUIRED: 'Deberá ingresar una razón para eliminar esta instancia.',
  ADD_SUBMISSION_CONFIRMATION:
    'Entregue este informe para [activityTitle] según se ingresó en [date] por [userName]?',
  RECENTLY_MISSED: 'Faltantes recientes',
  NO_MISSED_ACTIVITY_FOUND: 'No hay informes faltantes',
  AGO: 'hace',
  TODAY: 'hoy',
  DAYS: 'días',
  ONE_DAY: 'día',
  RESUBMIT: 'Entregue nuevamente el informe',
  VISIBLE_TO: 'Visible para',
  TRAINING_ADDED: 'Práctica agregada exitosamente.',
  TRAINING_UPDATED: 'Práctica actualizada exitosamente.',
  DOCUMENT_ADDED: 'Práctica agregada exitosamente.',
  DOCUMENT_UPDATED: 'Práctica actualizada exitosamente.',
  TRAINING_VIEWED: 'Se ha visto la práctica.',
  UNABLE_TO_MARK: 'No es posible marcar el estado de la práctica como Vista.',
  VIEWED_OFFLINE:
    'No es posible marcar el estado de la práctica como Vista mientras esté desconectado.',
  ASSIGN_TRAINING: 'Asignar práctica',
  ASSIGN_DOCUMENT: 'Asignar práctica',
  NO_VIDEO_AVAILABLE: 'No hay video disponible',
  //#endregion

  //#region Success and error alerts
  FORGOT_PASSWORD_REQUEST_SUCCESS_MSG:
    'Un correo electrónico fue enviado con detalles para crear una nueva contraseña.',
  COMMON_SAVED_SUCCESSFULLY: 'Guardado',
  AUDIT_SAVED_SUCCESSFULLY: 'Auditoria enviada al auditor.',
  //#endregion

  RANK_LABEL:
    'Usted se encuentra en {position}{position_ordinal} fuera de los patios de alimentación {total_feedyards}',
  COMPLAINCE_LABEL:
    'Su tasa de cumplimiento en el patio de alimentación es de {performance}%.',
  KEEP_IT_UP: '¡Siga así!',
  UNABLE_TO_SEND_REQUEST: 'No puede enviar la requisición. No está en línea.',
  DOWNLOADING: 'Descargando...',
  OFFLINE_MODE_REFRESH_ERROR: 'No puede actualizar. Está desconectado.',
  OFFLINE_MODE_LOAD_MORE_ERROR:
    'No puede obtener más datos. Está desconectado.',
  DIFFERENT_FEEDLOT_ERROR:
    'Por favor seleccione la empresa de engorda a la que pertenece esta notificación.',
  FEEDLOT_NOT_SELECTED_ERROR:
    'Por favor seleccione la empresa de engorda correcta para ver esta notificación.',

  //#region ERROR  CODES
  NO_INTERNET_CONNECTION: 'No hay conexión a internet disponible.',
  ERROR_ACCOUNT_LOCKED:
    'Su acceso ha sido bloqueado debido a demasiados intentos erroneos para ingresar.',
  ERROR_LOGIN_FAILED:
    'Por favor ingrese el nombre de usuario, la contraseña y el PIN correcto para iniciar su sesión.',
  ERROR_NO_EMAIL_TO_RESET_PASSWORD_PIN:
    'Falló el intento de enviarle un correo electrónico. Favor de comunicarse con el administrador del programa de Progressive Beef.',
  ERROR_NOT_AUTHORIZED: 'No tiene autorización para el acceso a este sistema',
  ERROR_INSTANCE_ALREADY_EXISTS:
    'El nombre de esta instancia ya existe. Elija un nombre único. Intente agregar una fecha o un número de modelo.',
  EMPTY_FIELD: 'Favor de llenar todos los campos',
  INVALID_PIN: 'PIN inválido. El PIN debe ser un número.',
  PIN_LENGTH_ERROR: 'El PIN debe tener 4 caracteres.',
  PIN_MATCH_ERROR: 'Ambos PINs deben ser iguales.',
  SOMETHING_WENT_WRONG: '¡Algo salió mal!',
  AN_ERROR_OCCURED: 'Ocurrió un error',
  INVALID_PIN_UNLOCK: 'PIN incorrecto, inténtelo de nuevo',
  FORGET_PASSWORD_EMPTY_USERNAME: 'Favor de ingresar su contraseña.',
  OFFLINE_SWITCH_FEEDLOT:
    'No puede cambiar de corral de engorda. Está desconectado.',
  ERROR_USER_ALREADY_EXISTS: 'Este usuario ya existe en el sistema.',
  PASSWORDS_DONT_MATCH: 'Ambas contraseñas deben ser iguales',
  VALIDATE_USER_PASSWORD_LENGTH: 'La contraseña debe tener 10 caracteres',
  UNABLE_TO_SUBMIT_AUDIT: 'No se puede presentar la auditoría al Auditor',
  UNABLE_TO_LOAD_AUDIT_REPORT: 'No se puede cargar el reporte del auditor.',
  LOGIN_FIELDS_MISSING:
    'Por favor ingrese el nombre de usuario, la contraseña y el PIN correcto para iniciar su sesión.',

  ERROR_CODE_00001: 'No se encontraron registros.',
  ERROR_CODE_00002: 'Problema al añadir el registro a la base de datos.',
  ERROR_CODE_00003: 'Este usuario ya existe en el sistema.',
  ERROR_CODE_00004:
    'Resultado incorrecto al fijar excepción de recuentos de columna.',
  ERROR_CODE_00005:
    'Resultado vacío, no se ha encontrado el registro de acceso al conjunto de datos.',
  ERROR_CODE_00006: 'No se puede establecer la conexión jdbc.',
  ERROR_CODE_00007: 'Permiso denegado para acceder a un recurso.',
  ERROR_CODE_00008:
    'No hay coincidencia de tipos entre las columnas java y jdbc',
  ERROR_CODE_00009: 'Fallo en la recuperación de datos.',
  ERROR_CODE_00010: 'Se produce una restricción de clave única o duplicada.',
  ERROR_CODE_00011: 'Excepción por problemas en la gramática sql.',
  ERROR_CODE_00012:
    'Se produce un error fatal y se produce una excepción de ejecución.',
  ERROR_CODE_00013: 'Se ha producido una excepción de DB.',
  ERROR_CODE_00014: 'Falta uno o más parámetros requeridos.',
  ERROR_CODE_00015: 'Problema al actualizar el registro a la base de datos.',
  ERROR_CODE_00016: 'Se proporciona una clave de función de usuario no válida.',
  ERROR_CODE_00017: 'Problema al importar Documento.',
  ERROR_CODE_00018: 'Claves de idioma no válidas especificadas para Documento.',
  ERROR_CODE_00019:
    'Se ha producido un error al procesar la solicitud de documento.',
  ERROR_CODE_00020: 'Se proporcionó una fecha de revisión no válida.',
  ERROR_CODE_00021: 'No se puede procesar el documento.',
  ERROR_CODE_00022: 'Solicitud de usuario no autorizada.',
  ERROR_CODE_00023: 'No se puede autenticar. Inicie sesión primero.',
  ERROR_CODE_00024: 'No hay tal documento disponible para importar.',
  ERROR_CODE_00025: 'No hay tal documento disponible para actualizar.',
  ERROR_CODE_00026:
    'Este comedero destinado al engorde de ganado ya existe en el sistema.',
  ERROR_CODE_00027: 'Su sesión ha expirado por inactividad.',
  ERROR_CODE_00028:
    'La solicitud sin token no será considerada a la espera del token.',
  ERROR_CODE_00030: 'Este correo electrónico pertenece a otro usuario.',
  ERROR_CODE_00031: 'No tiene autorización para el acceso a este sistema',
  ERROR_CODE_00032: 'La contraseña ha sido restablecida',
  ERROR_CODE_00033: 'La contraseña ha sido cambiada',
  ERROR_CODE_00034: 'La contraseña anterior es incorrecta',
  ERROR_CODE_00035: 'Problema al reestablecer la contraseña.',
  ERROR_CODE_00036:
    'No se proporciona el identificador de usuario ni el identificador de actividad del corral destinado al engorde.',
  ERROR_CODE_00037: 'Fallo en la validación.',
  ERROR_CODE_00038:
    'Una o más claves proporcionadas para las entradas de Actividad no es un número entero.',
  ERROR_CODE_00039: 'No se puede procesar la actividad.',
  ERROR_CODE_00040: 'Los datos de la actividad no son correctos.',
  ERROR_CODE_00041:
    'Error desconocido. Favor de reportarlo al Gerente o Administrador del Sistema.',
  ERROR_CODE_00042:
    'Hay un problema al importar las actividades al corral destinado al engorde.',
  ERROR_CODE_00043: 'No hay tal actividad disponible para actualizar.',
  ERROR_CODE_00044:
    'Hubo un problema en la importación de asociaciones de corrales destinados al engorde.',
  ERROR_CODE_00045:
    'El nombre de esta instancia ya existe. Elija un nombre único. Intente agregar una fecha o un número de modelo.',
  ERROR_CODE_00046:
    'Este corral destinado al engorde ya tiene un gerente de corral de engorde activo.',
  ERROR_CODE_00047:
    'Ya existe una actividad con este número. Seleccione un nuevo número o modifique la actividad.',
  ERROR_CODE_00048:
    'Ya existe un documento con este número. Seleccione un nuevo número o modifique ese documento.',
  ERROR_CODE_00049: 'Fallo en la validación del programa de actividades.',
  ERROR_CODE_00050:
    'Se permite una entrada de calendario inferior a la plantilla programada.',
  ERROR_CODE_00051: 'Fallo en la validación de la programación.',
  ERROR_CODE_00052:
    'No se proporciona la fecha de vencimiento o es la fecha incorrecta de la actividad basada en los eventos.',
  ERROR_CODE_00053:
    'Este documento ya está asociado a la actividad proporcionada.',
  ERROR_CODE_00054: 'Problema al eliminar el registro de la base de datos.',
  ERROR_CODE_00055: 'Un documento archivado no se puede asociar/desasociar.',
  ERROR_CODE_00056:
    'Un documento publicado no se puede asociar/desasociar a ninguna actividad. Intente asociar/desasociar con una versión borrador del documento.',
  ERROR_CODE_00057:
    'Todos los usuarios ya están desasociados a esta actividad. ',
  ERROR_CODE_00058:
    'Esta fecha de revisión ya existe, proporcione una fecha posterior.',
  ERROR_CODE_00059:
    'No hay actividades disponibles para importar en este momento.',
  ERROR_CODE_00060: 'Sincronización no válida de los parámetros de llamada.',
  ERROR_CODE_00061:
    'Este usuario es el entrenador de los corral/es destinado/s al engorde. Asigne otro entrenador al corral destinado al engorde.',
  ERROR_CODE_00071: 'Falta el valor requerido para la clave.',
  ERROR_CODE_00072: 'El valor de la clave es inválido.',
  ERROR_CODE_00073:
    'La hora de envío de la actividad no tiene el formato correcto.',
  ERROR_CODE_00074:
    'Únicamente se acepta la entrega de informes con fecha de hoy.',
  ERROR_CODE_00075:
    'Únicamente se acepta la entrega de informes con fecha de hoy.',
  ERROR_CODE_00076: 'No se enviaron todos los campos.',
  ERROR_CODE_00077: 'No se ha especificado ningún archivo en la solicitud.',
  ERROR_CODE_00078: 'Hay más de un archivo especificado en la solicitud.',
  ERROR_CODE_00079: 'El formato de este archivo no es válido',
  ERROR_CODE_00080: 'El código postal y los valores de estado no coinciden.',
  ERROR_CODE_00081: 'No se pudo copiar el archivo al servidor.',
  ERROR_CODE_00082: 'El archivo adjunto no existe en el servidor.',
  ERROR_CODE_00083:
    'No se puede desaprobar el documento. No se puede eliminar la versión borrador del documento.',
  ERROR_CODE_00088: 'La fecha retroactiva no está en un formato válido',
  ERROR_CODE_00089:
    'La fecha retroactiva debería tener al menos un día de antigüedad.',
  ERROR_CODE_00090: 'Usted no está autorizado para enviar.',
  ERROR_CODE_00091:
    'Uno o más usuarios no están autorizados a participar en esta actividad.',
  ERROR_CODE_00092: 'Este informe ya ha sido cancelado.',
  ERROR_CODE_00093:
    'Problema al importar las actividades. Informe al administrador del sistema.',
  ERROR_CODE_00094:
    'No hay documentos disponibles para importar en este momento.',
  ERROR_CODE_00095: 'El formato de la fecha de envío no es válido.',
  ERROR_CODE_00096: 'Esta fecha de revisión ya existe para esta actividad.',
  ERROR_CODE_00097: 'Esta fecha de revisión ya existe para este documento.',
  ERROR_CODE_00098:
    'Escoja una fecha de revisión posterior a la última fecha de revisión.',
  ERROR_CODE_00100:
    'No hay actividades publicadas en el corral destinado al engorde.',
  ERROR_CODE_00101: 'Formato de fecha inválido.',
  ERROR_CODE_00102: 'Error al generar archivo PDF',
  ERROR_CODE_00110:
    'El estado proporcionado no está permitido para este documento.',
  ERROR_CODE_00111: 'No existe el archivo PDF.',
  ERROR_CODE_00112:
    'No se puede desaprobar el documento. No se encontró el documento.',
  ERROR_CODE_00113:
    'No se puede restaurar el documento. No existe una versión obsoleta de este documento.',
  ERROR_CODE_00120:
    'No se puede desaprobar el documento. No se pudo borrar un documento borrador existente.',
  ERROR_CODE_00121:
    'No se puede desaprobar el documento. No se puede actualizar el estado a obsoleto.',
  ERROR_CODE_00122:
    'No se puede desaprobar el documento. No se pueden eliminar las asociaciones.',
  ERROR_CODE_00123:
    'No se puede restaurar el documento. No se encontró el documento.',
  ERROR_CODE_00124: 'El formato de la fecha no es válido.',
  ERROR_CODE_00125: 'El documento ya es obsoleto.',
  ERROR_CODE_00150:
    'Los datos del informe del corral destinado al engorde no son correctos.',
  ERROR_CODE_00151:
    'La identificación del informe del corral destinado al engorde es nula.',
  ERROR_CODE_00152: 'Error al generar el archivo PDF',
  ERROR_CODE_00153: 'Contraseña no válida',
  ERROR_CODE_00154:
    'No puede ingresar datos a una actividad que ha sido eliminada.',
  ERROR_CODE_00155: 'Hubo un problema al restaurar la actividad.',
  ERROR_CODE_00156: 'La actividad no existe con estado obsoleto.',
  ERROR_CODE_00157:
    'No se encontró registro especial o instancia para el registro especial. ',
  ERROR_CODE_00158:
    'No se puede realizar esta operación en una actividad especial.',
  ERROR_CODE_00159:
    'Hubo un problema al archivar la actividad en la tabla de borrado.',
  ERROR_CODE_00160: 'No hay datos estadísticos',
  ERROR_CODE_00161: 'No existe tal corral destinado al engorde.',
  ERROR_CODE_00162: 'Evento inválido.',
  ERROR_CODE_00163: 'Permiso del servidor {permission}',
  ERROR_CODE_00164: 'Parámetros de desplazamiento inválidos.',
  ERROR_CODE_00165: 'No se encontraron actualizaciones.',
  ERROR_CODE_00200:
    'Una o más de las bitácoras 8 8a y 8.1 no han sido publicadas en el Corral de Engorda.',
  ERROR_CODE_00063:
    'No puede marcarse como completado. No existen datos entregados para [date].',
  ERROR_CODE_00065:
    'Su acceso ha sido bloqueado debido a demasiados intentos erroneos para ingresar.',
  ERROR_CODE_00066:
    'Elija una contraseña fuerte que contenga de 10 a 15 caracteres incluyendo letras mayúsculas minúsculas y números. Pida ayuda a su gerente si la necesita.',
  ERROR_CODE_00222:
    'Entregas con fechas anteriores no se permiten en las revisiones de esta actividad',
  ERROR_CODE_00062: 'Su sesión ha caducado.',
  ERROR_CODE_00068: 'Por favor seleccione días de la semana válidos',
  ERROR_CODE_00069: 'Por favor seleccione día(s) del mes válidos',
  ERROR_CODE_00414: 'El PIN anterior es incorrecto.',
  ERROR_CODE_00427: 'Esta instancia está desactivada.',
  ERROR_CODE_00429:
    'No hay actividades entregados para los días seleccionados.',
  ERROR_CODE_00854: 'El nombre de usuario no debe tener menos de 3 caracteres',
  ERROR_CODE_00900:
    'Una práctica con este número ya existe. Por favor, seleccione un nuevo número',
  ERROR_CODE_00903: 'Error al enviar correo electrónico',
  ERROR_CODE_00950: 'Video no está disponible',
  ERROR_CODE_00814: 'Esta auditoria ya ha sido cerrada',
  ERROR_CODE_00815:
    'No se pudo publicar el comentario. No se pudo identificar el rol del usuario.',
  ERROR_CODE_00816:
    'No se pudo publicar el comentario. La disconformidad ya ha sido resuelta.',
  IOS_CAMERA_PERMISSION_ALERT:
    "You haven't accepted Camera permissions for this app previously. Please go to settings and enable Camera permissions for this app.",
  //#endregion
  APPLY_TAGS: 'Aplicar etiquetas',
  APPLY: 'Apply',
  NONE: 'None',
  EDIT_DOCUMENT: 'Editar documento',
  VIEW_DOCUMENT: 'Ver documento',
  INSTRUCTIONS: 'Instrucciones',
  PLEASE_SELECT: 'Seleccione',
  ENTER_TITLE: 'Entrar titulo',
  ATTACH: 'Adjuntar',
  SELECT_FILES: 'Seleccione el archivo',
  DOCUMENTS_BIN: 'Recipiente de documentos',
  INVALID_DATE: 'Fecha invalida',
  MARK_AS_VIEWED: 'Marcar como visto',
  SEND_REQUEST: 'Enviar Petición',
  CANNOT_LOAD_ATTACHMENT:
    'No se pueden cargar los archivos adjuntos debido a una conexión de Internet inactiva.',
  TRAININGS_ENABLED:
    'Nota: Los entrenamientos habilitados para la visualización solo serán visibles en el rango de fechas seleccionado.',
  SUBMISSION_ADDED: 'Entrega Completada.',
  SUBMISSION_REMOVED: 'Sumisión eliminada',
  UNABLE_TO_CONNECT: 'No se puede conectar al servidor',
  TRY_AGAIN: 'Intente de nuevo',
  DUE_AFTER: 'Se vence después',
  PENS_ALREADY_CLEANED: 'Corrales ya limpios',
  PENS_NOT_CLEANED: 'Corrales no limpios',
  ACTIVITIES_TO_SYNC: 'Actividades de sincronización',
  ACTIVITIES_WAITING_TO_SYNC: 'Actividades en espera de sincronización',
  NO_PENDING_ACTIONS: 'No hay acciones pendientes',
  ATTACHMENT_INFO_MESSAGE:
    'Solo se admiten archivos PNG, JPG y PDF de hasta 5 MB.',
  ATTACHMENT_SIZE_ERROR:
    'El tamaño del archivo adjunto excede los límites, reduzca el tamaño e intente nuevamente.',
  ATTACHMENT: 'archivo adjunto',
  PDF_PREVIEW: 'Vista previa de PDF',
  IMAGE_PREVIEW: 'Vista previa de imagen',
  LAST_SYNCED: 'Previa Conexión',
  BACKDATED_SUBMISSIONS: 'Sumisiones antedatadas',
  BACKDATED_ACTIVITIES: 'Actividades antedatadas',
  //#endregion

  LAST_SYNC_FAILED: 'La última sincronización falló.',
  USER_ROLE_1: 'Gerente del Programa',
  USER_ROLE_2: 'Capacitador',
  USER_ROLE_3: 'Gerente del Corral de Engorda',
  USER_ROLE_4: 'Supervisor',
  USER_ROLE_5: 'Trabajador',
  USER_ROLE_6: 'Auditor interno',
  USER_ROLE_7: 'Auditor externo',
  USER_ROLE_8: 'Crítico Interno',
  USER_ROLE_9: 'Crítico Externo',

  LOCKED: 'Bloquedo',
  NOT_NOW: 'Ahora no',
  CURRENT_PIN: 'PIN Actual',
  PLACEHOLDER_CURRENT_PIN: 'PIN actual *',
  NEW_PASSWORD_MATCH_ERROR:
    'La nueva contraseña no coincide con la contraseña de confirmación',
  NEW_PASSWORD_SAME_ERROR:
    'La contraseña nueva y la contraseña anterior no pueden ser iguales',
  PASSWORD_CHANGED_SUCCESS:
    'Su nueva contraseña ya está configurada. Por favor inicie sesión de nuevo para continuar',
  NEW_PIN_MATCH_ERROR: 'Ambos PINs deben ser iguales.',
  NEW_PIN_SAME_ERROR: 'El PIN nuevo y el PIN antiguo no pueden ser iguales.',
  PIN_CHANGED_SUCCESS:
    'PIN cambiado con éxito. Por favor inicia sesión de nuevo par continuar',
  PROGRESS: 'Progreso',
  NOT_SUBMITTED: 'No enviado',
  SUPERVISOR: 'Supervisor',
  NOT_SUPPORTED: 'Huella digital no admitida',
  NOT_PRESENT: 'Huella digital no disponible en este dispositivo',
  ADD_DOCUMENT: 'Añadir documento',
  DOCUMENT_NO: 'Numero de documento',
  REQUEST_PROCESSED_SHORTLY: 'Su solicitud será procesada en breve',
  UNLOCK_USER_CONFIRMATION: '¿Quiere desbloquear la cuenta de usuario?',
  ERROR_CODE_00166: 'El usuario ya está desbloqueado',
  BACKDATED_DATA_NOT_AVAILABLE: 'Datos no disponibles para trazar el gráfico',
  ERROR_CODE_00064: 'Por favor proporcione credenciales válidas',
  NO_ACTIVITES_ASSIGNED: 'No hay actividades asignadas',
  REMOVE_INSTANCE_WITHOUT_REASON_LABEL:
    '¿Estás seguro de que quieres eliminar la instancia?',
  ATTACHMENT_OFFLINE_ERROR:
    'Los archivos adjuntos sin conexión no son compatibles',
  PERFORMANCE_CUSTOM_DATE_ERROR: 'Por favor seleccione rango de fechas',
  NO_COMMENTS_FOUND: 'No se encontró ningún comentario',
  SUBMISSION_ADDED_PENDING: 'Envío pendiente',
  SUBMISSION_REMOVED_PENDING: 'Eliminar pendiente',
  CANNOT_SWITCH_FEEDLOT_WHILE_SYNCING:
    'Incapaz de cambiar de engorda. Sus datos se están sincronizando actualmente.	',
  THIS_YEAR: 'Año actual',
  APP_TRAINING_VIDEO_HTML: 'Videos de entranamiento',
  OLD_AUDIT_NOTIFICATION_TAP_ERROR:
    'Solo puede ver dos auditorías cerradas recientemente',
  INVALID_USERNAME: '*?"<>|& no está permitido en el nombre de usuario',
  INVALID_FIRSTNAME: '*?"<>|& no está permitido en su nombre',
  INVALID_LASTNAME: '*?"<>|& no está permitido en su apedillo',
  INVALID_DOCUMENT_NO: '*?"<>|& no está permitido en el número de documento',
  INVALID_DOCUMENT_TITLE:
    '*?"<>|& no está permitido en el título del documento',
  CONFIRM_SUBMIT_TO_AUDITOR_OR_RETURN_BACK:
    'Puede haber algunos NC que requieran su atención. ¿Quiere enviarlo al auditor?	',
  VALIDATE_USER_NAME_LENGTH_MIN:
    'El nombre de usuario debe tener al menos 3 caracteres',
  VALIDATE_USER_NAME_LENGTH_MAX:
    'El nombre de usuario no debe tener más de 45 caracteres',

  CHECK_FEEDYARD_RANK: 'Check Feedyard Rank',
  BIANNUALLY: 'every 6 months',

  //New
  WAITING_FOR_SUBMISSION: 'Waiting for Submission',
  TWELVE_MONTHS: '12 months',
  info1: "All non-conformances (NC's) found during the ",
  info2:
    ' Progressive Beef audit are listed below, along with necessary corrective actions required to clear the audit. Any responses or documentation submitted has been reviewed by ',
  info3:
    ' and has been found to adequately address the non-conformances. Clearance dates for each NC are listed below.',
  AUDIT_SUMMARY: 'Audit Summary',
  CLEARANCE_LETTER: 'Clearance Letter',
  ATTACH: 'ATTACH',
  WANT_TO_DELETE_THIS_ATTACHMENT:
    'Are you sure you want to remove this attachment?',
  INFO: 'INFO',
  ATTACHMENT_LIMIT_REACHED: `You can only add up to 3 attachments`,
};
