import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Collapse, CardBody, Card, CardHeader } from 'reactstrap';
import './style.scss';

// components
import PBIcon from '../../../../../components/PBIcon';
import MissedActvityCard from './missedActivityCard';

// localization
import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

// constants
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';

import { Events } from '@progressivebeef/shared/src/constants/MixpanelConstants';
import { trackEvent } from '@progressivebeef/shared/src/services/reactMixpanelService';

class RecentlyMissedActivities extends PureComponent {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
  }

  onPress = () => {
    trackEvent(Events.TAPPED_RECENTLY_MISSED);
    this.props.history.push(RouteConstants.ACTIVITIES);
  };

  toggle(e) {
    this.setState({ collapse: !this.state.collapse });
  }

  render() {
    const { collapse } = this.state;
    const { missedActivities, isMissedActivtiesLoading } = this.props;

    return (
      <div
        className={`recently-missed-box${
          isMissedActivtiesLoading ? ' dasboard-card-opt-half' : ''
        }`}
      >
        <Card>
          <CardHeader onClick={this.toggle}>
            <div className="label">
              {localization[getLocale()].RECENTLY_MISSED}
            </div>
            {collapse ? (
              <div className="arrow-icon">
                <PBIcon name="chevron-up" />
              </div>
            ) : (
              <div className="arrow-icon">
                <PBIcon name="chevron-down" />
              </div>
            )}
          </CardHeader>
          <Collapse isOpen={collapse}>
            <CardBody>
              <div className="info-listing-box">
                {!isMissedActivtiesLoading && (
                  <>
                    {missedActivities.length === 0 && (
                      <div className="no-result-found">
                        <p>{`${
                          localization[getLocale()].NO_MISSED_ACTIVITY_FOUND
                        }`}</p>
                      </div>
                    )}
                    {missedActivities.map((notification, index) => {
                      return (
                        <MissedActvityCard
                          key={index}
                          item={notification}
                          onPress={this.onPress}
                        />
                      );
                    })}
                  </>
                )}
              </div>
            </CardBody>
          </Collapse>
        </Card>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  const { isMissedActivtiesLoading, missedActivities } = state.dashboard;

  return {
    isMissedActivtiesLoading,
    missedActivities,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecentlyMissedActivities);
