// modules
import { put, takeLatest, call, select } from 'redux-saga/effects';

import {
  getUserId,
  setSelectedFeedlotId,
  setSelectedFeedlotName,
  getLocale,
  setSelectedFeedlotTrainingRulesEnabled,
  setSelectedFeedlotRankEnabled,
} from '../services/storageService/GlobalData';
import localization from '../localization/i18n';

//Constants
import {
  getAssignedFeedyardsSuccess,
  getAssignedFeedyardsFailure,
  selectFeedyardSuccess,
  selectFeedyardFailure,
} from '../store/actions/authentication';
import { syncDataBlockingRequest } from '../store/actions/dataSync';
import ACTION_CONSTANTS from '../constants/actionConstants';
import OptionsManager from '../database/reactDataManager/OptionsManager';
import FeedlotManager from '../database/reactDataManager/FeedlotManager';
import AuthManager from '../database/reactDataManager/AuthManager';

export const authenticationStoreRequest = (state) => state.authentication;

function* getAssignedFeedyards(action) {
  try {
    let authenticationStore = yield select(authenticationStoreRequest);

    let { searchText } = authenticationStore,
      selectedFeedyardId = OptionsManager.getSelectedFeedyard(),
      feedlots = [];

    let searchedFeedlots = FeedlotManager.getAssignedFeedyards(
      getUserId(),
      selectedFeedyardId,
      searchText
    );

    if (searchedFeedlots.length > 0) {
      feedlots = searchedFeedlots;
    }

    yield put(
      getAssignedFeedyardsSuccess({
        assignedFeedyards: feedlots,
      })
    );
  } catch (e) {
    yield put(getAssignedFeedyardsFailure(e));
  }
}

function* selectFeedyard(action) {
  try {
    let { selectedFeedyard } = action.payload;
    let selectedFeedyardId = OptionsManager.getSelectedFeedyard();

    if (navigator.onLine) {
      OptionsManager.setSelectedFeedyard(
        selectedFeedyard.feedlot_id.toString()
      );
      setSelectedFeedlotRankEnabled(selectedFeedyard.is_ranked);
      yield put(selectFeedyardSuccess({ selectedFeedyard }));

      if (selectedFeedyardId) {
        yield call(AuthManager.clearDBOnFeedyardChange);
      }
      setSelectedFeedlotId(selectedFeedyard.feedlot_id);
      setSelectedFeedlotName(selectedFeedyard.name);
      setSelectedFeedlotRankEnabled(selectedFeedyard.is_ranked);
      setSelectedFeedlotTrainingRulesEnabled(
        selectedFeedyard.training_rules_enabled
      );
      yield put(syncDataBlockingRequest());
    } else {
      let offlineError = localization[getLocale()].NO_INTERNET_CONNECTION;
      if (selectedFeedyardId) {
        offlineError = localization[getLocale()].OFFLINE_SWITCH_FEEDLOT;
      }
      yield put(
        selectFeedyardFailure({
          error: offlineError,
        })
      );
    }
  } catch (e) {
    yield put(selectFeedyardFailure({ error: e }));
  }
}

function* feedlotSaga() {
  yield takeLatest(
    ACTION_CONSTANTS.GET_ASSIGNED_FEEDYARDS_REQUESTED,
    getAssignedFeedyards
  );
  yield takeLatest(ACTION_CONSTANTS.SELECT_FEEDYARD_REQUESTED, selectFeedyard);
}

export default feedlotSaga;
