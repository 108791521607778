import AUTHENTICATION from './authentication';
import ACTIVITIES from './activities';
import PROGRAM_MANUALS from './programManuals';
import NAVIGATION_VIDEOS from './navigationVideos';
import USER from './user';
import INSTANCES from './instance';
import FEEDYARDS from './feedyards';
import VIEW from './view';
import DASHBOARD from './dashboard';
import DATA_SYNC from './dataSync';
import AUDITS from './audits';
import SETTINGS from './settings';
import CARDS from './cards';
import APP_TRAINING_VIDEO_HTML from './appTrainingVideoHTML';

let ACTION_CONSTANTS = {
  ...INSTANCES,
  ...USER,
  ...ACTIVITIES,
  ...AUTHENTICATION,
  ...FEEDYARDS,
  ...VIEW,
  ...PROGRAM_MANUALS,
  ...NAVIGATION_VIDEOS,
  ...DASHBOARD,
  ...DATA_SYNC,
  ...AUDITS,
  ...SETTINGS,
  ...CARDS,
  ...APP_TRAINING_VIDEO_HTML,
};

export default ACTION_CONSTANTS;
