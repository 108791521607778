import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import FormButton from '../../../../../components/FormButton';
import BasicDatePicker from '../../../../../components/BasicDatePicker';

import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import localization from '@progressivebeef/shared/src/localization/i18n';

class DatesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: this.props.start_date,
      end: this.props.end_date,
    };
  }

  onStartDateChange = (selectedDate) => {
    this.setState({
      start: selectedDate,
    });
  };

  onEndDateChange = (selectedDate) => {
    this.setState({
      end: selectedDate,
    });
  };

  render() {
    const {
      modalVisible,
      start_date,
      end_date,
      dismissModal,
      onSubmit,
    } = this.props;
    const { start, end } = this.state;

    return (
      <Modal isOpen={modalVisible}>
        <ModalBody className="custom-backdated-submission-popup">

          <div className="flex-col">
            <div className="label">{localization[getLocale()].START_DATE}</div>
            <div className="date-box">
              <BasicDatePicker
                value={start_date}
                onChange={this.onStartDateChange}
                onPress={this.onStartDateChange}
              />
            </div>
          </div>

          <div className="flex-col">
            <div className="label">{localization[getLocale()].END_DATE}</div>
            <div className="date-box">
              <BasicDatePicker
                value={end_date}
                onChange={this.onEndDateChange}
                onPress={this.onEndDateChange}
              />
            </div>
          </div>

        </ModalBody>

        <ModalFooter className="custom-backdated-submission-popup-footer">

          <FormButton
            onPressEvent={() => {
              onSubmit(start, end);
            }}
            success
            title={localization[getLocale()].PROCEED}
          />
          <FormButton
            onPressEvent={() => dismissModal()}
            success
            title={localization[getLocale()].CANCEL}
          />

        </ModalFooter>
      </Modal>
    );
  }
};

DatesModal.propTypes = {
  onSubmitStartDate: PropTypes.func,
  onSubmitEndDate: PropTypes.func,
  onCancelStartDate: PropTypes.func,
  onCancelEndDate: PropTypes.func,
  showStartDate: PropTypes.bool,
  showEndDate: PropTypes.bool,
  modalVisible: PropTypes.bool,
  start_date: PropTypes.any,
  end_date: PropTypes.any,
  onStartDatePress: PropTypes.func,
  onEndDatePress: PropTypes.func,
  dismissModal: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default DatesModal;
