import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Spinner } from 'reactstrap';

import './style.scss';

//custom components
import PBIcon from '../../../../components/PBIcon';
import AppContainer from '../../../../components/AppContainer';
import DescriptionView from '../../../../components/DescriptionView';
import Separator from '../../../../components/Separator';
import CommentCard from '../../../../components/CommentCard';
import CommentBox from '../../../../components/CommentBox';
import BubbleLabel from '../../../../components/BubbleLabel';
import {
  showErrorAlert,
  showSuccessAlert,
} from '../../../../components/Alerts';

//language
import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

//constants
import {
  getQuestionCommentsRequested,
  sendCommentRequested,
} from '@progressivebeef/shared/src/store/actions/audits';
import {
  showLoader,
  hideLoader,
} from '@progressivebeef/shared/src/store/actions/view';
import AssetConstants from '@progressivebeef/shared/src/constants/AssetConstants';
import {
  auditStatus,
  DATE_FORMATS,
  IS_CORRECTIVE_ACTIONS_REQUIRED,
  NON_CONFORMANCE_STATUS,
  userRoles,
} from '@progressivebeef/shared/src/constants/AppConstants';

import { globalHelper } from '@progressivebeef/shared/src/helpers';
import { ATTACHMENT_TYPES } from '@progressivebeef/shared/src/constants/FormConstants';
import Preview from '../../../../components/Preview';

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

class NCDetail extends PureComponent {
  constructor(props) {
    super(props);
    const { currentAudit, non_conformance } = this.props.location.state;

    this.state = {
      currentAudit,
      non_conformance,
      showPreview: false,
      type: ATTACHMENT_TYPES.PDF,
      source: {},
      isAttachmentAdded: false,
    };
  }

  componentDidMount() {
    let { non_conformance } = this.props.location.state;
    let { getQuestionComments } = this.props;
    let { currentAudit } = this.state;

    getQuestionComments({
      audit_assignment_id: currentAudit.audit_assignment_id,
      non_conformance,
    });
    this.commentsEnd.scrollIntoView({ behavior: 'smooth' });
  }

  componentDidUpdate(prevProps) {
    let {
      isLoading,
      success,
      sendCommentError,
      showLoaderView,
      hideLoaderView,
      questionComments,
    } = this.props;

    if (isLoading) {
      showLoaderView();
    } else {
      hideLoaderView();
    }

    if (success && success !== prevProps.success) {
      showSuccessAlert(success);
    }
    if (sendCommentError !== prevProps.sendCommentError) {
      if (typeof sendCommentError === 'string') {
        showErrorAlert(sendCommentError);
      }
    }
    if (questionComments.length !== prevProps.questionComments.length) {
      this.commentsEnd.scrollIntoView({ behavior: 'smooth' });
    }
  }

  render() {
    let { non_conformance } = this.props.location.state;
    let { user, questionComments, isLoading, history } = this.props;
    let { currentAudit } = this.state;

    const userRole = Number(user.userRoleId);
    const isAuditor =
      userRole === userRoles.INTERNAL_AUDITOR ||
      userRole === userRoles.EXTERNAL_AUDITOR;

    const openPreview = (source, type) => {
      this.setState({
        source: source,
        type: type,
        showPreview: true,
      });
    };

    return (
      <AppContainer
        title={currentAudit.audit.audit_number}
        iconHeader
        IconLeftHeader={this.renderLeftHeader}
        noncollapsible
        history={history}
        hideBottomBar>
        {this.state.showPreview && (
          <Preview
            modalVisible={this.state.showPreview}
            rightHeader
            title={
              this.state.type === ATTACHMENT_TYPES.PDF
                ? localization[getLocale()].PDF_PREVIEW
                : localization[getLocale()].IMAGE_PREVIEW
            }
            pdfSource={
              this.state.type === ATTACHMENT_TYPES.PDF
                ? this.state.source
                : null
            }
            imageSource={
              this.state.type !== ATTACHMENT_TYPES.PDF
                ? this.state.source
                : null
            }
            onRightIconPress={() => this.setState({ showPreview: false })}
            isBase64
            isComment
          />
        )}
        <div
          className={`audit-nc-detail-screen${
            currentAudit.status === auditStatus.SUBMITTED &&
            non_conformance.non_conformance_status !=
              NON_CONFORMANCE_STATUS.RESOLVED
              ? ' show-comment-box'
              : ''
          }${this.state.isAttachmentAdded ? ' attachment-added' : ''}`}>
          <div>
            <div className="head">
              <div className="title">
                {localization[getLocale()].NON_CONFORMANCE +
                  ' #' +
                  non_conformance.nc_index}
              </div>
              <div className="highlighted-title">
                <span className="equalto-mark">{'=  '}</span>
                {`${non_conformance.sectionText}`}
              </div>
            </div>
            {currentAudit.audit_summary.parameters
              .is_corrective_actions_required ==
              IS_CORRECTIVE_ACTIONS_REQUIRED.YES && (
              <Separator
                title={
                  localization[getLocale()].CA_DUE_DATE +
                  ': ' +
                  moment(
                    currentAudit.audit_summary.parameters
                      .corrective_actions_required_by,
                  ).format(DATE_FORMATS.MM_DD_YY)
                }
              />
            )}
            <div className="card-box">
              <BubbleLabel
                text={
                  non_conformance.non_conformance_status !=
                  NON_CONFORMANCE_STATUS.UNRESOLVED
                    ? localization[getLocale()].RESOLVED
                    : localization[getLocale()].UNRESOLVED
                }
              />
              <div className="ques-box">
                <div className="icon">
                  <img src={AssetConstants.questionss} />
                </div>
                <div className="txt">
                  {htmlToReactParser.parse(non_conformance.questionText)}
                </div>
              </div>

              <DescriptionView
                className="desc-title"
                title={localization[getLocale()].DESCRIPTION}
                description={non_conformance.non_conformance}
              />
              {currentAudit.audit_summary.parameters
                .is_corrective_actions_required ==
                IS_CORRECTIVE_ACTIONS_REQUIRED.YES && (
                <DescriptionView
                  className="desc-content"
                  title={localization[getLocale()].CORRECTIVE_ACTION}
                  description={
                    non_conformance.corrective_action
                      ? non_conformance.corrective_action
                      : localization[getLocale()].NO_CA_PROVIDED
                  }
                />
              )}
            </div>
            {questionComments && questionComments.length > 0 ? (
              questionComments.map((item) => {
                return (
                  <CommentCard
                    item={item}
                    key={'comment' + item.audit_question_comment_id.toString()}
                    openPreview={openPreview}
                  />
                );
              })
            ) : isLoading ? (
              <div className="bottom-infinite-loader">
                {globalHelper.renderIf(isLoading)(<Spinner color={'red'} />)}
              </div>
            ) : (
              <div className="no-result-found-box">
                <p>{localization[getLocale()].NO_COMMENTS_FOUND}</p>
              </div>
            )}
          </div>
          {
            // auditors cannot add comments
            !isAuditor &&
              currentAudit.audit_summary.parameters
                .is_corrective_actions_required ==
                IS_CORRECTIVE_ACTIONS_REQUIRED.YES &&
              currentAudit.status === auditStatus.SUBMITTED &&
              non_conformance.non_conformance_status !=
                NON_CONFORMANCE_STATUS.RESOLVED && (
                <div>
                  <CommentBox
                    onSubmit={(values, formikActions, images, files) => {
                      formikActions.resetForm({});
                      this.props.addComment({ values, images, files });
                    }}
                    setIsAttachmentAdded={(added) =>
                      this.setState({ isAttachmentAdded: added })
                    }
                  />
                </div>
              )
          }
          <div
            style={{ float: 'left', clear: 'both' }}
            ref={(el) => {
              this.commentsEnd = el;
            }}
          />
        </div>
      </AppContainer>
    );
  }

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          name={'chevron-left'}
          onPress={() => this.props.history.goBack()}
        />
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getQuestionComments: (payload) =>
      dispatch(getQuestionCommentsRequested(payload)),
    addComment: (payload) => dispatch(sendCommentRequested(payload)),
    showLoaderView: (payload) => dispatch(showLoader(payload)),
    hideLoaderView: (payload) => dispatch(hideLoader(payload)),
  };
};

const mapStateToProps = (state) => {
  const { user } = state.authentication;
  const { success, sendCommentError, audit, isLoading, questionComments } =
    state.audits;

  return {
    user,
    success,
    sendCommentError,
    audit,
    isLoading,
    questionComments,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NCDetail);
