import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  showSuccessAlert,
  showErrorAlert,
} from '../../../../components/Alerts';
import moment from 'moment';

import './style.scss';
import {
  ACTIVITY_FREQUENCY,
  Activity_Frequency_Calendar,
  MonthOptions,
  DAYS,
} from '@progressivebeef/shared/src/constants/AppConstants';
import LocaleHelper from '@progressivebeef/shared/src/helpers/LocaleHelper'
import Separator from '../../../../components/Separator';
import AppContainer from '../../../../components/AppContainer';
import RowItem from '../../../../components/RowItem';

import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import { globalHelper } from '@progressivebeef/shared/src/helpers';
import FormButton from '../../../../components/FormButton';
import {
  reminderWeeklyModel,
  reminderMonthlyModel,
  scheduleModel,
} from './models';
import {
  setActivityReminderRequest,
  deleteActivityReminderRequest,
  setActivityScheduleRequest,
} from '@progressivebeef/shared/src/store/actions/activities';
import BasicDatePicker from '../../../../components/BasicDatePicker';
import ActivityDetail from '../../../../components/ActivityDetail';
import RowPickerItem from '../../../../components/RowPickerItem';
import PBIcon from '../../../../components/PBIcon';
import Loading from '../../../../components/Loading/Loading';
import { FormGroup, Label, Input } from 'reactstrap';

import { trackEvent } from '@progressivebeef/shared/src/services/reactMixpanelService';
import {
  Events,
  Properties,
} from '@progressivebeef/shared/src/constants/MixpanelConstants';
import languageService from '@progressivebeef/shared/src/services/languageService';

const CUSTOM_DAY_OF_MONTH_VALUE = '0';
const pickerArray = LocaleHelper.SCHEDULE_PICKER_ARRAY();
const dayPickerArray = LocaleHelper.SCHEDULE_DAY_PICKER_ARRAY();
const weekDays = LocaleHelper.WEEK_DAYS();
const days = DAYS;

class ScheduleActivity extends Component {
  constructor(props) {
    super(props);
    const { currentActivity } = this.props.location.state;

    const scheduledData = currentActivity.schedule;
    const reminderData = currentActivity.reminder;

    const frequenctCalenderKey = reminderData
      ? reminderData.frequency_calendar_key
      : scheduledData.frequency_calendar_key;

    const { template_frequency_calendar_key } = scheduledData;

    const repeatCriteriaValue = reminderData
      ? reminderData.month_option === MonthOptions.DAY_OF_MONTH
        ? CUSTOM_DAY_OF_MONTH_VALUE
        : reminderData.repeat_by
      : dayPickerArray[0];

    this.minimumDate = new Date();

    this.state = {
      currentActivity,
      showDatePicker: false,

      showSchedulePicker: false,
      showDayOfPicker: false,
      showDaysOfMonthPicker: false,

      currentDate:
        scheduledData && scheduledData.due_date
          ? new Date(moment(scheduledData.due_date)) < new Date() ? this.minimumDate : new Date(moment(scheduledData.due_date))
          : this.minimumDate,

      frequenctCalenderKey,
      template_frequency_calendar_key,

      selectedPickerValue:
        (pickerArray.find((obj) => obj.value === frequenctCalenderKey) &&
          pickerArray.find((obj) => obj.value === frequenctCalenderKey)
            .value) ||
        pickerArray[0].value,

      selectedDayOfPickerValue:
        (dayPickerArray.find((obj) => obj.value === repeatCriteriaValue) &&
          dayPickerArray.find((obj) => obj.value === repeatCriteriaValue)
            .value) ||
        dayPickerArray[0].value,

      selectedDayOfMonth:
        (reminderData &&
          days.find((obj) => obj.value === +reminderData.days) &&
          days.find((obj) => obj.value === +reminderData.days).value) ||
        days[0].value,

      selectedDaysOfWeek:
        reminderData &&
          reminderData.daysArray &&
          Object.keys(reminderData.daysArray).length
          ? weekDays.map((day) => {
            day.selected = false;
            if (
              Object.keys(reminderData.daysArray)
                .map((key) => reminderData.daysArray[key])
                .includes(day.value)
            ) {
              day.selected = true;
            }
            return day;
          })
          : weekDays,
    };
  }

  componentDidUpdate(prevProps) {
    let {
      // isNonActivityLoading,
      // showNonActivityLoader,
      // hideNonActivityLoader,
      error,
      scheduleSuccess,
    } = this.props;
    // if (isNonActivityLoading) {
    //   showNonActivityLoader();
    // } else {
    //   hideNonActivityLoader();
    // }
    if (scheduleSuccess && scheduleSuccess !== prevProps.scheduleSuccess) {
      this.getAlert('Activity Scheduled', () => {
        this.props.history.goBack();
      });
    }
    if (error && error !== prevProps.error) {
      if (typeof error === 'string') {
        showErrorAlert(error);
      } else {
        showErrorAlert(localization[getLocale()].SOMETHING_WENT_WRONG);
      }
    }
  }

  getAlert(message = '', onPress = () => { }) {
    showSuccessAlert(message, [
      {
        text: localization[getLocale()].OK,
        onPress,
      },
    ]);
  }

  getPickerArray(type) {
    switch (type) {
      case Activity_Frequency_Calendar.YEARLY:
        return pickerArray.filter(
          (obj) =>
            obj.value !== Activity_Frequency_Calendar.QUARTERLY &&
            obj.value !== Activity_Frequency_Calendar.DAILY
        );
      case Activity_Frequency_Calendar.QUARTERLY:
        return pickerArray.filter(
          (obj) =>
            obj.value !== Activity_Frequency_Calendar.YEARLY &&
            obj.value !== Activity_Frequency_Calendar.DAILY
        );
      case Activity_Frequency_Calendar.MONTHLY:
        return pickerArray.filter(
          (obj) =>
            obj.value !== Activity_Frequency_Calendar.YEARLY &&
            obj.value !== Activity_Frequency_Calendar.QUARTERLY &&
            obj.value !== Activity_Frequency_Calendar.DAILY
        );
      case Activity_Frequency_Calendar.WEEKLY:
        return pickerArray.filter(
          (obj) => obj.value === Activity_Frequency_Calendar.WEEKLY
        );
      case Activity_Frequency_Calendar.DAILY:
        return pickerArray.filter(
          (obj) => obj.value === Activity_Frequency_Calendar.DAILY
        );
      default:
        return pickerArray;
    }
  }

  onSave = () => {
    const {
      currentActivity,
      currentDate,
      selectedPickerValue,
      selectedDaysOfWeek,
      selectedDayOfPickerValue,
      selectedDayOfMonth,
    } = this.state;
    const { frequency_key } = currentActivity;
    let payload;
    if (frequency_key === ACTIVITY_FREQUENCY.EVENT_BASED) {
      payload = scheduleModel();
      payload.feedlot_activity_id = currentActivity.feedlot_activity_id.toString();
      payload.submission_number = currentActivity.schedule.submission_number
        ? currentActivity.schedule.submission_number.toString()
        : '';
      payload.frequency_calendar_key = '';
      payload.due_date = moment(currentDate).format('YYYY-MM-DD');
      this.props.setActivityScheduleRequest({ schedule: payload });
    } else {
      if (selectedPickerValue === Activity_Frequency_Calendar.WEEKLY) {
        payload = reminderWeeklyModel();
        payload.feedlot_activity_id = currentActivity.feedlot_activity_id.toString();
        payload.frequency_calendar_key = selectedPickerValue;
        payload.days = selectedDaysOfWeek
          .filter((ob) => ob.selected)
          .map((ob) => ob.value)
          .toString();
      } else if (selectedPickerValue === Activity_Frequency_Calendar.MONTHLY) {
        payload = reminderMonthlyModel();
        payload.feedlot_activity_id = currentActivity.feedlot_activity_id.toString();
        payload.frequency_calendar_key = selectedPickerValue;
        if (selectedDayOfPickerValue === CUSTOM_DAY_OF_MONTH_VALUE) {
          payload.days = selectedDayOfMonth.toString();
          payload.month_option = MonthOptions.DAY_OF_MONTH;
          payload.repeat_by = '';
        } else {
          payload.days = selectedDaysOfWeek
            .filter((ob) => ob.selected)
            .map((ob) => ob.value)
            .toString();
          payload.month_option = MonthOptions.EVERY_N_WEEK;
          payload.repeat_by = selectedDayOfPickerValue.toString();
        }
      }
      if (payload) {
        try {
          let properties = {};
          properties[Properties.ACTIVITY_NAME] =
            currentActivity.locale[languageService()].title;
          properties[Properties.ACTIVITY_NUMBER] =
            currentActivity.activity_number;
          trackEvent(Events.SCHEDULE, properties);
        } catch (e) { }

        this.props.setActivityReminderRequest({ reminder: payload });
      } else if (currentActivity.reminder) {
        this.props.deleteActivityReminderRequest(
          currentActivity.feedlot_activity_id
        );
      }
    }
  };

  renderActivitySchedulers = () => {
    const {
      currentActivity,
      currentDate,
      selectedDaysOfWeek,
      selectedDayOfPickerValue,
      selectedPickerValue,
      selectedDayOfMonth,
    } = this.state;

    const { frequency_key } = currentActivity;

    switch (frequency_key) {
      case ACTIVITY_FREQUENCY.EVENT_BASED:
        return (
          <div className="activity-schedule-due-date-box">
            <RowItem
              heading={localization[getLocale()].DUE_DATE}
              value={currentDate}
              CustomItem={() => {
                return (
                  <div className="date-box">
                    <BasicDatePicker
                      value={currentDate}
                      onChange={(value) => {
                        this.setState({
                          currentDate: value || currentDate,
                        });
                      }}
                      minimumDate={this.minimumDate}
                      onPress={(value) => {
                        this.setState({
                          currentDate: value || this.state.currentDate,
                        });
                      }}
                    />
                  </div>
                );
              }}
            />
          </div>
        );
      default:
        const { template_frequency_calendar_key } = this.state;
        return (
          <div>
            <RowPickerItem
              heading={localization[getLocale()].REPEAT}
              selectedValue={selectedPickerValue}
              data={this.getPickerArray(template_frequency_calendar_key)}
              onValueChange={(itemValue) => {
                this.setState({
                  selectedPickerValue: itemValue,
                });
              }}
            />

            {/* Render only when Month is selected */}
            {globalHelper.renderIf(
              selectedPickerValue === Activity_Frequency_Calendar.MONTHLY
            )(
              <RowPickerItem
                heading={localization[getLocale()].REPEAT_EVERY}
                selectedValue={selectedDayOfPickerValue}
                data={dayPickerArray}
                onValueChange={(itemValue) => {
                  this.setState({
                    selectedDayOfPickerValue: itemValue,
                  });
                }}
              />
            )}

            {/* Render only when month is selected and days of month is selected */}
            {globalHelper.renderIf(
              selectedDayOfPickerValue === CUSTOM_DAY_OF_MONTH_VALUE &&
              selectedPickerValue === Activity_Frequency_Calendar.MONTHLY
            )(
              <RowPickerItem
                heading={localization[getLocale()].DAY}
                selectedValue={selectedDayOfMonth}
                data={days}
                onValueChange={(itemValue) => {
                  this.setState({ selectedDayOfMonth: itemValue });
                }}
              />
            )}

            {/* Render only when (month and days of week is selected) or (week is selected) */}
            <div className="month-select-list">
              {globalHelper.renderIf(
                (selectedDayOfPickerValue !== CUSTOM_DAY_OF_MONTH_VALUE &&
                  selectedPickerValue ===
                  Activity_Frequency_Calendar.MONTHLY) ||
                selectedPickerValue === Activity_Frequency_Calendar.WEEKLY
              )(
                selectedDaysOfWeek.map((day, index) => {
                  return (
                    <FormGroup
                      check
                      onClick={() => {
                        let newList = selectedDaysOfWeek.map((a) => {
                          if (a.value === day.value) {
                            a.selected = !a.selected;
                          }
                          return a;
                        });
                        this.setState({ selectedDaysOfWeek: newList });
                      }}
                    >
                      <Label check>
                        {day.label}
                        <Input
                          defaultChecked={day.selected}
                          type="checkbox"
                          onChange={(q) => q.stopPropagation()}
                          onClick={(q) => q.stopPropagation()}
                        />{' '}
                        <span className="checkmark"></span>
                      </Label>
                    </FormGroup>
                  );
                })
              )}
            </div>
          </div>
        );
    }
  };

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          name={'chevron-left'}
          onPress={() => {
            this.props.history.goBack();
          }}
        />
      </div>
    );
  };

  render() {
    const { currentActivity } = this.state;
    const { isNonActivityLoading, history } = this.props;
    const activityName =
      getLocale() === 'en'
        ? currentActivity.locale[1].title
        : currentActivity.locale[2].title;
    return (
      <>
        {isNonActivityLoading ? <Loading></Loading> : null}
        <AppContainer
          noncollapsible
          title={activityName}
          iconHeader
          history={history}
          IconLeftHeader={this.renderLeftHeader}
        >
          <div>
            <div>
              <ActivityDetail activity={currentActivity} />
            </div>
            <div>
              <div>
                <Separator title={localization[getLocale()].SCHEDULE} />
                {this.renderActivitySchedulers()}
                <div>
                  <div className="message-info-box">
                    {localization[getLocale()].SCHEDULE_SUBMISSION_COUNT_LABEL}
                  </div>
                </div>
                <FormButton
                  success
                  onPressEvent={this.onSave}
                  title={localization[getLocale()].SAVE}
                />
              </div>
            </div>
          </div>
        </AppContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setActivityReminderRequest: (payload) =>
      dispatch(setActivityReminderRequest(payload)),
    deleteActivityReminderRequest: (payload) =>
      dispatch(deleteActivityReminderRequest(payload)),
    setActivityScheduleRequest: (payload) =>
      dispatch(setActivityScheduleRequest(payload)),
  };
};

const mapStateToProps = (state) => {
  const { isNonActivityLoading, scheduleSuccess, error } = state.activities;
  return {
    isNonActivityLoading,
    scheduleSuccess,
    error,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleActivity);
