import React, { Component } from 'react';
import moment from 'moment';

import './style.scss';

import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

import AssetConstants from '@progressivebeef/shared/src/constants/AssetConstants';
import { DATE_FORMATS } from '@progressivebeef/shared/src/constants/AppConstants';

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

class AuditNcCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { item, onCardPress } = this.props;
    return (
      <div className="audit-nc-list" onClick={() => onCardPress(item)}>
        <div
          className="left-col"
          // style={[
          //   styles.cardHeadingContainer,
          //   item.non_conformance_status != 0 && { flex: 0.7 },
          // ]}
        >
          <div className="no-box">{'NC #' + `${item.nc_index}`}</div>
          <div className="desc-box">
            <span>{'=  '}</span>
            {`${item.sectionText}`}
          </div>
          <div className="ques-box">
            <div className="icon">
              <img src={AssetConstants.question} />
            </div>
            <div className="txt">
              {htmlToReactParser.parse(item.questionText)}
            </div>
          </div>
        </div>
        <div className="right-col">
          {item.non_conformance_status !== 0 && (
            <div>
              <div className="status">{localization[getLocale()].RESOLVED}</div>
              {item.updated_at && (
                <div className="resolvedDate">
                  {moment(item.updated_at).format(DATE_FORMATS.MM_DD_YY)}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AuditNcCard;
