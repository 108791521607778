import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

// methods
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import { globalHelper } from '@progressivebeef/shared/src/helpers';
import ActivitiesHelper from '@progressivebeef/shared/src/helpers/ActivitiesHelper';
import DashboardHelper from '@progressivebeef/shared/src/helpers/DashboardHelper';

// components
import { Spinner } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import AppContainer from '../../../../components/AppContainer';
import SubmissionCard from './submissionCard';
import PBIcon from '../../../../components/PBIcon';
import ActivityDetail from '../../../../components/ActivityDetail';
import EmptyListMsg from '../../../../components/EmptyListText';
import { showErrorAlert } from '../../../../components/Alerts';

// constants
import localization from '@progressivebeef/shared/src/localization/i18n';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';
import {
  ACTIVITY_TYPE,
  SUBMISSION_STATUS,
  DATE_FORMATS,
  userRoles,
} from '@progressivebeef/shared/src/constants/AppConstants';
import {
  getActivitySubmissionsRequest,
  getBackdatedActivitySubmissionsRequest,
  getActivityRevisionRequest,
  resetSubmissionsRequest,
} from '@progressivebeef/shared/src/store/actions/activities';

import { trackEvent } from '@progressivebeef/shared/src/services/reactMixpanelService';
import {
  Events,
  Properties,
} from '@progressivebeef/shared/src/constants/MixpanelConstants';
import languageService from '@progressivebeef/shared/src/services/languageService';
import MissedCard from './missedCard';

class SubmissionList extends Component {
  constructor(props) {
    super(props);
    const { currentActivity } = this.props.location.state;
    const { currentInstance } = this.props.location.state;
    this.state = {
      currentActivity,
      currentInstance,
      startIndex: 0,
      noMoreRecords: false,
      submissions: [],
      selectedSubmission: {},
    };
  }

  static getDerivedStateFromProps(props, state) {
    // should not call api if no more records
    if (
      props.submissions.length > 0 &&
      !props.isSubmissionsLoading &&
      props.updateSubmissionsList
    ) {
      if (props.submissions.length === state.submissions.length) {
        if (!state.noMoreRecords) {
          state.noMoreRecords = true;
        }
      } else if (props.submissions.length > state.submissions.length) {
        state.noMoreRecords = false;
      }
    }
    state.submissions = props.submissions;
    return state;
  }

  componentDidMount() {
    let { submissions, serverError } = this.props;

    const { currentActivity, currentInstance, backdated } =
      this.props.location.state;

    if (
      backdated ||
      submissions.length === 0 ||
      (!this.state.noMoreRecords && submissions.length < 10) ||
      (submissions.length > 0 &&
        submissions[0].activity_number != currentActivity.activity_number) ||
      (currentInstance &&
        submissions.length > 0 &&
        submissions[0].instance_value !== currentInstance.instance_value)
    ) {
      if (!serverError) {
        this.getSubmissions();
      }
    }

    window.addEventListener('focus', this.onFocus);
  }

  onFocus = () => {
    this.forceUpdate();
  };

  componentWilUnmount() {
    window.removeEventListener('focus', this.onFocus);
  }

  componentDidUpdate(prevProps) {
    const { submissionListError, revision, history } = this.props;
    const { bin, backdated, date } = this.props.location.state;
    let filterDuration = null;
    if (backdated) {
      filterDuration = DashboardHelper.getBackdatedSubmissionsInDuration(
        backdated,
        date.start_date,
        date.end_date,
      );
    }

    if (
      submissionListError &&
      submissionListError !== prevProps.submissionListError
    ) {
      if (typeof submissionListError === 'string') {
        showErrorAlert(submissionListError);
      } else {
        showErrorAlert(localization[getLocale()].SOMETHING_WENT_WRONG);
      }
    }
    if (revision) {
      const { currentInstance, selectedSubmission } = this.state;
      history.push(RouteConstants.ADD_SUBMISSION, {
        currentActivity: revision,
        currentInstance,
        disabled:
          selectedSubmission.status === SUBMISSION_STATUS.REJECTED
            ? false
            : true,
        submission: selectedSubmission,
        bin: bin,
        backdated,
        startDate: filterDuration
          ? moment(filterDuration.start_date).format(DATE_FORMATS.YYYY_MM_DD)
          : null,
        endDate: filterDuration
          ? moment(filterDuration.end_date).format(DATE_FORMATS.YYYY_MM_DD)
          : null,
      });
    }
    if (
      prevProps.submissions.length == 0 &&
      this.props.submissions.length > 0 &&
      !this.state.noMoreRecords
    ) {
      let zoomLevel = window.devicePixelRatio || 1;
      zoomLevel = Math.round(zoomLevel * 100);
      // fetch more on initial, fix for PBPR-6131
      if (zoomLevel < 140) {
        this.getSubmissions(
          this.props.submissions.filter((q) => q.status != 3).length,
        );
      }
    }
    if (
      prevProps.submissions.length > 0 &&
      this.props.submissions.length == 0
    ) {
      this.getSubmissions();
    }
  }

  getSubmissions = (index) => {
    const { currentActivity, currentInstance, startIndex, noMoreRecords } =
      this.state;
    const { backdated, date } = this.props.location.state;

    if (backdated) {
      const filterDuration = DashboardHelper.getBackdatedSubmissionsInDuration(
        backdated,
        date.start_date,
        date.end_date,
      );

      this.props.getBackdatedActivitySubmissions({
        activityId: currentActivity.feedlot_activity_id,
        activityNumber: currentActivity.activity_number,
        instanceName: currentInstance ? currentInstance.instance_value : null,
        startDate: moment(filterDuration.start_date).format(
          DATE_FORMATS.YYYY_MM_DD,
        ),
        endDate: moment(filterDuration.end_date).format(
          DATE_FORMATS.YYYY_MM_DD,
        ),
        backdated,
      });

      this.setState({
        noMoreRecords: true,
      });
    } else if (!noMoreRecords) {
      this.props.getActivitySubmissions({
        activityId: currentActivity.feedlot_activity_id,
        activityNumber: currentActivity.activity_number,
        instanceId: currentInstance
          ? currentInstance.feedlot_activity_instance_id
          : null,
        instanceName: currentInstance ? currentInstance.instance_value : null,
        startIndex: index || startIndex,
      });
    }
  };

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          name={'chevron-left'}
          onPress={() => {
            const { currentActivity } = this.props.location.state;

            this.props.resetSubmissions(currentActivity.activity_number);
            this.props.history.goBack();
          }}
        />
      </div>
    );
  };

  onPrintPress = () => {
    const { currentActivity, currentInstance } = this.state;

    try {
      let properties = {};
      properties[Properties.ACTIVITY_NAME] =
        currentActivity.locale[languageService()].title;
      properties[Properties.ACTIVITY_NUMBER] = currentActivity.activity_number;
      trackEvent(Events.SUBMISSION_PRINT_REQUESTED, properties);
    } catch (e) {}

    this.props.history.push(RouteConstants.ACTIVIY_PRINT, {
      currentActivity,
      currentInstance,
    });
  };

  renderRightHeader = () => {
    const { user } = this.props;
    const { currentActivity, currentInstance } = this.state;
    const { bin, backdated, date } = this.props.location.state;
    const { activity_type_key } = currentActivity;

    const roleId = Number(user.userRoleId);
    const isAuditor =
      roleId === userRoles.INTERNAL_AUDITOR ||
      roleId === userRoles.EXTERNAL_AUDITOR;

    const shouldShowPrint =
      activity_type_key === ACTIVITY_TYPE.LOG && !isAuditor && !backdated;

    let filterDuration = null;
    if (backdated) {
      filterDuration = DashboardHelper.getBackdatedSubmissionsInDuration(
        backdated,
        date.start_date,
        date.end_date,
      );
    }

    return (
      <div className="head-icon right-align submision-head-icon">
        <div className="hald-icon-box">
          {globalHelper.renderIf(shouldShowPrint)(
            <PBIcon name={'print'} onPress={this.onPrintPress} />,
          )}
        </div>
        <div className="hald-icon-box">
          {bin ||
          (currentInstance && !currentInstance.status) ||
          isAuditor ? null : (
            <PBIcon
              name={'check'}
              onPress={() =>
                this.props.history.push(RouteConstants.ADD_SUBMISSION, {
                  currentActivity,
                  currentInstance,
                  bin,
                  backdated,
                  startDate: filterDuration
                    ? moment(filterDuration.start_date).format(
                        DATE_FORMATS.YYYY_MM_DD,
                      )
                    : null,
                  endDate: filterDuration
                    ? moment(filterDuration.end_date).format(
                        DATE_FORMATS.YYYY_MM_DD,
                      )
                    : null,
                })
              }
            />
          )}
        </div>
      </div>
    );
  };

  getTitle = () => {
    let { currentInstance } = this.state;

    if (currentInstance) {
      return currentInstance.instance_value;
    } else {
      return localization[getLocale()].HISTORY;
    }
  };

  renderSectionHeader = (item) => <div>{item.title}</div>;
  renderItem = (item) => {
    const { currentActivity, currentInstance } = this.state;
    const { bin, backdated, date } = this.props.location.state;
    let filterDuration = null;
    if (backdated) {
      filterDuration = DashboardHelper.getBackdatedSubmissionsInDuration(
        backdated,
        date.start_date,
        date.end_date,
      );
    }

    return (
      <SubmissionCard
        item={item}
        backdated={backdated}
        onPress={async () => {
          if (item.status !== SUBMISSION_STATUS.MISSED) {
            if (
              item.feedlot_activity_id !== currentActivity.feedlot_activity_id
            ) {
              this.props.getRevisionActivity(item.feedlot_activity_id);
              this.setState({ selectedSubmission: item });
            } else {
              this.props.history.push(RouteConstants.ADD_SUBMISSION, {
                currentActivity,
                currentInstance,
                disabled:
                  item.status === SUBMISSION_STATUS.REJECTED ? false : true,
                submission: item,
                bin: bin,
                backdated,
                startDate: filterDuration
                  ? moment(filterDuration.start_date).format(
                      DATE_FORMATS.YYYY_MM_DD,
                    )
                  : null,
                endDate: filterDuration
                  ? moment(filterDuration.end_date).format(
                      DATE_FORMATS.YYYY_MM_DD,
                    )
                  : null,
              });
            }
          }
        }}
      />
    );
  };
  keyExtractor = (item, index) => item.title + index;

  fetchMoreData = () => {
    const { noMoreRecords } = this.state;
    const { submissions } = this.props;
    if (!noMoreRecords) {
      this.getSubmissions(submissions.filter((q) => q.status != 3).length);
    }
  };

  render() {
    const { currentActivity, startIndex, noMoreRecords } = this.state;
    const { isSubmissionsLoading, submissions, history, focusedActivity } =
      this.props;
    const { backdated, date } = this.props.location.state;
    if (backdated) {
    }

    let data = backdated
      ? isSubmissionsLoading
        ? []
        : ActivitiesHelper.parseSubmissionsSectionData(
            submissions,
            backdated,
          ) || []
      : ActivitiesHelper.parseSubmissionsSectionData(submissions, backdated) ||
        [];

    let dataLength = data.map((o) => o.data).flat().length;
    // let Submissions = data.filter(q=> q.data.filter(a => a.activitynumber == currentActivity.activity_number))
    return (
      <AppContainer
        noncollapsible
        title={this.getTitle()}
        iconHeader
        history={history}
        IconRightHeader={this.renderRightHeader}
        IconLeftHeader={this.renderLeftHeader}>
        <div>
          <div>
            <ActivityDetail activity={currentActivity} />
          </div>

          {isSubmissionsLoading &&
          (backdated || (startIndex === 0 && submissions.length === 0)) ? (
            <div className="bottom-infinite-loader">
              <Spinner color="primary" />
            </div>
          ) : submissions.length > 0 ? (
            <InfiniteScroll
              dataLength={dataLength}
              next={this.fetchMoreData}
              hasMore={dataLength < 10 ? false : !noMoreRecords}
              loader={
                isSubmissionsLoading && (
                  <div className="bottom-infinite-loader">
                    <Spinner color="primary" />
                  </div>
                )
              }
              scrollThreshold={0.999}
              scrollableTarget="scrollableDiv"
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>{localization[getLocale()].NO_MORE_RECORDS}</b>
                </p>
              }>
              {(focusedActivity.show_missed_entry ||
                (Object.keys(focusedActivity).length === 0 &&
                  currentActivity.show_missed_entry)) && <MissedCard />}
              {data.map((section, index) => {
                return (
                  <div key={index + 400}>
                    {React.createElement(
                      'div',
                      {
                        className: 'section-header',
                        key: index + 100,
                      },
                      this.renderSectionHeader(section),
                    )}
                    {React.createElement(
                      'div',
                      {
                        className: 'section-item-container',
                        key: index + 200,
                      },
                      section.data.map((item, index) =>
                        React.createElement(
                          'div',
                          {
                            className: 'section-item',
                            key: index,
                          },
                          this.renderItem(item, index),
                        ),
                      ),
                    )}
                  </div>
                );
              })}
            </InfiniteScroll>
          ) : // (submissions.length < 1 && !isSubmissionsLoading &&) ?
          // // <Spinner color="primary" />
          // <></>
          // : noMoreRecords ?
          //   <div>
          //     <div>
          //       {localization[getLocale()].NO_MORE_RECORDS}
          //     </div>
          //   </div>
          //   : <></>
          null}

          <div className="no-submission-result-found">
            {submissions.length < 1 && !isSubmissionsLoading && (
              <EmptyListMsg
                emptyTxt={localization[getLocale()].NO_SUBMISSION_FOUND}
              />
            )}
          </div>
        </div>
      </AppContainer>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getActivitySubmissions: (payload) =>
      dispatch(getActivitySubmissionsRequest(payload)),
    getBackdatedActivitySubmissions: (payload) =>
      dispatch(getBackdatedActivitySubmissionsRequest(payload)),
    getRevisionActivity: (payload) =>
      dispatch(getActivityRevisionRequest(payload)),
    resetSubmissions: (payload) => dispatch(resetSubmissionsRequest(payload)),
  };
};

const mapStateToProps = (state) => {
  const { user } = state.authentication;

  const {
    submissions,
    isSubmissionsLoading,
    submissionListError,
    revision,
    updateSubmissionsList,
    focusedActivity,
  } = state.activities;

  return {
    user,
    submissions,
    isSubmissionsLoading,
    submissionListError,
    revision,
    updateSubmissionsList,
    focusedActivity,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionList);
