import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import './style.scss'

import PBIcon from '../../../../components/PBIcon';

import { globalHelper } from '@progressivebeef/shared/src/helpers';
import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

import {
  DATE_FORMATS,
} from '@progressivebeef/shared/src/constants/AppConstants';

const WorkerItem = (props) => {
  const { item } = props;
  return (
    <div className="worker-activity-list-box">

      <div className="flex-col">
          <div className="desc-info">
             <div className="user-name">{`${item.user.profile.first_name} ${item.user.profile.last_name}`}</div> 
             <div className="date-box">  {globalHelper.renderIf(item.last_access_time)(
            <div>
              <div className="icon"><PBIcon name={'calendar-check'} /></div>
              <div className="date">{moment(item.last_access_time).format(DATE_FORMATS.MM_DD_YY)}</div>
            </div>
          )}</div> 

          <div className="date-box edit-date">
          {globalHelper.renderIf(item.latest_due_date)(
            <div>
                <div className="icon"><PBIcon name={'calendar-edit'} /></div>
        
               <div className="date"> {moment(item.latest_due_date).format(DATE_FORMATS.MM_DD_YY)}</div>
             
            </div>
          )}     
          </div>
          <div className="month-box">
          {item.fromNow} 
          </div>
          </div>
          <div className="worker-info">
              <div className="designation">{localization[getLocale()]['USER_ROLE_' + item.user.user_role_key]}</div>
              <div className="count-box">
                <div className="num">
                   {item.count_assigned_activities}
                </div>
                <div className="icon">
                   <PBIcon name={'file-check'} />
                </div>
              </div>  
          </div>
      </div>



   
    </div>
  );
};

WorkerItem.propTypes = {
  item: PropTypes.object,
};

export default WorkerItem;
