export const Events = {
  LOGIN: 'Login',
  VIEWED_NOTIFICATIONS: 'Viewed_Notifications',
  VIEWED_UPDATES: 'Viewed_Updates',
  VIEWED_PROGRAM_MANUAL: 'Viewed_Program_Manual',
  PRINTED_PROGRAM_MANUAL: 'Printed_Program_Manual',
  VIEWED_DETAILED_PERFORMANCE: 'Viewed_Detailed_Performance',
  VIEWED_WORKER_ACTIVITY: 'Viewed_Worker_Activity',
  VIEWED_DUE_ACTIVITIES_TODAY: 'Viewed_Due_Activities_Today',
  TAPPED_RECENTLY_MISSED: 'Tapped_Recently_Missed',
  PERFORMED_MANUAL_SYNC: 'Performed_Manual_Sync',
  ADDED_USER: 'Added_User',
  EDITED_USER: 'Edited_User',
  UNLOCKED_USER: 'Unlocked_User',
  ACTIVITY_VIEW_TYPE_CHANGED: 'Activity_View_Type_Changed',
  ACTIVITY_SEARCHED: 'Activity_Searched',
  INSTANCE_ADDED: 'Instance_Added',
  SUBMISSION_MADE: 'Submission_Made',
  ACTIVITY_ASSIGNED: 'Activity_Assigned',
  PASSWORD_CHANGED: 'Password_Changed',
  PIN_CHANGED: 'PIN_Changed',
  PIN_USED_TO_UNLOCK: 'PIN_Used_To_Unlock',
  LOAD_MORE_LISTING: 'Load_More_Listing',
  SCHEDULE: 'Schedule',
  BIN_ACTIVITIES_VIEWED: 'Bin_Activities_Viewed',
  PM_ASSOC_ACTIVITIES_VIEWED: 'PM_Associated_Activities_Viewed',
  SUBMISSION_PRINT_REQUESTED: 'Submission_Print_Requested',
  RESUBMISSION: 'Resubmission',
  PENDING_SUBMISSION: 'Pending_Submission',
  CLEAR_STORAGE: 'Clear_Storage',
  LOGOUT: 'Logout',
  SUBMISSION_REMOVED: 'Submission_Removed',
  APP_UPGRADED: 'App_Upgraded',
  RULE_BASE_PUSH: 'Rule_Base_Push',
};

export const Properties = {
  UNLOCKED_USERNAME: 'Unlocked_Username',
  USER_TYPE: 'User_Type',
  DOCUMENT_NUMBER: 'Document_Number',
  LANGUAGE: 'Language',
  IS_ONLINE: 'Online',
  USER_TYPE_USED: 'User_Type_Used',
  ACTIVITY_VIEW_TYPE: 'Activity_View_Type',
  SEARCH_STRING: 'Search_String',
  INSTANCE_VALUE: 'Instance_Value',
  INSTANCE_BASED: 'Instance_Based',
  ACTIVITY_TYPE: 'Activity_Type',
  ACTIVITY_FREQUENCY: 'Activity_Frequency',
  NUMBER_OF_ASSIGNED_USERS: 'Number_of_Assigned_Users',
  LIST_NAME: 'LIST_NAME',
  NUMBER_OF_RECORDS: 'Number_Of_Existing_Records',
  LOGIN_MODE: 'Login_Mode',
  BACKDATED: 'Backdated',
  SELECTED_OPTION: 'Selected_Option',
  NUMBER_OF_RECORDS_REMOVED: 'Number_Of_Records_Removed',
  LOGOUT_MODE: 'Logout_Mode',
  PREVIOUS_APP_VERSION: 'Previous_App_Version',
  NEW_APP_VERSION: 'New_App_Version',
  VIOLATION_FIELDS: 'Violation_Fields',
  FEEDYARD_NAME: 'FeedYard_Name',
  FEEDYARD_ID: 'FeedYard_Id',
  ACTIVITY_NAME: 'Activity_Name',
  ACTIVITY_NUMBER: 'Activity_Number',
  APPLICATION_TYPE: 'Application_Type', //options: APPLICATION_TYPES
  USER_NAME: 'Username',
  USER_ROLE: 'User_Role',
  JS_APP_VERSION: 'JS App Version',
};
