import { v4 as uuidv4 } from 'uuid';

import {
  getUserFirstName,
  getUserLastName,
  getUserId,
} from '../services/storageService/GlobalData';
import moment from 'moment';

const submissionModel = (submission, id, activityNumber, instanceName, isBackdated) => {
  let values = [];
  let keys = Object.keys(submission.values || []);
  if (keys.length > 0) {
    keys.map((value) => {
      const obj = {
        key: value,
        value: submission.values[value].type
          ? JSON.stringify(submission.values[value])
          : submission.values[value].toString(),
      };
      values.push(obj);
    });
  }

  let feedlotActivitySubmissionCancellation = null;
  if (submission.feedlotActivitySubmissionCancellation && submission.status === 0) {
    feedlotActivitySubmissionCancellation = {
      cancellation_date:
        submission.feedlotActivitySubmissionCancellation.cancellation_date,
      cancelled_by_user: submission.feedlotActivitySubmissionCancellation
        .cancelled_by_user.profile
        ? submission.feedlotActivitySubmissionCancellation.cancelled_by_user
          .profile.first_name +
        ' ' +
        submission.feedlotActivitySubmissionCancellation.cancelled_by_user
          .profile.last_name
        : submission.feedlotActivitySubmissionCancellation.cancelled_by_user,
      reason: submission.feedlotActivitySubmissionCancellation.reason,
    };
  }

  return {
    id: id || uuidv4(),
    feedlot_activity_id: submission.feedlot_activity_id,
    status: submission.status,
    feedlot_activity_instance_id: submission.feedlot_activity_instance_id,
    feedlot_activity_submission_entry_missed_id:
      submission.feedlot_activity_submission_entry_missed_id || null,
    is_active: submission.is_active || true,
    feedlot_activity_submission_id:
      submission.feedlot_activity_submission_id || null,
    actual_submission_date: submission.actual_submission_date || null,
    miss_date: submission.miss_date || null,
    submitted_date: submission.submitted_date,
    user_id: submission.submitted_by_user
      ? submission.submitted_by_user.user_id
      : getUserId(),
    first_name: submission.submitted_by_user
      ? submission.submitted_by_user.profile.first_name
      : getUserFirstName(),
    last_name: submission.submitted_by_user
      ? submission.submitted_by_user.profile.last_name
      : getUserLastName(),
    values: values,
    feedlotActivitySubmissionCancellation: feedlotActivitySubmissionCancellation,
    activity_number: activityNumber,
    instance_value: instanceName,
    localStorageAddedDate: moment().format('l'),
    removeCallPending: submission.removeCallPending || false,
    isBackdated: isBackdated || false
  };
};

export default submissionModel;
