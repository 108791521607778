import ACTION_CONSTANTS from '../../constants/actionConstants';
import { request, success, failure } from './index';

export function getProgramManualRequest(payload) {
  return request(ACTION_CONSTANTS.GET_PROGRAM_MANUAL_REQUEST, payload);
}

export function getProgramManual(payload) {
  return request(ACTION_CONSTANTS.GET_PROGRAM_MANUAL, payload);
}

export function getProgramManualSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_PROGRAM_MANUAL_SUCCESS, payload);
}

export function getProgramManualFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_PROGRAM_MANUAL_FAILURE, payload);
}

export function setNumberOfMissedTraining(payload) {
  return request(ACTION_CONSTANTS.SET_MISSED_TRAINING, payload);
}

export function searchProgramManuals(payload) {
  return request(ACTION_CONSTANTS.SEARCH_PROGRAM_MANUAL, payload);
}

export function getProgramManualTagsRequest(payload) {
  return request(ACTION_CONSTANTS.GET_PROGRAM_MANUAL_TAG_REQUEST, payload);
}

export function getProgramManualTags(payload) {
  return success(ACTION_CONSTANTS.GET_PROGRAM_MANUAL_TAG, payload);
}

export function setCurrentProgramManual(payload) {
  return success(ACTION_CONSTANTS.SET_CURRENT_PROGRAM_MANUAL, payload);
}

export function resetSOPProps(payload) {
  return success(ACTION_CONSTANTS.RESET_SOP_PROPS, payload);
}

export function getProgramManualByIdRequest(payload) {
  return request(ACTION_CONSTANTS.GET_PROGRAM_MANUAL_BY_ID_REQUEST, payload);
}

export function getProgramManualById(payload) {
  return request(ACTION_CONSTANTS.GET_PROGRAM_MANUAL_BY_ID, payload);
}

export function getProgramManualByIdSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_PROGRAM_MANUAL_BY_ID_SUCCESS, payload);
}

export function getProgramManualByIdFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_PROGRAM_MANUAL_BY_ID_FAILURE, payload);
}

export function deleteProgramManualUrlRequest(payload) {
  return request(ACTION_CONSTANTS.DELETE_PROGRAM_MANUAL_URL_REQUEST, payload);
}

export function deleteProgramManualUrl(payload) {
  return request(ACTION_CONSTANTS.DELETE_PROGRAM_MANUAL_URL, payload);
}

export function deleteProgramManualUrlSuccess(payload) {
  return request(ACTION_CONSTANTS.DELETE_PROGRAM_MANUAL_URL_SUCCESS, payload);
}

export function deleteProgramManualUrlFailure(payload) {
  return request(ACTION_CONSTANTS.DELETE_PROGRAM_MANUAL_URL_FAILURE, payload);
}

export function updateProgramManualUrlRequest(payload) {
  return request(ACTION_CONSTANTS.UPDATE_PROGRAM_MANUAL_URL_REQUEST, payload);
}

export function updateProgramManualUrl(payload) {
  return request(ACTION_CONSTANTS.UPDATE_PROGRAM_MANUAL_URL, payload);
}

export function updateProgramManualUrlSuccess(payload) {
  return request(ACTION_CONSTANTS.UPDATE_PROGRAM_MANUAL_URL_SUCCESS, payload);
}

export function updateProgramManualUrlFailure(payload) {
  return request(ACTION_CONSTANTS.UPDATE_PROGRAM_MANUAL_URL_FAILURE, payload);
}

export function getProgramManualActivitiesByIdRequest(payload) {
  return request(
    ACTION_CONSTANTS.GET_PROGRAM_MANUAL_ACTIVITIES_BY_ID_REQUEST,
    payload,
  );
}

export function getProgramManualActivitiesById(payload) {
  return request(ACTION_CONSTANTS.GET_PROGRAM_MANUAL_ACTIVITIES_BY_ID, payload);
}

export function getProgramManualActivitiesByIdSuccess(payload) {
  return success(
    ACTION_CONSTANTS.GET_PROGRAM_MANUAL_ACTIVITIES_BY_ID_SUCCESS,
    payload,
  );
}

export function getProgramManualActivitiesByIdFailure(payload) {
  return failure(
    ACTION_CONSTANTS.GET_PROGRAM_MANUAL_ACTIVITIES_BY_ID_FAILURE,
    payload,
  );
}

export function setVisibleToUsers(payload) {
  return request(ACTION_CONSTANTS.SET_VISIBLE_TO_USERS, payload);
}

export function setSelectedTags(payload) {
  return request(ACTION_CONSTANTS.SET_SELECTED_TAGS, payload);
}

export function createTrainingRequest(payload) {
  return request(ACTION_CONSTANTS.CREATE_TRAINING_REQUEST, payload);
}

export function createTraining(payload) {
  return request(ACTION_CONSTANTS.CREATE_TRAINING, payload);
}

export function createTrainingSuccess(payload) {
  return success(ACTION_CONSTANTS.CREATE_TRAINING_SUCCESS, payload);
}

export function createTrainingFailure(payload) {
  return failure(ACTION_CONSTANTS.CREATE_TRAINING_FAILURE, payload);
}

export function updateTrainingRequest(payload) {
  return request(ACTION_CONSTANTS.UPDATE_TRAINING_REQUEST, payload);
}

export function updateTraining(payload) {
  return request(ACTION_CONSTANTS.UPDATE_TRAINING, payload);
}

export function updateTrainingSuccess(payload) {
  return success(ACTION_CONSTANTS.UPDATE_TRAINING_SUCCESS, payload);
}

export function updateTrainingFailure(payload) {
  return failure(ACTION_CONSTANTS.UPDATE_TRAINING_FAILURE, payload);
}

export function resetCreateProps(payload) {
  return failure(ACTION_CONSTANTS.RESET_CREATE_TRAINING, payload);
}

export function resetFilterProps(payload) {
  return failure(ACTION_CONSTANTS.RESET_FILTER_TRAINING, payload);
}

export function resetErrorProps(payload) {
  return failure(ACTION_CONSTANTS.RESET_ERROR_TRAINING, payload);
}

export function resetProgramManualDetail(payload) {
  return failure(ACTION_CONSTANTS.RESET_PROGRAM_MANUAL_DETAIL, payload);
}

export function getDeprecatedProgramManualsRequest(payload) {
  return request(
    ACTION_CONSTANTS.GET_DEPRECATED_PROGRAM_MANUAL_REQUEST,
    payload,
  );
}

export function getDeprecatedProgramManuals(payload) {
  return request(ACTION_CONSTANTS.GET_DEPRECATED_PROGRAM_MANUAL, payload);
}

export function getDeprecatedProgramManualsSuccess(payload) {
  return success(
    ACTION_CONSTANTS.GET_DEPRECATED_PROGRAM_MANUAL_SUCCESS,
    payload,
  );
}

export function getDeprecatedProgramManualsFailure(payload) {
  return failure(
    ACTION_CONSTANTS.GET_DEPRECATED_PROGRAM_MANUAL_FAILURE,
    payload,
  );
}

export function getMissedProgramManualsRequest(payload) {
  return request(ACTION_CONSTANTS.GET_MISSED_PROGRAM_MANUAL_REQUEST, payload);
}

export function getMissedProgramManuals(payload) {
  return request(ACTION_CONSTANTS.GET_MISSED_PROGRAM_MANUAL, payload);
}

export function getMissedProgramManualsSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_MISSED_PROGRAM_MANUAL_SUCCESS, payload);
}

export function getMissedProgramManualsFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_MISSED_PROGRAM_MANUAL_FAILURE, payload);
}

export function moveProgramManualToActiveRequest(payload) {
  return request(
    ACTION_CONSTANTS.MOVE_PROGRAM_MANUALS_TO_ACTIVE_REQUEST,
    payload,
  );
}

export function moveProgramManualToActive(payload) {
  return request(ACTION_CONSTANTS.MOVE_PROGRAM_MANUALS_TO_ACTIVE, payload);
}

export function moveProgramManualToActiveSuccess(payload) {
  return success(
    ACTION_CONSTANTS.MOVE_PROGRAM_MANUALS_TO_ACTIVE_SUCCESS,
    payload,
  );
}

export function moveProgramManualToActiveFailure(payload) {
  return failure(
    ACTION_CONSTANTS.MOVE_PROGRAM_MANUALS_TO_ACTIVE_FAILURE,
    payload,
  );
}

export function markViewedRequest(payload) {
  return request(ACTION_CONSTANTS.MARK_VIEWED_REQUEST, payload);
}

export function markViewed(payload) {
  return request(ACTION_CONSTANTS.MARK_VIEWED, payload);
}

export function markViewedSuccess(payload) {
  return success(ACTION_CONSTANTS.MARK_VIEWED_SUCCESS, payload);
}

export function markViewedFailure(payload) {
  return failure(ACTION_CONSTANTS.MARK_VIEWED_FAILURE, payload);
}

export function moveProgramManualToBinRequest(payload) {
  return request(ACTION_CONSTANTS.MOVE_PROGRAM_MANUALS_TO_BIN_REQUEST, payload);
}

export function moveProgramManualToBin(payload) {
  return request(ACTION_CONSTANTS.MOVE_PROGRAM_MANUALS_TO_BIN, payload);
}

export function moveProgramManualToBinSuccess(payload) {
  return success(ACTION_CONSTANTS.MOVE_PROGRAM_MANUALS_TO_BIN_SUCCESS, payload);
}

export function moveProgramManualToBinFailure(payload) {
  return failure(ACTION_CONSTANTS.MOVE_PROGRAM_MANUALS_TO_BIN_FAILURE, payload);
}

//#region get Documents
export function getDocumentList(payload) {
  return request(ACTION_CONSTANTS.GET_DOCUMENTS, payload);
}

export function getDocumentListRequested(payload) {
  return request(ACTION_CONSTANTS.GET_DOCUMENTS_REQUEST, payload);
}

export function getDocumentListSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_DOCUMENTS_SUCCESS, payload);
}

export function getDocumentListFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_DOCUMENTS_FAILURE, payload);
}
//#endregion

export function emailReportRequest(payload) {
  return request(ACTION_CONSTANTS.EMAIL_REPORT_REQUEST, payload);
}

export function emailReport(payload) {
  return request(ACTION_CONSTANTS.EMAIL_REPORT, payload);
}

export function emailReportSuccess(payload) {
  return success(ACTION_CONSTANTS.EMAIL_REPORT_SUCCESS, payload);
}

export function emailReportFailure(payload) {
  return failure(ACTION_CONSTANTS.EMAIL_REPORT_FAILURE, payload);
}

export function filterUsersRequest(payload) {
  return request(ACTION_CONSTANTS.FILTER_DOCUMENT_USERS, payload);
}
