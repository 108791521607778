let _token = null,
  _username = '',
  _userRole = null,
  _userId = null,
  _locale = 'en',
  _lastSyncTime = null,
  _isFirstLogin = true,
  _selectedFeedlotName = '',
  _selectedFeedlotTrainingRulesEnabled = 0,
  _selectedFeedlotRankEnabled = 0,
  _selectedFeedlotId = null,
  _userFirstName = '',
  _userLastName = '',
  _isSessionExpired = false;

export const setToken = (token) => {
  _token = token;
};

export const getToken = () => {
  return _token;
};

export const setUsername = (username) => {
  _username = username;
};

export const getUsername = () => {
  return _username;
};

export const setUserRole = (userRole) => {
  _userRole = userRole;
};

export const getUserRole = () => {
  return _userRole;
};

export const setUserId = (userId) => {
  _userId = userId;
};

export const getUserId = () => {
  return _userId;
};

export const setLocale = (locale) => {
  _locale = locale;
  localStorage.setItem('language', locale);
};

export const getLocale = () => {
  return localStorage.getItem('language') || 'en';
};

export const setLastSyncTime = (lastSyncTime) => {
  _lastSyncTime = lastSyncTime;
};

export const getLastSyncTime = () => {
  return _lastSyncTime;
};

export const setFirstLogin = (isFirstLogin) => {
  _isFirstLogin = isFirstLogin;
};

export const getFirstLogin = () => {
  return _isFirstLogin;
};

export const setSelectedFeedlotName = (selectedFeedlotName) => {
  _selectedFeedlotName = selectedFeedlotName;
};

export const getSelectedFeedlotName = () => {
  return _selectedFeedlotName;
};

export const setSelectedFeedlotId = (selectedFeedlotId) => {
  _selectedFeedlotId = selectedFeedlotId;
};

export const getSelectedFeedlotId = () => {
  return _selectedFeedlotId;
};

export const setSelectedFeedlotTrainingRulesEnabled = (
  selectedFeedlotTrainingRulesEnabled,
) => {
  _selectedFeedlotTrainingRulesEnabled = selectedFeedlotTrainingRulesEnabled;
};

export const getSelectedFeedlotTrainingRulesEnabled = () => {
  return _selectedFeedlotRankEnabled;
};

export const setSelectedFeedlotRankEnabled = (selectedFeedlotRankEnabled) => {
  _selectedFeedlotRankEnabled = selectedFeedlotRankEnabled;
};

export const getSelectedFeedlotRankEnabled = () => {
  return _selectedFeedlotRankEnabled;
};

export const setUserFirstName = (firstName) => {
  _userFirstName = firstName;
};

export const getUserFirstName = () => {
  return _userFirstName;
};

export const setUserLastName = (lastName) => {
  _userLastName = lastName;
};

export const getUserLastName = () => {
  return _userLastName;
};

export const setSessionExpired = (isSessionExpired) => {
  _isSessionExpired = isSessionExpired;
};

export const getSessionExpired = () => {
  return _isSessionExpired;
};
