export default {
  activities: 'Activities',
  selectTags: 'Select Tags',
  activity: 'Activity',
  all: 'All',
  assigned: 'Assigned',
  unassigned: 'Unassigned',
  search: 'Search',
  applyTags: 'Apply Tags',
  instance: 'Instance',
  assignLog: 'Assign Log',
  activityBin: 'Activity Bin',

  // Separator
  dueToday: 'Due Today',
  dueWeek: 'Due This Week',
  dueMonth: 'Due This Month',
  dueQuarter: 'Due This Quarter',
  dueYear: 'Due This Year',
  upcoming: 'Upcoming',
  unscheduled: 'Unscheduled',

  done: 'Done',
  yes: 'Yes',
  ok: 'Ok',
  error: 'Error',
  success: 'Success',
  unableToReach:
    'Unable to reach the server. Kindly check your internet connection',
  cancel: 'Cancel',
  errorMessages: {
    NO_NETWORK_CONNECTION: {
      TITLE: 'Network Error',
      MESSAGE: 'No Internet Connectivity',
    },
    GENERAL_ERROR: {
      TITLE: 'Error',
      MESSAGE:
        'Sorry! There seems to be some problem at the moment. Please try later!',
    },
    UNAUTORIZED: {
      MESSAGE: 'You are not authorized',
    },
    FORBIDDEN: {
      MESSAGE: 'Request is forbidden',
    },
    REQUEST_NOT_FOUND: {
      MESSAGE: 'Request not found',
    },
    METHOD_NOT_ALLOWED: {
      MESSAGE: 'Method Not Allowed',
    },
    REQUEST_TIMEOUT: {
      MESSAGE: 'Request Timed Out',
    },
  },
};
