import { LoginType, PIN_CODE_LENGTH } from '../constants/AppConstants';
import localization from '../localization/i18n';
import { getLocale } from '../services/storageService/GlobalData';

const Regex = {
  EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  PASSWORD: /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
};

export const validateNewUserPin = (newPin, confirmPin) => {
  try {
    if (!newPin || !confirmPin || newPin == '' || confirmPin == '') {
      return localization[getLocale()].EMPTY_FIELD;
    } else if (isNaN(newPin) == true || isNaN(confirmPin) == true) {
      return localization[getLocale()].INVALID_PIN;
    } else if (
      newPin.toString().length != 4 ||
      confirmPin.toString().length != 4
    ) {
      return localization[getLocale()].PIN_LENGTH_ERROR;
    } else if (newPin.toString() !== confirmPin.toString()) {
      return localization[getLocale()].PIN_MATCH_ERROR;
    } else {
      return '';
    }
  } catch (e) {
    console.log(e);
    return 'error';
  }
};

///// Forgot Password Screen Form Validators

const validateEmail = (value, props) => {
  let errors = '';
  if (!value) {
    errors = 'Required';
  }
  if (value && !Regex.EMAIL.test(value)) {
    errors = 'Invalid email address';
  }
  return errors;
};

const validateUsername = (value, props) => {
  let errors = '';
  if (!value) {
    errors = 'Requried';
  }
  return errors;
};

// EO | Forgot Password Screen Form Validators

///// Setting Screen Form Validators

const passwordValidator = (value) => {
  let errors = {};
  if (!value.oldPassword) {
    errors.oldPassword = 'Required';
  }
  if (!value.newPassword) {
    errors.newPassword = 'Required';
  }

  // TODO: Uncomment it later when requirement changes for confirm and new password

  // if (value.confirm && value.new) {
  //   if (value.confirm !== value.new) {
  //     errors.confirm = "Password Must Match";
  //   }
  // }

  if (!!value.newPassword && value.newPassword.length < 8) {
    errors.newPassword = 'Min 8 characters Required';
  }
  // if (
  //   !!value.newPassword &&
  //   value.newPassword.length >= 8 &&
  //   !Regex.PASSWORD.test(value.newPassword)
  // ) {
  //   errors.newPassword = "Should Contain A-Z, 0-9 & [@,&,!..]";
  // }
  return errors;
};

const pinValidator = (value) => {
  let errors = {};
  if (!value.oldPin) {
    errors.oldPin = 'Required';
  }
  if (!value.newPin) {
    errors.newPin = 'Required';
  }

  if (!!value.newPin && value.newPin.length > 4) {
    errors.newPin = 'Maximum 4 characters Required';
  }

  return errors;
};

const validateLoginRequest = (request, requestType) => {
  let isValid = false;
  if (requestType === LoginType.PASSWORD) {
    if (request.user_name.length && request.password.length) {
      isValid = true;
    }
  } else {
    if (request.user_name.length && request.pin.length) {
      isValid = true;
    }
  }
  return isValid;
};

const validatePin = (value) => {
  let isValid = false;
  if (value && value.length === PIN_CODE_LENGTH) {
    isValid = true;
  }
  return isValid;
};

// EO | Setting Screen Form Validators

export const ChangeIdentificaitonFormValidator = {
  passwordValidator,
  pinValidator,
};

export const ForgotPasswordFormValidator = {
  validateEmail,
  validateUsername,
};

export const LoginFormValidator = {
  validateLoginRequest,
};

export const PinFormValidator = {
  validatePin,
};

export const ProfileFormValidator = {};
