import { request, success, failure } from './index';
import ACTION_CONSTANTS from '../../constants/actionConstants';

export function setPerformanceStartDate(payload) {
  return request(ACTION_CONSTANTS.PERFORMANCE_START_DATE, payload);
}

export function setPerformanceEndDate(payload) {
  return request(ACTION_CONSTANTS.PERFORMANCE_END_DATE, payload);
}
