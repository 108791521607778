/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import IcomoonReact from 'icomoon-react';
import iconSet from './selection.json';

class PBIcon extends PureComponent {
  render() {
    const { name, onPress, color, size } = this.props;
    return (
      <a style={color && { fill: color }}>
        <div onClick={onPress}>
          <IcomoonReact iconSet={iconSet} size={size ?? 24} icon={name} />
        </div>
      </a>
    );
  }
}

export default PBIcon;
