import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

class AlertModal extends Component {
  static myLoaderInstance;

  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
      title: '',
      desc: '',
      buttons: [],
    };

    AlertModal.myLoaderInstance = this;
  }

  static alert(title, desc, buttons) {
    this.myLoaderInstance._show(title, desc, buttons);
  }

  _show(title, desc, buttons) {
    this.setState({ isVisible: true, title, desc, buttons });
  }

  static hide() {
    this.myLoaderInstance._hide();
  }

  _hide() {
    this.setState({ isVisible: false });
  }

  updateState = (isVisible) => {
    this.setState({ isVisible });
  };

  closePopUp = () => {
    this.setState({ isVisible: false })
  }

  render() {
    const { title, desc, buttons } = this.state;

    return (
      <>
        <Modal isOpen={this.state.isVisible} toggle={this.closePopUp} className={'modal-dialog'}>
          <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
          <ModalBody>
            {desc}
          </ModalBody>
          <ModalFooter>
            {
              buttons ? buttons.map((item) => {
                return (
                  <Button color="primary" onClick={() => {
                    item.onPress();
                    this.closePopUp();
                  }}>{item.text}</Button>
                )
              }) :
                <Button color="primary" onClick={() => {
                  this.closePopUp();
                }}>{'Ok'}</Button>
            }
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default AlertModal;
