import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';

import AppContainer from '../../../../../components/AppContainer';
import MultiSelectList from '../../../../../components/MultiSelectList';
import PBIcon from '../../../../../components/PBIcon';

import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import localization from '@progressivebeef/shared/src/localization/i18n';

import {
  filterBackdatedActivities,
  getBackdatedActivitiesRequest,
} from '@progressivebeef/shared/src/store/actions/dashboard';

class BackdatedFilter extends PureComponent {
  filterPress = (filter) => {
    const tabType = this.props.type;
    this.props.filterActivities(filter);
    this.props.getActivities({ type: tabType });
    this.props.dismissModal();
  };

  getFilterOptions() {
    const { filter, filterOptions: options, tags } = this.props;
    var taglist = tags ? tags : [];
    let filterOptions = [...options, ...taglist];
    return filterOptions.map(function (item) {
      const index = filter.findIndex((m) => m.key === item.key);
      if (index === -1) {
        return item;
      } else {
        return filter[index];
      }
    });
  }

  render() {
    let filterOptions = this.getFilterOptions();
    let { history, modalVisible } = this.props;
    return (
      <Modal className="visible-to-user-popup" isOpen={modalVisible}>
        <AppContainer
          iconHeader
          noncollapsible
          title={localization[getLocale()].SELECT_TAGS}
          IconLeftHeader={this.renderLeftHeader}
          history={history}
        >
          <MultiSelectList
            buttonText={localization[getLocale()].APPLY_TAGS}
            data={filterOptions}
            onPress={this.filterPress}
          />
        </AppContainer>
      </Modal>
    );
  }

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          name={'chevron-left'}
          onPress={() => this.props.dismissModal()}
        />
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    filterActivities: (payload) => dispatch(filterBackdatedActivities(payload)),
    getActivities: (payload) =>
      dispatch(getBackdatedActivitiesRequest(payload)),
  };
};

const mapStateToProps = (state) => {
  const { backdatedFilter } = state.dashboard;
  const { tags } = state.activities;

  return {
    filter: backdatedFilter,
    tags,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BackdatedFilter);
