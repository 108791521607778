let Config,
  RNbCrypt,
  Linking,
  Platform = null;

if (process.env.REACT_APP_PLATFORM !== 'WEB') {
  import('react-native-config').then((conf) => {
    Config = conf;
  });

  import('react-native-bcrypt').then((RNbCrypt) => {
    RNbCrypt = RNbCrypt;
  });

  import('react-native').then(({ Linking, Platform }) => {
    Linking = Linking;
    Platform = Platform;
  });
} else {
  RNbCrypt = require('bcryptjs');
}

//constants
import { userRoles } from '../constants/AppConstants';
import { ENVIRONMENT } from '../constants/AppConstants';

class AuthenticationHelper {
  checkUserAuthorization = (user) => {
    //Dont allow internal and external reviewer
    return (
      user.user_role_key !== userRoles.INTERNAL_REVIEWER &&
      user.user_role_key !== userRoles.EXTERNAL_REVIEWER
    );
  };

  getVersionUpdate(currentVersion, newVersion) {
    let versionUpdate = false;
    if (typeof newVersion + typeof currentVersion == 'stringstring') {
      var newVersionArray = newVersion.split('.'),
        currentVersionArray = currentVersion.split('.'),
        len = Math.max(newVersionArray.length, currentVersionArray.length);

      for (var i = 0; i < len; i++) {
        if (
          (newVersionArray[i] &&
            !currentVersionArray[i] &&
            parseInt(newVersionArray[i]) > 0) ||
          parseInt(newVersionArray[i]) > parseInt(currentVersionArray[i])
        ) {
          versionUpdate = true;
          break;
        } else if (
          (currentVersionArray[i] &&
            !newVersionArray[i] &&
            parseInt(currentVersionArray[i]) > 0) ||
          parseInt(newVersionArray[i]) < parseInt(currentVersionArray[i])
        ) {
          break;
        }
      }
    }
    return versionUpdate;
  }

  openDownloadLink = () => {
    let url = null;
    // TODO: Move links to constants file
    if (Platform.OS === 'ios') {
      switch (Config.environment || ENVIRONMENT.QA) {
        case ENVIRONMENT.PRODUCTION:
          url = 'itms-apps://itunes.apple.com/us/app/prog.-beef/id995848222';
          break;
        case ENVIRONMENT.STAGING:
          url = 'https://appcenter.ms/users/smaidah/apps/PB-Azure-Staging-1';
          break;
        case ENVIRONMENT.QA:
          url =
            'https://install.appcenter.ms/orgs/saadali-fmww/apps/progressive-beef-qa/distribution_groups/public';
          break;
        default:
          break;
      }
    } else {
      // android
      switch (Config.environment || ENVIRONMENT.QA) {
        case ENVIRONMENT.PRODUCTION:
          url = 'market://details?id=com.pb.PB';
          break;
        case ENVIRONMENT.STAGING:
          url = 'https://appcenter.ms/users/smaidah/apps/PB-Azure-Staging/';
          break;
        case ENVIRONMENT.QA:
          url =
            'https://install.appcenter.ms/orgs/saadali-fmww/apps/progressive-beef-qa-1/distribution_groups/public';
          break;
        default:
          break;
      }
    }

    if (url) {
      Linking.openURL(url);
    }
  };

  comparePin = (currentUserpin, enteredPin) => {
    return new Promise((resolve, reject) => {
      try {
        if (process.env.REACT_APP_PLATFORM !== 'WEB') {
          RNbCrypt.compare(enteredPin, currentUserpin, (resp, pinMatched) => {
            resolve(pinMatched);
          });
        } else {
          RNbCrypt.compare(enteredPin, currentUserpin, (resp, pinMatched) => {
            resolve(pinMatched);
          });
        }
      } catch (err) {
        reject(err);
      }
    });
  };
}

export default new AuthenticationHelper();
