// modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';

import { showAlertMsg, showErrorAlert } from '../../../components/Alerts';
import PBIcon from '../../../components/PBIcon';

//constants
import {
  validatePinRequested,
  logoutRequested,
  resetLockScreenProps,
} from '@progressivebeef/shared/src/store/actions/authentication';
import logo from '../../../assets/img/brand/pb-logo.png';

import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import { PIN_CODE_LENGTH } from '@progressivebeef/shared/src/constants/AppConstants';

// style
import './lockscreen.scss';

const buttonArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 'x', 0, 'OK'];

const ViewButton = ({ onButtonPress, text, disabled }) => {
  return (
    <div
      className={disabled ? 'num disabledDiv' : 'num'}
      onClick={() => onButtonPress()}>
      {text === 'x' ? (
        <PBIcon name={'times'} />
      ) : (
        <a>
          <div>{text.toString()}</div>
        </a>
      )}
    </div>
  );
};

class LockScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: '',
    };
  }

  componentDidUpdate(prevProps) {
    let { isLogin, success, error, history, resetLockScreenProps } = this.props;

    // if (isLoading) {
    //   showLoaderView();
    // } else {
    //   hideLoaderView();
    // }

    if (error !== prevProps.error) {
      if (typeof error === 'string') {
        showErrorAlert(error);
      }
    }

    if (!isLogin) {
      history.push('/Login');
    }
    //redirect to previous screen or dashboard
    else if (success) {
      resetLockScreenProps();
      history.replace(
        window.history.state && window.history.state.state
          ? window.history.state.state.from.pathname
          : '/',
      );
    }
  }

  clearInput = () => {
    this.setState({
      input: '',
    });
  };

  onButtonPressHandle = (key) => {
    let { input } = this.state;

    if (key === 'x') {
      this.clearInput();
    } else if (input.length < PIN_CODE_LENGTH) {
      this.setState({
        input: (input += '' + key),
      });
    } else if (key === 'OK' || input.length === PIN_CODE_LENGTH) {
      this.onPinComplete(input, this.clearInput);
    }
  };

  onPinComplete = (val, clear) => {
    clear();
    this.props.validatePin(val);
  };

  render() {
    let { input } = this.state;
    return (
      <div className="signInBody">
        <div>
          <Col className="loginSection lockscreens-sec add">
            <Col md="12" className="loginInnerSection">
              <Row>
                <Col md="12" className="logout-btn">
                  <Button
                    color="primary"
                    block
                    onClick={() => {
                      showAlertMsg(
                        localization[getLocale()].CONFIRMATION.toUpperCase(),
                        localization[getLocale()].LOGOUT_CONFIRMATION,
                        [
                          {
                            text: 'No',
                            onPress: () => {
                              'cancel';
                            },
                          },
                          {
                            text: 'Yes',
                            onPress: () => {
                              if (navigator.onLine) {
                                this.props.history.push('/Logout');
                              } else {
                                setTimeout(() => {
                                  showErrorAlert(
                                    localization[getLocale()]
                                      .NO_INTERNET_CONNECTION,
                                  );
                                }, 100);
                              }
                            },
                          },
                        ],
                      );
                    }}>
                    <i className="fal fa-sign-out"></i>
                  </Button>
                </Col>
              </Row>
              <div className="logo">
                <img alt="Progresive Beef" src={logo} />
              </div>

              <div className="lockscreen-pin-field">
                <div className="label">
                  {' '}
                  {localization[getLocale()].ENTER_PIN}
                </div>
                <div className="pin-print-box">
                  {Array.apply(null, { length: PIN_CODE_LENGTH }).map(
                    (e, i) => (
                      <div className="star" key={'lock-input' + i}>
                        {input.length > i ? '*' : ''}
                      </div>
                    ),
                  )}
                </div>
              </div>

              <div className="lockscreen-number-box">
                {buttonArray.map((item) => {
                  return (
                    <ViewButton
                      disabled={
                        item === 'OK' && input.length !== PIN_CODE_LENGTH
                          ? true
                          : false
                      }
                      onButtonPress={() => this.onButtonPressHandle(item)}
                      text={item}
                    />
                  );
                })}
              </div>
            </Col>
          </Col>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    validatePin: (pin) => dispatch(validatePinRequested({ pin })),
    resetLockScreenProps: () => dispatch(resetLockScreenProps()),
    logout: () => dispatch(logoutRequested()),
  };
};

const mapStateToProps = (state) => {
  let {
    isLogin,
    success,
    error,
    isLoading,
    isFaceUnlockEnabled,
    isFingerPrintEnabled,
  } = state.authentication;
  return {
    isLogin,
    success,
    error,
    isLoading,
    isFaceUnlockEnabled,
    isFingerPrintEnabled,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LockScreen);
