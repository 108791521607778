import React from 'react';
import PropTypes from 'prop-types';
import PBIcon from '../../../../components/PBIcon';
import NotificationHelper from '@progressivebeef/shared/src/helpers/NotificationsHelper';
import './style.scss';

import { NOTIFICATION_EVENTS } from '@progressivebeef/shared/src/constants/AppConstants';
import AssetConstants from '@progressivebeef/shared/src/constants/AssetConstants';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';

import { trackEvent } from '@progressivebeef/shared/src/services/reactMixpanelService';
import {
  Events,
  Properties,
} from '@progressivebeef/shared/src/constants/MixpanelConstants';
import ActivitiesHelper from '@progressivebeef/shared/src/helpers/ActivitiesHelper';
import languageService from '@progressivebeef/shared/src/services/languageService';

var click = true;

const IconItem = (props) => {
  const { title, subTitle, iconName, onPress, customClass, pullToRef } = props;

  return (
    <div
      className={'notification-listing-box ' + customClass}
      onMouseDown={(e) => (click = true)}
      onMouseMove={(e) => (click = false)}
      onClick={() => {
        if (click && !pullToRef) {
          onPress();
        }
      }}>
      <div className="icon">
        <PBIcon name={iconName} />
      </div>
      <div className="desc">
        <div className="title">{title}</div>
        <div className="sub-title">{subTitle}</div>
      </div>
    </div>
  );
};

IconItem.propTypes = {
  iconColor: PropTypes.string,
  titleColor: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  iconName: PropTypes.string,
  onPress: PropTypes.func,
};

const ImageItem = (props) => {
  const { src, title, subTitle, onPress, customClass, pullToRef } = props;

  return (
    <div
      className={'notification-listing-box ' + customClass}
      onMouseDown={(e) => (click = true)}
      onMouseMove={(e) => (click = false)}
      onClick={() => {
        if (click && !pullToRef) {
          onPress();
        }
      }}>
      <div className="icon">
        <img alt="desc" src={src} />
      </div>
      <div className="desc">
        <div className="title">{title}</div>
        <div className="sub-title">{subTitle}</div>
      </div>
    </div>
  );
};

ImageItem.propTypes = {
  src: PropTypes.any,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  titleColor: PropTypes.string,
  onPress: PropTypes.func,
};

const NotificationItem = (props) => {
  const { item } = props;
  const event = item.event;
  if (event === NOTIFICATION_EVENTS.ACTIVITY_REMINDER) {
    const { heading, subHeading, iconColor, iconName, titleColor } =
      NotificationHelper.getReminderData(item);
    return (
      <IconItem
        onPress={() => {
          props.history.replace(RouteConstants.ACTIVITIES);
        }}
        iconColor={iconColor}
        customClass={'reminder'}
        titleColor={titleColor}
        iconName={iconName}
        title={heading}
        subTitle={subHeading}
      />
    );
  } else if (
    event === NOTIFICATION_EVENTS.ACTIVITY_DUE_TODAY ||
    event === NOTIFICATION_EVENTS.ACTIVITY_LOG8A_DUE_TODAY ||
    event === NOTIFICATION_EVENTS.ACTIVITY_LOG8_POINT_ONE_DUE_TODAY
  ) {
    const { heading, subHeading, iconColor, iconName, titleColor } =
      NotificationHelper.getDueTodayData(item);
    return (
      <IconItem
        onPress={() => {
          props.history.replace(RouteConstants.ACTIVITIES);
        }}
        iconColor={iconColor}
        titleColor={titleColor}
        title={heading}
        customClass={'due-today'}
        subTitle={subHeading}
        iconName={iconName}
      />
    );
  } else if (event === NOTIFICATION_EVENTS.ACTIVITY_MISSED_RECENTLY) {
    const { heading, subHeading } =
      NotificationHelper.getActivityMissedData(item);
    return (
      <ImageItem
        onPress={() => {
          props.history.replace(RouteConstants.ACTIVITIES);
        }}
        src={AssetConstants.missed}
        customClass={'missed'}
        title={heading}
        subTitle={subHeading}
      />
    );
  } else if (event === NOTIFICATION_EVENTS.ACTIVITY_SUBMISSION_VIOLATION) {
    const { heading, subHeading, iconColor, iconName, titleColor } =
      NotificationHelper.getSubmissionVoilation(item);
    const currentActivity = item.feedlot_activity;
    const currentInstance = item.submission.feedlot_activity_instance;
    const submission = item.submission;
    const violationData = item.submission.submission_violation_history.data;
    let params = {
      currentActivity,
      submission,
      disabled: true,
      violationData,
    };
    if (currentInstance) {
      params.currentInstance = currentInstance;
    }
    let fieldsViolated = JSON.parse(violationData);
    let activityFields = currentActivity.fields;
    let properties = {};
    let violatedFieldsLabels = ActivitiesHelper.getVoilatedRuleFieldLabels(
      fieldsViolated,
      activityFields,
    );
    properties[Properties.VIOLATION_FIELDS] = violatedFieldsLabels.toString();
    properties[Properties.ACTIVITY_NAME] =
      currentActivity.locale[languageService()].title;
    properties[Properties.ACTIVITY_NUMBER] = currentActivity.activity_number;
    trackEvent(Events.RULE_BASE_PUSH, properties);

    return (
      <IconItem
        onPress={() => {
          props.history.push(RouteConstants.ADD_SUBMISSION, params);
        }}
        customClass={'violation'}
        iconColor={iconColor}
        titleColor={titleColor}
        iconName={iconName}
        title={heading}
        subTitle={subHeading}
      />
    );
  } else if (event === NOTIFICATION_EVENTS.DOCUMENTS_READY) {
    const { heading, subHeading, iconColor, iconName, titleColor } =
      NotificationHelper.getDocumentsReadyData(item);
    const pdfSource = NotificationHelper.getDocumentReadyPdfSource(item);
    return (
      <IconItem
        onPress={() => {
          props.history.push(RouteConstants.VIEW_DOCUMENT_REPORT, {
            pdfSource,
          });
        }}
        customClass={'ready'}
        iconColor={iconColor}
        titleColor={titleColor}
        iconName={iconName}
        title={heading}
        subTitle={subHeading}
      />
    );
  } else if (event === NOTIFICATION_EVENTS.TRAINING_DUE_TODAY) {
    const { heading, subHeading, iconColor, iconName, titleColor } =
      NotificationHelper.getTrainingDueTodayData(item);
    return (
      <IconItem
        onPress={() => {
          props.history.push(RouteConstants.PROGRAM_MANUAL, {
            screen: RouteConstants.VIEW_DOCUMENT,
            initial: false,
            params: { currentProgramManual: item.feedlot_document },
          });
        }}
        customClass={'ready'}
        iconColor={iconColor}
        titleColor={titleColor}
        iconName={iconName}
        title={heading}
        subTitle={subHeading}
      />
    );
  } else if (event === NOTIFICATION_EVENTS.TRAINING_VIEWED) {
    const { heading, subHeading, iconColor, iconName, titleColor } =
      NotificationHelper.getTrainingViewedData(item);
    return (
      <IconItem
        onPress={() => {
          props.history.push(RouteConstants.PROGRAM_MANUAL, {
            screen: RouteConstants.VIEW_DOCUMENT,
            initial: false,
            params: { currentProgramManual: item.feedlot_document },
          });
        }}
        customClass={'ready'}
        iconColor={iconColor}
        titleColor={titleColor}
        iconName={iconName}
        title={heading}
        subTitle={subHeading}
      />
    );
  } else if (event === NOTIFICATION_EVENTS.TRAINING_MISSED) {
    const { heading, subHeading, iconColor, iconName, titleColor } =
      NotificationHelper.getTrainingMissedData(item);
    return (
      <IconItem
        onPress={() => {
          props.history.push(RouteConstants.PROGRAM_MANUAL, {
            screen: RouteConstants.VIEW_DOCUMENT,
            initial: false,
            params: { currentProgramManual: item.feedlot_document },
          });
        }}
        customClass={'ready'}
        iconColor={iconColor}
        titleColor={titleColor}
        iconName={iconName}
        title={heading}
        subTitle={subHeading}
      />
    );
  } else if (event === NOTIFICATION_EVENTS.ACTIVITY_INSTANCE_ASSIGNED) {
    const { heading, subHeading, iconColor, iconName, titleColor } =
      NotificationHelper.getActivityInstanceAssignedData(item);
    return (
      <IconItem
        onPress={() => {
          props.history.replace(RouteConstants.ACTIVITIES);
        }}
        customClass={'assigned'}
        iconColor={iconColor}
        titleColor={titleColor}
        iconName={iconName}
        title={heading}
        subTitle={subHeading}
      />
    );
  } else if (event === NOTIFICATION_EVENTS.ACTIVITY_LOG8A_FILLED) {
    const { heading, subHeading, iconColor, iconName, titleColor } =
      NotificationHelper.getActivityLog8FilledData(item);
    return (
      <IconItem
        onPress={() => {
          props.history.replace(RouteConstants.ACTIVITIES);
        }}
        customClass={'ready'}
        iconColor={iconColor}
        titleColor={titleColor}
        iconName={iconName}
        title={heading}
        subTitle={subHeading}
      />
    );
  } else if (event === NOTIFICATION_EVENTS.ACTIVITY_LOG8_MARKED_COMPLETED) {
    const { heading, subHeading, iconColor, iconName, titleColor } =
      NotificationHelper.getActivityLog8CompletedData(item);
    return (
      <IconItem
        onPress={() => {
          props.history.replace(RouteConstants.ACTIVITIES);
        }}
        iconColor={iconColor}
        titleColor={titleColor}
        iconName={iconName}
        title={heading}
        subTitle={subHeading}
      />
    );
  } else if (event === NOTIFICATION_EVENTS.ACTIVITY_FEEDYARD_REVISION_UPDATE) {
    const { heading, subHeading, iconColor, iconName, titleColor } =
      NotificationHelper.getActivityRevisionUpdate(item);
    return (
      <IconItem
        onPress={() => {
          props.history.replace(RouteConstants.ACTIVITIES);
        }}
        customClass={'ready'}
        iconColor={iconColor}
        titleColor={titleColor}
        iconName={iconName}
        title={heading}
        subTitle={subHeading}
      />
    );
  } else if (event === NOTIFICATION_EVENTS.DOCUMENT_FEEDYARD_REVISION_UPDATE) {
    const { heading, subHeading, iconColor, iconName, titleColor } =
      NotificationHelper.getDocumentRevisionUpdateData(item);
    return (
      <IconItem
        onPress={() => {
          props.history.replace(RouteConstants.PROGRAM_MANUAL);
        }}
        customClass={'ready'}
        iconColor={iconColor}
        titleColor={titleColor}
        iconName={iconName}
        title={heading}
        subTitle={subHeading}
      />
    );
  } else if (event === NOTIFICATION_EVENTS.ACTIVITY_ASSIGNED) {
    const { heading, subHeading, iconColor, iconName, titleColor } =
      NotificationHelper.getActivityAssignedData(item);
    return (
      <IconItem
        onPress={() => {
          props.history.replace(RouteConstants.ACTIVITIES);
        }}
        customClass={'assigned'}
        iconColor={iconColor}
        titleColor={titleColor}
        iconName={iconName}
        title={heading}
        subTitle={subHeading}
      />
    );
  } else if (event === NOTIFICATION_EVENTS.AUDIT_SUBMITTED) {
    const { getAuditById } = props;
    const { heading, subHeading, iconColor, iconName, titleColor } =
      NotificationHelper.getAuditSubmittedData(item);
    const audit = item.audit_assignment;
    return (
      <IconItem
        onPress={() => {
          getAuditById(audit.audit_assignment_id);
        }}
        customClass={'ready'}
        iconColor={iconColor}
        titleColor={titleColor}
        iconName={iconName}
        title={heading}
        subTitle={subHeading}
      />
    );
  } else if (event === NOTIFICATION_EVENTS.NEW_COMMENT_ADDED) {
    const { getAuditById } = props;
    const { heading, subHeading, iconColor, iconName, titleColor } =
      NotificationHelper.getNewCommentAddedData(item);
    const audit = item.audit_assignment;
    return (
      <IconItem
        onPress={() => {
          getAuditById(audit.audit_assignment_id);
        }}
        customClass={'ready'}
        iconColor={iconColor}
        titleColor={titleColor}
        iconName={iconName}
        title={heading}
        subTitle={subHeading}
      />
    );
  } else {
    return (
      <ImageItem src={AssetConstants.cow} title={event} subTitle={event} />
    );
  }
};

export default NotificationItem;
