import React from 'react';

import PBIcon from '../../../../components/PBIcon';

import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import localization from '@progressivebeef/shared/src/localization/i18n';

import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';

const ExportComplianceCard = (props) => {
  return (
    <div className="export-compliance-card" onClick={() => props.history.push(RouteConstants.EXPORT_COMPLIANCE)}>
      <div className="icon"><PBIcon name="file-excel" /></div>
      <div className="label">{localization[getLocale()].EXPORT_COMPLIANCE}</div>
    </div>
  );
};

export default ExportComplianceCard;
