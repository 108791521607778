import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import PBIcon from '../PBIcon';
import { globalHelper } from '@progressivebeef/shared/src/helpers';

const DashboardSmallCard = (props) => {
  const { heading, data, onPress, iconName, loading } = props;
  // const containerStyles = loading
  //   ? [
  //       styles.containerStyles,
  //       { backgroundColor: 'rgba(61,7,135,0.1)', height: normalize(82) },
  //     ]
  //   : [styles.containerStyles, { backgroundColor: 'rgba(61,7,135,1)' }];
  return (
    <div
      className={`small-blue-card${loading ? ' dasboard-card-opt-half' : ''}`}
      onClick={onPress}
    >
      {globalHelper.renderIf(!loading)(
        <>
          <div className="icon">
            <PBIcon name={iconName} />
          </div>
          <div className="desc">
            <div className="label">{heading}</div>
            <div className="num">{data}</div>
          </div>
        </>
      )}
    </div>
  );
};

DashboardSmallCard.propTypes = {
  heading: PropTypes.string,
  data: PropTypes.string,
  onPress: PropTypes.func,
  iconName: PropTypes.string,
};

// const styles = {
//   containerStyles: {
//     // height: normalize(82),
//     width: normalize(172),
//     borderRadius: normalize(5),
//     flexDirection: 'row',
//     justifyContent: 'center',
//     marginTop: normalize(12),
//     // margin: normalize(15),
//   },
//   iconContainerStyles: {
//     margin: 5,
//     marginLeft: normalize(12),
//     marginTop: normalize(10),
//   },
//   textContainerStyles: {
//     flexDirection: 'column',
//     margin: normalize(5),
//     width: '70%',
//   },
//   headingStyles: {
//     fontSize: normalize(15),
//     color: '#ffffff',
//     fontFamily: commonFont.sfProSemiBold,
//   },
//   dataStyles: {
//     fontSize: normalize(25),
//     color: '#ffffff',
//     fontFamily: commonFont.sfProLight,
//   },
//   textContainer: {
//     width: '90%',
//   },
//   iconStyles: {
//     color: '#ffffff',
//     marginLeft: normalize(10),
//     fontSize: normalize(40),
//   },
// };

export default DashboardSmallCard;
