import React, { Component } from 'react';
import './style.scss'

class PickerModal extends Component {
  static defaultProps = {
    onRequestClose: () => { },
    onCancel: () => { },
    cancelText: 'Cancel',
    show: false,
    menus: []
  }

  render() {
    const {
      onRequestClose,
      show,
    } = this.props

    return (
      <div className={`react-actionsheet${show ? ' react-actionsheet-show' : ''}`}
        onClick={onRequestClose}>
        <div className='react-actionsheet-mask'>
        </div>
        <div className='react-actionsheet-wrap'>
          <div className='react-actionsheet-menu'>
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}

export default PickerModal;
