// modules
import { takeLatest, call, put, delay } from 'redux-saga/effects';
import localization from '../localization/i18n';
import { getLocale } from '../services/storageService/GlobalData';

import ACTION_CONSTANTS from '../constants/actionConstants';
import AppTrainingVideoHTMLManager from '../database/reactDataManager/AppTrainingVideoHTMLManager';
import AppTrainingVideoHtmlService from '../services/api/appTrainingVideoHTML';
import {
  getAppTrainingVideoHtmlFailure,
  getAppTrainingVideoHtmlSuccess,
} from '../store/actions/appTrainingVideoHTML';

export function* getAppTrainingVideoHtml({ payload }) {
  try {
    const update = payload.update;
    let appTrainingVideoHtmlObj =
      AppTrainingVideoHTMLManager.getAppTrainingVideoHTML();

    if (navigator.onLine && update) {
      let resp = yield call(
        AppTrainingVideoHtmlService.getAppTrainingVideoHtml,
      );

      appTrainingVideoHtmlObj = resp.data && resp.data.app_training_video;
      AppTrainingVideoHTMLManager.saveAppTrainingVideoHTML(
        appTrainingVideoHtmlObj,
      );
      yield put(getAppTrainingVideoHtmlSuccess({ appTrainingVideoHtmlObj }));
    } else {
      yield delay(100);
      if (appTrainingVideoHtmlObj) {
        yield put(getAppTrainingVideoHtmlSuccess({ appTrainingVideoHtmlObj }));
      } else {
        yield put(
          getAppTrainingVideoHtmlFailure({
            error: localization[getLocale()].NO_INTERNET_CONNECTION,
          }),
        );
      }
    }
  } catch (e) {
    console.log('getAppTrainingVideoHtml fail', e);
    yield put(getAppTrainingVideoHtmlFailure({ error: e }));
  }
}

function* appTrainingVideoHtmlSaga() {
  yield takeLatest(
    ACTION_CONSTANTS.GET_APP_TRAINING_VIDEO_HTML_REQUEST,
    getAppTrainingVideoHtml,
  );
}

export default appTrainingVideoHtmlSaga;
