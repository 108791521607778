// Before (For react < 18.0.0)
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import BootSetup from './bootSetup';

require('dotenv').config();
ReactDOM.render(<BootSetup />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// // After (For react >= 18.0.0) // mgr-pdf-viewer-react does not work with this implementation
// import React from 'react';
// import { createRoot } from 'react-dom/client';
// import './index.css';
// import * as serviceWorker from './serviceWorker';
// import BootSetup from './bootSetup';

// require('dotenv').config();
// const root = createRoot(document.getElementById('root'));
// root.render(<BootSetup />);

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
