import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import CountTag from '../CountTag';
import ActivityTag from '../ActivityTag';
import moment from 'moment';
import PBIcon from '../PBIcon';
import './style.scss';

import {
  setCurrentActivity,
  setCurrentInstance,
} from '@progressivebeef/shared/src/store/actions/activities';
import {
  userRoles,
  ACTIVITY_PERMISSIONS,
  ACTIVITY_FREQUENCY,
  DATE_FORMATS,
  ACTIVITY_FREQUENCY_TYPES,
  SHOW_TWELVE_MONTH_ACTIVITY_TIME_DAYS,
} from '@progressivebeef/shared/src/constants/AppConstants';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';
import { globalHelper } from '@progressivebeef/shared/src/helpers';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import localization from '@progressivebeef/shared/src/localization/i18n';
import ActivityHelper from '@progressivebeef/shared/src/helpers/ActivitiesHelper';

var click = true;

class ActivityCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      show: false,
    };
  }

  onActivityCardPress = (currentActivity) => {
    const { user, setCurrentActivityRequest } = this.props;

    setCurrentActivityRequest(currentActivity);

    if (Number(user.userRoleId) === userRoles.WORKER) {
      if (currentActivity.instance_based) {
        this.props.history.push(RouteConstants.ACTIVITY_INSTANCE, {
          currentActivity,
        });
      } else {
        this.props.history.push(RouteConstants.ACTIVITY_SUBMISSIONS, {
          currentActivity,
        });
      }
    } else {
      if (
        /**
         * if activity permission_key is assigned worker, and activity is instance based and can not be scheduled
         * then navigate directly instance screen
         */
        currentActivity.instance_based &&
        currentActivity.permission_key ===
          ACTIVITY_PERMISSIONS.ASSIGNED_WORKER &&
        currentActivity.frequency_key === ACTIVITY_FREQUENCY.REQUIRED
      ) {
        this.props.history.push(RouteConstants.ACTIVITY_INSTANCE, {
          currentActivity,
        });
      } else {
        this.setState({ modalVisible: true });
      }
    }
  };

  onPickerPress = () => {
    this.setState({ modalVisible: false });
  };

  render() {
    var { item, history, onActivityCardPress } = this.props;
    const title = item.locale
      ? item.locale[getLocale() === 'en' ? 1 : 2].title
      : localization[getLocale()].TITLE;

    let remaining = 1e9;
    if (
      item.schedule.frequency_calendar_key ===
        ACTIVITY_FREQUENCY_TYPES.TWELVE_MONTH &&
      !item.waiting_for_submission &&
      item.due_date
    ) {
      if (item.due_date === moment().format('YYYY-MM-DD')) remaining = 0;
      else remaining = moment(item.due_date).diff(moment(), 'days') + 2;
    }

    return (
      <div className="activity-main-parent">
        {remaining <= SHOW_TWELVE_MONTH_ACTIVITY_TIME_DAYS && (
          <div
            style={{
              position: 'absolute',
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              height: '100%',
              width: '100%',
            }}
          />
        )}
        <div
          onMouseDown={(e) => (click = true)}
          onMouseMove={(e) => (click = false)}
          onClick={() => {
            if (click) {
              onActivityCardPress(item);
            }
          }}
          className="activity-main-box"
          style={{
            opacity:
              remaining <= SHOW_TWELVE_MONTH_ACTIVITY_TIME_DAYS ? 0.8 : 1,
            borderColor:
              remaining <= SHOW_TWELVE_MONTH_ACTIVITY_TIME_DAYS
                ? '#a6a6a6'
                : '#e6e6e6',
          }}>
          <div className="activity-log-box">
            <img alt="description" src={ActivityHelper.getTypeIcon(item)} />
            <div className="title">{item.activity_number}</div>
            <div style={{ flex: 1 }} />
            {remaining <= SHOW_TWELVE_MONTH_ACTIVITY_TIME_DAYS && (
              <div className="days-remaining">
                <PBIcon
                  type={'FontAwesome'}
                  className="clock-icon"
                  name={'history'}
                />
                <div className="clock-icon">
                  {remaining === 0 ? '1 day left' : `${remaining} days left`}
                </div>
              </div>
            )}
          </div>
          <div className="activity-tile-box">{title}</div>
          <div className="activity-list-of-detail">
            <CountTag
              count={item.instance_submission.numerator}
              totalCount={item.instance_submission.denominator}
            />
            <div className="activity-user-box">
              <PBIcon name={'user'} />
              <div className="no">{item.users ? item.users.length : 0}</div>
            </div>
            {globalHelper.renderIf(item.last_submitted_date)(
              <div className="activity-date-box">
                <div className="icon">
                  <PBIcon name={'calendar-check'} />
                </div>
                <div className="date">
                  {moment(item.last_submitted_date).format(
                    DATE_FORMATS.MM_DD_YY,
                  )}
                </div>
              </div>,
            )}
            {item.reminder_date ? (
              <div className="activity-date-box stopwatch-edit-date">
                <div className="icon">
                  <PBIcon name={'stopwatch'} />
                </div>
                <div className="date ">
                  {moment(item.reminder_date).format(DATE_FORMATS.MM_DD_YY)}
                </div>
              </div>
            ) : (
              globalHelper.renderIf(item.due_date)(
                <div className="activity-date-box stopwatch-edit-date">
                  <div className="icon">
                    <PBIcon name={'calendar-edit'} />
                  </div>
                  <div className="date">
                    {moment(item.due_date).format(DATE_FORMATS.MM_DD_YY)}
                  </div>
                </div>,
              )
            )}
          </div>
          {item.activity_tags && (
            <ActivityTag history={history} tags={item.activity_tags} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state.authentication;

  return {
    user: user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentActivityRequest: (payload) =>
      dispatch(setCurrentActivity(payload)),
    setCurrentInstanceRequest: (payload) =>
      dispatch(setCurrentInstance(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityCard);
