import mixpanel from 'mixpanel-browser';
import {
  getUserRole,
  getSelectedFeedlotName,
  getSelectedFeedlotId,
  getUsername,
} from '../storageService/GlobalData';

import { userRolesName, APPLICATION_TYPES } from '../../constants/AppConstants';
import { Properties } from '../../constants/MixpanelConstants';

export const trackEvent = (eventName, properties) => {
  try {
    if (!properties) {
      properties = {};
    }

    //add basic properties
    properties[Properties.USER_TYPE] = userRolesName[getUserRole()];
    properties[Properties.APPLICATION_TYPE] = APPLICATION_TYPES.WEB_RN;
    properties[Properties.JS_APP_VERSION] = process.env.REACT_APP_VERSION_CODE;

    if (navigator.onLine) {
      properties[Properties.IS_ONLINE] = true;
    }

    if (getSelectedFeedlotName()) {
      properties[Properties.FEEDYARD_NAME] = getSelectedFeedlotName();
    }
    if (getSelectedFeedlotId()) {
      properties[Properties.FEEDYARD_ID] = getSelectedFeedlotId();
    }
    if (getUsername()) {
      properties[Properties.USER_NAME] = getUsername();
    }
    if (getUserRole()) {
      properties[Properties.USER_ROLE] = getUserRole();
    }
    if (navigator.onLine) {
      //track event
      mixpanel.track(eventName, properties);
    }
  } catch (e) {
    console.log('Error in tracking event', e);
  }
};

export const setMixpanelUser = (user) => {
  try {
    mixpanel.identify(user.user_id.toString());

    mixpanel.people.set({
      $first_name: user.profile.first_name,
      $last_name: user.profile.last_name,
      $email: user.profile.email_address,
      Role: user.user_role_name,
      'User Name': user.user_name,
      Feedlots: getFeedlotsFromUserObj(user),
    });
  } catch (e) {
    console.log('Error in seting mixpanel user', e);
  }
};

export const getFeedlotsFromUserObj = (user) => {
  let feedlotsArray = user.feedlots,
    feedlots = [];

  for (let i in feedlotsArray) {
    feedlots.push(feedlotsArray[i].name);
  }
  return feedlots;
};
