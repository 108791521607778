import { showErrorAlert } from '../../../../web/src/components/Alerts';

export const getLocalstorageItem = (key) => {
  return JSON.parse(localStorage.getItem(key)) || [];
};

export function setLocalstorageItem(key, value, isClearStorage) {
  let size = getLocalStorageSize();

  if (size < 5 || isClearStorage) {
    localStorage.setItem(key, value);
  } else {
    showErrorAlert('Memory full. No more data can be fetched.');
  }
}

const getLocalStorageSize = () => {
  let _lsTotal = 0,
    _xLen,
    _x;
  for (_x in localStorage) {
    if (!localStorage.hasOwnProperty(_x)) {
      continue;
    }
    _xLen = (localStorage[_x].length + _x.length) * 2;
    _lsTotal += _xLen;
    // console.log(_x.substr(0, 50) + ' = ' + (_xLen / 1024).toFixed(2) + ' KB');
  }
  let sizeInKB = (_lsTotal / 1024).toFixed(2);
  console.log('Total = ' + sizeInKB + ' KB');
  let sizeInMB = (sizeInKB / 1024).toFixed(2);
  console.log('Total = ' + sizeInMB + ' MB');
  return sizeInMB;
};
