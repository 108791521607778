import {
  DOCUMENT_TYPE_KEY,
  LOCAL_STORAGE_KEYS,
  PROGRAM_MANUAL_STATUS,
} from '../../constants/AppConstants';
import { setLocalstorageItem } from '.';
import { getLocale } from '../../services/storageService/GlobalData';
import languageService from '../../services/languageService';

class ProgramManualManager {
  saveProgramManual(ProgramManualToSave) {
    try {
      let programManualsArr =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.PROGRAM_MANUALS)) ||
        [];

      for (let i in ProgramManualToSave) {
        let pManual = ProgramManualToSave[i];
        let manualIndex = programManualsArr.findIndex(
          (q) => q.document_number == pManual.document_number,
        );

        if (manualIndex != -1) {
          programManualsArr[manualIndex] = pManual;
        } else {
          programManualsArr.push(pManual);
        }
      }

      setLocalstorageItem(
        LOCAL_STORAGE_KEYS.PROGRAM_MANUALS,
        JSON.stringify(programManualsArr),
      );
      return true;
    } catch (e) {
      console.log('ProgramManual Save fail', e);
      return false;
    }
  }

  saveDeprecatedProgramManual(deprecaredProgramManuals) {
    let programManuals =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.PROGRAM_MANUALS)) ||
      [];
    let programManualToSave = [
      ...new Set([...deprecaredProgramManuals, ...programManuals]),
    ];
    this.saveProgramManual(programManualToSave);
  }

  updateProgramManualStatus(documentNumber, status) {
    try {
      let programManuals =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.PROGRAM_MANUALS)) ||
        [];

      let programManualIndex = programManuals.findIndex(
        (q) => q.document_number == documentNumber,
      );
      if (programManualIndex != -1) {
        programManuals[programManualIndex].status = status;
        this.saveProgramManual(programManuals);
      }
      return true;
    } catch (e) {
      console.log('saveProgramManualfailed', e);
      return false;
    }
  }

  // moveProgramManualtoBin(documentNumber) {
  //   try {
  //     let programManual = ProgBeefDB.objectForPrimaryKey(
  //       DOCUMENT_SCHEMA_NAME,
  //       documentNumber
  //     );

  //     ProgBeefDB.write(() => {
  //       programManual.status = 5;
  //     });
  //     return true;
  //   } catch (e) {
  //     console.log('saveProgramManualfailed', e);
  //     return false;
  //   }
  // }

  // moveProgramManualtoActive(documentNumber) {
  //   try {
  //     let programManuals = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.PROGRAM_MANUALS)) || [];

  //     let programManualIndex = programManuals.findIndex(
  //       (q) => q.document_number == documentNumber
  //     );
  //     programManuals[programManualIndex].status = 2;
  //     this.saveProgramManual(programManuals);
  //     return true;
  //   } catch (e) {
  //     console.log('saveProgramManualfailed', e);
  //     return false;
  //   }
  // }

  // markViewed(feedlotDocumentId) {
  //   try {
  //     let programManual = ProgBeefDB.objectForPrimaryKey(
  //       DOCUMENT_SCHEMA_NAME,
  //       feedlotDocumentId,
  //     );

  //     ProgBeefDB.write(() => {
  //       programManual.document_viewed = 1;
  //     });
  //     return true;
  //   } catch (e) {
  //     console.log('saveProgramManualfailed', e);
  //     return false;
  //   }
  // }

  // deleteURL(urlID) {
  //   try {
  //     console.log('delteURLFailedaa', urlID);
  //     ProgBeefDB.write(() => {
  //       ProgBeefDB.delete(
  //         ProgBeefDB.objectForPrimaryKey(
  //           DOCUMENT_URLS_SCHEMA_NAME,
  //           Number(urlID.feedlot_document_url_id),
  //         ),
  //       );
  //     });
  //     return true;
  //   } catch (e) {
  //     console.log('delteURLFailedaa', e);
  //     return false;
  //   }
  // }

  getProgramManual(query = '') {
    try {
      let programManuals =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.PROGRAM_MANUALS)) ||
        [];

      programManuals = programManuals
        .filter((q) => q.status == 2)
        .filter(
          (q) =>
            q.document_number.toLowerCase().includes(query.toLowerCase()) ||
            (q.document_locale[languageService()] &&
              q.document_locale[languageService()].title
                .toLowerCase()
                .includes(query.toLowerCase())),
        );

      programManuals = programManuals.sort((a, b) =>
        a.document_number
          .replace('m-', '')
          .toLowerCase()
          .localeCompare(
            b.document_number.replace('m-', '').toLowerCase(),
            getLocale(),
            { numeric: true },
          ),
      );

      return programManuals;
    } catch (e) {
      console.log('getProgramManual failed', e);
    }
  }

  // getDeprecatedProgramManual() {
  //   let programManual = ProgBeefDB.objects(DOCUMENT_SCHEMA_NAME).filtered(
  //     'status = 5',
  //   );

  //   programManual = programManual.sorted('document_number', false);
  //   return Array.from(programManual);
  // }

  saveProgramManualTags(tagsToSave) {
    try {
      setLocalstorageItem(
        LOCAL_STORAGE_KEYS.PROGRAM_MANUAL_TAGS,
        JSON.stringify(tagsToSave),
      );
      return true;
    } catch (e) {
      console.log('saveProgramManual tags failed', e);
      return false;
    }
  }

  getProgramManualTags() {
    try {
      let programManualtags =
        JSON.parse(
          localStorage.getItem(LOCAL_STORAGE_KEYS.PROGRAM_MANUAL_TAGS),
        ) || [];

      return programManualtags;
    } catch (e) {
      console.log('getProgramManual tags failed', e);
      return false;
    }
  }

  getProgramManualById(document_number) {
    try {
      let programManuals =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.PROGRAM_MANUALS)) ||
        [];
      let programManual = programManuals.find(
        (q) => q.document_number == document_number,
      );

      return programManual;
    } catch (e) {
      console.log('getProgramManualById failed', e);
      return false;
    }
  }

  getProgramManualActivitiesById(documentNumber) {
    try {
      let activities = JSON.parse(localStorage.getItem('activities')) || [];
      let assosiatedActivities = [];

      for (let i = 0; i < activities.length; i++) {
        let activity = activities[i];
        for (let j = 0; j < activity.documents.length; j++) {
          let doc = activity.documents[j];
          if (doc.document_number == documentNumber) {
            assosiatedActivities.push(activity);
          }
        }
      }
      assosiatedActivities = assosiatedActivities.sort(function (a, b) {
        return a.activity_number > b.activity_number;
      });

      return assosiatedActivities;
    } catch (e) {
      console.log('getProgramManualActivitiesById failed', e);
      return false;
    }
  }

  getDeprecatedProgramManuals() {
    try {
      let manuals =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.PROGRAM_MANUALS)) ||
        [];
      manuals = manuals.filter(
        (q) =>
          q.status == PROGRAM_MANUAL_STATUS.BIN &&
          q.document_type_key == DOCUMENT_TYPE_KEY.TRAINING,
      );
      manuals = manuals.sort(function (a, b) {
        return a.document_number > b.document_number;
      });

      return manuals;
    } catch (e) {
      console.log('getDeprecatedProgramManuals failed', e);
      return false;
    }
  }

  // getMissedProgramManuals() {
  //   let manuals = ProgBeefDB.objects(DOCUMENT_SCHEMA_NAME).filtered(
  //     'status = 6',
  //   );

  //   manuals = manuals.sorted('document_number', false);
  //   return Array.from(manuals);
  // }

  getTrainings() {
    let trainings =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.PROGRAM_MANUALS)) ||
      [];
    trainings = trainings.filter(
      (training) => training.document_type_key == DOCUMENT_TYPE_KEY.TRAINING,
    );

    return trainings;
  }
}

export default new ProgramManualManager();
