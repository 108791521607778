import {
  LOCAL_STORAGE_KEYS,
  SUBMISSION_STATUS,
} from '../../constants/AppConstants';
import { getLocalstorageItem, setLocalstorageItem } from '.';
import moment from 'moment';

class SettingsManager {
  clearLocalStorage(numberOfDays) {
    try {
      let submissions = [];

      if (numberOfDays && numberOfDays == 1) {
        submissions =
          JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SUBMISSIONS)) ||
          [];
        let currentDate = new Date();
        let dateLimit = new Date();

        dateLimit = new Date(
          dateLimit.setDate(dateLimit.getDate() - (14 + currentDate.getDay()))
        );
        dateLimit = moment(dateLimit).format('l');
        // console.log(dateLimit);
        //dont remove if pending or rejected or was fetched within two weeks
        submissions = submissions.filter(
          (q) =>
            q.status == SUBMISSION_STATUS.PENDING ||
            q.status == SUBMISSION_STATUS.REJECTED ||
            moment(q.localStorageAddedDate).diff(dateLimit) > 0
        );
      }
      setLocalstorageItem(
        LOCAL_STORAGE_KEYS.SUBMISSIONS,
        JSON.stringify(submissions),
        true
      );
    } catch (e) {
      console.log('error clearActivitySubmissions', e);
    }
  }
}

export default new SettingsManager();
