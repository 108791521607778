import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Input, InputGroup } from 'reactstrap';
import './style.scss';

import AppContainer from '../../../../components/AppContainer';
import FormButton from '../../../../components/FormButton';
import {
  showAlertMsg,
  showSuccessAlert,
  showErrorAlert,
} from '../../../../components/Alerts';
import PBIcon from '../../../../components/PBIcon';

import { ChangePasswordValidationSchema } from '@progressivebeef/shared/src/constants/FormValidations';
import { ChangePasswordFormInitialValues } from '@progressivebeef/shared/src/constants/FormInitialValues';

import localization from '@progressivebeef/shared/src/localization/i18n';
import {
  getLocale,
  getUsername,
} from '@progressivebeef/shared/src/services/storageService/GlobalData';

import {
  showLoader,
  hideLoader,
} from '@progressivebeef/shared/src/store/actions/view';
import { logoutRequested } from '@progressivebeef/shared/src/store/actions/authentication';
import { changePasswordRequest } from '@progressivebeef/shared/src/store/actions/user';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';

import { trackEvent } from '@progressivebeef/shared/src/services/reactMixpanelService';
import {
  Events,
  Properties,
} from '@progressivebeef/shared/src/constants/MixpanelConstants';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCurrent: false,
      showNew: false,
      showConfirm: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      changePasswordSuccess,
      changePasswordError,
      isChangePasswordLoading,
      showLoaderView,
      hideLoaderView,
      logout,
    } = this.props;
    if (isChangePasswordLoading) {
      showLoaderView();
    } else {
      hideLoaderView();
    }

    if (
      changePasswordSuccess &&
      prevProps.changePasswordSuccess !== changePasswordSuccess
    ) {
      trackEvent(Events.PASSWORD_CHANGED);
      showSuccessAlert(localization[getLocale()].PASSWORD_CHANGED_SUCCESS, [
        {
          text: localization[getLocale()].OK,
          onPress: () => {
            logout();
            this.props.history.replace(RouteConstants.LOGIN);
          },
        },
      ]);
    }
    if (prevProps.changePasswordError !== changePasswordError) {
      if (typeof changePasswordError === 'string') {
        showErrorAlert(changePasswordError);
      }
    }
  }

  validate(values) {
    const lengths = Object.values(values).map((value) => value.length);
    const min = Math.min(...lengths);
    if (min === 0) {
      showAlertMsg(
        localization[getLocale()].ERROR,
        localization[getLocale()].PLEASE_FILL_ALL_FIELDS,
      );
    }
    if (min < 10 && min > 0) {
      showAlertMsg(
        localization[getLocale()].ERROR,
        localization[getLocale()].PASSWORD_LENGTH_ERROR,
      );
    }
  }

  validateAndSubmit = (isValid, values, handleSubmit) => {
    if (!isValid) {
      this.validate(values);
    } else {
      const { newPassword, confirmPassword, currentPassword } = values;
      if (newPassword === currentPassword) {
        showAlertMsg(
          localization[getLocale()].ERROR,
          localization[getLocale()].NEW_PASSWORD_SAME_ERROR,
        );
        return;
      }
      if (newPassword !== confirmPassword) {
        showAlertMsg(
          localization[getLocale()].ERROR,
          localization[getLocale()].NEW_PASSWORD_MATCH_ERROR,
        );
        return;
      }
      handleSubmit();
    }
  };

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          name={'chevron-left'}
          onPress={() => {
            this.props.history.goBack();
          }}
        />
      </div>
    );
  };

  render() {
    const { history } = this.props;

    return (
      <Formik
        initialValues={ChangePasswordFormInitialValues}
        validationSchema={ChangePasswordValidationSchema}
        onSubmit={(values) => {
          this.props.changePassword({
            user: {
              new_password: values.newPassword,
              password: values.currentPassword,
              user_name: getUsername(),
            },
          });
        }}>
        {(props) => {
          const { handleSubmit, handleChange, handleBlur, values, isValid } =
            props;
          return (
            <AppContainer
              title={localization[getLocale()].CHANGE_PASSWORD}
              iconHeader
              history={history}
              IconLeftHeader={this.renderLeftHeader}>
              <InputGroup className="setting-change-pass-screen">
                <div className="flex-col">
                  <div className="label">
                    {localization[getLocale()].PLACEHOLDER_CURRENT_PASSWORD}
                  </div>
                  <div className="field-box">
                    <Input
                      onChange={handleChange('currentPassword')}
                      onBlur={handleBlur('currentPassword')}
                      value={values.currentPassword}
                      title={
                        localization[getLocale()].PLACEHOLDER_CURRENT_PASSWORD
                      }
                      placeholder={'*************'}
                      type={this.state.showCurrent ? 'text' : 'password'}
                      name={'currentPassword'}
                      id={'currentPassword'}
                    />
                    <i
                      className={`fal trailing-icon ${
                        this.state.showCurrent ? 'fa-eye' : 'fa-eye-slash'
                      }`}
                      onClick={() => {
                        this.setState({
                          showCurrent: !this.state.showCurrent,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="flex-col">
                  <div className="label">
                    {localization[getLocale()].PLACEHOLDER_NEW_PASSWORD}
                  </div>

                  <div className="field-box">
                    <Input
                      onChange={handleChange('newPassword')}
                      onBlur={handleBlur('newPassword')}
                      value={values.newPassword}
                      title={localization[getLocale()].PLACEHOLDER_NEW_PASSWORD}
                      placeholder={'*************'}
                      type={this.state.showNew ? 'text' : 'password'}
                      name={'newPassword'}
                      id={'newPassword'}
                    />
                    <i
                      className={`fal trailing-icon ${
                        this.state.showNew ? 'fa-eye' : 'fa-eye-slash'
                      }`}
                      onClick={() => {
                        this.setState({
                          showNew: !this.state.showNew,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="flex-col">
                  <div className="label">
                    {localization[getLocale()].PLACEHOLDER_CONFIRM_PASSWORD}
                  </div>
                  <div className="field-box">
                    <Input
                      onChange={handleChange('confirmPassword')}
                      onBlur={handleBlur('confirmPassword')}
                      value={values.confirmPassword}
                      title={
                        localization[getLocale()].PLACEHOLDER_CONFIRM_PASSWORD
                      }
                      placeholder={'*************'}
                      type={this.state.showConfirm ? 'text' : 'password'}
                      name={'confirmPassword'}
                      id={'confirmPassword'}
                    />
                    <i
                      className={`fal trailing-icon ${
                        this.state.showConfirm ? 'fa-eye' : 'fa-eye-slash'
                      }`}
                      onClick={() => {
                        this.setState({
                          showConfirm: !this.state.showConfirm,
                        });
                      }}
                    />
                  </div>
                </div>
                <FormButton
                  onPressEvent={() =>
                    this.validateAndSubmit(isValid, values, handleSubmit)
                  }
                  title={localization[getLocale()].SAVE}
                  success
                />
              </InputGroup>
            </AppContainer>
          );
        }}
      </Formik>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: (payload) => dispatch(changePasswordRequest(payload)),
    showLoaderView: (payload) => dispatch(showLoader(payload)),
    hideLoaderView: (payload) => dispatch(hideLoader(payload)),
    logout: () => dispatch(logoutRequested()),
  };
};

const mapStateToProps = (state) => {
  let { isChangePasswordLoading, changePasswordSuccess, changePasswordError } =
    state.user;

  return {
    isChangePasswordLoading,
    changePasswordSuccess,
    changePasswordError,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
