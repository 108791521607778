export const reminderMonthlyModel = () => ({
  feedlot_activity_id: '',
  frequency_calendar_key: 0,
  repeat_by: '',
  days: '',
  month_option: 0,
});

export const reminderWeeklyModel = () => ({
  feedlot_activity_id: '',
  frequency_calendar_key: 0,
  days: '',
});

export const scheduleModel = () => ({
  feedlot_activity_id: '',
  frequency_calendar_key: '1',
  submission_number: '',
  due_date: '',
});
