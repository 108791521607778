import _ from 'lodash';
// import { showErrorAlert } from '../components/Alerts';
import * as Yup from 'yup';
// import { showErrorAlert } from '../components/Alerts';
import { emailRegex } from '../constants/AppConstants';
import { getLocale } from '../services/storageService/GlobalData';
import localization from '../localization/i18n';
import { SUBMISSION_FIELD_TYPES } from './FormConstants';

let PLATFORM,
  Platform = null;

if (process.env.REACT_APP_PLATFORM !== 'WEB') {
  import('./theme/variables/commonFont').then(({ PLATFORM }) => {
    PLATFORM = PLATFORM;
  });

  import('react-native').then(({ Platform }) => {
    Platform = Platform;
  });
}

export const AddDocumentValidationSchema = Yup.object().shape({
  documentNumber: Yup.string().required('Document Number is required!'),
  englishTitle: Yup.string().required('Title is required!'),
  englishFile: Yup.string().required('File is required!'),
  spanishFile: Yup.string().when('spanishTitle', {
    is: (spanishTitle) => spanishTitle && spanishTitle !== '',
    then: Yup.string().required('File is required'),
  }),
});

export const ChangePinValidationSchema = Yup.object().shape({
  currentPin: Yup.string()
    .required('Current pin is required')
    .test(
      'len',
      'Pin must be exactly 4 characters',
      (val) => val && val.toString().length === 4,
    ),
  newPin: Yup.string()
    .required('New pin is required')
    .test(
      'len',
      'Pin must be exactly 4 characters',
      (val) => val && val.toString().length === 4,
    ),
  confirmPin: Yup.string()
    .required('Pin confirmation is required')
    .test(
      'len',
      'Pin must be exactly 4 characters',
      (val) => val && val.toString().length === 4,
    ),
});

export const ChangePasswordValidationSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .required('Current password is required')
    .test(
      'len',
      'Password must be greater than equal to 10 characters',
      (val) => val && val.length >= 10,
    ),
  newPassword: Yup.string()
    .required('New password is required')
    .test(
      'len',
      'Password must be greater than equal to 10 characters',
      (val) => val && val.length >= 10,
    ),
  confirmPassword: Yup.string()
    .required('Password confirmation is required')
    .test(
      'len',
      'Password must be greater than equal to 10 characters',
      (val) => val && val.length >= 10,
    ),
});

export const AddTrainingValidationSchema = Yup.object().shape({
  documentNumber: Yup.string().required('Document Number is required!'),
  dueAfter: Yup.string().required('Due After is required!'),
  numberOfDays: Yup.string().required('Number of Days is required!'),
  englishTitle: Yup.string().required('Title is required!'),
  englishFile: Yup.string().required('File is required!'),
  spanishFile: Yup.string().when('spanishTitle', {
    is: (spanishTitle) => spanishTitle && spanishTitle !== '',
    then: Yup.string().required('File is required'),
  }),
});

export const EmailReportValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Document Number is required!')
    .email('Email is not valid!'),
});

export const AddUserValidationSchema = Yup.object().shape({
  user_name: Yup.string().required('Username is required!'),
  first_name: Yup.string().required('First Name is required!'),
  last_name: Yup.string().required('Last Name is required!'),
});

// export const validateAddUserRequest = (data, user) => {
//   let isValid = true;
//   if (!user.user_id) {
//     if (data.password == '' || data.confirmPassword == '') {
//       isValid = false;
//       showErrorAlert(localization[getLocale()].PLEASE_FILL_ALL_FIELDS);
//     }
//   }
//   if (data.password != '' && data.password.length < 10) {
//     isValid = false;
//     showErrorAlert(localization[getLocale()].VALIDATE_USER_PASSWORD_LENGTH);
//   } else if (data.password !== data.confirmPassword) {
//     isValid = false;
//     showErrorAlert(localization[getLocale()].PASSWORDS_DONT_MATCH);
//   } else if (data.email_address && data.email_address != '') {
//     if (!emailRegex.test(data.email_address)) {
//       isValid = false;
//       showErrorAlert(localization[getLocale()].INVALID_EMAIL);
//     }
//   }
//   return isValid;
// };

export const AddSubmissionValidation = (fields, initalValues, values) => {
  let errors = {};
  const ids = Object.keys(values);
  for (let i = 0; i < ids.length; i++) {
    const field = _.find(fields, ['group_id', Number(ids[i])]);
    if (
      field.is_required === 1 &&
      field.field_type_key !== SUBMISSION_FIELD_TYPES.FORMULA &&
      field.field_type_key !== SUBMISSION_FIELD_TYPES.PARAGRAPH &&
      !(
        process.env.REACT_APP_PLATFORM !== 'WEB' &&
        Platform.OS === PLATFORM.ANDROID &&
        (field.field_type_key === SUBMISSION_FIELD_TYPES.SINGLE_SELECT ||
          field.field_type_key === SUBMISSION_FIELD_TYPES.EMPLOYEES)
      )
    ) {
      if (
        JSON.stringify(initalValues[ids[i]]) === JSON.stringify(values[ids[i]])
      ) {
        errors.error = ids[i] + ' field required';
        break;
      }
    }
  }
  return errors;
};
