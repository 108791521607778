export default {
  // general
  ERROR: 'Error',
  SUCCESS: 'Success',
  CONFIRMATION: 'Confirmation',
  YES: 'Yes',
  NO: 'No',
  OK: 'Ok',
  NOT_NOW: 'Not now',
  CANCEL: 'Cancel',
  SUBMIT_FORM: 'SEND',
  ENGLISH: 'English',
  SPANISH: 'Español',
  CONTINUE: 'Continue',
  NEXT: 'Next',
  PREVIOUS: 'Previous',
  CLOSE: 'Close',
  SAVE: 'Save',
  PROCEED: 'Proceed',
  ALERT: 'Alert',
  SUBMIT: 'Submit',
  CREATE: 'Create',
  PROGRESSIVE_BEEF: 'Progressive Beef',
  UNABLE_TO_CONNECT: 'Unable to connect the server. Please try again later',
  TRY_AGAIN: 'Try Again',
  UNLOCK: 'Unlock',
  USE: 'Use',
  TO: 'to',
  DATE: 'Date:',
  TOUCHID_SUPERSEDE:
    'Touch ID may supersede Face ID if both are enabled from device settings',
  DISABLE_TOUCHID:
    'To enable Face ID, please disable Touch ID from phone settings.',

  // Dashboard
  SYNCING: 'Syncing',
  FEEDLOT_PERFORMANCE: 'Feedlot Performance',
  WORKER_PERFORMANCE: 'Worker Performance',
  PERFORMANCE: 'Performance',
  LOADING: 'Content Loading',
  CHANGE_DATE: 'Change Date',
  REPORT_EMAIL: 'Your request has been processed successfully.',
  REQUEST_PROCESSED_SHORTLY: 'Your request will be processed shortly.',
  PERFORMANCE_CUSTOM_DATE_ERROR: 'Please select date range',

  CLEAR_STORAGE_ALL: 'Clear All',
  CLEAR_STORAGE_2_WEEKS: '2 Weeks',
  //#region ==== NOTIFICATION Strigns ====
  NO_NOTIFICATION_AVAILABLE: 'No notification available',
  NO_UPDATE_AVAILABLE: 'No update available',

  NOTIFICATION_REMINDER: 'Activity Reminder!',
  NOTIFICATION_ACTIVITY_DUE_TODAY: 'Due Today',
  NOTIFICATION_ACTIVITY_MISSED: 'Activity is Missed',
  NOTIFICATION_SUBMISSION_VOILATION: 'Violation(s) has been found',
  NOTIFICATION_DOCUMENT_READY: 'Document is ready to Print/Download',
  NOTIFICATION_TRAINING_DUE_TODAY: 'Training is available to view.',
  NOTIFICATION_TRAINING_VIEWED_BY: 'Training has been viewed by',
  NOTIFICATION_TRAINING_MISSED: 'Training has not been viewed by',
  NOTIFICATION_ACTIVITY_INSTANCE_ASSIGNED: 'Activity is assigned for instance',
  NOTIFICATION_ACTIVITY_LOG8_FILLED: 'Activity is filled for instance',
  NOTIFICATION_ACTIVITY_LOG8_COMPLETED: 'Activity is completed for instance',
  NOTIFICATION_REVISION_AVAILABLE: 'Has an updated revision',
  NOTIFICATION_ACTIVITY_ASSIGNED: 'Activity is assigned',
  NOTIFICATION_AUDIT_SUBMITTED: 'A new audit has been submitted by',
  NEW_COMMENT_ADDED: 'A new comment has been added by',

  UPDATE_SUBMITTED_BY: 'Submitted by',
  UPDATE_ACTIVITY_ASSIGNED: 'Assigned to',
  UPDATE_SUBMISSION_REMOVED: 'Removed by',
  //#endregion ==== NOTIFICATION Strigns ====

  REPORT: 'Report',

  NEVER_SIGNED_IN: 'Never Signed In',
  DELETE_VIDEO: 'Do you want to delete video?',

  // Activity
  SCHEDULE: 'Activity Schedule',
  DUE_DATE: 'Due Date',
  REPEAT: 'Repeat',
  SUBMITTED_DATE: 'Submitted Date',
  REMOVED: 'Removed',
  REMOVE: 'Remove',
  REMOVE_INSTANCE: 'Remove Instance',
  INSTANCE_DELETED_BY: 'Deleted By:',
  ADD_INSTANCE: 'Add Instance',
  ADD_INSTANCE_DIALOGUE: 'Please add instance value',
  ADD_INSTANCE_SUCCESS: 'Instance added successfully.',
  REMOVE_INSTANCE_DIALOGUE:
    'Are you sure you want to remove this instance? If yes please provide reason',
  REMOVE_INSTANCE_SUCCESS: 'Instance deleted.',
  ERROR_REMOVE_INSTANCE: 'You must provide the instance name.',
  ERROR_ADD_INSTANCE: 'You must provide the instance name.',
  SUBMITTED: 'Submitted',
  PENDING: 'Pending',
  REJECTED: 'Rejected',
  MISSED: 'Missed',
  TIMELY_SUBMITTED: 'Submitted Timely',
  PARTIALLY_SUBMITTED: 'Partially Submitted',
  MONTHS_PROGRESS: "This month's progress",
  WEEKS_PROGRESS: "This week's progress",
  PROGRESS: 'Progress',
  REPEAT_EVERY: 'Repeat Every',
  DAY: 'Day',
  DONE: 'Done',
  PRINT: 'Print',
  NOT_SUBMITTED: 'Not Submitted',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  NO_SUBMISSION_FOUND: 'No submissions found.',
  ACTIVITY_WORKERS: 'Workers Activity',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  LOCKED: 'Locked',
  SCHEDULE_SUBMISSION_COUNT_LABEL:
    'An entry will be required for each day/date you schedule this activity for.',
  CUSTOM: 'Custom',
  NO_SUBMISSION_HISTORY: 'There are no submissions in the selected date range.',

  //form
  PLACEHOLDER_ENTER_HERE: 'Enter Here',

  //routes header title
  LOGIN: 'LOGIN',
  FORGOT_PASSWORD: 'Forgot Password',
  GENERATE_PIN: 'Generate PIN',
  FEEDYARD_LIST: 'Select Feedyard',
  DASHBOARD: 'Dashboard',
  SOP: 'SOP',
  USERS: 'Users',
  ACTIVITIES: 'Activities',
  MORE: 'More',
  AUDITS: 'Audits',
  NAVIGATION_VIDEOS: 'Navigation Videos',
  SETTINGS: 'Settings',
  SWITCH_FEEDYARD: 'Switch Feedyard',
  LOGOUT: 'Logout',
  NOTIFICATION: 'Notification',
  NOTIFICATIONS: 'Notifications',

  COACHMARK: 'Coachmark',
  PENDING_ACTION: 'Pending Action',
  ASSIGN_LOG: 'Assign Log',
  // login
  USERNAME: 'Username',
  PIN: 'PIN',
  PASSWORD: 'Password',
  AUTHENTICATING: 'Authenticating...',
  ENTER_PASSWORD: 'Enter Password',
  LOGIN_VIA_BIOMETRIC: 'Login to Prog. Beef using ',
  UNLOCK_VIA_BIOMETRIC: 'Unlock using ',
  //logout
  LOGOUT_CONFIRMATION: 'Are you sure you want to logout?',

  //feedyards
  NO_FEEDYARDS_ASSIGNED: 'You are not assigned to any Feedyard.',
  ALL_FEEDYARDS: 'All Feedyards',
  SEARCHED_RESULTS: 'Searched Results',
  FEEDLOT_SINGLE_ASSIGNMENT: 'You are assigned to a single Feedyard',
  //forget PIN/password
  LOST_PIN_PASSWORD: 'Forgot Password/PIN',
  BACK_TO_LOGIN: 'Back to Login',
  FORGET_PASSWORD_TEXT: 'Enter your username to reset your password/PIN',

  //GENERATE PIN
  GENERATE: 'Generate PIN',
  ENTER_PIN: 'Enter PIN',
  CONFIRM_PIN: 'Confirm PIN',

  //dashboard
  VERSION: 'Version',
  APP_UPGRADE_TEXT: 'New Version Available',
  UPDATE: 'Updates',
  NEW_VERSION_AVAILABLE: 'New Version Available',
  ACTIVITIES_DUE_TODAY: 'Activities Due Today',
  EXPORT_COMPLIANCE: 'Export Compliance',
  WORKERS_ACTIVE_TODAY: 'Workers Active Today',
  ACTIVITY: 'Activity',
  NO_ACTIVITIES_IN_DATE_RANGE:
    'There are no activities in the selected date range.',
  // User
  USER_NAME: 'Username',
  NAME: 'Name',
  EMAIL: 'Email',
  NO_USERS_FOUND: 'No user(s) found in this status',
  PERSONAL_DETAILS: 'Personal Details',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  ACCOUNT_DETAIL: 'Account Details',
  PASSWORD_LABEL: 'Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  ROLE: 'Role',
  STATUS: 'Status',
  APP_JOINGING_DATE: 'App Joining Date',
  DOCUMENTS: 'Documents',
  DOCUMENTS_ASSIGNED: 'Documents Assigned',
  SELECT_DOCUMENTS: 'Select Document(s)',
  ASSIGN_DOCUMENTS: 'Assign Document(s)',
  ADD_USER: 'Add User',
  EDIT_USER: 'Edit User',
  UNLOCK_USER_CONFIRMATION: 'Do you want to unlock the user account?',
  INVALID_USERNAME:
    'Any space or special character from /\\:*?"<>|& is not allowed in Username',
  INVALID_FIRSTNAME:
    'Any special character from /\\:*?"<>|& is not allowed in FirstName',
  INVALID_LASTNAME:
    'Any special character from /\\:*?"<>|& is not allowed in LastName',

  //audit
  NON_CONFORMANCES: 'Non-Conformances',
  NON_CONFORMANCE: 'Non-Conformance',
  CORRECTIVE_ACTIONS: 'Corrective Actions',
  CORRECTIVE_ACTION: 'Corrective Action',
  NC_DUE_DATE: 'NC Due Date',
  NO_AUDITS_FOUND: 'No Audit(s) Found',
  NO_AUDIT_NCS_FOUND: 'No Audit Non-Conformance(s) Found',
  RECEIVED: 'Received',
  CORRECTIVE_ACTIONS_DUE_DATE: 'Corrective Actions Due Date',
  CA_DUE_DATE: 'CA Due Date',
  CONFIRM_SUBMIT_TO_AUDITOR: 'Submit to Auditor. Do you want to continue?',
  CONFIRM_SUBMIT_TO_AUDITOR_OR_RETURN_BACK:
    'There might be some NCs that require your attention. Do you want to submit it to the Auditor?',
  RESOLVED: 'Resolved',
  UNRESOLVED: 'Unresolved',
  DESCRIPTION: 'Description',
  NO_CA_PROVIDED: 'No Corrective Action provided by Auditor',
  COMMENT_PLACEHOLDER: 'Type your comment here',
  NO_COMMENTS_FOUND: 'No Comment(s) Found.',

  //audit status
  AUDIT_STATUS_dueToday: 'Due Today',
  AUDIT_STATUS_dueThisWeek: 'Due This Week',
  AUDIT_STATUS_dueThisMonth: 'Due This Month',
  AUDIT_STATUS_overdue: 'Overdue',
  AUDIT_STATUS_1: 'Due Audits',
  AUDIT_STATUS_2: 'On Going',
  AUDIT_STATUS_3: 'Received from Auditor',
  AUDIT_STATUS_4: 'Closed',
  AUDIT_STATUS_5: 'Review in progress',
  AUDIT_STATUS_6: 'Response required',
  AUDIT_STATUS_7: 'Reviewed',
  AUDIT_STATUS_8: 'Submitted to Reviewer',
  AUDIT_STATUS_9: 'Submitted to Auditor',
  AUDIT_STATUS_waitingForReview: 'Waiting For Review',

  AUDIT_CARD_STATUS_3: 'Received',
  AUDIT_CARD_STATUS_4: 'Closed',
  AUDIT_CARD_STATUS_9: 'Submitted',
  // settings
  PROFILE_SEND_DIAGNOSTICS_REPORT: 'Send Diagnostics Report',
  PLACEHOLDER_CURRENT_PASSWORD: 'Current Password *',
  PLACEHOLDER_NEW_PASSWORD: 'New Password *',
  PLACEHOLDER_CONFIRM_PASSWORD: 'Confirm New Password *',
  PLACEHOLDER_CURRENT_PIN: 'Current PIN *',
  PLACEHOLDER_NEW_PIN: 'New PIN *',
  PLACEHOLDER_CONFIRM_PIN: ' Confirm New PIN *',
  VIEW_COACHMARKS: 'View Coach Marks',
  EMAIL_ALERTS: 'Email Alerts',
  LOGIN_OPTIONS: 'Login Options',
  TOUCH_ID_FACE_ID_DISABLE_CONFIRMATION:
    'It will disable {{1}} and enable {{2}}. Do you want to continue?',
  CHANGE_PIN: 'Change PIN',
  CHANGE_PASSWORD: 'Change Password',
  CURRENT_PIN: 'Current PIN',
  NEW_PIN: 'New PIN',
  RE_ENTER_PIN: 'Re-enter New PIN',
  NEW_PIN_MATCH_ERROR: 'Both PINs must be same',
  NEW_PIN_SAME_ERROR: 'New PIN and Old PIN cannot be same',
  PIN_CHANGED_SUCCESS:
    'PIN changed successfully. Please login again to continue',

  CURRENT_PASSWORD: 'Current Password',
  NEW_PASSWORD: 'New Password',
  RE_ENTER_PASSWORD: 'Confirm New Password',
  PASSWORD_LENGTH_ERROR: 'Passwords must be 10 characters long',
  NEW_PASSWORD_MATCH_ERROR: 'Both passwords must be same',
  NEW_PASSWORD_SAME_ERROR: 'New Password and Old Password cannot be same',
  PASSWORD_CHANGED_SUCCESS:
    'Your new password is now set. Please login again to continue',
  SEND_DIAGNOSTIC_INFO: 'Send Diagnostic Info',
  CLEAR_STORAGE_OPTION: 'Clear Storage Space',
  LOGOUT_CONFIRMATION_MSG: (numberOfPendingActions) =>
    `You have ${numberOfPendingActions} pending actions. If you log out these will be lost. Do you wish to log out and lose these?`,
  // notifications
  AUDIT_ASSIGNED: 'Audit Assigned To You',
  AUDIT_REVIEWED: 'Audit Reviewed',
  REVIEW_REQUIRED: 'Review Required',
  AUDIT_RESPONSE_REQUIRED: 'Audit Response Required',

  //empty texts
  NO_PENDING_ACTIONS_AVAILABLE: 'No pending actions',
  NO_PAGE_DATA_AVAILABLE: 'No Page Data Available!',

  FORGOT_PASSWORD_REQUEST_SUCCESS_MSG:
    'An email has been sent to your associated email address with details to reset password/PIN.',
  COMMON_SAVED_SUCCESSFULLY: 'Saved Successfully',
  AUDIT_SAVED_SUCCESSFULLY: 'Audit submitted to Auditor.',

  //ERROR  CODES
  NO_INTERNET_CONNECTION: 'You need to have an active internet connection!',
  ERROR_NO_EMAIL_TO_RESET_PASSWORD_PIN:
    'Unable to send email. Please contact Administrator or Progressive Beef Program Manager.',
  NOT_SUPPORTED: 'Fingerprint not supported',
  NOT_PRESENT: 'Fingerprint not available on this device',
  ERROR_ACCOUNT_LOCKED:
    'Your account has been locked due to too many failed login attempts.',
  ERROR_LOGIN_FAILED:
    'Please enter correct username and password / PIN to login.',
  ERROR_NOT_AUTHORIZED: 'You are not authorized to access this system',
  ERROR_INSTANCE_ALREADY_EXISTS:
    'This instance name already exists. Choose a new unique name. Try adding a date or model number.',
  EMPTY_FIELD: 'Please fill all fields',
  INVALID_PIN: 'Invalid PIN. PIN must be a number.',
  PIN_LENGTH_ERROR: 'PIN must be 4 digits long.',
  PIN_MATCH_ERROR: 'Both PINs must be same.',
  SOMETHING_WENT_WRONG: 'Something went wrong',
  AN_ERROR_OCCURED: 'An error occured',
  INVALID_PIN_UNLOCK: 'PIN is incorrect, please try again',
  FORGET_PASSWORD_EMPTY_USERNAME: 'Please provide your username.',
  OFFLINE_SWITCH_FEEDLOT:
    'Unable to switch feedlot. You are currently offline.',
  ERROR_USER_ALREADY_EXISTS: 'This user already exists in the system.',
  PASSWORDS_DONT_MATCH: 'Both Passwords must be same',
  VALIDATE_USER_PASSWORD_LENGTH: 'Passwords must be 10 characters long',
  VALIDATE_USER_NAME_LENGTH_MIN: 'Username must be at least 3 characters long',
  VALIDATE_USER_NAME_LENGTH_MAX:
    'Username must not be longer than 45 characters',
  UNABLE_TO_SUBMIT_AUDIT: 'Unable to submit audit to the Auditor',
  UNABLE_TO_LOAD_AUDIT_REPORT: 'Unable to load Audit Report',
  LOGIN_FIELDS_MISSING:
    'Please enter correct username and password / PIN to login.',
  // NEW ENTRY
  ACTIVITY_BIN: 'Activity Bin',
  SEARCH: 'Search',
  APPLY_TAGS: 'Apply Tags',
  APPLY: 'Apply',
  SELECT_TAGS: 'Select Tags',
  ASSIGNED_TAGS: 'Assigned Tags',

  ALL: 'All',
  ASSIGNED: 'Assigned',
  UNASSIGNED: 'Unassigned',

  VIEW_SUBMISSIONS: 'View Submissions',
  VIEW_INSTANCES: 'View Instances',
  ASSIGN: 'Assign',
  HISTORY: 'History',
  SUBMISSIONS_PER: 'submission(s) per',

  REVISION_DATE: 'Revision Date & Time',
  FEEDYARD_REVISION_DATE: 'Feedyard Revision Date & Time',
  REQUIREMENTS: 'Requirements',
  ASSIGNED_TO: 'Assigned To',
  ACTIVITY_TAGS: 'Activity Tags',
  NONE: 'None',

  DUE_TODAY: 'Due Today',
  DUE_WEEK: 'Due This Week',
  DUE_MONTH: 'Due This Month',
  DUE_QUARTER: 'Due This Quarter',
  UPCOMING: 'Upcoming',
  UNSCHEDULED: 'Unscheduled',

  EDIT_DOCUMENT: 'Edit Document',
  MOVE_TO_BIN: 'Move to Bin',
  VIEW_DOCUMENT: 'View Document',
  URL: 'URL',
  DETAILS: 'Details',

  NO_RECORD_FOUND: 'No Record Found',
  NO_ACTIVITIES_FOUND: 'No Activities Found',
  NO_ASSOSIATED_ACTIVITIES_FOUND: 'No associated activities found',
  NO_DOCUMENTS_FOUND:
    'No document found. Contact Progressive Beef or check back later.',
  NO_URL_FOUND: 'No URL information to save or update',
  INVALID_URL: 'Invalid URL. Please provide URL in a valid format.',
  PLEASE_FILL_ALL_FIELDS: 'Please fill all required fields.',
  EMPTY_URL_LIST_MSG: 'No URLs exist for this document',

  INSTRUCTIONS: 'Instructions',
  ADD_DOCUMENT: 'Add Document',
  DOCUMENT_NO: 'Document #',
  VISIBLE_TO: 'Visible To',
  TITLE: 'Title',
  ENTER_TITLE: 'Enter Title',
  ATTACH: 'Attach',
  SELECT_FILES: 'Select Files',
  ENTER_URL: 'Enter URL',
  ENTER_TEXT: 'Enter Text',
  PLEASE_SELECT: 'Please Select',
  ATTACHMENT_MAX_SIZE_EXCEED:
    'Unable to upload the file. It exceeds the max 5MB size allowed.',
  INVALID_DOCUMENT_NO:
    'Any special character from /\\:*?"<>|& is not allowed in Document#',
  INVALID_DOCUMENT_TITLE:
    'Any special character from /\\:*?"<>| is not allowed in the Document Title',

  YEARLY: 'Year',
  QUATERLY: 'Quarter',
  MONTHLY: 'Month',
  WEEKLY: 'Week',
  DAILY: 'Day',
  BIANNUALLY: 'every 6 months',

  SUNDAY: 'Sunday',
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  ONE_WEEK: '1 Week',
  TWO_WEEKS: '2 Weeks',
  THREE_WEEKS: '3 Weeks',
  DAY_OF_THE_MONTH: 'Day of the Month',

  NO_MORE_RECORDS: 'No More Records',

  _MONTHLY: 'Last Month',
  _WEEKLY: 'Last Week',
  _DAILY: 'Yesterday',
  COMULATIVE: 'Cumulative',

  REQUEST_SENT_TO_ADMIN: 'Your request has been sent to admin',
  INVALID_DATES: 'Invalid Dates',
  START_LESS_THAN_END_DATE: 'End date must be same or later than start date',
  INVALID_EMAIL: 'Invalid email address',

  DOCUMENTS_BIN: 'Documents Bin',
  MOVE_TO_ACTIVE: 'Move To Active',
  EARLIER_END_DATE_ERROR: 'End date must be later than start date.',
  INVALID_DATE: 'Invalid Date',
  EMAIL_REPORT: 'Email Report',
  TRAININGS_ENABLED:
    'Note: Trainings enabled for viewing will only be visible in the selected date range.',
  END_DATE_EARLIER_THAN_TODAY_ERROR: 'Date range must be earlier than today',
  ENTER_NUMBER: 'Enter Number',
  ENTER_DECIMAL: 'Enter Decimal',
  CANNOT_LOAD_ATTACHMENT:
    'Cannot load attachment due to inactive internet connection.',
  ATTACHMENT_OFFLINE_ERROR: 'Attachments in offline are not supported',

  TRAINING: 'Training',
  EMPLOYEE: 'Employee',
  SORT_BY: 'Sort By',
  REPORT_EMAIL_SENT: 'Your request has been processed successfully.',
  ADD_TRAINING: 'Add Training',
  EDIT_TRAINING: 'Edit Training',
  VIEW_TRAINING: 'View Training',
  TRAINING_NUMBER: 'Training #',
  TAGS_APPLICABLE_TRAINING: 'Tags Applicable on Training',
  TRAINING_FREQUENCY: 'Training Frequency',
  TRAINING_VISIBLE_AFTER: 'Training Visible After',
  NOTICATION_LEVEL: 'Notification Level',
  TRAINING_VISIBLE_TO: 'Training visible to',
  DUE_AFTER: 'Due After',
  ONCE: 'Once',
  SIX_MONTHS: 'Six Months',
  ONE_YEAR: 'One Year',
  NUMBER_OF_DAYS: '# of Days',

  FEEDLOT_MANAGER: 'Feedlot Manager',
  SUPERVISOR: 'Supervisor',
  WORKER: 'Worker',
  AUDITOR: 'Auditor',
  BOTH: 'Both',

  MISSED_TRAINING: 'Employee Missed The Training',
  TRAININGS: 'Trainings',
  REVISION: 'Revision',
  FEEDYARD_REVISION: 'Feedyard Revision',
  MOVE_TO_ACTIVE_CONFIRMATION:
    'Are you sure you want to restore this binned training?',
  MOVE_TO_ACTIVE_DOCUMENT:
    'Are you sure you want to restore this binned document?',
  RESTORED_SUCCESSFULLY: 'Training has been restored successfully.',
  RESTORED_DOCUMENT: 'Document has been restored successfully.',
  BINNED_SUCCESSFULLY: 'This training has been successfully moved to Bin.',
  BINNED_DOCUMENT: 'This document has been successfully moved to Bin.',
  BINNED_OFFLINE: 'You cannot move this document to Bin while offline.',
  RESTORED_OFFLINE: 'You cannot restore this document while offline.',

  ASSIGNED_USERS: 'Assigned Users',
  VIEWED: 'Viewed',
  UNVIEWED: 'Unviewed',
  OVERDUE: 'Overdue',
  FILTER: 'Filter',
  NO_USER_FOUND: 'No user(s) found in this status',

  NO_CATEGORY: 'No category available',
  NO_VIDEO: 'No video available',
  VIDEOS: 'Video(s)',
  UPLOADED_ON: 'Uploaded on',
  MARK_AS_VIEWED: 'Mark as Viewed',
  SEND_REQUEST: 'Send Request',

  TRAINING_ADDED: 'Training added successfully.',
  TRAINING_UPDATED: 'Training updated successfully.',
  DOCUMENT_ADDED: 'Document added successfully.',
  DOCUMENT_UPDATED: 'Document updated successfully.',
  TRAINING_VIEWED: 'Training has been viewed.',
  UNABLE_TO_MARK: 'Unable to mark training status as Viewed.',
  VIEWED_OFFLINE:
    'You cannot mark this training status as Viewed while offline.',
  ASSIGN_TRAINING: 'Assign Training',
  ASSIGN_DOCUMENT: 'Assign Document',

  ACCOUNT: 'Account',
  PROGRAM_MANUAL: 'Program Manual',
  NO_VIDEO_AVAILABLE: 'No video available',
  BACKDATED_ACTIVITIES: 'Backdated Activities',
  BACKDATED_DATA_NOT_AVAILABLE: 'Data not available to plot the Graph.',

  YESTERDAY: 'Yesterday',
  LAST_WEEK: 'Last Week',
  LAST_MONTH: 'Last Month',
  CURRENT_YEAR: 'Current Year',
  THIS_WEEK: 'This Week',
  THIS_MONTH: 'This Month',
  THIS_YEAR: 'Current Year',
  SELECT_DATE_RANGE_LABEL: 'Select Date Range',

  RANK_LABEL:
    'You stand {position}{position_ordinal} out of {total_feedyards} feedyards',
  COMPLAINCE_LABEL: 'Your feedyard compliance rate is {performance}%.',
  KEEP_IT_UP: 'Keep it up!',
  UNABLE_TO_SEND_REQUEST: 'Unable to send request. You are currently offline.',
  DOWNLOADING: 'Downloading...',
  OFFLINE_MODE_REFRESH_ERROR: 'Unable to refresh. You are currently offline.',
  OFFLINE_MODE_LOAD_MORE_ERROR:
    'Unable to load more. You are currently offline.',
  DIFFERENT_FEEDLOT_ERROR:
    'Please select the feedyard to which this notification belongs to.',
  FEEDLOT_NOT_SELECTED_ERROR:
    'Please select the correct feedyard to view this notification.',

  ERROR_CODE_00001: 'Record not found.',
  ERROR_CODE_00002: 'Problem adding record to database.',
  ERROR_CODE_00003: 'This user already exists in the system.',
  ERROR_CODE_00004: 'Incorrect result set column count exception.',
  ERROR_CODE_00005: 'Empty result set data access record not found.',
  ERROR_CODE_00006: 'Cannot get jdbc connection.',
  ERROR_CODE_00007: 'Permission denied to access a resource.',
  ERROR_CODE_00008: 'Type mismatch among java and jdbc columns',
  ERROR_CODE_00009: 'Data retrieval failure.',
  ERROR_CODE_00010: 'Duplicate or unique key constraint occurs.',
  ERROR_CODE_00011: 'Bad sql grammar exception.',
  ERROR_CODE_00012: 'Fatal error occurs runtime exception occurs.',
  ERROR_CODE_00013: 'A DB exception has occurred.',
  ERROR_CODE_00014: 'One or more required parameters are missing.',
  ERROR_CODE_00015: 'Problem updating record to database.',
  ERROR_CODE_00016: 'Invalid user role key is provided.',
  ERROR_CODE_00017: 'Problem importing Document.',
  ERROR_CODE_00018: 'Invalid language keys specified for Document.',
  ERROR_CODE_00019: 'Error occurred while processing document request.',
  ERROR_CODE_00020: 'Invalid revision date is provided.',
  ERROR_CODE_00021: 'Unable to process document.',
  ERROR_CODE_00022: 'Unauthorized user request.',
  ERROR_CODE_00023: 'Your session has expired, please login again',
  ERROR_CODE_00024: 'No such document available to import.',
  ERROR_CODE_00025: 'No such document available to update.',
  ERROR_CODE_00026: 'This feedyard already exists in the system.',
  ERROR_CODE_00027: 'Your session has expired, please login again',
  ERROR_CODE_00028:
    'Request without token will not be entertained expecting token.',
  ERROR_CODE_00030:
    'This email address is already associated with another user.',
  ERROR_CODE_00031: 'You are not authorized to access this system',
  ERROR_CODE_00032: 'Password has been successfully reset.',
  ERROR_CODE_00033: 'Password has been successfully changed.',
  ERROR_CODE_00034: 'Old password is incorrect.',
  ERROR_CODE_00035: 'Problem resetting password.',
  ERROR_CODE_00036: 'Either user id or feedlot activity id is not provided.',
  ERROR_CODE_00037: 'Validation Failed.',
  ERROR_CODE_00038:
    'One or more keys provided for Activity entries is not an Integer.',
  ERROR_CODE_00039: 'Unable to process Activity.',
  ERROR_CODE_00040: 'Activity data not correct.',
  ERROR_CODE_00041: 'Problem adding submission reason in database.',
  ERROR_CODE_00042: 'There is some problem importing activities to feedlot.',
  ERROR_CODE_00043: 'No such activity available to update.',
  ERROR_CODE_00044: 'There was a problem importing feedlot associations.',
  ERROR_CODE_00045:
    'This instance name already exists. Choose a new unique name. Try adding a date or model number.',
  ERROR_CODE_00046: 'This feedlot already has an active feedlot manager.',
  ERROR_CODE_00047:
    'An activity by this number already exists. Please select a new number or edit that activity.',
  ERROR_CODE_00048:
    'A document by this number already exists. Please select a new number or edit that document.',
  ERROR_CODE_00049: 'Validation failed for activity schedule.',
  ERROR_CODE_00050:
    'Calendar entry lower than the template scheduled is allowed.',
  ERROR_CODE_00051: 'Validation failed for scheduling.',
  ERROR_CODE_00052:
    'Either due date is not provided or incorrect for event based activity.',
  ERROR_CODE_00053:
    'This document is already associated with provided activity.',
  ERROR_CODE_00054: 'Problem deleting record to data base.',
  ERROR_CODE_00055: 'An archived document cannot be associated/unassociated.',
  ERROR_CODE_00056:
    'A published document cannot be associated/unassociated with any activity. Please try associating/unassociatin with a draft version of the document.',
  ERROR_CODE_00057: 'All the users are unassigned from this activity. ',
  ERROR_CODE_00058:
    'This revision date already exists please provide a later date.',
  ERROR_CODE_00059: 'No activities are available to import at this moment.',
  ERROR_CODE_00060: 'Invalid synchronization call parameters.',
  ERROR_CODE_00061:
    'This user is the trainer for feedlot(s). Assign another trainer to feedlot.',
  ERROR_CODE_00071: 'Required value for key is missing.',
  ERROR_CODE_00072: 'Value for key is invalid.',
  ERROR_CODE_00073: 'Activity submission time not in correct format.',
  ERROR_CODE_00074: 'Submissions are only acceptable for current date.',
  ERROR_CODE_00075: 'Submissions are only acceptable for current date.',
  ERROR_CODE_00076: 'All fields not submitted.',
  ERROR_CODE_00077: 'No file specified in the request.',
  ERROR_CODE_00078: 'More than one file specified in the request.',
  ERROR_CODE_00079: 'Invalid file content type.',
  ERROR_CODE_00080: 'Zipcode and state values mismatch.',
  ERROR_CODE_00081: 'File could not be copied to the server.',
  ERROR_CODE_00082: 'Attachment file does not exists on the server.',
  ERROR_CODE_00083:
    'Unable to deprecate document. Unable to delete draft version of document.',
  ERROR_CODE_00088: 'Backdate provided is in invalid format',
  ERROR_CODE_00089: 'Backdate should be at least a day old.',
  ERROR_CODE_00090: 'You are not permitted to submit.',
  ERROR_CODE_00091: 'One or more users are not permitted on this activity.',
  ERROR_CODE_00092: 'This submission is already cancelled.',
  ERROR_CODE_00093: 'Problem importing activities. Please inform system admin.',
  ERROR_CODE_00094: 'No documents are available to import at this moment.',
  ERROR_CODE_00095: 'Submission date is in invalid format.',
  ERROR_CODE_00096: 'This revision date already exists for this Activity.',
  ERROR_CODE_00097: 'This revision date already exists for this document.',
  ERROR_CODE_00098:
    'Please choose a revision date later than the last revision date.',
  ERROR_CODE_00100: 'There are no published activities in the feedlot.',
  ERROR_CODE_00101: 'Invalid date format.',
  ERROR_CODE_00102: 'PDF file generation error.',
  ERROR_CODE_00110: 'The provided status is not allowed for this document.',
  ERROR_CODE_00111: 'PDF file does not exist.',
  ERROR_CODE_00112: 'Unable to deprecate document. Document not found.',
  ERROR_CODE_00113:
    'Document cannot be restored. Deprecated version of this document does not exists.',
  ERROR_CODE_00120:
    'Unable to deprecate document. Could not delete an existing draft.',
  ERROR_CODE_00121:
    'Unable to deprecate document. Unable to update status to deprecated.',
  ERROR_CODE_00122:
    'Unable to deprecate document. Unable to remove associations.',
  ERROR_CODE_00123: 'Unable to restore document. Document not found.',
  ERROR_CODE_00124: 'Date format is invalid.',
  ERROR_CODE_00125: 'Document already deprecated.',
  ERROR_CODE_00150: 'Feedlot report data not correct.',
  ERROR_CODE_00151: 'Feedlot report ID is null.',
  ERROR_CODE_00152: 'PDF File Generation Error',
  ERROR_CODE_00153: 'Invalid Password.',
  ERROR_CODE_00154: 'No operation can be performed on a deprecated activity.',
  ERROR_CODE_00155: 'Problem restoring activity.',
  ERROR_CODE_00156: 'Activity does not exist with deprecated status.',
  ERROR_CODE_00157: 'Either Special Log or No Instance Found for Special log. ',
  ERROR_CODE_00158:
    'This operation can not be performed on a special activity.',
  ERROR_CODE_00159: 'Problem archiving activity to deletion table.',
  ERROR_CODE_00160: 'No statistics found.',
  ERROR_CODE_00161: 'No such feedlot exists.',
  ERROR_CODE_00162: 'Invalid event.',
  ERROR_CODE_00163: 'Server Permission {permission}',
  ERROR_CODE_00164: 'Invalid scroll parameters.',
  ERROR_CODE_00165: 'No updates found.',
  ERROR_CODE_00200:
    'One or more Logs from Log8 Log8.1 and Log8a are missing or are not published in Feedyard.',
  ERROR_CODE_00063:
    'Cannot be marked complete. No submissions exist for [date].',
  ERROR_CODE_00065:
    'Your account has been locked due to too many failed login attempts.',
  ERROR_CODE_00066:
    'Choose a strong password with 10-15 capital and small characters with numbers. Contact your Manager or Trainer for assistance.',
  ERROR_CODE_00222:
    'Backdated submissions not allowed on earlier revision of this activity.',
  ERROR_CODE_00062: 'Your session has expired, please login again',
  ERROR_CODE_00068: 'Invalid days of week provided.',
  ERROR_CODE_00069: 'Please select valid day(s) of the month.',
  ERROR_CODE_00414: 'Old PIN is incorrect.',
  ERROR_CODE_00427: 'This instance is deactivated.',
  ERROR_CODE_00429: 'There are no activities in the selected date range.',
  ERROR_CODE_00854: 'Username should not be less than 3 characters',
  ERROR_CODE_00900:
    'A document by this number already exists. Please select a new number',
  ERROR_CODE_00903: 'Email sending failed',
  ERROR_CODE_00950: 'Video not available',
  ERROR_CODE_00814: 'This audit has already been closed.',
  ERROR_CODE_00815:
    'Failed to post comment. User role could not be identified.',
  ERROR_CODE_00816:
    'Failed to post comment. Non-conformance has already been resolved.',
  ERROR_CODE_00166: 'User is already unlocked.',
  SUBMISSION_ADDED: 'Submission Successful.',
  SUBMISSION_REMOVED: 'Submission removed.',
  SUBMISSION_ADDED_PENDING: 'Submission Pending.',
  SUBMISSION_REMOVED_PENDING: 'Remove Pending.',
  REASON_FOR_REMOVAL: 'Reason for Removal',
  REASON_REQUIRED: 'You must enter a reason for removal of this instance.',
  ADD_SUBMISSION_CONFIRMATION:
    'Submit this entry for [activityTitle] as entered on [date] as [userName]?',
  IOS_CAMERA_PERMISSION_ALERT:
    "You haven't accepted Camera permissions for this app previously. Please go to settings and enable Camera permissions for this app.",
  RECENTLY_MISSED: 'Recently Missed',
  NO_MISSED_ACTIVITY_FOUND: 'No missed entry found',
  CANNOT_SWITCH_FEEDLOT_WHILE_SYNCING:
    'Unable to switch feedlot. Your data is currently syncing.',
  AGO: 'ago',
  TODAY: 'today',
  DAYS: 'days',
  ONE_DAY: 'day',
  RESUBMIT: 'Resubmit',
  PENS_ALREADY_CLEANED: 'Pens already cleaned',
  PENS_NOT_CLEANED: 'Pens not cleaned',
  ACTIVITIES_TO_SYNC: 'Activities to Sync',
  ACTIVITIES_WAITING_TO_SYNC: 'Activities Waiting to Sync',
  NO_PENDING_ACTIONS: 'No pending actions',
  ATTACHMENT_INFO_MESSAGE: 'Only PNG,JPG & PDF files upto 5 MB supported.',
  ATTACHMENT_SIZE_ERROR:
    'Attached attachment size exceeds limits, please reduce size and try again.',
  ATTACHMENT: 'Attachment',
  PDF_PREVIEW: 'PDF Preview',
  IMAGE_PREVIEW: 'Image Preview',
  LAST_SYNCED: 'Last synced',
  REMOVE_INSTANCE_WITHOUT_REASON_LABEL:
    'Are you sure you want to remove this instance?',
  BACKDATED_SUBMISSIONS: 'Backdated Submissions',
  ERROR_CODE_00064: 'Please provide valid credentials.',
  LAST_SYNC_FAILED: 'Last Sync Failed',
  NO_ACTIVITES_ASSIGNED: 'No activities assigned',
  USER_ROLE_1: 'Program Manager',
  USER_ROLE_2: 'Trainer',
  USER_ROLE_3: 'Feedlot Manager',
  USER_ROLE_4: 'Supervisor',
  USER_ROLE_5: 'Worker',
  USER_ROLE_6: 'Internal Auditor',
  USER_ROLE_7: 'External Auditor',
  USER_ROLE_8: 'Internal Reviewer',
  USER_ROLE_9: 'External Reviewer',

  APP_TRAINING_VIDEO_HTML: 'PB Training Videos',

  OLD_AUDIT_NOTIFICATION_TAP_ERROR:
    'You can view only two recently closed audits',
  CHECK_FEEDYARD_RANK: 'Check Feedyard Rank',

  WAITING_FOR_SUBMISSION: 'Waiting for Submission',
  TWELVE_MONTHS: '12 months',

  // NEW
  info1: "All non-conformances (NC's) found during the ",
  info2:
    ' Progressive Beef audit are listed below, along with necessary corrective actions required to clear the audit. Any responses or documentation submitted has been reviewed by ',
  info3:
    ' and has been found to adequately address the non-conformances. Clearance dates for each NC are listed below.',
  AUDIT_SUMMARY: 'Audit Summary',
  CLEARANCE_LETTER: 'Clearance Letter',
  ATTACH: 'ATTACH',
  WANT_TO_DELETE_THIS_ATTACHMENT:
    'Are you sure you want to remove this attachment?',
  INFO: 'INFO',
  ATTACHMENT_LIMIT_REACHED: `You can only add up to 3 attachments`,
};
