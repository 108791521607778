import React, { Component } from 'react';
import './style.scss';
//language
import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

class BubbleLabel extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { text } = this.props;

    return (
      <>
        <div
          className={`bubble-txt${
            text === localization[getLocale()].UNRESOLVED ? ' unresolved' : ''
          }`}
        >
          {`${text ? text : ''}`}
        </div>
      </>
    );
  }
}

export default BubbleLabel;
