import React from 'react';
import moment from 'moment';
import { DATE_FORMATS } from '@progressivebeef/shared/src/constants/AppConstants';

const RowItem = (props) => {
  const { heading, value, onPress, CustomItem } = props;
  return (
    <div className="flex-col" onClick={onPress}>
      <div className="label">{heading}</div>
      <div>
        {CustomItem ? <CustomItem></CustomItem> : <div>
          {value instanceof Date
            ? moment(value).format(DATE_FORMATS.MM_DD_YYYY)
            : value}
        </div>}
      </div>
    </div>
  );
};

export default RowItem;
