import React, { useRef, useState } from 'react';
import PDFViewer from 'mgr-pdf-viewer-react';
import { Spinner } from 'reactstrap';
import './style.scss';
import PBIcon from '../PBIcon';

var angle = 0;

const PdfViewer = (props) => {
  const pdf = useRef(null);
  const [scale, setScale] = useState(1);

  let tapCount = 0;
  const { source } = props;

  // useEffect(() => {
  //   // console.log('pdfref', pdf);
  //   // console.log(document.getElementsByClassName('pdf-doc-box')[0].children[0].children[0].height)
  //   // console.log('asdbjasbdjabsjdbaksjb abas', document.getElementById(props.iden).getBoundingClientRect().width, document.getElementById(props.iden).getBoundingClientRect().height, document.getElementsByTagName('canvas') && document.getElementsByTagName('canvas').length > 1 ? document.getElementsByTagName('canvas')[0].clientHeight + ' ' + document.getElementsByTagName('canvas')[0].clientWidth : 0);
  //   if (document.getElementById(props.iden).getBoundingClientRect().width > 1000) {
  //     // console.log('asdbjasbdjabsjdbaksjb after', document.getElementById(props.iden).getBoundingClientRect().width, document.getElementById(props.iden).getBoundingClientRect().height);
  //     document.getElementById(props.iden).classList.add('pdf-doc-view-custom');
  //   } else {
  //     // console.log('asdbjasbdjabsjdbaksjb remove', document.getElementById(props.iden).getBoundingClientRect().width, document.getElementById(props.iden).getBoundingClientRect().height);
  //     document.getElementById(props.iden).classList.remove('pdf-doc-view-custom');
  //   }
  // }, []);

  return (
    <div>
      <div id={props.iden} className={`pdf-doc-view-parent `}>
        <PDFViewer
          ref={pdf}
          document={source}
          scale={scale}
          css={'pdf-doc-box'}
          loader={
            <div className="bottom-infinite-loader">
              <Spinner color={'red'} />
            </div>
          }
          onDocumentClick={(e) => {
            if (tapCount == 1) {
              tapCount = 0;
              if (scale == 1.2) {
                setScale(1);
              } else {
                setScale(1.2);
              }

              if (scale != 1.2) {
                document.getElementById(props.iden).classList.add('zoomin');
              } else {
                document.getElementById(props.iden).classList.remove('zoomin');
              }
            } else {
              tapCount++;
            }
          }}
          navigation={(nav) => {
            return (
              <nav className="pdf-pagination-box">
                <button
                  className="head-icon"
                  onClick={() => {
                    // document.getElementsByTagName('canvas')[0].style.transform = `rotate(${angle - 90}deg)`;
                    document.getElementById(props.iden).classList.remove(`pdf-doc-view-${angle}`);
                    if (angle === 0) {
                      angle = 270;
                    } else {
                      angle = angle - 90;
                    }
                    document.getElementById(props.iden).classList.add(`pdf-doc-view-${angle}`);
                  }}
                >
                  <i className="fas fa-undo"></i>
                </button>
                {nav.page != 1 && (
                  <button
                    onClick={() => {
                      nav.handlePrevClick();
                    }}
                  >
                    Prev
                  </button>
                )}
                <span>
                  Page {nav.page} of {nav.pages}
                </span>
                {nav.page != nav.pages && (
                  <button
                    onClick={() => {
                      nav.handleNextClick();
                    }}
                  >
                    Next
                  </button>
                )}

                <button
                  className="head-icon"
                  onClick={() => {
                    document.getElementById(props.iden).classList.remove(`pdf-doc-view-${angle}`);
                    if (angle === 270) {
                      angle = 0;
                    } else {
                      angle = angle + 90;
                    }
                    document.getElementById(props.iden).classList.add(`pdf-doc-view-${angle}`);
                    // document.getElementsByTagName('canvas')[0].style.transform = `rotate(${angle + 90}deg)`;
                  }}
                >
                  <i className="fas fa-redo"></i>
                </button>
              </nav>
            );
          }}
        />
      </div>
    </div>
  );
};

export default PdfViewer;
