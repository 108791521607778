import React, { PureComponent } from 'react';
import MenuPicker from '../MenuPicker';
import localization from '@progressivebeef/shared/src/localization/i18n';
import ROUTE_CONSTANTS from '@progressivebeef/shared/src/constants/RouteConstants';
import MoreOptionCard from '../MoreOptionCard';
import {
  getLocale,
  getUserRole,
} from '@progressivebeef/shared/src/services/storageService/GlobalData';
import { userRoles } from '@progressivebeef/shared/src/constants/AppConstants';
import { showAlertMsg, showErrorAlert } from '../Alerts';
import { logoutRequested } from '@progressivebeef/shared/src/store/actions/authentication';
import { connect } from 'react-redux';

class MorePickerModal extends PureComponent {
  render() {
    const { modalVisible, onPressEvent } = this.props;
    const { history } = this.props;

    return (
      <MenuPicker
        className="adadad"
        show={modalVisible}
        onRequestClose={() => {
          onPressEvent();
        }}>
        <div>
          {getUserRole() !== userRoles.WORKER ? (
            <MoreOptionCard
              onPress={() => {
                history.push(ROUTE_CONSTANTS.AUDIT);
              }}
              name={localization[getLocale()].AUDITS.toUpperCase()}
              iconName={'tasks'}
            />
          ) : null}
          <MoreOptionCard
            onPress={() => {
              history.push(ROUTE_CONSTANTS.NAVIGATION_VIDEOS);
            }}
            name={localization[getLocale()].NAVIGATION_VIDEOS.toUpperCase()}
            iconName={'film'}
          />
          <MoreOptionCard
            onPress={() => history.push(ROUTE_CONSTANTS.APP_TRAINING_VIDEO_HTML)}
            name={localization[getLocale()].APP_TRAINING_VIDEO_HTML.toUpperCase()}
            iconName={'photo-video'}
          />
          <MoreOptionCard
            onPress={() => history.push(ROUTE_CONSTANTS.SETTINGS)}
            name={localization[getLocale()].SETTINGS.toUpperCase()}
            iconName={'cogs'}
          />
          <MoreOptionCard
            onPress={() => history.push(ROUTE_CONSTANTS.SWITCH_FEEDYARD)}
            name={localization[getLocale()].SWITCH_FEEDYARD.toUpperCase()}
            iconName={'warehouse'}
          />
          <MoreOptionCard
            onPress={() => {
              showAlertMsg(
                localization[getLocale()].CONFIRMATION.toUpperCase(),
                localization[getLocale()].LOGOUT_CONFIRMATION,
                [
                  {
                    text: 'No',
                    onPress: () => {
                      'cancel';
                    },
                  },
                  {
                    text: 'Yes',
                    onPress: () => {
                      console.log('logout');
                      if (navigator.onLine) {
                        this.props.history.push('/Logout');
                      } else {
                        setTimeout(() => {
                          showErrorAlert(
                            localization[getLocale()].NO_INTERNET_CONNECTION,
                          );
                        }, 100);
                      }
                    },
                  },
                ],
              );
            }}
            name={localization[getLocale()].LOGOUT.toUpperCase()}
            iconName={'sign-out'}
          />
        </div>
      </MenuPicker>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (payload) => dispatch(logoutRequested(payload)),
  };
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MorePickerModal);
