import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from 'reactstrap';
import classnames from 'classnames';

import AppContainer from '../../../components/AppContainer';
import PBIcon from '../../../components/PBIcon';
import {
  showSuccessAlert,
  showErrorAlert,
  showAlertMsg,
} from '../../../components/Alerts';
import UserCard from '../../../components/UserCard';

import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';
import {
  searchUsersRequested,
  unlockUserRequest,
} from '@progressivebeef/shared/src/store/actions/user';
import { getDocumentListRequested } from '@progressivebeef/shared/src/store/actions/programMaunals';
import {
  USER_STATUS,
  userRoles,
} from '@progressivebeef/shared/src/constants/AppConstants';
import {
  showLoader,
  hideLoader,
} from '@progressivebeef/shared/src/store/actions/view';

import { globalHelper } from '@progressivebeef/shared/src/helpers';

class Users extends PureComponent {
  searchText = '';
  tabType = USER_STATUS.ACTIVE;
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
    };
  }

  componentDidMount() {
    this.searchUsers();
  }

  componentDidUpdate(prevProps) {
    let { isLoading, success, error, showLoaderView, hideLoaderView } =
      this.props;

    if (isLoading) {
      showLoaderView();
    } else {
      hideLoaderView();
    }

    if (success && success !== prevProps.success) {
      showSuccessAlert(localization[getLocale()].COMMON_SAVED_SUCCESSFULLY);
    }

    if (error !== prevProps.error) {
      if (typeof error === 'string') {
        showErrorAlert(error);
      }
    }
  }

  toggle(tab, tabType) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
      this.tabType = tabType;
      this.searchUsers(this.searchText);
    }
  }

  render() {
    const { history, searchText, searchedUsers, isLoading, userLoading, user } =
      this.props;
    const role = Number(user.userRoleId);
    const isAuditor =
      role === userRoles.INTERNAL_AUDITOR ||
      role === userRoles.EXTERNAL_AUDITOR;
    const { activeTab } = this.state;

    return (
      <>
        <AppContainer
          title={localization[getLocale()].USERS}
          onSearchPress={this.searchUsers}
          searchText={searchText}
          iconHeader
          history={history}
          IconRightHeader={this.renderRightHeader}>
          {userLoading ? (
            <div className="bottom-infinite-loader">
              {globalHelper.renderIf(userLoading)(<Spinner color={'red'} />)}
            </div>
          ) : (
            <div className="tab-screen-parent-container">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => {
                      this.toggle('1', USER_STATUS.ACTIVE);
                    }}>
                    {localization[getLocale()].ACTIVE}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => {
                      this.toggle('2', USER_STATUS.INACTIVE);
                    }}>
                    {localization[getLocale()].INACTIVE}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => {
                      this.toggle('3', USER_STATUS.LOCKED);
                    }}>
                    {localization[getLocale()].LOCKED}
                  </NavLink>
                </NavItem>
              </Nav>

              <div id="main" className="tab-screen-scrollable-container">
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    {searchedUsers && searchedUsers.length > 0 ? (
                      searchedUsers.map((user) => {
                        return (
                          <UserCard
                            onCardPress={this.onCardPress}
                            history={history}
                            item={user}
                            isAuditor={isAuditor}
                            {...this.props}
                          />
                        );
                      })
                    ) : isLoading ? (
                      <div className="bottom-infinite-loader">
                        {globalHelper.renderIf(isLoading)(
                          <Spinner color={'red'} />,
                        )}
                      </div>
                    ) : (
                      !isLoading && (
                        <div className="no-result-found-box">
                          <p>{localization[getLocale()].NO_USERS_FOUND}</p>
                        </div>
                      )
                    )}
                  </TabPane>
                  <TabPane tabId="2">
                    {searchedUsers && searchedUsers.length > 0 ? (
                      searchedUsers.map((user) => {
                        return (
                          <UserCard
                            onCardPress={this.onCardPress}
                            history={history}
                            item={user}
                            isAuditor={isAuditor}
                            {...this.props}
                          />
                        );
                      })
                    ) : isLoading ? (
                      <div className="bottom-infinite-loader">
                        {globalHelper.renderIf(isLoading)(
                          <Spinner color={'red'} />,
                        )}
                      </div>
                    ) : (
                      !isLoading && (
                        <div className="no-result-found-box">
                          <p>{localization[getLocale()].NO_USERS_FOUND}</p>
                        </div>
                      )
                    )}
                  </TabPane>
                  <TabPane tabId="3">
                    {searchedUsers && searchedUsers.length > 0 ? (
                      searchedUsers.map((user) => {
                        return (
                          <UserCard
                            {...this.props}
                            item={user}
                            onCardPress={this.onCardPress}
                            history={history}
                            isLockedTab={true}
                            isAuditor={isAuditor}
                            onUnlockUserButtonPress={
                              this.onUnlockUserButtonPress
                            }
                          />
                        );
                      })
                    ) : isLoading ? (
                      <div className="bottom-infinite-loader">
                        {globalHelper.renderIf(isLoading)(
                          <Spinner color={'red'} />,
                        )}
                      </div>
                    ) : (
                      !isLoading && (
                        <div className="no-result-found-box">
                          <p>{localization[getLocale()].NO_USERS_FOUND}</p>
                        </div>
                      )
                    )}
                  </TabPane>
                </TabContent>
              </div>
            </div>
          )}
        </AppContainer>
      </>
    );
  }

  renderRightHeader = () => {
    const { user } = this.props;
    const role = Number(user.userRoleId);
    // auditors cannot add users
    return (
      role !== userRoles.INTERNAL_AUDITOR &&
      role !== userRoles.EXTERNAL_AUDITOR && (
        <div className="head-icon right-align">
          <PBIcon
            name={'plus'}
            onPress={() => {
              this.props.getDocumentList();
              this.props.history.push(RouteConstants.ADD_USER);
            }}
          />
        </div>
      )
    );
  };

  searchUsers = (searchText = '') => {
    this.searchText = searchText;
    this.props.searchUsers(this.tabType, searchText);
  };

  onUnlockUserButtonPress = (user) => {
    showAlertMsg(
      localization[getLocale()].CONFIRMATION,
      localization[getLocale()].UNLOCK_USER_CONFIRMATION,
      [
        {
          text: 'No',
          onPress: () => {},
        },
        {
          text: 'Yes',
          onPress: () => {
            this.props.unlockUser(user);
          },
        },
      ],
    );
  };

  onCardPress = (item) => {
    const { user } = this.props;
    const role = Number(user.userRoleId);
    // auditors cannot add users
    if (
      item.user_role_key != userRoles.FEEDLOT_MANAGER &&
      role !== userRoles.INTERNAL_AUDITOR &&
      role !== userRoles.EXTERNAL_AUDITOR
    ) {
      this.props.getDocumentList();
      this.props.history.push(RouteConstants.ADD_USER, { user: item });
    }
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    searchUsers: (status, searchText) =>
      dispatch(searchUsersRequested({ status, searchText })),
    getDocumentList: (payload) => dispatch(getDocumentListRequested(payload)),
    unlockUser: (user) => dispatch(unlockUserRequest({ user })),
    showLoaderView: (payload) => dispatch(showLoader(payload)),
    hideLoaderView: (payload) => dispatch(hideLoader(payload)),
  };
};

const mapStateToProps = (state) => {
  const { user } = state.authentication;
  let { searchText, isLoading, userLoading, success, error, searchedUsers } =
    state.user;
  return {
    searchText,
    isLoading,
    userLoading,
    success,
    error,
    searchedUsers,
    user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
