import {
  USER_STATUS,
  userRoles,
} from './AppConstants';

export const LoginFormInitialValues = {
  username: '',
  pin: '',
  password: '',
};

export const GeneratePinInitialValues = {
  newPin: '',
  confirmPin: '',
};

export const ForgotPasswordFormInitialValues = {
  username: '',
};

export const AddDocumentFormInitialValues = {
  documentNumber: '',
  users: '',
  englishTitle: '',
  englishFile: '',
  spanishTitle: '',
  spanishFile: '',
  urls: '',
};

export const AddTrainingFormInitialValues = {
  documentNumber: '',
  tags: '',
  frequency: 1,
  numberOfDays: '0',
  dueAfter: '',
  notificationLevel: '3',
  users: '',
  englishTitle: '',
  englishFile: '',
  spanishTitle: '',
  spanishFile: '',
  urls: '',
};

export const AddUserFormInitialValues = {
  user_name: '',
  password: '',
  confirmPassword: '',
  user_role_key: userRoles.SUPERVISOR,
  user_id: null,
  is_active: (USER_STATUS.ACTIVE).toString(),
  joining_date: new Date(),
  first_name: '',
  last_name: '',
  email_address: '',
  document_numbers: [],
};

export const ChangePinFormInitialValues = {
  currentPin: '',
  newPin: '',
  confirmPin: '',
};

export const ChangePasswordFormInitialValues = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

export const CommentInitialValues = {
  comment: '',
};
