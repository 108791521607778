import { success, request } from './index';
import ACTION_CONSTANTS from '../../constants/actionConstants';

export function showLoader(payload = {}) {
  return success(ACTION_CONSTANTS.SHOW_LOADER, payload);
}

export function hideLoader(payload = {}) {
  return success(ACTION_CONSTANTS.HIDE_LOADER, payload);
}

export function showAlert(payload) {
  return success(ACTION_CONSTANTS.SHOW_ALERT, payload);
}

export function hideAlert(payload) {
  return success(ACTION_CONSTANTS.HIDE_ALERT, payload);
}

export function setNotificatioData(payload) {
  return success(ACTION_CONSTANTS.SET_NOTIFICATION_DATA, payload);
}

export function setDeviceInfo(payload) {
  return request(ACTION_CONSTANTS.SET_DEVICE_INFO, payload);
}

export function setBottomBarTab(payload) {
  return request(ACTION_CONSTANTS.SET_BOTTOM_BAR_TAB, payload);
}
