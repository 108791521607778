import React from 'react';

import AssetConstants from '@progressivebeef/shared/src/constants/AssetConstants';

export default ({ source, preview, removeFile, disabled }) => {
  return (
    <div className="file-view-box">
      <div className="icon" onClick={preview}>
        <img alt="description" src={source} />
      </div>
      {!disabled && (
        <div className="close-btn" onClick={removeFile}>
          <img alt="description" src={AssetConstants.stop} />
        </div>
      )}
    </div>
  );
};
