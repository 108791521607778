import { setLocalstorageItem } from './index';
import { LOCAL_STORAGE_KEYS } from '../../constants/AppConstants';

class UserManager {
  saveUserInfo(user) {
    try {
      setLocalstorageItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(user));
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }
}

export default new UserManager();
