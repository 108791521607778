//DB
import submissionModel from '../../models/submissionModel';
import {
  LOCAL_STORAGE_KEYS,
  SUBMISSION_STATUS,
} from '../../constants/AppConstants';
import _ from 'lodash';
import { getLocalstorageItem, setLocalstorageItem } from '.';
import {
  getUserFirstName,
  getUserLastName,
} from '../../services/storageService/GlobalData';

class SubmissionsManager {
  saveActivitySubmissions(submissions, activityNumber, instanceName, isBackdated) {
    try {
      let submissionList = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SUBMISSIONS)) || [];
      //check if their are any submissions for this activity with instance
      let activitySubmissions = submissionList.filter(i => i.activity_number == activityNumber);
      if (instanceName)
        activitySubmissions = activitySubmissions.filter(i => i.instanceName == instanceName);
      
      if (activitySubmissions.length < 10 || isBackdated) {
        let arr = [];
        for (let i in submissions) {
          arr.push(
            submissionModel(submissions[i], null, activityNumber, instanceName, isBackdated)
          );
        }

        let idsArr = arr.map((q) => q.id);

        if (submissionList) {
          let filtered = submissionList.filter((q) => !idsArr.includes(q.id));
          let submissionArr = [...arr, ...filtered];

          setLocalstorageItem(
            LOCAL_STORAGE_KEYS.SUBMISSIONS,
            JSON.stringify(submissionArr)
          );
        } else {
          setLocalstorageItem(
            LOCAL_STORAGE_KEYS.SUBMISSIONS,
            JSON.stringify(arr)
          );
        }

        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log('saveActivitiesSubmissionsfailed', e);
      return false;
    }
  }

  getActivitySubmissions(activityNumber, startIndex, isBackdated) {
    try {
      let submissions =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SUBMISSIONS)) || [];
      submissions = submissions.filter(
        (q) => q.activity_number == activityNumber
      );
      if (isBackdated)
        submissions = submissions.filter(q => q.isBackdated == true);
      let sortedSubmissions = submissions.sort((a, b) =>
        b.submitted_date.localeCompare(a.submitted_date)
      );

      const filteredSubmissions = sortedSubmissions.slice(0, startIndex + 10);
      return sortedSubmissions;
    } catch (e) {
      console.log('getActivitiesSubmissionsfailed', e);
      return false;
    }
  }

  getInstanceSubmissions(activityNumber, instanceName, startIndex, isBackdated) {
    try {
      let submissions =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SUBMISSIONS)) || [];
      submissions = submissions.filter(
        (q) =>
          q.activity_number == activityNumber &&
          q.instance_value == instanceName
      );
      if (isBackdated)
        submissions = submissions.filter(q => q.isBackdated == true);
      let sortedSubmissions = submissions.sort((a, b) =>
        b.submitted_date.localeCompare(a.submitted_date)
      );

      const filteredSubmissions = sortedSubmissions.slice(0, startIndex + 10);
      return sortedSubmissions;
    } catch (e) {
      console.log('getInstanceSubmissionsfailed', e);
      return false;
    }
  }

  clearSubmissions() {
    setLocalstorageItem(
      LOCAL_STORAGE_KEYS.SUBMISSIONS,
      JSON.stringify([])
    );
  }

  clearActivitySubmissions(activityNumber) {
    try {
      let submissions =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SUBMISSIONS)) || [];
      submissions = submissions.filter(
        (q) =>
          q.activity_number != activityNumber ||
          (q.activity_number == activityNumber && q.status == 3)
      );
      setLocalstorageItem(
        LOCAL_STORAGE_KEYS.SUBMISSIONS,
        JSON.stringify(submissions)
      );
    } catch (e) {
      console.log('error clearActivitySubmissions', e);
    }
  }

  clearInstanceSubmissions(instanceName) {
    try {
      let submissions =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SUBMISSIONS)) || [];
      submissions = submissions.filter(
        (q) =>
          q.instance_value != instanceName ||
          (q.instance_value == instanceName && q.status == 3)
      );
      setLocalstorageItem(
        LOCAL_STORAGE_KEYS.SUBMISSIONS,
        JSON.stringify(submissions)
      );
    } catch (e) {
      console.log('error clearInstanceSubmissions', e);
    }
  }

  getSubmissionById(id) {
    let submissions =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SUBMISSIONS)) || [];
    if (submissions) {
      let filtered = submissions.filter((q) => q.id == id);

      if (filtered[0]) {
        return filtered[0];
      }
    }
    return {};
  }

  getSubmissionByFeedlotActivitySubmissionId(id) {
    let submissions =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SUBMISSIONS)) || [];

    submissions = submissions.filter(
      (q) => q.feedlot_activity_submission_id == id
    );

    if (submissions[0]) {
      return submissions[0];
    }
    return {};
  }

  sortActivities() {
    let submissions =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SUBMISSIONS)) || [];

    setLocalstorageItem(
      LOCAL_STORAGE_KEYS.SUBMISSIONS,
      JSON.stringify(submissions)
    );
  }

  saveActivitySubmissionByStatus(
    submission,
    activityNumber,
    instanceName,
    status,
    submissionId
  ) {
    try {
      let id = null;
      if (submission && submission.status === SUBMISSION_STATUS.REMOVED) {
        let submissions =
          JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SUBMISSIONS)) ||
          [];
        let obj = submissions.filter(
          (q) =>
            q.feedlot_activity_submission_id ==
            `"${submission.feedlot_activity_submission_id}"`
        );
        let filterList = submissions.filter(
          (q) =>
            q.feedlot_activity_submission_id !=
            `"${submission.feedlot_activity_submission_id}"`
        );
        if (obj && obj.length > 0) {
          obj = obj[0].id;
          const values = obj.values;
          let removedObj = submissionModel(submission, obj.id);
          removedObj.values = values;
          filterList.push(removedObj);
          setLocalstorageItem(
            LOCAL_STORAGE_KEYS.SUBMISSIONS,
            JSON.stringify(filterList)
          );
        }
      } else if (
        submission &&
        submission.status === SUBMISSION_STATUS.SUBMITTED
      ) {
        let submissions =
          JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SUBMISSIONS)) ||
          [];
        if (status == SUBMISSION_STATUS.PENDING) {
          submission.status = status;
          submission.feedlotActivitySubmissionCancellation =
            submission.feedlot_activity_submission_cancellation;
          delete submission.feedlot_activity_submission_cancellation;
          submission.feedlotActivitySubmissionCancellation[
            'cancellation_date'
          ] = '2020-10-07T07:42:36.965Z';
          submission.feedlotActivitySubmissionCancellation[
            'cancelled_by_user'
          ] = getUserFirstName() + ' ' + getUserLastName();
          submission.removeCallPending = true;

          let oldSubmissionIndex = submissions.findIndex(
            (q) => q.id == submission.id
          );
          if (oldSubmissionIndex != -1) {
            submissions[oldSubmissionIndex] = submission;
          } else {
            submissions.push(submission);
          }
        } else {
          submissions.push(submissions);
        }
        setLocalstorageItem(
          LOCAL_STORAGE_KEYS.SUBMISSIONS,
          JSON.stringify(submissions)
        );
        id = submission.id;
      }

      if (submission && !submission.status && status) {
        submission.status = status;
        const submissionObj = submissionModel(
          submission,
          submissionId,
          activityNumber,
          instanceName,
          new Date(submission.submitted_date) < new Date()
        );
        let submissions =
          JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SUBMISSIONS)) ||
          [];
        submissions.push(submissionObj);
        setLocalstorageItem(
          LOCAL_STORAGE_KEYS.SUBMISSIONS,
          JSON.stringify(submissions)
        );
        id = submissionObj.id;
      }

      return id;
    } catch (e) {
      console.log('saveActivitiesSubmissionsfailed', e);
      return false;
    }
  }

  getPendingActivitySubmissions() {
    let submissions =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SUBMISSIONS)) || [];

    submissions = submissions.filter(
      (q) => q.status == `${SUBMISSION_STATUS.PENDING}`
    );

    return submissions;
  }

  removePendingSubmissions() {
    try {
      let submissions =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SUBMISSIONS)) || [];
      submissions = submissions.filter(
        (q) => q.status != SUBMISSION_STATUS.PENDING
      );

      if (submissions.length > 0) {
        setLocalstorageItem(
          LOCAL_STORAGE_KEYS.SUBMISSIONS,
          JSON.stringify(submissions)
        );
      } else {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.SUBMISSIONS);
      }
    } catch (e) {
      console.log('error removing submission', e);
    }
  }

  removeRejectedSubmission(id) {
    let submissions =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SUBMISSIONS)) || [];
    if (submissions) {
      let filtered = submissions.filter((q) => q.id != id);
      setLocalstorageItem(
        LOCAL_STORAGE_KEYS.SUBMISSIONS,
        JSON.stringify(filtered)
      );
    }

    return true;
  }

  resetSubmissions(activityNumber) {
    let submissions =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SUBMISSIONS)) || [];

    if (submissions) {
      let otherActivitySubmissions = submissions.filter(
        (q) => q.activity_number != activityNumber
          || q.status == `${SUBMISSION_STATUS.PENDING}`
          || q.status == `${SUBMISSION_STATUS.REJECTED}`
      );
      let thisActivitySubmissions = submissions.filter(
        (q) => q.activity_number == activityNumber
          && q.status != `${SUBMISSION_STATUS.PENDING}`
          && q.status != `${SUBMISSION_STATUS.REJECTED}`
      );
      thisActivitySubmissions = thisActivitySubmissions.reverse()
      thisActivitySubmissions = thisActivitySubmissions.splice(0, 10);
      //replace other activity submissions and this activity's 10 submissions only
      submissions = Array.from(
        new Set([...otherActivitySubmissions, ...thisActivitySubmissions])
      );
      setLocalstorageItem(
        LOCAL_STORAGE_KEYS.SUBMISSIONS,
        JSON.stringify(submissions)
      );
    }

    return submissions || [];
  }
}

export default new SubmissionsManager();
