import { LOCAL_STORAGE_KEYS } from '../../constants/AppConstants';
import { getLocalstorageItem, setLocalstorageItem } from '.';

class WorkerManager {
  /**
   * Save single modules metadata
   */
  saveMetaData(metaData) {
    try {
      let metaDataList = getLocalstorageItem(LOCAL_STORAGE_KEYS.META_DATA);
      if (metaDataList && metaDataList.length > 0) {
        metaDataList =
          metaDataList.filter((q) => q.moduleName != metaData.moduleName) || [];
        metaDataList.push(metaData);
        setLocalstorageItem(
          LOCAL_STORAGE_KEYS.META_DATA,
          JSON.stringify(metaDataList)
        );
      } else {
        setLocalstorageItem(
          LOCAL_STORAGE_KEYS.META_DATA,
          JSON.stringify([metaData])
        );
      }
      return true;
    } catch (e) {
      console.log('saveMetaDatafailed', e);
      return false;
    }
  }

  /**
   * Return Realm Object a specific module
   * @param {*} moduleName
   */
  getMetaDataByModule(moduleName) {
    try {
      let metaData =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.META_DATA)) || [];

      metaData = metaData.find((q) => q.moduleName == moduleName);
      return metaData;
    } catch (e) {
      return null;
    }
  }
}

export default new WorkerManager();
