import {
  put,
  takeEvery,
  call,
  select,
  takeLatest,
  delay,
} from 'redux-saga/effects';
import _ from 'lodash';

// services
import {
  getLocale,
  getSelectedFeedlotId,
  getUserId,
} from '../services/storageService/GlobalData';
import DashboardService from '../services/api/dashboard';
import NotificationService from '../services/api/notification';
import getLanguageKey from '../services/languageService';

// Helpers
import ActivitiesHelper from '../helpers/ActivitiesHelper';
import DashboardHelper from '../helpers/DashboardHelper';

// store
import {
  getWorkersSuccess,
  getWorkersFailure,
  getWorkers,
  postFeedlotReportFailure,
  postFeedlotReportSuccess,
  getFeedlotStatsSuccess,
  getFeedlotStatsFailure,
  getFeedlotCustomPerformanceSuccess,
  getFeedlotCustomPerformanceFailure,
  getFeedlotRankSuccess,
  getMobileNotification,
  getMobileNotificationSuccess,
  getMobileNotificationFailure,
  getBackdatedSubmissions,
  getBackdatedSubmissionsSuccess,
  getBackdatedSubmissionsFailure,
  getBackdatedCustomSubmissionsSuccess,
  getBackdatedCustomSubmissionsFailure,
  getBackdatedCustomSubmissionsActivitiesSuccess,
  getMobileNotificationLoadMoreAction,
  getMobileUpdate,
  getMobileUpdateLoadMoreAction,
  getMobileUpdateSuccess,
  getMobileUpdateFailure,
  getMissedActivitiesSuccess,
  getMissedActivitiesFailure,
  getMissedActivities,
  getFeedlotStats,
  resetMobileNotification,
  resetMobileUpdate,
  setMobileNotificationCount,
  getPendingActionsCountFailure,
  getPendingActionsCountSuccess,
  getPendingActionsCount,
  getSearchedPendingActionsSuccess,
  getSearchedPendingActionsFailure,
  getBackdatedActivities,
  getBackdatedActivitiesSuccess,
  getBackdatedActivitiesFailure,
  getFeedlotRankFailure,
  hideRankCard,
  resetRankCard,
} from '../store/actions/dashboard';

// database
import WorkerManager from '../database/reactDataManager/WorkerManager';
// import FeedlotStatsManager from '../database/reactDataManager/FeedlotStatsManager';

import MetaDataManager from '../database/reactDataManager/MetaDataManager';
import SubmissionsManager from '../database/reactDataManager/SubmissionsManager';
import ActivityManager from '../database/reactDataManager/ActivityManager';
import OptionsManager from '../database/reactDataManager/OptionsManager';
import NotificationManager from '../database/reactDataManager/NotificationManager';
import BackdatedSubmissionsManager from '../database/reactDataManager/BackdatedSubmissionsManager';

// general
import ACTION_CONSTANTS from '../constants/actionConstants/index';
import { MODULE_NAMES, NOTIFICATION_EVENTS } from '../constants/AppConstants';
import metaDataDto from '../models/metaDataModel';
import { SERVER_STATUS_CODES } from '../constants/ServerStatusCodes';
import { getActivitiesTags } from '../store/actions/activities';

import localization from '../localization/i18n';
import { getActivitiesAsync } from './activities';

const dashboardStoreRequest = (state) => state.dashboard;
const activitiesStoreRequest = (state) => state.activities;

export function* getWorkersAsync({ payload }) {
  const update = payload.update;
  const loading = payload.loading;
  if (loading) {
    yield put(getWorkers());
  }
  try {
    if (navigator.onLine && update) {
      const res = yield call(DashboardService.getWorkers);
      const workers = res.data.worker_statistics;
      WorkerManager.saveWorkers(workers);
    }
    let dbWorkers = WorkerManager.getWorkers();
    const { activeWorkers, nonActiveWorkers } =
      DashboardHelper.getActiveNonActiveWorkers(dbWorkers);
    yield put(getWorkersSuccess({ activeWorkers, nonActiveWorkers }));
  } catch (e) {
    console.log('getWorkersAsync', e);
    yield put(getWorkersFailure({ error: e.message }));
  }
}

export function* postFeedlotReportAsync({ payload }) {
  try {
    if (navigator.onLine) {
      const res = yield call(DashboardService.postFeedlotReport, payload);
      if (res.code == SERVER_STATUS_CODES._00001) {
        yield put(
          postFeedlotReportFailure({
            error: localization[getLocale()].NO_ACTIVITIES_IN_DATE_RANGE,
          }),
        );
      } else {
        yield put(postFeedlotReportSuccess());
      }
    } else {
      yield put(
        postFeedlotReportFailure({
          error: localization[getLocale()].NO_INTERNET_CONNECTION,
        }),
      );
    }
  } catch (e) {
    yield put(
      postFeedlotReportFailure({
        error: e.message || null, //localization[getLocale()].SOMETHING_WENT_WRONG,
      }),
    );
  }
}

export function* getFeedlotStatsAsync({ payload }) {
  const loading = payload.loading;
  if (loading) {
    yield put(getFeedlotStats());
  }
  try {
    let feedlotStats = null;
    if (navigator.onLine) {
      const res = yield call(DashboardService.getFeedlotStatistics);
      const { feedlot_statistics } = res.data;
      feedlotStats = feedlot_statistics.feedlotPerformance;
    }
    yield put(
      getFeedlotStatsSuccess({
        feedlotPerformance: feedlotStats,
      }),
    );
  } catch (e) {
    yield put(
      getFeedlotStatsFailure({
        error: e.message || null, //localization[getLocale()].SOMETHING_WENT_WRONG,
      }),
    );
  }
}

export function* getBackdatedActivitiesAsync({ payload }) {
  try {
    const dashboardStore = yield select(dashboardStoreRequest);
    yield put(getBackdatedActivities());
    const type = payload.type;
    const query = dashboardStore.backdatedQuery;
    const filter = dashboardStore.backdatedFilter;

    let tags = yield call(ActivityManager.getTags);
    yield put(getActivitiesTags(tags));

    // if (type === BACKDATED_SUBMISSIONS_FILTER.CUSTOM) {
    //   const backdatedActivitiesRequest = (state) =>
    //     state.dashboard.backdatedSubmissionsActivities;
    //   const backdatedSubmissionsActivities = yield select(
    //     backdatedActivitiesRequest
    //   );
    //   let activities = yield call(
    //     ActivityManager.getCustomBackdatedActivities,
    //     backdatedSubmissionsActivities[0].data,
    //     query,
    //     filter
    //   );

    //   yield put(
    //     getBackdatedActivitiesSuccess(activities)
    //   );
    // } else {
    let activities = yield call(
      ActivityManager.getBackdatedActivities,
      type,
      query,
      filter,
    );

    yield put(getBackdatedActivitiesSuccess(activities));
    // }
  } catch (e) {
    console.log('getBackdatedActivitiesFailure', e);
    yield put(
      getBackdatedActivitiesFailure({
        error: null, // localization[getLocale()].SOMETHING_WENT_WRONG,
      }),
    );
  }
}

export function* getBackdatedSubmissionsAsync({ payload }) {
  yield put(getBackdatedSubmissions());

  try {
    if (navigator.onLine) {
      const res = yield call(DashboardService.getBackdatedSubmissions);
      const { back_dated_submissions_count } = res.data;
      // let customStats = BackdatedSubmissionsManager.getStats()[0].custom;
      // console.log(
      //   'customStattsssss',
      //   BackdatedSubmissionsManager.getStats(),
      //   BackdatedSubmissionsManager.getStats()[0],
      //   BackdatedSubmissionsManager.getStats()[0].custom,
      //   'sadasdqweqw',
      //   Array.from[customStats],
      // );
      // back_dated_submissions_count.custom = Array.from[customStats];
      BackdatedSubmissionsManager.saveStats(back_dated_submissions_count);
    }
    const stats = BackdatedSubmissionsManager.getStats();
    yield put(
      getBackdatedSubmissionsSuccess({
        stats: stats,
      }),
    );
  } catch (e) {
    console.log('getBackdatedSubmissionsFailure', e);
    yield put(
      getBackdatedSubmissionsFailure({
        error: e.message || null, //localization[getLocale()].SOMETHING_WENT_WRONG,
      }),
    );
  }
}

export function* getBackdatedCustomSubmissionsAsync({ payload }) {
  const { startDate, endDate } = payload;
  try {
    if (navigator.onLine) {
      const res = yield call(
        DashboardService.getBackdatedCustomSubmissions,
        startDate,
        endDate,
      );
      const { back_dated_submissions_count } = res.data;
      BackdatedSubmissionsManager.saveCustomStats(back_dated_submissions_count);

      const repsonse = yield call(
        DashboardService.getBackdatedCustomSubmissionsActivities,
        startDate,
        endDate,
      );

      const { feedlot_activites } = repsonse.data;
      let custom_activities_instances = {};

      if (feedlot_activites.length > 0) {
        feedlot_activites
          .filter((a) => a.instance_based)
          .forEach((b) => {
            custom_activities_instances[b.activity_id] = b.instances;
          });
        let activitiesAdded =
          ActivityManager.saveBackDatedActivities(feedlot_activites);
      }
      let customActivities = feedlot_activites.filter(
        (q) =>
          q.activityBackDatedSubmissions.custom.backdatedSubmissionCount !== 0,
      );
      yield put(
        getBackdatedCustomSubmissionsActivitiesSuccess({
          data: customActivities,
          custom_activities_instances,
        }),
      );
    } else {
      yield put(
        getBackdatedCustomSubmissionsFailure({
          error: localization[getLocale()].UNABLE_TO_SEND_REQUEST,
        }),
      );
    }

    const stats = BackdatedSubmissionsManager.getStats();
    yield put(
      getBackdatedCustomSubmissionsSuccess({
        stats: stats,
      }),
    );
  } catch (e) {
    console.log('getBackdatedCustomSubmissionsFailure', e);

    yield put(
      getBackdatedCustomSubmissionsFailure({
        error: e.message || null, //localization[getLocale()].SOMETHING_WENT_WRONG,
      }),
    );
  }
}

// export const delay = (ms) => new Promise(res => setTimeout(res, ms))

export function* getFeedlotRankAsync() {
  try {
    const feedlotId = getSelectedFeedlotId();
    if (navigator.onLine) {
      const res = yield call(DashboardService.getFeedlotRank);
      const { count, feedlot_ranks } = res.data;

      OptionsManager.saveFeedlotRank(count, feedlot_ranks);
    }

    const rank = OptionsManager.getFeedlotRank();
    yield put(
      getFeedlotRankSuccess({
        rank: {
          count: rank.count,
          position: rank.position,
          performance: rank.performance,
        },
        feedlotId,
      }),
    );

    //hide rank card after 1 min
    yield delay(60000);
    yield put(resetRankCard());
    // yield delay(6000);
    // yield put(hideRankCard());
    // yield call(setInterval, () => {
    //   put(hideRankCard());
    // }, 6000);
  } catch (e) {
    console.log('getFeedlotRankFailure', e);
    yield put(
      getFeedlotRankFailure({
        error: e.message || null, //localization[getLocale()].SOMETHING_WENT_WRONG,
      }),
    );
  }
}

export function* getFeedlotCustomPerformanceFilterAsync({ payload }) {
  let performance = OptionsManager.getCustomPerformanceFilter();

  let custom_activities_instances = {},
    feedlot_activites = [];

  feedlot_activites = performance.activities;

  feedlot_activites
    .filter((a) => a.instance_based)
    .forEach((b) => {
      custom_activities_instances[b.activity_id] = b.instances;
    });
  yield put(
    getFeedlotCustomPerformanceSuccess({
      performance: performance.performance,
      startDate: performance.startDate,
      endDate: performance.endDate,
      feedlot_activites: feedlot_activites,
      custom_activities_instances,
    }),
  );
}

function* getFeedlotCustomPerformanceAsync({ payload }) {
  const { startDate, endDate } = payload;
  try {
    if (navigator.onLine) {
      const res = yield call(
        DashboardService.getFeedlotCustomPerformance,
        startDate,
        endDate,
        payload.alert,
      );
      const { performance, feedlot_activites } = res.data;
      let custom_activities_instances = {};

      OptionsManager.saveCustomPerformanceFilter(
        performance,
        startDate,
        endDate,
        feedlot_activites,
      );

      feedlot_activites
        .filter((a) => a.instance_based)
        .forEach((b) => {
          custom_activities_instances[b.activity_id] = b.instances;
        });

      yield put(
        getFeedlotCustomPerformanceSuccess({
          performance,
          feedlot_activites: feedlot_activites,
          custom_activities_instances,
        }),
      );
    } else {
      yield put(
        getFeedlotCustomPerformanceFailure({
          error: localization[getLocale()].UNABLE_TO_SEND_REQUEST,
        }),
      );
    }
  } catch (e) {
    console.log('getFeedlotCustomPerformanceFailure', e);
    yield put(
      getFeedlotCustomPerformanceFailure({
        error: e.message || null, //localization[getLocale()].SOMETHING_WENT_WRONG,
      }),
    );
  }
}

export function* getMobileNotificationAsync({ payload }) {
  const dashboardStore = yield select(dashboardStoreRequest);

  let mobile_notifications_in_store = dashboardStore.mobile_notifications;
  let lastSynced = null; // lastsync time for syncing
  let notificationMetaData = MetaDataManager.getMetaDataByModule(
    MODULE_NAMES.NOTIFICATION_MODULE, // get activity metadata for last sync time
  );
  if (notificationMetaData) {
    lastSynced = notificationMetaData.lastSynced;
  }
  const { shouldSync, loading, max_id, firstCall } = payload;
  let isSilentNotification = false;
  let mobile_notifications = [];
  if (loading) {
    // if loading true then start activity loader
    yield put(getMobileNotification());
  }
  if (max_id) {
    // for load more handling
    yield put(getMobileNotificationLoadMoreAction());
  }
  try {
    if (navigator.onLine) {
      let noMoreRecords = false;
      const notificationRes = yield call(
        NotificationService.getMobileNotifications,
        getUserId(),
        shouldSync && lastSynced,
        max_id,
        firstCall,
      );
      const metaDataModel = metaDataDto({
        moduleName: MODULE_NAMES.NOTIFICATION_MODULE,
        lastSynced: notificationRes.time,
      });
      const data = notificationRes.data;
      let silentNotifications = [];
      data.mobile_notifications?.forEach((notification) => {
        if (
          notification?.event === NOTIFICATION_EVENTS.ACTIVITY_REMOVED ||
          notification?.event === NOTIFICATION_EVENTS.AUDIT_UNASSIGNED
        )
          silentNotifications.push(notification);
        else mobile_notifications.push(notification);
      });

      if (silentNotifications.length > 0) {
        console.log('HANDLING SILENT NOTIFS: ', silentNotifications);
        isSilentNotification = true;
        const moduleIDs = silentNotifications
          .filter(
            (notification) =>
              notification?.event === NOTIFICATION_EVENTS.ACTIVITY_REMOVED,
          )
          .map((notification) => notification.module_id);
        if (moduleIDs.length > 0) {
          const didRemove = yield call(
            ActivityManager.removeActivities,
            moduleIDs,
          );
          if (didRemove)
            yield call(getActivitiesAsync, {
              payload: { update: true },
            });
        }
      }

      noMoreRecords =
        shouldSync || mobile_notifications.length > 0 ? false : true;

      if (Object.keys(data).length === 0) {
        yield put(getMobileNotificationLoadMoreAction());
      }

      let notificationCount = data.count || 0;
      MetaDataManager.saveMetaData(metaDataModel);

      if (!max_id) {
        // only save first call notifications
        NotificationManager.saveMobileNotifications(mobile_notifications);
      }
      if (!max_id && !shouldSync) {
        // reset store state for notifications
        yield put(resetMobileNotification());
      }
      const notifications = NotificationManager.getMobileNotifications();
      if (shouldSync) {
        if (mobile_notifications.length) {
          yield put(
            setMobileNotificationCount({
              mobileNotificationCount: notificationCount,
            }),
          );
        }
        // if syncing or load more then concat new data with old and sort by time
        mobile_notifications =
          DashboardHelper.sortNotificationsByDate(notifications);
      }
      if (max_id) {
        // if syncing or load more then concat new data with old and sort by time
        mobile_notifications = DashboardHelper.sortNotificationsByDate(
          mobile_notifications_in_store.concat(mobile_notifications),
        );
      }

      yield put(
        getMobileNotificationSuccess({
          mobile_notifications,
          noMoreRecords,
        }),
      );
    } else {
      const notifications = NotificationManager.getMobileNotifications();
      yield put(
        getMobileNotificationSuccess({ mobile_notifications: notifications }),
      );
    }
  } catch (e) {
    console.log('error getting notifications', e);
    yield put(getMobileNotificationFailure({ error: e.message }));
  }
}

export function* getMobileUpdateAsync({ payload }) {
  const dashboardStore = yield select(dashboardStoreRequest);
  let mobile_updates_in_store = dashboardStore.mobile_updates;
  let lastSynced = null; // lastsync time for syncing
  let noMoreUpdateRecords = false;
  let updatesMetaData = MetaDataManager.getMetaDataByModule(
    MODULE_NAMES.UPDATES_MODULE, // get activity metadata for last sync time
  );
  if (updatesMetaData && updatesMetaData[0]) {
    lastSynced = updatesMetaData[0].lastSynced;
  }

  const shouldSync = payload.shouldSync;
  const loading = payload.loading;
  const max_id = payload.max_id;
  let mobile_notifications = [];

  if (loading) {
    // if loading true then start activity loader
    yield put(getMobileUpdate());
  }
  if (max_id) {
    // for load more handling
    yield put(getMobileUpdateLoadMoreAction());
  }
  try {
    if (navigator.onLine) {
      const updatesRes = yield call(
        NotificationService.getMobileUpdates,
        getUserId(),
        shouldSync && lastSynced,
        max_id,
      );
      const metaDataModel = metaDataDto({
        moduleName: MODULE_NAMES.UPDATES_MODULE,
        lastSynced: updatesRes.time,
      });
      const data = updatesRes.data;
      mobile_notifications = data.mobile_notifications || [];

      noMoreUpdateRecords =
        shouldSync || mobile_notifications.length > 0 ? false : true;

      if (Object.keys(data).length === 0) {
        yield put(getMobileUpdateLoadMoreAction());
      }

      MetaDataManager.saveMetaData(metaDataModel);
      if (!max_id) {
        // only save first call notifications
        NotificationManager.saveMobileNotificationsUpdates(
          mobile_notifications,
        );
      }
      if (!max_id && !shouldSync) {
        yield put(resetMobileUpdate());
      }
      const updates = NotificationManager.getNotificationsUpdates();
      if (shouldSync) {
        // if syncing or load more then concat new data with old and sort by time
        mobile_notifications = DashboardHelper.sortNotificationsByDate(updates);
      }
      if (max_id) {
        mobile_notifications = DashboardHelper.sortNotificationsByDate(
          mobile_updates_in_store.concat(mobile_notifications),
        );
      }
      yield put(
        getMobileUpdateSuccess({
          mobile_updates: mobile_notifications,
          noMoreUpdateRecords,
        }),
      );
    } else {
      const updates = NotificationManager.getNotificationsUpdates();
      yield put(getMobileUpdateSuccess({ mobile_updates: updates }));
    }
  } catch (e) {
    console.log('error getting notifications', e);
    yield put(getMobileUpdateFailure({ error: e.message }));
  }
}

export function* getMissedActivitiesAsync({ payload }) {
  try {
    const { loading } = payload;
    if (loading) {
      yield put(getMissedActivities());
    }
    if (navigator.onLine) {
      const res = yield call(DashboardService.getMissedActivities);
      const { mobile_notifications } = res.data;
      NotificationManager.clearMissedActivitiesNotifications();
      NotificationManager.saveMissedActivitiesNotifications(
        mobile_notifications,
      );
    }
    const notifications =
      NotificationManager.getMissedActivitiesNotifications();
    yield put(getMissedActivitiesSuccess(notifications));
  } catch (e) {
    console.log('MISSED ACTIVITIES FAILURE: ', e);
    yield put(
      getMissedActivitiesFailure({
        error: e.message || null, //localization[getLocale()].SOMETHING_WENT_WRONG,
      }),
    );
  }
}

export function* getPendingActionsCountAsync({ payload }) {
  try {
    const { loading } = payload;
    if (loading) {
      yield put(getPendingActionsCount());
    }
    const pendingActionsList =
      SubmissionsManager.getPendingActivitySubmissions();
    const count = pendingActionsList.length;
    let pendingActions = [];
    if (count > 0) {
      let data = [];
      let activitiesStore = yield select(activitiesStoreRequest);
      pendingActionsList.map((submission) => {
        // if (activitiesStore.activities.length > 0) {
        const activity = ActivitiesHelper.getActivityFromStore(
          activitiesStore.activities,
          submission.feedlot_activity_id
            ? submission.feedlot_activity_id
            : submission.id,
        );

        const matchedIndex = _.findIndex(data, [
          'activity_number',
          activity.activity_number,
        ]);

        if (matchedIndex > -1) {
          data[matchedIndex].count++;
        } else {
          const item = {
            title: activity.locale[getLanguageKey()]
              ? activity.locale[getLanguageKey()].title
              : '',
            activity_number: activity.activity_number,
            activity_type: activity.activity_type_key,
            count: 1,
            activity_type_key: activity.activity_type_key,
          };
          data.push(item);
        }
        // }
      });
      pendingActions = data;
    }
    yield put(
      getPendingActionsCountSuccess({
        count,
        pendingActions,
      }),
    );
  } catch (e) {
    yield put(
      getPendingActionsCountFailure({
        error: e.message || null, //localization[getLocale()].SOMETHING_WENT_WRONG,
      }),
    );
  }
}

export function* getSearchedPendingActionsAsync({ payload }) {
  try {
    const query = payload.toLowerCase();
    const dashboardStore = yield select(dashboardStoreRequest);
    let pendingActions = _.cloneDeep(dashboardStore.pendingActions);
    if (pendingActions.length > 0) {
      const data = [];
      pendingActions[0].data.map((action) => {
        const title = action.title.toLowerCase();
        const activityNumber = action.activity_number.toLowerCase();
        if (title.includes(query) || activityNumber.includes(query)) {
          data.push(action);
        }
      });
      pendingActions[0].data = data;
    }
    yield put(
      getSearchedPendingActionsSuccess({
        searchedPendingActions: pendingActions,
      }),
    );
  } catch (e) {
    yield put(getSearchedPendingActionsFailure());
  }
}

function* dashboardSaga() {
  yield takeEvery(ACTION_CONSTANTS.GET_WORKERS_REQUEST, getWorkersAsync);
  yield takeEvery(
    ACTION_CONSTANTS.GET_BACKDATED_ACTIVITIES_REQUEST,
    getBackdatedActivitiesAsync,
  );
  yield takeLatest(
    ACTION_CONSTANTS.POST_FEEDLOT_REPORT_REQUEST,
    postFeedlotReportAsync,
  );
  yield takeEvery(
    ACTION_CONSTANTS.GET_FEEDLOT_STATS_REQUEST,
    getFeedlotStatsAsync,
  );
  yield takeEvery(
    ACTION_CONSTANTS.GET_FEEDLOT_CUSTOM_PERFORMANCE_REQUEST,
    getFeedlotCustomPerformanceAsync,
  );
  yield takeEvery(
    ACTION_CONSTANTS.GET_FEEDLOT_RANK_REQUEST,
    getFeedlotRankAsync,
  );
  yield takeEvery(
    ACTION_CONSTANTS.GET_BACKDATED_CUSTOM_SUBMISSIONS_REQUEST,
    getBackdatedCustomSubmissionsAsync,
  );
  yield takeLatest(
    ACTION_CONSTANTS.GET_MOBILE_NOTIFICATION_REQUEST,
    getMobileNotificationAsync,
  );
  yield takeLatest(
    ACTION_CONSTANTS.GET_MOBILE_UPDATE_REQUEST,
    getMobileUpdateAsync,
  );
  yield takeEvery(
    ACTION_CONSTANTS.GET_MISSED_ACTIVITIES_REQUEST,
    getMissedActivitiesAsync,
  );
  yield takeEvery(
    ACTION_CONSTANTS.GET_PENDING_ACTIONS_COUNT_REQUEST,
    getPendingActionsCountAsync,
  );
  yield takeEvery(
    ACTION_CONSTANTS.GET_SEARCHED_PENDING_ACTIONS_REQUEST,
    getSearchedPendingActionsAsync,
  );
}

export default dashboardSaga;
