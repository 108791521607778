//modules
import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, FormGroup, Input, Row } from 'reactstrap';

import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
//componets
import Loader from '../../../components/Loader';
import { showErrorAlert } from '../../../components/Alerts';
//styles
import './Login.scss';
import logo from '../../../assets/img/brand/pb-logo.png';

//actions
import { forgotPasswordRequested } from '@progressivebeef/shared/src/store/actions/authentication';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      submitted: false,
    };
  }

  componentDidUpdate(prevProps) {
    let { success, error } = this.props;

    console.log(success, error, prevProps);

    if (success && success !== prevProps.success) {
      alert(success);
    }

    if (error !== prevProps.error) {
      if (typeof error === 'string') {
        alert(error);
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { email } = this.state;

    if (email && email != '') {
      this.setState({ submitted: true });
      this.props.forgotPassword(email);
    } else {
      showErrorAlert(localization[getLocale()].FORGET_PASSWORD_EMPTY_USERNAME);
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { email, submitted } = this.state | {};

    return (
      <div className="signInBody">
        <div>
          <Col className="loginSection">
            <Col md="12" className="loginInnerSection">
              <div className="logo">
                <img alt="Progresive Beef" src={logo} />
              </div>
              <h4 className="desc">
                {localization[getLocale()].FORGET_PASSWORD_TEXT}
              </h4>
              <form name="form" onSubmit={this.handleSubmit}>
                <div className="login-field-box forgot-pass">
                  <FormGroup>
                    <div className="field-group reset-pass-field">
                      <i className="fal fa-user" style={{ marginRight: 15 }} />
                      <Input
                        id="email"
                        type="text"
                        value={email}
                        name="email"
                        placeholder={localization[getLocale()].USERNAME}
                        onChange={this.handleChange}
                        autoComplete="username"
                      />
                    </div>
                  </FormGroup>
                </div>

                <Row>
                  <Col md="12" className="login-btn">
                    <Button
                      color="primary"
                      type="button"
                      block
                      onClick={this.handleSubmit}>
                      {localization[getLocale()].SUBMIT}{' '}
                      {this.props.isLoading && <Loader className="sm" />}
                    </Button>
                  </Col>
                </Row>
                <a
                  onClick={() => this.props.history.push(RouteConstants.LOGIN)}
                  className="forgotPass">
                  {localization[getLocale()].BACK_TO_LOGIN}
                </a>
              </form>
            </Col>
          </Col>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (username) =>
      dispatch(forgotPasswordRequested({ username })),
  };
};

const mapStateToProps = (state) => {
  let { isLoading, success, error } = state.authentication;
  return { isLoading, success, error };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
