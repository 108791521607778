import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import AppContainer from '../../../../components/AppContainer';
import PBIcon from '../../../../components/PBIcon';
import {
  showErrorAlert,
  showSuccessAlert,
} from '../../../../components/Alerts';

import {
  getUsersListRequest,
  searchUsersListInStore,
  clearAssignLogSearch,
  updateSearchUsersListItem,
} from '@progressivebeef/shared/src/store/actions/user';
import AssignLogList from './List';
import {
  assignActivityRequest,
  setCurrentActivity,
} from '@progressivebeef/shared/src/store/actions/activities';
import {
  showLoader,
  hideLoader,
} from '@progressivebeef/shared/src/store/actions/view';

import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import { Spinner } from 'reactstrap';
import { globalHelper } from '@progressivebeef/shared/src/helpers';

import { trackEvent } from '@progressivebeef/shared/src/services/reactMixpanelService';
import {
  Events,
  Properties,
} from '@progressivebeef/shared/src/constants/MixpanelConstants';
import languageService from '@progressivebeef/shared/src/services/languageService';

class AssignLog extends PureComponent {
  constructor(props) {
    super(props);
    const { currentActivity } = this.props.location.state;
    this.state = {
      currentActivity,
    };
  }

  componentDidMount() {
    const { getUsers, setCurrentActivityRequest } = this.props;
    const { currentActivity, currentInstance } = this.props.location.state;

    // setCurrentActivityRequest(currentActivity);
    getUsers({
      update: false,
      instance: currentInstance ?? null,
    });
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (
  //     this.props.searchableAssignLogUsers.filter((q) => q.selected == true)
  //       .length >
  //     nextProps.searchableAssignLogUsers.filter((q) => q.selected == true)
  //       .length
  //   ) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  componentDidUpdate(prevProps) {
    let {
      isNonActivityLoading,
      activityAssignSuccess,
      error,
      showLoaderView,
      hideLoaderView,
      focusedActivity,
    } = this.props;

    if (isNonActivityLoading) {
      showLoaderView();
    } else {
      if (isNonActivityLoading !== prevProps.isNonActivityLoading) {
        hideLoaderView();
      }
    }

    if (
      activityAssignSuccess &&
      activityAssignSuccess !== prevProps.activityAssignSuccess
    ) {
      if (this.props.history.location && this.props.history.location.state) {
        const state = {
          ...this.props.history.location.state,
          focusedActivity,
          currentActivity: focusedActivity,
        };

        delete state.from;
        this.props.history.replace({
          ...this.props.history.location,
          state,
        });
      }

      showSuccessAlert('', [
        {
          text: localization[getLocale()].OK,
          onPress: () => {
            this.props.clearAssignLogSearch();
            this.props.history.goBack();
          },
        },
      ]);
    }
    if (error && error !== prevProps.error) {
      if (typeof error === 'string') {
        showErrorAlert(error);
      } else {
        showErrorAlert(localization[getLocale()].SOMETHING_WENT_WRONG);
      }
    }
  }

  onSubmit = () => {
    const { searchableAssignLogUsers } = this.props;
    const { currentInstance } = this.props.location.state;
    const { currentActivity } = this.state;
    const { feedlot_activity_id } = currentActivity;
    let payload = {
      feedlot_activity_assignment: {
        feedlot_activity_id,
        user_ids: [],
      },
    };
    if (currentInstance) {
      payload.feedlot_activity_assignment.feedlot_activity_instance_id =
        currentInstance.feedlot_activity_instance_id;
    }
    payload.feedlot_activity_assignment.user_ids = searchableAssignLogUsers
      .filter((user) => user.selected === true)
      .map((user) => user.user_id);

    try {
      let properties = {};
      properties[Properties.NUMBER_OF_ASSIGNED_USERS] =
        payload.feedlot_activity_assignment.user_ids.length;
      properties[Properties.ACTIVITY_NAME] =
        currentActivity.locale[languageService()].title;
      properties[Properties.ACTIVITY_NUMBER] = currentActivity.activity_number;
      trackEvent(Events.ACTIVITY_ASSIGNED, properties);
    } catch (e) {}

    this.props.assignUsers(payload);
  };

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          name={'chevron-left'}
          onPress={() => {
            this.props.clearAssignLogSearch();
            this.props.history.goBack();
          }}
        />
      </div>
    );
  };

  renderRightHeader = () => {
    return (
      <div className="head-icon right-align">
        <PBIcon name={'check'} onPress={this.onSubmit} />
      </div>
    );
  };

  render() {
    const { history, isLoading, searchableAssignLogUsers, query } = this.props;

    return (
      <>
        <AppContainer
          title={localization[getLocale()].ASSIGN_LOG}
          onSearchPress={(queryString) => {
            this.props.searchUsers(queryString);
          }}
          history={history}
          // loading={isLoading || isNonActivityLoading}
          searchText={query}
          iconHeader
          IconLeftHeader={this.renderLeftHeader}
          IconRightHeader={this.renderRightHeader}>
          {globalHelper.renderIf(isLoading)(
            <div className="bottom-infinite-loader">
              <Spinner color="primary" />
            </div>,
          )}
          <AssignLogList
            users={searchableAssignLogUsers}
            history={history}
            updateSearchUsersListItem={(user) =>
              this.props.updateSearchUsersListItem({
                list: searchableAssignLogUsers,
                user,
              })
            }
          />
        </AppContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (payload) => dispatch(getUsersListRequest(payload)),
    searchUsers: (payload) => dispatch(searchUsersListInStore(payload)),
    assignUsers: (payload) => dispatch(assignActivityRequest(payload)),
    showLoaderView: (payload) => dispatch(showLoader(payload)),
    hideLoaderView: (payload) => dispatch(hideLoader(payload)),
    clearAssignLogSearch: (payload) => dispatch(clearAssignLogSearch(payload)),
    setCurrentActivityRequest: (payload) =>
      dispatch(setCurrentActivity(payload)),
    updateSearchUsersListItem: (payload) =>
      dispatch(updateSearchUsersListItem(payload)),
  };
};

const mapStateToProps = (state) => {
  const { userLoading, searchableAssignLogUsers, query } = state.user;
  const {
    isNonActivityLoading,
    activityAssignSuccess,
    error,
    focusedActivity,
  } = state.activities;
  return {
    isLoading: userLoading,
    isNonActivityLoading,
    searchableAssignLogUsers,
    query,
    activityAssignSuccess,
    error,
    focusedActivity,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignLog);
