import { Activity_Frequency_Calendar } from '../constants/AppConstants';
import { getLocale } from '../services/storageService/GlobalData';
import localization from '../localization/i18n';

class LocaleHelper {
    SCHEDULE_PICKER_ARRAY = () => {
        return [
            { value: Activity_Frequency_Calendar.YEARLY, label: 'Yearly' },
            { value: Activity_Frequency_Calendar.QUARTERLY, label: 'Quarterly' },
            { value: Activity_Frequency_Calendar.MONTHLY, label: 'Monthly' },
            { value: Activity_Frequency_Calendar.WEEKLY, label: 'Weekly' },
            { value: Activity_Frequency_Calendar.DAILY, label: 'Daily' },
        ];
    };

    SCHEDULE_DAY_PICKER_ARRAY = () => {
        return [
            { value: '0', label: localization[getLocale()].DAY_OF_THE_MONTH },
            { value: 1, label: localization[getLocale()].ONE_WEEK },
            { value: 2, label: localization[getLocale()].TWO_WEEKS },
            { value: 3, label: localization[getLocale()].THREE_WEEKS },
        ];
    };

    WEEK_DAYS = () => {
        return [
            { value: 1, label: localization[getLocale()].SUNDAY, selected: false },
            { value: 2, label: localization[getLocale()].MONDAY, selected: false },
            { value: 3, label: localization[getLocale()].TUESDAY, selected: false },
            { value: 4, label: localization[getLocale()].WEDNESDAY, selected: false },
            { value: 5, label: localization[getLocale()].THURSDAY, selected: false },
            { value: 6, label: localization[getLocale()].FRIDAY, selected: false },
            { value: 7, label: localization[getLocale()].SATURDAY, selected: false },
        ];
    };

    USER_STATUS_PICKER_VALUES = () => {
        return [
            { value: '1', label: localization[getLocale()].ACTIVE, selected: false },
            {
                value: '0',
                label: localization[getLocale()].INACTIVE,
                selected: false,
            },
        ];
    };

    USER_ROLES_PICKER_VALUES = () => {
        return [
            {
                value: 4,
                label: localization[getLocale()].SUPERVISOR,
                selected: false,
            },
            { value: 5, label: localization[getLocale()].WORKER, selected: false },
        ];
    };

    BACKDATED_PICKER_VALUES = () => {
        return [
            {
                value: 'yesterday',
                label: localization[getLocale()]._DAILY,
                selected: false,
            },
            {
                value: 'thisWeek',
                label: localization[getLocale()].THIS_WEEK,
                selected: true,
            },
            {
                value: 'lastWeek',
                label: localization[getLocale()]._WEEKLY,
                selected: false,
            },
            {
                value: 'thisMonth',
                label: localization[getLocale()].THIS_MONTH,
                selected: false,
            },
            {
                value: 'lastMonth',
                label: localization[getLocale()]._MONTHLY,
                selected: false,
            },
            {
                value: 'thisYear',
                label: localization[getLocale()].THIS_YEAR,
                selected: false,
            },
            {
                value: 'custom',
                label: localization[getLocale()].CUSTOM,
                selected: false,
            },
        ];
    };

    TRAINING_FREQUENCY = () => {
        return [
            { value: 1, label: localization[getLocale()].ONCE },
            { value: 2, label: localization[getLocale()].SIX_MONTHS },
            { value: 3, label: localization[getLocale()].ONE_YEAR },
        ];
    };

    NOTIFICATION_LEVEL = () => {
        return [
            {
                label: localization[getLocale()].FEEDLOT_MANAGER,
                value: '3',
            },
            {
                label: localization[getLocale()].SUPERVISOR,
                value: '4',
            },
            {
                label: localization[getLocale()].BOTH,
                value: '0',
            },
        ];
    };

    CLEAR_STORAGE_OPTIONS = () => {
        return [
            { value: '1', label: localization[getLocale()].CLEAR_STORAGE_2_WEEKS, selected: false },
            { value: '2', label: localization[getLocale()].CLEAR_STORAGE_ALL, selected: false },
        ];
    }
  
}

export default new LocaleHelper();
