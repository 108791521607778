import React from 'react';
import PropTypes from 'prop-types';
import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from 'reactstrap';

class InputDialog extends React.Component {
  render() {
    const {
      dialogVisible,
      title,
      description,
      placeholder,
      successBtnText,
      onSuccessPress,
      onCancelPress,
      onInputChange,
      submissionExists,
    } = this.props;
    return (
      <Modal isOpen={dialogVisible} toggle={onCancelPress}>
        <ModalHeader toggle={onCancelPress}>{title}</ModalHeader>
        <ModalBody>
          {description}
          {/* show when null or true */}
          {submissionExists != false && (
            <Input
              placeholder={placeholder || 'Enter text'}
              onChange={(e) => onInputChange(`${e.target.value}`)}
            ></Input>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="btn-box">
            <Button color="primary" onClick={onCancelPress}>
              {submissionExists == false
                ? localization[getLocale()].NO
                : localization[getLocale()].CANCEL}
            </Button>
            <Button color="primary" onClick={onSuccessPress}>
              {successBtnText}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

InputDialog.propTypes = {
  dialogVisible: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  placeholder: PropTypes.string,
  successBtnText: PropTypes.string,
  onInputChange: PropTypes.func,
  onSuccessPress: PropTypes.func,
  onCancelPress: PropTypes.func,
};

export default InputDialog;
