export default {
  GET_PROGRAM_MANUAL_REQUEST: 'GET_PROGRAM_MANUAL_REQUEST',
  GET_PROGRAM_MANUAL: 'GET_PROGRAM_MANUAL',
  GET_PROGRAM_MANUAL_SUCCESS: 'GET_PROGRAM_MANUAL_SUCCESS',
  GET_PROGRAM_MANUAL_FAILURE: 'GET_PROGRAM_MANUAL_FAILURE',

  SEARCH_PROGRAM_MANUAL: 'SEARCH_PROGRAM_MANUAL',
  SET_CURRENT_PROGRAM_MANUAL: 'SET_CURRENT_PROGRAM_MANUAL',
  RESET_SOP_PROPS: 'RESET_SOP_PROPS',

  GET_PROGRAM_MANUAL_TAG: 'GET_PROGRAM_MANUAL_TAG',
  GET_PROGRAM_MANUAL_TAG_REQUEST: 'GET_PROGRAM_MANUAL_TAG_REQUEST',

  GET_PROGRAM_MANUAL_BY_ID_REQUEST: 'GET_PROGRAM_MANUAL_BY_ID_REQUEST',
  GET_PROGRAM_MANUAL_BY_ID: 'GET_PROGRAM_MANUAL_BY_ID',
  GET_PROGRAM_MANUAL_BY_ID_SUCCESS: 'GET_PROGRAM_MANUAL_BY_ID_SUCCESS',
  GET_PROGRAM_MANUAL_BY_ID_FAILURE: 'GET_PROGRAM_MANUAL_BY_ID_FAILURE',

  GET_PROGRAM_MANUAL_ACTIVITIES_BY_ID_REQUEST:
    'GET_PROGRAM_MANUAL_ACTIVITIES_BY_ID_REQUEST',
  GET_PROGRAM_MANUAL_ACTIVITIES_BY_ID: 'GET_PROGRAM_MANUAL_ACTIVITIES_BY_ID',
  GET_PROGRAM_MANUAL_ACTIVITIES_BY_ID_SUCCESS:
    'GET_PROGRAM_MANUAL_ACTIVITIES_BY_ID_SUCCESS',
  GET_PROGRAM_MANUAL_ACTIVITIES_BY_ID_FAILURE:
    'GET_PROGRAM_MANUAL_ACTIVITIES_BY_ID_FAILURE',

  SET_VISIBLE_TO_USERS: 'SET_VISIBLE_TO_USERS',
  SET_SELECTED_TAGS: 'SET_SELECTED_TAGS',

  DELETE_PROGRAM_MANUAL_URL_REQUEST: 'DELETE_PROGRAM_MANUAL_URL_REQUEST',
  DELETE_PROGRAM_MANUAL_URL: 'DELETE_PROGRAM_MANUAL_URL',
  DELETE_PROGRAM_MANUAL_URL_SUCCESS: 'DELETE_PROGRAM_MANUAL_URL_SUCCESS',
  DELETE_PROGRAM_MANUAL_URL_FAILURE: 'DELETE_PROGRAM_MANUAL_URL_FAILURE',

  UPDATE_PROGRAM_MANUAL_URL_REQUEST: 'UPDATE_PROGRAM_MANUAL_URL_REQUEST',
  UPDATE_PROGRAM_MANUAL_URL: 'UPDATE_PROGRAM_MANUAL_URL',
  UPDATE_PROGRAM_MANUAL_URL_SUCCESS: 'UPDATE_PROGRAM_MANUAL_URL_SUCCESS',
  UPDATE_PROGRAM_MANUAL_URL_FAILURE: 'UPDATE_PROGRAM_MANUAL_URL_FAILURE',

  CREATE_TRAINING_REQUEST: 'CREATE_TRAINING_REQUEST',
  CREATE_TRAINING: 'CREATE_TRAINING',
  CREATE_TRAINING_SUCCESS: 'CREATE_TRAINING_SUCCESS',
  CREATE_TRAINING_FAILURE: 'CREATE_TRAINING_FAILURE',

  UPDATE_TRAINING_REQUEST: 'UPDATE_TRAINING_REQUEST',
  UPDATE_TRAINING: 'UPDATE_TRAINING',
  UPDATE_TRAINING_SUCCESS: 'UPDATE_TRAINING_SUCCESS',
  UPDATE_TRAINING_FAILURE: 'UPDATE_TRAINING_FAILURE',

  RESET_CREATE_TRAINING: 'RESET_CREATE_TRAINING',
  RESET_FILTER_TRAINING: 'RESET_FILTER_TRAINING',
  RESET_ERROR_TRAINING: 'RESET_ERROR_TRAINING',
  RESET_PROGRAM_MANUAL_DETAIL: 'RESET_PROGRAM_MANUAL_DETAIL',

  MARK_VIEWED_REQUEST: 'MARK_VIEWED_REQUEST',
  MARK_VIEWED: 'MARK_VIEWED',
  MARK_VIEWED_SUCCESS: 'MARK_VIEWED_SUCCESS',
  MARK_VIEWED_FAILURE: 'MARK_VIEWED_FAILURE',

  GET_DEPRECATED_PROGRAM_MANUAL_REQUEST:
    'GET_DEPRECATED_PROGRAM_MANUAL_REQUEST',
  GET_DEPRECATED_PROGRAM_MANUAL: 'GET_DEPRECATED_PROGRAM_MANUAL',
  GET_DEPRECATED_PROGRAM_MANUAL_SUCCESS:
    'GET_DEPRECATED_PROGRAM_MANUAL_SUCCESS',
  GET_DEPRECATED_PROGRAM_MANUAL_FAILURE:
    'GET_DEPRECATED_PROGRAM_MANUAL_FAILURE',

  GET_MISSED_PROGRAM_MANUAL_REQUEST: 'GET_MISSED_PROGRAM_MANUAL_REQUEST',
  GET_MISSED_PROGRAM_MANUAL: 'GET_MISSED_PROGRAM_MANUAL',
  GET_MISSED_PROGRAM_MANUAL_SUCCESS: 'GET_MISSED_PROGRAM_MANUAL_SUCCESS',
  GET_MISSED_PROGRAM_MANUAL_FAILURE: 'GET_MISSED_PROGRAM_MANUAL_FAILURE',

  MOVE_PROGRAM_MANUALS_TO_ACTIVE_REQUEST:
    'MOVE_PROGRAM_MANUALS_TO_ACTIVE_REQUEST',
  MOVE_PROGRAM_MANUALS_TO_ACTIVE: 'MOVE_PROGRAM_MANUALS_TO_ACTIVE',
  MOVE_PROGRAM_MANUALS_TO_ACTIVE_SUCCESS:
    'MOVE_PROGRAM_MANUALS_TO_ACTIVE_SUCCESS',
  MOVE_PROGRAM_MANUALS_TO_ACTIVE_FAILURE:
    'MOVE_PROGRAM_MANUALS_TO_ACTIVE_FAILURE',

  MOVE_PROGRAM_MANUALS_TO_BIN_REQUEST: 'MOVE_PROGRAM_MANUALS_TO_BIN_REQUEST',
  MOVE_PROGRAM_MANUALS_TO_BIN: 'MOVE_PROGRAM_MANUALS_TO_BIN',
  MOVE_PROGRAM_MANUALS_TO_BIN_SUCCESS: 'MOVE_PROGRAM_MANUALS_TO_BIN_SUCCESS',
  MOVE_PROGRAM_MANUALS_TO_BIN_FAILURE: 'MOVE_PROGRAM_MANUALS_TO_BIN_FAILURE',

  GET_DOCUMENTS: 'GET_DOCUMENTS',
  GET_DOCUMENTS_REQUEST: 'GET_DOCUMENTS_REQUEST',
  GET_DOCUMENTS_SUCCESS: 'GET_DOCUMENTS_SUCCESS',
  GET_DOCUMENTS_FAILURE: 'GET_DOCUMENTS_FAILURE',

  EMAIL_REPORT_REQUEST: 'EMAIL_REPORT_REQUEST',
  EMAIL_REPORT: 'EMAIL_REPORT',
  EMAIL_REPORT_SUCCESS: 'EMAIL_REPORT_SUCCESS',
  EMAIL_REPORT_FAILURE: 'EMAIL_REPORT_FAILURE',

  SET_MISSED_TRAINING: 'SET_MISSED_TRAINING',
  FILTER_DOCUMENT_USERS: 'FILTER_DOCUMENT_USERS',
};
