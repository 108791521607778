import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { LOCAL_STORAGE_KEYS } from '@progressivebeef/shared/src/constants/AppConstants';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const getInitialRouteName = () => {
    let redirectToRoute = true;

    if (!localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)) {
      redirectToRoute = '/' + RouteConstants.LOGIN;
    } else {
      if (localStorage.getItem(LOCAL_STORAGE_KEYS.PIN_CODE) == '') {
        if (!rest.path.includes(RouteConstants.GENERATE_PIN))
          redirectToRoute = '/' + RouteConstants.GENERATE_PIN;
      } else {
        if (!localStorage.getItem(LOCAL_STORAGE_KEYS.SELECTED_FEEDYARD)) {
          if (!rest.path.includes(RouteConstants.FEEDYARD_LIST))
            redirectToRoute = '/' + RouteConstants.FEEDYARD_LIST;
        } else if (
          localStorage.getItem(LOCAL_STORAGE_KEYS.IS_LOCKED) == 'true'
        ) {
          if (
            !rest.path.includes(RouteConstants.LOCK_SCREEN) &&
            !rest.path.includes(RouteConstants.LOGOUT)
          )
            redirectToRoute = '/' + RouteConstants.LOCK_SCREEN;
        }
      }
    }
    return redirectToRoute;
  };

  let route = getInitialRouteName();

  return (
    <Route
      {...rest}
      render={(props) => {
        return route == true ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: route,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};
