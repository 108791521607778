import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Input } from 'reactstrap';

export default class DialogComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogVisible: false,
    };
  }

  render() {
    const {
      title,
      description,
      okText,
      cancelText,
      showDialog,
      handleSubmit,
      handleCancel,
      placeholder
    } = this.props;
    return (
      <Modal isOpen={showDialog} toggle={handleCancel} className={'modal-dialog'}>
        <ModalHeader toggle={handleCancel}>{title}</ModalHeader>
        <ModalBody>
          {description || ''}
          <Input placeholder={placeholder || 'Enter text'} onChange={(e) => this.setState({ value: e.target.value })}>
          </Input>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleCancel}>
            {cancelText || 'Cancel'}
          </Button>
          <Button color="primary" onClick={() => handleSubmit(this.state.value)}>
            {okText || 'Ok'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
