import { setLocalstorageItem } from '.';
import { LOCAL_STORAGE_KEYS } from '../../constants/AppConstants';

class AppTrainingVideoHTMLManager {
  saveAppTrainingVideoHTML(appTrainingVideoHTML) {
    try {
      setLocalstorageItem(
        LOCAL_STORAGE_KEYS.APP_TRAINING_VIDEO_HTML,
        JSON.stringify(appTrainingVideoHTML),
      );
    } catch (e) {
      console.log('saveAppTrainingVideoHtml failed', e);
      return null;
    }
  }

  getAppTrainingVideoHTML() {
    try {
      let appTrainingVideoHTML =
        JSON.parse(
          localStorage.getItem(LOCAL_STORAGE_KEYS.APP_TRAINING_VIDEO_HTML),
        ) || null;

      return appTrainingVideoHTML;
    } catch (e) {
      console.log('getAppTrainingVideoHtml fail', e);
    }
  }
}

export default new AppTrainingVideoHTMLManager();
