const feedlotPerformance = (data) => ({
  cumulative: {
    performance: data.cumulative.performance,
  },
  daily: {
    performance: data.daily.performance,
  },
  monthly: {
    performance: data.monthly.performance,
  },
  performance: data.performance,
  thisMonth: {
    performance: data.thisMonth.performance,
  },
  thisWeek: {
    performance: data.thisWeek.performance,
  },
  weekly: {
    performance: data.weekly.performance,
  },
});

export default feedlotPerformance;
