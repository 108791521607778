import ACTION_CONSTANTS from '../../constants/actionConstants';

export const initialState = {
  isSyncing: false,
  isAutoSyncing: false,
  isLoading: false,
  success: false,
  error: false,
  lastSynced: null,
  failure: false,
  syncCallFailed: false,
  isSyncingManually: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_CONSTANTS.SYNC_DATA_BLOCKING_REQUEST: {
      return {
        ...state,
        isSyncing: true,
        success: false,
        error: false,
        failure: false,
        syncCallFailed: false,
      };
    }
    case ACTION_CONSTANTS.SYNC_DATA_BLOCKING_SUCCESS: {
      return {
        ...state,
        isSyncing: false,
        lastSynced: action.payload.lastSynced,
        success: true,
        failure: state.syncCallFailed || false,
      };
    }
    case ACTION_CONSTANTS.SYNC_DATA_BLOCKING_FAILURE: {
      return {
        ...state,
        isSyncing: false,
        error: action.payload.error,
        lastSynced: new Date(),
        failure: true,
      };
    }

    case ACTION_CONSTANTS.SYNC_DATA_NON_BLOCKING_REQUEST: {
      return {
        ...state,
        isAutoSyncing: action.payload ? action.payload.isAutoSync : false,
        isLoading: true,
        success: false,
        error: false,
        syncPending: false,
        failure: false,
        syncCallFailed: false,
      };
    }
    case ACTION_CONSTANTS.SYNC_DATA_NON_BLOCKING_SUCCESS: {
      return {
        ...state,
        isAutoSyncing: false,
        isLoading: false,
        lastSynced: action.payload.lastSynced,
        success: true,
        syncPending: false,
        failure: state.syncCallFailed || false,
      };
    }
    case ACTION_CONSTANTS.SYNC_DATA_NON_BLOCKING_FAILURE: {
      return {
        ...state,
        isAutoSyncing: false,
        isLoading: false,
        error: action.payload.error,
        syncPending: action.payload.syncPending || false,
        lastSynced: new Date(),
        failure: true,
      };
    }

    //#region SYNC_ACTIVITY_DATA
    case ACTION_CONSTANTS.SYNC_ACTIVITY_DATA_REQUEST: {
      return {
        ...state,
        success: false,
        error: false,
      };
    }
    case ACTION_CONSTANTS.SYNC_ACTIVITY_DATA_SUCCESS: {
      return {
        ...state,
        success: true,
      };
    }
    case ACTION_CONSTANTS.SYNC_ACTIVITY_DATA_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    //#endregion

    case ACTION_CONSTANTS.SYNC_DATA_MANUALLY_REQUEST: {
      return {
        ...state,
        isSyncingManually: true,
        success: false,
        error: false,
        failure: false,
        syncCallFailed: false,
      };
    }
    case ACTION_CONSTANTS.SYNC_DATA_MANUALLY_SUCCESS: {
      return {
        ...state,
        isSyncingManually: false,
        lastSynced: action.payload.lastSynced,
        success: true,
        failure: state.syncCallFailed || false,
      };
    }
    case ACTION_CONSTANTS.SYNC_DATA_MANUALLY_FAILURE: {
      return {
        ...state,
        isSyncingManually: false,
        lastSynced: new Date(),
        error: action.payload.error,
        failure: true,
      };
    }

    // region POST_PENDING_DATA
    case ACTION_CONSTANTS.POST_PENDING_DATA_REQUEST: {
      return {
        ...state,
        success: false,
        error: false,
      };
    }
    case ACTION_CONSTANTS.POST_PENDING_DATA_SUCCESS: {
      return {
        ...state,
        success: true,
      };
    }
    case ACTION_CONSTANTS.POST_PENDING_DATA_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    // end region

    case ACTION_CONSTANTS.GET_ACTIVITIES_FAILURE: {
      // console.log('GET_ACTIVITIES_FAILURE daashboard');
      return {
        ...state,
        failure: true,
        syncCallFailed: true,
      };
    }

    case ACTION_CONSTANTS.GET_VIDEOS_FAILURE: {
      // console.log('GET_VIDEOS_FAILURE daashboard');
      return {
        ...state,
        failure: true,
        syncCallFailed: true,
      };
    }

    case ACTION_CONSTANTS.GET_BACKDATED_SUBMISSIONS_FAILURE: {
      // console.log('GET_BACKDATED_SUBMISSIONS_FAILURE daashboard');
      return {
        ...state,
        failure: true,
      };
    }

    case ACTION_CONSTANTS.GET_PROGRAM_MANUAL_FAILURE: {
      // console.log('GET_PROGRAM_MANUAL_FAILURE daashboard');
      return {
        ...state,
        failure: true,
        syncCallFailed: true,
      };
    }

    case ACTION_CONSTANTS.GET_MOBILE_NOTIFICATION_FAILURE: {
      // console.log('GET_MOBILE_NOTIFICATION_FAILURE daashboard');
      return {
        ...state,
        failure: true,
        syncCallFailed: true,
      };
    }

    case ACTION_CONSTANTS.GET_MOBILE_UPDATE_FAILURE: {
      // console.log('GET_MOBILE_UPDATE_FAILURE daashboard');
      return {
        ...state,
        failure: true,
        syncCallFailed: true,
      };
    }

    case ACTION_CONSTANTS.GET_FEEDLOT_STATS_FAILURE: {
      // console.log('GET_FEEDLOT_STATS_FAILURE daashboard');
      return {
        ...state,
        failure: true,
        syncCallFailed: true,
      };
    }

    case ACTION_CONSTANTS.GET_MISSED_ACTIVITIES_FAILURE: {
      // console.log('GET_MISSED_ACTIVITIES_FAILURE daashboard');
      return {
        ...state,
        failure: true,
        syncCallFailed: true,
      };
    }

    case ACTION_CONSTANTS.GET_FEEDLOT_RANK_FAILURE: {
      // console.log('GET_FEEDLOT_RANK_FAILURE daashboard');
      return {
        ...state,
        failure: true,
        syncCallFailed: true,
      };
    }

    case ACTION_CONSTANTS.GET_ASSIGN_LOGS_USERS_LIST_FAILURE: {
      // console.log('GET_ASSIGN_LOGS_USERS_LIST_FAILURE daashboard');
      return {
        ...state,
        failure: true,
        syncCallFailed: true,
      };
    }

    case ACTION_CONSTANTS.GET_USERS_FAILURE: {
      // console.log('GET_USERS_FAILURE daashboard');
      return {
        ...state,
        failure: true,
        syncCallFailed: true,
      };
    }

    case ACTION_CONSTANTS.GET_WORKERS_FAILURE: {
      // console.log('GET_WORKERS_FAILURE daashboard');
      return {
        ...state,
        failure: true,
        syncCallFailed: true,
      };
    }

    case ACTION_CONSTANTS.GET_AUDITS_FAILURE: {
      // console.log('GET_AUDITS_FAILURE daashboard');
      return {
        ...state,
        failure: true,
        syncCallFailed: true,
      };
    }

    case ACTION_CONSTANTS.GET_AUDITS_FAILURE: {
      // console.log('GET_AUDITS_FAILURE daashboard');
      return {
        ...state,
        failure: true,
        syncCallFailed: true,
      };
    }

    default:
      return state;
  }
};
