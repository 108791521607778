import { LOCAL_STORAGE_KEYS } from '../../constants/AppConstants';
import { getLocalstorageItem, setLocalstorageItem } from '.';

class WorkerManager {
  //saveActivities
  saveWorkers(workersToSave) {
    try {
      setLocalstorageItem(
        LOCAL_STORAGE_KEYS.WORKER,
        JSON.stringify(workersToSave)
      );
      return true;
    } catch (e) {
      console.log('saveWorkersfailed', e);
      return false;
    }
  }

  getWorkers() {
    let users =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.WORKER)) || [];

    return users;
  }
}

export default new WorkerManager();
