import React, { PureComponent } from 'react';

class FeedyardCard extends PureComponent {
  render() {
    let { item, onPress } = this.props;

    return (
      <div className="list" style={{ cursor: 'pointer' }} onClick={() => onPress(item)}>
        <div className="name">{item.name}</div>
        <div className="address">{item.address}</div>
        {/* <div className="icon">
          <i className="fal fa-chevron-right"></i>
        </div> */}
      </div>
    );
  }
}

export default FeedyardCard;
