import React from 'react';
import './style.scss';

// constants
import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

// styles
import AssetConstants from '@progressivebeef/shared/src/constants/AssetConstants';

const MissedCard = () => {
  return (
    <div className="submision-submmit-box">
      <div className="left-col">
        <div className="main-title">
          {<div>{`${localization[getLocale()].NOT_SUBMITTED}`}</div>}
        </div>
      </div>
      <div className="right-col">
        <div className="icon-box">
          <img alt="description" src={AssetConstants.missed} />
          <div className="label">{localization[getLocale()].MISSED}</div>
        </div>
      </div>
    </div>
  );
};

MissedCard.propTypes = {};

export default MissedCard;
