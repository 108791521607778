import React, { Component } from 'react';
import moment from 'moment';
import AppContainer from '../../../../components/AppContainer';
import PBIcon from '../../../../components/PBIcon';
import ActivityDetail from '../../../../components/ActivityDetail';
import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import { DATE_FORMATS } from '@progressivebeef/shared/src/constants/AppConstants';

import './style.scss';

class RemovedInstance extends Component {
  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          name={'chevron-left'}
          onPress={() => {
            this.props.history.goBack();
          }}
        />
      </div>
    );
  };

  render() {
    const {
      instance,
      instanceName,
      currentActivity,
    } = this.props.location.state;
    const userName = instance.removed_by
      ? `${instance.removed_by.profile.first_name} ${instance.removed_by.profile.last_name}`
      : '';
    const { history } = this.props;
    
    return (
      <AppContainer
        noncollapsible
        title={instanceName}
        iconHeader
        history={history}
        IconLeftHeader={this.renderLeftHeader}
      >
        <div>
          <ActivityDetail activity={currentActivity} />
        </div>
        <div className="instance-remove-box">
          <div className="head">
            <div className="icon">
              <PBIcon name="info-circle" />
            </div>
            <div className="label">{' ' + instance.instance_value}</div>
          </div>
          <div className="highlight-msg">{instance.reason}</div>
          <div className="deleted-info-box">
            <div className="box">
              {userName && <div>{localization[getLocale()].INSTANCE_DELETED_BY}</div>}
              {instance.removed_date_time && <div>{localization[getLocale()].DATE}</div>}
            </div>
            <div className="box">
              <div className="user-style">{userName}</div>
              <div>
                {instance.removed_date_time && 
                  moment(instance.removed_date_time).format(DATE_FORMATS.MM_DD_YY)}
                {' | '}
                {instance.removed_date_time && 
                  moment(instance.removed_date_time).format(DATE_FORMATS.H_MM_A)}
              </div>
            </div>
          </div>
        </div>
      </AppContainer>
    );
  }
}

export default RemovedInstance;
