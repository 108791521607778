import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment, { locale } from 'moment';
import { Line } from 'react-chartjs-2';
import './style.scss';

import PickerComponent from '../../../../../components/Picker';
import DatesModal from './dateModal';
import { showAlertMsg, showErrorAlert } from '../../../../../components/Alerts';

import DashboardHelper from '@progressivebeef/shared/src/helpers/DashboardHelper';

import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import localization from '@progressivebeef/shared/src/localization/i18n';
import LocaleHelper from '@progressivebeef/shared/src/helpers/LocaleHelper'
import {
  DATE_FORMATS,
  BACKDATED_SUBMISSIONS_FILTER,
} from '@progressivebeef/shared/src/constants/AppConstants';
import {
  getBackdatedCustomSubmissionsRequest,
  getSelectedBackdatedTab,
  resetBackdatedProps,
} from '@progressivebeef/shared/src/store/actions/dashboard';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';

class BackDatedSubmissionsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: props.selectedTab,
      currentDate: new Date(),
      start_date: new Date(),
      end_date: new Date(),
      showStartDate: false,
      showEndDate: false,
      modalVisible: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { error } = this.props;

    if (error && error !== prevProps.error) {
      if (typeof error === 'string') {
        this.setState({ modalVisible: false });
        setTimeout(() => {
          showErrorAlert(error, [
            {
              text: localization[getLocale()].OK,
              onPress: () => {
                this.props.resetBackdatedProps();
              },
            },
          ]);
        }, 500);
      } else {
        showErrorAlert(localization[getLocale()].SOMETHING_WENT_WRONG);
      }
    }
  }

  onSubmitCustom = (startDate, endDate) => {
    let currentDate = new Date();

    if (startDate > currentDate || endDate > currentDate) {
      showAlertMsg(
        localization[getLocale()].INVALID_DATES,
        localization[getLocale()].END_DATE_EARLIER_THAN_TODAY_ERROR
      );
      return;
    } else if (startDate > endDate) {
      showAlertMsg(
        localization[getLocale()].INVALID_DATES,
        localization[getLocale()].START_LESS_THAN_END_DATE
      );
      return;
    }

    startDate = moment(startDate).startOf('day');
    endDate = moment(endDate).endOf('day');

    startDate = moment(startDate).format(DATE_FORMATS.YYYY_MM_DD_HH_mm_ss);
    endDate = moment(endDate).format(DATE_FORMATS.YYYY_MM_DD_HH_mm_ss);

    this.props.getBackdatedCustomSubmissions({
      startDate,
      endDate,
    });

    this.setState({
      modalVisible: false,
      start_date: startDate,
      end_date: endDate,
    });
  };

  render() {
    const {
      isBackdatedSubmissionLoading,
      backdatedSubmissions,
      isSyncingManually,
      isAutoSyncing,
    } = this.props;

    const { filter } = this.state;
    const {
      modalVisible,
      showStartDate,
      showEndDate,
      start_date,
      end_date,
      currentDate,
    } = this.state;

    var labels =
      backdatedSubmissions.stats &&
      backdatedSubmissions.stats[filter] &&
      Array.from(backdatedSubmissions.stats[filter]).map((value) => {
        return filter === BACKDATED_SUBMISSIONS_FILTER.THIS_YEAR
          ? value.back_date
          : filter === BACKDATED_SUBMISSIONS_FILTER.THIS_WEEK ||
            filter === BACKDATED_SUBMISSIONS_FILTER.LAST_WEEK
            ? moment(value.back_date, 'YYYY-MM-DD').locale(getLocale()).format('ddd')
            : moment(value.back_date, 'YYYY-MM-DD').locale(getLocale()).format('MM/DD');
      });

    var data =
      backdatedSubmissions.stats &&
      backdatedSubmissions.stats[filter] &&
      Array.from(backdatedSubmissions.stats[filter]).map((value) =>
        Number(value.submissions_count)
      );

    const date = DashboardHelper.getBackdatedSubmissionsInDuration(
      filter,
      start_date,
      end_date
    );

    return (
      <div
        className={`backdated-dashboard-card${isBackdatedSubmissionLoading && !isSyncingManually && !isAutoSyncing
            ? ' dasboard-card-opt-half'
            : ''
          }`}
      >
        <div className="top-box">
          <div className="date-info">
            <div className="title">
              {localization[getLocale()].BACKDATED_SUBMISSIONS}
            </div>
            <div className="date">{`${date.start_date} - ${date.end_date}`}</div>
          </div>
          <div>
            <PickerComponent
              selectedValue={filter}
              onValueChange={(data) => {
                if (data === BACKDATED_SUBMISSIONS_FILTER.CUSTOM) {
                  this.setState({ modalVisible: true });
                }
                this.props.getSelectedBackdatedTab(data);
                this.setState({
                  filter: data,
                });
              }}
              data={LocaleHelper.BACKDATED_PICKER_VALUES()}
              placeholder={filter.label}
            />
          </div>
        </div>

        {data && data.length > 0 ? (
          <div
            onClick={() => {
              this.props.history.push(RouteConstants.BACKDATED_SUBMISSIONS, {
                filter: filter,
                date: date,
              });
            }}
          >
            <Line
              data={{
                labels: labels,
                datasets: [
                  {
                    label: 'Backdated submissions',
                    data: data,
                    backgroundColor: ['#2c2c62'],
                    borderColor: ['#0079a4'],
                    borderWidth: 2,
                    pointBackgroundColor: '#fff',
                    pointBorderColor: '#0079a4',
                  },
                ],
              }}
              options={{
                // disables bezier curves
                elements: { line: { tension: 0 }, point: { radius: 4 } },
                legend: {
                  display: false,
                  labels: {
                    fontColor: '#fff',
                  },
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        fontColor: '#fff',
                        fontSize: '10',
                        stepSize: 1,
                      },
                    },
                  ],
                  xAxes: [
                    {
                      ticks: {
                        fontColor: '#fff',
                      },
                    },
                  ],
                },
                events: ['click'],
              }}
            />
          </div>
        ) : (
            <div className="graph-empty-msg">
              Data not available to plot the Graph.
            </div>
          )}
        <DatesModal
          modalVisible={modalVisible}
          showStartDate={showStartDate}
          showEndDate={showEndDate}
          start_date={start_date}
          end_date={end_date}
          onSubmitStartDate={(date) => {
            this.setState({
              start_date: date || currentDate,
            });
            this.setState({ showStartDate: false });
          }}
          onSubmitEndDate={(date) => {
            this.setState({
              end_date: date || currentDate,
            });
            this.setState({ showEndDate: false });
          }}
          dismissModal={() => {
            this.setState({ modalVisible: false });
          }}
          onSubmit={this.onSubmitCustom}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBackdatedCustomSubmissions: (payload) =>
      dispatch(getBackdatedCustomSubmissionsRequest(payload)),
    resetBackdatedProps: (payload) => dispatch(resetBackdatedProps(payload)),
    getSelectedBackdatedTab: (payload) =>
      dispatch(getSelectedBackdatedTab(payload)),
  };
};

const mapStateToProps = (state) => {
  const {
    isBackdatedSubmissionLoading,
    backdatedSubmissions,
    selectedTab,
    getCustomBackdateError,
  } = state.dashboard;
  const { isSyncingManually, isAutoSyncing } = state.dataSync;
  return {
    isBackdatedSubmissionLoading,
    backdatedSubmissions,
    selectedTab,
    error: getCustomBackdateError,
    isSyncingManually,
    isAutoSyncing,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BackDatedSubmissionsCard);
