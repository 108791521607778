import React, { Component } from 'react';
import { Spinner } from 'reactstrap';

import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import localization from '@progressivebeef/shared/src/localization/i18n';
import ActivitySubmissionCard from '../../../../components/ActivitySubmissionCard';
import { globalHelper } from '@progressivebeef/shared/src/helpers';

class BackdateListItem extends Component {
  render() {
    let { data, isLoading, history, date, tabType } = this.props;

    return (
      <div id="#main">
        {globalHelper.renderIf(isLoading)(
          <div className="bottom-infinite-loader">
            <Spinner color={'red'} />
          </div>
        )}
        {data && data.length > 0 ? (
          data.map((item) => {
            return (
              <ActivitySubmissionCard
                key={'backdate-activity-' + item.feedlot_activity_id}
                {...this.props}
                tabType={tabType}
                history={history}
                date={date}
                item={item}
              />
            );
          })
        ) : data && data.length < 1 && !isLoading ?
            <div className="no-result-found-box">
              <p>{localization[getLocale()].NO_RECORD_FOUND}</p>
            </div>
            : !isLoading ? (
              <div className="bottom-infinite-loader">
                {globalHelper.renderIf(isLoading)(<Spinner color={'red'} />)}
              </div>
            ) : null}
      </div>
    );
  }
}

export default BackdateListItem;
