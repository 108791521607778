export default {
  SYNC_DATA_BLOCKING_REQUEST: 'SYNC_DATA_BLOCKING_REQUEST',
  SYNC_DATA_BLOCKING_SUCCESS: 'SYNC_DATA_BLOCKING_SUCCESS',
  SYNC_DATA_BLOCKING_FAILURE: 'SYNC_DATA_BLOCKING_FAILURE',

  SYNC_DATA_NON_BLOCKING_REQUEST: 'SYNC_DATA_NON_BLOCKING_REQUEST',
  SYNC_DATA_NON_BLOCKING_SUCCESS: 'SYNC_DATA_NON_BLOCKING_SUCCESS',
  SYNC_DATA_NON_BLOCKING_FAILURE: 'SYNC_DATA_NON_BLOCKING_FAILURE',

  SYNC_DATA_MANUALLY_REQUEST: 'SYNC_DATA_MANUALLY_REQUEST',
  SYNC_DATA_MANUALLY_SUCCESS: 'SYNC_DATA_MANUALLY_SUCCESS',
  SYNC_DATA_MANUALLY_FAILURE: 'SYNC_DATA_MANUALLY_FAILURE',

  SYNC_ACTIVITY_DATA_REQUEST: 'SYNC_ACTIVITY_DATA_REQUEST',
  SYNC_ACTIVITY_DATA_SUCCESS: 'SYNC_ACTIVITY_DATA_SUCCESS',
  SYNC_ACTIVITY_DATA_FAILURE: 'SYNC_ACTIVITY_DATA_FAILURE',

  POST_PENDING_DATA_REQUEST: 'POST_PENDING_DATA_REQUEST',
  POST_PENDING_DATA_SUCCESS: 'POST_PENDING_DATA_SUCCESS',
  POST_PENDING_DATA_FAILURE: 'POST_PENDING_DATA_FAILURE',

  CANCEL_SYNC: 'CANCEL_SYNC',
};
