import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Formik } from 'formik';

//custom components
import AppContainer from '../../../../components/AppContainer';
import AddEditUserForm from './form';
import PBIcon from '../../../../components/PBIcon';
import FormButton from '../../../../components/FormButton';
import {
  showErrorAlert,
  showSuccessAlert,
} from '../../../../components/Alerts';

//helper
import UserHelper from '@progressivebeef/shared/src/helpers/UsersHelper';

//language
import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

//constants
import { AddUserValidationSchema } from '@progressivebeef/shared/src/constants/FormValidations';
import {
  addUserRequested,
  resetUserProps,
} from '@progressivebeef/shared/src/store/actions/user';
import {
  showLoader,
  hideLoader,
} from '@progressivebeef/shared/src/store/actions/view';
import { getDocumentListRequested } from '@progressivebeef/shared/src/store/actions/programMaunals';
import {
  emailRegex,
  specialCharactersRegex,
  userNameRegex,
} from '@progressivebeef/shared/src/constants/AppConstants';

import { trackEvent } from '@progressivebeef/shared/src/services/reactMixpanelService';
import { Events } from '@progressivebeef/shared/src/constants/MixpanelConstants';

class AddEditUser extends PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getDocumentList();
  }

  componentDidUpdate(prevProps) {
    let {
      history,
      isLoading,
      success,
      addEditUserError,
      showLoaderView,
      hideLoaderView,
      userSaved,
    } = this.props;

    if (isLoading) {
      showLoaderView();
    } else {
      hideLoaderView();
    }

    if (success && success !== prevProps.success) {
      trackEvent(Events.ADDED_USER);
      showSuccessAlert(success);
    }

    if (addEditUserError !== prevProps.addEditUserError) {
      if (typeof addEditUserError === 'string') {
        showErrorAlert(addEditUserError);
      }
    }

    if (userSaved) {
      hideLoaderView();
      showSuccessAlert(localization[getLocale()].COMMON_SAVED_SUCCESSFULLY, [
        {
          text: localization[getLocale()].OK,
          onPress: () => {
            this.props.resetUserProps();
            history.goBack();
          },
        },
      ]);
    }
  }

  render() {
    let initialValues = UserHelper.getUserFormInitialValues(
      this.props.location.state ? this.props.location.state.user : null,
      this.props.documents,
    );

    const { history } = this.props;

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={AddUserValidationSchema}
        onSubmit={(values) => this.saveUser(values)}
        validateOnMount={true}>
        {(props) => {
          const { handleSubmit, isValid, submitCount, values } = props;
          let isSubmitted = submitCount > 0;

          return (
            <AppContainer
              title={
                this.props.location.state &&
                this.props.location.state.user.user_id
                  ? localization[getLocale()].EDIT_USER
                  : localization[getLocale()].ADD_USER
              }
              iconHeader
              IconLeftHeader={this.renderLeftHeader}
              history={history}
              IconRightHeader={this.renderRightHeader}
              noncollapsible>
              <div>
                <AddEditUserForm
                  {...props}
                  isSubmitted={isSubmitted}
                  history={this.props.history}
                  user={
                    this.props.location.state &&
                    this.props.location.state.user.user_id
                      ? this.props.location.state.user
                      : {}
                  }
                />
              </div>

              <FormButton
                onPressEvent={() =>
                  this.onSubmit(handleSubmit, isValid, values)
                }
                // custonLnrGrdntBtnStyles={styles.saveButton}
                title={localization[getLocale()].SAVE}
                success
              />
            </AppContainer>
          );
        }}
      </Formik>
    );
  }

  renderRightHeader = () => {
    if (
      !(this.props.location.state && this.props.location.state.user.user_id)
    ) {
      return (
        <div className="head-icon right-align">
          <PBIcon name={'times'} onPress={() => this.props.history.goBack()} />
        </div>
      );
    } else {
      return null;
    }
  };

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          name={'chevron-left'}
          onPress={() => this.props.history.goBack()}
        />
      </div>
    );
  };

  onSubmit = (handleSubmit, isValid, values) => {
    if (!isValid) {
      showErrorAlert(localization[getLocale()].PLEASE_FILL_ALL_FIELDS);
    } else {
      let user =
        this.props.location.state && this.props.location.state.user.user_id
          ? this.props.location.state.user
          : {};
      if (this.validateAddUserRequest(values, user)) {
        handleSubmit();
      }
    }
  };

  validateAddUserRequest = (data, user) => {
    let isValid = true;
    if (!user.user_id) {
      if (data.password == '' || data.confirmPassword == '') {
        isValid = false;
        showErrorAlert(localization[getLocale()].PLEASE_FILL_ALL_FIELDS);
      }
    }
    if (data.user_name.length < 3) {
      isValid = false;
      showErrorAlert(localization[getLocale()].VALIDATE_USER_NAME_LENGTH_MIN);
    } else if (data.user_name.length > 45) {
      isValid = false;
      showErrorAlert(localization[getLocale()].VALIDATE_USER_NAME_LENGTH_MAX);
    } else if (!userNameRegex.test(data.user_name)) {
      isValid = false;
      showErrorAlert(localization[getLocale()].INVALID_USERNAME);
    } else if (data.password != '' && data.password.length < 10) {
      isValid = false;
      showErrorAlert(localization[getLocale()].VALIDATE_USER_PASSWORD_LENGTH);
    } else if (data.password !== data.confirmPassword) {
      isValid = false;
      showErrorAlert(localization[getLocale()].PASSWORDS_DONT_MATCH);
    } else if (data.email_address && data.email_address != '') {
      if (!emailRegex.test(data.email_address)) {
        isValid = false;
        showErrorAlert(localization[getLocale()].INVALID_EMAIL);
      }
    } else if (specialCharactersRegex.test(data.first_name)) {
      isValid = false;
      showErrorAlert(localization[getLocale()].INVALID_FIRSTNAME);
    } else if (specialCharactersRegex.test(data.last_name)) {
      isValid = false;
      showErrorAlert(localization[getLocale()].INVALID_LASTNAME);
    }

    return isValid;
  };

  saveUser = (requestValues) => {
    requestValues = {
      ...requestValues,
      user_name: requestValues.user_name.trim(),
    };
    this.props.addUser(requestValues);
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: (payload) => dispatch(addUserRequested(payload)),
    resetUserProps: (payload) => dispatch(resetUserProps(payload)),
    showLoaderView: (payload) => dispatch(showLoader(payload)),
    hideLoaderView: (payload) => dispatch(hideLoader(payload)),
    getDocumentList: (payload) => dispatch(getDocumentListRequested(payload)),
  };
};

const mapStateToProps = (state) => {
  const { isLoading, success, addEditUserError, userSaved } = state.user;
  const { documents } = state.programManuals;

  return {
    isLoading,
    success,
    addEditUserError,
    userSaved,
    documents,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditUser);
