// modules
import React, { Component } from 'react';
import { connect } from 'react-redux';

//language
import localization from '@progressivebeef/shared/src/localization/i18n';
import {
  getLocale,
  setLocale,
} from '@progressivebeef/shared/src/services/storageService/GlobalData';

//styles
import { Button, Col, FormGroup, Input, Row } from 'reactstrap';

import './Login.scss';
import logo from '../../../assets/img/brand/pb-logo.png';

// constants
import {
  CLIENT_TYPE,
  LoginType,
} from '@progressivebeef/shared/src/constants/AppConstants';
import { LoginFormValidator } from '@progressivebeef/shared/src/validators/form-validators';

import {
  loginRequested,
  setLocaleToStorage,
} from '@progressivebeef/shared/src/store/actions/authentication';
import {
  showLoader,
  hideLoader,
} from '@progressivebeef/shared/src/store/actions/view';
import { showErrorAlert } from '../../../components/Alerts';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: 'en',
      username: '',
      password: '',
      pin: '',
      showPassword: false,
      showPin: false,
    };
  }

  componentDidUpdate(prevProps) {
    let { isLogin, user, showLoaderView, hideLoaderView, isLoading, error } =
      this.props;

    if (isLoading) {
      showLoaderView({
        loadingText: localization[getLocale()].AUTHENTICATING,
        isAuthenticating: true,
      });
    } else {
      hideLoaderView();
    }

    if (error !== prevProps.error) {
      if (typeof error === 'string') {
        showErrorAlert(error);
      }
    }

    if (isLogin && user) {
      hideLoaderView();
      if (!user.pinCode) {
        this.props.history.push('/GeneratePin');
      } else {
        this.props.history.push('/FeedyardList');
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username, password, pin } = this.state;

    if (username != '' && (pin != '' || password != '')) {
      const request = {
        user_name: username,
        client_type: CLIENT_TYPE,
      };
      let type = LoginType.PIN;

      if (pin) {
        request.pin = pin;
      } else {
        request.password = password;
        type = LoginType.PASSWORD;
      }
      if (LoginFormValidator.validateLoginRequest(request, type)) {
        this.props.login(request);
      }
    } else {
      showErrorAlert(localization[getLocale()].LOGIN_FIELDS_MISSING);
    }
  };

  onLocaleChange = (language) => {
    setLocale(language);
    this.setState({
      language,
    });
    this.props.setLocaleToStorage(language);
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  };

  render() {
    const { isLoading } = this.props;
    const { username, password, pin } = this.state;

    return (
      <div className="signInBody">
        <div>
          <Col className="loginSection">
            <Col md="12" className="loginInnerSection">
              <div className="logo">
                <img alt="Progresive Beef" src={logo} />
              </div>
              <form name="form" onSubmit={this.handleSubmit}>
                <div className="login-field-box">
                  <FormGroup>
                    <div className="field-group">
                      <i className="fal fa-user leading-icon"></i>
                      <Input
                        id="email"
                        type="text"
                        value={username}
                        name="username"
                        placeholder={localization[getLocale()].USERNAME}
                        onChange={this.handleChange}
                        defaultValue={''}
                      />
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <div
                      className={
                        'field-group ' + (pin ? 'field-disabled' : '')
                      }>
                      <i className="fal fa-key leading-icon" />
                      <div className="icon-input">
                        <Input
                          id="password"
                          type={this.state.showPassword ? 'text' : 'password'}
                          name="password"
                          value={password}
                          placeholder={localization[getLocale()].PASSWORD}
                          onChange={this.handleChange}
                          defaultValue={''}
                          autoComplete="new-password"
                        />
                        <i
                          className={`fal trailing-icon ${
                            this.state.showPassword ? 'fa-eye' : 'fa-eye-slash'
                          }`}
                          onClick={() => {
                            this.setState({
                              showPassword: !this.state.showPassword,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <div
                      className={
                        'field-group ' + (password ? 'field-disabled' : '')
                      }>
                      <i className="fal fa-th leading-icon"></i>
                      <div className="icon-input">
                        <Input
                          id="pin"
                          name="pin"
                          type={this.state.showPin ? 'text' : 'password'}
                          pattern="[0-9]+$"
                          maxLength={4}
                          value={pin}
                          placeholder={localization[getLocale()].PIN}
                          onChange={this.handleChange}
                          defaultValue={''}
                          autoComplete="new-password"
                        />
                        <i
                          className={`fal trailing-icon ${
                            this.state.showPin ? 'fa-eye' : 'fa-eye-slash'
                          }`}
                          onClick={() => {
                            this.setState({
                              showPin: !this.state.showPin,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </FormGroup>

                  <div className="lang-box">
                    <Button
                      onClick={() => this.onLocaleChange('en')}
                      className={getLocale() === 'en' ? 'active' : ''}
                      color="primary"
                      block
                      disabled={isLoading}>
                      {localization[getLocale()].ENGLISH}
                    </Button>

                    <Button
                      onClick={() => this.onLocaleChange('es')}
                      className={getLocale() === 'es' ? 'active' : ''}
                      color="primary"
                      block
                      disabled={isLoading}>
                      {localization[getLocale()].SPANISH}
                    </Button>
                  </div>
                </div>
                <Row>
                  <Col md="12" className="login-btn">
                    <Button
                      color="primary"
                      block
                      type="submit"
                      disabled={isLoading}>
                      {localization[getLocale()].LOGIN}
                    </Button>
                  </Col>
                </Row>

                <a
                  onClick={() =>
                    this.props.history.push(RouteConstants.FORGOT_PASSWORD)
                  }
                  className="forgotPass">
                  {localization[getLocale()].LOST_PIN_PASSWORD}
                </a>
              </form>
            </Col>
          </Col>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (requestData) => dispatch(loginRequested({ requestData })),
    showLoaderView: (payload) => dispatch(showLoader(payload)),
    hideLoaderView: (payload) => dispatch(hideLoader(payload)),
    setLocaleToStorage: (payload) => dispatch(setLocaleToStorage(payload)),
  };
};

const mapStateToProps = (state) => {
  const { isLogin, user, isLoading, loadingText, error } = state.authentication;
  return {
    isLogin,
    user,
    isLoading,
    loadingText,
    error,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
