import { apiClient } from './index';
import { handleResponse, handleError } from './responseHandler';
import APIConstants from '../../constants/APIConstants';

class AppTrainingVideoHtmlService {
  getAppTrainingVideoHtml() {
    const url = APIConstants.GET_APP_TRAINING_VIDEO_HTML;
    return apiClient().get(url).then(handleResponse).catch(handleError);
  }
}

export default new AppTrainingVideoHtmlService();
