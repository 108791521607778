import { request, success, failure } from './index';
import ACTION_CONSTANTS from '../../constants/actionConstants';

export function setServerError(payload) {
  return request(ACTION_CONSTANTS.SET_SERVER_ERROR, payload);
}

export function getWorkersRequest(payload = {}) {
  return request(ACTION_CONSTANTS.GET_WORKERS_REQUEST, payload);
}

export function getWorkers() {
  return request(ACTION_CONSTANTS.GET_WORKERS);
}

export function getWorkersSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_WORKERS_SUCCESS, payload);
}

export function getWorkersFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_WORKERS_FAILURE, payload);
}

export function sortWorkersAsc(payload) {
  return success(ACTION_CONSTANTS.SORT_WORKERS_ASC, payload);
}

export function sortWorkersDesc(payload) {
  return success(ACTION_CONSTANTS.SORT_WORKERS_DESC, payload);
}

export function postFeedlotReportRequest(payload = {}) {
  return request(ACTION_CONSTANTS.POST_FEEDLOT_REPORT_REQUEST, payload);
}

export function postFeedlotReportSuccess(payload) {
  return success(ACTION_CONSTANTS.POST_FEEDLOT_REPORT_SUCCESS, payload);
}

export function postFeedlotReportFailure(payload) {
  return failure(ACTION_CONSTANTS.POST_FEEDLOT_REPORT_FAILURE, payload);
}

export function getFeedlotStatsRequest(payload = {}) {
  return request(ACTION_CONSTANTS.GET_FEEDLOT_STATS_REQUEST, payload);
}

export function getFeedlotStats(payload = {}) {
  return request(ACTION_CONSTANTS.GET_FEEDLOT_STATS, payload);
}

export function getFeedlotStatsSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_FEEDLOT_STATS_SUCCESS, payload);
}

export function getFeedlotStatsFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_FEEDLOT_STATS_FAILURE, payload);
}

export function getBackdatedSubmissionsRequest(payload = {}) {
  return request(ACTION_CONSTANTS.GET_BACKDATED_SUBMISSIONS_REQUEST, payload);
}

export function getBackdatedSubmissions(payload = {}) {
  return request(ACTION_CONSTANTS.GET_BACKDATED_SUBMISSIONS, payload);
}

export function getBackdatedSubmissionsSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_BACKDATED_SUBMISSIONS_SUCCESS, payload);
}

export function getBackdatedSubmissionsFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_BACKDATED_SUBMISSIONS_FAILURE, payload);
}

export function getFeedlotCustomPerformanceRequest(
  payload = { startDate: '', endDate: '' },
) {
  return request(
    ACTION_CONSTANTS.GET_FEEDLOT_CUSTOM_PERFORMANCE_REQUEST,
    payload,
  );
}

export function getFeedlotCustomPerformanceSuccess(payload) {
  return success(
    ACTION_CONSTANTS.GET_FEEDLOT_CUSTOM_PERFORMANCE_SUCCESS,
    payload,
  );
}

export function getFeedlotCustomPerformanceFailure(payload) {
  return failure(
    ACTION_CONSTANTS.GET_FEEDLOT_CUSTOM_PERFORMANCE_FAILURE,
    payload,
  );
}

export function getCustomBackdatedDurationSuccess(payload) {
  return success(
    ACTION_CONSTANTS.GET_CUSTOM_BACKDATED_DURATION_SUCCESS,
    payload,
  );
}

export function getFeedlotRankRequest(payload = {}) {
  return request(ACTION_CONSTANTS.GET_FEEDLOT_RANK_REQUEST, payload);
}

export function getFeedlotRankSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_FEEDLOT_RANK_SUCCESS, payload);
}

export function getFeedlotRankFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_FEEDLOT_RANK_FAILURE, payload);
}

export function hideRankCard(payload) {
  return failure(ACTION_CONSTANTS.HIDE_RANK_CARD, payload);
}

export function resetRankCard(payload) {
  return failure(ACTION_CONSTANTS.RESET_RANK_CARD, payload);
}

export function getBackdatedCustomSubmissionsRequest(
  payload = { startDate: '', endDate: '' },
) {
  return request(
    ACTION_CONSTANTS.GET_BACKDATED_CUSTOM_SUBMISSIONS_REQUEST,
    payload,
  );
}

export function getBackdatedCustomSubmissions(payload) {
  return request(ACTION_CONSTANTS.GET_BACKDATED_CUSTOM_SUBMISSIONS, payload);
}

export function getBackdatedCustomSubmissionsSuccess(payload) {
  return success(
    ACTION_CONSTANTS.GET_BACKDATED_CUSTOM_SUBMISSIONS_SUCCESS,
    payload,
  );
}

export function getBackdatedCustomSubmissionsFailure(payload) {
  return failure(
    ACTION_CONSTANTS.GET_BACKDATED_CUSTOM_SUBMISSIONS_FAILURE,
    payload,
  );
}

export function getBackdatedCustomSubmissionsActivitiesSuccess(payload) {
  return success(
    ACTION_CONSTANTS.GET_BACKDATED_CUSTOM_SUBMISSIONS_ACTIVITIES_SUCCESS,
    payload,
  );
}

//#region ==== MOBILE NOTIFICATIONS ====
export function resetMobileNotification(payload = {}) {
  return request(ACTION_CONSTANTS.RESET_MOBILE_NOTIFICATION, payload);
}

export function setMobileNotificationCount(payload = {}) {
  return request(ACTION_CONSTANTS.SET_MOBILE_NOTIFICATION_COUNT, payload);
}

export function getMobileNotificationRequest(payload = {}) {
  return request(ACTION_CONSTANTS.GET_MOBILE_NOTIFICATION_REQUEST, payload);
}

export function getMobileNotification(payload = {}) {
  return request(ACTION_CONSTANTS.GET_MOBILE_NOTIFICATION, payload);
}

export function getMobileNotificationLoadMoreAction(payload = {}) {
  return request(ACTION_CONSTANTS.GET_MOBILE_NOTIFICATION_LOAD_MORE, payload);
}

export function getMobileNotificationSuccess(payload = {}) {
  return success(ACTION_CONSTANTS.GET_MOBILE_NOTIFICATION_SUCCESS, payload);
}

export function getMobileNotificationFailure(payload = {}) {
  return failure(ACTION_CONSTANTS.GET_MOBILE_NOTIFICATION_FAILURE, payload);
}
//#endregion

//#region ===== Mobile Updates =====
export function resetMobileUpdate(payload = {}) {
  return request(ACTION_CONSTANTS.RESET_MOBILE_UPDATE, payload);
}

export function getMobileUpdateRequest(payload = {}) {
  return request(ACTION_CONSTANTS.GET_MOBILE_UPDATE_REQUEST, payload);
}

export function getMobileUpdate(payload = {}) {
  return request(ACTION_CONSTANTS.GET_MOBILE_UPDATE, payload);
}

export function getMobileUpdateLoadMoreAction(payload = {}) {
  return request(ACTION_CONSTANTS.GET_MOBILE_UPDATE_LOAD_MORE, payload);
}

export function getMobileUpdateSuccess(payload = {}) {
  return success(ACTION_CONSTANTS.GET_MOBILE_UPDATE_SUCCESS, payload);
}

export function getMobileUpdateFailure(payload = {}) {
  return failure(ACTION_CONSTANTS.GET_MOBILE_UPDATE_FAILURE, payload);
}
//#endregion

export function getMissedActivities(payload = {}) {
  return request(ACTION_CONSTANTS.GET_MISSED_ACTIVITIES, payload);
}

export function getMissedActivitiesRequest(payload = {}) {
  return request(ACTION_CONSTANTS.GET_MISSED_ACTIVITIES_REQUEST, payload);
}

export function getMissedActivitiesSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_MISSED_ACTIVITIES_SUCCESS, payload);
}

export function getMissedActivitiesFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_MISSED_ACTIVITIES_FAILURE, payload);
}

export function getPendingActionsCount(payload = {}) {
  return request(ACTION_CONSTANTS.GET_PENDING_ACTIONS_COUNT, payload);
}

export function getPendingActionsCountRequest(payload = {}) {
  return request(ACTION_CONSTANTS.GET_PENDING_ACTIONS_COUNT_REQUEST, payload);
}

export function getPendingActionsCountSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_PENDING_ACTIONS_COUNT_SUCCESS, payload);
}

export function getPendingActionsCountFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_PENDING_ACTIONS_COUNT_FAILURE, payload);
}

export function getSearchedPendingActionsRequest(payload = {}) {
  return request(
    ACTION_CONSTANTS.GET_SEARCHED_PENDING_ACTIONS_REQUEST,
    payload,
  );
}

export function getSearchedPendingActionsSuccess(payload) {
  return success(
    ACTION_CONSTANTS.GET_SEARCHED_PENDING_ACTIONS_SUCCESS,
    payload,
  );
}

export function getSearchedPendingActionsFailure(payload) {
  return failure(
    ACTION_CONSTANTS.GET_SEARCHED_PENDING_ACTIONS_FAILURE,
    payload,
  );
}

export function getBackdatedActivitiesRequest(payload) {
  return request(ACTION_CONSTANTS.GET_BACKDATED_ACTIVITIES_REQUEST, payload);
}

export function getBackdatedActivities(payload) {
  return request(ACTION_CONSTANTS.GET_BACKDATED_ACTIVITIES, payload);
}

export function getBackdatedActivitiesSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_BACKDATED_ACTIVITIES_SUCCESS, payload);
}

export function getBackdatedActivitiesFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_BACKDATED_ACTIVITIES_FAILURE, payload);
}

export function searchBackdatedActivities(payload) {
  return request(ACTION_CONSTANTS.BACKDATED_SEARCH_ACTIVITIES, payload);
}

export function filterBackdatedActivities(payload) {
  return request(ACTION_CONSTANTS.BACKDATED_FILTER_ACTIVITIES, payload);
}

export function resetBackdatedProps(payload) {
  return request(ACTION_CONSTANTS.RESET_BACKDATED_PAYLOAD, payload);
}

export function getSelectedBackdatedTab(payload) {
  return request(ACTION_CONSTANTS.GET_SELECTED_BACKDATED_TAB, payload);
}
