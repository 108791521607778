export default {
  GET_AUDITS: 'GET_AUDITS',
  GET_AUDITS_REQUEST: 'GET_AUDITS_REQUEST',
  GET_AUDITS_SUCCESS: 'GET_AUDITS_SUCCESS',
  GET_AUDITS_FAILURE: 'GET_AUDITS_FAILURE',

  SET_CURRENT_AUDIT_REQUEST: 'SET_CURRENT_AUDIT_REQUEST',

  SEARCH_AUDITS_REQUEST: 'SEARCH_AUDITS_REQUEST',
  SEARCH_AUDITS_SUCCESS: 'SEARCH_AUDITS_SUCCESS',
  SEARCH_AUDITS_FAILURE: 'SEARCH_AUDITS_FAILURE',

  SEARCH_AUDIT_NCS_REQUEST: 'SEARCH_AUDIT_NCS_REQUEST',
  SEARCH_AUDIT_NCS_SUCCESS: 'SEARCH_AUDIT_NCS_SUCCESS',
  SEARCH_AUDIT_NCS_FAILURE: 'SEARCH_AUDIT_NCS_FAILURE',

  SET_AUDIT_STATUS_REQUEST: 'SET_AUDIT_STATUS_REQUEST',
  SET_AUDIT_STATUS_SUCCESS: 'SET_AUDIT_STATUS_SUCCESS',
  SET_AUDIT_STATUS_FAILURE: 'SET_AUDIT_STATUS_FAILURE',

  GET_AUDIT_REPORT_REQUEST: 'GET_AUDIT_REPORT_REQUEST',
  GET_AUDIT_REPORT_SUCCESS: 'GET_AUDIT_REPORT_SUCCESS',
  GET_AUDIT_REPORT_FAILURE: 'GET_AUDIT_REPORT_FAILURE',

  GET_QUESTION_COMMENTS: 'GET_QUESTION_COMMENTS',
  GET_QUESTION_COMMENTS_REQUEST: 'GET_QUESTION_COMMENTS_REQUEST',
  GET_QUESTION_COMMENTS_SUCCESS: 'GET_QUESTION_COMMENTS_SUCCESS',
  GET_QUESTION_COMMENTS_FAILURE: 'GET_QUESTION_COMMENTS_FAILURE',

  SEND_COMMENT_REQUEST: 'SEND_COMMENT_REQUEST',
  SEND_COMMENT_SUCCESS: 'SEND_COMMENT_SUCCESS',
  SEND_COMMENT_FAILURE: 'SEND_COMMENT_FAILURE',

  GET_AUDIT_BY_ID_REQUEST: 'GET_AUDIT_BY_ID_REQUEST',
  GET_AUDIT_BY_ID_SUCCESS: 'GET_AUDIT_BY_ID_SUCCESS',
  GET_AUDIT_BY_ID_FAILURE: 'GET_AUDIT_BY_ID_FAILURE',
};
