import React, { Component } from 'react';
import ActivityHelper from '@progressivebeef/shared/src/helpers/ActivitiesHelper';
import './style.scss';

class PendingActionsListItem extends Component {
  render() {
    let { item } = this.props;
    return (
      <div className="pending-action-item-listing">
        <div className="flex-row">
          <div className="count">
            {item.count}
          </div>
          <div className="icon">
            <img
              alt="description"
              src={ActivityHelper.getTypeIcon(item)}
            />
          </div>
          <div className="info">{item.activity_number}</div>
        </div>
        <div className="label">{item.title}</div>
      </div>
    );
  }
}

export default PendingActionsListItem;
