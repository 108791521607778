import React, { Component } from 'react';
import moment from 'moment';

import PBIcon from '../PBIcon';
import './style.scss';

import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

import {
  DATE_FORMATS,
  IS_CORRECTIVE_ACTIONS_REQUIRED,
} from '@progressivebeef/shared/src/constants/AppConstants';

class AuditCard extends Component {
  render() {
    let { item, onCardPress, history } = this.props;

    return (
      <div onClick={() => onCardPress(item, history)}>
        <div className="audit-listing-box">
          <div className="left-col">
            <div className="label">{`${item.audit.audit_number}`}</div>

            <div className="date-box">
              <div className="icon">
                <PBIcon name={'calendar-alt'} />
              </div>
              <div className="date">
                {moment(item.audit_summary.parameters.audit_from).format(
                  DATE_FORMATS.MM_DD_YY,
                ) +
                  ' - ' +
                  moment(item.audit_summary.parameters.audit_to).format(
                    DATE_FORMATS.MM_DD_YY,
                  )}
              </div>
            </div>
            <div className="correct-action-box">
              {item.audit_summary.parameters.is_corrective_actions_required ==
                IS_CORRECTIVE_ACTIONS_REQUIRED.YES &&
                item.non_conformances.length > 0 && (
                  <div>
                    {localization[getLocale()].CORRECTIVE_ACTIONS_DUE_DATE +
                      ' '}
                    {moment(
                      item.audit_summary.parameters
                        .corrective_actions_required_by,
                    ).format(DATE_FORMATS.MM_DD_YY)}
                  </div>
                )}
            </div>
          </div>
          <div className="right-col">
            <div className="audit-status">
              {localization[getLocale()]['AUDIT_CARD_STATUS_' + item.status]}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AuditCard;
