export default {
  GET_INITIALIZED: 'GET_INITIALIZED',
  GET_INITIALIZED_SUCCESS: 'GET_INITIALIZED_SUCCESS',
  GET_INITIALIZED_FAILURE: 'GET_INITIALIZED_FAILURE',

  SHOW_LOCK_SCREEN: 'SHOW_LOCK_SCREEN',
  RESET_LOCK_SCREEN_PROPS: 'RESET_LOCK_SCREEN_PROPS',

  LOGIN_REQUESTED: 'LOGIN_REQUESTED',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  LOGIN_VIA_BIOMETRIC_REQUESTED: 'LOGIN_VIA_BIOMETRIC_REQUESTED',
  LOGIN_VIA_BIOMETRIC_SUCCESS: 'LOGIN_VIA_BIOMETRIC_SUCCESS',
  LOGIN_VIA_BIOMETRIC_FAILURE: 'LOGIN_VIA_BIOMETRIC_FAILURE',

  TOGGLE_BIOMETRIC_REQUESTED: 'TOGGLE_BIOMETRIC_REQUESTED',
  TOGGLE_BIOMETRIC_SUCCESS: 'TOGGLE_BIOMETRIC_SUCCESS',
  TOGGLE_BIOMETRIC_FAILURE: 'TOGGLE_BIOMETRIC_FAILURE',

  LOGOUT_REQUESTED: 'LOGOUT_REQUESTED',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',

  START_LOADING: 'START_LOADING',
  STOP_LOADING: 'STOP_LOADING',

  SET_USER_DEVICE: 'SET_USER_DEVICE',
  SET_LOCALE_TO_STORAGE: 'SET_LOCALE_TO_STORAGE',

  SET_FIRST_LOGIN_REQUESTED: 'SET_FIRST_LOGIN_REQUESTED',
  SET_FIRST_LOGIN_SUCCESS: 'SET_FIRST_LOGIN_SUCCESS',
  SET_FIRST_LOGIN_FAILURE: 'SET_FIRST_LOGIN_FAILURE',

  SET_LATEST_ANDROID_VERSION_REQUESTED: 'SET_LATEST_ANDROID_VERSION_REQUESTED',
  SET_LATEST_ANDROID_VERSION_SUCCESS: 'SET_LATEST_ANDROID_VERSION_SUCCESS',
  SET_LATEST_ANDROID_VERSION_FAILURE: 'SET_LATEST_ANDROID_VERSION_FAILURE',

  SET_LATEST_IOS_VERSION_REQUESTED: 'SET_LATEST_IOS_VERSION_REQUESTED',
  SET_LATEST_IOS_VERSION_SUCCESS: 'SET_LATEST_IOS_VERSION_SUCCESS',
  SET_LATEST_IOS_VERSION_FAILURE: 'SET_LATEST_IOS_VERSION_FAILURE',

  SET_ANDROID_FORCE_UPDATE_REQUESTED: 'SET_ANDROID_FORCE_UPDATE_REQUESTED',
  SET_ANDROID_FORCE_UPDATE_SUCCESS: 'SET_ANDROID_FORCE_UPDATE_SUCCESS',
  SET_ANDROID_FORCE_UPDATE_FAILURE: 'SET_ANDROID_FORCE_UPDATE_FAILURE',

  SET_IOS_FORCE_UPDATE_REQUESTED: 'SET_IOS_FORCE_UPDATE_REQUESTED',
  SET_IOS_FORCE_UPDATE_SUCCESS: 'SET_IOS_FORCE_UPDATE_SUCCESS',
  SET_IOS_FORCE_UPDATE_FAILURE: 'SET_IOS_ANDROID_FORCE_UPDATE_FAILURE',

  FORGOT_PASSWORD_REQUESTED: 'FORGOT_PASSWORD_REQUESTED',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',

  GENERATE_PIN_REQUESTED: 'GENERATE_PIN_REQUESTED',
  GENERATE_PIN_SUCCESS: 'GENERATE_PIN_SUCCESS',
  GENERATE_PIN_FAILURE: 'GENERATE_PIN_FAILURE',

  VALIDATE_PIN_REQUESTED: 'VALIDATE_PIN_REQUESTED',
  VALIDATE_PIN_SUCCESS: 'VALIDATE_PIN_SUCCESS',
  VALIDATE_PIN_FAILURE: 'VALIDATE_PIN_FAILURE',

  UNLOCK_APP_VIA_BIOMETRIC_REQUESTED: 'UNLOCK_APP_VIA_BIOMETRIC_REQUESTED',
  UNLOCK_APP_VIA_BIOMETRIC_SUCCESS: 'UNLOCK_APP_VIA_BIOMETRIC_SUCCESS',
  UNLOCK_APP_VIA_BIOMETRIC_FAILURE: 'UNLOCK_APP_VIA_BIOMETRIC_FAILURE',

  TOGGLE_EMAIL_ALERT_REQUESTED: 'TOGGLE_EMAIL_ALERT_REQUESTED',
  TOGGLE_EMAIL_ALERT_SUCCEEDED: 'TOGGLE_EMAIL_ALERT_SUCCEEDED',
  TOGGLE_EMAIL_ALERT_FAILED: 'TOGGLE_EMAIL_ALERT_FAILED',
};
