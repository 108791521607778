import React, { Component } from 'react';
import './style.scss'

class CountTag extends Component {
  render() {
    const { totalCount, count } = this.props;

    return (
      <div className="component-count-box">
        {`${count ? count : 0}/${totalCount ? totalCount : 0}`}
      </div>
    );
  }
}

export default CountTag;
