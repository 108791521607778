import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from 'reactstrap';
import classnames from 'classnames';

import './style.scss';

import { showErrorAlert } from '../../../components/Alerts';
import AppContainer from '../../../components/AppContainer';

import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

import {
  getAppTrainingVideoHtmlRequest,
  resetAppTrainingVideoState,
} from '@progressivebeef/shared/src/store/actions/appTrainingVideoHTML';
import {
  showLoader,
  hideLoader,
} from '@progressivebeef/shared/src/store/actions/view';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

class AppTrainingVideoHtml extends PureComponent {
  fileType = '';
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      fileData: null,
    };
  }

  componentDidMount() {
    const { appTrainingVideoHTML, isLoading } = this.props;

    if (!appTrainingVideoHTML && !isLoading)
      this.props.getAppTrainingVideoHtml({ update: true });
  }

  componentDidUpdate(prevProps) {
    let { isLoading, showLoaderView, hideLoaderView, error } = this.props;

    if (isLoading) {
      showLoaderView();
    } else {
      hideLoaderView();
    }

    if (error !== prevProps.error) {
      if (typeof error === 'string') {
        showErrorAlert(error, [
          {
            text: localization[getLocale()].OK,
            onPress: () => {
              this.props.history.push(RouteConstants.DASHBOARD);
            },
            style: 'cancel',
          },
        ]);
      }
    }
  }

  componentWillUnmount() {
    this.props.resetAppTrainingVideoState();
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  renderHTML = () => {
    const { appTrainingVideoHTML } = this.props;
    let { activeTab } = this.state;

    let appTrainingVideoHTMLBody =
      appTrainingVideoHTML &&
      appTrainingVideoHTML.app_training_video_locale[activeTab].body;
    let htmlBody = htmlToReactParser.parse(appTrainingVideoHTMLBody);

    return htmlBody;
  };

  render() {
    const { history } = this.props;
    let { activeTab, isLoading } = this.state;

    return (
      <AppContainer
        title={localization[getLocale()].APP_TRAINING_VIDEO_HTML}
        iconHeader
        IconLeftHeader={this.renderLeftHeader}
        IconRightHeader={this.renderRightHeader}
        noncollapsible
        history={history}>
        <div className="tab-screen-parent-container">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => {
                  this.toggle('1');
                }}>
                {localization[getLocale()].ENGLISH}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  this.toggle('2');
                }}>
                {localization[getLocale()].SPANISH}
              </NavLink>
            </NavItem>
          </Nav>

          <div className="tab-screen-scrollable-container pos-relative">
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <div id="#main">
                  {isLoading ? (
                    <div className="bottom-infinite-loader">
                      <Spinner color={'red'} />
                    </div>
                  ) : (
                    <div
                      id="appTrainingVideoHTML-en"
                      className="html-view-main-box">
                      {this.renderHTML()}
                    </div>
                  )}
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div id="#main">
                  {isLoading ? (
                    <div className="bottom-infinite-loader">
                      <Spinner color={'red'} />
                    </div>
                  ) : (
                    <div
                      id="appTrainingVideoHTML-es"
                      className="html-view-main-box">
                      {this.renderHTML()}
                    </div>
                  )}
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </AppContainer>
    );
  }

  renderLeftHeader = () => {
    return <div className="head-icon left-align"></div>;
  };

  renderRightHeader = () => {
    return <div className="head-icon right-align"></div>;
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAppTrainingVideoHtml: (payload) =>
      dispatch(getAppTrainingVideoHtmlRequest(payload)),
    resetAppTrainingVideoState: (payload) =>
      dispatch(resetAppTrainingVideoState(payload)),
    showLoaderView: (payload) => dispatch(showLoader(payload)),
    hideLoaderView: (payload) => dispatch(hideLoader(payload)),
  };
};

const mapStateToProps = (state) => {
  const { appTrainingVideoHTML, isLoading, error, success } =
    state.appTrainingVideoHTML;

  return {
    appTrainingVideoHTML,
    isLoading,
    error,
    success,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppTrainingVideoHtml);
