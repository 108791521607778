import _ from 'lodash';
import ACTION_CONSTANTS from '../../constants/actionConstants';
import DashboardHelper from '../../helpers/DashboardHelper';
import {
  BACKDATED_SUBMISSIONS_FILTER,
  SORTED_ASC,
  SORTED_DESC,
  rankStatus,
} from '../../constants/AppConstants';
import { getSelectedFeedlotId } from '../../services/storageService/GlobalData';

export const initialState = {
  activeWorkers: [],
  nonActiveWorkers: [],
  workersSorted: SORTED_ASC,
  performance: null,
  feedlot_activites: [],
  custom_activities_instances: {},
  performanceData: {
    daily: { performance: '0.0' },
    thisWeek: { performance: '0.0' },
    weekly: { performance: '0.0' },
    thisMonth: { performance: '0.0' },
    monthly: { performance: '0.0' },
    cumulative: { performance: '0.0' },
  },
  mobile_notifications: [],
  mobile_updates: [],
  missedActivities: [],
  mobileNotificationCount: 0,
  isPendingActionsLoading: false,
  pendingActionsCount: 0,
  pendingActions: [],
  searchedPendingActions: [],
  backdatedSubmissions: [],
  backdatedActivities: [],
  backdatedSubmissionsActivities: [],
  isBackdatedSubmissionLoading: false,
  isBackdatedActivitiesLoading: true,
  pendingActionsQuery: '',
  backdatedFilter: [],
  backdatedQuery: '',
  rank: {},
  rankLoading: rankStatus.NOT_LOADED,
  serverError: false,
  isMobileNotificationLoadingMore: false,
  getCustomPerformanceSuccess: false,
  selectedTab: BACKDATED_SUBMISSIONS_FILTER.LAST_WEEK,
  showRankCard: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_CONSTANTS.GET_SELECTED_BACKDATED_TAB: {
      // console.log('GET_SELECTED_BACKDATED_TAB');
      return {
        ...state,
        selectedTab: action.payload,
      };
    }

    //#region ====== WORKER ACTIONS ======
    case ACTION_CONSTANTS.GET_WORKERS:
    case ACTION_CONSTANTS.GET_WORKERS_REQUEST: {
      return {
        ...state,
        isWorkersLoading: true,
        success: false,
        error: false,
      };
    }

    case ACTION_CONSTANTS.GET_WORKERS_SUCCESS: {
      return {
        ...state,
        isWorkersLoading: false,
        activeWorkers: action.payload.activeWorkers,
        nonActiveWorkers: action.payload.nonActiveWorkers,
        workersSorted: SORTED_ASC,
        success: true,
      };
    }

    case ACTION_CONSTANTS.GET_WORKERS_FAILURE: {
      return {
        ...state,
        isWorkersLoading: false,
        error: action.payload.error,
      };
    }

    case ACTION_CONSTANTS.SORT_WORKERS_ASC: {
      let activeWorkers = DashboardHelper.sortWorkersByDate(
        state.activeWorkers,
        SORTED_ASC,
      );
      let nonActiveWorkers = DashboardHelper.sortWorkersByDate(
        state.nonActiveWorkers,
        SORTED_ASC,
      );
      return {
        ...state,
        activeWorkers,
        nonActiveWorkers,
        workersSorted: SORTED_ASC,
      };
    }

    case ACTION_CONSTANTS.SORT_WORKERS_DESC: {
      let activeWorkers = DashboardHelper.sortWorkersByDate(
        state.activeWorkers,
      );
      let nonActiveWorkers = DashboardHelper.sortWorkersByDate(
        state.nonActiveWorkers,
      );
      return {
        ...state,
        activeWorkers,
        nonActiveWorkers,
        workersSorted: SORTED_DESC,
      };
    }
    //#endregion ======= WORKER ACTIONS =======

    //#region ====== FEEDLOT REPORT ACTIONS ======
    case ACTION_CONSTANTS.POST_FEEDLOT_REPORT_REQUEST: {
      return {
        ...state,
        isFeedlotReportLoading: true,
        postReportError: false,
        postReportSuccess: false,
      };
    }

    case ACTION_CONSTANTS.POST_FEEDLOT_REPORT_SUCCESS: {
      return {
        ...state,
        isFeedlotReportLoading: false,
        postReportSuccess: true,
      };
    }

    case ACTION_CONSTANTS.POST_FEEDLOT_REPORT_FAILURE: {
      return {
        ...state,
        isFeedlotReportLoading: false,
        postReportError: action.payload.error,
      };
    }
    //#endregion ====== FEEDLOT REPORT ACTIONS ======

    //#region ====== FEEDLOT STATS ACTIONS ======
    case ACTION_CONSTANTS.GET_FEEDLOT_STATS:
    case ACTION_CONSTANTS.GET_FEEDLOT_STATS_REQUEST: {
      return {
        ...state,
        isFeedlotStatsLoading: true,
        getStatsError: false,
        getStatsSuccess: false,
      };
    }

    case ACTION_CONSTANTS.GET_FEEDLOT_STATS_SUCCESS: {
      return {
        ...state,
        performanceData: _.assign(action.payload.feedlotPerformance),
        isFeedlotStatsLoading: false,
        getStatsSuccess: true,
      };
    }

    case ACTION_CONSTANTS.GET_FEEDLOT_STATS_FAILURE: {
      return {
        ...state,
        isFeedlotStatsLoading: false,
        getStatsError: action.payload.error,
      };
    }
    //#endregion ====== FEEDLOT STATS ACTIONS ======

    //#region ====== BACKDATED SUBMISSIONS ACTIONS ======
    case ACTION_CONSTANTS.GET_BACKDATED_SUBMISSIONS:
    case ACTION_CONSTANTS.GET_BACKDATED_CUSTOM_SUBMISSIONS_REQUEST:
    case ACTION_CONSTANTS.GET_BACKDATED_SUBMISSIONS_REQUEST: {
      return {
        ...state,
        isBackdatedSubmissionLoading: true,
      };
    }

    case ACTION_CONSTANTS.GET_BACKDATED_CUSTOM_SUBMISSIONS_SUCCESS:
    case ACTION_CONSTANTS.GET_BACKDATED_SUBMISSIONS_SUCCESS: {
      return {
        ...state,
        backdatedSubmissions: action.payload,
        isBackdatedSubmissionLoading: false,
      };
    }

    case ACTION_CONSTANTS.GET_BACKDATED_CUSTOM_SUBMISSIONS_FAILURE:
    case ACTION_CONSTANTS.GET_BACKDATED_SUBMISSIONS_FAILURE: {
      return {
        ...state,
        isBackdatedSubmissionLoading: false,
        getCustomPerformanceError: action.payload.error,
        getCustomBackdateError: action.payload.error,
      };
    }

    case ACTION_CONSTANTS.GET_BACKDATED_CUSTOM_SUBMISSIONS_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        backdatedSubmissionsActivities: action.payload.data,
        custom_activities_instances: action.payload.custom_activities_instances,
      };
    }

    case ACTION_CONSTANTS.GET_BACKDATED_ACTIVITIES: {
      return {
        ...state,
        isBackdatedActivitiesLoading: true,
        submissionListError: false,
      };
    }

    case ACTION_CONSTANTS.GET_BACKDATED_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        backdatedActivities: action.payload,
        isBackdatedActivitiesLoading: false,
      };
    }

    case ACTION_CONSTANTS.GET_BACKDATED_ACTIVITIES_FAILURE: {
      return {
        ...state,
        isBackdatedActivitiesLoading: false,
      };
    }

    case ACTION_CONSTANTS.BACKDATED_FILTER_ACTIVITIES: {
      // console.log('BACKDATED_FILTER_ACTIVITIES', action.payload);
      return {
        ...state,
        backdatedFilter: action.payload,
      };
    }

    case ACTION_CONSTANTS.BACKDATED_SEARCH_ACTIVITIES: {
      // console.log('BACKDATED_SEARCH_ACTIVITIES', action.payload);
      return {
        ...state,
        backdatedQuery: action.payload,
      };
    }

    case ACTION_CONSTANTS.RESET_BACKDATED_PAYLOAD: {
      return {
        ...state,
        backdatedFilter: [],
        backdatedQuery: '',
        getCustomPerformanceError: '',
        getCustomBackdateError: '',
      };
    }
    //#endregion ====== BACKDATED SUBMISSIONS ACTIONS ======

    //#region ====== FEEDLOT CUSTOM PERFORMANCE ACTIONS ======
    case ACTION_CONSTANTS.GET_FEEDLOT_CUSTOM_PERFORMANCE_REQUEST: {
      return {
        ...state,
        isFeedlotCustomPerformanceLoading: true,
        getCustomPerformanceError: action.payload.error,
        getCustomPerformanceSuccess: false,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    }

    case ACTION_CONSTANTS.GET_FEEDLOT_CUSTOM_PERFORMANCE_SUCCESS: {
      return {
        ...state,
        isFeedlotCustomPerformanceLoading: false,
        feedlot_activites: action.payload.feedlot_activites || [],
        custom_activities_instances:
          action.payload.custom_activities_instances || [],
        performance: action.payload.performance,
        getCustomPerformanceSuccess: true,
        // startDate: action.payload.startDate,
        // endDate: action.payload.endDate,
      };
    }

    case ACTION_CONSTANTS.GET_FEEDLOT_CUSTOM_PERFORMANCE_FAILURE: {
      return {
        ...state,
        isFeedlotCustomPerformanceLoading: false,
        getCustomPerformanceError: action.payload.error,
      };
    }
    //#endregion ====== FEEDLOT CUSTOM PERFORMANCE ACTIONS ======

    case ACTION_CONSTANTS.GET_CUSTOM_BACKDATED_DURATION_SUCCESS: {
      return {
        ...state,
        backdatedStartDate: action.payload.startDate,
        backdatedEndDate: action.payload.endDate,
      };
    }

    //#region ====== FEEDLOT RANK ACTIONS ======
    case ACTION_CONSTANTS.GET_FEEDLOT_RANK_REQUEST: {
      return {
        ...state,
        rankLoading: rankStatus.LOADING,
      };
    }

    case ACTION_CONSTANTS.GET_FEEDLOT_RANK_SUCCESS: {
      const prevStatus = state.rankLoading;
      const selectedFeedlotId = getSelectedFeedlotId();
      return {
        ...state,
        rankLoading:
          action.payload.feedlotId !== selectedFeedlotId
            ? prevStatus
            : rankStatus.LOADED,
        rank: action.payload.rank,
      };
    }

    case ACTION_CONSTANTS.GET_FEEDLOT_RANK_FAILURE: {
      return {
        ...state,
        rankLoading: rankStatus.NOT_LOADED,
      };
    }
    //#endregion ====== FEEDLOT RANK ACTIONS ======

    case ACTION_CONSTANTS.SELECT_FEEDYARD_REQUESTED: {
      return {
        ...state,
        rankLoading: rankStatus.NOT_LOADED,
      };
    }

    case ACTION_CONSTANTS.HIDE_RANK_CARD: {
      // console.log('HIDE_RANK_CARD daashboard');
      return {
        ...state,
        showRankCard: false,
      };
    }

    case ACTION_CONSTANTS.RESET_RANK_CARD: {
      const prevStatus = state.rankLoading;
      return {
        ...state,
        rankLoading:
          prevStatus === rankStatus.LOADED ? rankStatus.NOT_LOADED : prevStatus,
      };
    }

    //#region ====== MOBILE NOTIFICATION ACTIONS ======
    case ACTION_CONSTANTS.SET_MOBILE_NOTIFICATION_COUNT: {
      return {
        ...state,
        mobileNotificationCount: action.payload.mobileNotificationCount,
      };
    }
    case ACTION_CONSTANTS.RESET_MOBILE_NOTIFICATION: {
      return {
        ...state,
        mobile_notifications: [],
        mobileNotificationCount: 0,
      };
    }

    case ACTION_CONSTANTS.GET_MOBILE_NOTIFICATION:
    case ACTION_CONSTANTS.GET_MOBILE_NOTIFICATION_REQUEST: {
      return {
        ...state,
        isMobileNotificationLoading: true,
        mobileNotificationSuccess: false,
        mobileNotificationError: false,
      };
    }

    case ACTION_CONSTANTS.GET_MOBILE_NOTIFICATION_LOAD_MORE: {
      return {
        ...state,
        isMobileNotificationLoadingMore: true,
      };
    }

    case ACTION_CONSTANTS.GET_MOBILE_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        mobile_notifications: action.payload.mobile_notifications,
        isMobileNotificationLoading: false,
        isMobileNotificationLoadingMore: false,
        mobileNotificationSuccess: true,
        noMoreRecords: action.payload.noMoreRecords || false,
      };
    }

    case ACTION_CONSTANTS.GET_MOBILE_NOTIFICATION_FAILURE: {
      return {
        ...state,
        isMobileNotificationLoading: false,
        isMobileNotificationLoadingMore: false,
        mobileNotificationError: action.payload.error,
      };
    }
    //#endregion ====== MOBILE NOTIFICATION ACTIONS ======

    //#region ====== MOBILE UPDATES ACTIONS ======
    case ACTION_CONSTANTS.RESET_MOBILE_UPDATE: {
      return {
        ...state,
        mobile_updates: [],
      };
    }

    case ACTION_CONSTANTS.GET_MOBILE_UPDATE:
    case ACTION_CONSTANTS.GET_MOBILE_UPDATE_REQUEST: {
      return {
        ...state,
        isMobileUpdatesLoading: true,
        mobileUpdatesSuccess: false,
        mobileUpdatesError: false,
      };
    }

    case ACTION_CONSTANTS.GET_MOBILE_UPDATE_LOAD_MORE: {
      return {
        ...state,
        isMobileUpdatesLoadingMore: true,
      };
    }

    case ACTION_CONSTANTS.GET_MOBILE_UPDATE_SUCCESS: {
      return {
        ...state,
        mobile_updates: action.payload.mobile_updates,
        isMobileUpdatesLoading: false,
        isMobileUpdatesLoadingMore: false,
        mobileUpdatesSuccess: true,
        noMoreUpdateRecords: action.payload.noMoreUpdateRecords || false,
      };
    }

    case ACTION_CONSTANTS.GET_MOBILE_UPDATE_FAILURE: {
      return {
        ...state,
        isMobileUpdatesLoading: false,
        isMobileUpdatesLoadingMore: false,
        mobileUpdatesError: action.payload.error,
      };
    }
    //#endregion ====== MOBILE UPDATES ACTIONS ======

    // region GET_MISSED_ACTIVITIES
    case ACTION_CONSTANTS.GET_MISSED_ACTIVITIES:
    case ACTION_CONSTANTS.GET_MISSED_ACTIVITIES_REQUEST: {
      return {
        ...state,
        isMissedActivtiesLoading: true,
        success: false,
        error: false,
      };
    }

    case ACTION_CONSTANTS.GET_MISSED_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        isMissedActivtiesLoading: false,
        missedActivities: action.payload,
        success: true,
      };
    }

    case ACTION_CONSTANTS.GET_MISSED_ACTIVITIES_FAILURE: {
      return {
        ...state,
        isMissedActivtiesLoading: false,
        error: action.payload.error,
      };
    }

    // region GET_PENDING_ACTIONS_COUNT
    case ACTION_CONSTANTS.GET_PENDING_ACTIONS_COUNT:
    case ACTION_CONSTANTS.GET_PENDING_ACTIONS_COUNT_REQUEST: {
      return {
        ...state,
        isPendingActionsLoading: true,
        success: false,
        error: false,
      };
    }

    case ACTION_CONSTANTS.GET_PENDING_ACTIONS_COUNT_SUCCESS: {
      return {
        ...state,
        isPendingActionsLoading: false,
        pendingActionsCount: action.payload.count,
        pendingActions: action.payload.pendingActions,
        searchedPendingActions: action.payload.pendingActions,
        success: true,
      };
    }

    case ACTION_CONSTANTS.GET_PENDING_ACTIONS_COUNT_FAILURE: {
      return {
        ...state,
        isPendingActionsLoading: false,
        error: action.payload.error,
      };
    }
    // end region

    // region GET_SEARCHED_PENDING_ACTIONS
    case ACTION_CONSTANTS.GET_SEARCHED_PENDING_ACTIONS_REQUEST: {
      return {
        ...state,
        pendingActionsQuery: action.payload,
        success: false,
        error: false,
      };
    }

    case ACTION_CONSTANTS.GET_SEARCHED_PENDING_ACTIONS_SUCCESS: {
      return {
        ...state,
        searchedPendingActions: action.payload.searchedPendingActions,
        success: true,
      };
    }

    case ACTION_CONSTANTS.GET_SEARCHED_PENDING_ACTIONS_FAILURE: {
      return {
        ...state,
        error: false,
      };
    }
    // end region

    case ACTION_CONSTANTS.SET_SERVER_ERROR: {
      // console.log('SET_SERVER_ERROR', action.payload);
      return {
        ...state,
        serverError: action.payload,
      };
    }

    default:
      return state;
  }
};
