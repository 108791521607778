import React from 'react';
import { connect } from 'react-redux';

import { globalHelper } from '@progressivebeef/shared/src/helpers';
import PBIcon from '../../../../components/PBIcon';

import './style.scss';

const AssignLogsListItem = (props) => {
  const { item } = props;
  const name =
    globalHelper.capitalize(item.profile.first_name) +
    ' ' +
    globalHelper.capitalize(item.profile.last_name);
  return (
    <div className="activity-filter-tag-list"
      onClick={() => {
        item.selected = !item.selected;
        props.updateSearchUsersListItem(item);
      }}>
      {name}
      {item.selected && (
      <div className="icon-check"> <PBIcon name={'check'} /> </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  const { searchableAssignLogUsers } = state.user;
  return { searchableAssignLogUsers };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignLogsListItem);
