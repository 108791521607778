import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import CountTag from '../CountTag';
import ActivityTag from '../ActivityTag';
import PBIcon from '../PBIcon';
import {
  setCurrentActivity,
  setCurrentInstance,
} from '@progressivebeef/shared/src/store/actions/activities';
import {
  userRoles,
  ACTIVITY_PERMISSIONS,
  ACTIVITY_FREQUENCY,
  PERFORMANCE_KEY_CUSTOM,
  DATE_FORMATS,
} from '@progressivebeef/shared/src/constants/AppConstants';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';
import { globalHelper } from '@progressivebeef/shared/src/helpers';
import ActivityPickerModal from '../ActivityPickerModal';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import localization from '@progressivebeef/shared/src/localization/i18n';
import ActivityHelper from '@progressivebeef/shared/src/helpers/ActivitiesHelper';
import { Progress } from 'reactstrap';
import './style.scss';

class PerformanceActivityCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
    };
  }

  onActivityCardPress = (currentActivity) => {
    const {
      user,
      percentageKey,
      setCurrentActivityRequest,
      section,
    } = this.props;

    setCurrentActivityRequest(currentActivity);

    if (Number(user.userRoleId) === userRoles.WORKER) {
      if (currentActivity.instance_based) {
        this.props.history.push(RouteConstants.ACTIVITY_INSTANCE, {
          currentActivity,
          percentageKey: percentageKey ? percentageKey : PERFORMANCE_KEY_CUSTOM,
          section,
        });
      } else {
        this.props.history.push(RouteConstants.ACTIVITY_SUBMISSIONS, {
          currentActivity,
        });
      }
    } else {
      if (
        /**
         * if activity permission_key is assigned worker, and activity is instance based and can not be scheduled
         * then navigate directly instance screen
         */
        currentActivity.instance_based &&
        currentActivity.permission_key ===
        ACTIVITY_PERMISSIONS.ASSIGNED_WORKER &&
        currentActivity.frequency_key === ACTIVITY_FREQUENCY.REQUIRED
      ) {
        this.props.history.push(RouteConstants.ACTIVITY_INSTANCE, {
          currentActivity,
          percentageKey: percentageKey ? percentageKey : PERFORMANCE_KEY_CUSTOM,
          section,
        });
      } else {
        this.setState({ modalVisible: true });
      }
    }
  };

  onPickerPress = () => {
    this.setState({ modalVisible: false });
  };

  render() {
    var {
      item,
      history,
      setCurrentActivityRequest,
      setCurrentInstanceRequest,
      user,
      percentageKey,
      section,
    } = this.props;
    var { modalVisible } = this.state;
    const value = ActivityHelper.getActivityPerformanceValue(
      item,
      percentageKey,
      section,
    );
    const title = item.locale
      ? item.locale[getLocale() === 'en' ? 1 : 2].title
      : localization[getLocale()].TITLE;
    let color = 'timely';
    if (value && Number(value) === 0) {
      color = 'missed';
    } else if (value && Number(value) < 100) {
      color = 'partially';
    }

    return (
      <>
        <ActivityPickerModal
          item={item}
          user={user}
          section={section}
          history={history}
          modalVisible={modalVisible}
          onPressEvent={this.onPickerPress}
          percentageKey={percentageKey ? percentageKey : PERFORMANCE_KEY_CUSTOM}
          setCurrentActivity={setCurrentActivityRequest}
          setCurrentInstance={setCurrentInstanceRequest}
        />
        <div onClick={() => this.onActivityCardPress(item)} className="performance-listing-box">
          <div>
            <div>
              <div className="performance-count-progress">
                <CountTag
                  count={item.instance_submission.numerator}
                  totalCount={item.instance_submission.denominator}
                />
                <div className={"performance-progress-bar " + color}>
                  <Progress color={'awesome'} value={Number(value ? value : 0)} />
                  <div className="num">
                    {value && value > 0 && value < 100
                      ? Number(value).toFixed(2)
                      : Number(value)}
                    %
                  </div>
                </div>
              </div>
              <div className="performance-log-box">
                <img
                  alt="description"
                  src={ActivityHelper.getTypeIcon(item)}
                />
                <div className="title">
                  {item.activity_number}
                </div>
              </div>
              <div className="performance-tile-box">
                {title}
              </div>

              <div>
                <div>
                  <div className="performance-list-of-detail">
                    <div className="performance-user-box">
                      <div className="icon">
                        <PBIcon name={'user'} />
                      </div>
                      <div className="no">
                        {item.users ? item.users.length : 0}
                      </div>
                    </div>
                    {globalHelper.renderIf(item.last_submitted_date)(
                      <div className="performance-date-box">
                        <div className="icon">
                          <PBIcon
                            name={'calendar-check'}
                          />
                        </div>
                        <div className="date">
                          {moment(item.last_submitted_date).format(
                            DATE_FORMATS.MM_DD_YY,
                          )}
                        </div>
                      </div>,
                    )}
                    {item.reminder_date ? (
                      <div className="performance-date-box stopwatch-edit-date">
                        <div className="icon">
                          <PBIcon
                            name={'stopwatch'}
                          />
                        </div>
                        <div className="date">
                          {moment(item.reminder_date).format(
                            DATE_FORMATS.MM_DD_YY,
                          )}
                        </div>
                      </div>
                    ) : (
                        globalHelper.renderIf(item.due_date)(
                          <div className="performance-date-box stopwatch-edit-date">
                            <div className="icon">
                              <PBIcon
                                name={'calendar-edit'}
                              />
                            </div>
                            <div className="date">
                              {moment(item.due_date).format(
                                DATE_FORMATS.MM_DD_YY,
                              )}
                            </div>
                          </div>,
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {item.activity_tags && (
            <div className="activity-tag-box">
              <ActivityTag
                history={history}
                tags={item.activity_tags}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state.authentication;

  return {
    user: user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentActivityRequest: (payload) =>
      dispatch(setCurrentActivity(payload)),
    setCurrentInstanceRequest: (payload) =>
      dispatch(setCurrentInstance(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PerformanceActivityCard);
