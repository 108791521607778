import { getLocale } from '../../services/storageService/GlobalData';
import { CUSTOM_PERFORMANCE, FEEDLOT_RANK } from '../../constants/AppConstants';
import { setLocalstorageItem } from '.';

class OptionsManager {
  //general getter
  getOptionValueByKey(key) {
    let value = localStorage.getItem(key);
    return value;
  }

  //#region getLoggedinUser
  getLoggedinUser() {
    let user = {
      token: this.getToken(),
      userId: parseInt(this.getLoggedinUserId(), 10),
      pinCode: this.getPinCode(), //need to encrypt
      userName: this.getLoggedinUserName(),
      userEmail: this.getLoggedinUserEmail(),
      userFullName: this.getLoggedinUserFullName(),
      userRoleId: parseInt(this.getLoggedinUserRoleId(), 10),
      userRoleName: this.getLoggedinUserRoleName(),
      userEmailAlerts: this.getLoginUserEmailAlerts(),
    };

    return user;
  }
  //#endregion

  //#region islogin
  isLogin() {
    const isLogin = localStorage.getItem('isLogin');
    return isLogin;
  }

  saveIsLogin(value) {
    let isLogin = {
      key: 'isLogin',
      value,
    };

    setLocalstorageItem(isLogin.key, isLogin.value);
    return true;
  }
  //#endregion

  //#region isLocked
  isLocked() {
    const isLocked = localStorage.getItem('isLocked');
    return isLocked;
  }

  saveIsLocked(value) {
    let isLocked = {
      key: 'isLocked',
      value,
    };

    setLocalstorageItem(isLocked.key, isLocked.value);
    return true;
  }
  //#endregion

  //#region Token
  getToken() {
    const token = localStorage.getItem('token');
    return token;
  }

  saveToken(value) {
    let token = {
      key: 'token',
      value,
    };

    setLocalstorageItem(token.key, token.value);
    return token.value;
  }
  //#endregion

  //#region loggedinUserId
  getLoggedinUserId() {
    const loggedinUserId = localStorage.getItem('loggedinUserId');
    return loggedinUserId;
  }

  saveLoggedinUserId(value) {
    let loggedinUserId = {
      key: 'loggedinUserId',
      value,
    };

    setLocalstorageItem(loggedinUserId.key, loggedinUserId.value);
    return loggedinUserId.value;
  }
  //#endregion

  //#region pinCode
  getPinCode() {
    const pinCode = localStorage.getItem('pinCode');
    return pinCode;
  }

  savePinCode(value) {
    let pinCode = {
      key: 'pinCode',
      value: value || '',
    };

    setLocalstorageItem(pinCode.key, pinCode.value);
    return pinCode.value;
  }
  //#endregion

  //#region loggedinUserName
  getLoggedinUserName() {
    const loggedinUserName = localStorage.getItem('loggedinUserName');
    return loggedinUserName;
  }

  saveLoggedinUserName(value) {
    let loggedinUserName = {
      key: 'loggedinUserName',
      value,
    };

    setLocalstorageItem(loggedinUserName.key, loggedinUserName.value);
    return loggedinUserName.value;
  }

  //#region loggedinUserEmailAlerts
  getLoginUserEmailAlerts() {
    const emailAlerts = localStorage.getItem('loggedinUserEmailAlerts');
    return emailAlerts;
  }

  saveLoggedinUserEmailAlerts(value) {
    let loggedinUserEmailAlerts = {
      key: 'loggedinUserEmailAlerts',
      value,
    };

    setLocalstorageItem(
      loggedinUserEmailAlerts.key,
      loggedinUserEmailAlerts.value,
    );
    return loggedinUserEmailAlerts.value;
  }
  //#endregion

  //#region loggedinUserEmail
  getLoggedinUserEmail() {
    const loggedinUserEmail = localStorage.getItem('loggedinUserEmail');
    return loggedinUserEmail;
  }

  saveLoggedinUserEmail(value) {
    let loggedinUserEmail = {
      key: 'loggedinUserEmail',
      value: value || '',
    };

    setLocalstorageItem(loggedinUserEmail.key, loggedinUserEmail.value);
    return loggedinUserEmail.value;
  }
  //#endregion

  //#region loggedinUserFullName
  getLoggedinUserFullName() {
    const loggedinUserFullName = localStorage.getItem('loggedinUserFullName');
    return loggedinUserFullName;
  }

  saveLoggedinUserFullName(value) {
    let loggedinUserFullName = {
      key: 'loggedinUserFullName',
      value,
    };

    setLocalstorageItem(
      loggedinUserFullName.key,
      loggedinUserFullName.value,
      true,
    );
    return loggedinUserFullName.value;
  }
  //#endregion

  //#region loggedinUserRoleId
  getLoggedinUserRoleId() {
    const loggedinUserRoleId = localStorage.getItem('loggedinUserRoleId');
    return loggedinUserRoleId;
  }

  saveLoggedinUserRoleId(value) {
    let loggedinUserRoleId = {
      key: 'loggedinUserRoleId',
      value,
    };

    setLocalstorageItem(loggedinUserRoleId.key, loggedinUserRoleId.value);
    return loggedinUserRoleId.value;
  }
  //#endregion

  //#region loggedinUserRoleName
  getLoggedinUserRoleName() {
    const loggedinUserRoleName = localStorage.getItem('loggedinUserRoleName');
    return loggedinUserRoleName;
  }

  saveLoggedinUserRoleName(value) {
    let loggedinUserRoleName = {
      key: 'loggedinUserRoleName',
      value,
    };

    setLocalstorageItem(loggedinUserRoleName.key, loggedinUserRoleName.value);
    return loggedinUserRoleName.value;
  }

  //#endregion

  //#region lastNotificationSyncTime
  saveLastNotificationSyncTime(value) {
    let lastNotificationSyncTime = {
      key: 'lastNotifcationSyncTime',
      value,
    };

    setLocalstorageItem(
      lastNotificationSyncTime.key,
      lastNotificationSyncTime.value,
    );
    return lastNotificationSyncTime.value;
  }

  getLastNotifcationSyncTime() {
    const time = localStorage.getItem('lastNotifcationSyncTime');
    return time ? time : 0;
  }
  //#endregion

  //#region save & get notification count
  saveNotifcationCount(value) {
    let notifcationCount = {
      key: 'notificationCount',
      value,
    };

    setLocalstorageItem(notifcationCount.key, notifcationCount.value);
    return notifcationCount.value;
  }

  getNotifcationCount() {
    const count = localStorage.getItem('notificationCount');
    return count ? count : 0;
  }
  //#endregion

  //#region save & get language set
  saveLanguage() {
    let language = {
      key: 'language',
      value: getLocale(),
    };
    setLocalstorageItem(language.key, language.value);
    return language.value;
  }

  getLanguage() {
    const language = localStorage.getItem('language');
    return language;
  }
  //#endregion

  saveFeedlotRank(count, ranks) {
    try {
      let rank_count = {
        key: FEEDLOT_RANK.COUNT,
        value: String(count),
      };
      let rank_position = {
        key: FEEDLOT_RANK.POSITION,
        value: String(ranks && ranks.length > 0 ? ranks[0].rank.position : 0),
      };
      let rank_performance = {
        key: FEEDLOT_RANK.PERFORMANCE,
        value: String(
          ranks && ranks.length > 0 ? ranks[0].rank.performance : 0,
        ),
      };

      setLocalstorageItem(rank_count.key, rank_count.value);
      setLocalstorageItem(rank_position.key, rank_position.value);
      setLocalstorageItem(rank_performance.key, rank_performance.value);

      return true;
    } catch (e) {
      console.log('saveFeedlotRanksaveFeedlotRank', e);
      return false;
    }
  }

  getFeedlotRank() {
    var rankObj = {
      count: 0,
      position: 0,
      performance: 0,
    };

    try {
      const count = localStorage.getItem(FEEDLOT_RANK.COUNT);
      rankObj.count = count ? count : 0;

      const position = localStorage.getItem(FEEDLOT_RANK.POSITION);
      rankObj.position = position ? position : 0;

      const performance = localStorage.getItem(FEEDLOT_RANK.PERFORMANCE);
      rankObj.performance = performance ? performance : 0;

      return rankObj;
    } catch (e) {
      return rankObj;
    }
  }

  //#region coachmark status
  setFirstLogin(data) {
    setLocalstorageItem('isFirstLogin', data.value);
    return data.value === 'true';
  }

  getFirstLogin() {
    const data = localStorage.getItem('isFirstLogin');
    return data === 'true';
  }
  //#endregion

  //#region slectedFeedyard
  setSelectedFeedyard(value) {
    try {
      setLocalstorageItem('selectedFeedyard', value);
      return selectedFeedyard.value;
    } catch (e) {
      return false;
    }
  }

  getSelectedFeedyard() {
    const data = localStorage.getItem('selectedFeedyard');
    return data;
  }
  //#endregion

  saveCustomPerformanceFilter(performance, start, end, feedlot_activites) {
    try {
      setLocalstorageItem(CUSTOM_PERFORMANCE.PERFORMANCE, String(performance));
      setLocalstorageItem(CUSTOM_PERFORMANCE.START_DATE, String(start));
      setLocalstorageItem(CUSTOM_PERFORMANCE.END_DATE, String(end));
      setLocalstorageItem(
        CUSTOM_PERFORMANCE.ACTIVITIES,
        JSON.stringify(feedlot_activites),
      );

      return true;
    } catch (e) {
      console.log('saveCustomPerformanceFilter fail', e);
      return false;
    }
  }

  getCustomPerformanceFilter() {
    let customPerformanceObj = {};

    try {
      const performance = localStorage.getItem(CUSTOM_PERFORMANCE.PERFORMANCE);
      customPerformanceObj.performance = performance ? performance : 0;

      const startDate = localStorage.getItem(CUSTOM_PERFORMANCE.START_DATE);
      customPerformanceObj.startDate = startDate ? startDate : null;

      const endDate = localStorage.getItem(CUSTOM_PERFORMANCE.END_DATE);
      customPerformanceObj.endDate = endDate ? endDate : null;

      const activities = localStorage.getItem(CUSTOM_PERFORMANCE.ACTIVITIES);
      customPerformanceObj.activities = activities
        ? JSON.parse(activities)
        : [];

      return customPerformanceObj;
    } catch (e) {
      console.log('getCustomPerformanceFilter fail', e);
      return customPerformanceObj;
    }
  }
}

export default new OptionsManager();
