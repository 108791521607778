import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './style.scss';

// constants
import localization from '@progressivebeef/shared/src/localization/i18n';
import {
  getLocale,
  getUserRole,
} from '@progressivebeef/shared/src/services/storageService/GlobalData';

// constants
import {
  DATE_FORMATS,
  SUBMISSION_STATUS,
  userRoles,
} from '@progressivebeef/shared/src/constants/AppConstants';

// styles
import AssetConstants from '@progressivebeef/shared/src/constants/AssetConstants';
import ActivitiesHelper from '@progressivebeef/shared/src/helpers/ActivitiesHelper';

const SubmissionCard = (props) => {
  const getTitle = (status, firstName, lastName) => {
    if (status === SUBMISSION_STATUS.MISSED) {
      return (
        <div>{`${localization[getLocale()].NOT_SUBMITTED
          }`}</div>
      );
    } else if (status === SUBMISSION_STATUS.REMOVED) {
      return props.backdated ? null : (
        <div>{`${firstName} ${lastName}`}</div>
      );
    } else {
      return props.backdated ? null : (
        <div>{`${firstName} ${lastName}`}</div>
      );
    }
  };

  const getDate = (item) => {
    const status = item.status;
    if (status === SUBMISSION_STATUS.REMOVED) {
      return (
        <div className="date">
          {moment(item.submitted_date).format(DATE_FORMATS.MM_DD_YY_H_MM_A)}
        </div>
      );
    } else if (status === SUBMISSION_STATUS.MISSED) {
      return (
        <div className="date">
          {moment(item.miss_date).format(DATE_FORMATS.MM_DD_YYYY)}
        </div>
      );
    } else if (status === SUBMISSION_STATUS.REJECTED) {
      return (
        <div className="date">
          {moment(item.submitted_date).format(DATE_FORMATS.MM_DD_YYYY)}
        </div>
      );
    } else {
      if (
        (getUserRole() === userRoles.PROGRAM_MANAGER ||
          getUserRole() === userRoles.FEEDLOT_MANAGER ||
          getUserRole() === userRoles.SUPERVISOR) &&
        status !== SUBMISSION_STATUS.PENDING
      ) {
        return (
          <>
            <div className="due-date">
              <div className="label">
                {localization[getLocale()].DUE_DATE}
              </div>
              <div className="date">
                {moment(item.submitted_date).format(DATE_FORMATS.MM_DD_YYYY)}
              </div>
            </div>
            <div className="submitted-date">
              <div className="label">
                {localization[getLocale()].SUBMITTED_DATE}
              </div>
              <div className="date">
                {moment(item.actual_submission_date).format(
                  DATE_FORMATS.MM_DD_YYYY_LT,
                )}
              </div>
            </div>
          </>
        );
      } else {
        return (
          <div>
            <div className="date">
              {moment(item.submitted_date).format(DATE_FORMATS.MM_DD_YYYY)}
            </div>
          </div>
        );
      }
    }
  };

  const { item, onPress } = props;
  return (
    <div className="submision-submmit-box" onClick={onPress}>
      <div className="left-col">
        <div className={`main-title ${item.status === SUBMISSION_STATUS.REMOVED ? "removed-user" : ""}`}>{getTitle(item.status, item.first_name, item.last_name)}</div>
        <div className="date-box">{getDate(item)}</div>
      </div>
      <div className="right-col">
        <div className="icon-box">
          <img
            alt="description"
            src={AssetConstants[ActivitiesHelper.getIcon(item.status)]}
          />
          <div className="label">
            {ActivitiesHelper.getStatus(item.status)}
          </div>
        </div>

      </div>

    </div>
  );
};

SubmissionCard.propTypes = {
  item: PropTypes.object,
  onPress: PropTypes.func,
};

export default SubmissionCard;
