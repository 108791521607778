import React, { Component } from 'react';
import BasicDatePicker from '../../../../../components/BasicDatePicker';
import Label from './label';
import getLanguageKey from '@progressivebeef/shared/src/services/languageService/index';
import moment from 'moment';
import { DATE_FORMATS } from '@progressivebeef/shared/src/constants/AppConstants';
import ActivitiesHelper from '@progressivebeef/shared/src/helpers/ActivitiesHelper';

export default class DateField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPicker: false,
    };
  }

  componentDidMount() {
    const { field, data, form } = this.props;

    form.setFieldValue(data.group_id.toString(), field.value || new Date());
  }

  render() {
    const { field, data, form, violated, disabled } = this.props;
    const locale = data.label.locale[getLanguageKey()];

    return (
      <>
        <div
          className={`add-submission-view-info-box col-flex${
            data.violated ? ' violated' : ''
          }`}>
          <Label locale={locale} violated={violated} isFlex />

          <div className={`date-box`}>
            <BasicDatePicker
              disabled={disabled}
              value={`${moment(field.value || new Date()).format(
                DATE_FORMATS.MM_DD_YYYY,
              )}`}
              onChange={(value) => {
                form.setFieldValue(
                  data.group_id.toString(),
                  value || field.value,
                );
              }}
              onPress={(value) => {
                this.setState({
                  submissionDate: value || field.value,
                  showPicker: false,
                });
                form.setFieldValue(
                  data.group_id.toString(),
                  value || field.value,
                );
              }}
            />
          </div>
        </div>
        {ActivitiesHelper.shouldShowInstruction(locale) && (
          <div className="instruction-label add-submission-view-info-box-instruction instruction-fix-margin">
            {locale.instruction}
          </div>
        )}
      </>
    );
  }
}
