import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';

//style
import './style.scss';
import PBIcon from '../PBIcon';
import MorePickerModal from '../MorePickerModal';
import { setBottomBarTab } from '@progressivebeef/shared/src/store/actions/view';
import {
  BOTTOM_BAR_TABS,
  userRoles,
} from '@progressivebeef/shared/src/constants/AppConstants';

import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

const tabs = [{
  route: "/Dashboard",
  icon: 'tachometer-fast',
  label: "DASHBOARD"
}, {
  route: "/ProgramManual",
  icon: 'file-pdf',
  label: "SOP"
}, {
  route: "/Users",
  icon: 'users',
  label: "USERS"
}, {
  route: "/Activities",
  icon: 'inbox-in',
  label: "ACTIVITIES"
}, {
  route: "/More",
  icon: 'bars',
  label: "MORE"
}]

const worker_tabs = [{
  route: "/Dashboard",
  icon: 'tachometer-fast',
  label: "DASHBOARD"
}, {
  route: "/ProgramManual",
  icon: 'file-pdf',
  label: "SOP"
}, {
  route: "/Activities",
  icon: 'inbox-in',
  label: "ACTIVITIES"
}, {
  route: "/More",
  icon: 'bars',
  label: "MORE"
}]

class BottomTabBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
    };
  }

  onPickerPress = () => {
    this.setState({ modalVisible: false });
  };

  render() {
    var { modalVisible } = this.state;
    const { history, bottomTab, user } = this.props;

    let tabToShow =
      Number(user.userRoleId) === userRoles.WORKER ? worker_tabs : tabs;

    return (
      <>
        <nav className="navbar navbar-light bottom-tab-nav" role="navigation">
          <Nav className="w-100">
            <div className=" d-flex flex-row justify-content-around w-100">
              {tabToShow.map((tab, index) =>
                tab.label === 'MORE' ? (
                  <NavItem key={`tab-${index}`}>
                    <div
                      onClick={() => {
                        this.setState({
                          modalVisible: true,
                        });
                        this.props.setBottomBarTab(BOTTOM_BAR_TABS.MORE);
                      }}
                      className={`nav-link bottom-nav-link${tab.label == bottomTab ? ' active' : ''
                        }`}
                    >
                      <div className="row d-flex flex-column justify-content-center align-items-center">
                        <PBIcon name={tab.icon} />
                        <div className="label-title">{localization[getLocale()][tab.label]}</div>
                      </div>
                    </div>
                  </NavItem>
                ) : (
                    <NavItem key={`tab-${index}`}>
                      <NavLink
                        style={{ textDecoration: 'none' }}
                        to={tab.route}
                        className="nav-link bottom-nav-link"
                        isActive={() => {
                          return tab.label == bottomTab ? true : false;
                        }}
                        onClick={() => {
                          this.props.setBottomBarTab(tab.label);
                        }}
                      >
                        <div className="row d-flex flex-column justify-content-center align-items-center">
                          <PBIcon name={tab.icon} />
                          <div className="label-title">{localization[getLocale()][tab.label]}</div>
                        </div>
                      </NavLink>
                    </NavItem>
                  )
              )}
            </div>
          </Nav>
        </nav>
        <MorePickerModal
          modalVisible={modalVisible}
          onPressEvent={this.onPickerPress}
          history={history}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setBottomBarTab: (payload) => dispatch(setBottomBarTab(payload)),
  };
};

const mapStateToProps = (state) => {
  const { bottomTab } = state.view;
  const { user } = state.authentication;

  return {
    bottomTab,
    user: user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BottomTabBar);
