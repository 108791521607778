import React, { Component } from 'react';
import _ from 'lodash';

import Label from './label';
import PickerComponent from '../../../../../components/Picker';
import getLanguageKey from '@progressivebeef/shared/src/services/languageService';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import localization from '@progressivebeef/shared/src/localization/i18n';
import ActivitiesHelper from '@progressivebeef/shared/src/helpers/ActivitiesHelper';

export default class SingleSelectField extends Component {
  constructor(props) {
    super(props);
    const obj = _.find(props.form.values[props.name], ['value', true]);
    this.state = {
      selectedOption: obj ? obj.id : '',
      showPicker: false,
    };
  }

  setFieldValue = (id) => {
    const { form, name, replace, data } = this.props;
    const { values } = form;
    const option = _.find(values[name], ['id', id]);
    const index = _.findIndex(values[name], ['id', id]);
    option.value = true;
    replace(index, option);
    form.setFieldTouched(data.group_id.toString(), true, true);
    this.setState({ selectedOption: id });
  };

  render() {
    const { data, form, name, disabled } = this.props;
    const locale = data.label.locale[getLanguageKey()];

    return (
      <>
        <div
          className={`add-submission-view-info-box col-flex${
            data.violated ? ' violated' : ''
          }`}>
          <Label
            locale={locale}
            customLabelStyle={{ paddingVertical: 15 }}
            violated={data.violated}
            isFlex
          />
          {disabled && (
            <div className="singleSelect">
              {ActivitiesHelper.getOptionLabel(
                form.values[name],
                this.state.selectedOption,
              )}
            </div>
          )}
          {!disabled && (
            <PickerComponent
              selectedValue={this.state.selectedOption}
              onValueChange={this.setFieldValue}
              data={ActivitiesHelper.parseDataForPicker(
                form.values[name],
                'SubmissionSingleSelect',
              )}
              placeholder={localization[getLocale()].PLEASE_SELECT}
            />
          )}
        </div>
        {ActivitiesHelper.shouldShowInstruction(locale) && (
          <div className="instruction-label add-submission-view-info-box-instruction instruction-fix-margin">
            {locale.instruction}
          </div>
        )}
      </>
    );
  }
}
