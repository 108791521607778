import React from 'react';
import PropTypes from 'prop-types';

import ReactSpeedometer from 'react-d3-speedometer';
import { globalHelper } from '@progressivebeef/shared/src/helpers';
import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import { userRoles } from '@progressivebeef/shared/src/constants/AppConstants';
import { Spinner } from 'reactstrap';

const SpeedometerCard = (props) => {
  const {
    heading,
    date,
    value,
    onPress,
    loading,
    onDatePress,
    user,
    isFeedlotStatsLoading,
    isCustomCard,
    noActivities,
  } = props;

  const renderBody = () => {
    if (isFeedlotStatsLoading) {
      return <div />;
    } else {
      return (
        <div
          className="performance-card"
          style={{ backgroundColor: 'rgb(88,44,131)' }}
        >
          <div className="info-box">
            <div className="title">
              {Number(user.userRoleId) === userRoles.WORKER
                ? localization[getLocale()].WORKER_PERFORMANCE
                : localization[getLocale()].FEEDLOT_PERFORMANCE}
            </div>
            {onDatePress ? (
              <div
                style={{ zIndex: 1000 }}
                onClick={(event) => {
                  onDatePress();
                  event.stopPropagation();
                }}
              >
                <div className="day">{heading}</div>
                <div className="date">{date && date}</div>
              </div>
            ) : (
              <div>
                <div className="day">{heading}</div>
                <div className="date">{date}</div>
              </div>
            )}
          </div>
          {globalHelper.renderIf(loading)(
            <div>
              <Spinner color={'#fff'} />
            </div>
          )}
          {globalHelper.renderIf(!loading && heading !== 'This Week')(
            <div className="meter-box">
              <div className="value-box">
                {!isCustomCard || value || value == 0
                  ? value > 0
                    ? value && value.toFixed(2) + '%'
                    : noActivities === 0 &&
                      Number(user.userRoleId) === userRoles.WORKER
                    ? localization[getLocale()].NO_ACTIVITES_ASSIGNED
                    : value
                    ? value < 0
                      ? 'N/A'
                      : value && value.toFixed(2) + '%'
                    : '0%'
                  : ''}
              </div>
              <div className="text-right">
                <ReactSpeedometer
                  ringWidth={8}
                  minValue={0}
                  width={180}
                  height={120}
                  value={Number(
                    value ? (value < 0 ? 0 : value && value.toFixed(2)) : 0
                  )}
                  needleHeightRatio={0.7}
                  labelFontSize={'1'}
                  maxValue={100}
                  needleColor={'#fff'}
                  maxSegmentLabels={0}
                  customSegmentStops={[0, 90, 97, 100]}
                  segmentColors={[
                    'rgb(255, 0, 0)',
                    'rgba(255, 218, 17, 0.89)',
                    'rgb(41, 133, 0)',
                  ]}
                  segments={3}
                />
              </div>
            </div>
          )}
        </div>
      );
    }
  };

  return <div onClick={onPress}>{renderBody()}</div>;
};

SpeedometerCard.propTypes = {
  heading: PropTypes.string,
  date: PropTypes.any,
  value: PropTypes.number,
  onPress: PropTypes.func,
  onDatePress: PropTypes.func,
};

export default SpeedometerCard;
