import React, { Component } from 'react';

import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

import AppContainer from '../AppContainer';
import PBIcon from '../PBIcon';
import './style.scss';

class VideoPlayer extends Component {
  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          name={'chevron-left'}
          onPress={() => this.props.history.goBack()}
        />
      </div>
    );
  };

  render() {
    const { history } = this.props;

    return (
      <AppContainer
        iconHeader
        history={history}
        IconLeftHeader={this.renderLeftHeader}
        noncollapsible
        title={localization[getLocale()].NAVIGATION_VIDEOS}
      >
        <div className="training-video-play-screen">
          <video
            className="x-media videoPlayer"
            id="video"
            controls={true}
            autoplay="autoplay"
          >
            <source src={this.props.location.state.file} />
            {getLocale() == 'en' ? (
              <track
                label="English"
                kind="subtitles"
                srclang="en"
                src={this.props.location.state.english}
                default
              ></track>
            ) : (
              <track
                label="Espanol"
                kind="subtitles"
                srclang="es"
                src={this.props.location.state.spanish}
                default
              ></track>
            )}
          </video>
        </div>
      </AppContainer>
    );
  }
}

export default VideoPlayer;
