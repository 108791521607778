import moment from 'moment';
import ACTION_CONSTANTS from '../../constants/actionConstants';

export const initialState = {
  performanceStartDate: new Date(moment().subtract(1, 'd')),
  performanceEndDate: new Date(moment().subtract(1, 'd')),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_CONSTANTS.PERFORMANCE_START_DATE: {
      return {
        ...state,
        performanceStartDate: action.payload,
      };
    }
    case ACTION_CONSTANTS.PERFORMANCE_END_DATE: {
      return {
        ...state,
        performanceEndDate: action.payload,
      };
    }

    case ACTION_CONSTANTS.SELECT_FEEDYARD_REQUESTED: {
      return {
        ...state,
        performanceStartDate: new Date(moment().subtract(1, 'd')),
        performanceEndDate: new Date(moment().subtract(1, 'd')),
      };
    }

    default:
      return state;
  }
};
