import ACTION_CONSTANTS from '../../constants/actionConstants';
import { request, success, failure } from './index';

export function getActivitiesRequest(payload) {
  return request(ACTION_CONSTANTS.GET_ACTIVITIES_REQUEST, payload);
}

export function getActivities(payload) {
  return request(ACTION_CONSTANTS.GET_ACTIVITIES, payload);
}

export function getActivitiesSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_ACTIVITIES_SUCCESS, payload);
}

export function getActivitiesFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_ACTIVITIES_FAILURE, payload);
}

export function getActivityByIdRequest(payload) {
  return request(ACTION_CONSTANTS.GET_ACTIVITY_BY_ID_REQUEST, payload);
}

export function getActivityByIdSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_ACTIVITY_BY_ID_SUCCESS, payload);
}

export function getActivityByIdFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_ACTIVITY_BY_ID_FAILURE, payload);
}

export function getSubmissionByIdRequest(payload) {
  return request(ACTION_CONSTANTS.GET_SUBMISSION_BY_ID_REQUEST, payload);
}

export function getSubmissionByIdSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_SUBMISSION_BY_ID_SUCCESS, payload);
}

export function getSubmissionByIdFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_SUBMISSION_BY_ID_FAILURE, payload);
}

export function getActivitiesTags(payload) {
  return success(ACTION_CONSTANTS.GET_ACTIVITIES_TAG, payload);
}

export function sortActivities(payload) {
  return success(ACTION_CONSTANTS.SORT_ACTIVITIES, payload);
}

export function sortActivitiesSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_SORTED_ACTIVITIES_SUCCESS, payload);
}

export function getDeprecatedActivitiesRequest(payload) {
  return request(ACTION_CONSTANTS.GET_DEPRECATED_ACTIVITIES_REQUEST, payload);
}

export function getDeprecatedActivities(payload) {
  return request(ACTION_CONSTANTS.GET_DEPRECATED_ACTIVITIES, payload);
}

export function getDeprecatedActivitiesSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_DEPRECATED_ACTIVITIES_SUCCESS, payload);
}

export function getDeprecatedActivitiesFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_DEPRECATED_ACTIVITIES_FAILURE, payload);
}

export function getTagsFailure() {
  return success(ACTION_CONSTANTS.GET_ACTIVITIES_TAG_FAILURE, {});
}

export function setCurrentActivity(payload) {
  return success(ACTION_CONSTANTS.SET_CURRENT_ACTIVITY, payload);
}

export function filterActivities(payload) {
  return request(ACTION_CONSTANTS.FILTER_ACTIVITIES, payload);
}

export function searchActivities(payload) {
  return request(ACTION_CONSTANTS.SEARCH_ACTIVITIES, payload);
}

export function getActivityInstancesRequest(payload) {
  return request(ACTION_CONSTANTS.GET_ACTIVITY_INSTANCES_REQUEST, payload);
}

export function getActivityInstancesSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_ACTIVITY_INSTANCES_SUCCESS, payload);
}

export function getActivityInstancesFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_ACTIVITY_INSTANCES_FAILURE, payload);
}

export function deleteActivityInstancesRequest(payload) {
  return request(ACTION_CONSTANTS.DELETE_ACTIVITY_INSTANCES_REQUEST, payload);
}

export function deleteActivityInstancesSuccess(payload) {
  return success(ACTION_CONSTANTS.DELETE_ACTIVITY_INSTANCES_SUCCESS, payload);
}

export function deleteActivityInstancesFailure(payload) {
  return failure(ACTION_CONSTANTS.DELETE_ACTIVITY_INSTANCES_FAILURE, payload);
}

export function addActivityInstancesRequest(payload) {
  return request(ACTION_CONSTANTS.ADD_ACTIVITY_INSTANCES_REQUEST, payload);
}

export function addActivityInstancesSuccess(payload) {
  return success(ACTION_CONSTANTS.ADD_ACTIVITY_INSTANCES_SUCCESS, payload);
}

export function addActivityInstancesFailure(payload) {
  return failure(ACTION_CONSTANTS.ADD_ACTIVITY_INSTANCES_FAILURE, payload);
}

export function getActivitySubmissionsRequest(payload) {
  return request(ACTION_CONSTANTS.GET_ACTIVITY_SUBMISSIONS_REQUEST, payload);
}

export function getActivitySubmissionsSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_ACTIVITY_SUBMISSIONS_SUCCESS, payload);
}

export function getActivitySubmissionsFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_ACTIVITY_SUBMISSIONS_FAILURE, payload);
}

export function getBackdatedActivitySubmissionsRequest(payload) {
  return request(
    ACTION_CONSTANTS.GET_BACKDATED_ACTIVITY_SUBMISSIONS_REQUEST,
    payload,
  );
}

export function getBackdatedActivitySubmissionsSuccess(payload) {
  return success(
    ACTION_CONSTANTS.GET_BACKDATED_ACTIVITY_SUBMISSIONS_SUCCESS,
    payload,
  );
}

export function getBackdatedActivitySubmissionsFailure(payload) {
  return failure(
    ACTION_CONSTANTS.GET_BACKDATED_ACTIVITY_SUBMISSIONS_FAILURE,
    payload,
  );
}

export function assignActivityRequest(payload) {
  return request(ACTION_CONSTANTS.ASSIGN_ACTIVITY_REQUEST, payload);
}

export function assignActivitySuccess(payload = {}) {
  return success(ACTION_CONSTANTS.ASSIGN_ACTIVITY_SUCCESS, payload);
}

export function assignActivityFailure(payload) {
  return failure(ACTION_CONSTANTS.ASSIGN_ACTIVITY_FAILURE, payload);
}

export function setActivityReminderRequest(payload) {
  return request(ACTION_CONSTANTS.SET_ACTIVITY_REMINDER_REQUEST, payload);
}

export function setActivityReminderSuccess(payload) {
  return success(ACTION_CONSTANTS.SET_ACTIVITY_REMINDER_SUCCESS, payload);
}

export function setActivityReminderFailure(payload) {
  return failure(ACTION_CONSTANTS.SET_ACTIVITY_REMINDER_FAILURE, payload);
}

export function deleteActivityReminderRequest(payload) {
  return request(ACTION_CONSTANTS.DELETE_ACTIVITY_REMINDER_REQUEST, payload);
}

export function deleteActivityReminderSuccess(payload) {
  return success(ACTION_CONSTANTS.DELETE_ACTIVITY_REMINDER_SUCCESS, payload);
}

export function deleteActivityReminderFailure(payload) {
  return failure(ACTION_CONSTANTS.DELETE_ACTIVITY_REMINDER_FAILURE, payload);
}

export function setActivityScheduleRequest(payload) {
  return request(ACTION_CONSTANTS.SET_ACTIVITY_SCHEDULE_REQUEST, payload);
}

export function setActivityScheduleSuccess(payload) {
  return success(ACTION_CONSTANTS.SET_ACTIVITY_SCHEDULE_SUCCESS, payload);
}

export function setActivityScheduleFailure(payload) {
  return failure(ACTION_CONSTANTS.SET_ACTIVITY_SCHEDULE_FAILURE, payload);
}

export function postActivityPrintRequest(payload) {
  return request(ACTION_CONSTANTS.POST_ACTIVITY_PRINT_REQUEST, payload);
}

export function postActivityPrintSuccess(payload) {
  return success(ACTION_CONSTANTS.POST_ACTIVITY_PRINT_SUCCESS, payload);
}

export function postActivityPrintFailure(payload) {
  return failure(ACTION_CONSTANTS.POST_ACTIVITY_PRINT_FAILURE, payload);
}

export function postFormActivityPrintRequest(payload) {
  return request(ACTION_CONSTANTS.POST_FORM_ACTIVITY_PRINT_REQUEST, payload);
}

export function postFormActivityPrintSuccess(payload) {
  return success(ACTION_CONSTANTS.POST_FORM_ACTIVITY_PRINT_SUCCESS, payload);
}

export function postFormActivityPrintFailure(payload) {
  return failure(ACTION_CONSTANTS.POST_FORM_ACTIVITY_PRINT_FAILURE, payload);
}

export function setCurrentInstance(payload) {
  return success(ACTION_CONSTANTS.SET_CURRENT_INSTANCE, payload);
}

export function postActivitySubmissionRequest(payload) {
  return request(ACTION_CONSTANTS.POST_ACTIVITY_SUBMISSION_REQUEST, payload);
}

export function postActivitySubmissionSuccess(payload) {
  return success(ACTION_CONSTANTS.POST_ACTIVITY_SUBMISSION_SUCCESS, payload);
}

export function postActivitySubmissionFailure(payload) {
  return failure(ACTION_CONSTANTS.POST_ACTIVITY_SUBMISSION_FAILURE, payload);
}

export function postActivityAttachmentRequest(payload) {
  return request(ACTION_CONSTANTS.POST_ACTIVITY_ATTACHMENT_REQUEST, payload);
}

export function postActivityAttachmentSuccess(payload) {
  return success(ACTION_CONSTANTS.POST_ACTIVITY_ATTACHMENT_SUCCESS, payload);
}

export function postActivityAttachmentFailure(payload) {
  return failure(ACTION_CONSTANTS.POST_ACTIVITY_ATTACHMENT_FAILURE, payload);
}

export function removeActivitySubmissionRequest(payload) {
  return request(ACTION_CONSTANTS.REMOVE_ACTIVITY_SUBMISSION_REQUEST, payload);
}

export function removeActivitySubmissionSuccess(payload) {
  return success(ACTION_CONSTANTS.REMOVE_ACTIVITY_SUBMISSION_SUCCESS, payload);
}

export function removeActivitySubmissionFailure(payload) {
  return failure(ACTION_CONSTANTS.REMOVE_ACTIVITY_SUBMISSION_FAILURE, payload);
}

export function resetActivitiesProps(payload) {
  return request(ACTION_CONSTANTS.RESET_ACTIVITIES_PAYLOAD, payload);
}

export function clearPerformanceProps(payload) {
  return request(ACTION_CONSTANTS.RESET_PERFORMANCE_PAYLOAD, payload);
}

export function setSelectedPerformanceTab(payload) {
  return request(ACTION_CONSTANTS.GET_SELECTED_PERFORMANCE_TAB, payload);
}

export function getPerformanceActivitiesRequest(payload) {
  return request(ACTION_CONSTANTS.GET_PERFORMANCE_ACTIVITIES_REQUEST, payload);
}

export function getPerformanceActivitiesSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_PERFORMANCE_ACTIVITIES_SUCCESS, payload);
}

export function getPerformanceActivitiesFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_PERFORMANCE_ACTIVITIES_FAILURE, payload);
}

export function searchPerformanceActivities(payload) {
  return request(ACTION_CONSTANTS.SEARCH_PERFORMANCE_ACTIVITIES, payload);
}

export function filterPerformanceActivities(payload) {
  return request(ACTION_CONSTANTS.FILTER_PERFORMANCE_ACTIVITIES, payload);
}

export function syncActivitySubmissionsRequest(payload) {
  return request(ACTION_CONSTANTS.SYNC_ACTIVITY_SUBMISSIONS_REQUEST, payload);
}

export function syncActivitySubmissionsSuccess(payload) {
  return success(ACTION_CONSTANTS.SYNC_ACTIVITY_SUBMISSIONS_SUCCESS, payload);
}

export function syncActivitySubmissionsFailure(payload) {
  return failure(ACTION_CONSTANTS.SYNC_ACTIVITY_SUBMISSIONS_FAILURE, payload);
}

export function postPendingActivitySubmissionsRequest(payload) {
  return request(
    ACTION_CONSTANTS.POST_PENDING_ACTIVITY_SUBMISSIONS_REQUEST,
    payload,
  );
}

export function postPendingActivitySubmissionsSuccess(payload) {
  return success(
    ACTION_CONSTANTS.POST_PENDING_ACTIVITY_SUBMISSIONS_SUCCESS,
    payload,
  );
}

export function postPendingActivitySubmissionsFailure(payload) {
  return failure(
    ACTION_CONSTANTS.POST_PENDING_ACTIVITY_SUBMISSIONS_FAILURE,
    payload,
  );
}

export function postActivitySubmissionsOnServerRequest(payload) {
  return request(
    ACTION_CONSTANTS.POST_ACTIVITY_SUBMISSION_ON_SERVER_REQUEST,
    payload,
  );
}

export function postActivitySubmissionsOnServerSuccess(payload) {
  return success(
    ACTION_CONSTANTS.POST_ACTIVITY_SUBMISSION_ON_SERVER_SUCCESS,
    payload,
  );
}

export function postActivitySubmissionsOnServerRemoved() {
  return success(
    ACTION_CONSTANTS.POST_ACTIVITY_SUBMISSION_ON_SERVER_REMOVED,
    {},
  );
}

export function postActivitySubmissionsOnServerFailure(payload) {
  return failure(
    ACTION_CONSTANTS.POST_ACTIVITY_SUBMISSION_ON_SERVER_FAILURE,
    payload,
  );
}

export function getActivityRevisionRequest(payload) {
  return request(ACTION_CONSTANTS.GET_ACTIVITY_REVISION_REQUEST, payload);
}

export function getActivityRevisionSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_ACTIVITY_REVISION_SUCCESS, payload);
}

export function getActivityRevisionFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_ACTIVITY_REVISION_FAILURE, payload);
}

export function resetSubmissionProps(payload) {
  return failure(ACTION_CONSTANTS.RESET_SUBMISSION_PROPS, payload);
}

//#region get attachment submission file path
export function getAttachmentSubmissionFilePathRequest(payload) {
  return request(
    ACTION_CONSTANTS.GET_ATTACHMENT_SUBMISSION_FILE_PATH_REQUEST,
    payload,
  );
}

export function getAttachmentSubmissionFilePathSuccess(payload) {
  return success(
    ACTION_CONSTANTS.GET_ATTACHMENT_SUBMISSION_FILE_PATH_SUCCESS,
    payload,
  );
}

export function getAttachmentSubmissionFilePathFailure(payload) {
  return failure(
    ACTION_CONSTANTS.GET_ATTACHMENT_SUBMISSION_FILE_PATH_FAILURE,
    payload,
  );
}
//#endregion

//#region remove submission on server
export function postRemoveActivitySubmissionsOnServerRequest(payload) {
  return request(
    ACTION_CONSTANTS.POST_REMOVE_ACTIVITY_SUBMISSION_ON_SERVER_REQUEST,
    payload,
  );
}

export function postRemoveActivitySubmissionsOnServerSuccess(payload) {
  return success(
    ACTION_CONSTANTS.POST_REMOVE_ACTIVITY_SUBMISSION_ON_SERVER_SUCCESS,
    payload,
  );
}

export function postRemoveActivitySubmissionsOnServerFailure(payload) {
  return failure(
    ACTION_CONSTANTS.POST_REMOVE_ACTIVITY_SUBMISSION_ON_SERVER_FAILURE,
    payload,
  );
}
//#endregion

//#region remove submission on server
export function resetSubmissionsRequest(payload) {
  return request(ACTION_CONSTANTS.RESET_SUBMISSIONS_REQUEST, payload);
}

export function resetSubmissionsSuccess(payload) {
  return success(ACTION_CONSTANTS.RESET_SUBMISSIONS_SUCCESS, payload);
}

export function resetSubmissionsFailure(payload) {
  return failure(ACTION_CONSTANTS.RESET_SUBMISSIONS_FAILURE, payload);
}
//#endregion
