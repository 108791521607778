import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';

import AppContainer from '../../../components/AppContainer';
import PBIcon from '../../../components/PBIcon';
import ActivityBinCard from '../../../components/ActivityBinCard';
import { showErrorAlert } from '../../../components/Alerts';

import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

import { trackEvent } from '@progressivebeef/shared/src/services/reactMixpanelService';
import { Events } from '@progressivebeef/shared/src/constants/MixpanelConstants';
import './style.scss';

import { globalHelper } from '@progressivebeef/shared/src/helpers';

import { getDeprecatedActivitiesRequest } from '@progressivebeef/shared/src/store/actions/activities';

class ActivityBin extends PureComponent {
  componentDidMount() {
    if (navigator.onLine) {
      this.props.getDeprecatedActivities({ update: true });
    } else {
      showErrorAlert(localization[getLocale()].NO_INTERNET_CONNECTION);
    }
    trackEvent(Events.BIN_ACTIVITIES_VIEWED);
  }

  onRefresh = () => {
    if (navigator.onLine) {
      this.props.getDeprecatedActivities({ update: true });
    } else {
      showErrorAlert(localization[getLocale()].OFFLINE_MODE_REFRESH_ERROR);
    }
  };

  renderSectionHeader = (item) => <div>{item.title}</div>;
  renderItem = (item) => {
    return <ActivityBinCard {...this.props} item={item}></ActivityBinCard>;
  };
  keyExtractor = (item, index) => item.title + index;

  render() {
    const { loading, deprecatedActivities, history } = this.props;
    return (
      <AppContainer
        title={localization[getLocale()].ACTIVITY_BIN}
        iconHeader
        history={history}
        IconLeftHeader={this.renderLeftHeader}
        noncollapsible
      >
        {globalHelper.renderIf(loading)(
          <div className="bottom-infinite-loader">
            <Spinner color={'red'} />
          </div>
        )}
        {!loading && deprecatedActivities.length > 0 && deprecatedActivities[0].data && deprecatedActivities[0].data.length < 1 &&
          <div className="no-activity-result-found">{localization[getLocale()].NO_ACTIVITIES_FOUND}</div>
        }
        {deprecatedActivities.map((section, index) => {
          return (
            <div key={index + 400}>
              {section.data.length > 0 && section.title
                ? React.createElement(
                  'div',
                  {
                    className: 'section-header',
                    key: index + 100,
                  },
                  this.renderSectionHeader(section)
                )
                : null}
              {React.createElement(
                'div',
                {
                  className: 'section-item-container',
                  key: index + 200,
                },
                section.data.map((item, index) =>
                  React.createElement(
                    'div',
                    {
                      className: 'section-item',
                      key: index,
                    },
                    this.renderItem(item, index)
                  )
                )
              )}
            </div>
          );
        })}
        {/* {deprecatedActivities ? deprecatedActivities.map((item) => {
            return (
              <ActivityBinCard navigation={navigation} item={item} />
            )
          }) : <div>
              {localization[getLocale()].NO_ACTIVITIES_FOUND}</div>} */}
      </AppContainer>
    );
  }

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          name={'chevron-left'}
          onPress={() => this.props.history.goBack()}
        />
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDeprecatedActivities: (payload) =>
      dispatch(getDeprecatedActivitiesRequest(payload)),
  };
};

const mapStateToProps = (state) => {
  const { isLoading, deprecatedActivities } = state.activities;

  return {
    deprecatedActivities,
    loading: isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityBin);
