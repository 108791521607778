import AssetConstants from '../constants/AssetConstants';

class ProgramManualHelper {
  getDocumentInitialFormValues(programManual, currentProgramManual) {
    return currentProgramManual
      ? {
          documentNumber: programManual.document_number
            ? programManual.document_number.replace('m-', '')
            : '',
          englishTitle: programManual.document_locale
            ? programManual.document_locale[1].title
            : '',
          spanishTitle: programManual.document_locale
            ? programManual.document_locale[2]?.title
            : '',
          spanishFile: {
            name: programManual.document_locale
              ? programManual.document_locale[2]?.file_name
              : '',
          },
          englishFile: {
            name: programManual.document_locale
              ? programManual.document_locale[1]?.file_name
              : '',
          },
        }
      : {
          documentNumber: '',
          users: '',
          englishTitle: '',
          englishFile: '',
          spanishTitle: '',
          spanishFile: '',
          urls: '',
        };
  }

  getTrainingInitialFormValues(programManual, currentProgramManual) {
    return currentProgramManual
      ? {
          documentNumber: programManual.document_number
            ? programManual.document_number.replace('m-', '')
            : '',
          englishTitle: programManual.document_locale
            ? programManual.document_locale[1].title
            : '',
          spanishTitle: programManual.document_locale
            ? programManual.document_locale[2]?.title
            : '',
          tags: '',
          frequency: 1,
          numberOfDays: programManual.visible_after + '',
          dueAfter: programManual.unviewed_notification_after + '',
          notificationLevel: '3',
          spanishFile: {
            name: programManual.document_locale
              ? programManual.document_locale[2]?.file_name
              : '',
          },
          englishFile: {
            name: programManual.document_locale
              ? programManual.document_locale[1]?.file_name
              : '',
          },
        }
      : {
          documentNumber: '',
          tags: '',
          frequency: 1,
          numberOfDays: '0',
          dueAfter: '',
          notificationLevel: '3',
          users: '',
          englishTitle: '',
          englishFile: '',
          spanishTitle: '',
          spanishFile: '',
          urls: '',
        };
  }

  getTagIcon(key, item) {
    return key === 2
      ? AssetConstants.cow
      : key === 1
      ? AssetConstants.logType
      : key === 3 && item.requires_approval === 1
      ? AssetConstants.attachment
      : key === 3
      ? AssetConstants.wrench
      : AssetConstants.logType;
  }

  getDocumentIcon(key, item) {
    return key === 5
      ? AssetConstants.sms
      : key === 1
      ? AssetConstants.document
      : key === 4 || key === 3
      ? AssetConstants.checkDocument
      : key === 2
      ? AssetConstants.bullDocument
      : AssetConstants.timeSort;
  }

  convertToPickerModal(item, tags) {
    return item.document_tags && item.document_tags !== ''
      ? item.document_tags.split(',').map((q) => ({
          key: q,
          value: tags.find((m) => m.key === Number(q))?.value,
        }))
      : [];
  }

  getFilteredUsers(users, filter) {
    var filterUser = filter && filter.length > 0 ? filter[0] : { key: 'all' };

    if (filterUser.key !== 'all') {
      users = users.filter((record) => {
        var document_viewed = record.document_viewed;
        var viewed_by = record.viewed_by;

        if (filterUser.key === 'overdue' && document_viewed == 0) {
          return this.checkMissedDateForUserTrainings(viewed_by);
        } else {
          if (document_viewed == filterUser.key) {
            return true;
          } else {
            return false;
          }
        }
      });
    }

    return users;
  }

  checkMissedDateForUserTrainings(miss_date) {
    if (miss_date) {
      var today = new Date(),
        dateArray = miss_date.split(' ');

      var missedDate = dateArray[0].split('-');
      var parsedDate = new Date(
        missedDate[0],
        parseInt(missedDate[1]) - 1,
        missedDate[2],
      );

      parsedDate.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);

      if (today.getTime() > parsedDate.getTime()) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}

export default new ProgramManualHelper();
