import { all } from 'redux-saga/effects';
import authSaga from './authentication';
import feedlotSaga from './feedlot';
import activitiesSaga from './activities';
import programManualsSaga from './programManuals';
import userSaga from './user';
import dataSyncSaga from './dataSync';
import navigationVideosSaga from './navigationVideos';
import dashboardSaga from './dashboard';
import auditSaga from './audits';
import settingsSaga from './settings';
import appTrainingVideoHtmlSaga from './appTrainingVideoHTML';

export default function* mainSaga() {
  yield all([
    authSaga(),
    feedlotSaga(),
    activitiesSaga(),
    userSaga(),
    dataSyncSaga(),
    programManualsSaga(),
    dashboardSaga(),
    navigationVideosSaga(),
    auditSaga(),
    settingsSaga(),
    appTrainingVideoHtmlSaga(),
  ]);
}
