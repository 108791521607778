import { apiClient } from './index';
import { handleResponse, handleError } from './responseHandler';
import APIConstants from '../../constants/APIConstants';
import { getSelectedFeedlotId, getUserId } from '../storageService/GlobalData';

class UserService {
  getFeedlotUsersList(id = 1) {
    const url = APIConstants.GET_FEEDLOT_USERS_LIST.replace(
      '{{id}}',
      getSelectedFeedlotId() || 1,
    );
    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  getUsersList(lastSynced) {
    let url = APIConstants.GET_FEEDLOT_USERS_LIST.replace(
      '{{id}}',
      getSelectedFeedlotId() || 1
    );
    if (lastSynced) {
      url = url.concat(`?syncCall=${true}&time=${lastSynced}`);
    }
    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  addUser(payload) {
    const url = APIConstants.ADD_USER.replace(
      '{{id}}',
      getSelectedFeedlotId() || 1,
    );
    if (payload.user.user_id != undefined && payload.user.user_id != '') {
      return apiClient()
        .post(url, payload)
        .then(handleResponse)
        .catch(handleError);
    } else {
      return apiClient()
        .put(url, payload)
        .then(handleResponse)
        .catch(handleError);
    }
  }

  changePin(params) {
    let url = APIConstants.CHANGE_PIN.replace('{{user_id}}', getUserId());
    url = url.concat(`?oldPin=${params.oldPin}&newPin=${params.newPin}`);
    return apiClient().post(url).then(handleResponse).catch(handleError);
  }

  changePassword(data) {
    let url = APIConstants.CHANGE_PASSWORD;
    return apiClient().post(url, data).then(handleResponse).catch(handleError);
  }

  unlockUser(params) {
    let url = APIConstants.UNLOCK_USER(params);
    return apiClient().get(url).then(handleResponse).catch(handleError);
  }
}

export default new UserService();
