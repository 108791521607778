import ACTION_CONSTANTS from '../../constants/actionConstants';
import { BOTTOM_BAR_TABS } from '../../constants/AppConstants';

export const initialState = {
  isLoaderLoading: false,
  loadingText: '',
  notificationData: null,
  isAuthenticating: false,
  showAlert: false,
  alertText: false,
  device: '',
  bottomTab: BOTTOM_BAR_TABS.DASHBOARD,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_CONSTANTS.SHOW_LOADER: {
      return {
        ...state,
        isLoaderLoading: true,
        loadingText: action.payload.loadingText || '',
        isAuthenticating: action.payload.isAuthenticating || false,
      };
    }
    case ACTION_CONSTANTS.HIDE_LOADER: {
      return {
        ...state,
        isLoaderLoading: false,
        loadingText: '',
        isAuthenticating: false,
      };
    }

    case ACTION_CONSTANTS.SHOW_ALERT: {
      return {
        ...state,
        showAlert: true,
        alertText: action.payload.alertText || '',
      };
    }
    case ACTION_CONSTANTS.HIDE_LOADER: {
      return {
        ...state,
        showAlert: false,
        alertText: '',
      };
    }

    case ACTION_CONSTANTS.SET_NOTIFICATION_DATA: {
      return {
        ...state,
        notificationData: action.payload.notificationData,
      };
    }

    case ACTION_CONSTANTS.SET_DEVICE_INFO: {
      // console.log('SET_DEVICE_INFO', action.payload);
      return {
        ...state,
        device: action.payload.device,
      };
    }

    case ACTION_CONSTANTS.SET_BOTTOM_BAR_TAB: {
      return {
        ...state,
        bottomTab: action.payload,
      };
    }

    default:
      return state;
  }
};
