// modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CustomInput } from 'reactstrap';

import './style.scss';

import AppContainer from '../../../components/AppContainer';
import PBIcon from '../../../components/PBIcon';
import SettingOption from '../../../components/SettingOption';
import PickerComponent from '../../../components/Picker';

import {
  getLocale,
  getUserRole,
  getSelectedFeedlotName,
} from '@progressivebeef/shared/src/services/storageService/GlobalData';
import localization from '@progressivebeef/shared/src/localization/i18n';

import LocaleHelper from '@progressivebeef/shared/src/helpers/LocaleHelper';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';
import { clearLocalStorageRequest } from '@progressivebeef/shared/src/store/actions/settings';
import { showErrorAlert } from '../../../components/Alerts';
import {
  hideLoader,
  showLoader,
} from '@progressivebeef/shared/src/store/actions/view';
import { emailAlertsToggleRequested } from '@progressivebeef/shared/src/store/actions/authentication';
import { userRoles } from '@progressivebeef/shared/src/constants/AppConstants';

class Settings extends Component {
  componentDidUpdate(prevProps) {
    const { error, loading, showLoaderView, hideLoaderView } = this.props;

    if (loading) {
      showLoaderView();
    } else {
      hideLoaderView();
    }

    if (prevProps.error !== error) {
      if (typeof error === 'string') {
        showErrorAlert(error);
      }
    }
  }

  render() {
    const { user, history, emailAlerts, toggleEmailAlerts, isLoading } =
      this.props;
    const { userFullName, userRoleId } = user;
    const userName = userFullName ? userFullName : '';
    let env = process.env.REACT_APP_ENVIRONMENT;
    let version = process.env.REACT_APP_VERSION_CODE;
    env = typeof env === 'string' ? env.toUpperCase() : '';

    const handleEmailAlerts = (e) => {
      const { checked } = e.target;
      if (!isLoading) {
        toggleEmailAlerts(checked);
      }
    };
    const { FEEDLOT_MANAGER, PROGRAM_MANAGER, SUPERVISOR } = userRoles;
    const isRole = (role) => userRoleId == role;

    return (
      <AppContainer
        history={history}
        title={localization[getLocale()].SETTINGS}>
        <div className="setting-screen-box">
          <div className="top-info">
            <div className="left-col">
              <div className="label">{userName}</div>
              <div className="designation">
                {localization[getLocale()]['USER_ROLE_' + getUserRole()]}
              </div>
              <div className="feedyard">{getSelectedFeedlotName()}</div>
            </div>
            {env != 'PROD' && (
              <div className="right-col">
                <div className="build-info">{env}</div>
              </div>
            )}
          </div>
          <div>
            <SettingOption
              onPress={() => {
                this.props.history.push(RouteConstants.CHANGE_PASSWORD);
              }}
              title={localization[getLocale()].CHANGE_PASSWORD}
            />
            <SettingOption
              onPress={() => {
                this.props.history.push(RouteConstants.CHANGE_PIN);
              }}
              title={localization[getLocale()].CHANGE_PIN}
            />
          </div>
          {(isRole(FEEDLOT_MANAGER) ||
            isRole(SUPERVISOR) ||
            isRole(PROGRAM_MANAGER)) && (
            <SettingOption
              title={localization[getLocale()].EMAIL_ALERTS}
              rightComponent={
                <div className="icon">
                  <CustomInput
                    type="switch"
                    name="switch"
                    id="switch"
                    checked={emailAlerts}
                    onChange={handleEmailAlerts}
                  />
                </div>
              }
            />
          )}
          <SettingOption
            title={localization[getLocale()].VIEW_COACHMARKS}
            onPress={() => {
              this.props.history.push(RouteConstants.COACHMARK, {
                previousRoute: RouteConstants.SETTINGS,
              });
            }}
            rightComponent={
              <div className="icon">
                <PBIcon name={'info-circle'} />
              </div>
            }
          />
        </div>

        <div className="clear-storage-box">
          <PickerComponent
            placeholder={localization[getLocale()].CLEAR_STORAGE_OPTION}
            data={LocaleHelper.CLEAR_STORAGE_OPTIONS()}
            onValueChange={(itemValue) => {
              this.props.clearLocalStorage(itemValue);
            }}
          />
        </div>

        <div>
          <div className="show-version-box">
            {localization[getLocale()].VERSION + ' '}
            {version}
          </div>
        </div>
      </AppContainer>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearLocalStorage: (payload) => dispatch(clearLocalStorageRequest(payload)),
    showLoaderView: (payload) => dispatch(showLoader(payload)),
    hideLoaderView: (payload) => dispatch(hideLoader(payload)),
    toggleEmailAlerts: (payload) =>
      dispatch(emailAlertsToggleRequested(payload)),
  };
};

const mapStateToProps = (state) => {
  let { user } = state.authentication;
  let { error, success, loading } = state.settings;
  let { emailAlerts, isLoading } = state.authentication;
  return {
    user,
    error,
    success,
    loading,
    emailAlerts,
    isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
