import React from 'react';
import PickerComponent from '../Picker';
import './style.scss';

const RowPickerItem = (props) => {
  const { heading, selectedValue, onValueChange, data } = props;
  return (
    <div className="activity-schedule-picker-box">
      <div className="label">{heading}</div>
      <div className="picker-list"> 
        <PickerComponent
          selectedValue={selectedValue}
          onValueChange={onValueChange}
          data={data}
        />
      </div>
    </div>
  );
};

export default RowPickerItem;
