import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Formik } from 'formik';
import moment from 'moment';
import './style.scss';

// methods
import getLanguageKey from '@progressivebeef/shared/src/services/languageService';

// components
import AppContainer from '../../../../components/AppContainer';
import PBIcon from '../../../../components/PBIcon';
import ActivityDetail from '../../../../components/ActivityDetail';
import Field from './Fields/field';
import BasicDatePicker from '../../../../components/BasicDatePicker';
import Loader from '../../../../components/Loader';
import FormButton from '../../../../components/FormButton';
import {
  showAlertMsg,
  showErrorAlert,
  showSuccessAlert,
} from '../../../../components/Alerts';
import DialogComponent from '../../../../components/Dialog';

// constants
import {
  getLocale,
  getUserRole,
  getToken,
} from '@progressivebeef/shared/src/services/storageService/GlobalData';
import localization from '@progressivebeef/shared/src/localization/i18n';

import { trackEvent } from '@progressivebeef/shared/src/services/reactMixpanelService';
import {
  Events,
  Properties,
} from '@progressivebeef/shared/src/constants/MixpanelConstants';

import ActivitiesHelper from '@progressivebeef/shared/src/helpers/ActivitiesHelper';
import { globalHelper } from '@progressivebeef/shared/src/helpers';

import {
  postActivitySubmissionRequest,
  postActivityAttachmentRequest,
  removeActivitySubmissionRequest,
  resetSubmissionProps,
  postFormActivityPrintRequest,
  getAttachmentSubmissionFilePathRequest,
} from '@progressivebeef/shared/src/store/actions/activities';
import {
  DATE_FORMATS,
  userRoles,
  SUBMISSION_STATUS,
  ACTIVITY_TYPE_VALUES,
  ACTIVITY_FREQUENCY_VALUES,
  ACTIVITY_TYPE,
} from '@progressivebeef/shared/src/constants/AppConstants';
import { AddSubmissionValidation } from '@progressivebeef/shared/src/constants/FormValidations';
import {
  showLoader,
  hideLoader,
} from '@progressivebeef/shared/src/store/actions/view';

import printJS from 'print-js';
import { ATTACHMENT_TYPES } from '@progressivebeef/shared/src/constants/FormConstants';
import { baseUrl } from '@progressivebeef/shared/src/services/api';
import APIConstants from '@progressivebeef/shared/src/constants/APIConstants';
import languageService from '@progressivebeef/shared/src/services/languageService';

class AddSubmission extends Component {
  constructor(props) {
    super(props);
    const {
      currentActivity,
      currentInstance,
      disabled,
      submission,
      violationData,
    } = this.props.location.state;
    this.state = {
      attachmentSubmissionFilePath: null,
      currentActivity,
      currentInstance,
      disabled: disabled || false,
      submission: submission || { status: null },
      showSubmissionDate: false,
      submissionDate: submission
        ? moment(submission.submitted_date).format(DATE_FORMATS.MM_DD_YYYY)
        : new Date(),
      violationData,
      showReasonDialog: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      isAddSubmissionError,
      isAddSubmissionSuccess,
      isAddSubmissionLoading,
      isFormPrintLoading,
      isFormPrintError,
      isFormPrintSuccess,
      showLoaderView,
      hideLoaderView,
      removePending,
      error,
    } = this.props;
    const { disabled } = this.state;

    if (isFormPrintLoading) {
      showLoaderView();
    } else {
      hideLoaderView();
    }

    if (
      isFormPrintSuccess &&
      isFormPrintSuccess !== prevProps.isFormPrintSuccess
    ) {
      showSuccessAlert(localization[getLocale()].REQUEST_SENT_TO_ADMIN, [
        {
          text: 'Ok',
          onPress: () => {
            this.props.history.goBack();
          },
        },
      ]);
    }
    if (isFormPrintError !== prevProps.isFormPrintError) {
      if (typeof isFormPrintError === 'string') {
        showErrorAlert(isFormPrintError);
      }
    }
    if (error !== prevProps.error) {
      if (typeof error === 'string') {
        showErrorAlert(error);
      }
    }

    if (
      !isAddSubmissionLoading &&
      isAddSubmissionLoading !== prevProps.isAddSubmissionLoading &&
      isAddSubmissionSuccess
    ) {
      let successMsg = '';
      // let { getSubmissions } = this.props.location.state;
      // if (getSubmissions && typeof getSubmissions !== undefined) {
      //   getSubmissions();
      // }

      if (!disabled) {
        if (navigator.onLine) {
          successMsg = localization[getLocale()].SUBMISSION_ADDED;
        } else {
          successMsg = localization[getLocale()].SUBMISSION_ADDED_PENDING;
        }
      } else {
        if (removePending) {
          successMsg = localization[getLocale()].SUBMISSION_REMOVED_PENDING;
        } else {
          successMsg = localization[getLocale()].SUBMISSION_REMOVED;
        }
      }
      showSuccessAlert(successMsg, [
        {
          text: 'Ok',
          onPress: () => {
            this.props.history.goBack();
          },
        },
      ]);
    }

    if (
      isAddSubmissionError &&
      isAddSubmissionError !== prevProps.isAddSubmissionError
    ) {
      if (typeof isAddSubmissionError === 'string') {
        showErrorAlert(isAddSubmissionError, [
          {
            text: 'Ok',
            onPress: () => {
              console.log('Dont redirect');
              // this.props.history.goBack();
            },
          },
        ]);
      } else {
        showErrorAlert(localization[getLocale()].SOMETHING_WENT_WRONG);
      }
    }
  }

  componentWillUnmount() {
    this.props.resetSubmissionProps();
  }

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          name={'chevron-left'}
          onPress={() => {
            this.props.history.goBack();
          }}
        />
      </div>
    );
  };

  renderRightHeader = () => {
    const { currentActivity, submission } = this.state;
    return (
      <div className="head-icon right-align submision-head-icon">
        <div className="hald-icon-box">
          {globalHelper.renderIf(
            (currentActivity.activity_type_key === ACTIVITY_TYPE.ATTACHMENT ||
              currentActivity.activity_type_key === ACTIVITY_TYPE.FORM) &&
              submission.status !== null,
          )(
            <PBIcon
              name={'print'}
              onPress={() => {
                if (
                  currentActivity.activity_type_key ===
                    ACTIVITY_TYPE.ATTACHMENT &&
                  submission &&
                  submission.values[0].value
                ) {
                  this.onPrint(submission.values[0].value);
                } else {
                  this.props.postFormActivityPrint(
                    submission.feedlot_activity_submission_id,
                  );
                }
              }}
            />,
          )}
        </div>
      </div>
    );
  };

  getInitialValues = () => {
    const { currentActivity, submission, violationData } = this.state;
    const { focusedActivity } = this.props;

    return ActivitiesHelper.getAddSubmissionInitialValues(
      focusedActivity &&
        focusedActivity.activity_number &&
        focusedActivity.feedlot_activity_id ==
          currentActivity.feedlot_activity_id &&
        !violationData
        ? focusedActivity
        : currentActivity || {},
      submission,
    );
  };

  renderForm = () => {
    const { currentActivity, disabled, violationData } = this.state;

    const fields =
      currentActivity.fields &&
      Object.keys(currentActivity.fields).map(
        (key) => currentActivity.fields[key],
      );

    return fields.map((field) => {
      if (violationData && violationData.includes(field.group_id)) {
        field.violated = true;
      }
      return (
        <Field
          {...this.props}
          field={field}
          disabled={disabled}
          activityType={currentActivity.activity_type_key}
          submissionNumber={
            currentActivity.activity_number === 'LOG21'
              ? currentActivity.schedule.submission_number
              : null
          }
          specialProperty={field.special_property || null}
          fields={fields}
        />
      );
    });
  };

  onSubmit = async (values) => {
    const { currentActivity, currentInstance, submissionDate, submission } =
      this.state;
    showAlertMsg(
      localization[getLocale()].CONFIRMATION.toUpperCase(),
      localization[getLocale()].ADD_SUBMISSION_CONFIRMATION.replace(
        '[activityTitle]',
        this.getTitle(),
      )
        .replace(
          '[date]',
          moment(submissionDate).format(DATE_FORMATS.MM_DD_YYYY),
        )
        .replace('[userName]', this.props.user.userFullName),
      [
        {
          text: 'No',
          onPress: () => {
            'cancel';
          },
        },
        {
          text: 'Yes',
          onPress: () => {
            let formValues = _.cloneDeep(values);
            let { backdated, startDate, endDate } = this.props.location.state;
            const payload = {
              formValues,
              submissionDate,
              currentActivity,
              currentInstance,
              backdated,
              startDate,
              endDate,
            };
            if (submission.status === SUBMISSION_STATUS.REJECTED) {
              payload.submissionId = submission.id;
            }

            if (submission.status === SUBMISSION_STATUS.REJECTED) {
              let properties = {};

              properties[Properties.ACTIVITY_NAME] =
                currentActivity.locale[languageService()].title;
              properties[Properties.ACTIVITY_NUMBER] =
                currentActivity.activity_number;

              trackEvent(Events.RESUBMISSION, properties);
            } else {
              try {
                let properties = {};
                properties[Properties.INSTANCE_BASED] = currentInstance
                  ? true
                  : false;
                properties[Properties.ACTIVITY_TYPE] =
                  ACTIVITY_TYPE_VALUES[currentActivity.activity_type_key];
                properties[Properties.ACTIVITY_FREQUENCY] =
                  ACTIVITY_FREQUENCY_VALUES[currentActivity.frequency_key];
                properties[Properties.BACKDATED] =
                  moment(submissionDate) !== moment() ? true : false;
                properties[Properties.ACTIVITY_NAME] =
                  currentActivity.locale[languageService()].title;
                properties[Properties.ACTIVITY_NUMBER] =
                  currentActivity.activity_number;
                trackEvent(Events.SUBMISSION_MADE, properties);
              } catch (e) {}
            }
            if (
              !navigator.onLine &&
              currentActivity.activity_type_key == ACTIVITY_TYPE.ATTACHMENT
            ) {
              //alert was disolving very fast (was unable to see)
              setTimeout(
                () =>
                  showErrorAlert(
                    localization[getLocale()].NO_INTERNET_CONNECTION,
                  ),
                100,
              );
            } else {
              if (submissionDate > new Date()) {
                setTimeout(
                  () =>
                    showErrorAlert(localization[getLocale()].ERROR_CODE_00074),
                  100,
                );
              } else {
                this.props.postSubmission(payload);
              }
            }
          },
        },
      ],
    );
  };

  getTitle = () => {
    let { currentActivity, currentInstance } = this.state;

    if (currentInstance) {
      return currentInstance.instance_value;
    } else {
      return currentActivity.activity_number;
    }
  };

  removeSubmission = (reason) => {
    let { currentActivity, currentInstance } = this.state;
    let { backdated, startDate, endDate } = this.props.location.state;

    if (reason) {
      const payload = {
        requestBody: ActivitiesHelper.getRemoveSubmissionObject(
          this.state.submission.feedlot_activity_submission_id,
          reason,
        ),
        backdated,
        startDate,
        endDate,
      };

      try {
        let properties = {};
        properties[Properties.INSTANCE_BASED] = currentInstance ? true : false;
        properties[Properties.ACTIVITY_TYPE] =
          ACTIVITY_TYPE_VALUES[currentActivity.activity_type_key];
        properties[Properties.ACTIVITY_FREQUENCY] =
          ACTIVITY_FREQUENCY_VALUES[currentActivity.frequency_key];
        properties[Properties.ACTIVITY_NAME] =
          currentActivity.locale[languageService()].title;
        properties[Properties.ACTIVITY_NUMBER] =
          currentActivity.activity_number;
        trackEvent(Events.SUBMISSION_REMOVED, properties);
      } catch (e) {}

      this.props.removeSubmission(payload);
    } else {
      showErrorAlert(localization[getLocale()].REASON_REQUIRED);
    }
  };

  onPrint = async (file, condition) => {
    const type = file.split('.').pop();
    const token = getToken();
    var response = await ActivitiesHelper.getImageFromServer(
      `${baseUrl}${APIConstants.GET_ACTIVITY_ATTACHMENT}`.replace(
        '{{fileName}}',
        file,
      ),
      {
        token: token,
      },
    );

    // let properties = {};
    // properties[Properties.DOCUMENT_NUMBER] = programManual.document_number;
    // trackEvent(Events.PRINTED_PROGRAM_MANUAL, properties);

    if (type === ATTACHMENT_TYPES.PDF) {
      printJS({
        printable: response.split(',')[1],
        type: 'pdf',
        base64: true,
      });
    } else {
      printJS({
        printable: '<div><img alt="description" src="' + response + '"/></div>',
        type: 'raw-html',
      });
    }
  };

  render() {
    const {
      currentActivity,
      showSubmissionDate,
      submissionDate,
      disabled,
      submission,
      showReasonDialog,
      violationData,
    } = this.state;
    const { isAddSubmissionLoading, user, focusedActivity, history } =
      this.props;
    const locale = currentActivity.locale[getLanguageKey()];
    const { bin } = this.props.location.state;

    let activity =
      focusedActivity && focusedActivity.activity_number && !violationData
        ? focusedActivity
        : currentActivity;

    return (
      <>
        {isAddSubmissionLoading && <Loader loading={isAddSubmissionLoading} />}
        <AppContainer
          noncollapsible
          title={this.getTitle()}
          history={history}
          iconHeader
          IconRightHeader={this.renderRightHeader}
          IconLeftHeader={this.renderLeftHeader}>
          {(submission.status === SUBMISSION_STATUS.REMOVED ||
            (submission.status === SUBMISSION_STATUS.PENDING &&
              submission.removeCallPending)) && (
            <div className="deleted-submission-alert-box">
              <div className="inner-box">
                <div className="icon">
                  <PBIcon name={'info-circle'} />
                </div>
                <div>
                  <div className="info-title">
                    {submission.feedlotActivitySubmissionCancellation.reason}
                  </div>
                  <div className="info-desc">
                    {`${
                      submission.feedlotActivitySubmissionCancellation
                        .cancelled_by_user
                    } deleted on ${moment(
                      submission.feedlotActivitySubmissionCancellation
                        .cancellation_date,
                    ).format(DATE_FORMATS.MM_DD_YYYY)}`}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div>
            <ActivityDetail activity={activity} />
          </div>

          <div className="add-submission-user-info-box">
            <div
              className={`name${
                submission.status === SUBMISSION_STATUS.REMOVED ||
                (submission.status === SUBMISSION_STATUS.PENDING &&
                  submission.removeCallPending)
                  ? ' removed-user'
                  : ''
              }`}>
              {submission.first_name
                ? `${submission.first_name + ' ' + submission.last_name}`
                : `${user.userFullName}`}
            </div>
            <div className="date-box">
              <BasicDatePicker
                value={submissionDate}
                onChange={(selectedDate) => {
                  this.setState({
                    submissionDate: selectedDate || this.state.submissionDate,
                  });
                }}
                onPress={(value) => {
                  this.setState({
                    submissionDate: value || this.state.submissionDate,
                    showSubmissionDate: false,
                  });
                }}
              />
            </div>
            {/* <div className="date" onClick={() => {
              if (
                !disabled &&
                submission.status !== SUBMISSION_STATUS.REJECTED
              ) {
                this.setState({ showSubmissionDate: true });
              }
            }}>
              <div className="date">
                {moment(submissionDate).format(DATE_FORMATS.MM_DD_YYYY)}
              </div>
            </div> */}
          </div>

          <div className="add-submission-instruction-box">
            {' '}
            {locale.instruction && (
              <div>
                <div className="title">
                  {localization[getLocale()].INSTRUCTIONS}
                </div>
                <div className="desc">{locale.instruction}</div>
              </div>
            )}
          </div>

          <Formik
            onSubmit={(values) => this.onSubmit(values)}
            initialValues={this.getInitialValues()}
            validate={(values) => {
              if (submission.status === SUBMISSION_STATUS.REJECTED) {
                return {};
              } else {
                return AddSubmissionValidation(
                  currentActivity.fields,
                  this.getInitialValues(),
                  values,
                );
              }
            }}>
            {(props) => (
              <div className="add-submission-view-info-parent">
                {this.renderForm()}
                {!disabled && (
                  <FormButton
                    title={
                      submission.status === SUBMISSION_STATUS.REJECTED
                        ? localization[getLocale()].RESUBMIT
                        : localization[getLocale()].SUBMIT
                    }
                    onPressEvent={() => {
                      if (props.isValid) {
                        props.handleSubmit();
                      } else {
                        showErrorAlert(
                          localization[getLocale()].PLEASE_FILL_ALL_FIELDS,
                        );
                      }
                    }}
                  />
                )}
                {bin
                  ? null
                  : disabled &&
                    // auditors and workers cannot remove
                    ((getUserRole() !== userRoles.WORKER &&
                      getUserRole() !== userRoles.INTERNAL_AUDITOR &&
                      getUserRole() !== userRoles.EXTERNAL_AUDITOR) ||
                      (getUserRole() === userRoles.WORKER &&
                        submission.user_id === Number(user.userId))) &&
                    submission.status === SUBMISSION_STATUS.SUBMITTED && (
                      <FormButton
                        title={localization[getLocale()].REMOVE}
                        onPressEvent={() =>
                          this.setState({ showReasonDialog: true })
                        }
                      />
                    )}
              </div>
            )}
          </Formik>

          {showSubmissionDate && (
            <BasicDatePicker
              value={submissionDate}
              onChange={(selectedDate) => {
                this.setState({
                  submissionDate: selectedDate || this.state.submissionDate,
                });
              }}
              onPress={(value) => {
                this.setState({
                  submissionDate: value || this.state.submissionDate,
                  showSubmissionDate: false,
                });
              }}
            />
          )}

          {showReasonDialog && (
            <DialogComponent
              showDialog={true}
              title={localization[getLocale()].REASON_FOR_REMOVAL}
              placeholder={'Enter reason here'}
              handleSubmit={(value) => {
                this.setState({ showReasonDialog: false }, () => {
                  this.removeSubmission(value);
                });
              }}
              handleCancel={() => this.setState({ showReasonDialog: false })}
            />
          )}
        </AppContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    postSubmission: (payload) =>
      dispatch(postActivitySubmissionRequest(payload)),
    postAttachment: (payload) =>
      dispatch(postActivityAttachmentRequest(payload)),
    removeSubmission: (payload) =>
      dispatch(removeActivitySubmissionRequest(payload)),
    resetSubmissionProps: (payload) => dispatch(resetSubmissionProps(payload)),
    postFormActivityPrint: (payload) =>
      dispatch(postFormActivityPrintRequest(payload)),
    getAttachmentSubmissionFilePath: (payload) =>
      dispatch(getAttachmentSubmissionFilePathRequest(payload)),
    showLoaderView: (payload) => dispatch(showLoader(payload)),
    hideLoaderView: (payload) => dispatch(hideLoader(payload)),
  };
};

const mapStateToProps = (state) => {
  const {
    isAddSubmissionLoading,
    isAddSubmissionError,
    isAddSubmissionSuccess,
    focusedActivity,
    isFormPrintLoading,
    isFormPrintError,
    isFormPrintSuccess,
    attachmentSubmissionFilePath,
    error,
    removePending,
  } = state.activities;
  const { user } = state.authentication;
  const { assignLogUsers } = state.user;

  return {
    isAddSubmissionLoading,
    isAddSubmissionError,
    user,
    assignLogUsers,
    isAddSubmissionSuccess,
    focusedActivity,
    isFormPrintLoading,
    isFormPrintError,
    isFormPrintSuccess,
    attachmentSubmissionFilePath,
    error,
    removePending,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSubmission);
