import React, { PureComponent } from 'react';
import AppContainer from '../../../components/AppContainer';
import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import AssetConstants from '@progressivebeef/shared/src/constants/AssetConstants';
import FormButton from '../../../components/FormButton';
import { connect } from 'react-redux';
import { setServerError } from '@progressivebeef/shared/src/store/actions/dashboard';
import './style.scss';

class ServerError extends PureComponent {
  render() {
    return (
      <AppContainer
        hideBottomBar
        title={localization[getLocale()].PROGRESSIVE_BEEF}
        noncollapsible>
        <div className="server-error-screen">
          <div className="img-box"><img src={AssetConstants.usb} /></div>
          <div className="msg-box">
            {localization[getLocale()].SOMETHING_WENT_WRONG}
          </div>
          <div className="button-box">
            <FormButton
              title={localization[getLocale()].CANCEL}
              success
              onPressEvent={() => {
                this.props.setServerError(true);
                this.props.history.goBack();
              }}
            />
          </div>
        </div>
      </AppContainer>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setServerError: (payload) => dispatch(setServerError(payload)),
  };
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ServerError);
