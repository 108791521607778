import { request, success, failure } from './index';
import ACTION_CONSTANTS from '../../constants/actionConstants';

export function syncDataBlockingRequest() {
  return request(ACTION_CONSTANTS.SYNC_DATA_BLOCKING_REQUEST);
}

export function syncDataBlockingSuccess(payload) {
  return success(ACTION_CONSTANTS.SYNC_DATA_BLOCKING_SUCCESS, payload);
}

export function syncDataBlockingFailure(payload) {
  return failure(ACTION_CONSTANTS.SYNC_DATA_BLOCKING_FAILURE, payload);
}

export function syncDataNonBlockingRequest() {
  return request(ACTION_CONSTANTS.SYNC_DATA_NON_BLOCKING_REQUEST);
}

export function syncDataNonBlockingSuccess(payload) {
  return success(ACTION_CONSTANTS.SYNC_DATA_NON_BLOCKING_SUCCESS, payload);
}

export function syncDataNonBlockingFailure(payload) {
  return failure(ACTION_CONSTANTS.SYNC_DATA_NON_BLOCKING_FAILURE, payload);
}

export function syncDataManuallyRequest() {
  return request(ACTION_CONSTANTS.SYNC_DATA_MANUALLY_REQUEST);
}

export function syncDataManuallySuccess(payload) {
  return success(ACTION_CONSTANTS.SYNC_DATA_MANUALLY_SUCCESS, payload);
}

export function syncDataManuallyFailure(payload) {
  return failure(ACTION_CONSTANTS.SYNC_DATA_MANUALLY_FAILURE, payload);
}

export function syncActivityDataRequest(payload) {
  return request(ACTION_CONSTANTS.SYNC_ACTIVITY_DATA_REQUEST, payload);
}

export function syncActivityDataSuccess(payload) {
  return success(ACTION_CONSTANTS.SYNC_ACTIVITY_DATA_SUCCESS, payload);
}

export function syncActivityDataFailure(payload) {
  return failure(ACTION_CONSTANTS.SYNC_ACTIVITY_DATA_FAILURE, payload);
}

export function postPendingDataRequest(payload) {
  return request(ACTION_CONSTANTS.POST_PENDING_DATA_REQUEST, payload);
}

export function postPendingDataSuccess(payload) {
  return success(ACTION_CONSTANTS.POST_PENDING_DATA_SUCCESS, payload);
}

export function postPendingDataFailure(payload) {
  return failure(ACTION_CONSTANTS.POST_PENDING_DATA_FAILURE, payload);
}

export function cancelsync() {
  return request(ACTION_CONSTANTS.CANCEL_SYNC);
}