export default {
  //#region auth
  LOGIN: 'user/login',
  LOGOUT: 'user/logout',
  FORGOT_PASSWORD: 'user/forgotPassword/',
  GENERATE_PIN: 'user/addPin/',
  TOGGLE_EMAIL_ALERTS: 'user/toggle_alerts',
  //#endregion

  //#region activities
  // GET_ACTIVITIES: 'feedlotactivity/1?syncCall=true&time=2019-05-07 03:10:31',
  GET_ACTIVITY_TAGS: '',
  GET_ACTIVITIES:
    'feedlotactivity/{{id}}?language_key={{languageKey}}&status=2&include_deprecated=true',
  GET_ACTIVITY_BY_ID:
    'feedlotactivity/{{feedlot_id}}/activity/id/{{id}}?language_key={{languageKey}}&include_fields=1',
  GET_SUBMISSION_BY_ID: 'feedlotactivitysubmission/{{id}}',
  GET_DEPRECATED_ACTIVITIES:
    'feedlotactivity/{{id}}/deprecated?language_key={{languageKey}}&include_fields=1',
  GET_ACTIVITY_INSTANCES: '/feedlotactivityinstance/{{id}}',
  DELETE_ACTIVITY_INSTANCES: 'feedlotactivityinstance/delete',
  ADD_ACTIVITY_INSTANCES: 'feedlotactivityinstance',
  GET_ACTIVITY_SUBMISSIONS:
    'feedlotactivity/feedlot/{{feedlotId}}/activity/history/{{activityNumber}}?startIndex={{startIndex}}&record=10',
  GET_BACKDATED_ACTIVITY_SUBMISSIONS:
    'feedlotactivity/{{feedlotId}}/backdated_submissions?activity_number={{activityNumber}}&start_date={{startDate}}&end_date={{endDate}}',
  POST_ASSIGN_ACTIVITY: 'feedlotactivity/assign',
  PUT_ACTIVITY_REMINDER: 'feedlotactivityreminder',
  DELETE_ACTIVITY_REMINDER: 'feedlotactivityreminder/{{id}}',
  POST_ACTIVITY_SCHEDULE: 'feedlotactivity/schedule',
  POST_ACTIVITY_PRINT: 'feedlotactivitysubmission/report',
  POST_FORM_ACTIVITY_PRINT:
    'feedlotactivitysubmission/{{id}}/report?language_key={{languageKey}}',
  POST_ACTIVITY_SUBMISSION: 'feedlotactivity/submit',
  POST_ACTIVITY_ATTACHMENT: 'feedlotactivityattachment',
  REMOVE_ACTIVITY_SUBMISSION: 'feedlotactivity/cancel',
  POST_FEEDLOT_REPORT: 'feedlotactivity/report',
  GET_ACTIVITY_ATTACHMENT: 'feedlotactivityattachment/file/{{fileName}}',
  SYNC_ACTIVITY_SUBMISSIONS:
    'feedlotactivity/{{feedlotId}}/activity/history/{{activityNumber}}?syncCall=true&time={{lastSyncedTime}}',
  GET_BACKDATED_SUBMISSION:
    'feedlotactivity/{{feedlotId}}/backdated_submission_count',
  GET_BACKDATED_CUSTOM_SUBMISSIONS:
    'feedlotactivity/{{feedlotId}}/backdated_submission_count?start_date={{start_date}}&end_date={{end_date}}',
  GET_BACKDATED_CUSTOM_SUBMISSIONS_ACTIVITIES:
    'feedlotactivityperformance/feedlot/{{feedlotId}}/{{start_date}}/{{end_date}}',
  //#endregion

  //#region user
  GET_FEEDLOT_USERS_LIST: 'feedlot/{{id}}/users',
  GET_WORKERS: 'feedlotstatistics/{{id}}/workers',
  ADD_USER: 'user/{{id}}',
  CHANGE_PIN: 'user/changePin/{{user_id}}',
  CHANGE_PASSWORD: 'user/changePassword',
  UNLOCK_USER: (userid) => `user/unlock/${userid}`,
  //#endregion

  //#region feedlot
  GET_FEEDLOT_STATS: 'feedlotstatistics/{{id}}',
  GET_FEEDLOT_CUSTOM_PERFORMANCE:
    'feedlotactivityperformance/feedlot/{{id}}/{{start_date}}/{{end_date}}',
  GET_MISSED_ACTIVITIES:
    'mobilenotification/event/activity_missed_recently/{{feedlotId}}/{{userId}}?startIndex=0&records=5',
  //#endregion

  //#region SOP
  GET_PROGRAM_MANUALS:
    'feedlotdocument/{{id}}?language_key={{languageKey}}&exclude_body=1&status=2&include_deprecated=1',
  GET_PROGRAM_MANUAL_BY_ID:
    'feedlotdocument/{{id}}/document/{{document_number}}',
  GET_PROGRAM_MANUALS_TAGS: 'menuitem/20',
  GET_DOCUMENT:
    'feedlotdocument/{{feedlot_id}}/document/file/{{document_number}}?language_key={{language_key}}',
  CREATE_TRAINING: 'feedlotdocument/addtraining',
  UPDATE_TRAINING: 'feedlotdocument/updatetraining',
  CREATE_TRAINING_URLS: 'feedlotdocument/url/process',
  GET_DEPRECATED_PROGRAM_MANUALS: 'feedlotdocument/{{feedlotId}}?status=6',
  MOVE_PROGRAM_MANUALS_TO_ACTIVE:
    'feedlotdocument/{{feedlotId}}/restore/{{documentNumber}}',
  MOVE_PROGRAM_MANUALS_TO_BIN:
    'feedlotdocument/{{feedlotId}}/bin/{{documentNumber}}',
  EMAIL_BINNED_REPORT: 'feedlotdocument/report?binned_report=1',
  EMAIL_REPORT: 'feedlotdocument/report',
  DELETE_PROGRAM_MANUAL_URL: 'feedlotdocument/url/delete',
  UPDATE_PROGRAM_MANUAL_URL: 'feedlotdocument/url/process',
  MARK_AS_VIEWED: 'feedlotdocument/{{feedlotId}}/viewed/{{documentNumber}}',
  //#endregion

  //#region AUDITS
  GET_AUDITS: 'auditassignment/feedlot/{{id}}/fm',
  SET_AUDIT_STATUS: 'auditassignment/{{auditAssignmentId}}/status/',
  SEND_COMMENT: 'auditsectionquestion/comment',
  GET_AUDIT_REPORT: 'auditassignment/{{auditAssignmentId}}/summary',
  GET_CLEARANCE_LETTER: 'auditassignment/{auditAssignmentId}/clearance_letter',
  GET_COMMENT_ATTACHMENTS: 'auditquestioncommentattachment/file/{{fileName}}',
  //#endregion

  //#region Notification start
  GET_MOBILE_NOTIFICATIONS: 'mobilenotification/{{feedlot_id}}/{{user_id}}',
  GET_MOBILE_UPDATES: 'mobileupdates/{{feedlot_id}}/{{user_id}}',
  //#endregion

  //#region Dashboard
  FEEDLOT_RANK: 'performance/rank/{{id}}',
  //#endregion

  //#region Notification start
  GET_NAVIGATION_VIDEOS: 'training/?platform=2',
  GET_VIDEO: 'training/file/{{file}}/?platform=2',
  //#endregion

  SET_USER_DEVICE: 'user/{{id}}/device',
  // app_type=2

  GET_APP_TRAINING_VIDEO_HTML: 'app_training_videos/1',
};
