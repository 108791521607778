import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Input } from 'reactstrap';
import './style.scss';

import PBIcon from '../../../../components/PBIcon';
import AppContainer from '../../../../components/AppContainer';
import BasicDatePicker from '../../../../components/BasicDatePicker';
import FormButton from '../../../../components/FormButton';
import {
  showAlertMsg,
  showSuccessAlert,
  showErrorAlert,
} from '../../../../components/Alerts';

import localization from '@progressivebeef/shared/src/localization/i18n';
import {
  getLocale,
  getSelectedFeedlotId,
} from '@progressivebeef/shared/src/services/storageService/GlobalData';

import { globalHelper } from '@progressivebeef/shared/src/helpers';

import {
  showLoader,
  hideLoader,
} from '@progressivebeef/shared/src/store/actions/view';
import { postFeedlotReportRequest } from '@progressivebeef/shared/src/store/actions/dashboard';
import { emailRegex } from '@progressivebeef/shared/src/constants/AppConstants';

class ExportCompliance extends Component {
  constructor(props) {
    super(props);
    const currentDate = new Date();
    const email = '';
    this.state = {
      currentDate,
      start_date: currentDate,
      end_date: currentDate,
      showStartDate: false,
      showEndDate: false,
    };
  }

  componentDidUpdate(prevProps) {
    let {
      isFeedlotReportLoading,
      error,
      success,
      showLoaderView,
      hideLoaderView,
    } = this.props;

    if (isFeedlotReportLoading) {
      showLoaderView();
    } else {
      hideLoaderView();
    }
    if (success && success !== prevProps.success) {
      showSuccessAlert(localization[getLocale()].REQUEST_PROCESSED_SHORTLY, [
        {
          text: localization[getLocale()].OK,
          onPress: () => {},
        },
      ]);
    }
    if (error !== prevProps.error) {
      if (error && typeof error === 'string') {
        showErrorAlert(error);
      }
    }
  }

  onSubmit = () => {
    const { postFeedlotReport } = this.props;
    const { start_date, end_date } = this.state;
    const currentDate = new Date();

    const payload = { feedlot_report: {} };
    let emailInput = document.getElementById('email');
    this.email = emailInput.value;

    if (!this.email || this.email === '') {
      showErrorAlert(localization[getLocale()].PLEASE_FILL_ALL_FIELDS);
    } else if (!emailRegex.test(this.email)) {
      showErrorAlert(localization[getLocale()].INVALID_EMAIL);
    } else if (start_date > end_date) {
      showAlertMsg(
        localization[getLocale()].INVALID_DATES,
        localization[getLocale()].START_LESS_THAN_END_DATE,
      );
      return;
    } else if (end_date > currentDate) {
      showAlertMsg(
        localization[getLocale()].INVALID_DATES,
        localization[getLocale()].END_DATE_EARLIER_THAN_TODAY_ERROR,
      );
      return;
    } else {
      payload.feedlot_report = {
        start_date: moment(start_date).format('YYYY-MM-DD') + ' 00:00:01',
        end_date: moment(end_date).format('YYYY-MM-DD') + ' 23:59:59',
        emails: this.email,
        feedlot_id: Number(getSelectedFeedlotId()) || 1,
        language_key: getLocale() === 'en' ? 1 : 2,
      };

      postFeedlotReport(payload);
    }
  };

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          type={'FontAwesome'}
          name={'chevron-left'}
          onPress={() => {
            this.props.history.goBack();
          }}
        />
      </div>
    );
  };

  render() {
    const { start_date, end_date, currentDate } = this.state;
    const { history } = this.props;
    return (
      <>
        <AppContainer
          title={localization[getLocale()].EMAIL_REPORT}
          noncollapsible
          iconHeader
          IconLeftHeader={this.renderLeftHeader}
          history={history}>
          <div className="export-compliance-listing-box">
            <div className="row-box">
              <div className="label">{localization[getLocale()].EMAIL}*</div>
              <div className="field-box">
                <Input
                  type="text"
                  id="email"
                  // value={this.email}
                  placeholder={localization[getLocale()].EMAIL}
                />
              </div>
            </div>

            <div className="row-box">
              <div className="label">
                {localization[getLocale()].START_DATE + '*'}
              </div>
              <div className="date-box">
                <BasicDatePicker
                  value={start_date}
                  onChange={(selectedDate) => {
                    this.setState({
                      start_date: selectedDate || currentDate,
                    });
                  }}
                  onPress={(value) => {
                    this.setState({
                      start_date: value || this.state.start_date,
                    });
                  }}
                />
              </div>
            </div>

            <div className="row-box">
              <div className="label">
                {localization[getLocale()].END_DATE + '*'}
              </div>
              <div className="date-box">
                <BasicDatePicker
                  value={end_date}
                  onChange={(selectedDate) => {
                    this.setState({
                      end_date: selectedDate || currentDate,
                    });
                  }}
                  onPress={(value) => {
                    this.setState({
                      end_date: value || this.state.end_date,
                    });
                  }}
                />
              </div>
            </div>
            <FormButton
              success
              onPressEvent={this.onSubmit}
              title={localization[getLocale()].PROCEED}
            />
          </div>
        </AppContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    postFeedlotReport: (payload) => dispatch(postFeedlotReportRequest(payload)),
    showLoaderView: (payload) => dispatch(showLoader(payload)),
    hideLoaderView: (payload) => dispatch(hideLoader(payload)),
  };
};

const mapStateToProps = (state) => {
  const { postReportError, isFeedlotReportLoading, postReportSuccess } =
    state.dashboard;

  return {
    error: postReportError,
    success: postReportSuccess,
    isFeedlotReportLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportCompliance);
