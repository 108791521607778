import { LOCAL_STORAGE_KEYS } from '../../constants/AppConstants';
import navigationVideosModel from '../../models/navigationVideosModel';
import { getLocale } from '../../services/storageService/GlobalData';
import { getLocalstorageItem, setLocalstorageItem } from '.';

class NavigationVideosManager {
  saveNavigationVideos(NavigationVideosToSave) {
    try {
      let videos = [];
      for (let i in NavigationVideosToSave) {
        videos.push(navigationVideosModel(NavigationVideosToSave[i]));
      }

      setLocalstorageItem(
        LOCAL_STORAGE_KEYS.NAVIGATION_VIDEOS,
        JSON.stringify(videos),
      );
      return true;
    } catch (e) {
      console.log('saveNavigationVideosfailed', e);
      return false;
    }
  }

  getNavigationVideos(query = '') {
    let navigationVideos = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.NAVIGATION_VIDEOS),
    );

    // ensure videos exist in local storage
    if (navigationVideos !== null) {
      return navigationVideos.filter((q) =>
        q.locale[getLocale() === 'en' ? 0 : 1].title
          .toLowerCase()
          .includes(query.toLowerCase()),
      );
    } else return [];
  }
}

export default new NavigationVideosManager();
