const activityModel = (activity) => ({
  feedlot_activity_id: activity.feedlot_activity_id,
  activity_id: activity.activity_id,
  activityBackDatedSubmissions: activity.activityBackDatedSubmissions || null,
  activity_number: activity.activity_number,
  activity_type_key: activity.activity_type_key,
  activity_tags: activity.activity_tags,
  documents: activity.documents,
  due_date: activity.due_date || '',
  feedlotPerformance: activity.feedlotPerformance || null,
  activityPredictedPerformance: activity.activityPredictedPerformance || null,
  feedlot_id: activity.feedlot_id,
  fields: activity.fields,
  frequency_key: activity.frequency_key || null,
  instance_based: activity.instance_based || null,
  last_submitted_date: activity.last_submitted_date || '',
  instance_submission: activity.instance_submission,
  instances: activity.instances,
  instance_count: activity.instance_count || null,
  is_permitted: activity.is_permitted || null,
  locale: activity.locale,
  permission_key: activity.permission_key || null,
  published_on: activity.published_on || null,
  revision_date: activity.revision_date || null,
  reminder_date: activity.reminder_date || null,
  reminder: activity.reminder || null,
  schedule: activity.schedule || null,
  status: activity.status || null,
  template_revision_date: activity.template_revision_date || null,
  violation_notification_level: activity.violation_notification_level || null,
  users: activity.users,
  localeString: JSON.stringify(activity.locale),
});

export default activityModel;
