import ACTION_CONSTANTS from '../../constants/actionConstants';
import { request, success, failure } from './index';

export function getNavigationVideosRequest(payload) {
  return request(ACTION_CONSTANTS.GET_VIDEOS_REQUEST, payload);
}

export function getNavigationVideos(payload) {
  return request(ACTION_CONSTANTS.GET_VIDEOS, payload);
}

export function getNavigationVideosSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_VIDEOS_SUCCESS, payload);
}

export function getNavigationVideosFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_VIDEOS_FAILURE, payload);
}

export function searchNavigationVideos(payload) {
  return request(ACTION_CONSTANTS.SEARCH_VIDEOS, payload);
}

export function resetNavigationVideosProps(payload) {
  return request(ACTION_CONSTANTS.RESET_VIDEOS_PAYLOAD, payload);
}

export function resetDownloadProps(payload) {
  return request(ACTION_CONSTANTS.RESET_VIDEOS_DOWNLOAD, payload);
}

export function deleteVideoRequest(payload) {
  return request(ACTION_CONSTANTS.DELETE_VIDEOS, payload);
}

export function downloadVideoRequest(payload) {
  return request(ACTION_CONSTANTS.DOWNLOAD_VIDEOS_REQUEST, payload);
}

export function downloadVideo(payload) {
  return request(ACTION_CONSTANTS.DOWNLOAD_VIDEOS, payload);
}

export function downloadVideoSuccess(payload) {
  return success(ACTION_CONSTANTS.DOWNLOAD_VIDEOS_SUCCESS, payload);
}

export function downloadVideoFailure(payload) {
  return failure(ACTION_CONSTANTS.DOWNLOAD_VIDEOS_FAILURE, payload);
}

export function removeVideoRequest(payload) {
  return request(ACTION_CONSTANTS.REMOVE_VIDEO, payload);
}
