import { apiClient } from './index';
import { handleResponse, handleError } from './responseHandler';
import APIConstants from '../../constants/APIConstants';

class InstanceService {
  getInstancesForActivity(activityId) {
    const url = APIConstants.GET_ACTIVITY_INSTANCES.replace(
      '{{id}}',
      activityId,
    );
    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  deleteActivityInstance(payload) {
    const url = APIConstants.DELETE_ACTIVITY_INSTANCES;
    return apiClient()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  }

  addActivityInstance(payload) {
    const url = APIConstants.ADD_ACTIVITY_INSTANCES;
    return apiClient()
      .put(url, payload)
      .then(handleResponse)
      .catch(handleError);
  }
}

export default new InstanceService();
