import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Separator from '../../../../components/Separator';
import AppContainer from '../../../../components/AppContainer';
import RowItem from '../../../../components/RowItem';
import FormButton from '../../../../components/FormButton';
import BasicDatePicker from '../../../../components/BasicDatePicker';
import {
  showErrorAlert,
  showSuccessAlert,
  showAlertMsg,
} from '../../../../components/Alerts';

import { globalHelper } from '@progressivebeef/shared/src/helpers';

import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import { postActivityPrintRequest } from '@progressivebeef/shared/src/store/actions/activities';
import {
  showLoader,
  hideLoader,
} from '@progressivebeef/shared/src/store/actions/view';
import { DATE_FORMATS } from '@progressivebeef/shared/src/constants/AppConstants';
import PBIcon from '../../../../components/PBIcon';
import './style.scss';

class ActivityPrint extends Component {
  constructor(props) {
    super(props);
    const { currentActivity, currentInstance } = this.props.location.state;
    const currentDate = new Date();
    this.state = {
      currentActivity,
      currentInstance,
      currentDate,
      start_date: currentDate,
      end_date: currentDate,
      showStartDate: false,
      showEndDate: false,
    };
  }

  componentDidUpdate(prevProps) {
    let {
      isNonActivityLoading,
      printSubmissionError,
      success,
      showLoaderView,
      hideLoaderView,
    } = this.props;

    if (isNonActivityLoading) {
      showLoaderView();
    } else {
      hideLoaderView();
    }
    if (success && success !== prevProps.success) {
      showSuccessAlert(localization[getLocale()].REQUEST_SENT_TO_ADMIN);
    }
    if (printSubmissionError !== prevProps.printSubmissionError) {
      if (typeof printSubmissionError === 'string') {
        showErrorAlert(printSubmissionError);
      }
    }
  }

  navigateTo = (route) => {
    const { currentInstance, currentActivity } = this.state;
    this.props.history.push(route, {
      currentInstance,
      currentActivity,
    });
  };

  onSubmit = () => {
    const { postActivityPrint } = this.props;
    const {
      currentActivity,
      currentInstance,
      start_date,
      end_date,
    } = this.state;
    const { activity_number, feedlot_id } = currentActivity;
    const payload = { feedlot_report: {} };

    if (start_date >= end_date) {
      showAlertMsg(
        localization[getLocale()].INVALID_DATES,
        localization[getLocale()].START_LESS_THAN_END_DATE
      );
      return;
    }

    payload.feedlot_report = {
      activity_number,
      start_date:
        moment(start_date).format(DATE_FORMATS.YYYY_MM_DD) + ' 00:00:01',
      end_date: moment(end_date).format(DATE_FORMATS.YYYY_MM_DD) + ' 23:59:59',
      feedlot_id,
      language_key: getLocale() === 'en' ? 1 : 2,
    };
    if (currentInstance) {
      payload.feedlot_report.instance_value = currentInstance.instance_value;
    }
    if (navigator.onLine) {
      postActivityPrint(payload);
    } else {
      showErrorAlert(localization[getLocale()].NO_INTERNET_CONNECTION);
    }
  };

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          name={'chevron-left'}
          onPress={() => {
            this.props.history.goBack();
          }}
        />
      </div>
    );
  };

  render() {
    const {
      currentActivity,
      currentInstance,
      start_date,
      end_date,
      currentDate,
      showStartDate,
      showEndDate,
    } = this.state;
    const activityName =
      getLocale() === 'en'
        ? currentActivity.locale[1].title
        : currentActivity.locale[2].title;
    const title =
      currentInstance && currentInstance.instance_value
        ? currentInstance.instance_value
        : activityName;
    let { history } = this.props;

    return (
      <>
        <AppContainer
          noncollapsible
          title={title}
          iconHeader
          history={history}
          IconLeftHeader={this.renderLeftHeader}
        >
          <Separator title={localization[getLocale()].PRINT} />
          <div className="export-compliance-listing-box">
            <div className="row-box">
              <div className="label">
                {localization[getLocale()].START_DATE + '*'}
              </div>
              <div className="date-box">
                <BasicDatePicker
                  value={start_date}
                  onChange={(selectedDate) => {
                    this.setState({
                      start_date: selectedDate || currentDate,
                    });
                  }}
                  onPress={(value) => {
                    this.setState({
                      start_date: value || this.state.start_date,
                    });
                  }}
                />
              </div>
            </div>

            <div className="row-box">
              <div className="label">
                {localization[getLocale()].END_DATE + '*'}
              </div>
              <div className="date-box">
                <BasicDatePicker
                  value={end_date}
                  onChange={(selectedDate) => {
                    this.setState({
                      end_date: selectedDate || currentDate,
                    });
                  }}
                  onPress={(value) => {
                    this.setState({
                      end_date: value || this.state.end_date,
                    });
                  }}
                />
              </div>
            </div>
            <FormButton
              success
              onPressEvent={this.onSubmit}
              title={localization[getLocale()].PROCEED}
            />
          </div>
        </AppContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    postActivityPrint: (payload) => dispatch(postActivityPrintRequest(payload)),
    showLoaderView: (payload) => dispatch(showLoader(payload)),
    hideLoaderView: (payload) => dispatch(hideLoader(payload)),
  };
};

const mapStateToProps = (state) => {
  const {
    printSubmissionError,
    isNonActivityLoading,
    success,
  } = state.activities;

  return {
    printSubmissionError,
    success,
    isNonActivityLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityPrint);
