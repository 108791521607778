import { request, success, failure } from './index';
import ACTION_CONSTANTS from '../../constants/actionConstants';

//#region ==== diagnostic Email
export function sendDiagnosticEmailRequest(payload) {
  return request(ACTION_CONSTANTS.SEND_DIAGNOSTIC_EMAIL_REQUEST, payload);
}
export function sendDiagnosticEmailSuccess(payload) {
  return success(ACTION_CONSTANTS.SEND_DIAGNOSTIC_EMAIL_SUCCESS, payload);
}
export function sendDiagnosticEmailFailure(payload) {
  return failure(ACTION_CONSTANTS.SEND_DIAGNOSTIC_EMAIL_FAILURE, payload);
}
//#endregion ==== diagnostic Email

//#region ==== clear local storage
export function clearLocalStorageRequest(payload) {
  return request(ACTION_CONSTANTS.CLEAR_LOCAL_STORAGE_REQUEST, payload);
}
export function clearLocalStorageSuccess(payload) {
  return success(ACTION_CONSTANTS.CLEAR_LOCAL_STORAGE_SUCCESS, payload);
}
export function clearLocalStorageFailure(payload) {
  return failure(ACTION_CONSTANTS.CLEAR_LOCAL_STORAGE_FAILURE, payload);
}
//#endregion
