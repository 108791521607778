import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { showErrorAlert } from '../Alerts';
import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import { DATE_FORMATS } from '@progressivebeef/shared/src/constants/AppConstants';
import AssetConstants from '@progressivebeef/shared/src/constants/AssetConstants';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';
import './style.scss';

import {
  downloadVideoRequest,
  deleteVideoRequest,
} from '@progressivebeef/shared/src/store/actions/navigationVideos';

class NavigationVideoCard extends Component {
  onCardPress = (video) => {
    const { urls } = this.props;
    var { history } = this.props;
    history.push(RouteConstants.VIDEO_PLAYER, {
      file: urls.file,
      english: urls.english,
      spanish: urls.spanish,
    });
  };

  onDownloadPress = async (video) => {
    if (window.navigator.onLine) {
      this.props.downloadVideoRequest({
        fileName: video.file,
        spanish:
          video.locale && video.locale[1]
            ? video.locale[1].subtitle_file
            : null,
        english:
          video.locale && video.locale[0]
            ? video.locale[0].subtitle_file
            : null,
        id: video.training_video_id,
      });
    } else {
      showErrorAlert(localization[getLocale()].NO_INTERNET_CONNECTION, [
        {
          text: localization[getLocale()].OK,
          onPress: () => { },
        },
      ]);
    }
  };

  renderBody() {
    var { item, isDownloaded } = this.props;
    const title = item.locale
      ? item.locale[getLocale() === 'en' ? 0 : 1].title
      : localization[getLocale()].TITLE;

    return (
      <div className="row-flex">
        <div className="left-col">
          <div className="label">
            {title}
          </div>
          <div className="file-info">
            <div className="file-name">{item.file_size}</div>
            <div className="file-date">
              {`${localization[getLocale()].UPLOADED_ON} ${moment(
                item.datetime,
              ).format(DATE_FORMATS.MM_DD_YY)}`}
            </div>
          </div>
        </div>
        <div className="right-col">
          <div>
            {isDownloaded ? (
              <div onClick={() => this.onCardPress(item)}>
                <img
                  alt="description"
                  src={AssetConstants.play}
                />
              </div>
            ) : (
                <div onClick={() => this.onDownloadPress(item)}>
                  <img
                    alt="description"
                    src={AssetConstants.download}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    var { isDownloaded } = this.props;

    return isDownloaded ? (
      <div>
        <div>{this.renderBody()}</div>
      </div>
    ) : (
        <div>{this.renderBody()}</div>
      );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    downloadVideoRequest: (payload) => dispatch(downloadVideoRequest(payload)),
    deleteVideoRequest: (payload) => dispatch(deleteVideoRequest(payload)),
  };
};

const mapStateToProps = (state) => {
  const { query, downloadLoading } = state.navigationVideos;
  const { user } = state.authentication;

  return {
    user: user,
    query,
    downloadLoading,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavigationVideoCard);
