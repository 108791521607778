import React from 'react';
import { connect } from 'react-redux';
import DashboardSmallCard from '../../../../components/DashboardSmallCard/dashboardSmallCard';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import localization from '@progressivebeef/shared/src/localization/i18n';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';
import { trackEvent } from '@progressivebeef/shared/src/services/reactMixpanelService';
import { Events } from '@progressivebeef/shared/src/constants/MixpanelConstants';

const PendingActionsCard = (props) => {
  const { isPendingActionsLoading, pendingActionsCount } = props;

  return (
    <DashboardSmallCard
      loading={isPendingActionsLoading}
      heading={localization[getLocale()].ACTIVITIES_WAITING_TO_SYNC}
      data={`${pendingActionsCount}`}
      iconName="sync"
      onPress={() => {
        if (!isPendingActionsLoading) {
          trackEvent(Events.PENDING_SUBMISSION);
          props.history.push(RouteConstants.PENDING_ACTIONS);
        }
      }}
    />
  );
};

const mapStateToProps = (state) => {
  const { isPendingActionsLoading, pendingActionsCount } = state.dashboard;

  return {
    isPendingActionsLoading,
    pendingActionsCount,
  };
};

export default connect(mapStateToProps)(PendingActionsCard);
