import React, { Component } from 'react';
import './style.scss'

class MenuPicker extends Component {
  static defaultProps = {
    onRequestClose: () => { },
    onCancel: () => { },
    cancelText: 'Cancel',
    show: false,
    menus: []
  }

  render() {
    const {
      onRequestClose,
      show,
    } = this.props

    return (
      <div className={`react-actionsheet${show ? ' react-actionsheet-show' : ''}`}
        onClick={onRequestClose}>
        <div className={`react-actionsheet-mask ${show ? ' margin-bottom' : ''}`}>
        </div>
        <div className={`react-actionsheet-wrap ${show ? ' margin-bottom' : ''}`}>
          <div className='more-option-dashboard-menu'>
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}

export default MenuPicker;
