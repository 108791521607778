import { put, takeEvery, call, select, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
//services
import UserService from '../services/api/users';
import { trackEvent } from '../services/reactMixpanelService';

//Managers
import FeedLotUsersManager from '../database/reactDataManager/FeedLotUsersManager';

//helpers
import UserHelper from '../helpers/UsersHelper';

//constants
import localization from '../localization/i18n';
import ACTION_CONSTANTS from '../constants/actionConstants/index';
import {
  getUsersListSuccess,
  searchUsersListSuccess,
  getUsersRequested,
  getUsers,
  getUsersSuccess,
  getUsersFailure,
  searchUsersSuccess,
  searchUsersFailure,
  addUserFailure,
  addUserSuccess,
  changePinFailure,
  changePinSuccess,
  changePasswordSuccess,
  changePasswordFailure,
  unlockUserSuccess,
  unlockUserFailure,
  getUsersListFailure,
  searchUsersRequested,
} from '../store/actions/user';
import { Events, Properties } from '../constants/MixpanelConstants';
import {
  MODULE_NAMES,
  userRolesName,
  USER_STATUS,
  DATE_FORMATS,
} from '../constants/AppConstants';
import { getLocale, getUsername } from '../services/storageService/GlobalData';
import { getProgramManualRequest } from '../store/actions/programMaunals';
import MetaDataManager from '../database/reactDataManager/MetaDataManager';
// import { setCredentials } from '../services/keychainService';
import metaDataDto from '../models/metaDataModel';

export const getActivitiesStore = (state) => state.activities;
export const userStoreRequest = (state) => state.user;

export function* getAssignLogsUsersAsync({ payload }) {
  try {
    const { update, instance } = payload;
    let activitiesStore = yield select(getActivitiesStore);
    let { focusedActivity, focusedInstance } = activitiesStore;
    let assignedUsers = [];
    let focusedActivityUsers = [];
    let focusedInstanceUsers = [];

    if (focusedInstance || instance) {
      focusedInstanceUsers =
        instance && instance.users
          ? Object.keys(instance.users).map((key) => instance.users[key])
          : focusedInstance.users
          ? Object.keys(focusedInstance.users).map(
              (key) => focusedInstance.users[key],
            )
          : [];
    } else {
      focusedActivityUsers = focusedActivity.users
        ? Object.keys(focusedActivity.users).map(
            (key) => focusedActivity.users[key],
          )
        : [];
    }

    if (navigator.onLine && update) {
      yield call(getUsersAsync, { payload: { update: true } });
    }
    let dbUsers = yield call(FeedLotUsersManager.getActiveUsers);

    assignedUsers = UserHelper.filterUsersWithPermissionKey(
      dbUsers,
      focusedActivity.permission_key,
    );

    let userArray = assignedUsers.map((user) => {
      user.selected = instance
        ? !!focusedInstanceUsers.find(
            (instanceUser) => instanceUser.user_id === user.user_id,
          )
        : !focusedActivityUsers
        ? false
        : !!focusedActivityUsers.find(
            (actvitityUser) => actvitityUser.user_id === user.user_id,
          );
      return user;
    });

    yield put(getUsersListSuccess(userArray));
  } catch (e) {
    console.log('getAssignLogsUsersAsync', e);
    yield put(getUsersListFailure());
  }
}

export function* getUsersAsync({ payload }) {
  try {
    const update = payload.update;
    yield put(getUsers());

    if (navigator.onLine && update) {
      let lastSynced = null;
      let usersMetaData = MetaDataManager.getMetaDataByModule(
        MODULE_NAMES.USERS_MODULE, // get activity metadata for last sync time
      );
      if (usersMetaData) {
        lastSynced = usersMetaData.lastSynced;
      }

      let resp = yield call(UserService.getUsersList, lastSynced);

      let metaDataModel = metaDataDto({
        moduleName: MODULE_NAMES.USERS_MODULE,
        lastSynced: resp.time,
      });
      MetaDataManager.saveMetaData(metaDataModel); // upsert activity metadata with new lastSync time

      resp = resp.data.feedlot;
      let users = [...resp.users, ...resp.feedlot_managers];
      if (users.length > 0) {
        let usersAdded = FeedLotUsersManager.saveUsers(users);
        // console.log('save users to DB successfull', usersAdded);
      }
    }

    let usersList = yield call(FeedLotUsersManager.getActiveUsers);
    let status = USER_STATUS.ACTIVE;
    let searchText = '';
    yield put(searchUsersRequested({ status, searchText }));
    yield put(getUsersSuccess(usersList));
  } catch (e) {
    console.log('getUsersFailure', e);
    yield put(getUsersFailure(e));
  }
}

function* searchAssignLogsUsers(action) {
  let userStore = yield select(userStoreRequest);
  let assignLogsUsers = userStore.assignLogUsers;
  let query = userStore.query;
  const filtered = assignLogsUsers.filter((ob) => {
    let name = ob.profile.first_name + ' ' + ob.profile.last_name;
    return name.toLowerCase().startsWith(query.toLowerCase());
  });
  yield put(searchUsersListSuccess(filtered));
}

function* searchUser({ payload }) {
  try {
    const { status } = payload;
    let userStore = yield select(userStoreRequest),
      { searchText } = userStore;

    let searchedUsers = FeedLotUsersManager.searchUsers(status, searchText);
    //searching from DB so that if sync call changes Users updated should be shown

    yield put(searchUsersSuccess({ searchedUsers: searchedUsers || [] }));
  } catch (e) {
    console.log('search user error', e);
    yield put(searchUsersFailure({ error: e }));
  }
}

function* addUser({ payload }) {
  try {
    if (navigator.onLine) {
      let userReqData = yield call(UserHelper.getUserRequestData, payload);
      let response = yield call(UserService.addUser, userReqData);
      if (response && response.data) {
        let user = response.data.user;

        FeedLotUsersManager.saveUser(user);

        let properties = {};
        properties[Properties.USER_TYPE_USED] =
          userRolesName[user.user_role_key];

        if (userReqData.user_id) {
          trackEvent(Events.EDITED_USER, properties);
        } else {
          trackEvent(Events.ADDED_USER, properties);
        }
        yield put(addUserSuccess(user));

        //call sync in background
        if (userReqData.user.document_numbers.length > 0) {
          //sync doc as well if user has been assigned with new doc
          yield put(
            getProgramManualRequest({
              update: true,
            }),
          );
        }
        yield put(getUsersRequested({ update: true }));
      } else {
        yield put(
          addUserFailure({
            error: null, // localization[getLocale()].SOMETHING_WENT_WRONG,
          }),
        );
      }
    } else {
      yield put(
        addUserFailure({
          error: localization[getLocale()].NO_INTERNET_CONNECTION,
        }),
      );
    }
  } catch (e) {
    console.log('addUser error', e);
    yield put(addUserFailure({ error: e.message }));
  }
}

function* changeUserPin({ payload }) {
  if (navigator.onLine) {
    try {
      const res = yield call(UserService.changePin, payload);
      let pin = payload.newPin;
      // yield call(setCredentials, getUsername(), JSON.stringify({ pin }));
      yield put(changePinSuccess());
    } catch (e) {
      yield put(
        changePinFailure({
          error: e.message,
        }),
      );
    }
  } else {
    yield put(
      changePinFailure({
        error: localization[getLocale()].NO_INTERNET_CONNECTION,
      }),
    );
  }
}

function* changeUserPassword({ payload }) {
  if (navigator.onLine) {
    try {
      const res = yield call(UserService.changePassword, payload);
      let password = payload.user.new_password;
      // yield call(setCredentials, getUsername(), JSON.stringify({ password }));
      yield put(changePasswordSuccess());
    } catch (e) {
      yield put(
        changePasswordFailure({
          error: e.message,
        }),
      );
    }
  } else {
    yield put(
      changePasswordFailure({
        error: localization[getLocale()].NO_INTERNET_CONNECTION,
      }),
    );
  }
}

function* unLockUser({ payload }) {
  try {
    let { user_id, user_role_key, profile } = payload.user;

    if (navigator.onLine) {
      let resp = yield call(UserService.unlockUser, user_id);
      yield call(getUsersAsync, {
        payload: { update: true },
      });

      yield call(searchUser, { payload: { status: USER_STATUS.LOCKED } });
      let properties = {};
      properties[Properties.USER_TYPE_USED] = userRolesName[user_role_key];
      properties[Properties.UNLOCKED_USERNAME] =
        profile.first_name + ' ' + profile.last_name;

      trackEvent(Events.UNLOCKED_USER, properties);
      yield put(unlockUserSuccess());
    } else {
      yield put(
        unlockUserFailure({
          error: localization[getLocale()].NO_INTERNET_CONNECTION,
        }),
      );
    }
  } catch (e) {
    yield put(
      unlockUserFailure({
        error: e,
      }),
    );
  }
}

function* userSaga() {
  yield takeEvery(
    ACTION_CONSTANTS.GET_ASSIGN_LOGS_USERS_LIST_REQUEST,
    getAssignLogsUsersAsync,
  );
  yield takeEvery(
    ACTION_CONSTANTS.SEARCH_ASSIGN_LOGS_USERS_LIST_STORE,
    searchAssignLogsUsers,
  );
  yield takeEvery(ACTION_CONSTANTS.GET_USERS_REQUEST, getUsersAsync);
  yield takeLatest(ACTION_CONSTANTS.SEARCH_USERS_REQUEST, searchUser);
  yield takeLatest(ACTION_CONSTANTS.ADD_USER_REQUEST, addUser);
  yield takeEvery(ACTION_CONSTANTS.CHANGE_PIN_REQUEST, changeUserPin);
  yield takeEvery(ACTION_CONSTANTS.CHANGE_PASSWORD_REQUEST, changeUserPassword);
  yield takeEvery(ACTION_CONSTANTS.UNLOCK_USER_REQUEST, unLockUser);
}

export default userSaga;
