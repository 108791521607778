const navigationVideosModel = (video) => ({
  training_video_id: video.training_video_id,
  assigned_role_level: video.assigned_role_level,
  file: video.file,
  category_id: video.category_id,
  category: video.category,
  locale: video.locale,
  file_size: video.file_size,
  datetime: video.datetime,
  isDownloaded: video.isDownloaded,
  isEngSubtitleDownloaded: video.isEngSubtitleDownloaded,
  isSpSubtitleDownloaded: video.isSpSubtitleDownloaded,
  localeString: JSON.stringify(video.locale),
});

export default navigationVideosModel;
