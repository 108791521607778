export default {
  // General Assets
  pbLogo: require('../assets/images/logoProgressiveBeef.png'),
  internetUnavailablePDF: '../assets/internet_unavailable.pdf',
  pbBg: require('../assets/images/mainBg2Gauss.png'),
  pbLoader: require('../assets/images/pb-loader.gif'),
  blurredDashboard: require('../assets/images/Settings/blurredDashboard.png'),
  usb: require('../assets/images/Settings/usb.png'),
  timeSort: require('../assets/images/Activities/timeSort.png'),
  cow: require('../assets/images/Activities/cowIcon.png'),
  attachment: require('../assets/images/Activities/attachmentIcon.png'),
  logType: require('../assets/images/Activities/logTypeIcon.png'),
  removed: require('../assets/images/Activities/removed.png'),
  missed: require('../assets/images/Activities/missed.png'),
  submitted: require('../assets/images/Activities/submitted.png'),
  rejected: require('../assets/images/Activities/rejected.png'),
  pending: require('../assets/images/Activities/pending.png'),
  png: require('../assets/images/Activities/png.png'),
  jpg: require('../assets/images/Activities/jpg.png'),
  pdf: require('../assets/images/Activities/pdf.png'),
  stop: require('../assets/images/Activities/stop.png'),
  filterFilled: require('../assets/images/Activities/filter-filled.png'),
  filterUnfilled: require('../assets/images/Activities/filter-unfilled.png'),
  fileChecked: require('../assets/images/Notification/group.png'),
  certificate: require('../assets/images/Settings/certificate.png'),

  //activities
  more: require('../assets/images/Activities/more.png'),
  camera: require('../assets/images/Activities/camera.png'),
  circleClock: require('../assets/images/Activities/circle-clock.png'),

  //login
  touchID: require('../assets/images/Login/fingerprint.png'),
  faceID: require('../assets/images/Login/faceIdWhite.png'),

  // Program Manual
  sms: require('../assets/images/ProgramManuals/sms.png'),
  document: require('../assets/images/ProgramManuals/document.png'),
  bullDocument: require('../assets/images/ProgramManuals/bullDocument.png'),
  checkDocument: require('../assets/images/ProgramManuals/checkDocument.png'),
  cowDocument: require('../assets/images/ProgramManuals/cow.png'),
  wrench: require('../assets/images/ProgramManuals/wrench.png'),
  gear: require('../assets/images/ProgramManuals/gear.png'),
  barn: require('../assets/images/ProgramManuals/barn.png'),

  // Navigation Videos
  download: require('../assets/images/NavigationVideos/download.png'),
  bin: require('../assets/images/NavigationVideos/bin.png'),
  play: require('../assets/images/NavigationVideos/play.png'),

  // Coachmarks
  coachmark: {
    en: {
      Worker: {
        dashboard: {
          1: require('../assets/images/coachmarks/en/Worker/dashboard/1.gif'),
          2: require('../assets/images/coachmarks/en/Worker/dashboard/2.gif'),
          3: require('../assets/images/coachmarks/en/Worker/dashboard/3.gif'),
          4: require('../assets/images/coachmarks/en/Worker/dashboard/4.gif'),
          5: require('../assets/images/coachmarks/en/Worker/dashboard/5.gif'),
        },
      },
      Other: {
        dashboard: {
          1: require('../assets/images/coachmarks/en/Other/dashboard/1.gif'),
          2: require('../assets/images/coachmarks/en/Other/dashboard/2.gif'),
          3: require('../assets/images/coachmarks/en/Other/dashboard/3.gif'),
          4: require('../assets/images/coachmarks/en/Other/dashboard/4.gif'),
          5: require('../assets/images/coachmarks/en/Other/dashboard/5.gif'),
          6: require('../assets/images/coachmarks/en/Other/dashboard/6.gif'),
          7: require('../assets/images/coachmarks/en/Other/dashboard/7.gif'),
          8: require('../assets/images/coachmarks/en/Other/dashboard/8.gif'),
          9: require('../assets/images/coachmarks/en/Other/dashboard/9.gif'),
        },
      },
    },
    es: {
      Worker: {
        dashboard: {
          1: require('../assets/images/coachmarks/es/Worker/dashboard/1.gif'),
          2: require('../assets/images/coachmarks/es/Worker/dashboard/2.gif'),
          3: require('../assets/images/coachmarks/es/Worker/dashboard/3.gif'),
          4: require('../assets/images/coachmarks/es/Worker/dashboard/4.gif'),
          5: require('../assets/images/coachmarks/es/Worker/dashboard/5.gif'),
        },
      },
      Other: {
        dashboard: {
          1: require('../assets/images/coachmarks/es/Other/dashboard/1.gif'),
          2: require('../assets/images/coachmarks/es/Other/dashboard/2.gif'),
          3: require('../assets/images/coachmarks/es/Other/dashboard/3.gif'),
          4: require('../assets/images/coachmarks/es/Other/dashboard/4.gif'),
          5: require('../assets/images/coachmarks/es/Other/dashboard/5.gif'),
          6: require('../assets/images/coachmarks/es/Other/dashboard/6.gif'),
          7: require('../assets/images/coachmarks/es/Other/dashboard/7.gif'),
          8: require('../assets/images/coachmarks/es/Other/dashboard/8.gif'),
          9: require('../assets/images/coachmarks/es/Other/dashboard/9.gif'),
        },
      },
    },
  },

  //Audit
  auditPdf: require('../assets/images/Audit/pdf.png'),
  question: require('../assets/images/Audit/question.png'),
  questionss: require('../assets/images/Audit/question@2x.png'),
  submitAudit: require('../assets/images/Audit/tick.png'),
  sendComment: require('../assets/images/Audit/send.svg'),
  attachment: require('../assets/images/Audit/attachment.svg'),
};
