import { LOCAL_STORAGE_KEYS } from "../../constants/AppConstants";

class FeedlotManager {
  getAssignedFeedyards(userid, feedlot_id, searchText) {
    try {
      let user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));
      let assignedFeedyards = user.feedlots;

      if (assignedFeedyards.length > 0) {
        if (feedlot_id) {
          assignedFeedyards = assignedFeedyards.filter(q=> q.feedlot_id != feedlot_id);
        }

        if (searchText && searchText != '') {
          // assignedFeedyards = assignedFeedyards.filter(item => {
          //   return item.name.toLowerCase().includes(searchText.toLowerCase())
          // });

          assignedFeedyards = assignedFeedyards.filter(item => {
            return Object.keys(item).some(key =>
              key === 'name' || key === 'address' ?
                item[key].toLowerCase().includes(searchText.toLowerCase())
                : false
            );
          });

          // assignedFeedyards.filter(
          //   `name LIKE[c] "*${searchText}*" || address LIKE[c] "*${searchText}*"`,
          // );
        }

        return assignedFeedyards;
      } else {
        return [];
      }
    } catch (e) {
      console.log('user error', e);
      return [];
    }
  }

  getFeedyardById(id) {
    try {
      let user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));
      let assignedFeedyards = user.feedlots;

      const feedyard = assignedFeedyards.find(item => item.feedlot_id == id);
      return feedyard;
    } catch (e) {
      return null;
    }
  }
}

export default new FeedlotManager();
