import ACTION_CONSTANTS from '../../constants/actionConstants/index';
import { PERFORMANCE_DATA_KEYS_MAP } from '../../constants/AppConstants';

export const initialState = {
  isLoading: false,
  loadingText: '',
  user: {},
  activities: [],
  sortedActivities: [],
  dueToday: {
    total: 0,
    dueToday: 0,
  },
  deprecatedActivities: [],
  filter: [],
  performanceActivitiesFilter: [],
  query: '',
  performanceQuery: '',
  tags: [],
  sort: false,
  focusedActivity: {},
  focusedInstance: null,
  notificationActivityData: {}, // using it separately because using focusedActivity was interferring with other work
  instances: [],
  submissions: [],
  performanceActivities: {
    daily: [],
    thisWeek: [],
    weekly: [],
    thisMonth: [],
    monthly: [],
    cumulative: [],
  },
  customPerformanceActivities: [],
  attachment: '',
  isSubmissionsLoading: false,
  isAddSubmissionLoading: false,
  revision: null,
  updateSubmissionsList: false,
  isAddSubmissionSuccess: false,
  isAddSubmissionError: false,
  isPerformanceActivitiesLoading: false,
  submissionListError: false,
  fetchList: false,
  selectedTab: '2',
  scheduleSuccess: false,
};

const activities = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_CONSTANTS.RESET_ACTIVITIES_PAYLOAD: {
      // console.log('RESET_ACTIVITIES_PAYLOAD');
      return {
        ...state,
        filter: [],
        query: '',
        sort: false,
      };
    }

    case ACTION_CONSTANTS.RESET_PERFORMANCE_PAYLOAD: {
      // console.log('RESET_PERFORMANCE_PAYLOAD');
      return {
        ...state,
        performanceQuery: '',
      };
    }

    case ACTION_CONSTANTS.GET_SELECTED_PERFORMANCE_TAB: {
      // console.log('GET_SELECTED_PERFORMANCE_TAB');
      return {
        ...state,
        selectedTab: action.payload,
      };
    }

    case ACTION_CONSTANTS.GET_ACTIVITIES: {
      // console.log('GET_ACTIVITIES');
      const update = action.payload.update;
      const obj = {
        ...state,
        fetchList: false,
        submissionListError: false,
      };
      // update loader only for api call
      if (update) {
        obj.isLoading = true;
      }
      return obj;
    }

    case ACTION_CONSTANTS.GET_ACTIVITIES_SUCCESS: {
      // console.log('GET_ACTIVITIES_SUCCESS', action.payload.lastSynced);
      const update = action.payload.update;
      const obj = {
        ...state,
        activities: action.payload.separatedActivities,
        lastSynced: action.payload.lastSynced,
        dueToday: action.payload.dueToday || state.dueToday,
        isPerformanceActivitiesLoading: false,
        fetchList: true,
      };
      // update loader only for api call
      if (update) {
        obj.isLoading = false;
      }
      return obj;
    }

    case ACTION_CONSTANTS.GET_ACTIVITIES_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ACTION_CONSTANTS.GET_ACTIVITY_BY_ID_REQUEST: {
      return {
        ...state,
        // isLoading: true,
      };
    }

    case ACTION_CONSTANTS.GET_ACTIVITY_BY_ID_SUCCESS: {
      return {
        ...state,
        notificationActivityData: {
          ...state.notificationActivityData,
          activity: action.payload.activity,
        },
      };
    }

    case ACTION_CONSTANTS.GET_ACTIVITY_BY_ID_FAILURE: {
      return {
        ...state,
        notificationActivityData: {
          ...state.notificationActivityData,
          activity: action.payload.activity,
        },
      };
    }

    case ACTION_CONSTANTS.GET_SUBMISSION_BY_ID_REQUEST: {
      return {
        ...state,
        // isLoading: true,
      };
    }

    case ACTION_CONSTANTS.GET_SUBMISSION_BY_ID_SUCCESS: {
      return {
        ...state,
        notificationActivityData: {
          ...state.notificationActivityData,
          submission: action.payload.submission,
        },
      };
    }

    case ACTION_CONSTANTS.GET_SUBMISSION_BY_ID_FAILURE: {
      return {
        ...state,
        notificationActivityData: {
          ...state.notificationActivityData,
          submission: action.payload.submission,
        },
      };
    }

    case ACTION_CONSTANTS.GET_DEPRECATED_ACTIVITIES: {
      // console.log('GET_DEPRECATED_ACTIVITIES');
      return {
        ...state,
        isLoading: true,
      };
    }

    case ACTION_CONSTANTS.GET_DEPRECATED_ACTIVITIES_SUCCESS: {
      // console.log('GET_DEPRECATED_ACTIVITIES_SUCCESS', action.payload);
      return {
        ...state,
        deprecatedActivities: action.payload,
        isLoading: false,
      };
    }

    case ACTION_CONSTANTS.GET_DEPRECATED_ACTIVITIES_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ACTION_CONSTANTS.GET_ACTIVITIES_TAG: {
      // console.log('GET_ACTIVITIES_TAG');
      return {
        ...state,
        tags: action.payload,
      };
    }

    case ACTION_CONSTANTS.GET_SORTED_ACTIVITIES_SUCCESS: {
      // console.log('GET_SORTED_ACTIVITIES_SUCCESS');
      return {
        ...state,
        sortedActivities: action.payload,
      };
    }

    case ACTION_CONSTANTS.SEARCH_ACTIVITIES: {
      return {
        ...state,
        query: action.payload,
      };
    }

    case ACTION_CONSTANTS.SEARCH_PERFORMANCE_ACTIVITIES: {
      return {
        ...state,
        performanceQuery: action.payload,
      };
    }

    case ACTION_CONSTANTS.FILTER_ACTIVITIES: {
      // console.log('FILTER_ACTIVITIES', action.payload);
      return {
        ...state,
        filter: action.payload,
      };
    }

    case ACTION_CONSTANTS.SORT_ACTIVITIES: {
      // console.log('SORT_ACTIVITIES', action.payload);
      return {
        ...state,
        sort: action.payload,
      };
    }

    case ACTION_CONSTANTS.SET_CURRENT_ACTIVITY: {
      const instances =
        (action.payload.instances &&
          Object.keys(action.payload.instances).map(
            (key) => action.payload.instances[key],
          )) ||
        [];
      return {
        ...state,
        focusedActivity: action.payload,
        instances: instances,
      };
    }

    case ACTION_CONSTANTS.GET_ACTIVITY_INSTANCES_REQUEST: {
      return {
        ...state,
        isNonActivityLoading: true,
      };
    }

    case ACTION_CONSTANTS.GET_ACTIVITY_INSTANCES_SUCCESS: {
      return {
        ...state,
        isNonActivityLoading: false,
        instances: action.payload,
      };
    }

    case ACTION_CONSTANTS.GET_ACTIVITY_INSTANCES_FAILURE: {
      return {
        ...state,
        isNonActivityLoading: false,
      };
    }

    case ACTION_CONSTANTS.DELETE_ACTIVITY_INSTANCES_REQUEST: {
      return {
        ...state,
        isNonActivityLoading: true,
        delInstanceSuccess: false,
        error: false,
        errorMessage: false,
      };
    }

    case ACTION_CONSTANTS.DELETE_ACTIVITY_INSTANCES_SUCCESS: {
      return {
        ...state,
        isNonActivityLoading: false,
        delInstanceSuccess: true,
        instances: action.payload,
      };
    }

    case ACTION_CONSTANTS.DELETE_ACTIVITY_INSTANCES_FAILURE: {
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        error: action.payload.error || action.payload.errorMessage,
        isNonActivityLoading: false,
      };
    }

    case ACTION_CONSTANTS.ADD_ACTIVITY_INSTANCES_REQUEST: {
      return {
        ...state,
        isNonActivityLoading: true,
        addInstanceSuccess: false,
        error: false,
        errorMessage: false,
      };
    }

    case ACTION_CONSTANTS.ADD_ACTIVITY_INSTANCES_SUCCESS: {
      return {
        ...state,
        addInstanceSuccess: true,
        isNonActivityLoading: false,
        instances: action.payload,
      };
    }

    case ACTION_CONSTANTS.ADD_ACTIVITY_INSTANCES_FAILURE: {
      return {
        ...state,
        isNonActivityLoading: false,
        error: action.payload.error,
        errorMessage: action.payload.errorMessage,
      };
    }

    // region GET_ACTIVITY_SUBMISSIONS
    case ACTION_CONSTANTS.GET_ACTIVITY_SUBMISSIONS_REQUEST: {
      return {
        ...state,
        isSubmissionsLoading: true,
        error: false,
        submissions: action.payload.startIndex === 0 ? [] : state.submissions,
        submissionListError: false,
      };
    }

    case ACTION_CONSTANTS.GET_BACKDATED_ACTIVITY_SUBMISSIONS_REQUEST: {
      return {
        ...state,
        isSubmissionsLoading: true,
        submissionListError: false,
      };
    }

    case ACTION_CONSTANTS.GET_ACTIVITY_SUBMISSIONS_SUCCESS: {
      return {
        ...state,
        isSubmissionsLoading: false,
        submissions: action.payload.submissions,
        submissionListError: false,
        updateSubmissionsList: action.payload.updateSubmissionsList
          ? action.payload.updateSubmissionsList
          : false,
      };
    }

    case ACTION_CONSTANTS.GET_ACTIVITY_SUBMISSIONS_FAILURE: {
      return {
        ...state,
        isSubmissionsLoading: false,
        submissionListError: action.payload.submissionListError,
      };
    }

    // region ASSIGN_ACTIVITY_REQUEST
    case ACTION_CONSTANTS.ASSIGN_ACTIVITY_REQUEST: {
      return {
        ...state,
        isNonActivityLoading: true,
        activityAssignSuccess: false,
        success: false,
        error: false,
      };
    }

    case ACTION_CONSTANTS.ASSIGN_ACTIVITY_SUCCESS: {
      return {
        ...state,
        isNonActivityLoading: false,
        activityAssignSuccess: true,
        success: true,
        error: false,
      };
    }

    case ACTION_CONSTANTS.ASSIGN_ACTIVITY_FAILURE: {
      return {
        ...state,
        isNonActivityLoading: false,
        error: action.payload.error,
      };
    }
    // end region

    // region SET_ACTIVITY_REMINDER_REQUEST
    case ACTION_CONSTANTS.SET_ACTIVITY_REMINDER_REQUEST: {
      return {
        ...state,
        isNonActivityLoading: true,
        success: false,
        scheduleSuccess: false,
        error: false,
      };
    }

    case ACTION_CONSTANTS.SET_ACTIVITY_REMINDER_SUCCESS: {
      return {
        ...state,
        isNonActivityLoading: false,
        scheduleSuccess: true,
        success: true,
        error: false,
      };
    }

    case ACTION_CONSTANTS.SET_ACTIVITY_REMINDER_FAILURE: {
      return {
        ...state,
        isNonActivityLoading: false,
        scheduleSuccess: false,
        error: action.payload.error,
      };
    }
    // end region

    // region DELETE_ACTIVITY_REMINDER_REQUEST
    case ACTION_CONSTANTS.DELETE_ACTIVITY_REMINDER_REQUEST: {
      return {
        ...state,
        isNonActivityLoading: true,
        scheduleSuccess: false,
        success: false,
        error: false,
      };
    }

    case ACTION_CONSTANTS.DELETE_ACTIVITY_REMINDER_SUCCESS: {
      return {
        ...state,
        isNonActivityLoading: false,
        scheduleSuccess: true,
        success: true,
        error: false,
      };
    }

    case ACTION_CONSTANTS.DELETE_ACTIVITY_REMINDER_FAILURE: {
      return {
        ...state,
        scheduleSuccess: false,
        isNonActivityLoading: false,
        error: action.payload.error,
      };
    }
    // end region

    // region SET_ACTIVITY_SCHEDULE_REQUEST
    case ACTION_CONSTANTS.SET_ACTIVITY_SCHEDULE_REQUEST: {
      return {
        ...state,
        isNonActivityLoading: true,
        success: false,
        scheduleSuccess: false,
        error: false,
      };
    }

    case ACTION_CONSTANTS.SET_ACTIVITY_SCHEDULE_SUCCESS: {
      return {
        ...state,
        isNonActivityLoading: false,
        success: true,
        scheduleSuccess: true,
        error: false,
      };
    }

    case ACTION_CONSTANTS.SET_ACTIVITY_SCHEDULE_FAILURE: {
      return {
        ...state,
        isNonActivityLoading: false,
        error: action.payload.error,
      };
    }
    // end region

    //#region POST_ACTIVITY_PRINT_REQUEST
    case ACTION_CONSTANTS.POST_ACTIVITY_PRINT_REQUEST: {
      return {
        ...state,
        isNonActivityLoading: true,
        success: false,
        printSubmissionError: false,
      };
    }

    case ACTION_CONSTANTS.POST_ACTIVITY_PRINT_SUCCESS: {
      return {
        ...state,
        isNonActivityLoading: false,
        printSubmissionError: false,
        success: true,
      };
    }

    case ACTION_CONSTANTS.POST_ACTIVITY_PRINT_FAILURE: {
      return {
        ...state,
        isNonActivityLoading: false,
        printSubmissionError: action.payload.error,
      };
    }
    //#endregion

    //#region POST_FORM_ACTIVITY_PRINT_SUCCESS
    case ACTION_CONSTANTS.POST_FORM_ACTIVITY_PRINT_REQUEST: {
      return {
        ...state,
        isFormPrintLoading: true,
        isFormPrintSuccess: false,
        isFormPrintError: false,
        error: false,
      };
    }

    case ACTION_CONSTANTS.POST_FORM_ACTIVITY_PRINT_SUCCESS: {
      return {
        ...state,
        isFormPrintLoading: false,
        isFormPrintError: false,
        isFormPrintSuccess: true,
      };
    }

    case ACTION_CONSTANTS.POST_FORM_ACTIVITY_PRINT_FAILURE: {
      return {
        ...state,
        isFormPrintLoading: false,
        isFormPrintError: action.payload.error,
      };
    }
    //#endregion POST_FORM_ACTIVITY_PRINT_SUCCESS

    case ACTION_CONSTANTS.SET_CURRENT_INSTANCE: {
      return {
        ...state,
        focusedInstance: action.payload,
        submissions: [],
      };
    }

    // region POST_ACTIVITY_SUBMISSION
    case ACTION_CONSTANTS.POST_ACTIVITY_SUBMISSION_REQUEST: {
      return {
        ...state,
        isAddSubmissionLoading: true,
        isAddSubmissionSuccess: false,
        isAddSubmissionError: false,
      };
    }

    case ACTION_CONSTANTS.POST_ACTIVITY_SUBMISSION_SUCCESS: {
      const payloadSubmissions = action.payload
        ? action.payload
        : state.submissions;
      return {
        ...state,
        isAddSubmissionLoading: false,
        isAddSubmissionError: false,
        isAddSubmissionSuccess: true,
        submissions: payloadSubmissions,
      };
    }

    case ACTION_CONSTANTS.POST_ACTIVITY_SUBMISSION_FAILURE: {
      // console.log('POST_ACTIVITY_SUBMISSION_FAILURE');
      return {
        ...state,
        isAddSubmissionLoading: false,
        isAddSubmissionError: action.payload.error,
      };
    }
    // end region

    // region POST_ACTIVITY_ATTACHMENT
    case ACTION_CONSTANTS.POST_ACTIVITY_ATTACHMENT_REQUEST: {
      return {
        ...state,
        success: false,
        error: false,
      };
    }

    case ACTION_CONSTANTS.POST_ACTIVITY_ATTACHMENT_SUCCESS: {
      return {
        ...state,
        error: false,
        success: false,
        attachment: action.payload.attachment,
      };
    }

    case ACTION_CONSTANTS.POST_ACTIVITY_ATTACHMENT_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    // end region

    // region REMOVE_ACTIVITY_SUBMISSION
    case ACTION_CONSTANTS.REMOVE_ACTIVITY_SUBMISSION_REQUEST: {
      return {
        ...state,
        isAddSubmissionLoading: true,
        success: false,
        error: false,
        isAddSubmissionSuccess: false,
      };
    }

    case ACTION_CONSTANTS.REMOVE_ACTIVITY_SUBMISSION_SUCCESS: {
      return {
        ...state,
        isAddSubmissionLoading: false,
        error: false,
        isAddSubmissionSuccess: true,
        removePending: action.payload ? action.payload.removePending : false,
        submissions:
          action.payload && action.payload.submissions
            ? action.payload.submissions
            : state.submissions,
      };
    }

    case ACTION_CONSTANTS.REMOVE_ACTIVITY_SUBMISSION_FAILURE: {
      return {
        ...state,
        isAddSubmissionLoading: false,
        error: action.payload.error,
      };
    }
    // end region

    case ACTION_CONSTANTS.GET_PERFORMANCE_ACTIVITIES_REQUEST: {
      return {
        ...state,
        isPerformanceActivitiesLoading: true,
        performanceActivitiesError: false,
        performanceActivitiesSuccess: false,
      };
    }
    case ACTION_CONSTANTS.GET_PERFORMANCE_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        performanceActivities: action.payload.separatedPerformanceActivities,
        customPerformanceActivities: action.payload.separatedCustomActivities,
        //if there are no activities and they are getting fetched from server
        //yet user is on performance list show loader
        isPerformanceActivitiesLoading:
          action.payload.noActivitiesPresent && state.isLoading ? true : false,
        performanceActivitiesSuccess: true,
      };
    }
    case ACTION_CONSTANTS.GET_PERFORMANCE_ACTIVITIES_FAILURE: {
      return {
        ...state,
        isPerformanceActivitiesLoading: false,
        performanceActivitiesError: action.payload.error,
      };
    }

    case ACTION_CONSTANTS.FILTER_PERFORMANCE_ACTIVITIES: {
      // console.log('FILTER_PERFORMANCE_ACTIVITIES', action.payload);
      return {
        ...state,
        performanceActivitiesFilter: action.payload,
      };
    }

    // region SYNC_ACTIVITY_SUBMISSIONS
    case ACTION_CONSTANTS.SYNC_ACTIVITY_SUBMISSIONS_REQUEST: {
      return {
        ...state,
        isAddSubmissionSuccess: false,
        isAddSubmissionError: false,
      };
    }

    case ACTION_CONSTANTS.SYNC_ACTIVITY_SUBMISSIONS_SUCCESS: {
      return {
        ...state,
        isAddSubmissionError: false,
        isAddSubmissionSuccess: true,
        submissions: action.payload,
        updateSubmissionsList: false,
      };
    }

    case ACTION_CONSTANTS.SYNC_ACTIVITY_SUBMISSIONS_FAILURE: {
      return {
        ...state,
        isAddSubmissionError: action.payload.error,
      };
    }
    // end region

    // region POST_PENDING_ACTIVITY_SUBMISSIONS
    case ACTION_CONSTANTS.POST_PENDING_ACTIVITY_SUBMISSIONS_REQUEST: {
      return {
        ...state,
        success: false,
        error: false,
      };
    }
    case ACTION_CONSTANTS.POST_PENDING_ACTIVITY_SUBMISSIONS_SUCCESS: {
      return {
        ...state,
        success: true,
        submissions: [],
      };
    }
    case ACTION_CONSTANTS.POST_PENDING_ACTIVITY_SUBMISSIONS_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    // end region

    // region POST_ACTIVITY_SUBMISSION_ON_SERVER
    case ACTION_CONSTANTS.POST_ACTIVITY_SUBMISSION_ON_SERVER_REQUEST: {
      return {
        ...state,
        isAddSubmissionLoading: true,
        isAddSubmissionSuccess: false,
        isAddSubmissionError: false,
      };
    }

    case ACTION_CONSTANTS.POST_ACTIVITY_SUBMISSION_ON_SERVER_SUCCESS: {
      return {
        ...state,
        isAddSubmissionLoading: false,
        isAddSubmissionError: false,
        isAddSubmissionSuccess: true,
      };
    }
    case ACTION_CONSTANTS.POST_ACTIVITY_SUBMISSION_ON_SERVER_REMOVED: {
      return {
        ...state,
        isAddSubmissionLoading: false,
      };
    }

    case ACTION_CONSTANTS.POST_ACTIVITY_SUBMISSION_ON_SERVER_FAILURE: {
      const payloadSubmissions = action.payload.submissions
        ? action.payload.submissions
        : state.submissions;
      return {
        ...state,
        isAddSubmissionLoading: false,
        isAddSubmissionError: action.payload.error,
        submissions: payloadSubmissions,
      };
    }
    // end region

    // region POST_REMOVE_ACTIVITY_SUBMISSION_ON_SERVER
    case ACTION_CONSTANTS.POST_REMOVE_ACTIVITY_SUBMISSION_ON_SERVER_REQUEST: {
      return {
        ...state,
        success: false,
        error: false,
        isAddSubmissionLoading: true,
        isAddSubmissionSuccess: false,
        isAddSubmissionError: false,
      };
    }

    case ACTION_CONSTANTS.POST_REMOVE_ACTIVITY_SUBMISSION_ON_SERVER_SUCCESS: {
      return {
        ...state,
        isAddSubmissionLoading: false,
        error: false,
        success: true,
        // submissions: action.payload,
      };
    }

    case ACTION_CONSTANTS.POST_REMOVE_ACTIVITY_SUBMISSION_ON_SERVER_FAILURE: {
      return {
        ...state,
        isAddSubmissionLoading: false,
        error: action.payload.error,
      };
    }
    // end region

    // region GET_ACTIVITY_REVISION
    case ACTION_CONSTANTS.GET_ACTIVITY_REVISION_REQUEST: {
      return {
        ...state,
        submissionListError: false,
        // isNonActivityLoading: true,
      };
    }

    case ACTION_CONSTANTS.GET_ACTIVITY_REVISION_SUCCESS: {
      return {
        ...state,
        // isNonActivityLoading: false,
        revision: action.payload,
        submissionListError: false,
      };
    }

    case ACTION_CONSTANTS.GET_ACTIVITY_REVISION_FAILURE: {
      return {
        ...state,
        // isNonActivityLoading: false,
        submissionListError: action.payload.error,
        revision: null,
      };
    }
    // end region

    case ACTION_CONSTANTS.RESET_SUBMISSION_PROPS: {
      return {
        ...state,
        revision: null,
      };
    }

    //#region GET_ATTACHMENT_SUBMISSION_FILE_PATH_REQUEST
    case ACTION_CONSTANTS.GET_ATTACHMENT_SUBMISSION_FILE_PATH_REQUEST:
      return {
        ...state,
        isFormPrintLoading: true,
        isFormPrintError: false,
        success: false,
        error: false,
        attachmentSubmissionFilePath: null,
      };

    case ACTION_CONSTANTS.GET_ATTACHMENT_SUBMISSION_FILE_PATH_SUCCESS:
      return {
        ...state,
        isFormPrintLoading: false,
        attachmentSubmissionFilePath: action.payload.filePath,
      };

    case ACTION_CONSTANTS.GET_ATTACHMENT_SUBMISSION_FILE_PATH_FAILURE:
      return {
        ...state,
        isFormPrintLoading: false,
        error: action.payload.error,
      };
    //#endregion

    //#region RESET_SUBMISSIONS
    case ACTION_CONSTANTS.RESET_SUBMISSIONS_REQUEST:
      return {
        ...state,
        submissions: state.submissions.slice(0, 10),
      };

    case ACTION_CONSTANTS.RESET_SUBMISSIONS_SUCCESS:
      return {
        ...state,
        submissions: action.payload.submissions,
      };

    case ACTION_CONSTANTS.RESET_SUBMISSIONS_FAILURE:
      return {
        ...state,
        submissions: [],
      };
    //#endregion

    default:
      return state;
  }
};

export default activities;
