export default {
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',

  SHOW_ALERT: 'SHOW_ALERT',
  HIDE_ALERT: 'HIDE_ALERT',

  SET_NOTIFICATION_DATA: 'SET_NOTIFICATION_DATA',
  SET_DEVICE_INFO: 'SET_DEVICE_INFO',
  
  SET_BOTTOM_BAR_TAB: 'SET_BOTTOM_BAR_TAB',
};
