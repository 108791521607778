import React, { Component } from 'react';
import PropTypes from 'prop-types';

class EmptyListMsg extends Component {
  render() {
    if (this.props.loading) {
      return null;
    }
    if (this.props.emptyTxt) {
      return (
        <div>
          <div>{this.props.emptyTxt}</div>
        </div>
      );
    } else {
      return null;
    }
  }
}

EmptyListMsg.propTypes = {
  emptyTxt: PropTypes.string,
  loading: PropTypes.bool,
};

export default EmptyListMsg;
