import AuditHelper from '../helpers/AuditHelper';

const auditAssignmentModel = (auditAssignment) => ({
  audit_assignment_id: auditAssignment.audit_assignment_id,
  audit_id: auditAssignment.audit_id,
  audit_date: auditAssignment.audit_date,
  audit_summary: auditAssignment.audit_summary,
  audit_type: auditAssignment.audit_type,
  feedlot_id: auditAssignment.feedlot_id,
  start_date: auditAssignment.start_date,
  status: auditAssignment.status,
  assigned_to_mobile: auditAssignment.assigned_to_mobile,
  is_audit_announced: auditAssignment.is_audit_announced,
  audit: auditAssignment.audit,
  non_conformances: AuditHelper.getauditNCs(auditAssignment),
  update_date: auditAssignment.update_date,
  auditor_name: auditAssignment.auditor_name,
});

export default auditAssignmentModel;
