export default {
  AUTH: 'auth',
  LOGIN: 'Login',
  LOCK_SCREEN: 'LockScreen',
  FORGOT_PASSWORD: 'ForgotPassword',
  GENERATE_PIN: 'GeneratePin',
  FEEDYARD_LIST: 'FeedyardList',
  MAIN: 'Main',

  //#region  dashboard
  DASHBOARD_STACK: 'DashboardStack',
  DASHBOARD: 'Dashboard',
  ACTIVE_WORKERS: 'ActiveWorkers',
  ACTIVITY_PERFORMANCE: 'ActivityPerformance',
  EXPORT_COMPLIANCE: 'ExportCompliance',
  BACKDATED_SUBMISSIONS: 'BackDatedSubmissions',
  BACKDATED_ACTIVITY_FILTER: 'BackdatedFilter',
  COACHMARK: 'Coachmark',
  NOTIFICATION: 'Notification',
  VIEW_DOCUMENT_REPORT: 'ViewDocumentReport',
  PENDING_ACTIONS: 'PendingActions',
  //#endregion

  //#region Program manual
  PROGRAM_MANUAL: 'ProgramManual',
  VIEW_DOCUMENT: 'ViewDocument',
  PROGRAM_MANUAL_DETAIL: 'ProgramManualDetail',
  ADD_SUBMISSION: 'AddSubmission',
  ADD_DOCUMENT: 'AddDocument',
  VISIBLE_TO: 'VisibleTo',
  PROGRAM_MANUAL_BIN: 'ProgramManualBin',
  PROGRAM_MANUAL_MISSED: 'ProgramManualMissed',
  EMAIL_REPORT: 'EmailReport',
  DOCUMENTS_ASSIGNED_LIST: 'DocumentsAssignedList',
  ADD_TRAINING: 'AddTraining',
  SELECT_TAGS: 'SelectTags',
  ASSIGNED_USERS: 'AssignedUsers',
  PROGRAM_MANUAL_FILTER: 'ProgramManualFilter',
  //#endregion

  //#region users
  USERS: 'Users',
  ADD_USER: 'AddUser',
  EDIT_USER: 'EditUser',
  //#endregion

  //#region Activities
  ACTIVITIES: 'Activities',
  ACTIVITY_ASSIGN_LOG: 'AssignLog',
  ACTIVITY_INSTANCE: 'Instance',
  ACTIVITY_REMOVED_INSTANCE: 'RemovedInstance',
  ACTIVITY_FILTER: 'Filter',
  ACTIVITY_SCHEDULE: 'Schedule',
  ACTIVITY_SUBMISSIONS: 'SubmissionList',
  ACTIVITY_TAG_LIST: 'ActivityTagList',
  ACTIVIY_PRINT: 'Print',
  ACTIVIY_BIN: 'ActivityBin',
  //#endregion

  //#region Audit
  AUDIT: 'Audits',
  AUDIT_NC_LIST: 'AuditNCList',
  NC_DETAIL: 'NCDetail',
  AUDIT_REPORT: 'AuditReport',
  //#endregion

  //#region === SETTINGS ===
  SETTINGS: 'Settings',
  CHANGE_PIN: 'ChangePin',
  CHANGE_PASSWORD: 'ChangePassword',
  //#endregion === SETTINGS ===

  //#region MORE
  MORE: 'More',
  MORE_MENU: 'MoreMenu',
  NAVIGATION_VIDEOS: 'NavigationVideos',
  VIDEO_PLAYER: 'VideoPlayer',
  SWITCH_FEEDYARD: 'SwitchFeedyard',
  APP_TRAINING_VIDEO_HTML: 'appTrainingVideoHTML',
  LOGOUT: 'Logout',
  //#endregion

  SERVER_ERROR: 'ServerError',
  SERVER_NOT_RESPONDING: 'ServerNotResponding',
};
