import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const FormButton = (props) => {
  const {
    customBtnStyles,
    customTextStyles,
    custonLnrGrdntBtnStyles,
    title,
    onPressEvent,
    disabled,
    customProps,
    customStyles,
    success,
    dark,
  } = props;

  return (
    <Button
      disabled={disabled}
      transparent={'true'}
      onClick={(e) => {
        onPressEvent && onPressEvent(e);
      }}
    >
      <div uppercase={'false'}>{title}</div>
    </Button>
  );
};

FormButton.propTypes = {
  title: PropTypes.string,
  onPressEvent: PropTypes.func,
  customBtnStyles: PropTypes.object,
  customTextStyles: PropTypes.object,
  custonLnrGrdntBtnStyles: PropTypes.object,
  success: PropTypes.bool,
};

export default FormButton;
