import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import AppContainer from '../../../../components/AppContainer';
import PBIcon from '../../../../components/PBIcon';
import PendingActionsListItem from '../../../../components/PendingActionsListItem';

import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

import { getSearchedPendingActionsRequest } from '@progressivebeef/shared/src/store/actions/dashboard';
import { globalHelper } from '@progressivebeef/shared/src/helpers';
import { Spinner } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { showErrorAlert } from '../../../../components/Alerts';

class PendingActions extends PureComponent {
  onRefresh = () => {
    const { pendingActionsQuery } = this.props;

    if (navigator.onLine) {
      this.props.searchPendingActions(pendingActionsQuery);
    } else {
      showErrorAlert(localization[getLocale()].OFFLINE_MODE_REFRESH_ERROR);
    }
  };

  render() {
    const {
      isPendingActionsLoading,
      pendingActionsQuery,
      searchedPendingActions,
      history,
    } = this.props;

    return (
      <>
        <AppContainer
          title={localization[getLocale()].ACTIVITIES_WAITING_TO_SYNC}
          onSearchPress={this.search}
          searchText={pendingActionsQuery}
          iconHeader
          IconLeftHeader={this.renderLeftHeader}
          IconRightHeader={this.renderRightHeader}
          history={history}
        >
          <InfiniteScroll
            dataLength={searchedPendingActions.length}
            hasMore={false}
            // refreshFunction={this.onRefresh}
            // pullDownToRefresh
            // pullDownToRefreshThreshold={50}
            // pullDownToRefreshContent={
            //   <div className="bottom-infinite-loader">
            //     <Spinner color={'red'} />
            //   </div>
            // }
            // releaseToRefreshContent={
            //   <div className="bottom-infinite-loader">
            //     <Spinner color={'red'} />
            //   </div>
            // }
          >
            {searchedPendingActions && searchedPendingActions.length > 0 ? (
              searchedPendingActions.map((item) => {
                return <PendingActionsListItem {...this.props} item={item} />;
              })
            ) : isPendingActionsLoading ? (
              <div className="bottom-infinite-loader">
                {globalHelper.renderIf(isPendingActionsLoading)(
                  <Spinner color={'red'} />
                )}
              </div>
            ) : (
                  <div className="no-result-found-box">
                    <p>{localization[getLocale()].NO_PENDING_ACTIONS}</p>
                  </div>
                )}
          </InfiniteScroll>
        </AppContainer>
      </>
    );
  }

  search = (query) => {
    this.props.searchPendingActions(query);
  };

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          type={'FontAwesome'}
          name={'chevron-left'}
          onPress={() => {
            this.props.history.goBack();
          }}
        />
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    searchPendingActions: (payload) =>
      dispatch(getSearchedPendingActionsRequest(payload)),
  };
};

const mapStateToProps = (state) => {
  const {
    isPendingActionsLoading,
    pendingActionsQuery,
    searchedPendingActions,
  } = state.dashboard;

  return {
    isPendingActionsLoading,
    pendingActionsQuery,
    searchedPendingActions,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PendingActions);
