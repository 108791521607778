import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import './style.scss';
import ActivityTag from '../ActivityTag';
import ProgramManualPickerModal from '../ProgramManualPickerModal';
import DeprecatedProgramManualPickerModal from '../DeprecatedProgramManualPickerModal';

import ProgramManualHelper from '@progressivebeef/shared/src/helpers/ProgramManualHelper';

import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

import { userRoles } from '@progressivebeef/shared/src/constants/AppConstants';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';

var click = true;

class ProgramManualCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
    };
  }

  onCardPress = (item) => {
    const { user, history } = this.props;

    if (item.document_type_key !== 5) {
      history.push(RouteConstants.PROGRAM_MANUAL_DETAIL, {
        currentProgramManual: item,
      });
    } else {
      if (
        Number(user.userRoleId) === userRoles.FEEDLOT_MANAGER ||
        Number(user.userRoleId) === userRoles.TRAINER ||
        Number(user.userRoleId) === userRoles.PROGRAM_MANAGER
      ) {
        this.setState({ modalVisible: true });
      } else {
        history.push(RouteConstants.VIEW_DOCUMENT, {
          currentProgramManual: item,
        });
      }
    }
  };

  onPickerPress = () => {
    this.setState({ modalVisible: false });
  };

  render() {
    var { item, tags, route, deprecated, history, onCardPress } = this.props;
    var { modalVisible } = this.state;
    var document_tags = ProgramManualHelper.convertToPickerModal(item, tags);

    return (
      <>
        <div
          className="program-manual-list-box"
          onMouseDown={(e) => (click = true)}
          onMouseMove={(e) => (click = false)}
          onClick={() => {
            if (click) {
              onCardPress(item);
            }
          }}>
          <div className="parent-flex-box">
            <div className="icon-box">
              <img
                alt="description"
                src={ProgramManualHelper.getDocumentIcon(
                  item.document_type_key,
                  item,
                )}
              />
            </div>
            <div className="info-box">
              <div className="main-title">
                {item.document_locale &&
                item.document_locale[getLocale() === 'en' ? 1 : 2]
                  ? item.document_locale[getLocale() === 'en' ? 1 : 2].title
                  : ''}
              </div>

              <div className="key-info-box">
                <div className="icon">
                  <img
                    alt="description"
                    src={ProgramManualHelper.getTagIcon(item.tag_key, item)}
                  />
                </div>
                <div className="label">
                  {item.document_number.replace('m-', '')}
                </div>
              </div>
            </div>
          </div>

          <div>
            {document_tags && document_tags.length > 0 && (
              <div>
                <ActivityTag
                  route={route}
                  tags={document_tags}
                  history={history}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state.authentication;

  return {
    user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramManualCard);
