import { LOCAL_STORAGE_KEYS, FEEDLOT_RANK } from '../../constants/AppConstants';

class AuthManager {
  clearDBOnLogout() {
    try {
      window.localStorage.clear();
    } catch (err) {
      console.log(err);
    }
  }

  clearDBOnFeedyardChange() {
    try {
      let keysToRemove = [
        LOCAL_STORAGE_KEYS.TAGS,
        LOCAL_STORAGE_KEYS.ACTIVITIES,
        LOCAL_STORAGE_KEYS.ACTIVITY_BACKDATED_SUBMISSIONS,
        LOCAL_STORAGE_KEYS.USERS,
        LOCAL_STORAGE_KEYS.INSTANCES,
        LOCAL_STORAGE_KEYS.META_DATA,
        LOCAL_STORAGE_KEYS.MISSED_ACTIVITIES_NOTIFICATION,
        LOCAL_STORAGE_KEYS.PROGRAM_MANUALS,
        LOCAL_STORAGE_KEYS.PROGRAM_MANUAL_TAGS,
        LOCAL_STORAGE_KEYS.SUBMISSIONS,
        LOCAL_STORAGE_KEYS.WORKER,
        LOCAL_STORAGE_KEYS.LAST_NOTIFCATION_SYNC_TIME,
        LOCAL_STORAGE_KEYS.NOTIFICATION_COUNT,
        LOCAL_STORAGE_KEYS.AUDITS,
        LOCAL_STORAGE_KEYS.NOTIFICATIONS,
        LOCAL_STORAGE_KEYS.NOTIFICATIONS_UPDATE,
        LOCAL_STORAGE_KEYS.AUDITS,
        FEEDLOT_RANK.COUNT,
        FEEDLOT_RANK.POSITION,
        FEEDLOT_RANK.PERFORMANCE,
      ];
      for (let i = 0; i < keysToRemove.length; i++) {
        localStorage.removeItem(keysToRemove[i]);
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default new AuthManager();
