import { request, success, failure } from './index';
import ACTION_CONSTANTS from '../../constants/actionConstants';

//#region getUsers
export function getUsers(payload) {
  return request(ACTION_CONSTANTS.GET_USERS, payload);
}

export function getUsersRequested(payload) {
  return request(ACTION_CONSTANTS.GET_USERS_REQUEST, payload);
}

export function getUsersSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_USERS_SUCCESS, payload);
}

export function getUsersFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_USERS_FAILURE, payload);
}
//#endregion

//#region search Users
export function searchUsersRequested(payload) {
  return request(ACTION_CONSTANTS.SEARCH_USERS_REQUEST, payload);
}

export function searchUsersSuccess(payload) {
  return success(ACTION_CONSTANTS.SEARCH_USERS_SUCCESS, payload);
}

export function searchUsersFailure(payload) {
  return failure(ACTION_CONSTANTS.SEARCH_USERS_FAILURE, payload);
}
//#endregion

export function getUsersListRequest(payload = {}) {
  return request(ACTION_CONSTANTS.GET_ASSIGN_LOGS_USERS_LIST_REQUEST, payload);
}

export function getUsersListSuccess(payload) {
  return success(ACTION_CONSTANTS.GET_ASSIGN_LOGS_USERS_LIST_SUCCESS, payload);
}

export function getUsersListFailure(payload) {
  return failure(ACTION_CONSTANTS.GET_ASSIGN_LOGS_USERS_LIST_FAILURE, payload);
}

export function searchUsersListInStore(payload) {
  return request(ACTION_CONSTANTS.SEARCH_ASSIGN_LOGS_USERS_LIST_STORE, payload);
}

export function clearAssignLogSearch(payload) {
  return request(ACTION_CONSTANTS.RESET_ASSIGN_LOG_SEARCH, payload);
}

export function searchUsersListSuccess(payload) {
  return success(
    ACTION_CONSTANTS.SEARCH_ASSIGN_LOGS_USERS_LIST_SUCCESS,
    payload,
  );
}

export function updateSearchUsersListItem(payload) {
  return success(ACTION_CONSTANTS.UPDATE_SEARCH_ASSIGN_LOG_USERS, payload);
}

//#region add Users
export function addUserRequested(payload) {
  return request(ACTION_CONSTANTS.ADD_USER_REQUEST, payload);
}

export function addUserSuccess(payload) {
  return success(ACTION_CONSTANTS.ADD_USER_SUCCESS, payload);
}

export function addUserFailure(payload) {
  return failure(ACTION_CONSTANTS.ADD_USER_FAILURE, payload);
}
//#endregion

export function resetUserProps(payload) {
  return request(ACTION_CONSTANTS.RESET_USER_PROPS, payload);
}

//#region ==== Change Pin ====
export function changePinRequest(payload) {
  return request(ACTION_CONSTANTS.CHANGE_PIN_REQUEST, payload);
}
export function changePinSuccess(payload) {
  return success(ACTION_CONSTANTS.CHANGE_PIN_SUCCESS, payload);
}
export function changePinFailure(payload) {
  return failure(ACTION_CONSTANTS.CHANGE_PIN_FAILURE, payload);
}
//#endregion ==== Change Pin ===

//#region ==== Change Password ====
export function changePasswordRequest(payload) {
  return request(ACTION_CONSTANTS.CHANGE_PASSWORD_REQUEST, payload);
}
export function changePasswordSuccess(payload) {
  return success(ACTION_CONSTANTS.CHANGE_PASSWORD_SUCCESS, payload);
}
export function changePasswordFailure(payload) {
  return failure(ACTION_CONSTANTS.CHANGE_PASSWORD_FAILURE, payload);
}
//#endregion ==== Change Password ===

//#region ==== unlockuser ====
export function unlockUserRequest(payload) {
  return request(ACTION_CONSTANTS.UNLOCK_USER_REQUEST, payload);
}
export function unlockUserSuccess(payload) {
  return success(ACTION_CONSTANTS.UNLOCK_USER_SUCCESS, payload);
}
export function unlockUserFailure(payload) {
  return failure(ACTION_CONSTANTS.UNLOCK_USER_FAILURE, payload);
}
//#endregion ==== unlockuser ===
