import React, { Component } from 'react';
import { connect } from 'react-redux';
import './style.scss';

//custom components
import ActivityTag from '../ActivityTag';
import PBIcon from '../PBIcon';
import FormButton from '../FormButton';

//helper
import UserHelper from '@progressivebeef/shared/src/helpers/UsersHelper';
import ProgramManualHelper from '@progressivebeef/shared/src/helpers/ProgramManualHelper';

import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import localization from '@progressivebeef/shared/src/localization/i18n';

class DocumentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: UserHelper.setAssignedDocumentSelected(
        this.props.documents,
        this.props.values.document_numbers
      ),
    };
  }

  onSelectItem = (data) => {
    data.isSelect = !data.isSelect;
    const index = this.state.documents.findIndex((key) => data.key === key);
    this.state.documents[index] = data;

    this.setState({
      documents: this.state.documents,
    });
  };

  render() {
    let { documents } = this.state;
    const selected = documents.filter((q) => q.isSelect);
    return (
      <>
        {documents && documents.length > 0 ? (
          <>
            {documents.map((doc) => this.renderItem(doc))}
            <div className="document-submit-bottom">
              <FormButton
                success
                title={localization[getLocale()].SAVE}
                onPressEvent={() => this.props.onSubmit(selected)}
              />
            </div>
          </>
        ) : (
          <div className="no-result-found-box">
            <h3>{localization[getLocale()].NO_RECORD_FOUND}</h3>
          </div>
        )}
      </>
    );
  }

  renderItem(item) {
    let { tags, history, route } = this.props;

    let document_tags = ProgramManualHelper.convertToPickerModal(item, tags);

    return (
      <div
        className="add-user-document-list-box"
        onClick={() => this.onSelectItem(item)}
        key={item.feedlot_document_id.toString()}
      >
        <div>
          <div className="label">
            {item.document_locale &&
              item.document_locale[getLocale() === 'en' ? 1 : 2]
              ? item.document_locale[getLocale() === 'en' ? 1 : 2].title
              : ''}
          </div>
          <div className="info">{item.document_number.replace('m-', '')}</div>
        </div>

        <div>
          {document_tags && (
            <div>
              <ActivityTag
                //   route={route}
                history={history}
                tags={document_tags}
                //   customStyle={styles.tagStyle}
              />
            </div>
          )}
        </div>

        <div className="icon-check">
          {item.isSelect && <PBIcon name={item.isSelect ? 'check' : ''} />}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  const { isLoading, documents, tags } = state.programManuals;

  return {
    isLoading,
    documents,
    tags,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentList);
