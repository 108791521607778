import React from 'react';
import { connect } from 'react-redux';

import DashboardSmallCard from '../../../../components/DashboardSmallCard/dashboardSmallCard';

import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import localization from '@progressivebeef/shared/src/localization/i18n';

import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';

import { trackEvent } from '@progressivebeef/shared/src/services/reactMixpanelService';
import { Events } from '@progressivebeef/shared/src/constants/MixpanelConstants';

const ActiveWorkerCard = (props) => {
  const { activeWorkers, nonActiveWorkers, isWorkersLoading } = props;
  let total = activeWorkers.length + nonActiveWorkers.length;

  return (
    <DashboardSmallCard
      loading={isWorkersLoading}
      heading={localization[getLocale()].WORKERS_ACTIVE_TODAY}
      data={`${activeWorkers.length}/${total}`}
      iconName="user-cowboy"
      onPress={() => {
        if (!isWorkersLoading) {
          trackEvent(Events.VIEWED_WORKER_ACTIVITY);
          props.history.push(RouteConstants.ACTIVE_WORKERS);
        }
      }}
    />
  );
};

const mapStateToProps = (state) => {
  const { isWorkersLoading, activeWorkers, nonActiveWorkers } = state.dashboard;

  return {
    isWorkersLoading,
    activeWorkers,
    nonActiveWorkers,
  };
};

export default connect(mapStateToProps)(ActiveWorkerCard);
