export default {
  GET_ASSIGNED_FEEDYARDS_REQUESTED: 'GET_ASSIGNED_FEEDYARDS_REQUESTED',
  GET_ASSIGNED_FEEDYARDS_SUCCESS: 'GET_ASSIGNED_FEEDYARDS_SUCCESS',
  GET_ASSIGNED_FEEDYARDS_FAILURE: 'GET_ASSIGNED_FEEDYARDS_FAILURE',

  SELECT_FEEDYARD_REQUESTED: 'SELECT_FEEDYARD_REQUESTED',
  SELECT_FEEDYARD_SUCCESS: 'SELECT_FEEDYARD_SUCCESS',
  SELECT_FEEDYARD_FAILURE: 'SELECT_FEEDYARD_FAILURE',

  GET_FILTERED_FEEDYARD_LIST_REQUEST: 'GET_FILTERED_FEEDYARD_LIST_REQUEST',
  GET_FILTERED_FEEDYARD_LIST_SUCCESS: 'GET_FILTERED_FEEDYARD_LIST_SUCCESS',
  GET_FILTERED_FEEDYARD_LIST_FAILURE: 'GET_FILTERED_FEEDYARD_LIST_FAILURE',
};
