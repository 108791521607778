import React from 'react';
import PropTypes from 'prop-types';
import './style.scss'

import BubbleLabel from '../../../../../components/BubbleLabel';

import DashboardHelper from '@progressivebeef/shared/src/helpers/DashboardHelper';
import ActivitiesHelper from '@progressivebeef/shared/src/helpers/ActivitiesHelper';

import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import AssetConstants from '@progressivebeef/shared/src/constants/AssetConstants';

const MissedActvityCard = (props) => {
  const { item, onPress } = props;
  return (
    <div className="box" onClick={onPress}>
      <div className="icon">
        <img alt="desc" src={AssetConstants.missed} />
      </div>
      <div className="desc">
       {item.feedlot_activity.locale[getLocale() === 'en' ? 1 : 2].title &&
        <div className="title">
          {`${item.feedlot_activity.locale[getLocale() === 'en' ? 1 : 2].title}`}
        </div>
        }
        <div className="log-info">
          <div className="img">
            <img
              alt="desc"
              src={
                AssetConstants[
                ActivitiesHelper.getActivityTypeIcon(item.activity_type_key)
                ]
              }
            />
          </div>
          <div className="txt">{`${item.feedlot_activity.activity_number}`}</div>
        </div>
      </div>

      <div className="day-status">
        <BubbleLabel
          text={`${DashboardHelper.getNotificationTimeText(
            item.notification_create_datetime
          )}`}
        />
      </div>
    </div>
  );
};

MissedActvityCard.propTypes = {
  item: PropTypes.object,
  onPress: PropTypes.func,
};

export default MissedActvityCard;
