import React, { PureComponent } from 'react';
import { Modal } from 'reactstrap';

import AppContainer from '../AppContainer';
import PBIcon from '../PBIcon';
import MultiSelectList from '../MultiSelectList';

import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

class ActivityTagList extends PureComponent {
  render() {
    const { history, tags, modalVisible } = this.props;

    return (
      <Modal className="visible-to-user-popup" isOpen={modalVisible}>
        <AppContainer
          title={localization[getLocale()].ASSIGNED_TAGS}
          iconHeader
          IconLeftHeader={this.renderLeftHeader}
          noncollapsible
          history={history}
        >
          <MultiSelectList
            disabled
            buttonText={localization[getLocale()].APPLY_TAGS}
            data={tags}
          />
        </AppContainer>
      </Modal>
    );
  }

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          name={'chevron-left'}
          onPress={() => this.props.dismissModal()}
        />
      </div>
    );
  };
}

export default ActivityTagList;
