import React from 'react';
import { Field, FieldArray } from 'formik';

//components
import LabelField from './labelField';
import InputField from './inputField';
import DateField from './dateField';
import TextAreaField from './textAreaField';
import MultiSelectField from './multiSelectField';
import AttachmentField from './attachmentField';
import SingleSelectField from './singleSelectField';
import EmployeeField from './employeeField';

import { SUBMISSION_FIELD_TYPES } from '@progressivebeef/shared/src/constants/FormConstants';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import localization from '@progressivebeef/shared/src/localization/i18n';
import { KEYBOARD_TYPES } from '@progressivebeef/shared/src/constants/AppConstants';

export default ({
  field,
  activityType,
  disabled,
  submissionNumber,
  specialProperty,
  fields,
}) => {
  switch (field.field_type_key) {
    case SUBMISSION_FIELD_TYPES.TEXT_FIELD:
      return (
        <Field
          name={field.group_id}
          data={field}
          disabled={disabled}
          violated={field.violated}
          placeholder={localization[getLocale()].ENTER_TEXT}
          component={InputField}
        />
      );

    case SUBMISSION_FIELD_TYPES.INTEGER:
      return (
        <Field
          name={field.group_id}
          data={field}
          keyboardType={KEYBOARD_TYPES.NUMBER}
          disabled={disabled}
          violated={field.violated}
          placeholder={localization[getLocale()].ENTER_NUMBER}
          component={InputField}
        />
      );

    case SUBMISSION_FIELD_TYPES.DECIMAL:
      return (
        <Field
          name={field.group_id}
          data={field}
          keyboardType={KEYBOARD_TYPES.DECIMAL}
          disabled={disabled}
          violated={field.violated}
          placeholder={localization[getLocale()].ENTER_DECIMAL}
          component={InputField}
        />
      );

    case SUBMISSION_FIELD_TYPES.SINGLE_SELECT:
      return (
        <FieldArray
          name={field.group_id}
          render={(props) => (
            <SingleSelectField {...props} data={field} disabled={disabled} />
          )}
        />
      );

    case SUBMISSION_FIELD_TYPES.MULTI_SELECT:
      return (
        <FieldArray
          name={field.group_id}
          render={(props) => (
            <MultiSelectField
              {...props}
              data={field}
              disabled={disabled}
              submissionNumber={submissionNumber}
              specialProperty={specialProperty}
            />
          )}
        />
      );

    case SUBMISSION_FIELD_TYPES.DATE_FIELD:
      return (
        <Field
          name={field.group_id}
          data={field}
          disabled={disabled}
          violated={field.violated}
          component={DateField}
        />
      );

    case SUBMISSION_FIELD_TYPES.TEXT_AREA:
      return (
        <Field
          name={field.group_id}
          data={field}
          disabled={disabled}
          violated={field.violated}
          component={TextAreaField}
        />
      );

    case SUBMISSION_FIELD_TYPES.PARAGRAPH:
      return (
        <Field
          name={field.group_id}
          data={field}
          fieldType={SUBMISSION_FIELD_TYPES.PARAGRAPH}
          component={LabelField}
        />
      );

    case SUBMISSION_FIELD_TYPES.FORMULA:
      return (
        <Field
          name={field.group_id}
          data={field}
          fieldType={SUBMISSION_FIELD_TYPES.FORMULA}
          violated={field.violated}
          component={LabelField}
          fields={fields}
        />
      );

    case SUBMISSION_FIELD_TYPES.EMPLOYEES:
      return (
        <Field
          name={field.group_id}
          data={field}
          disabled={disabled}
          component={EmployeeField}
        />
      );

    case SUBMISSION_FIELD_TYPES.ATTACHMENT:
      return (
        <FieldArray
          name={field.group_id}
          render={(props) => (
            <AttachmentField
              {...props}
              data={field}
              activityType={activityType}
              disabled={disabled}
            />
          )}
        />
      );

    case SUBMISSION_FIELD_TYPES.LABEL:
      return (
        <Field
          name={field.group_id}
          data={field}
          fieldType={SUBMISSION_FIELD_TYPES.LABEL}
          component={LabelField}
        />
      );

    default:
      return null;
  }
};
