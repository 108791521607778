// modules
import React, { Component } from 'react';

import DescriptionView from '../DescriptionView';

//helper
import { globalHelper } from '@progressivebeef/shared/src/helpers';
import localization from '@progressivebeef/shared/src/localization/i18n';
import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';

//Styles
import './style.scss';
import AttachmentPreview from '../AttachmentPreview';
import { ATTACHMENT_TYPES } from '@progressivebeef/shared/src/constants/FormConstants';

class CommentCard extends Component {
  getCommentTime = () => {
    let { item } = this.props;
    //removed timezone
    return globalHelper.getDateInFromNowFormat(item.create_datetime);
  };

  render() {
    let { item, openPreview } = this.props;
    return (
      <div className="comment-card-box">
        <DescriptionView
          title={item.commentator_user_name}
          subTitle={
            localization[getLocale()]['USER_ROLE_' + item.commentator_user_role]
          }
          description={item.comment}
        />
        {item.audit_question_comment_attachments?.length > 0 && (
          <div className="attachments">
            {item.audit_question_comment_attachments.map((attachment, i) => {
              const isPDF =
                attachment.file_name.substring(
                  attachment.file_name.length - 3,
                ) === 'pdf';
              return (
                <AttachmentPreview
                  onClick={(source) => {
                    openPreview(
                      source,
                      isPDF ? ATTACHMENT_TYPES.PDF : ATTACHMENT_TYPES.JPEG,
                    );
                  }}
                  key={i}
                  hideClose
                  isComment
                  isPDF={isPDF}
                  fileName={attachment.file_name}
                  shouldFetch
                  data={attachment.data}
                />
              );
            })}
          </div>
        )}
        <div className="comment-time">{this.getCommentTime()}</div>
      </div>
    );
  }
}

export default CommentCard;
