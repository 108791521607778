import ACTION_CONSTANTS from '../../constants/actionConstants/index';

export const initialState = {
  isLoading: false,
  downloadLoading: false,
  loadingText: '',
  programManuals: [],
  query: '',
  downloaded: false,
  deleted: false,
  downloadedVideo: [],
};

const navigationVideos = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_CONSTANTS.RESET_VIDEOS_PAYLOAD: {
      // console.log('RESET_VIDEOS_PAYLOAD');
      return {
        ...state,
        query: '',
        sort: false,
        downloaded: false,
      };
    }

    case ACTION_CONSTANTS.RESET_VIDEOS_DOWNLOAD: {
      // console.log('RESET_VIDEOS_DOWNLOAD');
      return {
        ...state,
        downloaded: false,
        deleted: false,
      };
    }

    case ACTION_CONSTANTS.DELETE_VIDEOS: {
      // console.log('DELETE_VIDEOS');
      return {
        ...state,
        deleted: true,
      };
    }

    case ACTION_CONSTANTS.GET_VIDEOS: {
      // console.log('GET_VIDEOS');
      return {
        ...state,
        isLoading: true,
      };
    }

    case ACTION_CONSTANTS.GET_VIDEOS_SUCCESS: {
      // console.log('GET_VIDEOS_SUCCESS', action.payload);
      return {
        ...state,
        programManuals: action.payload,
        lastSynced: action.payload.lastSynced,
        isLoading: false,
      };
    }

    case ACTION_CONSTANTS.GET_VIDEOS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ACTION_CONSTANTS.SEARCH_VIDEOS: {
      return {
        ...state,
        query: action.payload,
      };
    }

    case ACTION_CONSTANTS.DOWNLOAD_VIDEOS: {
      // console.log('DOWNLOAD_VIDEO');
      return {
        ...state,
        downloadLoading: true,
      };
    }

    case ACTION_CONSTANTS.DOWNLOAD_VIDEOS_SUCCESS: {
      // console.log('DOWNLOAD_VIDEO_SUCCESS', action.payload);
      return {
        ...state,
        downloadLoading: false,
        downloaded: true,
        downloadedVideo: [...state.downloadedVideo, action.payload],
      };
    }

    case ACTION_CONSTANTS.DOWNLOAD_VIDEOS_FAILURE: {
      return {
        ...state,
        downloadLoading: false,
      };
    }

    case ACTION_CONSTANTS.REMOVE_VIDEO: {
      // console.log('REMOVE_VIDEO', action.payload);
      return {
        ...state,
        downloadedVideo: state.downloadedVideo.filter(q => q.training_video_id != action.payload)
      };
    }

    default:
      return state;
  }
};

export default navigationVideos;
