/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TabContent, TabPane, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import './style.scss';

import AppContainer from '../../../../components/AppContainer';
import PBIcon from '../../../../components/PBIcon';
import BackdateListItem from './BackdateListItem';
import BackdatedFilter from './BackdatedFilter';

import { getLocale } from '@progressivebeef/shared/src/services/storageService/GlobalData';
import localization from '@progressivebeef/shared/src/localization/i18n';

import {
  BACKDATED_SUBMISSIONS_FILTER,
  BACKDATED_SUBMISSIONS_FILTER_INDEX,
  BACKDATED_SUBMISSIONS_FILTER_KEYS,
} from '@progressivebeef/shared/src/constants/AppConstants';
import AssetConstants from '@progressivebeef/shared/src/constants/AssetConstants';
import {
  getBackdatedActivitiesRequest,
  searchBackdatedActivities,
  resetBackdatedProps,
  getSelectedBackdatedTab,
} from '@progressivebeef/shared/src/store/actions/dashboard';

class BackDatedSubmissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
      activeTab: 
      props.selectedTab 
      ? BACKDATED_SUBMISSIONS_FILTER_INDEX[props.selectedTab]
      : props.location.state
          ? BACKDATED_SUBMISSIONS_FILTER_INDEX[props.location.state.filter]
          : BACKDATED_SUBMISSIONS_FILTER_INDEX.LAST_WEEK,
    };
  }

  componentDidMount() {
    this.props.getBackdatedActivities({
      type: this.state.activeTab,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeTab } = this.state;
    if ((activeTab !== prevState.activeTab) || (prevProps.isLoading !=  this.props.isLoading)) {
      this.toggle(activeTab);
    }
  }

  renderRightHeader = () => {
    const { backdatedFilter } = this.props;

    return (
      <div className="head-icon right-align">
        <div
          onClick={() =>
            this.setState({
              showFilter: true,
            })
          }
        >
          <img
            alt="desc"
            src={
              backdatedFilter && backdatedFilter.length > 0
                ? AssetConstants.filterFilled
                : AssetConstants.filterUnfilled
            }
          />
        </div>
      </div>
    );
  };

  renderLeftHeader = () => {
    return (
      <div className="head-icon left-align">
        <PBIcon
          name={'chevron-left'}
          onPress={() => {
            this.props.history.goBack();
          }}
        />
      </div>
    );
  };

  searchActivities = (queryString) => {
    this.props.searchActivities(queryString);
    this.props.getActivities({ type: this.state.activeTab });
  };

  toggle = (tab) => {
    this.setState({
      activeTab: tab,
    });
    this.props.getActivities({
      type: tab,
    });
  };

  render() {
    const {
      location,
      history,
      backdatedQuery,
      isBackdatedActivitiesLoading,
      backdatedActivities,
      isLoading,
    } = this.props;
    const filter = location.state
      ? location.state.filter
      : BACKDATED_SUBMISSIONS_FILTER.YESTERDAY;
    const date = location.state.date;
    const { activeTab, showFilter } = this.state;

    return (
      <>
        <AppContainer
          iconHeader
          title={localization[getLocale()].BACKDATED_ACTIVITIES}
          onSearchPress={this.searchActivities}
          searchText={backdatedQuery}
          IconRightHeader={this.renderRightHeader}
          IconLeftHeader={this.renderLeftHeader}
          history={history}
        >
          <>
            {showFilter && (
              <BackdatedFilter
                modalVisible={showFilter}
                dismissModal={() => this.setState({ showFilter: false })}
                history={history}
                filterOptions={[
                  { key: 'All', value: 'All', isSelect: false },
                  { key: 'No Tag', value: 'No Tag', isSelect: false },
                ]}
                type={this.state.activeTab}
              />
            )}
            <div className="tab-screen-parent-container">
              <div id="scrollabl" className="scrollmenu nav-tabs">

                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '0' })}
                    onClick={() => {
                      this.toggle('0');
                      this.props.getSelectedBackdatedTab(BACKDATED_SUBMISSIONS_FILTER.YESTERDAY);
                    }}
                  >
                    {localization[getLocale()].YESTERDAY}
                  </NavLink>
                </NavItem>


                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => {
                      this.toggle('1');
                      this.props.getSelectedBackdatedTab(BACKDATED_SUBMISSIONS_FILTER.THIS_WEEK);
                    }}
                  >
                    {localization[getLocale()].THIS_WEEK}
                  </NavLink>
                </NavItem>


                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => {
                      this.toggle('2');
                      this.props.getSelectedBackdatedTab(BACKDATED_SUBMISSIONS_FILTER.LAST_WEEK);
                    }}
                  >
                    {localization[getLocale()].LAST_WEEK}
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => {
                      this.toggle('3');
                      this.props.getSelectedBackdatedTab(BACKDATED_SUBMISSIONS_FILTER.THIS_MONTH);
                    }}
                  >
                    {localization[getLocale()].THIS_MONTH}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '4' })}
                    onClick={() => {
                      this.toggle('4');
                      this.props.getSelectedBackdatedTab(BACKDATED_SUBMISSIONS_FILTER.LAST_MONTH);
                    }}
                  >
                    {localization[getLocale()].LAST_MONTH}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '5' })}
                    onClick={() => {
                      this.toggle('5');
                      this.props.getSelectedBackdatedTab(BACKDATED_SUBMISSIONS_FILTER.THIS_YEAR);
                    }}
                  >
                    {localization[getLocale()].CURRENT_YEAR}
                  </NavLink>
                </NavItem>
                {filter === BACKDATED_SUBMISSIONS_FILTER.CUSTOM && (
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '6' })}
                      onClick={() => {
                        this.toggle('6');
                        this.props.getSelectedBackdatedTab(BACKDATED_SUBMISSIONS_FILTER.CUSTOM);
                      }}
                    >
                      {localization[getLocale()].CUSTOM}
                    </NavLink>
                  </NavItem>
                )}
              </div>

              <div className="tab-screen-scrollable-container">
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="0">
                    <BackdateListItem
                      tabType={BACKDATED_SUBMISSIONS_FILTER_KEYS[activeTab]}
                      {...this.props}
                      history={history}
                      isLoading={isBackdatedActivitiesLoading || isLoading}
                      title={BACKDATED_SUBMISSIONS_FILTER.YESTERDAY}
                      data={backdatedActivities || []}
                      date={date}
                    />
                  </TabPane>
                  <TabPane tabId="1">
                    <BackdateListItem
                      tabType={BACKDATED_SUBMISSIONS_FILTER_KEYS[activeTab]}
                      {...this.props}
                      history={history}
                      isLoading={isBackdatedActivitiesLoading || isLoading}
                      title={BACKDATED_SUBMISSIONS_FILTER.YESTERDAY}
                      data={backdatedActivities || []}
                      date={date}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <BackdateListItem
                      tabType={BACKDATED_SUBMISSIONS_FILTER_KEYS[activeTab]}
                      {...this.props}
                      history={history}
                      isLoading={isBackdatedActivitiesLoading || isLoading}
                      title={BACKDATED_SUBMISSIONS_FILTER.YESTERDAY}
                      data={backdatedActivities || []}
                      date={date}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <BackdateListItem
                      tabType={BACKDATED_SUBMISSIONS_FILTER_KEYS[activeTab]}
                      {...this.props}
                      history={history}
                      isLoading={isBackdatedActivitiesLoading || isLoading}
                      title={BACKDATED_SUBMISSIONS_FILTER.YESTERDAY}
                      data={backdatedActivities || []}
                      date={date}
                    />
                  </TabPane>
                  <TabPane tabId="4">
                    <BackdateListItem
                      tabType={BACKDATED_SUBMISSIONS_FILTER_KEYS[activeTab]}
                      {...this.props}
                      history={history}
                      isLoading={isBackdatedActivitiesLoading || isLoading}
                      title={BACKDATED_SUBMISSIONS_FILTER.YESTERDAY}
                      data={backdatedActivities || []}
                      date={date}
                    />
                  </TabPane>
                  <TabPane tabId="5">
                    <BackdateListItem
                      tabType={BACKDATED_SUBMISSIONS_FILTER_KEYS[activeTab]}
                      {...this.props}
                      history={history}
                      isLoading={isBackdatedActivitiesLoading || isLoading}
                      title={BACKDATED_SUBMISSIONS_FILTER.YESTERDAY}
                      data={backdatedActivities || []}
                      date={date}
                    />
                  </TabPane>
                  <TabPane tabId="6">
                    <BackdateListItem
                      tabType={BACKDATED_SUBMISSIONS_FILTER_KEYS[activeTab]}
                      {...this.props}
                      history={history}
                      isLoading={isBackdatedActivitiesLoading || isLoading}
                      title={BACKDATED_SUBMISSIONS_FILTER.CUSTOM}
                      data={backdatedActivities || []}
                      date={date}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </>
        </AppContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getActivities: (payload) =>
      dispatch(getBackdatedActivitiesRequest(payload)),
    searchActivities: (payload) => dispatch(searchBackdatedActivities(payload)),
    resetBackdatedProps: (payload) => dispatch(resetBackdatedProps(payload)),
    getBackdatedActivities: (payload) =>
      dispatch(getBackdatedActivitiesRequest(payload)),
    getSelectedBackdatedTab: (payload) =>
      dispatch(getSelectedBackdatedTab(payload)),
  };
};

const mapStateToProps = (state) => {
  const {
    backdatedQuery,
    backdatedFilter,
    backdatedSubmissionsActivities,
    isBackdatedActivitiesLoading,
    backdatedActivities,
    selectedTab,
  } = state.dashboard;
  const { isLoading } = state.activities;

  return {
    backdatedQuery,
    selectedTab,
    backdatedFilter,
    backdatedSubmissionsActivities,
    isBackdatedActivitiesLoading,
    backdatedActivities,
    isLoading,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BackDatedSubmissions);
