import {
  HTTP_STATUS_CODES,
  STATUS_CODES_TYPES,
} from '../../constants/HttpStatusCodes';
import { getLocale, getSessionExpired } from '../storageService/GlobalData';
import localization from '../../localization/i18n';
import { SERVER_STATUS_CODES } from '../../constants/ServerStatusCodes';
import { store } from '../../store/react';
import { history } from '../../../../web/src/bootSetup';

var checkLatestVersion,
  checkForceUpdate,
  checkSessionExpired,
  Sentry = null;

if (process.env.REACT_APP_PLATFORM !== 'WEB') {
  import('./interceptor').then((obj) => {
    checkLatestVersion = obj.checkLatestVersion;
    checkForceUpdate = obj.checkForceUpdate;
    checkSessionExpired = obj.checkSessionExpired;
  });

  import('../sentry').then((Sentry) => {
    Sentry = Sentry;
  });
  // import("../../store").then(({ store }) => {
  //   store = store;
  // });
} else {
  import('./reactInterceptor').then((obj) => {
    checkSessionExpired = obj.checkSessionExpired;
  });
  // import("../../store/react").then(({ store }) => {
  //   store = store;
  // });
}

// import navigationService from '../navigationService';
import RouteConstants from '../../constants/RouteConstants';
import ACTION_CONSTANTS from '../../constants/actionConstants';
import AlertService from '../AlertService';

export function handleResponse(response) {
  // console.log('RESPONSE Success IN API CALL : \n', response);
  checkLatestVersion && checkLatestVersion(response.headers);
  checkForceUpdate && checkForceUpdate(response.headers);

  if (HTTP_STATUS_CODES[response.status].type === STATUS_CODES_TYPES.SUCCESS) {
    if (
      response.data.response.success === true ||
      response.data.response.code === SERVER_STATUS_CODES._00001
    ) {
      store.dispatch({
        type: ACTION_CONSTANTS.SET_SERVER_ERROR,
        payload: false,
      });

      // console.log('RESPONSE SUCCESS IN API CALL : \n', response.data);
      return {
        data: response.data.response.PBBeans,
        time: response.data.response.time,
        ts: response.data.response.ts,
        code: response.data.response.code,
      };
    } else {
      throw response;
    }
  } else {
    throw response;
  }
}

export async function handleError(error) {
  // console.log('RESPONSE ERROR IN API CALL : \n', error, history);
  //TODO: need to change window.location.pathname, history for RN
  if (!error.response && !error.data) {
    // network error + server down
    if (
      window.location.pathname ==
      '/' + RouteConstants.SERVER_NOT_RESPONDING
    ) {
    } else {
      history.push(RouteConstants.SERVER_NOT_RESPONDING);
    }
    throw { message: null };
  } else {
    checkSessionExpired && checkSessionExpired(error) === false;
    if (!getSessionExpired()) {
      if (error.response && error.response.status) {
        if (error.response.status == 404 || error.response.status == 503) {
          if (
            window.location.pathname != RouteConstants.SERVER_NOT_RESPONDING
          ) {
            history.push(RouteConstants.SERVER_NOT_RESPONDING);
          }
          throw { message: null };
        }

        if (error.response.status == 500) {
          if (window.location.pathname != RouteConstants.SERVER_ERROR) {
            history.push(RouteConstants.SERVER_ERROR);
          }
          throw { message: null };
        }
      }
      if (process.env.REACT_APP_PLATFORM !== 'WEB') {
        Sentry.logError(error);
      }

      let errorMessage = {
        message: error.data
          ? error.data.response.code === '_00908'
            ? error.data.response.message
            : localization[getLocale()][
                'ERROR_CODE' + error.data.response.code
              ] ?? error.data.response.message
          : localization[getLocale()].SOMETHING_WENT_WRONG,
        code: error.data ? error.data.response.code : null,
        errors: [],
      };

      // console.log('errorMsessage', errorMessage);
      throw errorMessage;
    } else {
      throw { message: null };
    }
  }
}
