import React, { PureComponent } from 'react';
import './style.scss';

class Separator extends PureComponent {
  render() {
    const {
      title,
      titleStyle,
      rightTitle,
      rightTitleStyle,
      uppercase = true,
    } = this.props;

    return (
      <div className="separator-head-bar">
        <div uppercase={uppercase} style={titleStyle}>
          {title}
        </div>
        {rightTitle && (
          <div style={rightTitleStyle}>{rightTitle}</div>
        )}
      </div>
    );
  }
}

export default Separator;
