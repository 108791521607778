import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Spinner } from 'reactstrap';
import '../style.scss';

import Separator from '../../../../components/Separator';
import AppContainer from '../../../../components/AppContainer';
import {
  showSuccessAlert,
  showErrorAlert,
  showAlertMsg,
} from '../../../../components/Alerts';
import localization from '@progressivebeef/shared/src/localization/i18n';
import {
  getLocale,
  getSelectedFeedlotName,
} from '@progressivebeef/shared/src/services/storageService/GlobalData';
import { globalHelper } from '@progressivebeef/shared/src/helpers';
import {
  AUDIT_REPORT_FILENAME,
  auditStatus,
  DATE_FORMATS,
  TAB_ALL,
  IS_CORRECTIVE_ACTIONS_REQUIRED,
  userRoles,
} from '@progressivebeef/shared/src/constants/AppConstants';
import APIConstants from '@progressivebeef/shared/src/constants/APIConstants';
import RouteConstants from '@progressivebeef/shared/src/constants/RouteConstants';
import AssetConstants from '@progressivebeef/shared/src/constants/AssetConstants';
import {
  showLoader,
  hideLoader,
} from '@progressivebeef/shared/src/store/actions/view';
import {
  searchAuditNcsRequested,
  searchAuditsRequested,
  setAuditStatusRequested,
  setCurrentAuditRequested,
} from '@progressivebeef/shared/src/store/actions/audits';
import PBIcon from '../../../../components/PBIcon';
import AuditNcCard from '../../../../components/AuditNcCard';

class AuditNCList extends PureComponent {
  ncSearchText = '';
  constructor(props) {
    super(props);
    const { audit } = this.props.location.state;

    this.state = {
      currentAudit: this.props.audit || audit,
    };
  }

  componentDidMount() {
    let { audits } = this.props;

    if (audits.length == 0) {
      this.props.searchAudits(TAB_ALL, '');
    }
    this.searchAuditNCs();
  }

  componentDidUpdate(prevProps) {
    let { isLoading, success, error, showLoaderView, hideLoaderView, audits } =
      this.props;

    if (isLoading) {
      showLoaderView();
    } else {
      hideLoaderView();
    }

    if (success && success !== prevProps.success) {
      showSuccessAlert(success);
    }

    if (error !== prevProps.error) {
      if (typeof error === 'string') {
        showErrorAlert(error);
      }
    }

    if (!prevProps.audit) {
      const selectedAuditStatus = _.find(
        audits,
        _.flow(
          _.property('data'),
          _.partialRight(_.some, {
            audit_assignment_id: this.state.currentAudit.audit_assignment_id,
          }),
        ),
      );
      let auditToSet = _.find(selectedAuditStatus.data, [
        'audit_assignment_id',
        this.state.currentAudit.audit_assignment_id,
      ]);
      this.props.setCurrentAudit(auditToSet);
      this.setState({
        currentAudit: auditToSet,
      });
    }
  }

  render() {
    let { history, ncSearchText, auditNCs, isLoading } = this.props;
    let { currentAudit } = this.state;
    return (
      <>
        <AppContainer
          title={currentAudit.audit.audit_number}
          onSearchPress={this.searchAuditNCs}
          searchText={ncSearchText}
          iconHeader
          history={history}
          IconLeftHeader={this.renderLeftHeader}
          IconRightHeader={this.renderRightHeader}>
          <div className="audit-nc-separator-head-bar">
            <Separator
              uppercase={false}
              title={
                currentAudit.audit_summary.parameters
                  .is_corrective_actions_required ==
                IS_CORRECTIVE_ACTIONS_REQUIRED.YES
                  ? localization[getLocale()].CA_DUE_DATE +
                    ': ' +
                    moment(
                      currentAudit.audit_summary.parameters
                        .corrective_actions_required_by,
                    ).format(DATE_FORMATS.MM_DD_YY)
                  : ''
              }
              rightTitle={
                currentAudit.status !== auditStatus.CLOSED
                  ? localization[getLocale()][
                      'AUDIT_STATUS_' + currentAudit.status
                    ]
                  : localization[getLocale()][
                      'AUDIT_STATUS_' + currentAudit.status
                    ] +
                    ': ' +
                    moment(currentAudit?.update_date).format(
                      DATE_FORMATS.MM_DD_YY,
                    )
              }
            />
            {this.renderClearanceHeader()}
          </div>
          {auditNCs && auditNCs.length > 0 ? (
            auditNCs.map((auditNC) => {
              return (
                <AuditNcCard
                  key={'nc-' + auditNC.audit_question_answer_id}
                  onCardPress={this.onCardPress}
                  history={history}
                  item={auditNC}
                  {...this.props}
                />
              );
            })
          ) : isLoading ? (
            <div className="bottom-infinite-loader">
              {globalHelper.renderIf(isLoading)(<Spinner color={'red'} />)}
            </div>
          ) : (
            !isLoading && (
              <div className="no-result-found-box">
                <p>{localization[getLocale()].NO_AUDIT_NCS_FOUND}</p>
              </div>
            )
          )}
        </AppContainer>
      </>
    );
  }

  renderClearanceHeader = () => {
    let { currentAudit } = this.state;
    const { non_conformances } = currentAudit;
    const ncResolve =
      !non_conformances ||
      !non_conformances.length ||
      non_conformances.every((obj) => !!obj.updated_at);

    return (
      <>
        {currentAudit.status === auditStatus.CLOSED && ncResolve && (
          <div className="feedyardInfo">
            <p>
              {localization[getLocale()].info1}
              <span className="auditInfo">
                {moment(currentAudit?.audit_date).format(DATE_FORMATS.MM_DD_YY)}
              </span>
              {localization[getLocale()].info2}
              <span className="auditInfo">{currentAudit?.auditor_name}</span>
              {localization[getLocale()].info3}
            </p>
          </div>
        )}
      </>
    );
  };

  renderLeftHeader = () => {
    const { user } = this.props;

    const userRole = Number(user.userRoleId);
    const isAuditor =
      userRole === userRoles.INTERNAL_AUDITOR ||
      userRole === userRoles.EXTERNAL_AUDITOR;

    return (
      <div className="head-icon left-align feedLot">
        <PBIcon
          name={'chevron-left'}
          onPress={() => {
            // CLEARANCE LETTER CR: DISABLE SUBMIT BACK TO AUDITOR FUNCTIONALITY
            // const { currentAudit } = this.state;
            // disable submission confirmation for auditor
            // auditor cannot submit audit back from non-audit app
            // if (!isAuditor && currentAudit.status == auditStatus.SUBMITTED) {
            //   showAlertMsg(
            //     localization[getLocale()].CONFIRMATION.toUpperCase(),
            //     localization[getLocale()]
            //       .CONFIRM_SUBMIT_TO_AUDITOR_OR_RETURN_BACK,
            //     [
            //       {
            //         text: localization[getLocale()].NO,
            //         onPress: this.props.history.goBack,
            //       },
            //       {
            //         text: localization[getLocale()].YES,
            //         onPress: () => {
            //           new Promise((resolve, reject) => {
            //             this.props.submitToAuditor(
            //               auditStatus.SUBMITTED_TO_AUDITOR,
            //               currentAudit.audit_assignment_id,
            //               { resolve, reject },
            //             );
            //           }).then(this.props.history.goBack);
            //         },
            //       },
            //       { text: localization[getLocale()].CANCEL, onPress: () => {} },
            //     ],
            //   );
            // }
            this.props.history.goBack();
          }}
        />
        <p
          className="feedLotName"
          style={{
            marginLeft: 12,
            fontSize: 24,
            marginTop: -6,
            color: '#fff',
            fontWeight: 'bolder',
          }}>
          {getSelectedFeedlotName()}
        </p>
      </div>
    );
  };

  renderRightHeader = () => {
    let { currentAudit } = this.state;
    const { user } = this.props;

    const userRole = Number(user.userRoleId);
    const isAuditor =
      userRole === userRoles.INTERNAL_AUDITOR ||
      userRole === userRoles.EXTERNAL_AUDITOR;
    const { non_conformances } = currentAudit;
    const ncResolve =
      !non_conformances ||
      !non_conformances.length ||
      non_conformances.every((obj) => !!obj.updated_at);
    return (
      <div className="head-icon right-align">
        <div
          className="hald-icon-box"
          onClick={() => {
            let auditReportURL = APIConstants.GET_AUDIT_REPORT.replace(
              '{{auditAssignmentId}}',
              currentAudit.audit_assignment_id,
            );

            let pdfSource = globalHelper.getPdfSource(
              auditReportURL,
              AUDIT_REPORT_FILENAME,
            );

            this.props.history.push(RouteConstants.AUDIT_REPORT, {
              pdfSource,
              currentAudit,
            });
          }}>
          <div className="actionsLabel">
            <img
              src={AssetConstants.auditPdf}
              alt={localization[getLocale()].AUDIT_SUMMARY}
            />
            <p className="iconText">
              {localization[getLocale()].AUDIT_SUMMARY}
            </p>
          </div>
        </div>
        {currentAudit.status === auditStatus.CLOSED && ncResolve && (
          <div
            className="hald-icon-box"
            onClick={() => {
              let auditReportURL = APIConstants.GET_CLEARANCE_LETTER.replace(
                '{auditAssignmentId}',
                currentAudit.audit_assignment_id,
              );

              let pdfSource = globalHelper.getPdfSource(
                auditReportURL,
                AUDIT_REPORT_FILENAME,
              );

              this.props.history.push(RouteConstants.AUDIT_REPORT, {
                pdfSource,
                currentAudit,
              });
            }}>
            <div className="actionsLabel">
              <img
                src={AssetConstants.auditPdf}
                alt={localization[getLocale()].CLEARANCE_LETTER}
              />
              <p className="iconText">
                {localization[getLocale()].CLEARANCE_LETTER}
              </p>
            </div>
          </div>
        )}
        {/* CLEARANCE LETTER CR: REMOVE SUBMIT BACK TO AUDITOR FUNCTIONALITY */}
        {/* {
          // auditor not allowed to submit audits
          !isAuditor && currentAudit.status == auditStatus.SUBMITTED && (
            <div
              className="hald-icon-box"
              onClick={() => {
                let { audit_assignment_id } = currentAudit;
                showAlertMsg(
                  localization[getLocale()].CONFIRMATION.toUpperCase(),
                  localization[getLocale()].CONFIRM_SUBMIT_TO_AUDITOR,
                  [
                    {
                      text: 'No',
                      onPress: () => {},
                      style: 'cancel',
                    },
                    {
                      text: 'Yes',
                      onPress: () => {
                        new Promise((resolve, reject) => {
                          this.props.submitToAuditor(
                            auditStatus.SUBMITTED_TO_AUDITOR,
                            currentAudit.audit_assignment_id,
                            { resolve, reject },
                          );
                        }).then(this.props.history.goBack);
                      },
                    },
                  ],
                );
              }}>
              <img src={AssetConstants.submitAudit} />
            </div>
          )
        } */}
      </div>
    );
  };

  searchAuditNCs = (ncSearchText = '') => {
    let { currentAudit } = this.state;

    this.ncSearchText = ncSearchText;
    this.props.searchAuditNCs(currentAudit, ncSearchText);
  };

  onCardPress = (item) => {
    let { currentAudit } = this.state;

    this.props.history.push(RouteConstants.NC_DETAIL, {
      non_conformance: item,
      currentAudit,
    });
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    searchAudits: (tabType, searchText) =>
      dispatch(searchAuditsRequested({ tabType, searchText })),
    searchAuditNCs: (audit, ncSearchText) =>
      dispatch(searchAuditNcsRequested({ audit, ncSearchText })),
    submitToAuditor: (statusToUpdate, auditAssignmentId, promise) =>
      dispatch(
        setAuditStatusRequested({ statusToUpdate, auditAssignmentId, promise }),
      ),
    showLoaderView: (payload) => dispatch(showLoader(payload)),
    hideLoaderView: (payload) => dispatch(hideLoader(payload)),
    setCurrentAudit: (audit) => dispatch(setCurrentAuditRequested(audit)),
  };
};

const mapStateToProps = (state) => {
  const { user } = state.authentication;
  let { ncSearchText, isLoading, error, success, auditNCs, audit, audits } =
    state.audits;

  return {
    user,
    isLoading,
    ncSearchText,
    error,
    success,
    auditNCs,
    audit,
    audits,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditNCList);
